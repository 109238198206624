import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  BasePageMetaResolver,
  CmsService,
  PageLinkService,
  PageMetaResolver,
  PageTitleResolver,
  PageType
} from '@spartacus/core';
import { escaper } from '@utils/escaper';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AmCheckoutPageMetaResolver extends PageMetaResolver implements PageTitleResolver {
  pageTitle: string = '';
  pageId: string = '';

  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    protected readonly cms: CmsService,
    protected readonly pageLinkService: PageLinkService,
    protected readonly router: Router
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'MultiStepCheckoutSummaryPageTemplate';
  }

  resolveTitle(): Observable<string> {
    return this.cms.getCurrentPage().pipe(
      switchMap((page) => {
        return of(escaper.decode(page.title));
      })
    );
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      startWith(null),
      map(() => this.pageLinkService.getCanonicalUrl())
    );
  }
}
