<div class="msg-info-box {{ messageType }}-msg-info" [ngClass]="!hasBackgroudColor ? 'noBackgroundColor' : ''">
  <span *ngIf="iconType"
    ><am-svg-container [type]="iconType" [style]="iconStyle" [svgDescription]="iconDescription"></am-svg-container
  ></span>
  <span *ngIf="imageUrl"><img [src]="imageUrl" [alt]="iconDescription" /></span>
  <span *ngIf="!iconType && !imageUrl"><i class="{{ iconClass }}"></i></span>
  <div *ngIf="messageContent" [ngClass]="!hasBackgroudColor ? 'text' : ''" [innerHtml]="messageContent"></div>
  <div *ngIf="!messageContent"><ng-content></ng-content></div>
  <ng-container *ngIf="showCloseBtn">
    <button aria-label="Close current message" class="close-btn" (click)="closeMsgFunc($event)">
      <am-svg-container [type]="svgType.CLOSEBLUESM" svgDescription="close"></am-svg-container>
    </button>
  </ng-container>
</div>
