<div class="am-favorite-notify-product-list" *ngIf="products$ | async as products">
  <div class="top-content">
    <p>{{ products.length }} {{ 'amOrderListDetail.items' | cxTranslate }}</p>
    <div class="cx-sorting-wrapper">
      <label aria-required="true">
        <span>{{ 'amWishProductList.sortBy' | cxTranslate }}:</span>
        <cx-sorting
          class="sort-selector"
          [sortOptions]="sorts"
          (sortListEvent)="changeSortCode($event)"
          [selectedOption]="sortCode"></cx-sorting>
      </label>
    </div>
  </div>
  <div class="product-list">
    <div class="product-box" *ngFor="let product of products; let i = index">
      <div class="product-detail-box" [ngClass]="{ hasNotifyMeTip: hasNotifyMeTip(product) }">
        <div class="product-info-wrapper">
          <div class="image-container">
            <a
              class="product-image-box"
              [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
              [ngClass]="product.isDisabled ? 'opacity' : ''">
              <am-media [container]="product.images?.PRIMARY" format="cartIcon"></am-media>
            </a>
            <div class="tag">
              <p *ngIf="product.stock?.stockLevelStatus === 'outOfStock'">
                {{ 'amProductDetails.variant.outOfStock' | cxTranslate }}
              </p>

              <p *ngIf="product.isComingSoon && product.productTagGroupA?.tagName">
                {{ product.productTagGroupA?.tagName }}
              </p>
            </div>
          </div>

          <div class="product-info">
            <div class="product-description" #description>
              <p class="brand-name font-bold" *ngIf="product.brandName">{{ product.brandName }}</p>
              <p class="product-name">{{ product.name }}</p>
            </div>

            <ng-container *ngIf="product.isDisabled; else normalBlock">
              <div *ngIf="product.isDeliveryCountry === false">
                <p class="error-text">
                  {{ 'amErrorMapping.errorMessageCanNotDeliver' | cxTranslate }}
                </p>
              </div>
              <div *ngIf="product.isDiscontinued || product.isInRedeemPeriod === false && !product.isComingSoon">
                <p class="error-text">
                  {{ 'amErrorMapping.errorMessageNoLongerAvailable' | cxTranslate }}
                </p>
              </div>
            </ng-container>
            
            <ng-template #normalBlock>
              <div class="delivery-method">
                <ng-container *ngIf="collectionMethodType[product.collectionMethod] === collectionMethod.HOME_DELIVERY">
                  <am-svg-container
                    [type]="svgType.HOMEDBLACK"
                    [style]="cartIconStyle"
                    svgDescription="Home delivery"></am-svg-container>
                </ng-container>
                <ng-container
                  *ngIf="collectionMethodType[product.collectionMethod] === collectionMethod.EMAIL_DELIVERY">
                  <am-svg-container
                    [type]="svgType.EMAILBLACK"
                    [style]="cartIconStyle"
                    svgDescription="Home delivery"></am-svg-container>
                </ng-container>
                <ng-container *ngIf="product.collectionMethod === collectionMethod.INFLIGHT_PICKUP">
                  <am-svg-container
                    [type]="svgType.INFLIGHTICONGREY"
                    [style]="cartIconStyle"
                    svgDescription="Inflight Pickup"></am-svg-container>
                </ng-container>
                <ng-container *ngIf="product.collectionMethod === collectionMethod.SHOP_PICKUP">
                  <am-svg-container
                    [type]="svgType.INSTOREBLACK"
                    [style]="cartIconStyle"
                    svgDescription="Instore"></am-svg-container>
                </ng-container>

                <ng-container *ngIf="getCurrentBaseOptions(product.baseOptions, product.code); let productOption">
                  <ng-container *ngFor="let variant of productOption?.selected?.variantOptionQualifiers; let i = index">
                    <ng-container *ngIf="i === 0">
                      <span *ngIf="variant.name && variant.value">
                        <span *ngIf="variant.qualifier === 'dateAndTime'">
                          {{ variant.value | amDate : 'long' }}
                        </span>
                        <span *ngIf="variant.qualifier !== 'dateAndTime'">
                          {{ variant.value }}
                        </span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="i !== 0">
                      <span *ngIf="variant.name && variant.value">
                        <span>{{ variant.value }}</span>
                      </span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <div class="product-sale">
                <div class="product-price">
                  <am-plp-product-price [product]="product"></am-plp-product-price>
                </div>

                <div *ngIf="product.havePublishedPotentialBonusRules" class="product-sale-earn-mile">
                  <span class="icon-yellow">
                    <img src="/assets/images/icons/earn-yellow.svg" alt="" />
                  </span>
                  <p class="font-bold">{{ 'amProductItem.bonusMessage' | cxTranslate }}</p>
                </div>
              </div>
            </ng-template>

            <div class="mobile-cta-button">
              <ng-container *ngIf="activeType === activeTabCodeMap.favourite">
                <button
                  *ngIf="
                    product.isDeliveryCountry !== false &&
                    product.isDiscontinued !== true &&
                    product.isComingSoon !== true &&
                    product.stock?.stockLevelStatus !== 'outOfStock'
                  "
                  class="button-secondary"
                  (click)="addToCart(product)">
                  {{ 'amAddToCart.addToCart' | cxTranslate }}
                </button>

                <button
                  *ngIf="
                    disableNotifyButton !== true &&
                    product.excludeNotifyMe !== true &&
                    product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP &&
                    (product.stock?.stockLevelStatus === 'outOfStock' || product.isComingSoon === true) &&
                    !isInNotifyList(product.code)
                  "
                  class="button-secondary"
                  (click)="addProductIntoNotifyList(product.code)">
                  {{ 'amWishProductList.notifyMe' | cxTranslate }}
                </button>
              </ng-container>

              <ng-container *ngIf="activeType === activeTabCodeMap.notify">
                <button
                  *ngIf="
                    product.isDeliveryCountry !== false &&
                    product.isDiscontinued !== true &&
                    product.isComingSoon !== true &&
                    product.stock?.stockLevelStatus !== 'outOfStock'
                  "
                  class="button-secondary"
                  (click)="moveNotifyItemToCart(product)">
                  {{ 'amWishProductList.moveToCart' | cxTranslate }}
                </button>

                <button
                  *ngIf="
                    disableNotifyButton !== true &&
                    product.excludeNotifyMe !== true &&
                    product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP &&
                    product.stock?.stockLevelStatus === 'outOfStock' &&
                    !!product.emailTriggerTime
                  "
                  class="button-secondary"
                  (click)="addProductIntoNotifyList(product.code)">
                  {{ 'amWishProductList.notifyMe' | cxTranslate }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="cta-button-wrapper" *ngIf="activeType === activeTabCodeMap.favourite">
          <am-svg-container
            [type]="svgType.ICONDELETEGREEN"
            class="delete-button"
            svgDescription="delete button"
            [style]="deleteIconStyle"
            (click)="removeProductFromFavoriteList(product.code)">
          </am-svg-container>

          <button
            *ngIf="
              product.isDeliveryCountry !== false &&
              product.isDiscontinued !== true &&
              product.isComingSoon !== true &&
              product.stock?.stockLevelStatus !== 'outOfStock' &&
              product.isSalable == true
            "
            class="button-secondary"
            (click)="addToCart(product)">
            {{ 'amAddToCart.addToCart' | cxTranslate }}
          </button>

          <button
            *ngIf="
              disableNotifyButton !== true &&
              product.excludeNotifyMe !== true &&
              product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP &&
              (product.stock?.stockLevelStatus === 'outOfStock' || product.isComingSoon === true) &&
              !isInNotifyList(product.code)
            "
            class="button-secondary"
            (click)="addProductIntoNotifyList(product.code)">
            {{ 'amWishProductList.notifyMe' | cxTranslate }}
          </button>
        </div>

        <div class="cta-button-wrapper" *ngIf="activeType === activeTabCodeMap.notify">
          <am-svg-container
            class="delete-button"
            [type]="svgType.ICONDELETEGREEN"
            svgDescription="delete button"
            [style]="deleteIconStyle"
            (click)="removeProductFromNotifyList(product.code)">
          </am-svg-container>

          <button
            *ngIf="
              product.isDeliveryCountry !== false &&
              product.isDiscontinued !== true &&
              product.isComingSoon !== true &&
              product.stock?.stockLevelStatus !== 'outOfStock' &&
              product.isSalable == true
            "
            class="button-secondary"
            (click)="moveNotifyItemToCart(product)">
            {{ 'amWishProductList.moveToCart' | cxTranslate }}
          </button>

          <button
            *ngIf="
              disableNotifyButton !== true &&
              product.excludeNotifyMe !== true &&
              product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP &&
              product.stock?.stockLevelStatus === 'outOfStock' &&
              !!product.emailTriggerTime
            "
            class="button-secondary"
            (click)="addProductIntoNotifyList(product.code)">
            {{ 'amWishProductList.notifyMe' | cxTranslate }}
          </button>

          <div class="notifyMe-tip">
            <p *ngIf="product.isComingSoon; else outOfStock">
              <am-svg-container [type]="svgType.ICONREMINDER" svgDescription="subscribed"></am-svg-container>
              {{ 'amWishProductList.susbcribedTip' | cxTranslate }}
            </p>
            <ng-template #outOfStock>
              <ng-container *ngIf="product.stock?.stockLevelStatus === 'outOfStock'; else hasStock">
                <p *ngIf="!!product.emailTriggerTime">
                  {{ 'amWishProductList.outOfStockAgainTip' | cxTranslate }}
                </p>
                <p *ngIf="!product.emailTriggerTime">
                  <am-svg-container [type]="svgType.ICONREMINDER" svgDescription="subscribed"></am-svg-container>
                  {{ 'amWishProductList.susbcribedTip' | cxTranslate }}
                </p>
              </ng-container>
            </ng-template>

            <ng-template #hasStock>
              <p *ngIf="!product.isDisabled">
                <am-svg-container
                  [type]="svgType.ICONTICKGREEN"
                  svgDescription="done"
                  [style]="doneIconStyle"></am-svg-container>
                {{ 'amWishProductList.availableTip' | cxTranslate }}
              </p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <am-favorite-notify-reminder></am-favorite-notify-reminder>
</div>
