import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import UrlHelper from '@utils/url-helper';
import urlParser from 'js-video-url-parser';
import { MediaConfig, MediaState, MediaType } from './media-player.model';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';
import { YoukuPlayerComponent } from './youku-player/youku-player.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaPlayerComponent implements OnInit {
  @Input() posterImage: string;
  @Input() videoLink: string;
  @Input() placeHolderMode: boolean;

  @ViewChild('vimeoPlayer')
  vimeoPlayer: VimeoPlayerComponent;

  @ViewChild('youtubePlayer')
  youtubePlayer: YoutubePlayerComponent;

  @ViewChild('youkuPlayer')
  youkuPlayer: YoukuPlayerComponent;

  mediaConfig: MediaConfig;
  displayCoverImage: boolean = true;
  showLoading: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.listenPlayStateChange = this.listenPlayStateChange.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get MediaTypeEnum() {
    return MediaType;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get MediaStateEnum() {
    return MediaState;
  }

  setMediaConfig(): void {
    const info = urlParser.parse(this.videoLink);
    // const videoType = this.getVideoType(this.videoLink);
    if (info && MediaType[info.provider]) {
      this.mediaConfig = {
        type: MediaType[info.provider],
        url: this.videoLink,
        id: info.id,
        poster: this.posterImage
      };
    }
    // else if (HTML5VideoType[videoType]) {
    //   this.mediaConfig = {
    //     type: MediaType.video,
    //     url: this.videoLink,
    //     format: videoType,
    //     poster: this.posterImage
    //   }
    // } else if (videoType === 'm3u8') {
    //   this.mediaConfig = {
    //     type: MediaType.hls,
    //     url: this.videoLink,
    //     format: videoType,
    //     poster: this.posterImage
    //   }
    // }
    else {
      this.mediaConfig = {
        type: null,
        url: UrlHelper.isValidUrl(this.videoLink) ? this.videoLink : ''
      };
    }
  }

  getVideoType(url: string): string {
    let index = url.lastIndexOf('.');
    if (index === -1) {
      return null;
    }
    const type = url.substring(index + 1).toLocaleLowerCase();
    return type;
  }

  startPlay(): void {
    if (this.mediaConfig.type === this.MediaTypeEnum.youtube) {
      // this.displayCoverImage = false
      this.showLoading = true;
      this.youtubePlayer.startPlay();
    } else if (this.mediaConfig.type === this.MediaTypeEnum.vimeo) {
      // iframe
      this.showLoading = true;
      this.vimeoPlayer.startPlay();
    }
    // this.displayCoverImage = false
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  listenPlayStateChange() {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return (() => {
      if (!this.posterImage) {
        // don't need to do anything if without posterImage
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        return () => {};
      } else {
        // need to toggle the style for the posterImage
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        return (state: MediaState) => {
          // console.log('state', state);
          if (state === MediaState.Playing || state === MediaState.Paused) {
            this.showLoading = false;
            this.displayCoverImage = false;
          }
          if (state === MediaState.Ended) {
            this.displayCoverImage = true;
          }
          this.cdr.detectChanges();
        };
      }
    })();
  }

  ngOnInit(): void {
    this.setMediaConfig();
  }
}
