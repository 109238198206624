<div class="am-media-player" *ngIf="mediaConfig">
  <div *ngIf="!!posterImage && !!mediaConfig.type" class="cover-image" [ngClass]="{ hide: !displayCoverImage }">
    <img [src]="posterImage" />
    <div class="play-button-box" [ngClass]="{ loading: showLoading }">
      <button class="play-button" (click)="startPlay()"></button>
      <am-loading *ngIf="showLoading"></am-loading>
    </div>
  </div>
  <div class="video-player">
    <am-youtube-player
      #youtubePlayer
      *ngIf="mediaConfig.type === MediaTypeEnum.youtube"
      [onPlayStateChange]="listenPlayStateChange()"
      [mediaConfig]="mediaConfig">
    </am-youtube-player>

    <am-vimeo-player
      #vimeoPlayer
      *ngIf="mediaConfig.type === MediaTypeEnum.vimeo"
      [onPlayStateChange]="listenPlayStateChange()"
      [mediaConfig]="mediaConfig">
    </am-vimeo-player>

    <!-- <am-youku-player 
      #youkuPlayer
      *ngIf="mediaConfig.type === MediaTypeEnum.youku"
      [onPlayStateChange]="listenPlayStateChange()"
      [mediaConfig]=" mediaConfig?.type !== MediaTypeEnum.youku">
    </am-youku-player> -->

    <!-- <am-vime-player 
      #vimePlayer
      *ngIf="!!MediaTypeEnum[mediaConfig.type] 
             && mediaConfig.type !== MediaTypeEnum.youtube
             && mediaConfig.type !== MediaTypeEnum.vimeo
             && mediaConfig.type !== MediaTypeEnum.youku"
      [togglePlayerDisplay]="listenPlayStateChange()"
      [mediaConfig]="mediaConfig">
    </am-vime-player> -->

    <ng-container *ngIf="!MediaTypeEnum[mediaConfig.type] && !!mediaConfig.url">
      <iframe
        height="100%"
        width="100%"
        [src]="mediaConfig.url | safeUrl"
        frameborder="0"
        allow="autoplay"
        allowfullscreen="true">
      </iframe>
    </ng-container>
  </div>
</div>
