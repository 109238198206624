import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { PriceChangeDialogComponent } from './price-change-dialog.component';

@NgModule({
  declarations: [PriceChangeDialogComponent],
  imports: [CommonModule, I18nModule, KeyboardFocusModule],
  exports: [PriceChangeDialogComponent]
})
export class PriceChangeDialogModule {}
