import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { CoBrandCouponDialogComponent } from './co-brand-coupon-dialog.component';

@NgModule({
  imports: [CommonModule, I18nModule, IconModule, KeyboardFocusModule],
  declarations: [CoBrandCouponDialogComponent],
  exports: [CoBrandCouponDialogComponent]
})
export class CoBrandCouponDialogModule {}
