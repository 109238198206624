import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { YoukuPlayerComponent } from './youku-player.component';

@NgModule({
  declarations: [YoukuPlayerComponent],
  imports: [CommonModule],
  exports: [YoukuPlayerComponent]
})
export class YoukuPlayerModule {}
