import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SliderToggleButtonComponent } from './slider-toggle-button.component';

@NgModule({
  declarations: [SliderToggleButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, KeyboardFocusModule],
  exports: [SliderToggleButtonComponent]
})
export class SliderToggleButtonModule {}
