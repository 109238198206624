import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import {
  getContextParameterDefault,
  LanguageService,
  LANGUAGE_CONTEXT_ID,
  LoggerService,
  OccEndpointsService,
  SiteContextConfig
} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CLIENT_IP, INFLIGHT_POS_CODE } from 'server-token';
import { CountryService } from '../services/country/country.service';
import { COUNTRY_CONTEXT_ID } from '../store/actions/countries.action';

@Injectable({ providedIn: 'root' })
export class AmlSiteContextInterceptor implements HttpInterceptor {
  activeCountry: string;
  activeLang: string;
  protected subscription = new Subscription();
  constructor(
    private countryService: CountryService,
    private languageService: LanguageService,
    private occEndpoints: OccEndpointsService,
    private flightStatusStorageSvc: FlightStatusStorageService,
    private config: SiteContextConfig,
    protected logger: LoggerService,
    @Optional() @Inject(CLIENT_IP) protected clientIp,
    @Optional() @Inject(INFLIGHT_POS_CODE) protected posCode
  ) {
    this.activeLang = getContextParameterDefault(this.config, LANGUAGE_CONTEXT_ID);
    this.activeCountry = getContextParameterDefault(this.config, COUNTRY_CONTEXT_ID);

    this.subscription.add(this.languageService.getActive().subscribe((data) => (this.activeLang = data)));
    this.subscription.add(this.countryService.getActive().subscribe((data) => (this.activeCountry = data)));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.occEndpoints.getBaseUrl())) {
      const params: {
        lang: string;
        country: string;
        curr: string;
        pos?: string;
      } = {
        lang: this.activeLang,
        country: this.activeCountry,
        curr: 'MVR'
      };
      if (!!this.posCode || this.flightStatusStorageSvc.getInFlightIndicator()) {
        params.pos = this.posCode || this.flightStatusStorageSvc.getFlightNumber();
      }

      request = request.clone({
        setParams: {
          ...params
        }
      });
      this.logger.log({
        interceptorName: 'siteContextInterceptor',
        url: request.urlWithParams,
        clientIp: this.clientIp
      });
      if (!!this.clientIp) {
        request = request.clone({
          setHeaders: {
            clientIp: this.clientIp
          }
        });
      }
    }

    return next.handle(request);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
