<ng-container *ngIf="isCustomerEmulationSessionInProgress$ | async">
  <div class="row">
    <div class="form-group col-lg-3 col-md-4 col-sm-12">
      <input
        formcontrolname="customer"
        type="text"
        disabled="true"
        value="{{ customer?.name }}"
        placeholder="{{ customer?.name }}, {{ customer?.uid }}" />
    </div>
    <div class="form-group col-lg-3 col-md-4 col-sm-12">
      <button (click)="logoutCustomer(customer?.uid)">
        {{ 'asm.endSession' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
