import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService, PRODUCT_NORMALIZER, UserIdService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FavoriteProduct } from '../services/favorite-notify-products.service';
import { ProductListPageState } from '../store/favorite-notify-product-list-state';

interface FavoriteAndNotifyProductList {
  favoriteEntries: FavoriteProduct[];
  notifyMeEntries: FavoriteProduct[];
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteNotifyProductsConnector {
  constructor(
    protected occEndpointSvc: OccEndpointsService,
    protected userIdService: UserIdService,
    protected http: HttpClient,
    private converterService: ConverterService
  ) {}
  protected subscription = new Subscription();

  getFavoriteAndNotifyProductList(activeType?: string, sortCode?: string): Observable<FavoriteAndNotifyProductList> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    let url = this.occEndpointSvc.buildUrl('getFavoriteAndNotifyProductList', {
      urlParams: { userId }
    });
    if (sortCode) {
      url += `?sort=${sortCode}`;
    }
    return this.http.get<FavoriteAndNotifyProductList>(url).pipe(
      map((data) => {
        const favoriteEntries = data.favoriteEntries?.map((item) => {
          const newProduct = this.converterService.convert(item.product, PRODUCT_NORMALIZER);
          return {
            ...item,
            product: newProduct
          };
        });
        const notifyMeEntries = data.notifyMeEntries?.map((item) => {
          const newProduct = this.converterService.convert(item.product, PRODUCT_NORMALIZER);
          return {
            ...item,
            product: newProduct
          };
        });
        return {
          ...data,
          favoriteEntries,
          notifyMeEntries,
          activeType
        };
      })
    );
  }

  addProductIntoFavoriteList(productCode: string, sortCode?: string): Observable<ProductListPageState> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    let url = this.occEndpointSvc.buildUrl('addProductIntoFavoriteList', {
      urlParams: { userId, productCode }
    });
    if (sortCode) {
      url += `&sort=${sortCode}`;
    }
    return this.http.post<ProductListPageState>(url, {}).pipe(
      map((data) => {
        return {
          ...data,
          productCode
        };
      })
    );
  }

  removeProductFromFavoriteList(
    productCode: string,
    sortCode?: string,
    removeAllVariantProducts?: boolean
  ): Observable<ProductListPageState> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    let url = '';
    if (removeAllVariantProducts) {
      url = this.occEndpointSvc.buildUrl('removeProductFromFavoriteList4PLP', {
        urlParams: { userId, productCode }
      });
    } else {
      url = this.occEndpointSvc.buildUrl('removeProductFromFavoriteList', {
        urlParams: { userId, productCode }
      });
    }

    if (sortCode) {
      url += `&sort=${sortCode}`;
    }
    return this.http.post<ProductListPageState>(url, {}).pipe(
      map((data) => {
        return {
          ...data,
          productCode
        };
      })
    );
  }

  addProductIntoNotifyList(productCode: string, sortCode?: string): Observable<ProductListPageState> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    let url = this.occEndpointSvc.buildUrl('addProductIntoNotifyList', {
      urlParams: { userId, productCode }
    });
    if (sortCode) {
      url += `&sort=${sortCode}`;
    }
    return this.http.post<ProductListPageState>(url, {}).pipe(
      map((data) => {
        return {
          ...data,
          productCode
        };
      })
    );
  }

  removeProductFromNotifyList(productCode: string, sortCode?: string): Observable<ProductListPageState> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    let url = this.occEndpointSvc.buildUrl('removeProductFromNotifyList', {
      urlParams: { userId, productCode }
    });
    if (sortCode) {
      url += `&sort=${sortCode}`;
    }
    return this.http.post<ProductListPageState>(url, {}).pipe(
      map((data) => {
        return {
          ...data,
          productCode
        };
      })
    );
  }
}
