import { NgModule } from '@angular/core';
import { AnonymousConsentsModule, AuthModule, ExternalRoutesModule, ProductModule, ProductOccModule, UserModule, UserOccModule } from "@spartacus/core";
import { AnonymousConsentManagementBannerModule, AnonymousConsentsDialogModule, BannerModule, CategoryNavigationModule, CmsParagraphModule, ConsentManagementModule, FooterNavigationModule, HamburgerMenuModule, LinkModule, LoginRouteModule, NavigationEventModule, NavigationModule, PageTitleModule, ProductDetailsPageModule, ProductFacetNavigationModule, ProductListingPageModule, ProductListModule, ProductPageEventModule, SearchBoxModule, SiteContextSelectorModule, TabParagraphContainerModule } from "@spartacus/storefront";
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { CdcFeatureModule } from './features/cdc/cdc-feature.module';
import { CdsFeatureModule } from './features/cds/cds-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { ProductConfiguratorFeatureModule } from './features/product-configurator/product-configurator-feature.module';
import { QualtricsFeatureModule } from './features/qualtrics/qualtrics-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { StoreFinderFeatureModule } from './features/storefinder/store-finder-feature.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';

@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot(),
    LoginRouteModule,
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    // BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    // BreadcrumbModule,
    // ScrollToTopModule,
    PageTitleModule,
    UserModule,
    UserOccModule,
    // AddressBookModule,
    // PaymentMethodsModule,
    // NotificationPreferenceModule,
    // MyInterestsModule,
    // StockNotificationModule,
    ConsentManagementModule,
    // MyCouponsModule,
    AnonymousConsentsModule.forRoot(),
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    ProductModule.forRoot(),
    ProductOccModule,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    // ProductTabsModule,
    // ProductCarouselModule,
    // ProductReferencesModule,
    // ProductImagesModule,
    // ProductSummaryModule,
    // ProductIntroModule,
    // CostCenterOccModule,
    NavigationEventModule,
    // HomePageEventModule,
    ProductPageEventModule,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    CartBaseFeatureModule,
    // CartSavedCartFeatureModule,
    // WishListFeatureModule,
    // CartQuickOrderFeatureModule,
    // CartImportExportFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    // PersonalizationFeatureModule,
    TagManagementFeatureModule,
    ProductConfiguratorFeatureModule,
    // EpdVisualizationFeatureModule,
    // DigitalPaymentsFeatureModule,
    CdsFeatureModule,
    CdcFeatureModule,
    StoreFinderFeatureModule,
    SmartEditFeatureModule,
    // SmartEditModule,
    QualtricsFeatureModule,
    // ProductVariantsFeatureModule,
    // ProductImageZoomFeatureModule,
    // ProductBulkPricingFeatureModule,
    // OrganizationOrderApprovalFeatureModule,
    // OrganizationAdministrationFeatureModule,
    AsmFeatureModule
  ]
})
export class SpartacusFeaturesModule { }
