import { ViewConfig } from '@spartacus/storefront';

export const viewConfig: ViewConfig = {
  view: {
    defaultPageSize: 24,
    infiniteScroll: {
      active: false,
      productLimit: 0,
      showMoreButton: false
    }
  }
};
