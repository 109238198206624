import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { PopoverModule } from '@spartacus/storefront';
import { RelationShipComponent } from './relation-ship.component';

@NgModule({
  declarations: [RelationShipComponent],
  imports: [CommonModule, PopoverModule, I18nModule, SvgContainerModule],
  exports: [RelationShipComponent]
})
export class RelationShipModule {}
