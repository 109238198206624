import { Pipe, PipeTransform } from '@angular/core';
import { CxDatePipe, LanguageService } from '@spartacus/core';
import * as dayjs from 'dayjs';
import { dateTimePattern } from './dateTimePattern';

// type CxDatePipe, not DatePipe, due to conflict with Angular's DatePipe - problem occurs for the backward compatibility compiler of Ivy
@Pipe({ name: 'amDate', pure: false })
export class AmDatePipe extends CxDatePipe implements PipeTransform {
  constructor(protected language: LanguageService) {
    super(null);
  }

  // TODO: Replace `any` to match strict types from angular in 4.0
  // Overload to support stricter type check from angular 11 onwards
  transform(value: any, format?: string, timezone?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string): null;
  transform(value: any, format?: string, timezone?: string): string | null {
    if (!dayjs(value).isValid() || value === undefined || value === null) {
      return '';
    }
    if (typeof value !== 'number') {
      value = value.slice(0, 19);
    }
    let language = null;
    this.language
      .getActive()
      .subscribe((lang) => {
        language = lang;
      })
      .unsubscribe();
    if (dateTimePattern[language]) {
      if (!!format) {
        if (dateTimePattern[language][format]) {
          format = dateTimePattern[language][format];
        } else {
          return super.transform(value, format, timezone);
        }
      } else {
        format = dateTimePattern[language].default;
      }
    }
    return super.transform(value, format, timezone);
  }
}
