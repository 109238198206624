/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  CmsActivatedRouteSnapshot,
  CmsService,
  isNotUndefined,
  ProtectedRoutesGuard,
  RouteLoadStrategy,
  RoutingConfigService,
  RoutingService
} from '@spartacus/core';
import { CmsPageGuard, CmsPageGuardService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmCmsPageGuard extends CmsPageGuard {
  static guardName = 'CmsPageGuard';

  constructor(
    protected routingService: RoutingService,
    protected cmsService: CmsService,
    protected protectedRoutesGuard: ProtectedRoutesGuard,
    protected service: CmsPageGuardService,
    protected routingConfig: RoutingConfigService
  ) {
    super(routingService, cmsService, protectedRoutesGuard, service, routingConfig);
  }

  /**
   * Tries to load the CMS page data for the anticipated route and returns:
   * - `true` - if it can be activated
   * - `false` - if it cannot be activated
   * - `UrlTree` - if user should be redirected to a given `UrlTree`
   *
   * If the route can be activated, it fires additional calculations on the CMS components present on this CMS page,
   * based on their configuration (`cmsComponents` config).
   *
   * For more, see docs of the `CmsPageGuardService.canActivatePage`.
   */
  canActivate(route: CmsActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.protectedRoutesGuard.canActivate(route).pipe(
      switchMap((canActivate) =>
        canActivate === true
          ? this.routingService.getNextPageContext().pipe(
              filter(isNotUndefined),
              take(1),
              switchMap((pageContext) =>
                this.cmsService.getPage(pageContext, this.isShouldReload(pageContext)).pipe(
                  first(),
                  switchMap((pageData) =>
                    pageData
                      ? this.service.canActivatePage(pageContext, pageData, route, state)
                      : this.service.canActivateNotFoundPage(pageContext, route, state)
                  )
                )
              )
            )
          : of(canActivate)
      )
    );
  }

  /**
   * Returns whether we should reload the CMS page data, even when it was loaded before.
   */
  private isShouldReload(pageContext): boolean {
    if (pageContext.id?.includes('/my-account/order/')) {
      return true;
    }
    return this.routingConfig.getLoadStrategy() !== RouteLoadStrategy.ONCE;
  }
}
