import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { VerachatComponent } from './verachat.component';
import {SafeCodeModule} from "@utils/pipe/safe-code/safe-code.module";

@NgModule({
  declarations: [VerachatComponent],
  imports: [CommonModule, I18nModule, SafeCodeModule],
  exports: [VerachatComponent]
})
export class VerachatModule {}
