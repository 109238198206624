import { Cart } from '@spartacus/cart/base/root';
import { StateUtils } from '@spartacus/core';
import { MULTI_CART_DATA } from '../multi-cart-state';

export const CART_REMOVE_ENTRIES = '[Cart-entry] remove cart entries';
export const CART_REMOVE_ENTRIES_SUCCESS = '[Cart-entry] remove cart entries success';
export const CART_REMOVE_ENTRIES_FAIL = '[Cart-entry] remove cart entries fail';

export class CartRemoveEntries extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CART_REMOVE_ENTRIES;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      entriesNumber: Array<number>;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class CartRemoveEntriesSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CART_REMOVE_ENTRIES_SUCCESS;
  constructor(
    public payload: {
      cartId?: string;
      userId?: string;
      entriesNumber?: Array<number>;
      cart?: Cart;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class CartRemoveEntriesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CART_REMOVE_ENTRIES_FAIL;
  constructor(
    public payload: {
      cartId: string;
      error: any;
      userId: string;
      entriesNumber: Array<number>;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
