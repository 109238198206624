import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { GlobalMessageType, HttpErrorHandler, HttpResponseStatus, Priority } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class AmBadGatewayHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.BAD_GATEWAY;

  handleError(_request: HttpRequest<any>, errorResponse: HttpErrorResponse): void {
    if (isDevMode() || this.isSsr()) {
      console.warn(`An http 502 error occurred\n`, errorResponse.message);
      this.globalMessageService.add({ key: 'httpHandlers.badGateway' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  getPriority(): Priority {
    return Priority.NORMAL;
  }
}
