<div class="am-navigation-bar-container">
  <ng-container *ngIf="data$ | async as navigationData">
    <div class="navigation desktop">
      <div class="navigation-track">
        <swiper class="lists lg-size-9 md-size-6" [config]="swiperConfig">
          <ng-template swiperSlide *ngFor="let item of navigationData?.navigationNode?.children; let i = index">
            <a
              [routerLink]="{ cxRoute: 'category', params: { code: item.entries[0]?.localizedUrl } } | cxUrl"
              class="lists-item"
              (mouseenter)="navMouseEnter(item, i)"
              (mouseleave)="navMouseLeave()"
              data-cxlinktag-placement="top_nav"
              [attr.data-cxlinktag-name]="item.name">
              <div class="lists-item-box">
                <ng-container *ngIf="item?.icon?.url; else noImg">
                  <img
                    *ngIf="item?.icon?.name !== 'store'"
                    class="icon-nav"
                    alt=""
                    [src]="getBaseUrl() + item?.icon?.url" />
                  <img *ngIf="item?.icon?.name === 'store'" class="icon-nav" alt="" [src]="item?.icon?.url" />
                </ng-container>
                <ng-template #noImg>
                  <img class="icon-nav" alt="" src="" />
                </ng-template>

                <p class="name-nav">
                  <span *ngIf="item.name === 'navigation.store'">{{ item.name | cxTranslate }}</span>
                  <span *ngIf="item.name !== 'navigation.store'">{{ item.name }}</span>
                </p>

                <div
                  class="list-item-border"
                  [ngClass]="{ 'border-bottom-display': selectedDisplay && selectedIndex === i }"></div>
              </div>
            </a>
          </ng-template>
        </swiper>
      </div>

      <div
        class="lists-item-content"
        (mouseenter)="groupsMouseenter()"
        (mouseleave)="groupsMouseLeave()"
        [ngClass]="{ 'item-display': selectedDisplay }">
        <div class="groups">
          <div class="column" *ngIf="showFirstColumn">
            <ng-container *ngFor="let childLevel1 of selectedItem?.children">
              <ng-container *ngIf="childLevel1?.indexForDesktopNavColumn === 'FIRST_COLUMN'">
                <div class="group">
                  <a
                    class="level-one"
                    [routerLink]="
                      { cxRoute: 'category', params: { code: childLevel1?.entries[0]?.localizedUrl } } | cxUrl
                    ">
                    <span>{{ childLevel1.name }}</span>
                    <div *ngIf="childLevel1?.children.length > 0" class="icon-arrow"></div>
                  </a>
                  <ng-container *ngFor="let childLevel2 of childLevel1?.children">
                    <a
                      class="level-two"
                      [routerLink]="
                        { cxRoute: 'category', params: { code: childLevel2?.entries[0]?.localizedUrl } } | cxUrl
                      ">
                      <span>{{ childLevel2.name }}</span>
                      <div *ngIf="childLevel2?.children.length > 0" class="icon-arrow"></div>
                    </a>

                    <ng-container *ngFor="let childLevel3 of childLevel2?.children">
                      <a
                        class="level__three"
                        [routerLink]="
                          { cxRoute: 'category', params: { code: childLevel3?.entries[0]?.localizedUrl } } | cxUrl
                        ">
                        <span>{{ childLevel3.name }}</span>
                        <div *ngIf="childLevel3?.children.length > 0" class="icon-arrow"></div>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="column" *ngIf="showSecondColumn">
            <ng-container *ngFor="let childLevel1 of selectedItem?.children">
              <ng-container *ngIf="childLevel1?.indexForDesktopNavColumn === 'SECOND_COLUMN'">
                <div class="group">
                  <a
                    class="level-one"
                    [routerLink]="
                      { cxRoute: 'category', params: { code: childLevel1?.entries[0]?.localizedUrl } } | cxUrl
                    ">
                    <span>{{ childLevel1.name }}</span>
                    <div *ngIf="childLevel1?.children.length > 0" class="icon-arrow"></div>
                  </a>
                  <ng-container *ngFor="let childLevel2 of childLevel1?.children">
                    <a
                      class="level-two"
                      [routerLink]="
                        { cxRoute: 'category', params: { code: childLevel2?.entries[0]?.localizedUrl } } | cxUrl
                      ">
                      <span>{{ childLevel2.name }}</span>
                      <div *ngIf="childLevel2?.children.length > 0" class="icon-arrow"></div>
                    </a>

                    <ng-container *ngFor="let childLevel3 of childLevel2?.children">
                      <a
                        class="level__three"
                        [routerLink]="
                          { cxRoute: 'category', params: { code: childLevel3?.entries[0]?.localizedUrl } } | cxUrl
                        ">
                        <span>{{ childLevel3.name }}</span>
                        <div *ngIf="childLevel3?.children.length > 0" class="icon-arrow"></div>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="column" *ngIf="showThirdColumn">
            <ng-container *ngFor="let childLevel1 of selectedItem?.children">
              <ng-container *ngIf="childLevel1?.indexForDesktopNavColumn === 'THIRD_COLUMN'">
                <div class="group">
                  <a
                    class="level-one"
                    [routerLink]="
                      { cxRoute: 'category', params: { code: childLevel1?.entries[0]?.localizedUrl } } | cxUrl
                    ">
                    <span>{{ childLevel1.name }}</span>
                    <div *ngIf="childLevel1?.children.length > 0" class="icon-arrow"></div>
                  </a>
                  <ng-container *ngFor="let childLevel2 of childLevel1?.children">
                    <a
                      class="level-two"
                      [routerLink]="
                        { cxRoute: 'category', params: { code: childLevel2?.entries[0]?.localizedUrl } } | cxUrl
                      ">
                      <span>{{ childLevel2.name }}</span>
                      <div *ngIf="childLevel2?.children.length > 0" class="icon-arrow"></div>
                    </a>

                    <ng-container *ngFor="let childLevel3 of childLevel2?.children">
                      <a
                        class="level__three"
                        [routerLink]="
                          { cxRoute: 'category', params: { code: childLevel3?.entries[0]?.localizedUrl } } | cxUrl
                        ">
                        <span>{{ childLevel3.name }}</span>
                        <div *ngIf="childLevel3?.children.length > 0" class="icon-arrow"></div>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="column" *ngIf="showFourthColumn">
            <ng-container *ngFor="let childLevel1 of selectedItem?.children">
              <ng-container *ngIf="childLevel1?.indexForDesktopNavColumn === 'FOURTH_COLUMN'">
                <div class="group">
                  <a
                    class="level-one"
                    [routerLink]="
                      { cxRoute: 'category', params: { code: childLevel1?.entries[0]?.localizedUrl } } | cxUrl
                    ">
                    <span>{{ childLevel1.name }}</span>
                    <div *ngIf="childLevel1?.children.length > 0" class="icon-arrow"></div>
                  </a>
                  <ng-container *ngFor="let childLevel2 of childLevel1?.children">
                    <a
                      class="level-two"
                      [routerLink]="
                        { cxRoute: 'category', params: { code: childLevel2?.entries[0]?.localizedUrl } } | cxUrl
                      ">
                      <span>{{ childLevel2.name }}</span>
                      <div *ngIf="childLevel2?.children.length > 0" class="icon-arrow"></div>
                    </a>

                    <ng-container *ngFor="let childLevel3 of childLevel2?.children">
                      <a
                        class="level__three"
                        [routerLink]="
                          { cxRoute: 'category', params: { code: childLevel3?.entries[0]?.localizedUrl } } | cxUrl
                        ">
                        <span>{{ childLevel3.name }}</span>
                        <div *ngIf="childLevel3?.children.length > 0" class="icon-arrow"></div>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="column" *ngIf="showFifthColumn">
            <ng-container *ngFor="let childLevel1 of selectedItem?.children">
              <ng-container *ngIf="childLevel1?.indexForDesktopNavColumn === 'FIFTH_COLUMN'">
                <div class="group">
                  <a
                    class="level-one"
                    [routerLink]="
                      { cxRoute: 'category', params: { code: childLevel1?.entries[0]?.localizedUrl } } | cxUrl
                    ">
                    <span>{{ childLevel1.name }}</span>
                    <div *ngIf="childLevel1?.children.length > 0" class="icon-arrow"></div>
                  </a>
                  <ng-container *ngFor="let childLevel2 of childLevel1?.children">
                    <a
                      class="level-two"
                      [routerLink]="
                        { cxRoute: 'category', params: { code: childLevel2?.entries[0]?.localizedUrl } } | cxUrl
                      ">
                      <span>{{ childLevel2.name }}</span>
                      <div *ngIf="childLevel2?.children.length > 0" class="icon-arrow"></div>
                    </a>

                    <ng-container *ngFor="let childLevel3 of childLevel2?.children">
                      <a
                        class="level__three"
                        [routerLink]="
                          { cxRoute: 'category', params: { code: childLevel3?.entries[0]?.localizedUrl } } | cxUrl
                        ">
                        <span>{{ childLevel3.name }}</span>
                        <div *ngIf="childLevel3?.children.length > 0" class="icon-arrow"></div>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="column" *ngIf="!showFirstColumn"></div>
          <div class="column" *ngIf="!showSecondColumn"></div>
          <div class="column" *ngIf="!showThirdColumn"></div>
          <div class="column" *ngIf="!showFourthColumn"></div>
          <div class="column" *ngIf="!showFifthColumn"></div>
        </div>
      </div>
    </div>

    <div class="mobile-navigation-container" [ngClass]="{ 'user-mobile-navigation-container': hadLogin }">
      <div class="mobile-navigation mobile" #mobileNavigate [ngClass]="{ noScrollBar: selectedItemArray[0].show }">
        <div class="btn-wrappper" *ngIf="!hadLogin">
          <button class="button-primary" (click)="redirectToSignup()">
            <!-- <a [href]="signUrl"> -->
            {{ 'amMinilogin.signUp' | cxTranslate }}
            <!-- </a> -->
          </button>

          <span class="line"></span>
          <button class="button-secondary" (click)="loginWithRedirect()">
            <!-- <a  href="javascript:void(0)"> -->
            <span>{{ 'amMinilogin.signIn' | cxTranslate }}</span>
            <!-- </a> -->
          </button>
        </div>

        <div class="category-item-container">
          <!-- <ng-container> -->
          <a
            class="category-item"
            (click)="onNavigationItemClick(item, 0, i, categoryContainer)"
            href="javascript:void(0)"
            *ngFor="let item of navigationData?.navigationNode?.children; let i = index"
            data-cxlinktag-placement="top_nav"
            [attr.data-cxlinktag-name]="item.name"
            id="category-item-{{ i }}"
            [attr.aria-label]="
              'Collapsed,' + item.name + ',link,Double tap to activate,links available,use swipe up then right to view'
            ">
            <div class="category-item-left">
              <ng-container *ngIf="item?.icon?.url; else noImg">
                <img *ngIf="item?.icon?.name !== 'store'" alt="" [src]="getBaseUrl() + item?.icon?.url" />
                <img *ngIf="item?.icon?.name === 'store'" class="icon-nav" alt="" [src]="item?.icon?.url" />
              </ng-container>
              <ng-template #noImg>
                <img alt="" src="" />
              </ng-template>
              <span *ngIf="item.name !== 'navigation.store'">{{ item.name }}</span>
              <span *ngIf="item.name === 'navigation.store'">{{ item.name | cxTranslate }}</span>
            </div>
            <div class="category-item-right" *ngIf="item.children.length > 0"></div>
            <!-- <ng-container *ngIf="item.children.length > 0"></ng-container> -->
          </a>
          <!-- </ng-container> -->
        </div>
        <!-- 
        <ng-container *ngFor="let item of selectedItemArray; let i = index"> -->
        <div
          class="scrollBox"
          [ngClass]="{ 'user-scrollBox': hadLogin, showScrollBox: item?.show }"
          *ngFor="let item of selectedItemArray; let i = index">
          <div class="scrollBox-container">
            <button
              id="header"
              class="scrollBox-header"
              (click)="backToHome()"
              *ngIf="i === 0"
              aria-label="Back to home, Double-tap to activate">
              <div class="icon-arrow-left"></div>
              <span>
                {{ 'amMinilogin.backToHome' | cxTranslate }}
              </span>
            </button>

            <a
              id="subHeader"
              class="scrollBox-header"
              (click)="goBack(i)"
              *ngIf="i !== 0 && item.data?.name"
              href="javascript:void(0)">
              <div class="icon-arrow-left"></div>
              <span>{{ item.data?.name }}</span>
            </a>

            <div *ngIf="i === 0" class="scrollBox-title">
              <ng-container *ngIf="item.data?.icon?.url; else noImg">
                <img [src]="getBaseUrl() + item.data?.icon?.url" alt="" />
              </ng-container>
              <ng-template #noImg>
                <img src="" alt="" />
              </ng-template>

              <span role="heading">{{ item.data?.name }}</span>
            </div>

            <div *ngFor="let childLevel1 of item.data?.children">
              <a
                *ngIf="childLevel1.children.length > 0"
                class="scrollBox-content"
                (click)="onNavigationItemClick(childLevel1, i + 1)"
                href="javascript:void(0)">
                <span>{{ childLevel1.name }}</span>
                <div class="category-item-right"></div>
              </a>
              <a
                *ngIf="childLevel1.children.length <= 0"
                class="scrollBox-content"
                [routerLink]="{ cxRoute: 'category', params: { code: childLevel1?.entries[0]?.localizedUrl } } | cxUrl">
                <span>{{ childLevel1.name }}</span>
              </a>
            </div>
            <a (blur)="onLastElementTab(i + 1)" aria-hidden="true" class="is-visually-hidden" href="javascript:void(0)">
              <span>This is the last element, will be back to first elememt!</span>
            </a>
          </div>
        </div>
        <!-- </ng-container> -->
      </div>
    </div>
  </ng-container>
</div>
