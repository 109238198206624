import { Component } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { FlatDeliverFeeData } from '@spartacus/cart/base/root';
import { SiteContextComponentService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-flat-rate-delivery-fee-dialog',
  templateUrl: './flat-rate-delivery-dialog.component.html'
})
export class FlatRateDeliveryFeeDialogComponent {
  subscription: Subscription = new Subscription();
  svgType = svgConfig;
  style = ' width: 24px; height: 44px;';
  flatDeliverFeeObject: FlatDeliverFeeData;

  constructor(
    protected launchDialogService: AmLaunchDialogService,
    protected siteContextService: SiteContextComponentService
  ) {}

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        const { flatDeliverFeeObject } = data;
        this.flatDeliverFeeObject = flatDeliverFeeObject;
      })
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
