import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { ReminderModule } from '@components/content/reminder/reminder.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { FavoriteNotifyReminderComponent } from './favorite-notify-reminder.component';

@NgModule({
  declarations: [FavoriteNotifyReminderComponent],
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    SvgContainerModule,
    ReminderModule,
    CommonModule,

    I18nModule,
    UrlModule,
    // RouterModule,
    // NgSelectModule,
    // FormsModule,

    SvgContainerModule,
    MessageTipModule
  ],
  exports: [FavoriteNotifyReminderComponent]
})
export class FavoriteNotifyReminderModule {}
