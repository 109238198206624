/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, Optional, PLATFORM_ID } from '@angular/core';
import { ConfigInitializerService, getContextParameterDefault, SiteContextConfig } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SITE_CONTEXT_FROM_COOKIE } from 'server-token';

import { COUNTRY_CONTEXT_ID } from '../../providers/am-context-ids';
import { CountryStatePersistenceService } from './country-state-persistence.service';
import { CountryService } from './country.service';

@Injectable({ providedIn: 'root' })
export class CountryInitializer implements OnDestroy {
  protected subscription: Subscription;

  constructor(
    protected countryService: CountryService,
    protected countryStatePersistenceService: CountryStatePersistenceService,
    protected configInit: ConfigInitializerService,
    @Optional() @Inject(SITE_CONTEXT_FROM_COOKIE) protected siteContextFromCookie,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {}

  /**
   * Initializes the value of the active currency.
   */
  initialize(): void {
    this.subscription = this.configInit
      .getStable('context')
      .pipe(
        // TODO(#12351): <--- plug here explicitly SiteContextRoutesHandler
        switchMap(() => this.countryStatePersistenceService.initSync()),
        switchMap(() => this.setFallbackValue())
      )
      .subscribe();
  }

  /**
   * On subscription to the returned observable:
   *
   * Sets the default value taken from config, unless the active currency has been already initialized.
   */
  protected setFallbackValue(): Observable<unknown> {
    return this.configInit
      .getStable('context')
      .pipe(tap((config: SiteContextConfig) => this.setDefaultFromConfig(config)));
  }

  /**
   * Sets the active currency value based on the default value from the config,
   * unless the active currency has been already initialized.
   */
  protected setDefaultFromConfig(config: SiteContextConfig): void {
    let contextParam = null;
    if (isPlatformServer(this.platformId) && this.siteContextFromCookie?.country) {
      contextParam = this.siteContextFromCookie.country;
    } else {
      contextParam = getContextParameterDefault(config, COUNTRY_CONTEXT_ID);
    }
    if (!this.countryService.isInitialized() && contextParam) {
      this.countryService.setActive(contextParam);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}

export function initializeCountry(countryInitializer: CountryInitializer) {
  const result = () => {
    countryInitializer.initialize();
  };
  return result;
}
