import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { MediaPlayerModule } from '@components/content/common/mediaPlayer/media-player.module';
import { AmGenericLinkModule } from '@components/content/generic-link/generic-link.module';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CarouselModule, PageComponentModule } from '@spartacus/storefront';
import { SwiperModule } from 'swiper/angular';
import { VideoBannerComponent } from './video-banner.component';

@NgModule({
  declarations: [VideoBannerComponent],
  imports: [
    CommonModule,
    RouterModule,
    AmGenericLinkModule,
    I18nModule,
    UrlModule,
    PageComponentModule,
    CarouselModule,
    SwiperModule,
    MediaPlayerModule,
    AmMediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        DisplayContentVideoComponent: {
          // typeCode
          component: VideoBannerComponent
        }
      }
    } as CmsConfig)
  ]
})
export class AmVideoBannerModule {}
