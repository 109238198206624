import { Injectable } from '@angular/core';
import { CheckoutPreCheckMsgCode } from '@model/cart.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmValidateCartService {
  protected validateCartError = new BehaviorSubject<any>(null);
  protected validateCart$ = this.validateCartError.asObservable();

  triggerValidateCartError(val: any) {
    this.validateCartError.next(val);
  }

  getValidateCartErrorObs() {
    return this.validateCart$;
  }

  validateMobileError(): Observable<any> {
    return this.validateCart$.pipe(
      filter((val) => val?.msgCode === CheckoutPreCheckMsgCode.MOBILE_VERIFY_LRP_ENABLED || val === null)
    );
  }

  validatePaymentZeroError(): Observable<any> {
    return this.validateCart$.pipe(
      filter((val) => val?.msgCode === CheckoutPreCheckMsgCode.MILES_CASH_ZERO || val === null)
    );
  }
}
