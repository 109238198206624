import { Component } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'am-general-error',
  templateUrl: './general-error.component.html'
})
export class GeneralErrorComponent {
  constructor(protected routingService: RoutingService, protected amActiveCartService: ActiveCartFacade) {
    this.amActiveCartService.reloadActiveCart();
  }

  goBackHome(): void {
    this.routingService.go('');
  }
}
