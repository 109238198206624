export const mapping_pageName = new Map([
  ['home', 'HOMEPAGE'],
  ['search', 'SEARCH_RESULT'],
  ['category', 'PRODUCT_LISTING'],
  ['product', 'PRODUCT_DETAILS'],
  ['cart', 'CART'],
  ['checkoutShippingAddress', 'CHECKOUT'],
  ['checkoutVerifyOtp', 'MEMBER_VERIFICATION'],
  ['checkordersummarypage', 'GROUND_ORDER_CONFIRMATION'],
  ['orderConfirmation', 'GROUND_ORDER_CONFIRMATION'],
  ['orders', 'ORDER_HISTORY'],
  ['orderDetails', 'ORDER_DETAILS'],
  ['order', 'ORDER_DETAILS'],
  ['inflightPendingOrderSummary', 'INFLIGHT_ORDER_SUMMARY'],
  ['accountInflightOrderDetailItems', 'PENDING_ORDER'],
  ['inflightOrderVerifyPage', 'PAYMENT_VERFICATION'],
  ['inflightOrderVerify', 'PAYMENT_VERFICATION'],
  ['inflightOrderComplete', 'ORDER_COMPLETED'],
  ['campaign', 'CAMPAIGN'],
  ['notFound', '404_ERROR'],
  ['generalError', 'GENERAL_ERROR'],
  ['balanceError', 'BALANCE_ERROR']
]);
export const mapping_fulfillmentType = new Map([
  ['HOMEDELIVERY_IMMEDIATESHIPMENT', 'HD_IS'],
  ['HOMEDELIVERY_GROUPSHIPMENT', 'HD_GS'],
  ['HOMEDELIVERY_MERCHANTDELIVERY', 'HD_MD'],
  ['EMAIL_DELIVERY', 'EMAIL'],
  ['REDEMPTION_LETTER', 'REDLETTER'],
  ['EVOUCHER', 'EVOUCHER'],
  ['EVOUCHER_QR', 'EVOUCHER']
]);
export const mapping_mpcOptioin = new Map([
  ['CashOnly', 'C'],
  ['MilesOnly', 'M'],
  ['MilesPlusCash', 'MPC'],
  ['FullCashOrMpcWithMinMiles', 'C']
]);
export const mapping_deliveryMethod = new Map([
  ['DOOR_TO_DOOR', 'D2D'],
  ['LOCKERS', 'LOCKERS'],
  ['PICK_UP_STORE', 'PICKUPSTORE']
]);
export const mapping_paymentMethod = new Map([
  ['VSA', 'VI'],
  ['MSC', 'MC'],
  ['AMX', 'AX'],
  ['DNC', 'DC'],
  ['JCB', 'JC'],
  ['CUP', 'CU'],
  ['UTP', 'TP'],
  ['ALP', 'AP']
]);
export const mapping_errorType = new Map([
  ['errorCode', 'CLIENT'],
  ['errorTime2', 'SYSTEM'],
  ['errorTime3', 'SYSTEM']
]);

export const mapping_errorCode = new Map([
  ['generalError', 'ERR_GENERAL'],
  ['balanceError', 'ERR_BALANCE'],
  ['notFound', 'ERR_404']
]);

export const mapping_errorMsg = new Map([
  ['generalError', 'Sorry, we cant process this request right now.'],
  ['balanceError', 'Insufficient miles'],
  ['notFound', 'Page not found']
]);

export const BREAKPOINT = {
  LARGE: 1008,
  MEDIUM: 600,
  LARGE_AM: 1280,
  MEDIUM_AM: 768
};

export function deliveryStatus(status, type, subMethod): string {
  // back end status and type
  if (type.startsWith('HOME')) {
    if (status == 'CREATED' || status == 'CREATED_ON_HOLD') {
      return 'ORDER_PLACED';
    }
    if (status == 'DELIVERING') {
      return 'IN_TRANSIT';
    }
    if (status == 'COLLECTED') {
      return 'ARRIVED';
    }
  }
  if (type.startsWith('EMAIL')) {
    if (status == 'CREATED_ON_HOLD') {
      return 'ORDER_PLACED';
    }
    if (status == 'CREATED') {
      return 'PROCESSING';
    }
    if (status == 'DELIVERING') {
      return 'ARRIVED';
    }
  }
  if (type.startsWith('REDEMPTION')) {
    if (status == 'CREATED' || status == 'CREATED_ON_HOLD') {
      return 'ORDER_PLACED';
    }
    if (status == 'SHIPPED') {
      return 'IN_TRANSIT';
    }
    if (status == 'DELIVERING' || status == 'ARRIVED' || status == 'COLLECTED') {
      return 'ARRIVED';
    }
  }
  if (type.startsWith('EVOUCHER')) {
    if (status == 'CREATED_ON_HOLD') {
      return 'ORDER_PLACED';
    }
    if (status == 'CREATED') {
      return 'PROCESSING';
    }
    if (status == 'DELIVERING' || (status == 'EVOUCHER_SENT' && subMethod == 'MAPPING')) {
      return 'ARRIVED';
    }
  }
  if (status == 'CANCELLED' || status == 'AUTO_CANCELLED' || status == 'WAIT_REVERSE_OFFLINE') {
    return 'CANCELLED';
  }
  if (status == 'WAITING') {
    return 'PENDING';
  }
  return '';
}

export function deliveryMethod(type, deliveryMethod): string {
  let method = '';
  if (type.startsWith('HOME') || type.startsWith('REDEMPTION')) {
    switch (deliveryMethod) {
      case 'LOCKERS':
        method = 'LOCKERS';
        break;
      case 'PICK_UP_STORE':
        method = 'PICKUPSTORE';
        break;
      case 'DOOR_TO_DOOR':
        method = 'D2D';
        break;
      default:
        method = '';
    }
  }
  return method;
}

export function displayFullfillmentType(type: string): string {
  if (type == 'HOMEDELIVERY_IMMEDIATESHIPMENT') {
    return 'HD_IS';
  } else if (type == 'HOMEDELIVERY_GROUPSHIPMENT') {
    return 'HD_GS';
  } else if (type == 'HOMEDELIVERY_MERCHANTDELIVERY') {
    return 'HD_MD';
  } else if (type == 'EMAIL_DELIVERY') {
    return 'EMAIL';
  } else if (type == 'REDEMPTION_LETTER') {
    return 'REDLETTER';
  } else if (type == 'EVOUCHER' || type === 'EVOUCHER_QR') {
    return 'EVOUCHER';
  }
  return '';
}

function joinProductSuperCategory(entry): string {
  let names = '';
  entry.product?.categories.forEach((e, i, array) => {
    if (i == array.length - 1) {
      names += this.replaceQuotationMarks(e.name);
    } else {
      names += this.replaceQuotationMarks(e.name) + '|';
    }
  });
  return names;
}

function joinInstantDiscount(entry): string {
  if (entry.product?.discountRows?.length == 1) {
    if (entry.product?.discountRows[0].description) {
      return entry.product?.discountRows[0].description;
    }
  }
  let instantDiscount = entry.basePrice?.value * entry.quantity - entry.totalPrice?.value + '';
  if (entry.product?.discountRows?.length > 1) {
    entry.product?.discountRows.forEach((e) => {
      if (e.description) {
        instantDiscount += ';' + e.description;
      }
    });
  }
  return instantDiscount;
}
