import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderSummaryNoticeService {
  private isShowObs = new BehaviorSubject<boolean>(null);
  isShow$ = this.isShowObs.asObservable();
  showTime = 5;
  entryNumber: number = 0;
  constructor() {}

  open(entryNumber, time = 5): void {
    this.isShowObs.next(true);
    this.showTime = time;
    this.entryNumber = entryNumber;
  }

  close(): void {
    this.isShowObs.next(false);
  }

  isShow(): Observable<boolean> {
    return this.isShow$;
  }
}
