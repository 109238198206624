import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { AmPageTitleComponent } from './am-page-title.component';

@NgModule({
  declarations: [AmPageTitleComponent],
  imports: [CommonModule],
  exports: [AmPageTitleComponent],
  providers: [
    provideOutlet({
      id: 'LandingPage3Template',
      position: OutletPosition.BEFORE,
      component: AmPageTitleComponent
    })
  ]
})
export class AmPageTitleModule {}
