import { environment } from '@env/environment';
import { OccConfig } from '@spartacus/core/src/occ';

const occConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          list: 'products/${productCode}',
          details:
            'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType',
          default:
            'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType'
        },
        user: 'users/${userId}',
        productSearch:
          '/search?fields=products(DEFAULT),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch',
        orderHistory: 'users/${userId}/orders',
        orderDetail: 'users/${userId}/orders/${orderId}',
        carts: 'users/${userId}/carts',
        cart: 'users/${userId}/carts/${cartId}',
        createCart: 'users/${userId}/carts',
        addEntries: 'users/${userId}/carts/${cartId}/entries',
        addEmail: 'users/${userId}/carts/${cartId}/email',
        givenProfileConsent: 'users/${userId}/profile-consent?consentState=GIVEN',
        showCustomerPendingPopup: 'users/${userId}/showCustomerPendingPopup',
        showCustomerPendingPopupCancel: 'users/${userId}/showCustomerPendingPopup/cancel',
        generateHashId: 'users/${userId}/generateHashId',
        orderConfirmation: 'users/${userId}/checkout/orderConfirmation/${orderCode}',
        pendingOrderConfirmation: 'users/${userId}/checkout/payment-pending/orderConfirmation/${orderCode}',
        pendingOrderDetail: 'users/${userId}/orders/${orderCode}',
        getTotalEarnedMiles:
          'users/${userId}/carts/${cartId}/checkout/getTotalEarnedMiles?discountCode=${discountCode}&cardCode=${cardCode}&oriTotalEarnedMiles=${oriTotalEarnedMiles}',
        paymentVerify: 'users/${userId}/payment-verify/${orderCode}',
        paymentVerifySend: 'users/${userId}/payment-verify/send?code=${orderCode}',
        paymentVerifyCode: 'users/${userId}/payment-verify/verify?code=${orderCode}&otp=${smsCode}',
        paymentMethod: 'users/${userId}/carts/${cartId}/checkout/payment-method',
        smsVerifycode: 'users/${userId}/checkout/sms/verifycode/resend',
        addOneSelfAddres: 'users/${userId}/carts/${cartId}/checkout/addOneSelfAddres',
        editOneSelfAddress: 'users/${userId}/carts/${cartId}/checkout/editOneSelfAddress?addressCode=${addressCode}',
        removeOneSelfAddres: 'users/${userId}/carts/${cartId}/checkout/removeOneSelfAddres?addressCode=${addressCode}',
        deliveryAddress: 'users/${userId}/carts/${cartId}/checkout/deliveryAddress',
        getDistrict: 'users/${userId}/carts/${cartId}/checkout/getDistrict?deliveryMethod=${deliveryMethodCode}',
        getAddressOfLockersOrStores:
          '/users/${userId}/carts/${cartId}/checkout/getAddressOfLockersOrStores?deliveryMethod=${deliveryMethodCode}&district=${logisticCompanyCode}',
        titleList: 'titleList',
        balanceError: 'users/${userId}/carts/${cartId}/checkout/balanceError',
        generalError: 'checkout/generalError',
        sendVoucher: 'users/${userId}/orders/${orderNum}/sendVoucher?code=${consigmentCode}',
        consenttemplates: 'users/${userId}/consenttemplates',
        getCommonConfig: '/common/configurations',
        getIsInflightEnv: '/common/isInflightEnv',
        searchHogTags: '/search/hottags',
        getNavigationNodeUrl: '/cms/components/AMLCategoryNavComponent',
        cartProductDiscount: 'users/${userId}/carts/${cartId}/entry/${entryNumber}/cartPagePromPopUp',
        cartPageGiftOffer: 'users/${userId}/carts/${cartId}/entry/${entryNumber}/cartPageGiftOfferPopUp',
        milesUpdate: 'users/${userId}/carts/${cartId}/miles/${mile}/update',
        toggleDeliveryFeeMiles: 'users/${userId}/carts/${cartId}/toggle/deliveryFeeMiles/${isOpen}',
        toggleFcom: 'users/${userId}/carts/${cartId}/toggle/fcom/${isOpen}',
        validate: 'users/${userId}/carts/${cartId}/checkout',
        cartVoucher: 'users/${userId}/carts/${cartId}/vouchers?fields=CART',
        cartRemoveVoucher: 'users/${userId}/carts/${cartId}/vouchers?voucherId=${voucherId}&fields=CART',
        deleteCart: 'users/${userId}/carts/${cartId}',
        updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
        removeEntry: 'users/${userId}/carts/${cartId}/entry/${entryNumber}/remove',
        confirmPayment: 'users/${userId}/carts/${cartId}/checkout/payment/offline/${orderCode}',
        getFlightList: '/getFlightListByPosType/${posType}',
        getPointOfServiceStoreStatus: '/shopcrewbff/getPosStoreopenStatus/${pk}',
        getDestinationCountry: '/destinationCountries/destinationCountry',
        getLagFlag: 'products/${productCode}/lag',
        getRecommentProducts: 'products/${productCode}/reminder/suggestions',
        getLiveChatConfig: '/common/chatWidgetConfigurations',

        ccsiValidationLoading: 'users/${userId}/carts/${cartId}/checkout/loading?UID=${uid}',
        switchInflightOrGround: 'users/${userId}/carts/${cartId}/toggle/deliverymethod/${type}',
        getFavoriteAndNotifyProductList: 'users/${userId}/mylist/getFavoriteAndNotifyProductList',
        addProductIntoFavoriteList: 'users/${userId}/mylist/addProductIntoFavoriteList?productCode=${productCode}',
        addProductIntoNotifyList: 'users/${userId}/mylist/addProductIntoNotifyList?productCode=${productCode}',
        removeProductFromFavoriteList:
          'users/${userId}/mylist/removeProductFromFavoriteList?productCode=${productCode}',
        removeProductFromFavoriteList4PLP:
          'users/${userId}/mylist/removeProductFromFavoriteList4PLP?productCode=${productCode}',
        removeProductFromNotifyList: 'users/${userId}/mylist/removeProductFromNotifyList?productCode=${productCode}',
        // inflight diagnostic
        getInflightDiagnosticInfo: '/ifsnet'
      },
      prefix: '/asiamilesspartacuswebservices/v2',
      useWithCredentials: true
    }
  }
};

if (environment.baseUrl) {
  occConfig.backend.occ.baseUrl = environment.baseUrl;
}

export default occConfig;
