import { Component, Input } from '@angular/core';
import { CollectionMethod } from '@model/collection-method.enum';
import { Product, TranslationService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'am-delivery-period',
  templateUrl: './delivery-period.component.html',
  styleUrls: ['./delivery-period.component.scss']
})
export class DeliveryPeriodComponent {
  @Input() isFreeGiftProduct: boolean = false;
  @Input() freeGiftProduct: Product;

  product$: Observable<Product> = this.currentProductService.getProduct().pipe(
    map((data: any) => {
      if (this.isFreeGiftProduct) {
        this.handleData(this.freeGiftProduct);
        return this.freeGiftProduct;
      } else {
        this.handleData(data);
        return data;
      }
    })
  );
  deliveryPeriod$: Observable<string>;
  collectionMethod = CollectionMethod;
  constructor(private translate: TranslationService, protected currentProductService: CurrentProductService) {}

  handleData(product: Product): void {
    let productDeliveryTime = product.productDeliveryTime;
    let deliveryPeriodUnit$: Observable<string>;
    if (productDeliveryTime.deliveryTimeMax && productDeliveryTime.deliveryTimeMax < 2) {
      deliveryPeriodUnit$ = this.translate.translate('amDelivery.period.unitDay');
    } else {
      deliveryPeriodUnit$ = this.translate.translate('amDelivery.period.unitDays');
    }
    // Max day = 0 : immediately
    if (productDeliveryTime.deliveryTimeMax === 0) {
      if (product.collectionMethod === this.collectionMethod.SHOP_PICKUP) {
        this.deliveryPeriod$ = this.translate.translate('amDelivery.period.dayZeroO2O');
      } else {
        this.deliveryPeriod$ = this.translate.translate('amDelivery.period.dayZero');
      }
    } else {
      this.deliveryPeriod$ = deliveryPeriodUnit$.pipe(
        map((v) => {
          // Day range: {min} - {max} days
          if (productDeliveryTime.deliveryTimeMin && productDeliveryTime.deliveryTimeMin > 0) {
            return productDeliveryTime.deliveryTimeMin + ' - ' + productDeliveryTime.deliveryTimeMax + ' ' + v;
          } else {
            return productDeliveryTime.deliveryTimeMax + ' ' + v;
          }
        })
      );
    }
  }

  coverDate(date, deliveryDatePtn): string {
    return date ? dayjs(date).format(deliveryDatePtn) : date;
  }
}
