export const dateTimePattern = {
  en: {
    default: 'd MMM yyyy',
    long: 'd MMM yyyy HH:mm:ss'
  },
  zh: {
    default: 'yyyy年MM月dd日',
    long: 'yyyy年MM月dd日 HH:mm:ss'
  },
  zh_TW: {
    default: 'yyyy年MM月dd日',
    long: 'yyyy年MM月dd日 HH:mm:ss'
  },
  ja: {
    default: 'yyyy年 MM月 dd日',
    long: 'yyyy年 MM月 dd日 HH:mm:ss'
  },
  ko: {
    default: 'yyyy년 MM월 dd일',
    long: 'yyyy년 MM월 dd일 HH:mm:ss'
  }
};
