import { ModuleWithProviders, NgModule } from '@angular/core';
import { errorHandlers } from './http-interceptors/handlers';

@NgModule({
  imports: [],
  providers: []
})
export class AmGlobalMessageModule {
  static forRoot(): ModuleWithProviders<AmGlobalMessageModule> {
    return {
      ngModule: AmGlobalMessageModule,
      providers: [...errorHandlers]
    };
  }
}
