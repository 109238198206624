import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { GeneralErrorComponent } from './general-error.component';

@NgModule({
  declarations: [GeneralErrorComponent],
  imports: [CommonModule, I18nModule]
})
export class GeneralErrorModule {}
