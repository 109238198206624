import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { SiteContextType } from '@config/am-constant.config';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CollectionMethod } from '@model/collection-method.enum';
import { ActiveCartFacade, Cart, CartModification } from '@spartacus/cart/base/root';
import { WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, SiteContextComponentService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { AmActiveCartService } from 'src/app/amiredeem/pages/cart/service/active-cart.service';
import { AmCheckoutDeliveryService } from '../../../checkout/facade/am-checkout-delivery.service';
import { AmCartService } from '../../service/am-cart.service';
import { AmValidateCartService } from '../../service/am-validate-cart.service';

@Component({
  selector: 'am-cart-messages',
  templateUrl: './cart-messages.component.html'
})
export class CartMessagesComponent implements OnInit, AfterViewInit, OnDestroy {
  inflightPickUpError = false;
  verifyOtpMaxTimeError = false;
  // isFlightPickUp: boolean;
  showRemoveSuccessMessage: boolean = false;
  showValidateZeroMessage: boolean = false;
  cart$ = this.activeCartService.getActive().pipe(
    tap((cart) => {
      this.accInfoMsgs = cart.responseInfo?.globalMessages?.accInfoMsgs?.map((val) => val) || [];
      this.inflightPickUpError = cart.entries?.some((val) => {
        return (
          // (val.product.collectionMethod !== CollectionMethod.INFLIGHT_PICKUP &&
          //   this.flightStatusStorageService.isInflightPickFlow) ||
          val.product.collectionMethod === CollectionMethod.INFLIGHT_PICKUP &&
          !this.flightStatusStorageService.isInflightPickFlow
        );
      });
    })
  );
  country: string;

  svgType = svgConfig;

  currencyContext = SiteContextType.CURRENCY;
  // currency$ = this.siteContextService.getActiveItem(this.currencyContext);

  style_icon_imported_duties = 'width: 20px; height: 20px; margin-left: 8px';

  accInfoMsgs = [];
  @Input() isStoreOpenStatus: boolean | null;

  @Input() isDelistedOrOffline: boolean;
  @Input() activeTab: string;

  user$ = this.userAccountFacade.get();
  @ViewChild('balanceMobile') balanceMobile: ElementRef;

  subscription: Subscription = new Subscription();
  constructor(
    protected amCartService: AmCartService,
    protected activeCartService: AmActiveCartService,
    protected amValidateCartService: AmValidateCartService,
    private countryService: CountryService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected userAccountFacade: UserAccountFacade,
    protected launchDialogService: AmLaunchDialogService,
    public winRef: WindowRef,
    public checkoutDeliveryService: AmCheckoutDeliveryService,
    public amConfigService: AmConfigService,
    protected siteContextService: SiteContextComponentService,
  ) {
    this.subscription.add(
      this.countryService.getActive().subscribe((country) => {
        this.country = country;
      })
    );

    this.subscription.add(
      this.checkoutDeliveryService.verifyMaxOtpTimeErrorObs.subscribe((value) => {
        this.verifyOtpMaxTimeError = value;
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.activeCartService.isStable().subscribe((v) => {
        if (!v) {
          this.amCartService.closeRemoveEntrySuccess();
        }
      })
    );
    this.subscription.add(
      this.amCartService.getRemoveEntrySuccessObs().subscribe((data) => {
        this.showRemoveSuccessMessage = data;
      })
    );
    this.subscription.add(
      this.amValidateCartService.validatePaymentZeroError().subscribe((data) => {
        this.showValidateZeroMessage = data;
      })
    );
    // this.isFlightPickUp = this.flightStatusStorageService.isInflightPickFlow;
  }

  checkIfFlightPickUp(deliveryMethod: string): boolean {
    return this.amCartService.inflightFlowIfInfligthActive(deliveryMethod);
  }

  ngAfterViewInit(): void {
    if (this.balanceMobile) {
      const dom = this.winRef.document?.querySelector('cx-page-layout.CartPageTemplate');
      if (!dom?.childNodes[0].isEqualNode(this.balanceMobile.nativeElement)) {
        dom.childNodes[0]?.before(this.balanceMobile.nativeElement);
      }
    }
  }
  topLowStockFlag(item: CartModification[]): boolean {
    return item && item.some((e) => e.statusCode === 'lowStock');
  }
  reeStatusAlertMesgFlag(cart: Cart): boolean {
    let res = false;
    cart.entries
      ?.filter((e) => e.selected)
      .forEach((entry) => {
        if (
          this.country === 'HK' &&
          entry.product.specialProductType &&
          entry.product.specialProductType === 'ELECTRONIC' &&
          !entry.statutoryRemovalService
        ) {
          res = true;
        }
      });
    return res;
  }

  closeRemoveMessage(): void {
    this.amCartService.closeRemoveEntrySuccess();
  }

  closeMessage(index, distinguishFlag?: string): void {
    if (distinguishFlag) {
      this.winRef.localStorage?.removeItem('priceChange');
    } else {
      this.accInfoMsgs.splice(index, 1);
    }
  }

  removeUnavailable(cart: Cart): void {
    const removeList = [];
    const isInflightPickFlow = this.flightStatusStorageService.isInflightPickFlow;
    cart.entries.forEach((entry) => {
      if (
        // (isInflightPickFlow && entry.product.collectionMethod !== CollectionMethod.INFLIGHT_PICKUP) ||
        !isInflightPickFlow &&
        entry.product.collectionMethod === CollectionMethod.INFLIGHT_PICKUP
      ) {
        removeList.push(entry);
      }
    });
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.REMOVE_CART_ALL_ERROR_INFO, undefined, undefined, {
      entries: removeList
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  removeDelistOrOffline(cart: Cart): void {
    const removeList = [];
    cart.entries.forEach((entry) => {
      if (entry.isDelisted || entry.isOffline) {
        removeList.push(entry);
      }
    });
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.REMOVE_CART_ALL_ERROR_INFO, undefined, undefined, {
      entries: removeList
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  openCustomPopup(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.CUSTOM_TAX, undefined, undefined);
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
