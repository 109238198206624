<ng-container
  *ngIf="product.paymentMethod === 'MilesPlusCash' || product.paymentMethod === 'FullCashOrMpcWithMinMiles'">
  <ng-container *ngIf="product.originFullCashPrice && product.originFullCashPrice !== '0'">
    <p class="sale-price">
      <span class="cash-box">
        <span class="currency">{{ product.currency }}</span>
        <ng-container *ngIf="!product.discountFullCashPrice">
          <span>{{ product.originFullCashPrice | number : '1.' + decimal + '-2' }}</span>
        </ng-container>
        <ng-container *ngIf="product.discountFullCashPrice">
          <span>{{ product.discountFullCashPrice | number : '1.' + decimal + '-2' }}</span>
        </ng-container>
      </span>
      <ng-container *ngIf="product.minimumMilesSpend > 0 && product.paymentMethod === 'MilesPlusCash'">
        <span class="miles-box">
          <span class="icon-plus">+</span>
          <am-svg-container
            class="icon-light-grey-miles"
            [type]="svgType.AMICONGREY"
            [style]="style_asia_mile_light_grey"
            svgDescription="Asia miles"></am-svg-container>
          <span>{{ product.minimumMilesSpend | number : '1.0-2' }}</span>
        </span>
      </ng-container>
    </p>
  </ng-container>

  <p class="full-miles-price">
    <span class="sale-miles-price">
      <span *ngIf="product.originFullCashPrice !== '' && product.originFullCashPrice > 0" class="text-or">
        {{ 'amProductItem.or' | cxTranslate }}
      </span>
      <span class="milies-box">
        <am-svg-container
          [type]="svgType.AMICONGREY"
          [style]="style_asia_mile_grey"
          svgDescription="Asia miles"></am-svg-container>
        <ng-container *ngIf="product.promotionPrice?.value">
          <span class="miles">{{ product.promotionPrice.value | number : '1.0-2' }}</span>
        </ng-container>
        <ng-container *ngIf="!product.promotionPrice?.value">
          <span class="miles">{{ product.price?.value | number : '1.0-2' }}</span>
        </ng-container>
      </span>
    </span>
  </p>

  <span>
    <ng-container
      *ngIf="product.discountFullCashPrice && product.originFullCashPrice * 1 > product.discountFullCashPrice * 1">
      <div class="original-miles-price miles-box">
        <span class="currency">{{ product.currency }}</span>
        <del>{{ product.originFullCashPrice | number : '1.' + decimal + '-2' }}</del>
        <ng-container *ngIf="product.minimumMilesSpend > 0 && product.paymentMethod === 'MilesPlusCash'">
          <span>
            <span class="icon-plus">+</span>
            <am-svg-container
              [type]="svgType.AMICONGREY"
              [style]="style_asia_mile_light_grey"
              svgDescription="Asia miles"></am-svg-container>
            <del
              ><span>{{ product.minimumMilesSpend | number : '1.0-2' }}</span></del
            >
          </span>
        </ng-container>
        <ng-container *ngIf="product.promotionPrice && product.promotionPrice.value">
          <span class="slash-or">/</span>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="product.promotionPrice && product.promotionPrice.value">
      <div class="original-miles-price miles-box">
        <am-svg-container
          [type]="svgType.AMICONGREY"
          [style]="style_asia_mile_light_grey"
          svgDescription="Asia miles"></am-svg-container>
        <del class="origin-product-price">
          <span>{{ product.price?.value | number : '1.0-2' }}</span>
        </del>
      </div>
    </ng-container>
  </span>
</ng-container>

<ng-container *ngIf="product.paymentMethod === 'CashOnly'">
  <p class="sale-price">
    <span class="cash-box">
      <span class="currency">{{ product.currency }}</span>
      <ng-container *ngIf="!product.discountFullCashPrice">
        <span>{{ product.originFullCashPrice | number : '1.' + decimal + '-2' }}</span>
      </ng-container>
      <ng-container *ngIf="product.discountFullCashPrice">
        <span>{{ product.discountFullCashPrice | number : '1.' + decimal + '-2' }}</span>
      </ng-container>
    </span>
  </p>
  <ng-container
    *ngIf="product.discountFullCashPrice && product.originFullCashPrice * 1 > product.discountFullCashPrice * 1">
    <div class="original-miles-price miles-box">
      <span class="currency">{{ product.currency }}</span>
      <del>
        <span>{{ product.originFullCashPrice | number : '1.' + decimal + '-2' }}</span>
      </del>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="product.paymentMethod === 'MilesOnly'">
  <p class="sale-price onlyMiles">
    <span class="sale-miles-price only-miles-box">
      <am-svg-container
        [type]="svgType.AMICONGREY"
        [style]="style_asia_mile_grey"
        svgDescription="Asia miles"></am-svg-container>
      <ng-container *ngIf="product.promotionPrice?.value">
        <span>{{ product.promotionPrice.value | number : '1.0-2' }}</span>
      </ng-container>
      <ng-container *ngIf="!product.promotionPrice?.value">
        <span>{{ product.price?.value | number : '1.0-2' }}</span>
      </ng-container>
    </span>
  </p>
  <ng-container *ngIf="product.promotionPrice && product.promotionPrice.value">
    <div class="original-miles-price miles-box">
      <am-svg-container
        [type]="svgType.AMICONGREY"
        [style]="style_asia_mile_light_grey"
        svgDescription="Asia miles"></am-svg-container>
      <del>
        <span>{{ product.price?.value | number : '1.0-2' }}</span>
      </del>
    </div>
  </ng-container>
</ng-container>
<ng-content select="[icon]"></ng-content>
