import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmGenericLinkComponent } from './generic-link.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AmGenericLinkComponent],
  exports: [AmGenericLinkComponent]
})
export class AmGenericLinkModule {}
