<!-- <span tabindex="0" (smeOnTab)="onFirstElementTab()" aria-hidden="true" class="is-visually-hidden"></span> -->
<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header">
      <button type="button" class="close" (click)="dismissModal('Cross click')">
        <img tabindex="0" src="/assets/images/icons/am-close.svg" />
      </button>
    </div>

    <div class="cx-dialog-body modal-body">
      <div class="pending-order-content" #pendingOrderContent>
        {{ 'pendingOrderPopup.content' | cxTranslate : { orderExpiryDay: orderExpiryDay } }}
      </div>

      <div class="pending-order-button">
        <button class="button-secondary button-secondary-cancel" (click)="clickNotNow()">
          {{ 'pendingOrderPopup.notNow' | cxTranslate }}
        </button>

        <button class="button-secondary button-secondary-ok" (click)="viewMyOrder()" (blur)="onLastElementTab()">
          {{ 'pendingOrderPopup.viewMyOrder' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
