import { OccConfig } from '@spartacus/core';

export const defaultAmOccCartConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        cartProductDiscount: 'users/${userId}/carts/${cartId}/entry/${entryNumber}/cartPagePromPopUp',
        cartPageGiftOffer: 'users/${userId}/carts/${cartId}/entry/${entryNumber}/cartPageGiftOfferPopUp',
        milesUpdate: 'users/${userId}/carts/${cartId}/miles/${mile}/update',
        toggleDeliveryFeeMiles: 'users/${userId}/carts/${cartId}/toggle/deliveryFeeMiles/${isOpen}',
        toggleFcom: 'users/${userId}/carts/${cartId}/toggle/fcom/${isOpen}',
        validate: 'users/${userId}/carts/${cartId}/checkout',
        cartVoucher: 'users/${userId}/carts/${cartId}/vouchers',
        cartRemoveVoucher: 'users/${userId}/carts/${cartId}/vouchers?voucherId=${voucherId}',
        deleteCart: 'users/${userId}/carts/${cartId}',
        updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
        removeEntry: 'users/${userId}/carts/${cartId}/entry/${entryNumber}/remove',
        removeEntries: 'users/${userId}/carts/${cartId}/entries/remove'
      }
    }
  }
};
