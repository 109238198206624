import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmAddToCartService {
  private isShowObs = new BehaviorSubject<boolean>(null);
  isShow$ = this.isShowObs.asObservable();
  constructor() {}

  open(): void {
    this.isShowObs.next(true);
  }

  close(): void {
    this.isShowObs.next(false);
  }

  isShow(): Observable<boolean> {
    return this.isShow$;
  }
}
