<div class="am-checkout-loading">
  <div class="loading-img loading-page-effect">
    <div class="cx_loading-icon">
      <div class="cx_primary-path"></div>
      <div class="cx_secondary-path">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <p class="loading-tip">
    {{ 'amCheckoutLoading.wait' | cxTranslate }}<br />
    {{ 'amCheckoutLoading.redirectTip' | cxTranslate }}
  </p>
  <form id="paymentRequestForm" method="post"></form>
</div>
