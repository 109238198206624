import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddToCartParams } from '@model/cart.model';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import { Cart, CartModification, CART_NORMALIZER } from '@spartacus/cart/base/root';
import { CmsService, ConverterService, OccEndpointsService } from '@spartacus/core';
import { PageLayoutService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AmOccCartEntryAdapter extends OccCartEntryAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected converterService: ConverterService,
    protected pageLayoutService: PageLayoutService,
    protected cmsService: CmsService
  ) {
    super(http, occEndpointSvc, converterService);
  }
  public add(
    userId: string,
    cartId: string,
    productCode: string,
    quantity: number = 1,
    pickupStore?: string,
    obj: AddToCartParams = {
      miles: 1
    }
  ): Observable<CartModification> {
    const url = this.occEndpointSvc.buildUrl('addEntries', {
      urlParams: { userId, cartId, quantity }
    });
    // Handle b2b case where the x-www-form-urlencoded is still used
    if (url.includes(`quantity=${quantity}`)) {
      const header = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

      return this.http
        .post<CartModification>(url, {}, { headers: header, params: { code: productCode } })
        .pipe(
          map((val) => ({ ...val, cart: val.cart ? this.converterService.convert(val.cart, CART_NORMALIZER) : null }))
        );
    }

    const toAdd = {
      qty: quantity,
      code: productCode,
      miles: obj.miles || 0,
      removalServiceStatus: obj.removalServiceStatus
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http
      .post<CartModification>(url, toAdd, { headers })
      .pipe(
        map((val) => ({ ...val, cart: val.cart ? this.converterService.convert(val.cart, CART_NORMALIZER) : null }))
      );
  }
  public remove(userId: string, cartId: string, entryNumber: string): Observable<Cart> {
    const url = this.occEndpointSvc.buildUrl('removeEntry', {
      urlParams: {
        userId,
        cartId,
        entryNumber
      }
    });

    return this.http.post(url, {}).pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  public removeEntries(userId: string, cartId: string, entryNumber: Array<number>): Observable<Cart> {
    const url = this.occEndpointSvc.buildUrl('removeEntries', {
      urlParams: {
        userId,
        cartId
      }
    });
    return this.http.post(url, { entires: entryNumber }).pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  public updateCart(
    userId: string,
    cartId: string,
    entryNumber: string,
    qty: number,
    pickupStore?: string
  ): Observable<Cart> {
    let params = {};
    if (pickupStore) {
      params = {
        deliveryPointOfService: {
          name: pickupStore
        }
      };
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = this.occEndpointSvc.buildUrl('updateEntries', {
      urlParams: {
        userId,
        cartId,
        entryNumber
      }
    });

    return this.http
      .patch<Cart>(url, { quantity: qty, ...params }, { headers })
      .pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  public update(
    userId: string,
    cartId: string,
    entryNumber: string,
    qty?: number,
    pickupStore?: string,
    pickupToDelivery: boolean = false
  ): Observable<Cart> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = this.occEndpointsService.buildUrl('updateEntries', {
      urlParams: {
        userId,
        cartId,
        entryNumber
      }
    });

    // switch from pickup to delivery mode
    if (pickupStore === undefined && pickupToDelivery) {
      return this.http
        .put<Cart>(url, { quantity: qty }, { headers })
        .pipe(this.converterService.pipeable(CART_NORMALIZER));
    }

    let params = {};
    if (pickupStore) {
      params = {
        deliveryPointOfService: {
          name: pickupStore
        }
      };
    }

    return this.http
      .patch<Cart>(url, { quantity: qty, ...params }, { headers })
      .pipe(this.converterService.pipeable(CART_NORMALIZER));
  }
}
