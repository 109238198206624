import { Component, ViewChild } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

import { svgConfig } from '../../../../config/am-svg-sprite.config';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';

@Component({
  selector: 'am-display-search-box',
  templateUrl: './display-search-box.component.html'
})
export class DisplaySearchBoxComponent {
  activeSearchContent: boolean = false;
  svgType = svgConfig;
  data$ = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<any>,
    protected winRef: WindowRef,
    // protected launchDialogService: AmLaunchDialogService,
  ) {}

  @ViewChild('searchDialog')
  private searchDialog: SearchDialogComponent;

  displaySearchContent() {
    // can not use launchDialogService, only spartacus SearchBoxComponent' children can provide CmsComponentData
    // const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.GLOBAL_SEARCH_DIALOG, undefined, undefined);
    // if (dialog) {
    //   dialog.pipe(take(1)).subscribe();
    // }
    this.activeSearchContent = true;
    this.searchDialog.open();
    // timeout for css transition
    this.winRef.isBrowser() &&
      setTimeout(() => {
        this.searchDialog.activeSearchInput();
      }, 500);
  }

  hideSearchContent(): void {
    this.activeSearchContent = false;
  }

}
