export default class UrlHelper {
  /**
   * @param url 
   * @returns transform the query params to object
     eg.?aaa=1&bbb=2&ccc => { aad: "1", bbb: "2", ccc: ""}
   */
  static parseQueryStringToObject(url?: string): object {
    url = url ?? window?.location.href;
    url = decodeURIComponent(url);
    const pairs: Array<string> = url.split('?').length > 1 ? url.split('?')[1].split('&') : [];
    const data = {};
    if (pairs && pairs.length > 0) {
      pairs.map((pair: string) => {
        const pairArray = pair.split('=');
        data[pairArray[0]] = pairArray[1] ?? '';
      });
    }
    return data;
  }

  static stringifyObjectToUrlParams(object: object, isEncode?: boolean): string {
    let str = '';
    for (let name in object) {
      str += `${name}=${object[name]}&`;
    }
    str = str.slice(0, -1);
    if (isEncode) {
      str = encodeURIComponent(str);
    }
    return str;
  }

  /**
   *
   * @param name the key that want to get from the query params
   * @param urlString: origin url
   * @returns the value of query params
   */
  static getSearchParamByName(name?: string, urlString?: string): string {
    urlString = urlString ?? window?.location.href;
    const url = new URL(urlString);
    return url.searchParams.get(name) ?? '';
  }

  /**
   *
   * @param urlString: origin url
   * @param name: the key for new query param
   * @param value: the value for new query param
   * @returns the url with new param
   */
  static addSearchParam(urlString: string, name: string, value: string): string {
    urlString = urlString ?? window?.location.href;
    const url = new URL(urlString);
    url.searchParams.set(name, value);
    return url.href;
  }

  static getOrderCodeFromUrl(url: string): string | null {
    url = url ?? window?.location.href;
    const regex = /^[\s\S]*([A-Z]\d{8})/;
    const matches = url.match(regex);
    if (matches && matches.length === 2) {
      return matches[1];
    }
    return null;
  }

  static isValidUrl(urlString: string): boolean {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  }
}
