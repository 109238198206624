import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { TermsAndConditionsModule } from '../../../product-detail/component/terms-and-conditions/terms-and-conditions.module';
import { PromotionCashDialogComponent } from './promotion-cash-dialog.component';

@NgModule({
  declarations: [PromotionCashDialogComponent],
  exports: [PromotionCashDialogComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    TermsAndConditionsModule,
    SafeCodeModule,
    DateTimeModule,
    KeyboardFocusModule
  ]
})
export class AmPromotionCashDialogModule {}
