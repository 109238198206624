import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { removeAllErrorDialogComponent } from './remove-all-error-dialog.component';

@NgModule({
  declarations: [removeAllErrorDialogComponent],
  exports: [removeAllErrorDialogComponent],
  imports: [CommonModule, I18nModule, IconModule, KeyboardFocusModule]
})
export class RemoveAllErrorDialogModule {}
