import { Component, OnInit } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CmsService, OccConfig, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface ImageModel {
  code: string;
  mime: string;
  url: string;
}

export interface LinkModel {
  display: string;
  linkName: string;
  newTab: string;
  typeCode: string;
  uid: string;
  name: string;
  localizedUrl: string;
}
export interface CtaContentBannerModel {
  uid: string;
  typeCode: string;
  image: ImageModel;
  link: LinkModel;
  description: string;
  title: string;
}
@Component({
  selector: 'am-content-highlight-with-image',
  templateUrl: './content-highlight-with-image.component.html',
  styleUrls: ['./content-highlight-with-image.component.scss']
})
export class ContentHighlightWithImageComponent implements OnInit {
  svgType = svgConfig;

  constructor(
    protected componentData: CmsComponentData<any>,
    protected cmsService: CmsService,
    protected occConfig: OccConfig,
    protected winRef: WindowRef
  ) {}

  contentHighlightWithImageData$: Observable<CtaContentBannerModel[]> = this.componentData.data$.pipe(
    switchMap((data) => {
      return !!data.ctaContentBanners
        ? combineLatest(
            data.ctaContentBanners
              .trim()
              .split(' ')
              .map((componentUid) => {
                return this.cmsService.getComponentData<CtaContentBannerModel>(componentUid).pipe(
                  map((detailItem) => {
                    if (!detailItem) {
                      return undefined;
                    }
                    return detailItem;
                  })
                );
              })
          )
        : of(null);
    })
  );

  protected getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  ngOnInit(): void {}

  checkUrlIfContainsDomain(url: string): string {
    if (!url) {
      return 'javascript:void(0)';
    }

    if (url.indexOf('http') !== -1) {
      return url;
    } else {
      return this.winRef.location?.origin + url;
    }
  }
}
