import { Component } from '@angular/core';
import { CmsService, Image, ProductSearchPage } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AmProductListComponentService } from '../../services/product-list-component.service';

@Component({
  selector: 'am-product-page-title',
  templateUrl: './product-page-title.component.html',
  styleUrls: ['./product-page-title.component.scss']
})
export class ProductPageTitleComponent {
  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;

  pageLabel: string = '';
  categoryNameForBanner: string = 'categoryNameForBanner';
  bannerImageUrl: string = '';

  constructor(private productListComponentService: AmProductListComponentService, protected cmsservice: CmsService) {
    this.cmsservice
      .getCurrentPage()
      .subscribe((data) => {
        this.pageLabel = data.label;
      })
      .unsubscribe();
  }

  makeUpMediaContainer(url: string): Image {
    return {
      url: url,
      role: 'presentation'
    } as Image;
  }
}
