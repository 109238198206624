import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseOption, RoutingService } from '@spartacus/core';

@Component({
  selector: 'am-product-variant-color-selector',
  templateUrl: './product-variant-color-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductVariantColorSelectorComponent {
  constructor(private routingService: RoutingService) {}

  @Input() variants: BaseOption;
  @Input() pageFrom: string;
  @Output() emitProductCode: EventEmitter<string> = new EventEmitter();

  changeColor(code: string, name: string): null {
    if (code) {
      if (this.pageFrom === 'mylistPage') {
        this.emitProductCode.emit(code);
      } else {
        this.routingService.go({
          cxRoute: 'product',
          params: { code }
        });
      }
    }
    return null;
  }

  firstUpperCase(value: string): string {
    return value.slice(0, 1).toLocaleUpperCase() + value.slice(1);
  }
}
