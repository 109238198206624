import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { PLPProductPriceModule } from '@components/content/common/plp-product-price/plp-product-price.module';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { PAGE_LAYOUT_HANDLER, SortingModule, KeyboardFocusModule } from '@spartacus/storefront';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { AmAddToCartModule } from '../product-detail/component/add-to-cart/add-to-cart.module';
import { RelationShipModule } from '../product-detail/component/product-intro/relation-ship/relation-ship.module';
import { ProductSliderAlcoholicModule } from '../product-detail/component/product-slider-alcoholic/product-slider-alcoholic.module';
import { ProductSliderElectronicModule } from '../product-detail/component/product-slider-electronic/product-slider-electronic.module';
import { ProductVariantsContainerModule } from '../product-detail/component/product-variants-container/product-variants-container.module';
import { FavoriteNotifyBottomSheetComponent } from './components/favorite-notify-bottom-sheet/favorite-notify-bottom-sheet.component';

import { FavoriteNotifyProductListComponent } from './components/favorite-notify-product-list/favorite-notify-product-list.component';
import { FavoriteNotifyReminderModule } from './components/favorite-notify-reminder/favorite-notify-reminder.module';
import { FavoriteNotifySwitchTabComponent } from './components/favorite-notify-switch-tab/favorite-notify-switch-tab.component';
import { RecommendFollowCateogriesComponent } from './components/recommend-follow-categories/recommend-follow-cateogries.component';
import { FavoriteNotifyProductsPageHandler } from './services/favorite-notify-page-handler';

@NgModule({
  declarations: [
    FavoriteNotifySwitchTabComponent,
    FavoriteNotifyProductListComponent,
    RecommendFollowCateogriesComponent,
    FavoriteNotifyBottomSheetComponent
  ],
  exports: [
    FavoriteNotifySwitchTabComponent,
    FavoriteNotifyProductListComponent,
    RecommendFollowCateogriesComponent,
    FavoriteNotifyBottomSheetComponent
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FavoriteNotifySwitchTabComponent: {
          component: FavoriteNotifySwitchTabComponent,
          guards: [AuthGuard]
        },
        FavoriteNotifyProductListComponent: {
          component: FavoriteNotifyProductListComponent
        },
        EMLCategoryThumbnailContainerComponent: {
          component: RecommendFollowCateogriesComponent
        }
      }
    }),
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: FavoriteNotifyProductsPageHandler,
      multi: true
    }
  ],
  imports: [
    CommonModule,
    SortingModule,
    I18nModule,
    AmMediaModule,
    UrlModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    PLPProductPriceModule,
    SvgContainerModule,
    MessageTipModule,
    DateTimeModule,
    FavoriteNotifyReminderModule,
    RelationShipModule,
    ProductVariantsContainerModule,
    ProductSliderElectronicModule,
    ProductSliderAlcoholicModule,
    AmAddToCartModule,
    KeyboardFocusModule
  ]
})
export class FavoriteNotifyProductsModule {}
