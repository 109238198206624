<am-reminder
  *ngIf="showRminderAction"
  [iconType]="isErrorInfo() ? svgType.ICONERRORRED : svgType.ICONSUCCESSGREEN"
  [backgroudColor]="isErrorInfo() ? '#F8F0F1' : '#F3F8F7'"
  [showCtaButton]="showRminderAction === 'removeFavoriteSuccess' || showRminderAction === 'removeNotifyMeSuccess'"
  (clickCtaBtnEmit)="clickUndoBtn()">
  <div
    *ngIf="showRminderAction === 'addFavoriteSuccess'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.addFavourite' | cxTranslate"></div>
  <div
    *ngIf="showRminderAction === 'addFavoriteFail'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.addFavouriteFail' | cxTranslate"></div>
  <div
    *ngIf="showRminderAction === 'removeFavoriteSuccess'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.removeFavourite' | cxTranslate"></div>
  <div
    *ngIf="showRminderAction === 'addNotifyMeSuccess'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.addNotifyMeMessage' | cxTranslate"></div>
  <div
    *ngIf="showRminderAction === 'removeNotifyMeSuccess'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.removeNotifyMeMessage' | cxTranslate"></div>
  <div
    *ngIf="showRminderAction === 'addNotifyMeFail'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.addNotifyMeFail' | cxTranslate"></div>

  <div
    *ngIf="showRminderAction === 'addToCartMaxError'"
    class="description"
    [innerHtml]="
      'amErrorMapping.quantityReducedNumberOfItemsAddedMaxOrderQuantityExceeded' | cxTranslate : params
    "></div>

  <!-- <div
    *ngIf="showRminderAction === 'addToCartSuccess'"
    class="description"
    [innerHtml]="'favouriteNotifyMe.addToCartSuccess' | cxTranslate"></div> -->
</am-reminder>
