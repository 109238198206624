import { Injectable } from '@angular/core';
import {
  FIRST_ACCESS_COUNTRY_NAME,
  FIRST_VISIT_COOKIE_NAME
} from '@components/layout/globalPopup/contants/global-popup.contansts';
import CookiesHelper from '@utils/cookies/cookies.helper';

@Injectable({
  providedIn: 'root'
})
export class SiteContextSelectorStorageService {
  constructor() {}

  storeFirstVisitCookie(selectedLanguage: string, selectedCountry: string) {
    CookiesHelper.setCookie(FIRST_VISIT_COOKIE_NAME, selectedLanguage);
    CookiesHelper.setCookie(FIRST_ACCESS_COUNTRY_NAME, selectedCountry);
  }

  getFirstVisitCookie() {
    return CookiesHelper.getCookie(FIRST_VISIT_COOKIE_NAME);
  }

  getFirstVisitSelectedCountry() {
    return CookiesHelper.getCookie(FIRST_ACCESS_COUNTRY_NAME);
  }

  isFirstVisit(): boolean {
    return !CookiesHelper.getCookie(FIRST_VISIT_COOKIE_NAME) || !CookiesHelper.getCookie(FIRST_ACCESS_COUNTRY_NAME);
  }
}
