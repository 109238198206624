import { Component, Input, OnInit } from '@angular/core';
import { svgPath } from '@config/am-svg-sprite.config';

@Component({
  selector: 'am-svg-container',
  templateUrl: './svg-container.component.html',
  styleUrls: ['./svg-container.component.scss']
})
export class SvgContainerComponent implements OnInit {
  ROOT_PATH = svgPath;
  protected sizeMap = {
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '48px',
  };
  protected colorMap = {
    dark: '#2d2d2d',
    primary: '#005d63',
    // red: '#D11F00'
  };

  @Input() style: any = '';
  @Input() type: string;
  @Input() svgDescription: string = '';
  @Input() class: string = '';
  @Input() color: 'dark' | 'primary';
  @Input() size: 's' | 'm' | 'l' | 'xl';// = 'm'

  constructor() {}

  ngOnInit(): void {
    if (this.style === '' && this.size && this.color) {
      const color = this.colorMap[this.color] || this.colorMap.dark,
        width = this.sizeMap[this.size] || this.sizeMap.m,
        height = this.sizeMap[this.size] || this.sizeMap.m;
      this.style = {
        color,
        width,
        height,
      }
    }
  }

  getSvgPath() {
    return this.ROOT_PATH + '#' + this.type;
  }
}
