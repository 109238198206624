import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { AmMediaModule } from '../common/media/media.module';
import { RecommendProductListModule } from '../product/recommendProductList/recommend-product-list.module';
import { SvgContainerModule } from '../svg-container/svg-container.module';
import { CategoryThumbanilPnfComponent } from './category-thumbanil-pnf.component';

@NgModule({
  imports: [
    CommonModule,
    KeyboardFocusModule,
    AmMediaModule,
    SvgContainerModule,
    I18nModule,
    AmMediaModule,
    SvgContainerModule,
    RouterModule,
    UrlModule,
    RecommendProductListModule,
    ConfigModule.withConfig({
      cmsComponents: {
        PNFCategoryThumbnailContainerComponent: {
          // typeCode
          component: CategoryThumbanilPnfComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [CategoryThumbanilPnfComponent],
  declarations: [CategoryThumbanilPnfComponent]
  // entryComponents: [CategoryThumbanilComponent]
})
export class CategoryThumbnailPnfModule {}
