import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { CustomerPendingPopup } from '@model/order-expiry.model';
import {
  AuthRedirectStorageService,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
  WindowRef
} from '@spartacus/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PendingOrderPopupService {
  protected subscription = new Subscription();

  constructor(
    protected userIdService: UserIdService,
    protected occEndpointSvc: OccEndpointsService,
    private httpClient: HttpClient,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected launchDialogService: AmLaunchDialogService,
    protected winRef: WindowRef
  ) {}

  getPendingPopupData(): Observable<CustomerPendingPopup> {
    return this.userIdService.getUserId().pipe(
      filter((userId) => userId !== OCC_USER_ID_ANONYMOUS),
      switchMap((userId: any) => {
        const url = this.occEndpointSvc.buildUrl('showCustomerPendingPopup', { urlParams: { userId } });
        return this.httpClient.get<CustomerPendingPopup>(url);
      })
    );
  }

  isCancelPendingPopup(): Observable<any> {
    return this.userIdService.getUserId().pipe(
      filter((userId) => userId !== OCC_USER_ID_ANONYMOUS),
      switchMap((userId) => {
        const url = this.occEndpointSvc.buildUrl('showCustomerPendingPopupCancel', { urlParams: { userId } });
        return this.httpClient.post<any>(
          url,
          {},
          {
            // @ts-ignore
            responseType: 'text'
          }
        );
      })
    );
  }

  showCustomerPendingPopup(): Observable<CustomerPendingPopup | null> {
    const hasPendingData = this.winRef.localStorage?.getItem('pendingPopup');
    if (!!hasPendingData) {
      return of(null);
    }
    return this.getPendingPopupData().pipe(
      tap((res) => {
        if (res.hasPendingOrder == 'true') {
          this.setPopupStatus(res.hasPendingOrder);
          const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.SHOW_PENDING_ORDER, undefined, undefined, {
            orderExpiryDay: res.orderExpiryDay
          });
          if (dialog) {
            this.subscription.add(dialog.pipe(take(1)).subscribe());
          }
        }
      })
    );
  }

  setPopupStatus(status): void {
    this.winRef.localStorage?.setItem('pendingPopup', status);
  }

  removePopupStatus(): void {
    this.winRef.localStorage?.removeItem('pendingPopup');
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
