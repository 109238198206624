<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title" aria-live="polite" aria-atomic="true">
          {{ 'amCartDetails.popup.cxMobileVerify.title' | cxTranslate }}
        </div>
        <button type="button" class="close" (click)="closeModal('dismiss')">
          <span aria-hidden="true">
            <svg class="icon-close">
              <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
            </svg>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main">
          <div class="section">
            <div class="verified-moblie-number">
              {{ 'amCartDetails.popup.cxMobileVerify.content1' | cxTranslate : params }}
            </div>
            <div
              class="verified-moblie-number"
              [innerHTML]="'amCartDetails.popup.cxMobileVerify.content2' | cxTranslate | safeHtml"></div>
            <div
              class="verified-moblie-number"
              [innerHTML]="'amCartDetails.popup.cxMobileVerify.content3' | cxTranslate | safeHtml"></div>
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="cx-dialog-buttons d-flex">
        <a [href]="msg.verifyMobileUrl" class="button-primary" target="_blank" (click)="closeModal('cancel')">
          {{ 'amCartDetails.popup.cxMobileVerify.btn' | cxTranslate }}
        </a>
      </div>
    </div>
  </div>
</div>
