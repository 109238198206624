<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="offer-details-popup">
      <div class="cx-dialog-header">
        <div class="title-wrapper">
          <span>{{ 'amProductDetails.promotionDetails.title' | cxTranslate }}</span>
          <button
            aria-label="close promotion details popup"
            class="close-popup-btn link"
            (click)="closeModal('Cross click')">
            <am-svg-container
              [type]="svgType.CLOSEGREYSM"
              [style]="style_icon_close"
              svgDescription="close popup"></am-svg-container>
          </button>
        </div>

        <div class="tabs-wrapper" *ngIf="tabCount >= 2">
          <ul class="tabs">
            <li
              *ngIf="product?.discountRows?.length > 0 || product?.potentialDiscountRules?.length > 0"
              class="tabs_tab-item"
              [class.selected]="activeTab === 'discountTag'">
              <button
                aria-label="discount tabs"
                class="link"
                href="javascript:void(0);"
                data-tab-id="promotion-details"
                (click)="setActiveTab('discountTag')">
                {{ 'amProductDetails.promotionDetails.discountTab' | cxTranslate }}
              </button>
            </li>
            <li
              *ngIf="
                product?.potentialFreeGiftBuyAGetBRules?.length > 0 ||
                product?.potentialFreeGiftThresholdRules?.length > 0
              "
              class="tabs_tab-item"
              [class.selected]="activeTab === 'freeGiftTag'">
              <button
                aria-label="freegift tabs"
                class="link"
                href="javascript:void(0);"
                data-tab-id="free-gift-details"
                (click)="setActiveTab('freeGiftTag')">
                {{ 'freeGift.giftOffer' | cxTranslate }}
              </button>
            </li>
            <li
              *ngIf="product?.potentialBonusRules?.length"
              class="tabs_tab-item"
              [class.selected]="activeTab === 'bonusTag'">
              <button
                aria-label="bonus tabs"
                class="link"
                href="javascript:void(0);"
                data-tab-id="offer-details"
                (click)="setActiveTab('bonusTag')">
                {{ 'amProductDetails.promotionDetails.bonusTab' | cxTranslate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="cx-dialog-body main" [ngClass]="{ 'no-tabs': tabCount < 2 }" #popupBody>
        <div class="popup-tooltip-details" id="promotion-details" [class.hidden]="activeTab !== 'discountTag'">
          <div
            class="section"
            *ngFor="let discount of product.discountRows"
            [ngClass]="{
              multiple: isMultipleItems(product.discountRows, product.potentialDiscountRules)
            }">
            <am-offer-details-popup-item
              [discountOrBonusRule]="discount"
              [isPotentialRule]="false"
              [multiple]="multiple"
              [activeCode]="activeCodeObj['discountTag']"
              (clickPromotion)="afterSelectedPromotion($event)">
            </am-offer-details-popup-item>
          </div>
          <div
            class="section"
            *ngFor="let discount of product.potentialDiscountRules"
            [ngClass]="{
              multiple: isMultipleItems(product.discountRows, product.potentialDiscountRules)
            }">
            <am-offer-details-popup-item
              [discountOrBonusRule]="discount"
              [isPotentialRule]="true"
              [multiple]="multiple"
              [activeCode]="activeCodeObj['discountTag']"
              (clickPromotion)="afterSelectedPromotion($event)">
            </am-offer-details-popup-item>
          </div>
        </div>

        <div class="popup-tooltip-details" id="free-gift-details" [class.hidden]="activeTab !== 'freeGiftTag'">
          <ng-container *ngIf="pageFrom === 'cartPage'">
            <div class="section" *ngFor="let productPromotion of product">
              <am-popup-item-gift-offer [freeGiftPromotion]="productPromotion"></am-popup-item-gift-offer>
            </div>
          </ng-container>

          <div
            class="section"
            *ngFor="let freeGiftBuyAGetB of product?.potentialFreeGiftBuyAGetBRules"
            [ngClass]="{
              multiple: isMultipleItems(
                product?.potentialFreeGiftBuyAGetBRules,
                product?.potentialFreeGiftThresholdRules
              )
            }">
            <am-popup-item-gift-offer
              [freeGiftPromotion]="freeGiftBuyAGetB"
              [multiple]="multiple"
              [activeCode]="activeCodeObj['freeGiftTag']"
              (clickPromotion)="afterSelectedPromotion($event)"></am-popup-item-gift-offer>
          </div>

          <div
            class="section"
            *ngFor="let freeGiftThreshold of product?.potentialFreeGiftThresholdRules"
            [ngClass]="{
              multiple: isMultipleItems(
                product?.potentialFreeGiftBuyAGetBRules,
                product?.potentialFreeGiftThresholdRules
              )
            }">
            <am-popup-item-gift-offer
              [freeGiftPromotion]="freeGiftThreshold"
              [multiple]="multiple"
              [activeCode]="activeCodeObj['freeGiftTag']"
              (clickPromotion)="afterSelectedPromotion($event)"></am-popup-item-gift-offer>
          </div>
        </div>

        <div class="popup-tooltip-details" id="offer-details" [class.hidden]="activeTab !== 'bonusTag'">
          <div
            class="section"
            *ngFor="let bonus of product.potentialBonusRules"
            [ngClass]="{ multiple: isMultipleItems(product.potentialBonusRules) }">
            <am-offer-details-popup-item
              [discountOrBonusRule]="bonus"
              [isPotentialRule]="true"
              [multiple]="multiple"
              [activeCode]="activeCodeObj['bonusTag']"
              (clickPromotion)="afterSelectedPromotion($event)">
            </am-offer-details-popup-item>
          </div>
        </div>
      </div>
      <!-- <div class="cx-dialog-footer">
        <button aria-label="close current popup" class="close-popup-btn" (click)="closeModal('click close button')">
          {{ 'amProductDetails.promotionDetails.popUpDone' | cxTranslate }}
        </button>
      </div> -->
    </div>
  </div>
</div>
