import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';

@Component({
  selector: 'am-message-tip',
  templateUrl: './message-tip.component.html',
  styleUrls: ['./message-tip.component.scss']
})
export class MessageTipComponent implements OnInit {
  // iconType is used for svg usage
  @Input() iconType: string;
  @Input() messageContent: string;
  // so far we have ERROR, Warning, success message type
  @Input() messageType: string;
  // this flag is used for controling the display of message background color
  @Input() hasBackgroudColor: boolean = true;
  // iconStyle is used for svg style, you may also use css to control svg style
  @Input() iconStyle: string = 'width: 24px; height: 24px; margin: 0 5px 0 10px';
  @Input() iconDescription: string = '';
  // imageUrl is used for image usage
  @Input() imageUrl: string;

  // this flag is used for controling close btn in message
  @Input() showCloseBtn: boolean = false;
  // this flag is used along with "hasBackgroudColor" usually to remove background color, padding and margin
  @Input() isSmallMessageTip: boolean;
  // this flag is used for font icon.
  @Input() iconClass: string;

  @Output() closeMsgEmit: EventEmitter<boolean> = new EventEmitter();

  svgType = svgConfig;

  constructor() {}

  ngOnInit(): void {
    if (this.isSmallMessageTip) {
      this.iconStyle = 'width: 20px; height: 20px; margin:0 5px 0 0px';
    }
  }

  closeMsgFunc(): void {
    this.closeMsgEmit.emit(true);
  }
}
