import { Country } from '@spartacus/core/src/model';
import { Category } from '@spartacus/core';

export const SITE_CONTEXT_FEATURE = 'amSiteContext';

export interface CountriesEntities {
  [isocode: string]: Country;
}

export interface CountriesState {
  entities: CountriesEntities;
  activeCountry: string;
}

export interface SearchState {
  popularTags: string;
  popularBrands: Category[];
  cathayFeature: any[];
  popularTagsList: string[];
  loaded: boolean;
}

export interface SiteContextState {
  countries: CountriesState;
  search: SearchState;
}

export interface StateWithSiteContext {
  [SITE_CONTEXT_FEATURE]: SiteContextState;
}
