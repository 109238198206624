import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CommomConfigMap, CommonState, COMMON_FEATURE, StateWithAmCommon } from '../common-state';

export const getCommonState: MemoizedSelector<StateWithAmCommon, CommonState> =
  createFeatureSelector<CommonState>(COMMON_FEATURE);

export const getCommonConfigDetails: MemoizedSelector<StateWithAmCommon, CommomConfigMap> = createSelector(
  getCommonState,
  (state) => state?.commonConfig
);
