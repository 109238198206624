import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmToolTipModule } from '@components/content/common/tool-tip/am-tool-tip.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { DeliveryComponent } from './delivery.component';

@NgModule({
  declarations: [DeliveryComponent],
  imports: [
    CommonModule,
    I18nModule,
    SafeCodeModule,
    KeyboardFocusModule,
    UrlModule,
    SvgContainerModule,
    AmToolTipModule
  ],
  exports: [DeliveryComponent]
})
export class AmDeliveryModule {}
