import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { ProductVariantsContainerComponent } from './product-variants-container.component';
import { ProductVariantColorSelectorModule } from './variant-color-selector/product-variant-color-selector.module';
import { ProductVariantSizeSelectorModule } from './variant-size-selector/product-variant-size-selector.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ProductVariantSizeSelectorModule,
    ProductVariantColorSelectorModule
  ],
  declarations: [ProductVariantsContainerComponent],
  exports: [ProductVariantsContainerComponent]
})
export class ProductVariantsContainerModule {}
