<ng-container *ngIf="variant?.options">
  <div class="variant-selector d-flex">
    <div class="variant-label">{{ getName(variant) }}:</div>
    <div class="variant-select input-wrapper">
      <ng-select
        [(ngModel)]="variant.selected.value"
        bindLabel="value"
        bindValue="value"
        [items]="variant.options"
        [clearable]="false"
        appearance="outline"
        [searchable]="false"
        (change)="onChange($event)">
        <ng-template ng-label-tmp let-item="item">
          <ng-container *ngIf="item.qualifier === 'dateAndTime'; else elseQualifier">
            {{ item.value | amDate: 'long' }}
          </ng-container>
          <ng-template #elseQualifier>
            {{ item.value }}
          </ng-template>
          <ng-container
            *ngIf="
              variant.isTheOptionSecondLevel &&
                item.stock?.stockLevel <= 0 &&
                item.stock?.stockLevelStatus !== 'inStock';
              else elseStock
            ">
            ({{ 'amProductDetails.variant.outOfStock' | cxTranslate }})
          </ng-container>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <ng-container *ngIf="item.qualifier === 'dateAndTime'; else elseQualifier">
            {{ item.value | amDate: 'long' }}
          </ng-container>
          <ng-template #elseQualifier>
            {{ item.value }}
          </ng-template>
          <ng-container
            *ngIf="
              variant.isTheOptionSecondLevel &&
                item.stock?.stockLevel <= 0 &&
                item.stock?.stockLevelStatus !== 'inStock';
              else elseStock
            ">
            ({{ 'amProductDetails.variant.outOfStock' | cxTranslate }})
          </ng-container>
        </ng-template>
      </ng-select>
      <span class="label-content" [ngClass]="{ 'not-empty': !!variant.selected.value }">
        {{ getName(variant) }}
      </span>
    </div>
  </div>
</ng-container>
