<div #dialog class="order-summary-notice">
  <div (mouseenter)="clearAutoClose()" (mouseleave)="addAutoClose()" class="content">
    <div class="popover-body">
      <div class="notice-title">
        <span class="text">
          <am-svg-container [type]="svgType.SMALLBELL"></am-svg-container>
          <span>
            {{ 'orderSummary.noticeTitle' | cxTranslate }}
          </span>
        </span>
        <button aria-label="close notice popup" class="link" (click)="close()">
          <am-svg-container [type]="svgType.AMCLOSE"></am-svg-container>
        </button>
      </div>
      <div class="notice-content">{{ 'orderSummary.noticeContent' | cxTranslate : { number: entryNumber } }}</div>
      <div class="notice-btns">
        <a aria-label="Check out now" [routerLink]="{ cxRoute: 'cart' } | cxUrl" (click)="goToCart()" class="">
          {{ 'orderSummary.noticeButton' | cxTranslate }}
          <am-svg-container [type]="svgType.ARROWFORWARD"></am-svg-container>
        </a>
      </div>
    </div>
  </div>
</div>
