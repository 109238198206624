import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'am-reminder',
  templateUrl: './reminder.component.html'
})
export class ReminderComponent {
  @Input() iconType: string;
  @Input() backgroudColor: boolean = true;
  @Input() showCtaButton: boolean = false;

  @Output() clickCtaBtnEmit: EventEmitter<boolean> = new EventEmitter();

  clickCtaBtnFunc(): void {
    this.clickCtaBtnEmit.emit(true);
  }
}
