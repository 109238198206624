import { Component } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-co-brand-coupon-dialog',
  templateUrl: './co-brand-coupon-dialog.component.html',
  styleUrls: ['./co-brand-coupon-dialog.component.scss']
})
export class CoBrandCouponDialogComponent {
  cobrandCardName: string = '';
  subscription: Subscription = new Subscription();

  constructor(protected launchDialogService: AmLaunchDialogService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        this.cobrandCardName = data.cobrandCardName;
      })
    );
  }

  closeModal(reason?: string) {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
