import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { SearchActions } from '../actions/index';
import { AmSearchBoxService } from '@components/content/mulit-search-box/search-box/am-search-box.service'

@Injectable()
export class SearchEffects {
  loadHotTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.LOAD_HOT_TAGS),
      exhaustMap(() => {
        return this.amSearchBoxService.fetchHotSearchList().pipe(
          map((tags) => {
            return new SearchActions.LoadHotTagsSuccess(tags);
          }),
          catchError((error) => of(new SearchActions.LoadHotTagsFail(normalizeHttpError(error))))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private amSearchBoxService: AmSearchBoxService
  ) {}
}
