import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { AmCartItemListModule } from '../cart-item-list/cart-item-list.module';
import { AmCartMessagesModule } from '../cart-messages/cart-messages.module';
import { AmCartTotalsModule } from '../cart-totals/cart-totals.module';
import { CartDetailNonflow1ContainerComponent } from './cart-detail-nonflow1-container.component';

@NgModule({
  declarations: [CartDetailNonflow1ContainerComponent],
  imports: [CommonModule, AmCartMessagesModule, AmCartTotalsModule, AmCartItemListModule, I18nModule],
  exports: [CartDetailNonflow1ContainerComponent]
})
export class CartDetailNonflow1ContainerModule {}
