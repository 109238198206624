<ng-container *ngIf="lrpHeaderBannerData">
  <div class="lrp-header-banner-container">
    <div class="lrp-header-banner-content-width">
      <div class="d-flex text-container">
        <div class="">
          <div class="lrp-header-banner-title">{{ lrpHeaderBannerData.title }}</div>
          <div *ngIf="lrpHeaderBannerData.description" class="lrp-header-banner-description">
            {{ lrpHeaderBannerData.description }}
          </div>
          <ng-container
            *ngIf="lrpHeaderBannerData.link?.display === 'true' && lrpHeaderBannerData.link?.newTab === 'true'">
            <a
              class="lrp-header-banner-link-area"
              [attr.href]="lrpHeaderBannerData.link?.localizedUrl"
              target="_blank"
              >{{ lrpHeaderBannerData.link?.linkName }}</a
            >
            <am-svg-container [type]="svgType.AMICONTRACKING" svgDescription="Open in a new tab"></am-svg-container>
          </ng-container>
          <ng-container
            *ngIf="lrpHeaderBannerData.link?.display === 'true' && lrpHeaderBannerData.link?.newTab !== 'true'">
            <a class="lrp-header-banner-link-area internal-link" [attr.href]="lrpHeaderBannerData.link?.localizedUrl">{{
              lrpHeaderBannerData.link?.linkName
            }}</a>
          </ng-container>
        </div>
      </div>
      <div class="image-container">
        <am-media [container]="mediaData"></am-media>
      </div>
    </div>
  </div>
</ng-container>
