<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="product?.productDeliveryTime?.isFixDeliveryDate; else isFixDeliveryDate">
    <ng-container *ngIf="product.productDeliveryTime.deliveryStartDate; else elseDeliveryDate">
      {{ product.productDeliveryTime.deliveryStartDate | amDate: ('amFormat.time.pattern.normal' | cxTranslate) }} -
      {{ product.productDeliveryTime.deliveryEndDate | amDate: ('amFormat.time.pattern.normal' | cxTranslate) }}
    </ng-container>
    <ng-template #elseDeliveryDate>
      {{ product.productDeliveryTime.deliveryEndDate | amDate: ('amFormat.time.pattern.normal' | cxTranslate) }}
    </ng-template>
  </ng-container>
  <ng-template #isFixDeliveryDate>
    <ng-container *ngIf="deliveryPeriod$ | async as deliveryPeriod">
      {{ deliveryPeriod }}
    </ng-container>
  </ng-template>
</ng-container>
