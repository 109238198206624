import { Injectable } from '@angular/core';
import { AsmEnablerService, ASM_ENABLED_LOCAL_STORAGE_KEY } from '@spartacus/asm/root';
// import { ASM_LOGINNED_LOCAL_STORAGE_KEY } from '../am-asm-constants';

@Injectable({
  providedIn: 'root'
})
export class AmAsmEnablerService extends AsmEnablerService {
  removeAsmStoreStatus(): void {
    if (this.winRef.localStorage) {
      this.winRef.localStorage?.removeItem(ASM_ENABLED_LOCAL_STORAGE_KEY);
    }
  }

  isLaunched(): boolean {
    const params = this.location.path().split('?')[1];
    return !!params && params.split('&').includes('asm=true');
  }
}
