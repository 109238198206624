import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Country } from '@spartacus/core/src/model';
import { CountriesEntities, CountriesState, SiteContextState, StateWithSiteContext } from '../state';
import { getSiteContextState } from './site-context.selector';

const activeCountriesSelector = (state: CountriesState) => state.activeCountry;
const countriesEntitiesSelector = (state: CountriesState) => state.entities;
const activeCountriesObject = (state: CountriesState) => {
  return state.entities[state.activeCountry];
};

export const getCountriesState: MemoizedSelector<StateWithSiteContext, CountriesState> = createSelector(
  getSiteContextState,
  (state: SiteContextState) => state.countries
);

export const getCountriesEntities: MemoizedSelector<StateWithSiteContext, CountriesEntities> = createSelector(
  getCountriesState,
  countriesEntitiesSelector
);

export const getActiveCountry: MemoizedSelector<StateWithSiteContext, string> = createSelector(
  getCountriesState,
  activeCountriesSelector
);

export const getActiveCountryEntity: MemoizedSelector<StateWithSiteContext, Country> = createSelector(
  getCountriesState,
  activeCountriesObject
);

export const getAllCountries: MemoizedSelector<StateWithSiteContext, Country[]> = createSelector(
  getCountriesEntities,
  (entities) => {
    return entities ? Object.keys(entities).map((isocode) => entities[isocode]) : null;
  }
);
