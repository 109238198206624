import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { PendingOrderPopupService } from '@components/content/pending-order-popup/pending-order-popup.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { AmLoginService } from '@core/auth/user-auth/facade/am-login.service';
import { AmAuthRedirectService } from '@core/auth/user-auth/services/am-auth-redirect.service';
import { AmOAuthLibWrapperService } from '@core/auth/user-auth/services/am-oauth-wrapper.service';
import { AmThirdPartyKeepAliveService } from '@core/auth/user-auth/services/am-third-party-keep-alive.service';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService, TokenTarget } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthToken,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  OCC_USER_ID_CURRENT,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
  WindowRef
} from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import UrlHelper from '@utils/url-helper';
import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AmAsmEnablerService } from './am-asm-enable.service';
import { AmCsAgentAuthService } from './am-csagent-auth.service';

const loginCookieFlag = 'am_logged';

@Injectable({
  providedIn: 'root'
})
export class AmAsmAuthService extends AmAuthService {
  isBrowser: boolean;
  protected subscription = new Subscription();
  constructor(
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: AmOAuthLibWrapperService,
    protected authStorageService: AsmAuthStorageService,
    protected authRedirectService: AmAuthRedirectService,
    protected routingService: RoutingService,
    protected oAuthService: OAuthService,
    protected http: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected winRef: WindowRef,
    protected router: Router,
    protected amThirdPartyKeepAliveService: AmThirdPartyKeepAliveService,
    protected pendingOrderPopupService: PendingOrderPopupService,
    protected urlHelperService: UrlHelperService,
    protected asmEnablerSvc: AmAsmEnablerService,
    protected globalMessageService: GlobalMessageService,
    protected amCsAgentAuthService: AmCsAgentAuthService,
    protected amLoginService: AmLoginService,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {
    super(
      store,
      userIdService,
      oAuthLibWrapperService,
      authStorageService,
      authRedirectService,
      routingService,
      oAuthService,
      http,
      occEndpointSvc,
      winRef,
      router,
      amThirdPartyKeepAliveService,
      amLoginService,
      pendingOrderPopupService
    );
  }

  async checkOAuthParamsInUrl(): Promise<void> {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (!this.isBrowser) {
      return;
    }
    // do this change for mobile app
    const decodeUrlLocation = new URL(decodeURIComponent(this.winRef.location.href));
    const params = UrlHelper.parseQueryStringToObject(this.winRef.location.href);
    const code = params['code'];
    const refreshToken = params['refreshToken'] || decodeUrlLocation.hash?.includes('refreshToken');
    const isAsmEnable = this.asmEnablerSvc.isEnabled();
    const hasInAppFlag = params['LSapp'] || decodeUrlLocation.hash?.includes('LSapp');
    this.storeInAppFlag(hasInAppFlag);
    if (code) {
      this.getTokenWithCode(code);
    } else if (isAsmEnable) {
      this.tryAsmLogin();
    } else if (refreshToken) {
      this.reloginForInapp();
    } else {
      this.syncLoginStatusWithMlcCookie();
    }
  }

  async getTokenWithCode(code: string): Promise<void> {
    if (code === 'redirect' || code === 'error' || code === 'error_home') {
      return;
    }
    try {
      this.amLoginService.setLoginProgress(true);
      const loginResult = await this.oAuthLibWrapperService.tryLogin();
      const token = this.authStorageService?.getItem('access_token');
      // We get the result in the code flow even if we did not logged in that why we also need to check if we have access_token
      if (loginResult.result && token) {
        const isAsmEnable = this.asmEnablerSvc.isEnabled();
        if (isAsmEnable) {
          this.amCsAgentAuthService.authorizeCustomerSupportAgent();
        } else {
          this.userIdService.setUserId(OCC_USER_ID_CURRENT);
          this.store.dispatch(new AuthActions.Login());
        }
        if (loginResult.tokenReceived) {
          this.authRedirectService.redirect();
          this.amLoginService.setLoginProgress(false);
          CookiesHelper.setCookie(loginCookieFlag, 'true');
          this.amThirdPartyKeepAliveService.storeThirdTokenKeepAliveStartTime();
          this.amThirdPartyKeepAliveService.setupThirdPartyTokenTimer();
        }
      }
    } catch {}
  }

  async tryAsmLogin(): Promise<void> {
    const fetchIdpSessionValidateUrlEndpoint =
      this.occEndpointSvc.getBaseUrl() + '/assisted-service/idp/sessionValidateUrl';
    const nonce = await this.oAuthService.createAndSaveNonce();
    const httpParams = new HttpParams().set('state', encodeURIComponent(nonce));
    this.http
      .get<any>(fetchIdpSessionValidateUrlEndpoint, { params: httpParams })
      .toPromise()
      .then((result) => {
        this.subscription.add(
          this.isCSAgentLoggedIn()
            .pipe(take(1))
            .subscribe((isLoggedIn: boolean) => {
              if (!isLoggedIn) {
                // sso login
                if (result.data) {
                  this.winRef.location.href = result.data;
                } else {
                  this.asmEnablerSvc.removeAsmStoreStatus();
                  this.loginWithIdp();
                }
              }
              if (isLoggedIn && !result.data) {
                this.coreLogout();
              }
            })
        );
      });
  }

  loginWithIdp(): void {
    const queryLoginUrl = this.occEndpointSvc.getBaseUrl() + '/assisted-service/loginurl';
    this.http.get<string>(queryLoginUrl).subscribe(async (loginUrl) => {
      const nonce = await this.oAuthService.createAndSaveNonce();
      const redirectUri = this.winRef.location.href;
      if (!this.asmEnablerSvc.isLaunched()) {
        UrlHelper.addSearchParam(redirectUri, 'asm', 'true');
      }
      loginUrl =
        loginUrl +
        '&login_url=' +
        encodeURIComponent(redirectUri) +
        '&target_url=' +
        encodeURIComponent(redirectUri + '&state=' + encodeURIComponent(nonce));
      this.winRef.location.href = loginUrl;
    });
  }

  setLogoutStatus(): void {
    this.userIdService.clearUserId();
    this.asmEnablerSvc.removeAsmStoreStatus();
    this.amThirdPartyKeepAliveService.removeThirdTokenKeepAliveStartTime();
    CookiesHelper.deleteCookie(loginCookieFlag);
    this.store.dispatch(new AuthActions.Logout());
  }

  /* the code reference from AsmAuthService of spartacus start */
  setAsmEndSessionStatus() {
    this.userIdService.clearUserId();
    this.store.dispatch(new AuthActions.Logout());
  }

  protected canUserLogin(): boolean {
    let tokenTarget: TokenTarget | undefined;
    let token: AuthToken | undefined;

    this.authStorageService
      .getToken()
      .subscribe((tok) => (token = tok))
      .unsubscribe();
    this.authStorageService
      .getTokenTarget()
      .subscribe((tokTarget) => (tokenTarget = tokTarget))
      .unsubscribe();
    return !(Boolean(token?.access_token) && tokenTarget === TokenTarget.CSAgent);
  }

  protected warnAboutLoggedCSAgent(): void {
    this.globalMessageService.add(
      {
        key: 'asm.auth.agentLoggedInError'
      },
      GlobalMessageType.MSG_TYPE_ERROR
    );
  }

  isCSAgentLoggedIn(): Observable<boolean> {
    return combineLatest([this.authStorageService.getToken(), this.authStorageService.getTokenTarget()]).pipe(
      map(([token, tokenTarget]) => {
        return (
          Boolean(token?.access_token) && (tokenTarget === TokenTarget.User || tokenTarget === TokenTarget.CSAgent)
        );
      })
    );
  }

  /**
   * Loads a new user token with Resource Owner Password Flow when CS agent is not logged in.
   * @param userId
   * @param password
   */
  async loginWithCredentials(userId: string, password: string): Promise<void> {
    if (this.canUserLogin()) {
      await super.loginWithCredentials(userId, password);
    } else {
      this.warnAboutLoggedCSAgent();
    }
  }

  /**
   * Returns `true` if user is logged in or being emulated.
   */
  isUserLoggedIn(): Observable<boolean> {
    return combineLatest([
      this.authStorageService.getToken(),
      this.userIdService.isEmulated(),
      this.authStorageService.getTokenTarget()
    ]).pipe(
      map(([token, isEmulated, tokenTarget]) => {
        return (
          Boolean(token?.access_token) &&
          (tokenTarget === TokenTarget.User || (tokenTarget === TokenTarget.CSAgent && isEmulated))
        );
      })
    );
  }
  /** end **/

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
