import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmProfileTagLifecycleService } from '@core/cds/profiletag/services/am-profile-tag-lifecycle.service';
import { MerchantProductConverter } from '@core/product/converters/convert-merchant-product'
import { Category } from '@model/product.model';
import { MerchandisingUserContext, ProfileTagEventService } from '@spartacus/cds';
import { OccEndpointsService, Product, RoutingService, WindowRef } from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { Observable, of, race, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmlMerchandisingCarouselService {
  private sessionStorage: Storage;
  private pageType: string;
  subscription = new Subscription();
  constructor(
    private occEndpointSvc: OccEndpointsService,
    private http: HttpClient,
    private winRef: WindowRef,
    protected routingService: RoutingService,
    protected profileTagEventService: ProfileTagEventService,
    protected amProfileTagLifecycleService: AmProfileTagLifecycleService,
    protected merchantProductConverter: MerchantProductConverter
  ) {
    this.sessionStorage = this.winRef.sessionStorage;
  }

  getMerchandisingData(uid) {
    const geoCode = CookiesHelper.getCookie('geoCode');
    // if in china, the profile-tag.js shouldn't work, so
    const contextSource$ =
      !!geoCode && geoCode.toLocaleUpperCase() === 'CN' ? of(null) : this.getConsentReferenceContext();
    return race(timer(3000), contextSource$).pipe(
      switchMap((data) => {
        let url = this.occEndpointSvc.getBaseUrl() + `/cms/components/merchandising/${uid}`;
        const productCode = this.sessionStorage?.getItem('productCode');
        let headers: HttpHeaders = new HttpHeaders();
        if (!!data && !!data.consentReference) {
          headers = headers.set('consent-reference', data.consentReference);
        }
        return this.getCategoryCode(productCode).pipe(
          switchMap((category) => {
            url = this.addUrlParams(url, 'categoryCode', category?.code);
            url = this.addUrlParams(url, 'productCode', productCode);
            return this.http.get<{[key: string]: Product}>(url, { headers }).pipe(map(result => {
              return Object.keys(result).map(k => {
                return this.merchantProductConverter.convertImage(result[k])
              })
            }));
          })
        );
      })
    );
  }

  getPageNotFoundData(): Observable<Array<Product>> {
    const geoCode = CookiesHelper.getCookie('geoCode');
    // if in china, the profile-tag.js shouldn't work, so
    const contextSource$ =
      !!geoCode && geoCode.toLocaleUpperCase() === 'CN' ? of(null) : this.getConsentReferenceContext();
    return race(timer(3000), contextSource$).pipe(
      switchMap((data) => {
        let url = this.occEndpointSvc.getBaseUrl() + `/search/pageNotFound`;
        const productCode = this.sessionStorage?.getItem('productCode');
        let headers: HttpHeaders = new HttpHeaders();
        if (!!data && !!data.consentReference) {
          headers = headers.set('consent-reference', data.consentReference);
        }
        return this.getCategoryCode(productCode).pipe(
          switchMap((category) => {
            url = this.addUrlParams(url, 'categoryCode', category?.code);
            url = this.addUrlParams(url, 'productCode', productCode);

            return this.http.get<Array<Product>>(url, { headers });
          })
        );
      })
    );
  }

  getCategoryCode(productCode: string): Observable<{ code: string }> {
    let categoryCode = this.sessionStorage?.getItem('categoryCode');
    if (!!productCode) {
      return !!categoryCode ? of({ code: categoryCode }) : this.getCategoryCodeByProductCode(productCode);
    }
    return of({ code: categoryCode });
  }

  getCategoryCodeByProductCode(productCode: string): Observable<any> {
    const url = this.occEndpointSvc.getBaseUrl() + `/products/${productCode}/category`;
    return this.http.get<Category>(url).pipe(
      tap((category) => {
        this.sessionStorage?.setItem('categoryCode', category.code);
      })
    );
  }

  addUrlParams(url: string, key: string, value: string): string {
    if (!!value) {
      return url.includes('?') ? (url += `&${key}=${value}`) : (url += `?${key}=${value}`);
    }
    return url;
  }

  getPageType(): string {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((route) => {
        this.pageType = route.state.semanticRoute;
      })
    );
    return this.pageType;
  }

  private getConsentReferenceContext(): Observable<MerchandisingUserContext> {
    return this.profileTagEventService.getConsentReference().pipe(
      // startWith(''),
      distinctUntilChanged(),
      map((consentReference) => ({ consentReference }))
    );
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
