<h2>{{ 'amGeneralError.title' | cxTranslate }}</h2>

<div class="general-error">
  <p>
    {{ 'amGeneralError.content1' | cxTranslate }}
    <a href="#">
      {{ 'amGeneralError.link' | cxTranslate }}
    </a>
    {{ 'amGeneralError.content2' | cxTranslate }}
  </p>

  <a class="button-secondary" role="button" href="javascript:void(0)" (click)="goBackHome()">
    {{ 'amGeneralError.backHome' | cxTranslate }}
  </a>
</div>
