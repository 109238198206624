import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { ShoppingTipModule } from '@components/layout/header/shopping-tip/shopping-tip.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { PopoverModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { InflightSiteContextSelectorPopupComponent } from './inflight-site-context-selector-popup.component';

// export const inflightSiteContextDialogLayoutConfig: LayoutConfig = {
//   launch: {
//     INFLIGHT_SELECT_SITE_CONTEXT: {
//       inlineRoot: true,
//       component: InflightSiteContextSelectorPopupComponent,
//       dialogType: DIALOG_TYPE.DIALOG
//     }
//   }
// };

@NgModule({
  declarations: [InflightSiteContextSelectorPopupComponent],
  imports: [
    CommonModule,
    I18nModule,
    NgSelectModule,
    FormsModule,
    PopoverModule,
    MessageTipModule,
    AmMediaModule,
    SafeCodeModule,
    SvgContainerModule,
    ShoppingTipModule
  ],
  exports: [InflightSiteContextSelectorPopupComponent]
})
export class InflightSiteContextSelectorPopupModule {}
