import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { LanguageService, OccConfig, OccEndpointsService, WindowRef } from '@spartacus/core';
import { combineLatest, fromEvent, interval, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { VerachatService } from '../verachat/verachat.service';

interface ChatItem {
  chatWidgetIcon: string;
  chatWidgetHypertext: string;
  chatWidgetHyperlink: string;
  langCode: string;
  veraChatEnable: boolean;
}

@Component({
  selector: 'am-livechat',
  templateUrl: './livechat.component.html'
})
export class LivechatComponent implements OnInit, OnDestroy {
  window: any = this.winRef.nativeWindow ?? {};
  svgType = svgConfig;
  style_chat_svg_icon = ' width: 30px; height: 30px; ';
  style_svg_icon = ' width: 16px; height: 16px; ';

  showLiveChat: boolean = false;
  showBackToTopBtn: boolean = false;
  userLogin$: Subscription;
  scrollSub$: Subscription;
  checkSDK$: Subscription;
  chatData: ChatItem = null;
  isBrowser: boolean = false;

  hk_row_link = 'https://api.whatsapp.com/send/?phone=85227472747&text&type=phone_number&app_absent=0';
  tw_link = 'https://api.whatsapp.com/send/?phone=85227472747&text&type=phone_number&app_absent=0';
  cn_link = 'https://www.cathaypacific.com/cx/sc_CN/contact-us.html ';
  jp_link = 'https://lin.ee/gSwA0GH ';
  kr_link = 'https://pf.kakao.com/_xnCQUK';

  svg_icon = this.svgType.WHATSAPP;
  svg_desc = '';
  chat_link = this.hk_row_link;
  enable_vera = false;
  veraSDKFlag = false;
  

  constructor(
    protected languageService: LanguageService,
    protected authService: AmAuthService,
    protected winRef: WindowRef,
    protected cdr: ChangeDetectorRef,
    protected occEndpointSvc: OccEndpointsService,
    protected occConfig: OccConfig,
    protected httpClient: HttpClient,
    protected amConfigService: AmConfigService,

    protected verachatService: VerachatService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.openChatDialog = this.openChatDialog.bind(this);
    this.closeChatDialog = this.closeChatDialog.bind(this);
  }

  scrollToTop(): void {
    this.window?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openChatDialog() {
    this.verachatService.openVeraChat();
  }

  closeChatDialog() {
    this.verachatService.closeVeraChat();
  }

  ngOnInit(): void {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.addScrollEvent();
    this.setLiveChatDisplayStatus();
  }
  protected subscription = new Subscription();
  addScrollEvent() {
    if (this.isBrowser) {
      this.scrollSub$ = fromEvent(this.window, 'scroll').subscribe((event) => {
        if (this.window.scrollY >= 600) {
          this.showBackToTopBtn = true;
          this.cdr.detectChanges();
        } else {
          this.showBackToTopBtn = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  setLiveChatDisplayStatus() {
    this.subscription.add(
      combineLatest([
        this.authService.isUserLoggedIn(),
        this.amConfigService.getCommonConfig().pipe(filter((data) => data.loading !== true))
      ])
        .pipe(
          distinctUntilChanged((prev, current) => {
            return prev[0] === current[0];
          })
        )
        .subscribe(([isUserLoggedIn, config]) => {
          this.showLiveChat = false;
          if (isUserLoggedIn && config.inflight !== true) {
            this.getLiveChatData();
            this.showLiveChat = true;
            if (config.veraChatEnable) {
              this.enable_vera = true;
            }
          }
        })
    );
  }

  ngAfterViewInit() {}

  getLiveChatData() {
    const url = this.occEndpointSvc.buildUrl('getLiveChatConfig');
    this.subscription.add(
      combineLatest([this.httpClient.get<Array<ChatItem>>(url), this.languageService.getActive()]).subscribe(
        ([data, currentLanguage]) => {
          this.chatData = null;
          const currentChatData = data.filter((item) => item.langCode === currentLanguage);
          if (currentChatData && currentChatData.length > 0) {
            this.chatData = {
              ...currentChatData[0],
              chatWidgetIcon: this.getBaseUrl() + currentChatData[0].chatWidgetIcon
            };
            if (this.chatData.veraChatEnable) {
              this.checkVeraSDK();
            }
          }

          if (!this.enable_vera) {
            switch (currentLanguage) {
              case 'zh_TW':
                this.svg_icon = this.svgType.WHATSAPP;
                this.svg_desc = 'whats app';
                this.chat_link = this.tw_link;
                return;
              case 'zh':
                this.svg_icon = this.svgType.WECHAT;
                this.svg_desc = 'wechat';
                this.chat_link = this.cn_link;
                return;
              case 'ja':
                this.svg_icon = this.svgType.LINE;
                this.svg_desc = 'line';
                this.chat_link = this.jp_link;
                return;
              case 'ko':
                this.svg_icon = this.svgType.KAKAO;
                this.svg_desc = 'kakao';
                this.chat_link = this.kr_link;
                return;
              default:
                this.svg_icon = this.svgType.WHATSAPP;
                this.svg_desc = 'whats app';
                this.chat_link = this.hk_row_link;
                return;
            }
          }

          this.closeChatDialog();
        }
      )
    );
  }

  checkVeraSDK() {
    this.checkSDK$ = interval(1000).pipe(
      takeUntil(timer(30000))
    ).subscribe(() => {
      if (this.window.CXChatFrameworkSDK && this.window.cxDataLayer?.application) {
        this.veraSDKFlag = true;
        this.cdr.detectChanges();
        this.checkSDK$?.unsubscribe();
      }
    });
  }

  getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  ngOnDestroy(): void {
    this.userLogin$?.unsubscribe();
    this.scrollSub$?.unsubscribe();
    this.checkSDK$?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
