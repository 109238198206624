import { Component, OnInit } from '@angular/core';
import { DayjsService } from '@components/content/format/dayjs.service';
import { SiteContextType } from '@config/am-constant.config';
import { AmConsentService } from '@core/user/consents/services/am-consents.service';
import { CmsService, WindowRef } from '@spartacus/core';
import { CmsComponentData, NavigationService, SiteContextComponentService } from '@spartacus/storefront';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CxDataLayerService } from 'src/app/amiredeem/common/data-layer/dataLayer.service';
import { FlightInfoService } from '../header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '../header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '../header/am-site-context/services/country/country.service';
import { GlobalPopupService } from '../launch-dialog/global-dialog/service/global-popup.service';
import { CookieBarService } from './cookie-bar/cookie-bar.service';
import { VerachatService } from './verachat/verachat.service';

@Component({
  selector: 'am-site-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  currencyContext = SiteContextType.CURRENCY;
  constructor(
    protected componentData: CmsComponentData<any>,
    protected navigationService: NavigationService,
    protected cmsService: CmsService,
    protected cxDataLayerService: CxDataLayerService,
    protected cookieBarService: CookieBarService,
    protected amConsentService: AmConsentService,
    protected dayjsService: DayjsService,
    protected countryService: CountryService,
    protected globalPopupService: GlobalPopupService,
    protected flightInfoService: FlightInfoService,
    protected siteContextService: SiteContextComponentService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected verachatService: VerachatService,
    protected winRef: WindowRef
  ) {
    this.flightInfoService.startFlightInfoTimer();
  }

  data$ = this.componentData.data$;
  activeCountry$ = this.countryService.getActive();
  geoLocation = CookiesHelper.getCookie('geoLocation');
  isFlightPickUpFlow = this.flightStatusStorageService.isInflightPickFlow;

  navigationItems$: Observable<any> = this.componentData.data$.pipe(
    map((componentData) => {
      const itemIds = [];
      if (componentData?.navigationNode?.children?.length > 0) {
        const childrens = componentData.navigationNode.children[0]?.children;
        !!childrens &&
          childrens.forEach((child) => {
            itemIds.push(child.entries[0].itemId);
          });
      }
      return itemIds;
    }),
    switchMap((itemIds: []) =>
      combineLatest(
        itemIds.map((component) =>
          this.cmsService.getComponentData<any>(component).pipe(
            switchMap((navigationItem) => {
              return this.activeCountry$.pipe(
                map((activeCountry) => {
                  return { ...navigationItem, geoLocation: this.geoLocation, country: activeCountry };
                })
              );
            }),
            map((navigationItem) => {
              if (navigationItem.geoLocations) {
                const geoLocationArr = navigationItem.geoLocations.split(' ');
                if (!this.geoLocation || navigationItem.geoLocation.indexOf(geoLocationArr) === -1) {
                  return undefined;
                }
              }

              if (navigationItem.countries) {
                const countries = Object.keys(navigationItem.countries);
                if (countries.indexOf(navigationItem.country) === -1) {
                  return undefined;
                }
              }

              if (!navigationItem) {
                return undefined;
              }
              return navigationItem;
            })
          )
        )
      )
    )
  );

  socialItems$: Observable<Observable<any>[]> = this.componentData.data$.pipe(
    filter((data) => !!data.socialMedias),
    map((data) => data.socialMedias.trim().split(' ')),
    map((codes) => codes.map((code) => this.cmsService.getComponentData(code)))
  );

  styleClass$: Observable<string> = this.componentData.data$.pipe(map((d) => d?.styleClass));
  protected subscription = new Subscription();
  ngOnInit(): void {
    this.cxDataLayerService.listenPageChange();
    this.cookieBarService.addCookieBarLib();
    this.verachatService.addVeraChatLib();
    this.subscription.add(this.amConsentService.getTemplates().subscribe());
    this.subscription.add(this.dayjsService.initDayjs().subscribe());
    this.globalPopupService.checkIfDisplayPopup();
  }

  setAriaLabel(navigationItems, index, currectNode): String {
    if (this.checkIsIOS(this.winRef.nativeWindow)) {
      return 'link ' + (index + 1) + '/' + navigationItems.length + ', ' + currectNode?.linkName;
    }
  }

  checkIsIOS($window) {
    if ($window) {
      let regxStr = $window['navigator']['userAgent'];
      return /iPhone|iPod|iPad/.test(regxStr);
    }
  }
}
