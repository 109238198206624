import { DeliveryAddressInfo } from '../components/model/deliveryAddressInfo';

export const AM_CHECKOUT_FEATURE = 'amCheckout';

export interface DeliveryAddressesState {
  deliveyAndAddressInfo: DeliveryAddressInfo;
  activeAddressCode: string;
}

export interface AmCheckoutState {
  shippingAddress: DeliveryAddressesState;
}

export interface StateWithAmCheckout {
  [AM_CHECKOUT_FEATURE]: AmCheckoutState;
}
