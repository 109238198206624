<div class="loading-img">
  <div class="cx_loading-icon">
    <div class="cx_primary-path"></div>
    <div class="cx_secondary-path">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
