<h2>{{ 'amBalanceError.title' | cxTranslate }}</h2>
<div class="balance-error">
  <p class="balance-error-message">
    {{ 'amBalanceError.content' | cxTranslate }}
  </p>

  <div class="enough">
    <div class="enough-required">
      <p class="font-bold">
        {{ 'amBalanceError.required' | cxTranslate }}
      </p>
      <div class="enough-number">
        <span class="am-icon_grey_gold"></span>
        <span class="font-bold" *ngIf="cart$ | async as cart">
          {{ cart.totalPrice?.value }}
        </span>
      </div>
    </div>
    <div class="enough-operation">
      <span></span>
    </div>
    <div class="enough-balance bottom">
      <p class="font-bold">
        {{ 'amBalanceError.balance' | cxTranslate }}
      </p>
      <div class="enough-number">
        <span class="minus"></span>
        <span class="am-icon_grey_gold"></span>
        <span class="font-bold" *ngIf="user$ | async as user">
          {{ user.customerRecord?.account | number }}
        </span>
      </div>
    </div>
    <div class="enough-operation-equal">
      <span></span>
      <span></span>
    </div>
    <div class="enough-still">
      <p class="font-bold">
        {{ 'amBalanceError.stillRequire' | cxTranslate }}
      </p>
      <div class="enough-number">
        <span class="am-icon_grey_gold"></span>
        <span class="font-bold" *ngIf="cart$ | async as cart">
          <ng-container *ngIf="user$ | async as user">
            {{ cart.totalPrice?.value - user.customerRecord?.account | number }}
          </ng-container>
        </span>
      </div>
    </div>
  </div>

  <a class="button-secondary" role="button" href="javascript:void(0)" (click)="goBackHome()">
    {{ 'amGeneralError.backHome' | cxTranslate }}
  </a>
</div>
