import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { Product } from '@spartacus/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-offer-details-popup',
  templateUrl: './offer-details-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferDetailsPopupComponent implements OnInit, OnDestroy {
  product: Product;
  source = 'product';
  activeTab = '';
  multiple: boolean;
  scrollHeight: number;
  tabCount: number = 0;
  pageFrom: string = '';
  activeCodeObj = {
    discountTag: '',
    freeGiftTag: '',
    bonusTag: ''
  };
  svgType = svgConfig;
  style_icon_close = 'width: 38px; height: 38px';

  subscription: Subscription = new Subscription();

  constructor(protected launchDialogService: AmLaunchDialogService, private el: ElementRef) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        if (!!data) {
          const { product, promoPopName, code, pageFrom } = data;
          this.product = product;

          if (pageFrom !== 'cartPage') {
            let newDiscount = this.product?.discountRows?.filter((discount) => {
              return discount.showOfferMessage;
            });
            let newPotentialDiscountRules = this.product?.potentialDiscountRules?.filter((discount) => {
              if (typeof discount.showOfferMessage === 'boolean') {
                return discount.showOfferMessage;
              } else {
                return true;
              }
            });
            this.product = {
              ...this.product,
              discountRows: newDiscount,
              potentialDiscountRules: newPotentialDiscountRules
            };
          }
          this.activeTab = promoPopName;
          this.pageFrom = pageFrom;
          this.activeCodeObj[this.activeTab] = code;
          // discount promotion
          if (this.product?.discountRows?.length > 0 || this.product?.potentialDiscountRules?.length > 0) {
            this.tabCount++;
          }
          // bonus promotion
          if (this.product?.potentialBonusRules?.length > 0) {
            this.tabCount++;
          }
          // free gift promotion(buyAgetB/threshold)
          if (
            this.product?.potentialFreeGiftBuyAGetBRules?.length > 0 ||
            this.product?.potentialFreeGiftThresholdRules?.length > 0
          ) {
            this.tabCount++;
          }
        }
      })
    );
  }

  setActiveTab(name): void {
    this.activeTab = name;
  }

  afterSelectedPromotion(activeCode) {
    this.activeCodeObj[this.activeTab] = activeCode;
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  isMultipleItems(obj1?: any, obj2?: any): boolean {
    let items = 0;
    if (obj1) {
      items = items + obj1.length;
    }
    if (obj2) {
      items = items + obj2.length;
    }
    return items > 1 ? (this.multiple = true) : (this.multiple = false);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
