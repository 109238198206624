import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

export interface HeadingsModel {
  uid: string;
  uuid: string;
  typeCode: string;
  name: string;
  modifiedtime: string;
  headingType: string;
  content: string;
  container: string;
}

@Component({
  selector: 'am-headings',
  templateUrl: './headings.component.html',
  styleUrls: ['./headings.component.scss']
})
export class HeadingsComponent implements OnInit {
  headingsDetais$: Observable<HeadingsModel> = this.componentData.data$;

  headingsType = {
    Heading1: 'Heading1',
    Heading2: 'Heading2',
    Heading3: 'Heading3',
    Heading4: 'Heading4',
    Heading5: 'Heading5'
  };

  constructor(public componentData: CmsComponentData<any>) {}

  ngOnInit(): void {}
}
