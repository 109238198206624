import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  DEFAULT_SCOPE,
  OccEndpointsService,
  Product,
  ProductActions,
  ProductLoadingService,
  ProductScope,
  ProductSelectors,
  ProductService,
  RoutingService,
  StateWithProduct
} from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService extends ProductService {
  protected products: {
    [code: string]: { [scope: string]: Observable<any> };
  } = {};

  private loadProductSelectedQuantitySub: BehaviorSubject<number> = new BehaviorSubject(null);
  public loadProductSelectedQuantityObs: Observable<number> = this.loadProductSelectedQuantitySub.asObservable();
  private loadProductSelectedMilesSub: BehaviorSubject<number> = new BehaviorSubject(null);
  public loadProductSelectedMilesObs: Observable<number> = this.loadProductSelectedMilesSub.asObservable();
  private loadProductSelectedCashSub: BehaviorSubject<number> = new BehaviorSubject(null);
  public loadProductSelectedCashObs: Observable<number> = this.loadProductSelectedCashSub.asObservable();

  // private loadShopStatusSub: BehaviorSubject<boolean> = new BehaviorSubject(null);
  // public loadShopStatusSubObs: Observable<boolean> = this.loadShopStatusSub.asObservable();
  constructor(
    protected store: Store<StateWithProduct>,
    protected productLoading: ProductLoadingService,
    protected routingService: RoutingService,
    private occEndpointSvc: OccEndpointsService,
    private http: HttpClient
  ) {
    super(store, productLoading);
  }

  /**
   * Returns the product observable. The product will be loaded
   * whenever there's no value observed.
   *
   * The underlying product loader ensures that the product is
   * only loaded once, even in case of parallel observers.
   *
   * You should provide product data scope you are interested in to not load all
   * the data if not needed. You can provide more than one scope.
   *
   * @param productCode Product code to load
   * @param scopes Scope or scopes of the product data
   */
  get(
    productCode: string,
    scopes: (ProductScope | string)[] | ProductScope | string = DEFAULT_SCOPE
  ): Observable<Product> {
    return productCode ? this.productLoading.get(productCode, [].concat(scopes)) : of(undefined);
  }

  /**
   * Returns boolean observable for product's loading state
   */
  isLoading(productCode: string, scope: ProductScope | string = ''): Observable<boolean> {
    return this.store.pipe(select(ProductSelectors.getSelectedProductLoadingFactory(productCode, scope)));
  }

  /**
   * Returns boolean observable for product's load success state
   */
  isSuccess(productCode: string, scope: ProductScope | string = ''): Observable<boolean> {
    return this.store.pipe(select(ProductSelectors.getSelectedProductSuccessFactory(productCode, scope)));
  }

  /**
   * Returns boolean observable for product's load error state
   */
  hasError(productCode: string, scope: ProductScope | string = ''): Observable<boolean> {
    return this.store.pipe(select(ProductSelectors.getSelectedProductErrorFactory(productCode, scope)));
  }

  getCode(): Observable<string> {
    return this.routingService.getRouterState().pipe(map((state) => state.state.params['productCode']));
  }

  changeQuantity(quantity: number): void {
    this.loadProductSelectedQuantitySub.next(quantity);
  }

  changeSelectionMiles(selectionMiles: number): void {
    this.loadProductSelectedMilesSub.next(selectionMiles);
  }

  changeSelectionCash(selectionCash: number): void {
    this.loadProductSelectedCashSub.next(selectionCash);
  }

  getLagFlag(productCode: string): Observable<{ data: boolean }> {
    const url = this.occEndpointSvc.buildUrl('getLagFlag', { urlParams: { productCode } });
    return this.http.get<{ data: boolean }>(url).pipe((data) => {
      return data;
    });
  }

  reloadProduct(productCode: string, scope = ProductScope.DETAILS) {
    this.isLoading(productCode, scope)
      .pipe(
        tap((loading) => {
          if (!loading) {
            this.store.dispatch(new ProductActions.LoadProduct(productCode, scope));
          }
        })
      )
      .subscribe()
      .unsubscribe();
  }
}
