export const YOUTUBE_API_SCRIPT = 'https://www.youtube.com/iframe_api';
export const DAILYMOTION_API_SCRIPT = 'https://geo.dailymotion.com/libs/player/';
export const YOUKU_API_SCRIPT = 'https://player.youku.com/jsapi';
export const adobeScriptMap = {
  dev: '//assets.adobedtm.com/launch-ENd0747039e5304f83bf975c27b99d8574-development.min.js',
  sit: '//assets.adobedtm.com/launch-ENd0747039e5304f83bf975c27b99d8574-development.min.js',
  prod: '//assets.adobedtm.com/launch-ENc8411a417b5a47018e1fc52879edd3a6.min.js'
};
export const GOOGLE_MAP_API = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB8q8LKAvhFWPhSBs85FwtLghCvqTN6Vsc';
export const GOOGLE_MAP_COMMON_JS = 'https://maps.googleapis.com/maps-api-v3/api/js/48/10/common.js';
