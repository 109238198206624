import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { SiteContextState } from '../state';
import * as fromCountries from './countries.reducer';
import * as fromSearch from './search.reducer';

export function getReducers(): ActionReducerMap<SiteContextState> {
  return {
    countries: fromCountries.reducer,
    search: fromSearch.reducer
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<SiteContextState>> = new InjectionToken<
  ActionReducerMap<SiteContextState>
>('SiteContextReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers
};
