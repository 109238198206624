import { ChangeDetectorRef, Component } from '@angular/core';
import { AmAsmComponentService } from '@core/asm/root/services/am-asm-component.service';
import { AmOAuthLibWrapperService } from '@core/auth/user-auth/services/am-oauth-wrapper.service';
import { AsmSessionTimerComponent } from '@spartacus/asm/components';
import { AsmConfig } from '@spartacus/asm/root';
import { RoutingService, UserIdService } from '@spartacus/core';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';

@Component({
  selector: 'am-asm-session-timer',
  templateUrl: './am-asm-session-timer.component.html',
  styleUrls: ['./am-asm-session-timer.component.scss']
})
export class AmAsmSessionTimerComponent extends AsmSessionTimerComponent {
  configTimeOut: number = 0;

  constructor(
    protected config: AsmConfig,
    protected asmComponentService: AmAsmComponentService,
    protected routingService: RoutingService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected amConfigService: AmConfigService,
    protected userIdService: UserIdService,
    protected oauthLibWrapperService: AmOAuthLibWrapperService
  ) {
    super(config, asmComponentService, routingService, changeDetectorRef, userIdService);
    this.subscriptions.add(
      this.amConfigService.getCommonConfig().subscribe((data) => {
        this.configTimeOut = data.asmSessionTimer;
      })
    );
  }

  protected getTimerStartDelayInSeconds(): number {
    const configTimeOut = this.configTimeOut ?? this.config.asm?.agentSessionTimer?.startingDelayInSeconds;
    if (configTimeOut === undefined) {
      return 600;
    }
    if (configTimeOut > this.maxStartDelayInSeconds) {
      return this.maxStartDelayInSeconds;
    } else {
      return configTimeOut;
    }
  }

  // ngOnInit(): void {
  //   this.timeLeft = this.getTimerStartDelayInSeconds();
  //   this.interval = setInterval(() => {
  //     if (this.timeLeft > 0) {
  //       this.timeLeft--;
  //     } else {
  //       clearInterval(this.interval);
  //       this.asmComponentService.logoutCustomerSupportAgentAndCustomer();
  //     }
  //     this.changeDetectorRef.markForCheck();
  //   }, 1000);

  //   this.resetOnNavigate();
  //   this.resetOnCustomerSessionChange();
  // }

  refreshTokenAndResetTimer() {
    this.oauthLibWrapperService.refreshToken();
    this.resetTimer();
  }
}
