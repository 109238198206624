import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ConsentRecord, ConsentTemplate } from '../../model/consent.model';
import {
  AmConsentsState,
  AmConsentsTemplateState,
  AM_CONSENTS_STORE_FEATURE,
  StateWithAmConsents
} from '../am-consents-state';

export const getAmConsentState: MemoizedSelector<StateWithAmConsents, AmConsentsState> =
  createFeatureSelector<AmConsentsState>(AM_CONSENTS_STORE_FEATURE);

export const getAmConsentsTemplateState: MemoizedSelector<StateWithAmConsents, AmConsentsTemplateState> =
  createSelector(getAmConsentState, (state: AmConsentsState) => state.amConsents);

export const getConsentTemplates: MemoizedSelector<StateWithAmConsents, ConsentTemplate[]> = createSelector(
  getAmConsentsTemplateState,
  (state: AmConsentsTemplateState) => state.templates
);

export const getConsentRecords: MemoizedSelector<StateWithAmConsents, ConsentRecord[]> = createSelector(
  getAmConsentsTemplateState,
  (state: AmConsentsTemplateState) => state.consents
);

export const getConsentByTemplateId = (templateId: string): MemoizedSelector<StateWithAmConsents, ConsentRecord> =>
  createSelector(getConsentRecords, (consentRecords) =>
    consentRecords.find((consentItem) => consentItem.templateCode === templateId)
  );
