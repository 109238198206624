import { Action } from '@ngrx/store';
import { HttpErrorModel } from '@spartacus/core'

export const LOAD_HOT_TAGS = '[Site-context] Load Hot Tags';
export const LOAD_HOT_TAGS_FAIL = '[Site-context] Load Hot Tags Fail';
export const LOAD_HOT_TAGS_SUCCESS = '[Site-context] Load Hot Tags Success';

export class LoadHotTags implements Action {
  readonly type = LOAD_HOT_TAGS;
}

export class LoadHotTagsFail implements Action {
  readonly type = LOAD_HOT_TAGS_FAIL;
  constructor(public payload: HttpErrorModel) {
    this.payload = payload;
  }
}

export class LoadHotTagsSuccess implements Action {
  readonly type = LOAD_HOT_TAGS_SUCCESS;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

// action types
export type SearchAction =
  | LoadHotTags
  | LoadHotTagsFail
  | LoadHotTagsSuccess
