import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { CartOfferDetailsDialogModule } from '../../cart-offer-details-dialog/cart-offer-details-dialog.module';
import { DeliveryFeeDialogComponent } from './delivery-fee-dialog.component';

@NgModule({
  imports: [CommonModule, I18nModule, IconModule, SafeCodeModule, CartOfferDetailsDialogModule, KeyboardFocusModule],
  declarations: [DeliveryFeeDialogComponent],
  exports: [DeliveryFeeDialogComponent]
})
export class DeliveryFeeDialogModule {}
