import { NgModule } from '@angular/core';
import {
  CartConnector,
  CartEntryConnector,
  CartPersistenceModule,
  CartValidationConnector,
  CartVoucherConnector
} from '@spartacus/cart/base/core';
import { HttpErrorHandler } from '@spartacus/core';
import { BadCartRequestHandler } from 'src/app/amiredeem/pages/cart/request/bad-cart-request.handler';
import { BadVoucherRequestHandler } from 'src/app/amiredeem/pages/cart/request/bad-voucher-request.handler';
import { AmMultiCartStoreModule } from 'src/app/amiredeem/pages/cart/store/am-multi-cart-store.module';
import { facadeProviders } from '../service/facade-providers';

@NgModule({
  imports: [AmMultiCartStoreModule, CartPersistenceModule],
  providers: [
    CartConnector,
    CartEntryConnector,
    CartVoucherConnector,
    CartValidationConnector,
    ...facadeProviders,
    {
      provide: HttpErrorHandler,
      useExisting: BadCartRequestHandler,
      multi: true
    },
    {
      provide: HttpErrorHandler,
      useExisting: BadVoucherRequestHandler,
      multi: true
    }
  ]
})
export class AmCartBaseCoreModule {}
