import { Injectable } from '@angular/core';
import { UserIdService, WindowRef } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, HamburgerMenuService } from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FlightStatusStorageService } from '../am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AmlHeaderService {
  protected subscription = new Subscription();
  constructor(
    private hamburgerMenuService: HamburgerMenuService,
    protected userIdService: UserIdService,
    protected breakpointService: BreakpointService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected winRef: WindowRef
  ) {
    this.subscription.add(
      this.breakpointService.isEqual(BREAKPOINT.xs).subscribe((d) => {
        if (d) {
          this.deliveryAddStyle();
        } else {
          // Not mobile close popup
          this.hamburgerMenuService.toggle(true);
          const navigationBarContainer = this.winRef.document.querySelector('.am-navigation-bar-container') as any;
          if (navigationBarContainer) {
            navigationBarContainer.style = '';
          }
        }
      })
    );
  }

  isShowAccountInfo: BehaviorSubject<boolean> = new BehaviorSubject(false);

  toggleAccountInfo(): void {
    this.isShowAccountInfo.next(!this.isShowAccountInfo.value);
    if (this.isShowAccountInfo.value) {
      this.hamburgerMenuService.isExpanded.next(false);
    }
  }
  OnDestroy() {
    this.isShowAccountInfo.complete();
    this.subscription?.unsubscribe();
  }

  deliveryAddStyle() {
    if (this.hamburgerMenuService.isExpanded.value) {
      this.winRef.isBrowser() &&
        setTimeout(() => {
          const navigation = this.winRef.document?.querySelector('cx-page-layout.navigation');
          let height = '0px';
          if (this.flightStatusStorageService.getInFlightIndicator()) {
            height = '211px';
          } else {
            height = '116px';
          }
          const navigationBarContainer = navigation?.querySelector('.am-navigation-bar-container') as any;
          navigationBarContainer.style.paddingBottom = height;
        }, 200);
    }
  }
}
