import { Component, OnDestroy, OnInit } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { OccConfig, WindowRef } from '@spartacus/core';
import { CmsComponentData, MediaContainer } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

export interface LrpHeaderBannerModel {
  container: string;
  description: string;
  link: LinkModel;
  media: any;
  modifiedtime: string;
  name: string;
  title: string;
  typeCode: string;
  uid: string;
  uuid: string;
}

export interface LinkModel {
  container: string;
  display: string;
  external: string;
  linkName: string;
  localizedUrl: string;
  name: string;
  newTab: string;
  target: string;
  typeCode: string;
  uid: string;
  uuid: string;
}
@Component({
  selector: 'am-lrp-header-banner',
  templateUrl: './lrp-header-banner.component.html',
  styleUrls: ['./lrp-header-banner.component.scss']
})
export class LrpHeaderBannerComponent implements OnInit, OnDestroy {
  mediaData: Array<MediaContainer> = [];
  lrpHeaderBannerData: LrpHeaderBannerModel;
  svgType = svgConfig;
  constructor(
    public componentData: CmsComponentData<any>,
    protected occConfig: OccConfig,
    protected winRef: WindowRef
  ) {}
  subscription = new Subscription();
  ngOnInit(): void {
    this.subscription.add(
      this.componentData.data$.subscribe((data: LrpHeaderBannerModel) => {
        if (!data) {
          return;
        }
        this.lrpHeaderBannerData = data;
        this.mediaData.push(this.getImageContainer(data.media));
      })
    );
  }

  getImageContainer(media): MediaContainer {
    const mediaContainer: MediaContainer = {};
    if (!media) {
      return;
    }
    if (media.desktop && media.desktop?.url) {
      mediaContainer.desktop = {
        url: this.getBaseUrl() + media.desktop.url
      };
    }
    if (media.mobile && media.mobile?.url) {
      mediaContainer.mobile = {
        url: this.getBaseUrl() + media.mobile.url
      };
    }
    if (media.tablet && media.tablet?.url) {
      mediaContainer.tablet = {
        url: this.getBaseUrl() + media.tablet.url
      };
    }
    if (media.widescreen && media.widescreen?.url) {
      mediaContainer.widescreen = {
        url: this.getBaseUrl() + media.widescreen.url
      };
    }
    return mediaContainer;
  }

  protected getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  checkIfContainDomain(url: string): string {
    if (!url) {
      return 'javascript:void(0)';
    }

    if (url.indexOf('http') !== -1) {
      return url;
    } else {
      return this.winRef.location.origin + url;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
