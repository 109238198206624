<ng-container *ngIf="media">
  <!-- not use lazy loading images -->

  <ng-container *ngIf="!useLazyLoading">
    <ng-container *ngIf="media?.src && !isMissing">
      <ng-container>
        <picture *ngIf="responsiveImage">
          <source *ngIf="!!media.mobile" media="(max-width: 767.98px)" [attr.srcset]="media.mobile" />
          <source *ngIf="!!media.tablet" media="(max-width: 991.98px)" [attr.srcset]="media.tablet" />
          <source *ngIf="!!media.desktop" media="(max-width: 1200px)" [attr.srcset]="media.desktop" />
          <img
            [attr.src]="media.src"
            [attr.loading]="lazy"
            [attr.alt]="media.alt ?? 'picture ' + uuidRef"
            [attr.role]="media.role"
            (load)="loadHandler()"
            (error)="errorHandler()" />
        </picture>

        <img
          *ngIf="!responsiveImage && media?.src"
          [attr.src]="media.src"
          [attr.alt]="media.alt ?? 'picture ' + uuidRef"
          [attr.role]="media.role"
          [attr.loading]="lazy"
          (load)="loadHandler()"
          (error)="errorHandler()" />
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(!media?.src && !media?.srcset) || isMissing">
      <img
        class="missing-image"
        aria-hidden="true"
        [attr.srcset]="media?.srcset"
        [attr.alt]="'picture ' + uuidRef"
        [attr.role]="media?.role"
        [attr.loading]="lazy"
        [src]="'amImg.missing' | cxTranslate" />
    </ng-container>
  </ng-container>

  <!-- use lazy loading images  -->
  <ng-container *ngIf="useLazyLoading">
    <ng-container *ngIf="winRef.isBrowser() && (isInflight$ | async); else DefaultImage">
      <ng-container></ng-container>
      <ng-container *ngIf="responsiveImage">
        <picture>
          <source
            *ngIf="!!media.mobile"
            media="(max-width: 767.98px)"
            [attr.lazyLoad]="media.mobile"
            [attr.errorImage]="'amImg.missing' | cxTranslate" />
          <source
            *ngIf="!!media.tablet"
            media="(max-width: 991.98px)"
            [attr.lazyLoad]="media.tablet"
            [attr.errorImage]="'amImg.missing' | cxTranslate" />
          <source
            *ngIf="!!media.desktop"
            media="(max-width: 1200px)"
            [attr.lazyLoad]="media.desktop"
            [attr.errorImage]="'amImg.missing' | cxTranslate" />
          <img
            [defaultImage]="defaultImage"
            [errorImage]="'amImg.missing' | cxTranslate"
            [attr.alt]="media.alt ?? 'picture ' + uuidRef"
            [attr.role]="media.role"
            [scrollTarget]="scrollTarget"
            [offset]="offset"
            [transitionEvent]="transitionEvent"
            (onStateChange)="onStateChange($event)"
            [lazyLoad]="media.src"
            [threshold]="threshold" />
        </picture>
      </ng-container>
      <ng-container *ngIf="!responsiveImage">
        <img
          [defaultImage]="defaultImage"
          [errorImage]="'amImg.missing' | cxTranslate"
          [attr.alt]="media.alt ?? 'picture ' + uuidRef"
          [attr.role]="media.role"
          [scrollTarget]="scrollTarget"
          [offset]="offset"
          [transitionEvent]="transitionEvent"
          (onStateChange)="onStateChange($event)"
          [lazyLoad]="media.srcset || media.src"
          [threshold]="threshold"
          [useSrcset]="!!media.srcset" />
      </ng-container>
    </ng-container>
    <!-- server side uses the default image -->
    <ng-template #DefaultImage>
      <img
        aria-hidden="true"
        [attr.alt]="'picture ' + uuidRef"
        [attr.role]="media?.role"
        [attr.loading]="lazy"
        [src]="defaultImage" />
    </ng-template>
  </ng-container>
</ng-container>

<ng-container *ngIf="!media">
  <img
    class="missing-image"
    aria-hidden="true"
    [attr.srcset]="media?.srcset"
    [attr.alt]="'picture ' + uuidRef"
    [attr.role]="media?.role"
    [attr.loading]="lazy"
    [src]="'amImg.missing' | cxTranslate" />
</ng-container>
