<ng-container *ngIf="headingsDetais$ | async as headingsDetais">
  <div class="headings-container">
    <ng-container *ngIf="headingsDetais.headingType === headingsType.Heading1">
      <h1>{{ headingsDetais.content }}</h1></ng-container
    >
    <ng-container *ngIf="headingsDetais.headingType === headingsType.Heading2">
      <h2>{{ headingsDetais.content }}</h2></ng-container
    >
    <ng-container *ngIf="headingsDetais.headingType === headingsType.Heading3">
      <h3>{{ headingsDetais.content }}</h3></ng-container
    >
    <ng-container *ngIf="headingsDetais.headingType === headingsType.Heading4">
      <h4>{{ headingsDetais.content }}</h4></ng-container
    >
    <ng-container *ngIf="headingsDetais.headingType === headingsType.Heading5">
      <h5>{{ headingsDetais.content }}</h5></ng-container
    >
  </div>
</ng-container>
