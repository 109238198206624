export enum ErrorType {
  'basket.information.quantity.adjusted' = 'amErrorMapping.quantityAdjusted',
  'basket.information.quantity.noItemsAdded.maxOrderQuantityExceeded' = 'amErrorMapping.quantityNoItemsAddedMaxOrderQuantityExceeded',
  'basket.information.quantity.noItemsAdded.noStock' = 'amErrorMapping.quantityNoItemsAddedNoStock',
  'basket.information.quantity.reducedNumberOfItemsAdded.lowStock' = 'amErrorMapping.quantityReducedNumberOfItemsAddedLowStock',
  'basket.information.quantity.reducedNumberOfItemsAdded.maxOrderQuantityExceeded' = 'amErrorMapping.quantityReducedNumberOfItemsAddedMaxOrderQuantityExceeded',

  'basket.error.occurred' = 'amErrorMapping.errorOccurred',
  'basket.error.quantity.invalid' = 'amErrorMapping.quantityInvalid',
  'basket.add.to.cart.not.members' = 'amErrorMapping.addToCartNotMembers',

  'text.product.page.exclude.in.flight.error.msg' = 'amErrorMapping.excludeInFlightErrorMsg',
  'text.product.detail.page.variant.out.of.stock' = 'amErrorMapping.variantOutOfStock',
  'text.product.detail.page.error.message.no.longer.available' = 'amErrorMapping.errorMessageNoLongerAvailable',
  'text.product.detail.page.error.message.can.not.deliver' = 'amErrorMapping.errorMessageCanNotDeliver',
  'text.product.detail.page.error.message.full.cart' = 'amErrorMapping.errorMessageFullCart',
  'text.product.page.exclude.in.flight.error.unavailable' = 'amErrorMapping.inflightErrorUnavailable',

  'text.cart.page.product.error.maximum.times' = 'amErrorMapping.errorMaximumTimes',
  'text.cart.page.product.error.redemption.not.start' = 'amErrorMapping.errorRedemptionNotStart',
  'text.cart.page.mpc.slider.error.unredeemable' = 'amErrorMapping.sliderErrorUnredeemable',
  'text.cart.page.cart.error.promo.code' = 'amErrorMapping.cartErrorPromoCode',
  'text.cart.page.cart.error.promo.code.used' = 'amErrorMapping.cartErrorPromoCodeUsed',
  'text.cart.page.cart.error.promo.code.maxTry' = 'amErrorMapping.cartErrorPromoCodeMaxTry',
  // 'text.cart.page.cart.alert.price.change' = 'amErrorMapping.cartAlertPriceChange',
  'text.order.detail.page.two.order.enquiry.phone' = 'amOrderDetails.cancelAlertMessage',

  'default' = 'amErrorMapping.default'
}
export class ErrorTypeMapping {
  type;
  constructor(code: string) {
    this.type = ErrorType[code] ? ErrorType[code] : ErrorType.default;
  }
}
