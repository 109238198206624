import { Directive, ElementRef } from '@angular/core';
import { WindowRef } from '@spartacus/core';

const TIP_TEXT = 'PLEASEWAITPLEASEWAITPLEASEWAITPLEASEWAITPLEASEWAIT';
const TIP_MIN = 5;
const TIP_MAX = 10;
const MASK_COLOR = '#e6e7e8';

@Directive({
  selector: '[loadingMask]'
})
export class LoadingMaskDirective {
  constructor(
    private el: ElementRef,
    protected winRef: WindowRef,
  ) {}

 ngAfterContentInit() {
  if (!this.el.nativeElement.innerText) {
    const count = Math.floor(Math.random() * (TIP_MAX - TIP_MIN + 1) + TIP_MIN);
    const tip = TIP_TEXT.slice(0, count);
    this.el.nativeElement.innerHTML = tip;
  }
  this.el.nativeElement.style.background = MASK_COLOR;
  this.el.nativeElement.style.color = MASK_COLOR;
  this.el.nativeElement.style.borderColor = MASK_COLOR;
  this.el.nativeElement.style.userSelect = 'none';
  this.el.nativeElement.style.cursor = 'default';
  this.el.nativeElement.style.textDecoration = 'none';
  if (this.el.nativeElement.parentElement?.tagName === 'A') {
    this.el.nativeElement.parentElement.style.cursor = 'default';
    this.el.nativeElement.parentElement.style.textDecoration = 'none';
  }
 }

}