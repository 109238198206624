import { Injectable } from '@angular/core';
import { ActiveCartService, getCartIdByUserId, isEmpty, isTempCartId } from '@spartacus/cart/base/core';
import { Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { OCC_USER_ID_ANONYMOUS, RoutingService, UserIdService, OCC_CART_ID_CURRENT } from '@spartacus/core';
import { combineLatest, Observable, using } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take, tap, withLatestFrom } from 'rxjs/operators';
import { AmValidateCartService } from './am-validate-cart.service';

@Injectable({
  providedIn: 'root'
})
export class AmActiveCartService extends ActiveCartService {
  // protected userId: string = '';
  // protected cartId: string = '';

  // spartacus new feature
  constructor(
    protected multiCartService: MultiCartFacade,
    protected userIdService: UserIdService,
    protected routing: RoutingService,
    protected amValidateCartService: AmValidateCartService
  ) {
    super(multiCartService, userIdService);
    this.subscription.add(
      this.routing.getRouterState().subscribe((route) => {
        if (route.navigationId > 1 && route?.nextState?.semanticRoute === 'cart') {
          this.reloadActiveCart();
        }
      })
    );
  }

  initActiveCart() {
    // Stream for getting the cart value
    const cartValue$ = this.cartEntity$.pipe(
      map((cartEntity) => {
        return {
          cart: cartEntity.value,
          isStable: !cartEntity.loading && cartEntity.processesCount === 0,
          loaded: Boolean((cartEntity.error || cartEntity.success) && !cartEntity.loading)
        };
      }),
      // we want to emit empty carts even if those are not stable
      // on merge cart action we want to switch to empty cart so no one would use old cartId which can be already obsolete
      // so on merge action the resulting stream looks like this: old_cart -> {} -> new_cart
      filter(({ isStable, cart }) => isStable || isEmpty(cart))
    );

    // Responsible for loading cart when it does not exist 。
    const loading = cartValue$.pipe(
      withLatestFrom(this.activeCartId$, this.userIdService.getUserId()),
      filter(([, , userId]) => userId !== OCC_USER_ID_ANONYMOUS),
      tap(([{ cart, loaded, isStable }, cartId, userId]) => {
        if (isStable && isEmpty(cart) && !loaded && !isTempCartId(cartId) && this.shouldLoadCartOnCodeFlow) {
          this.load(cartId, userId);
        }
      })
    );

    this.activeCart$ = using(
      () => loading.subscribe(),
      () => cartValue$
    ).pipe(
      // Normalization for empty cart value returned as empty object.
      map(({ cart }) => (cart ? cart : {})),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  /**
   * change log:
   * global change:
   * change the activeCartId$ by following the new spartacus change
   * change the this.validateCartError.next(null); =>  this.amValidateCartService.triggerValidateCartError(null);
   * function change:
   * update: addEntry: change amMultiCartService.updateEntry to multiCartService.addEntry as they are the same
   * move: update(miles) => AmCartService.updateMiles
   *       getAddToCartResult, getAddToCartNumber, getAddToCartError, clearAddToCartResult, initAddToCartResult => AmAddToCartService
   *       toggleDeliveryFee, toggleFcom,  getVoucherError, closeRemoveEntrySuccess => AmCartService,
   *       removeEntrySuccess => AmCartService.getRemoveEntrySuccessObs
   * remove: remove cartCheck for it is useless;
   *
   *
   **/

  getActive(): Observable<Cart> {
    return this.activeCart$;
  }

  /**
   * the same as spartacus
   * Add entry to active cart
   *
   * @param productCode
   * @param quantity
   */
  addEntry(productCode: string, quantity: number): void {
    this.subscription.add(
      this.requireLoadedCart()
        .pipe(withLatestFrom(this.userIdService.getUserId()))
        .subscribe(([cartState, userId]) => {
          this.multiCartService.addEntry(userId, getCartIdByUserId(cartState, userId), productCode, quantity);
        })
    );
  }

  /**
   * difference from spartaucs: add this.amValidateCartService.triggerValidateCartError(null);
   * Update entry
   *
   * @param entryNumber
   * @param quantity
   */
  updateEntry(entryNumber: number, quantity: number): void {
    this.subscription.add(
      this.activeCartId$.pipe(withLatestFrom(this.userIdService.getUserId()), take(1)).subscribe(([cartId, userId]) => {
        this.amValidateCartService.triggerValidateCartError(null);
        this.multiCartService.updateEntry(userId, cartId, entryNumber, quantity);
      })
    );
  }

  /**
   * Reloads active cart
   */
  reloadActiveCart(): void {
    this.subscription.add(
      combineLatest([this.getActiveCartId(), this.userIdService.takeUserId()])
        .pipe(
          take(1),
          map(([cartId, userId]) => {
            this.multiCartFacade.loadCart({
              cartId: cartId === '' ? OCC_CART_ID_CURRENT : cartId,
              userId,
              extraData: { active: true }
            });
          })
        )
        .subscribe()
    );
  }
}
