export const configDeliveryMethodCodes = ['DOOR_TO_DOOR', 'PICK_UP_STORE', 'LOCKERS'];

export const configDeliveryMethods = [
  {
    code: 'DOOR_TO_DOOR',
    translateKey: 'amShippingAddress.homeOffice',
    disabled: false
  },
  {
    code: 'PICK_UP_STORE',
    translateKey: 'amShippingAddress.pickUpStore',
    disabled: false
  },
  {
    code: 'LOCKERS',
    translateKey: 'amShippingAddress.smartLockers',
    disabled: false
  }
];

export const displayModeMap = {
  onlyHomeAddress: 'OnlyHomeAddress',
  onlyEmailAddress: 'OnlyEmailAddress',
  bothHomeAndEmailAddress: 'HomeAndEmailAddress',
  onlyShopPickupAddress: 'OnlyShopPickupAddress',
  onlyInflightPickupAddress: 'onlyInflightPickupAddress'
};
