import { Injectable } from '@angular/core';
import { Occ, OccConfig, Product } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class MerchantProductConverter {
  constructor(protected config: OccConfig) {}

  convertImage(product: Product) {
    if (Array.isArray(product.galleryImages)) {
      return { ...product, convertedImages: this.transformGalleryImages(product.galleryImages) };
    }

    return product;
  }

  transformGalleryImages(images: Occ.Image[]) {
    return images.reduce(
      (accumulator, current) => {
        const image = current[Object.keys(current)[0]];

        if (image.hasOwnProperty('galleryIndex')) {
          accumulator[image.imageType][image.galleryIndex] = {
            ...accumulator[image.imageType][image.galleryIndex],
            ...current
          };

          return accumulator;
        }

        return { ...accumulator, [image.imageType]: { ...accumulator[image.imageType], ...current } };
      },
      { GALLERY: [] }
    );
  }
}
