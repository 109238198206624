<div
  class="inner"
  *ngIf="facetList$ | async as facetList"
  [cxFocus]="isDialog ? dialogFocusConfig : {}"
  [ngClass]="isDialog ? 'dialog-open' : ''"
  (esc)="close($event)"
  (click)="block($event)">
  <!-- <div class="select-miles-range" *ngIf="paymentMethodStatus !== true">
    <span class="heading">
      {{ 'amProductFacet.milesRange' | cxTranslate }}
    </span>
    <am-price-slider-bar [searchTextFromRouter]="searchTextFromRouter"></am-price-slider-bar>
  </div> -->
  <div class="popup-header">
    <span>{{ 'amProductFacet.Refine' | cxTranslate }}</span>
    <button (click)="close()" aria-label="close button"><span class="close-button"></span></button>
  </div>
  <div class="popup-total-clear-container">
    <p
      class="total-amount"
      [innerHTML]="'amProductListDetail.products' | cxTranslate : { totalCounts: model.pagination?.totalResults }"></p>
    <!-- <button class="clear-all" (click)="clearAll()" *ngIf="facetList?.activeFacets?.length > 0">
      <am-svg-container [type]="svgType.CLEARALL"></am-svg-container
      ><span>{{ 'amProductFacet.clearAll' | cxTranslate }}</span>
    </button> -->
  </div>
  <div class="toggle-paymentMethod-wrapper">
    <div class="toggle-paymentMethod-left">
      <div class="title">
        {{ 'amProductListDetail.payWithCash' | cxTranslate }}
      </div>
      <div class="describe">
        {{ 'amProductListDetail.payWithCashDescribe' | cxTranslate }}
      </div>
    </div>
    <div class="toggle-section">
      <am-slider-toggle-button
        name="paymentMethod-toggle"
        [status]="payByCashStatus"
        (changed)="togglePaymentMethod($event)">
      </am-slider-toggle-button>
    </div>
  </div>

  <div class="sorting-section">
    <am-accordion
      [headerLabel]="'amProductListDetail.sortBy' | cxTranslate"
      displayIconPosition="right"
      accordionType="plusType"
      idRef="sort_by"
      [expanded]="isFacetExpanded(facetList.activeFacets.length > 0)"
      (expandedChange)="storeFacetStatus($event)">
      <form name="sorting group">
        <div class="form-check" *ngFor="let sortItem of sortingOption; let i = index">
          <input
            type="radio"
            class="am-radio-selector"
            [name]="sortItem.name"
            [value]="sortItem.code"
            [id]="sortItem.code + '-' + i"
            [(ngModel)]="selectedSortItem"
            (ngModelChange)="sortList($event)" />
          <label [for]="sortItem.code + '-' + i">{{ sortItem.name }}</label>
        </div>
      </form>
    </am-accordion>
  </div>

  <am-facet
    #facetRef
    *ngFor="let facet of facetList.facets"
    [facet]="facet"
    [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }"
    [payByCashStatus]="payByCashStatus"
    [searchTextFromRouter]="searchTextFromRouter"
    [facetStatus]="facetService.facetStatus"
    [class.expanded]="isExpanded(facet) | async"
    [class.collapsed]="isCollapsed(facet) | async"></am-facet>
  <!-- (unlock)="expandFacetGroup(facet, facetRef)" -->
  <button class="button-primary apply-btn" (click)="close()">
    {{ 'amProductFacet.apply' | cxTranslate : { total: model.pagination?.totalResults } }}
  </button>
</div>
