import {
  decodeMap,
  decodeMapLegacy,
  decodeMapNumeric,
  invalidReferenceCodePoints,
  regexDecode
} from './constants/decodeMap';
import {
  encodeMap,
  regexAsciiWhitelist,
  regexAstralSymbols,
  regexBmpWhitelist,
  regexEncodeNonAscii,
  regexEscape,
  regexInvalidRawCodePoint
} from './constants/encodeMap';
export class Escape {
  has(object, propertyName): any {
    return object.hasOwnProperty.call(object, propertyName);
  }

  merge(options, defaults): any {
    if (!options) {
      return defaults;
    }
    const result = {};
    for (const key in defaults) {
      if (defaults[key]) {
        result[key] = this.has(options, key) ? options[key] : defaults[key];
      }
    }
    return result;
  }

  contains(array, value): boolean {
    let index = -1;
    const length = array.length;
    while (++index < length) {
      if (array[index] === value) {
        return true;
      }
    }
    return false;
  }

  codePointToSymbol(codePoint, strict): any {
    let output = '';
    if ((codePoint >= 0xd800 && codePoint <= 0xdfff) || codePoint > 0x10ffff) {
      // See issue #4:
      // “Otherwise, if the number is in the range 0xD800 to 0xDFFF or is
      // greater than 0x10FFFF, then this is a parse error. Return a U+FFFD
      // REPLACEMENT CHARACTER.”
      if (strict) {
        this.parseError('character reference outside the permissible Unicode range');
      }
      return '\uFFFD';
    }
    if (this.has(decodeMapNumeric, codePoint)) {
      if (strict) {
        this.parseError('disallowed character reference');
      }
      return decodeMapNumeric[codePoint];
    }
    if (strict && this.contains(invalidReferenceCodePoints, codePoint)) {
      this.parseError('disallowed character reference');
    }
    if (codePoint > 0xffff) {
      codePoint -= 0x10000;
      output += String.fromCharCode(((codePoint >>> 10) & 0x3ff) | 0xd800);
      codePoint = 0xdc00 | (codePoint & 0x3ff);
    }
    output += String.fromCharCode(codePoint);
    return output;
  }

  parseError(message): void {
    throw Error('Parse error: ' + message);
  }

  decode(html, options?: any): any {
    const defaultOptions = {
      isAttributeValue: false,
      strict: false
    };
    const mergeOptions = this.merge(options, defaultOptions);
    const strict = mergeOptions.strict;
    return html.replace(regexDecode, ($0, $1, $2, $3, $4, $5, $6, $7, $8) => {
      let codePoint;
      let semicolon;
      let decDigits;
      let hexDigits;
      let reference;
      let next;

      if ($1) {
        reference = $1;
        // Note: there is no need to check `has(decodeMap, reference)`.
        return decodeMap[reference];
      }

      if ($2) {
        // Decode named character references without trailing `;`, e.g. `&amp`.
        // This is only a parse error if it gets converted to `&`, or if it is
        // followed by `=` in an attribute context.
        reference = $2;
        next = $3;
        if (next && options.isAttributeValue) {
          if (strict && next === '=') {
            this.parseError('`&` did not start a character reference');
          }
          return $0;
        } else {
          if (strict) {
            this.parseError('named character reference was not terminated by a semicolon');
          }
          // Note: there is no need to check `has(decodeMapLegacy, reference)`.
          return decodeMapLegacy[reference] + (next || '');
        }
      }

      if ($4) {
        // Decode decimal escapes, e.g. `&#119558;`.
        decDigits = $4;
        semicolon = $5;
        if (strict && !semicolon) {
          this.parseError('character reference was not terminated by a semicolon');
        }
        codePoint = parseInt(decDigits, 10);
        return this.codePointToSymbol(codePoint, strict);
      }

      if ($6) {
        // Decode hexadecimal escapes, e.g. `&#x1D306;`.
        hexDigits = $6;
        semicolon = $7;
        if (strict && !semicolon) {
          this.parseError('character reference was not terminated by a semicolon');
        }
        codePoint = parseInt(hexDigits, 16);
        return this.codePointToSymbol(codePoint, strict);
      }

      // If we’re still here, `if ($7)` is implied; it’s an ambiguous
      // ampersand for sure. https://mths.be/notes/ambiguous-ampersands
      if (strict) {
        this.parseError('named character reference was not terminated by a semicolon');
      }
      return $0;
    });
  }

  decEscape(codePoint) {
    return '&#' + codePoint + ';';
  }

  hexEscape(codePoint) {
    return '&#x' + codePoint.toString(16).toUpperCase() + ';';
  }

  encode(string, options?: any) {
    const defaultOptions = {
      allowUnsafeSymbols: false,
      encodeEverything: false,
      strict: false,
      useNamedReferences: false,
      decimal: false
    };
    options = this.merge(options, defaultOptions);
    var strict = options.strict;
    if (strict && regexInvalidRawCodePoint.test(string)) {
      this.parseError('forbidden code point');
    }
    var encodeEverything = options.encodeEverything;
    var useNamedReferences = options.useNamedReferences;
    var allowUnsafeSymbols = options.allowUnsafeSymbols;
    var escapeCodePoint = options.decimal ? this.decEscape : this.hexEscape;

    var escapeBmpSymbol = function (symbol) {
      return escapeCodePoint(symbol.charCodeAt(0));
    };

    if (encodeEverything) {
      // Encode ASCII symbols.
      string = string.replace(regexAsciiWhitelist, function (symbol) {
        // Use named references if requested & possible.
        if (useNamedReferences && this.has(encodeMap, symbol)) {
          return '&' + encodeMap[symbol] + ';';
        }
        return escapeBmpSymbol(symbol);
      });
      // Shorten a few escapes that represent two symbols, of which at least one
      // is within the ASCII range.
      if (useNamedReferences) {
        string = string
          .replace(/&gt;\u20D2/g, '&nvgt;')
          .replace(/&lt;\u20D2/g, '&nvlt;')
          .replace(/&#x66;&#x6A;/g, '&fjlig;');
      }
      // Encode non-ASCII symbols.
      if (useNamedReferences) {
        // Encode non-ASCII symbols that can be replaced with a named reference.
        string = string.replace(regexEncodeNonAscii, function (string) {
          // Note: there is no need to check `has(encodeMap, string)` here.
          return '&' + encodeMap[string] + ';';
        });
      }
      // Note: any remaining non-ASCII symbols are handled outside of the `if`.
    } else if (useNamedReferences) {
      // Apply named character references.
      // Encode `<>"'&` using named character references.
      if (!allowUnsafeSymbols) {
        string = string.replace(regexEscape, function (string) {
          return '&' + encodeMap[string] + ';'; // no need to check `has()` here
        });
      }
      // Shorten escapes that represent two symbols, of which at least one is
      // `<>"'&`.
      string = string.replace(/&gt;\u20D2/g, '&nvgt;').replace(/&lt;\u20D2/g, '&nvlt;');
      // Encode non-ASCII symbols that can be replaced with a named reference.
      string = string.replace(regexEncodeNonAscii, function (string) {
        // Note: there is no need to check `has(encodeMap, string)` here.
        return '&' + encodeMap[string] + ';';
      });
    } else if (!allowUnsafeSymbols) {
      // Encode `<>"'&` using hexadecimal escapes, now that they’re not handled
      // using named character references.
      string = string.replace(regexEscape, escapeBmpSymbol);
    }
    return (
      string
        // Encode astral symbols.
        .replace(regexAstralSymbols, function ($0) {
          // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
          var high = $0.charCodeAt(0);
          var low = $0.charCodeAt(1);
          var codePoint = (high - 0xd800) * 0x400 + low - 0xdc00 + 0x10000;
          return escapeCodePoint(codePoint);
        })
        // Encode any remaining BMP symbols that are not printable ASCII symbols
        // using a hexadecimal escape.
        .replace(regexBmpWhitelist, escapeBmpSymbol)
    );
  }
}
