import { Injectable } from '@angular/core';
import { AmConsentService } from '@core/user/consents/services/am-consents.service';
import { ActionsSubject } from '@ngrx/store';
import { CdsConfig, ConsentChangedPushEvent, ProfileTagLifecycleService } from '@spartacus/cds';
import { AuthActions, ConsentService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmProfileTagLifecycleService extends ProfileTagLifecycleService {
  constructor(
    protected consentService: ConsentService,
    protected config: CdsConfig,
    protected actionsSubject: ActionsSubject,
    protected amConsentService: AmConsentService
  ) {
    super(consentService, config, actionsSubject);
  }

  consentChanged(): Observable<ConsentChangedPushEvent> {
    return this.amConsentService.getConsentByTemplateId(this.config.cds.consentTemplateId).pipe(
      filter(Boolean),
      map((profileConsent) => {
        return this.amConsentService.isConsentGiven(profileConsent);
      }),
      map((granted) => {
        return new ConsentChangedPushEvent(granted);
      })
    );
  }

  loginSuccessful(): Observable<boolean> {
    return this.actionsSubject.pipe(
      filter((action) => action.type === AuthActions.LOGIN),
      mapTo(true)
    );
  }
}
