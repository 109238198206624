import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { AmSearchBoxComponentService } from '@components/content/mulit-search-box/search-box/am-search-box-component.service';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CmsConfig } from '@spartacus/core/src/cms';
import { IconModule, SearchBoxComponentService } from '@spartacus/storefront';
// import { AmSearchBoxModule } from '../../../content/mulit-search-box/search-box/am-search-box.module';
import { SvgContainerModule } from '../../../content/svg-container/svg-container.module';
import { DisplaySearchBoxComponent } from './display-search-box.component';
import { SearchDialogModule } from './search-dialog/search-dialog.module';

@NgModule({
  declarations: [DisplaySearchBoxComponent],
  imports: [
    CommonModule,
    RouterModule,
    AmMediaModule,
    IconModule,
    UrlModule,
    I18nModule,
    // AmSearchBoxModule,
    SearchDialogModule,
    SvgContainerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SearchBoxComponent: {
          // typeCode
          component: DisplaySearchBoxComponent,
          providers: [
            {
              provide: SearchBoxComponentService,
              useClass: AmSearchBoxComponentService
            }
          ]
        }
      }
    } as CmsConfig)
  ]
})
export class DisplaySearchBoxModule {}
