/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmCartNotEmptyGuard implements CanActivate {
  isBrowser: boolean = false;

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected semanticPathService: SemanticPathService,
    protected router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(): Observable<boolean | UrlTree> {
    if (!this.isBrowser) {
      return of(true);
    }
    return this.activeCartFacade.takeActive().pipe(
      map((cart) => {
        if (this.isEmpty(cart)) {
          return this.router.parseUrl(this.semanticPathService.get('home') ?? '');
        }
        return true;
      })
    );
  }

  private isEmpty(cart: Cart): boolean {
    return cart && !cart.totalItems;
  }
}
