import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InterceptorUtil,
  normalizeHttpError,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  USE_CLIENT_TOKEN
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AmOccCartValidationAdapter {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService) {}

  protected getHeaders(userId: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return headers;
  }

  validate(cartId, userId): Observable<any> {
    const url = this.occEndpoints.buildUrl('validate', {
      urlParams: { cartId, userId }
    });
    return this.http.get<any>(url).pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }

  checkCart(cartId, userId): Observable<any> {
    const url = this.occEndpoints.buildUrl('validate', {
      urlParams: { cartId, userId }
    });
    return this.http.get<any>(url);
  }
}
