import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OccConfig, Product } from "@spartacus/core";
import { getTagGroupAColor } from "@utils/getProductTagGroupAColor";

@Component({
  selector: 'am-product-card',
  templateUrl: './product-card.component.html',
})
export class AmProductCardComponent {
  @Input() isLoading?: boolean;
  @Input() productData?: Product;
  @Output() onNavigate?: EventEmitter<UIEvent> = new EventEmitter();

  getTagGroupAColor = getTagGroupAColor;
  imgShowUrl: string;

  constructor(
    // protected occConfig: OccConfig,
  ) { 
  }

  ngOnInit() {
  }

  handleNavigate(evt: UIEvent) {
    this.onNavigate.emit(evt);
  }

}