import { AuthConfig } from '@spartacus/core';
import UrlHelper from '@utils/url-helper';

// we need to pass the state to the oauth server(cx),
// so that it can give the state back to us when redirect back.
function addStateToTargetUrl(url: string): string {
  const state = UrlHelper.getSearchParamByName('state', url);
  let loginreferrer = UrlHelper.getSearchParamByName('loginreferrer', url);
  if (!!loginreferrer && loginreferrer.endsWith('/')) {
    loginreferrer = loginreferrer.slice(0, -1);
  }
  loginreferrer += `?state=${state}`;
  return UrlHelper.addSearchParam(url, 'loginreferrer', loginreferrer);
}

function cleanUrlParams(url: string): string {
  const urlObject = new URL(url);
  urlObject.searchParams.delete('client_id');
  urlObject.searchParams.delete('redirect_uri');
  urlObject.searchParams.delete('state');
  urlObject.searchParams.delete('response_type');
  urlObject.searchParams.delete('scope');
  return urlObject.href;
}

export const authConfig: AuthConfig = {
  authentication: {
    client_id: 'spartacus_customer',
    client_secret: 'secret',
    tokenEndpoint: '/auto-login',
    revokeEndpoint: '/logout',
    OAuthLibConfig: {
      responseType: 'code',
      scope: '',
      customTokenParameters: ['code', 'sessionTokenIdentifier'],
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      disablePKCE: true,
      oidc: false,
      clearHashAfterLogin: false,
      openUri: (uri) => {
        const url = addStateToTargetUrl(uri);
        if (typeof window !== 'undefined') {
          window.location.href = cleanUrlParams(url);
        }
      }
    }
  }
};
