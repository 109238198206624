import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  OccProductSearchAdapter,
  ProductSearchPage,
  ProductSearchService,
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  SearchConfig
} from '@spartacus/core';
import { Observable } from 'rxjs';

const DEFAULT_SEARCH_CONFIG: SearchConfig = {
  pageSize: 9
};

@Injectable()
export class AmOccProductSearchAdapter extends OccProductSearchAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected productSearchService: ProductSearchService
  ) {
    super(http, occEndpoints, converter);
  }

  search(query: string, searchConfig: SearchConfig = DEFAULT_SEARCH_CONFIG): Observable<ProductSearchPage> {
    return this.http.get<any>(this.getSearchEndpoint(query, searchConfig)).pipe(
      this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER)
    )
  }
}
