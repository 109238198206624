import { Component, Input, OnInit } from '@angular/core';
import { YOUTUBE_API_SCRIPT } from '@config/am-third-party-lib.config';
import { ThirdPartyLibService } from '@utils/thirdPartyLibLoader/third-party-lib.service';
import { MediaConfig, MediaState } from '../media-player.model';

export interface YouTubeConfig {
  videoId: string;
  playerVars?: {
    listType: string;
    list: string;
  };
}

@Component({
  selector: 'am-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit {
  constructor(protected thirdPartyLibService: ThirdPartyLibService) {}

  @Input() mediaConfig: MediaConfig;
  @Input() onPlayStateChange: Function;

  player: any = null;
  playConfig: YouTubeConfig = null;
  clickedPlayVideoButNotReady = false;

  ready(e): void {
    if (e.target) {
      this.player = e.target;
      this.player.mute();
      if (this.clickedPlayVideoButNotReady) {
        this.player.seekTo(0);
      }
    } else {
      // console.log('youtube video ready function error: cannot get the video item');
    }
  }

  startPlay(): void {
    if (this.player) {
      this.player.seekTo(0);
    } else {
      this.clickedPlayVideoButNotReady = true;
    }
  }

  getPlayConfig(url: string): YouTubeConfig {
    if (this.mediaConfig.id) {
      return {
        videoId: this.mediaConfig.id
      };
    }
    const $url = new URL(url);
    if ($url.hostname.endsWith('youtube.com')) {
      const videoId = $url.searchParams.get('v');
      return videoId
        ? { videoId }
        : // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          (() => {
            if ($url.pathname.startsWith('/embed')) {
              const segments = $url.pathname.split('/');
              const videoId = segments[segments.length - 1];
              const list = $url.searchParams.get('list');
              return {
                videoId,
                playerVars: {
                  listType: 'playlist',
                  list
                }
              };
            } else {
              return undefined;
            }
          })();
    } else if ($url.hostname === 'youtu.be') {
      return { videoId: $url.pathname.substr(1) };
    }
  }

  stateChange(e): void {
    const state: MediaState = e.target.getPlayerState();
    this.onPlayStateChange(state);
    // this.onStateChange(state)
  }

  ngOnInit(): void {
    const scriptUrl = YOUTUBE_API_SCRIPT;
    this.thirdPartyLibService.embedScript({
      src: scriptUrl
    });
    // this.thirdPartyLibService.addScript(scriptUrl);
    this.playConfig = this.getPlayConfig(this.mediaConfig.url);
  }
}
