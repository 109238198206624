import { FavoriteProduct } from '../services/favorite-notify-products.service';

export const AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE = 'am-my-product-list';
export const PRODUCT_DETAIL_ENTITY = '[Product] Detail Entity';

export interface StateWithFavoriteAndNotifyProducts {
  [AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE]: FavoriteAndNotifyProductsMap;
}

export interface FavoriteAndNotifyProductsMap {
  favoriteAndNotifyProducts?: ProductListPageState;
}

export interface ProductListPageState {
  activeType?: string;
  displayEntries?: FavoriteProduct[];
  favoriteEntries?: FavoriteProduct[];
  notifyMeEntries?: FavoriteProduct[];
  loaded?: boolean;
}
