import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsmConfig } from '@spartacus/asm/root';
import { I18nModule, OccConfig, provideConfig } from '@spartacus/core';
import { FormErrorsModule, LayoutConfig } from '@spartacus/storefront';
import { AmAsmMainUiComponent } from './asm-main-ui/am-asm-main-ui.component';
import { AmAsmSessionTimerComponent } from './asm-session-timer/am-asm-session-timer.component';
import { FormatTimerPipe } from './asm-session-timer/format-timer.pipe';
import { AmAsmToggleUiComponent } from './asm-toggle-ui/am-asm-toggle-ui.component';
import { AmCSAgentLoginFormComponent } from './csagent-login-form/am-csagent-login-form.component';
import { AmCustomerEmulationComponent } from './customer-emulation/am-customer-emulation.component';
import { AmCustomerSelectionComponent } from './customer-selection/am-customer-selection.component';

export const defaultAsmLayoutConfig: LayoutConfig = {
  launch: {
    ASM: {
      outlet: 'cx-storefront',
      component: AmAsmMainUiComponent
    }
  }
};

export const defaultAsmConfig: AsmConfig = {
  asm: {
    agentSessionTimer: {
      startingDelayInSeconds: 60 * 30
    },
    customerSearch: {
      maxResults: 20
    }
  }
};

export const defaultOccAsmConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        asmCustomerSearch: '/assisted-service/customers/search'
      }
    }
  }
};

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, FormErrorsModule, FormsModule, NgSelectModule],
  declarations: [
    AmAsmMainUiComponent,
    AmCSAgentLoginFormComponent,
    AmCustomerSelectionComponent,
    AmAsmSessionTimerComponent,
    FormatTimerPipe,
    AmCustomerEmulationComponent,
    AmAsmToggleUiComponent
  ],
  providers: [
    // provideDefaultConfig(defaultAsmConfig),
    provideConfig(defaultAsmLayoutConfig)
  ]
})
export class AmAsmComponentsModule {}
