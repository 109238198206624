import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService, WindowRef } from '@spartacus/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlightStatusStorageService } from './flight-status-storage.service';

export interface FlightItem {
  posCode?: string;
  pk?: string;
  posType?: string;
  flightOrigin?: {
    portCode: string;
  };
  flightDest?: {
    portCode: string;
  };
  inflightSalesChannel?: string;
}

interface FlightResponse {
  statusCode?: string;
  data?: Array<FlightItem>;
}

export interface PointOfServiceStoreStatus {
  data?: {
    inflightSalesChannel?: string;
    isStoreOpen?: boolean;
    name?: string;
  };
  statusCode?: string;
}

export interface PointOfServiceInfo {
  inflightSalesChannel?: string;
  isStoreOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FlightInfoService {
  public timeOut: Subject<boolean> = new Subject();
  timer: NodeJS.Timeout;

  constructor(
    private occEndpointSvc: OccEndpointsService,
    private http: HttpClient,
    protected userIdService: UserIdService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected winRef: WindowRef
  ) {
    // this.startFlightInfoTimer();
  }

  getFlightList(flightNumber: string): Observable<FlightItem> {
    // const url =
    //   'https://origin-lifestyle-api-t0.ete.asiamiles.com/asiamilesspartacuswebservices/v2/asiamiles/getFlightListByPosType/INFLIGHT';
    const posType = 'INFLIGHT';
    const url = this.occEndpointSvc.buildUrl('getFlightList', { urlParams: { posType } });
    return this.http.get<FlightResponse>(url).pipe(
      map((object) => {
        const { data } = object;
        console.warn('posCodesList', flightNumber);
        const currentFlightItems = data.filter(
          (item) => item.posCode?.toUpperCase() === `${flightNumber.toUpperCase()}`
        );
        return currentFlightItems[0];
      })
    );
  }

  private _getPointOfServiceStoreStatus(): Observable<PointOfServiceStoreStatus> {
    const pk = this.flightStatusStorageService.getFlightPk();
    // const url = `https://origin-lifestyle-api-t0.ete.asiamiles.com/asiamilesspartacuswebservices/v2/asiamiles/shopcrewbff/getPosStoreopenStatus/${pk}`;
    const url = this.occEndpointSvc.buildUrl('getPointOfServiceStoreStatus', { urlParams: { pk } });
    const obj = this.http.get<PointOfServiceStoreStatus>(url);
    return obj;
  }

  getPointOfServiceStatus(): Observable<PointOfServiceInfo | null> {
    if (this.flightStatusStorageService.isPosInflightTypeFlow) {
      return this._getPointOfServiceStoreStatus().pipe(
        map((data) =>
          data.statusCode != '00'
            ? { isStoreOpen: false, inflightSalesChannel: this.flightStatusStorageService.getInflightSalesChannel() }
            : {
                isStoreOpen: data.data.isStoreOpen,
                inflightSalesChannel: data.data.inflightSalesChannel
              }
        )
      );
    }
    return of(null);
  }

  startFlightInfoTimer(): void {
    const flightIndicator = this.flightStatusStorageService.getInFlightIndicator();
    if (!flightIndicator) {
      return;
    }
    const createdTime = this.flightStatusStorageService.getFlightInfoSaveTime();
    if (createdTime) {
      this.cleanFlightInfoTimer();
      const diff = this.flightStatusStorageService.getFlightInfoExpiredPeriod() - (new Date().getTime() - createdTime);
      if (diff <= 0) {
        this.flightStatusStorageService.removeFlightStatus();
        this.timeOut.next(true);
        return;
      }
      this.timer =
        this.winRef.isBrowser() &&
        setTimeout(() => {
          this.flightStatusStorageService.removeFlightStatus();
          this.cleanFlightInfoTimer();
          this.timeOut.next(true);
        }, diff);
    }
  }

  getFlightOriginAndDest(): string | null {
    const flightInfobject = this.flightStatusStorageService.getFilgitInfo();
    let origin;
    let dest;
    if (flightInfobject?.flightOrigin?.portCode && flightInfobject?.flightDest?.portCode) {
      origin = flightInfobject?.flightOrigin?.portCode;
      dest = flightInfobject?.flightDest?.portCode;
    } else {
      return null;
    }
    return origin + '-' + dest;
  }

  cleanFlightInfoTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
