<ng-container *ngIf="state$ | async as state">
  <!-- <span class="heading">
    {{ facet.name }}
  </span> -->

  <ng-container *ngIf="routerState$ | async">
    <am-accordion
      [headerLabel]="facet.name"
      displayIconPosition="right"
      accordionType="plusType"
      [idRef]="facet.code"
      [expanded]="isFacetExpanded(facet.code)"
      (expandedChange)="storeFacetStatus($event)">
      <ng-container *ngIf="facet.code === 'brand'">
        <div class="search-box">
          <span class="search-left"><am-svg-container [type]="svgType"></am-svg-container></span>
          <input
            type="text"
            name="search brand name"
            [(ngModel)]="brandNameValue"
            id="search-input-box"
            (focus)="inputFocused($event)"
            (blur)="inputBlured($event)" />
        </div>
      </ng-container>
      <div class="facet-container">
        <a
          *ngFor="let value of filterFacet(facet.values)"
          #facetValue
          routerLink="./"
          [queryParams]="getLinkParams(value, facet.name)"
          [ngClass]="{ selected: value.selected, value: !facet.code.includes('categoryLevel') }"
          [cxFocus]="value.name"
          (keydown.space)="openLink($event)"
          [attr.aria-label]="value.name">
          <span>
            <span class="label">{{ value.name }}</span>
            <!-- <span class="count">{{ value.count }}</span> -->
          </span>
        </a>
      </div>
      <button class="unselect-all" (click)="unselectAll()" *ngIf="facet.code === 'brand' && isSelectBrand()">
        {{ 'amProductFacet.clearAll' | cxTranslate }}
      </button>
    </am-accordion>

    <ng-container *ngIf="!payByCashStatus && facet.code === 'brand'">
      <am-accordion
        [headerLabel]="'amProductFacet.milesRange' | cxTranslate"
        displayIconPosition="right"
        accordionType="plusType"
        [expanded]="isFacetExpanded('miles_range')"
        (expandedChange)="storeFacetStatus($event)"
        idRef="miles_range">
        <div class="facet-container">
          <div class="select-miles-range">
            <!-- <span class="heading">
              {{ 'amProductFacet.milesRange' | cxTranslate }}
            </span> -->
            <am-price-slider-bar [searchTextFromRouter]="searchTextFromRouter"></am-price-slider-bar>
          </div>
        </div>
      </am-accordion>
    </ng-container>
  </ng-container>
</ng-container>
