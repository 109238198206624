import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { SiteContextType } from '@config/am-constant.config';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CartVoucherService } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { CustomerCouponService } from '@spartacus/core';
import { LAUNCH_CALLER, SiteContextComponentService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AmCartService } from '../../service/am-cart.service';
@Component({
  selector: 'am-cart-coupon',
  templateUrl: './cart-coupon.component.html'
})
export class AmCartCouponComponent implements OnInit, OnDestroy {
  svgType = svgConfig;
  MAX_CUSTOMER_COUPON_PAGE = 100;
  couponForm: FormGroup;
  cartIsLoading$: Observable<boolean>;
  couponError = false;
  couponCodeApplied: string;
  couponType: number;
  couponValue: number;
  isFirstCoBrand = false;
  cart$ = this.activeCartService.getActive().pipe(
    tap((cart: Cart) => {
      this.showError = true;
      this.couponCode(cart);
      if (!this.couponError) {
        if (cart.appliedVouchers.length) {
          this.couponForm.patchValue({
            couponCode: cart.appliedVouchers[0]?.voucherCode
          });
          this.showPromoCode = true;
        } else {
          this.couponForm.reset();
        }
      }
      if (this.couponType === 5 && this.isFirstCoBrand) {
        const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_COBRAND_COUPON, undefined, undefined, {
          cobrandCardName: this.cobrandCardName
        });
        if (dialog) {
          this.subscription.add(dialog.pipe(take(1)).subscribe());
        }
      }

      this.currency = cart?.subTotalPriceCash.currencyIso;
    })
  );
  cartId: string;
  showPromoCode = false;
  voucherError$ = this.amCartService.getVoucherError();
  // voucherError;
  protected subscription = new Subscription();
  currency: any;
  cobrandCardName: string;
  showError = true;
  // currencyContext = SiteContextType.CURRENCY;
  constructor(
    protected cartVoucherService: CartVoucherService,
    protected formBuilder: FormBuilder,
    protected customerCouponService: CustomerCouponService,
    protected activeCartService: ActiveCartFacade,
    protected amCartService: AmCartService,
    protected launchDialogService: AmLaunchDialogService,
    private siteContextService: SiteContextComponentService
  ) {
    // this.subscription.add(
    //   this.siteContextService.getActiveItem(this.currencyContext).subscribe((val: any) => {
    //     this.currency = val;
    //   })
    // );
  }

  ngOnInit(): void {
    // this.activeCartService.getVoucherError().subscribe((d) => {
    //   console.log(d);
    //   this.voucherError = d;
    // });
    this.couponForm = this.formBuilder.group({
      couponCode: ['']
    });
    this.cartIsLoading$ = this.activeCartService.isStable().pipe(map((loaded) => !loaded));

    this.subscription.add(
      this.cartVoucherService.getAddVoucherResultSuccess().subscribe((success) => {
        if (success) {
          this.couponError = false;
          this.cartVoucherService.resetAddVoucherProcessingState();
        }
      })
    );

    this.subscription.add(
      this.cartVoucherService.getAddVoucherResultError().subscribe((error) => {
        if (error) {
          this.couponError = true;
          this.cartVoucherService.resetAddVoucherProcessingState();
        }
      })
    );
  }

  applyVoucher(): void {
    if (this.couponForm.valid && this.couponForm.value.couponCode) {
      this.cartVoucherService.addVoucher(this.couponForm.value.couponCode, this.cartId);
      this.isFirstCoBrand = true;
    } else {
      this.couponForm.markAllAsTouched();
      this.isFirstCoBrand = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.cartVoucherService.resetAddVoucherProcessingState();
  }

  couponCode(cart: Cart): void {
    this.couponCodeApplied = null;
    this.couponType = null;
    this.couponValue = 0;

    if (!cart.appliedVouchers?.length) {
      return;
    }
    cart.appliedProductPromotions?.forEach((promotion) => {
      let sum = 0;
      if (!promotion.bonusMiles && !promotion.orderPromo) {
        promotion.appliedVouchers?.forEach((voucher) => {
          this.couponCodeApplied = voucher;
          this.couponType = 1;
          const cartVoucher = cart.appliedVouchers.filter((item) => {
            return item.voucherCode === voucher;
          })[0];
          if (!!cartVoucher && cartVoucher.isFreeGiftPromo === true) {
            this.couponType = 6;
          }
        });
        promotion.consumedEntries?.forEach((consumed) => {
          sum += consumed.discountUnitPrice;
        });
      }
      if (sum > 0) {
        this.couponValue = sum;
      }
    });
    cart.appliedAmlOrderPromotions?.forEach((promotion) => {
      let sum = 0;
      promotion.appliedVouchers?.forEach((voucher) => {
        this.couponCodeApplied = voucher;
        this.couponType = 2;
      });
      promotion.consumedEntries?.forEach((consumed) => {
        sum += consumed.discountUnitPrice;
      });
      if (this.couponType === 2) {
        this.couponValue = sum;
      }
    });

    cart.appliedCashPromotions?.forEach((promotion) => {
      promotion.appliedVouchers?.forEach((voucher) => {
        this.couponCodeApplied = voucher;
        this.couponType = 3;
        this.couponValue = promotion.discountCash;
      });
    });

    cart.appliedBonusPromotions?.forEach((promotion) => {
      promotion.appliedVouchers?.forEach((voucher) => {
        this.couponCodeApplied = voucher;
        this.couponType = 4;
        this.couponValue = promotion.bonusMiles;
      });
    });
    cart.appliedPaymentPromotions?.forEach((promotion) => {
      promotion.appliedVouchers?.forEach((voucher) => {
        this.couponCodeApplied = voucher;
        this.couponType = 5;
        this.cobrandCardName = promotion.promotion.cardName;
      });
    });
    // cart.appliedCoBrandCardGrouping?.forEach((promotion) => {
    //   // if (cart.appliedVouchers[0].code === promotion.key) {
    //   this.couponCodeApplied = promotion.key;
    //   this.cobrandCardName = promotion.key;
    //   this.couponType = 5;
    //   // }
    // });
  }

  isShowCoupon(cart: Cart): boolean {
    let show = false;
    if (cart.appliedVouchers?.length) {
      cart.appliedProductPromotions?.forEach((promotion) => {
        if (!promotion.bonusMiles && !promotion.orderPromo) {
          promotion.appliedVouchers?.forEach((voucher) => {
            show = true;
          });
        }
      });
      cart.appliedAmlOrderPromotions?.forEach((promotion) => {
        promotion.appliedVouchers?.forEach((voucher) => {
          show = true;
        });
      });
    }
    return show;
  }

  isWarnCouponCode(cart: Cart): boolean {
    let couponCode;
    cart.appliedProductPromotions?.forEach((promotion) => {
      if (!promotion.bonusMiles && !promotion.orderPromo) {
        promotion.appliedVouchers?.forEach((voucher) => {
          couponCode = voucher;
        });
      }
    });
    cart.appliedPaymentPromotions?.forEach((promotion) => {
      promotion.appliedVouchers?.forEach((voucher) => {
        couponCode = voucher;
      });
    });
    let hasCode =
      cart.appliedVouchers?.length && cart.appliedProductPromotions?.length && !couponCode && !cart.usedCouponFlag;
    if (hasCode) {
      this.showError = true;
    }
    return hasCode;
  }

  removeChangeVoucher(cart: Cart, reset): void {
    this.removeSpace();
    this.showError = false;
    if (cart.appliedVouchers?.length && this.couponForm.value.couponCode !== cart.appliedVouchers[0]?.voucherCode) {
      this.cartVoucherService.removeVoucher(cart.appliedVouchers[0].voucherCode);
      this.couponError = !reset;
    }
  }
  removeVoucher(cart: Cart, reset): void {
    if (cart.appliedVouchers?.length) {
      this.cartVoucherService.removeVoucher(cart.appliedVouchers[0].voucherCode);
      this.couponError = !reset;
    }
  }
  changeCoupon(): void {
    this.showError = false;
  }

  removeSpace(): void {
    let couponCodeBackup = this.couponForm.value.couponCode?.replace(/\s*/g, '');
    this.couponForm = this.formBuilder.group({
      couponCode: couponCodeBackup
    });
  }
}
