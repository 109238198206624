import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  BasePageMetaResolver,
  CmsService,
  PageLinkService,
  PageMetaResolver,
  PageTitleResolver,
  PageType,
  Product,
  ProductScope,
  ProductService,
  RoutingService
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { escaper } from '@utils/escaper';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AmProductPageMetaResolver extends PageMetaResolver implements PageTitleResolver {
  pageTitle: string = '';

  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    protected currentProductService: CurrentProductService,
    protected routingService: RoutingService,
    protected productService: ProductService,
    protected readonly cms: CmsService,
    protected readonly router: Router,
    protected readonly pageLinkService: PageLinkService
  ) {
    super();
    this.pageType = PageType.PRODUCT_PAGE;
  }

  protected product$: Observable<Product> = this.routingService.getRouterState().pipe(
    map((state) => state.state.params['productCode']),
    filter((code) => !!code),
    switchMap((code) => this.productService.get(code, ProductScope.DETAILS)),
    filter((p) => Boolean(p))
  );

  resolveTitle(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) => {
        if (product?.responseInfo?.pageTitle) {
          return of(escaper.decode(product?.responseInfo?.pageTitle));
        }
        return of('');
      })
    );
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      startWith(null),
      map(() => this.pageLinkService.getCanonicalUrl())
    );
  }

  resolveDescription(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) => {
        const productName = product.name;
        return this.cms.getCurrentPage().pipe(
          filter((page) => !!page),
          switchMap((page) => {
            const pageDescription = page.properties?.description?.replace('{product name}', productName);
            return of(pageDescription);
          })
        );
      })
    );
  }
}
