import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { OccCommonConnector } from '../../occ/common.connector';
import { CommonActions } from '../actions';
@Injectable()
export class CommonConfigEffects {
  // creatCommonConfig$: Observable<any> = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CommonActions.CREATE_COMMON_CONFIG),
  //     map((action: CommonActions.CreateCommon) => action.payload),
  //     tap((d) => {
  //       console.log(d);
  //     })
  //   )
  // );

  loadCommonConfig$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.LOAD_COMMON_CONFIG),
      map((action: CommonActions.LoadCommonConfig) => action.payload),
      exhaustMap(() => {
        return this.occCommonConnector.load().pipe(
          map((data) => {
            return new CommonActions.LoadCommonConfigSuccess({ ...data });
          })
        );
      })
    )
  );
  constructor(protected actions$: Actions, protected occCommonConnector: OccCommonConnector) {}
}
