import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmLoadingModule } from '@components/content/loading/loading.module';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { CartLoadingDialogComponent } from './cart-loading-dialog.component';

@NgModule({
  declarations: [CartLoadingDialogComponent],
  imports: [CommonModule, AmLoadingModule, I18nModule, KeyboardFocusModule]
})
export class CartLoadingDialogModule {}
