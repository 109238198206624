import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CollectionMethodType } from '@model/collection-method.enum';
import { Cart, ConsignmentEntry, OrderEntry } from '@spartacus/cart/base/root';
import { AmCartService } from '../../service/am-cart.service';

@Component({
  selector: 'am-am-cart-detail-flow1-container',
  templateUrl: './am-cart-detail-flow1-container.component.html',
  styleUrls: ['./am-cart-detail-flow1-container.component.scss']
})
export class AmCartDetailFlow1ContainerComponent implements OnInit, OnChanges {
  activeTab: string;
  inflightCartEntries: OrderEntry[] = [];
  groundCartEntries: OrderEntry[] = [];
  infligtAmount: number = 0;
  groundAmount: number = 0;
  constructor(protected amCartService: AmCartService) {}

  @Input() isStoreOpenStatus: boolean;
  @Input() cart: Cart;
  @Input() entries: OrderEntry[] = [];
  @Input() cartLoaded: boolean;

  ngOnInit(): void {
    this.spiltCartWhileFlow1(this.entries);
    if (
      this.inflightCartEntries.length > 0 ||
      (this.inflightCartEntries.length === 0 && this.groundCartEntries.length === 0)
    ) {
      this.activeTab = 'inflight';
      this.amCartService.inflightFlowIfInfligthActive(this.activeTab);
    }
    // handle activetab is different from cart.deliveryMethod
    if (this.inflightCartEntries.length === 0 && this.groundCartEntries.length > 0) {
      this.activeTab = 'ground';
      if (this.cart.deliveryMethod !== this.activeTab) {
        this.switchTab(this.activeTab);
      }
    } else {
      if (this.cart && this.cart.deliveryMethod !== this.activeTab) {
        this.switchTab(this.activeTab);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes', changes);
    this.spiltCartWhileFlow1(this.entries);
  }

  getStoreOpenStatusOff(ev): void {
    if (ev === false) {
      this.isStoreOpenStatus = ev;
    }
  }

  switchTab(tabName: string): void {
    this.activeTab = tabName;
    this.amCartService.inflightFlowIfInfligthActive(tabName);
    this.amCartService.switchInflightOrGround(tabName);
  }

  spiltCartWhileFlow1(entries: OrderEntry[]) {
    if (entries.length === 0) {
      return;
    }
    this.inflightCartEntries = [];
    this.groundCartEntries = [];
    this.infligtAmount = 0;
    this.groundAmount = 0;
    if (entries.every((item) => item.hasOwnProperty('orderEntry'))) {
      this.entries = entries.map((consignmentEntry) => {
        const entry = Object.assign({}, (consignmentEntry as ConsignmentEntry).orderEntry);
        entry.quantity = consignmentEntry.quantity;
        return entry;
      });
    } else {
      this.entries = entries;
    }
    this.entries.forEach((entryItem) => {
      if (entryItem.product.collectionMethod === CollectionMethodType.INFLIGHT_PICKUP) {
        this.inflightCartEntries.push(entryItem);
        this.infligtAmount += entryItem.quantity;
      } else {
        this.groundCartEntries.push(entryItem);
        this.groundAmount += entryItem.quantity;
      }
    });
  }

  checkCartItemIfDelistOrOffline(entries: OrderEntry[]): boolean {
    let invalidCart = entries?.find((item) => item.isDelisted || item.isOffline);
    return invalidCart ? true : false;
  }
}
