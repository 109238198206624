import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError, SiteContextActions, withdrawOn } from '@spartacus/core';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AmOccCartEntryAdapter } from '../../occ/am-occ-cart-entry.adapter';
import { CartActions } from '../actions';

@Injectable()
export class AmCartRemoveEntriesEffects {
  private contextChange$ = this.actions$.pipe(
    ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE)
  );

  constructor(private actions$: Actions, private amOccCartEntryAdapter: AmOccCartEntryAdapter) {}

  removeCartEntries$: Observable<
    CartActions.CartRemoveEntriesSuccess | CartActions.CartRemoveEntriesFail | CartActions.LoadCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.CART_REMOVE_ENTRIES),
      map((action: CartActions.CartRemoveEntries) => action.payload),
      concatMap((payload) => {
        return this.amOccCartEntryAdapter.removeEntries(payload.userId, payload.cartId, payload.entriesNumber).pipe(
          map((data) => {
            return new CartActions.CartRemoveEntriesSuccess({
              ...payload,
              ...data
            });
          }),
          catchError((error) =>
            from([
              new CartActions.CartRemoveEntriesFail({
                ...payload,
                error: normalizeHttpError(error)
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId
              })
            ])
          )
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );
}
