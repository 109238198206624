import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseOption, ProductService, RoutingService } from '@spartacus/core';

@Component({
  selector: 'am-product-variant-size-selector',
  templateUrl: './product-variant-size-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductVariantSizeSelectorComponent implements OnInit {
  constructor(private productService: ProductService, private routingService: RoutingService) {}

  @Input() pageFrom: string;
  @Input() variants: BaseOption;
  variant;

  @Output() emitProductCode: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    this.variant = { ...this.variants };
  }

  onChange(variant: any): null {
    if (variant && variant.code) {
      if (this.pageFrom === 'mylistPage') {
        this.emitProductCode.emit(variant.code);
      } else {
        this.routingService.go({
          cxRoute: 'product',
          params: variant
        });
      }
    }
    return null;
  }

  getName(variant): void {
    if (variant.isTheOptionSecondLevel) {
      return variant.selected?.qualifier === 'variantTypeValue' || variant.selected?.qualifier === 'typeValue'
        ? variant.selected?.variantTypeName
        : variant.selected?.name;
    } else {
      return variant.selected?.qualifier === 'typeValue' ? variant.selected?.typeName : variant.selected?.name;
    }
  }
}
