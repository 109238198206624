import { Action } from '@ngrx/store';

export const CREATE_COMMON_CONFIG = '[Common] Create Common Config';

export const LOAD_COMMON_CONFIG = '[Common] Load Common Config';
export const LOAD_COMMON_CONFIG_FAIL = '[Common] Load Common Config Fail';
export const LOAD_COMMON_CONFIG_SUCCESS = '[Common] Load Common Config Success';

export class CreateCommon implements Action {
  readonly type = CREATE_COMMON_CONFIG;
  constructor(public payload?: any) {
    this.payload = payload;
  }
}

export class LoadCommonConfig implements Action {
  readonly type = LOAD_COMMON_CONFIG;
  constructor(public payload?: any) {
    this.payload = payload;
  }
}
export class LoadCommonConfigSuccess implements Action {
  readonly type = LOAD_COMMON_CONFIG_SUCCESS;
  constructor(public payload?: any) {
    this.payload = payload;
  }
}
export class LoadCommonConfigFail implements Action {
  readonly type = LOAD_COMMON_CONFIG_FAIL;
  constructor(public payload?: any) {
    this.payload = payload;
  }
}

export type CommonAction = LoadCommonConfig | LoadCommonConfigSuccess | LoadCommonConfigFail;
