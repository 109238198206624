import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FlightInfoService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import {
  CmsService,
  LanguageService,
  OccConfig,
  OccEndpointsService,
  RoutingService,
  WindowRef
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { SwiperOptions } from 'swiper';
import { NavigationBarItemModel } from './navigation-bar.model';

@Component({
  selector: 'am-navigation-bar',
  templateUrl: './navigation-bar.component.html'
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  selectedDisplay: boolean = false;
  selectedIndex: number = -1;
  selectedItem;
  selectedItemBackup;

  showFirstColumn: boolean = false;
  showSecondColumn: boolean = false;
  showThirdColumn: boolean = false;
  showFourthColumn: boolean = false;
  showFifthColumn: boolean = false;

  selectedItemArray = [];
  externalLinksSub: Subscription;

  swiperConfig: SwiperOptions = {
    navigation: true,
    loop: false,
    slidesPerView: 9,
    spaceBetween: 15,
    pagination: { clickable: true },
    grabCursor: true,
    breakpoints: {
      '768': {
        slidesPerView: 6
      },
      '992': {
        slidesPerView: 9
      }
    }
  };

  storeItem = {
    icon: {
      name: 'store',
      url: '/assets/images/icons/navigation/icon-catagory-store.svg'
    },
    name: 'navigation.store',
    children: [],
    entries: [
      {
        itemId: '',
        itemType: '',
        localizedUrl: 'main_cityplazaStore'
      }
    ]
  };

  hadLogin: boolean = false;
  signUrl = '';

  currentActiveItem: string;

  @ViewChild('mobileNavigate', { static: false }) mobileNavigate: ElementRef;
  isBrowser: boolean;
  data$: Observable<Array<any>> = this.flightInfoService.getPointOfServiceStatus().pipe(
    switchMap((d) => {
      this.flightStatusStorageSvc.inflightSalesChannel = d?.inflightSalesChannel;
      this.flightStatusStorageSvc.updateInflightSalesChannel(d?.inflightSalesChannel);
      return this.componentData.data$.pipe(
        filter((data) => !!data.navigationNode),
        map((data) => {
          if (this.flightStatusStorageSvc.isInflightPickFlow) {
            // data['navigationNode']['children'] = Array.from(data['navigationNode']['children']);
            if (data['navigationNode']['children'].length < 9) {
              this.isBrowser &&
                setTimeout(() => {
                  let swiperDom = this.el.nativeElement.querySelector('.swiper-wrapper');
                  this.render.setStyle(swiperDom, 'justify-content', 'center');
                }, 100);
            }
            return data;
          } else {
            // data['navigationNode']['children'] = Array.from(data['navigationNode']['children']).filter(
            //   (item: any) => !item.enableInflightFlag
            // );
            return {
              ...data,
              ...{
                navigationNode: {
                  children: Array.from(data['navigationNode']['children']).filter(
                    (item: any) => !item.enableInflightFlag
                  )
                }
              }
            };
          }
        }),
        tap(() => {
          this.initSelectedItemArrayData();
        })
      );
    })
  );
  subscription = new Subscription();
  constructor(
    public componentData: CmsComponentData<any>,
    protected occConfig: OccConfig,
    protected authService: AmAuthService,
    protected cmsService: CmsService,
    protected httpClient: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected languageService: LanguageService,
    private readonly el: ElementRef,
    public flightStatusStorageSvc: FlightStatusStorageService,
    protected routingService: RoutingService,
    protected countryService: CountryService,
    private readonly render: Renderer2,
    private cdr: ChangeDetectorRef,
    protected winRef: WindowRef,
    protected amConfigService: AmConfigService,
    protected flightInfoService: FlightInfoService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.getExternalLinks();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.authService.isUserLoggedIn().subscribe((res) => {
        this.hadLogin = res;
      })
    );
  }

  loginWithRedirect(): void {
    this.authService.loginWithRedirect();
  }

  redirectToSignup(): void {
    this.winRef.location.href = this.signUrl;
  }

  getExternalLinks() {
    this.subscription.add(
      (this.externalLinksSub = combineLatest([
        this.amConfigService.getCommonConfig(),
        this.amConfigService.getInflightEnvObs
      ]).subscribe(([externalLinks, isInflightObject]) => {
        if (!!isInflightObject && !!externalLinks) {
          this.signUrl = isInflightObject.data ? externalLinks.inflightSignUrl : externalLinks.signUrl;
          this.cdr.markForCheck();
        }
      }))
    );
  }

  initSelectedItemArrayData(): void {
    for (let i = 0; i < 6; i++) {
      this.selectedItemArray.push({
        data: null,
        show: false
      });
    }
  }

  protected getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  navMouseEnter(item: NavigationBarItemModel, index: number): void {
    this.selectedIndex = index;
    this.selectedItem = item;
    this.selectedItemBackup = item;

    if (this.selectedItem?.children && this.selectedItem?.children.length > 0) {
      this.selectedDisplay = true;
    }

    this.setNavColumn();
  }

  setNavColumn(): void {
    // if show sub column
    this.showFifthColumn = false;
    this.showSecondColumn = false;
    this.showThirdColumn = false;
    this.showFourthColumn = false;
    this.showFifthColumn = false;
    this.selectedItem.children.forEach((childLevel1) => {
      if (childLevel1.indexForDesktopNavColumn === 'FIRST_COLUMN') {
        this.showFirstColumn = true;
      } else if (childLevel1.indexForDesktopNavColumn === 'SECOND_COLUMN') {
        this.showSecondColumn = true;
      } else if (childLevel1.indexForDesktopNavColumn === 'THIRD_COLUMN') {
        this.showThirdColumn = true;
      } else if (childLevel1.indexForDesktopNavColumn === 'FOURTH_COLUMN') {
        this.showFourthColumn = true;
      } else if (childLevel1.indexForDesktopNavColumn === 'FIFTH_COLUMN') {
        this.showFifthColumn = true;
      }
    });
  }

  navMouseLeave(): void {
    this.selectedDisplay = false;
    this.selectedItem = null;
  }

  groupsMouseenter(): void {
    if (this.selectedItemBackup?.children && this.selectedItemBackup?.children.length > 0) {
      this.selectedDisplay = true;
      this.selectedItem = this.selectedItemBackup;
      this.setNavColumn();
    }
  }

  groupsMouseLeave(): void {
    this.selectedDisplay = false;
    this.selectedItem = null;
  }

  onNavigationItemClick(item: any, index, i?: number, categoryContainer?): void {
    if (!this.isBrowser) {
      return;
    }
    if (item?.children.length <= 0) {
      if (item?.entries?.length) {
        this.routingService.go({
          cxRoute: 'category',
          params: {
            code: item.entries[0].localizedUrl
          }
        });
      }
      return;
    }
    this.mobileNavigate.nativeElement.scrollTo(0, 0);

    if (i) {
      this.currentActiveItem = '#category-item-' + i;
    }

    this.selectedItemArray[index].data = item;
    this.selectedItemArray[index].show = true;

    setTimeout(() => {
      if (index === 0) {
        this.el.nativeElement.querySelector('#header').focus();
      } else {
        this.el.nativeElement.querySelector('#subHeader').focus();
      }
    }, 200);
  }

  backToHome(): void {
    if (!this.isBrowser) {
      return;
    }
    this.selectedItemArray[0].show = false;
    // this.mobileNavigate.nativeElement.scrollTo(0, 0);
    setTimeout(() => {
      this.el.nativeElement.querySelector(this.currentActiveItem).focus();
    }, 200);
  }

  goBack(index): void {
    if (!this.isBrowser) {
      return;
    }
    this.selectedItemArray[index].show = false;
    setTimeout(() => {
      this.el.nativeElement.querySelector('#header').focus();
    }, 200);
  }

  onLastElementTab(index): void {
    if (!this.isBrowser) {
      return;
    }
    setTimeout(() => {
      if (index === 1) {
        this.el.nativeElement.querySelector('#header').focus();
      } else {
        this.el.nativeElement.querySelector('#subHeader').focus();
      }
    }, 200);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
