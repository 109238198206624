import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import * as dayjs from 'dayjs';
import { Subscription, of } from 'rxjs';
import { switchMap, take, tap, timeout } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';

export interface MessageItem {
  key?: string;

  ctaLabel?: string;
  ctaLink?: string;
  messageDescription?: string;
  ifOpenANewTag?: string;
  hasPromotionCode?: boolean;
  promotionCode?: boolean;
  displayInEnvironment?: string;
  displayTheMessageForAllEnvironments?: string;
}

export interface MessageBoxData {
  name: string;
  interval: string;
  uid: string;
  // displayForInflightFlowOne?: string;
  messages:
    | {
        [key: string]: MessageItem;
      }
    | Array<MessageItem>;
}

export enum displayInEnvironmentOption {
  flow1 = 'Inflight_Flow_1_InflightPickUp_HD',
  flow2 = 'Inflight_Flow_2_HD',
  onground = 'Onground'
}
@Component({
  selector: 'am-message-box',
  templateUrl: './message-box.component.html'
})
export class MessageBoxComponent implements OnDestroy {
  messageItems = [];
  interval = 0;
  timer: any;
  componentId: string = '';
  activeIndex: number = 0;
  pause: boolean = false;
  showAlert = false;
  hideComponent = false;
  svgType = svgConfig;
  promotionElemets: Array<HTMLElement>;
  needUpdateCopyEvent: boolean = true;
  isBrowser: boolean = false;
  subscription = new Subscription();

  data$ = this.amConfigService.getPosStatus().pipe(
    switchMap((status) => {
      return this.componentData.data$.pipe(
        tap((data) => {
          this.componentId = 'notificationBar_' + data.uid;
          const expiredHideTime = this.detectHideTimeExpired();
          if (!expiredHideTime) {
            this.hideComponent = true;
            return;
          }
          this.messageItems = [];
          // console.log(this.flightStatusStorageSvc.isInflightPickFlow, 'isInflightPickFlow');
          const currentEnv = !this.flightStatusStorageSvc.getInFlightIndicator()
            ? displayInEnvironmentOption.onground
            : this.flightStatusStorageSvc.isInflightPickFlow
            ? displayInEnvironmentOption.flow1
            : displayInEnvironmentOption.flow2;
          if (data.messages) {
            for (let key of Object.keys(data.messages)) {
              const messageItem = data.messages[key] as MessageItem;
              if (messageItem.displayTheMessageForAllEnvironments === 'true') {
                this.organizeMessageStructure(messageItem, key);
              } else {
                if (messageItem.displayInEnvironment && messageItem.displayInEnvironment.indexOf(currentEnv) > 0) {
                  this.organizeMessageStructure(messageItem, key);
                }
              }
            }
            this.needUpdateCopyEvent = true;
            this.activeIndex = 0;
            if (this.isBrowser) {
              this.initAnimation(data);
            }
          }
        })
      );
    })
  );

  constructor(
    protected componentData: CmsComponentData<MessageBoxData>,
    protected cdr: ChangeDetectorRef,
    protected winRef: WindowRef,
    public flightStatusStorageSvc: FlightStatusStorageService,
    protected sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    protected amConfigService: AmConfigService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  organizeMessageStructure(messageItem: MessageItem, key: string) {
    if (messageItem.messageDescription) {
      let newMessageDesc = messageItem.messageDescription;
      let hasPromotionCode = false;
      if (messageItem.messageDescription.includes('{{promotionCode}}') && !!messageItem.promotionCode) {
        newMessageDesc = messageItem.messageDescription.replace(
          '{{promotionCode}}',
          this.getPromotionHtml(messageItem)
        );
        hasPromotionCode = true;
      }
      if (messageItem.messageDescription.includes('{{URL}}')) {
        newMessageDesc = newMessageDesc.replace('{{URL}}', this.getCtaHtml(messageItem));
      }
      if (messageItem.messageDescription.includes('{{amicon}}')) {
        newMessageDesc = newMessageDesc.replace('{{amicon}}', "<span class='am-icon-light-grey'></span>");
      }
      this.messageItems.push({
        ...messageItem,
        messageDescription: newMessageDesc,
        hasPromotionCode,
        key
      });
    }
  }

  getPromotionHtml(messageItem: MessageItem): string {
    return `<span class="promotionCodeWrapper" promotionCode=${messageItem.promotionCode}>
                    <span class="promotion_code">
                     ${messageItem.promotionCode}
                    </span>
                    <span class="icon-copy">
                    </span>
                  </span>`;
  }

  getCtaHtml(messageItem: MessageItem) {
    return `<a class="cta-link"
               href= ${messageItem.ctaLink}
               target=${messageItem.ifOpenANewTag !== 'false' ? '_blank' : '_self'}>
                    ${messageItem.ctaLabel}
            </a>`;
  }

  storeExpiredDate(event) {
    event.stopPropagation();
    const time = dayjs().endOf('date').valueOf();
    this.winRef.sessionStorage?.setItem(this.componentId, time + '');
    this.hideComponent = true;
  }

  detectHideTimeExpired() {
    const expiredTime = this.winRef.sessionStorage?.getItem(this.componentId) ?? 0;
    const time = new Date().getTime();
    return !!expiredTime ? time > Number(expiredTime) : true;
  }

  copyPromotionCode(event): void {
    if (!event || !event.currentTarget || !this.isBrowser) {
      return;
    }

    const storage = this.winRef.document?.createElement('textarea');
    storage.value = event.currentTarget.getAttribute('promotionCode')?.trim();
    const element = this.winRef.document?.querySelector('body');
    element.appendChild(storage);
    // Copy the text in the fake `textarea` and remove the `textarea`
    storage.select();
    storage.setSelectionRange(0, 99999);
    document?.execCommand('copy');
    element.removeChild(storage);
    this.showAlert = true;
    of(null)
      .pipe(timeout(1500), take(1))
      .subscribe(() => {
        this.showAlert = false;
        this.cdr.markForCheck();
      });
  }

  initAnimation(data: MessageBoxData) {
    this.interval = Number(data.interval) > 5 ? Number(data.interval) : 5;
    if (this.messageItems.length > 1) {
      this.runAnimation();
    } else {
      this.closeTimer();
    }
  }

  runAnimation(): void {
    this.closeTimer();
    this.timer = setInterval(() => {
      if (this.pause) {
        return;
      }
      this.activeIndex = this.activeIndex + 1 <= this.messageItems.length - 1 ? this.activeIndex + 1 : 0;
      this.cdr.markForCheck();
    }, 1000 * this.interval);
  }

  startAnimation() {
    this.pause = false;
  }

  pauseAnimation() {
    this.pause = true;
  }

  closeTimer() {
    this.timer && clearInterval(this.timer);
  }

  ngAfterViewChecked() {
    if (this.needUpdateCopyEvent === false) {
      return;
    }
    this.needUpdateCopyEvent = false;
    if (this.isBrowser) this.addPromotionCopyEvent();
  }

  addPromotionCopyEvent() {
    if (this.messageItems.length > 0) {
      this.promotionElemets = this.elementRef?.nativeElement?.querySelectorAll('.promotionCodeWrapper');
      if (this.promotionElemets && this.promotionElemets.length > 0) {
        this.promotionElemets?.forEach((element) => {
          element.removeEventListener('click', this.copyPromotionCode);
          element.addEventListener('click', this.copyPromotionCode.bind(this));
        });
      }
    }
  }

  ngOnDestroy() {
    this.closeTimer();
    if (this.isBrowser && this.promotionElemets && this.promotionElemets.length > 0) {
      this.promotionElemets.forEach((element) => {
        element.removeEventListener('click', this.copyPromotionCode);
      });
    }
    this.subscription?.unsubscribe();
  }
}
