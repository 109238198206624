import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatePersistenceService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { LoadConsentTemplatesSuccess, SetConsents } from '../store/actions/am-consents.action';
import { AmConsentsTemplateState, StateWithAmConsents } from '../store/am-consents-state';
import { getAmConsentsTemplateState } from '../store/selectors/am-consents.selector';

/**
 * Anonymous consents state synced to browser storage.
 */
export type SyncedAnonymousConsentsState = Partial<AmConsentsTemplateState>;

/**
 * Responsible for saving the anonymous consents data in browser storage.
 */
@Injectable({
  providedIn: 'root'
})
export class AmConsentsStatePersistenceService implements OnDestroy {
  protected subscription = new Subscription();

  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected store: Store<StateWithAmConsents>
  ) {}
  //   protected anonymousConsentsService: AnonymousConsentsService

  /**
   * Identifier used for storage key.
   */
  protected key = 'am-consents';

  /**
   * Initializes the synchronization between state and browser storage.
   */
  public initSync() {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.key,
        state$: this.getAuthState(),
        onRead: (state) => this.onRead(state)
      })
    );
  }

  /**
   * Gets and transforms state from different sources into the form that should
   * be saved in storage.
   */
  protected getAuthState(): Observable<SyncedAnonymousConsentsState> {
    return this.store.select(getAmConsentsTemplateState);
  }

  /**
   * Function called on each browser storage read.
   * Used to update state from browser -> state.
   */
  protected onRead(state: SyncedAnonymousConsentsState | undefined) {
    const templates = state?.templates;
    const consents = state?.consents;

    // templates
    if (templates) {
      this.store.dispatch(new LoadConsentTemplatesSuccess(templates ?? []));
    }

    // consents
    if (consents) {
      this.store.dispatch(new SetConsents(consents ?? []));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
