import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  OccRequestsOptimizerService,
  Product,
  ProductAdapter,
  PRODUCT_NORMALIZER,
  ScopedDataWithUrl,
  ScopedProductData
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AmOccProductAdapter implements ProductAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService
  ) {}

  load(productCode: string, scope?: string): Observable<Product> {
    return this.http.get(this.getEndpoint(productCode, scope)).pipe(this.converter.pipeable(PRODUCT_NORMALIZER));
  }

  loadMany(products: ScopedProductData[]): ScopedProductData[] {
    const scopedDataWithUrls: ScopedDataWithUrl[] = products.map((model) => ({
      scopedData: model,
      url: this.getEndpoint(model.code, model.scope)
    }));

    return this.requestsOptimizer.scopedDataLoad<Product>(scopedDataWithUrls).map((scopedProduct: any) => {
      return {
        ...scopedProduct,
        data$: scopedProduct.data$.pipe(
          map((val: any) => {
            // console.log(val, val.code, scopedProduct.code, 'pdp code');
            if (Object.keys(val).length === 0) {
              val.isNotValidUser = true;
            } else {
              val.isNotValidUser = false;
              if (val.code !== scopedProduct.code) {
                val.oldCode = val.code;
                val.code = scopedProduct.code;
              }
            }
            return val;
          }),
          this.converter.pipeable(PRODUCT_NORMALIZER)
        )
      } as ScopedProductData;
    });
  }

  protected getEndpoint(code: string, scope?: string): string {
    return this.occEndpoints.buildUrl('product', {
      urlParams: { productCode: code },
      scope
    });
  }
}
