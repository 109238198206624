export enum MediaType {
  youtube = 'youtube',
  vimeo = 'vimeo'
  // youku = "youku",
  // dailymotion = "dailymotion",
  // hls = "hls",
  // dash = "dash",
  // video = "video",
}

export enum MediaState {
  Unstarted = -1,
  Ended = 0,
  Playing = 1,
  Paused = 2,
  Buffering = 3,
  VideoCued = 5
}

export interface MediaConfig {
  type: MediaType;
  url?: string;
  id?: string;
  poster?: string;
  format?: string;
  youtubeList?: string;
}

export enum HTML5VideoType {
  mp4 = 'mp4',
  ogg = 'ogg',
  webm = 'webm'
}
