import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { VimeoPlayerComponent } from './vimeo-player.component';

@NgModule({
  declarations: [VimeoPlayerComponent],
  imports: [CommonModule, SafeCodeModule],
  exports: [VimeoPlayerComponent]
})
export class VimeoPlayerModule {}
