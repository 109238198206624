<ng-container *ngIf="!flightStatusStorageService.isInflightPickFlow">
  <div class="container">
    <div class="empty-cart">
      <h1 class="empty-cart-headline">
        {{ 'amEmptyCart.title' | cxTranslate }}
      </h1>
      <div class="voucher-error" *ngIf="voucherError$ | async as voucherError">
        <div class="warn-message">
          <i class="icon-warning"></i>
          <span>{{ voucherError.code | cxTranslate }}</span>
        </div>
      </div>
      <div class="empty-cart-content">
        <p class="title">
          {{ 'amEmptyCart.emptyCart' | cxTranslate }}
        </p>
        <p class="detail">
          {{ 'amEmptyCart.addItems' | cxTranslate }}
        </p>
        <a aria-label="Continue shopping,Go to home" href="/" class="btn btn-primary">
          {{ 'amEmptyCart.continueShopping' | cxTranslate }}
        </a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="flightStatusStorageService.isInflightPickFlow">
  <div class="container">
    <div class="cartPage-headline">
      {{ 'amCartDetails.titleFlow1' | cxTranslate }}
    </div>
  </div>
  <am-am-cart-detail-flow1-container></am-am-cart-detail-flow1-container>
</ng-container>
