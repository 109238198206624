import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CollectionMethod } from '@model/collection-method.enum';
import { Product, RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { RelationShip } from '../product-intro.component';

@Component({
  selector: 'am-relation-ship',
  templateUrl: './relation-ship.component.html'
})
export class RelationShipComponent implements OnChanges, OnDestroy {
  @Input() product: Product;
  @Input() currentCollectionMethod: string;
  @Input() pageFrom: string = '';

  @Output() emitProductCode: EventEmitter<string> = new EventEmitter();

  relationShipArray: RelationShip[];
  showDeliveryOptions: boolean = false;
  collectionMethod = CollectionMethod;

  svgType = svgConfig;
  protected subscription = new Subscription();

  constructor(
    protected flightStatusStorageService: FlightStatusStorageService,
    private routingService: RoutingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setRelationShips(this.product);
  }

  setRelationShips(product: Product): void {
    this.relationShipArray = [];
    if (product.relationships && product.relationships.length > 0) {
      this.showDeliveryOptions = true;
      this.relationShipArray = [
        ...product.relationships,
        {
          code: product.code,
          collectionMethod: product.collectionMethod,
          fulfillmentName: product.fulfillmentName
        }
      ];
      this.sortRelationShips();
      this.setRelationShipsIcon();
      this.setRelationShipsOnGround();
    }
  }

  sortRelationShips() {
    const inflightPickupItems = this.relationShipArray.filter((item) => {
      return item.collectionMethod === this.collectionMethod.INFLIGHT_PICKUP;
    });
    const shopPickItems = this.relationShipArray.filter((item) => {
      return item.collectionMethod === this.collectionMethod.SHOP_PICKUP;
    });
    const otherItems = this.relationShipArray.filter((item) => {
      return (
        item.collectionMethod !== this.collectionMethod.INFLIGHT_PICKUP &&
        item.collectionMethod !== this.collectionMethod.SHOP_PICKUP
      );
    });
    this.relationShipArray = [...inflightPickupItems, ...shopPickItems, ...otherItems];
  }

  setRelationShipsIcon(): void {
    this.relationShipArray = this.relationShipArray?.map((item) => {
      if (item.collectionMethod === this.collectionMethod.INFLIGHT_PICKUP) {
        return {
          ...item,
          icon: this.svgType.INFLIGHTICONGREY,
          selectedIcon: this.svgType.INFLIGHTICONGREEN,
          svgDescription: 'Inflight'
        };
      } else if (item.collectionMethod === this.collectionMethod.SHOP_PICKUP) {
        return {
          ...item,
          icon: this.svgType.INSTOREGREY,
          selectedIcon: this.svgType.INSTOREGREEN,
          svgDescription: 'Instore'
        };
      } else {
        return {
          ...item,
          icon: this.svgType.HOMEDBLACK,
          selectedIcon: this.svgType.HOMEDGREEN,
          svgDescription: 'Home delivery'
        };
      }
    });
  }

  setRelationShipsOnGround() {
    // inflight flow one, show all delivery mehtod
    // ground or inflight flow two, no show inflight delivery method
    // mylist page no show inflight delivery method
    if (
      (!this.flightStatusStorageService.isInflightPickFlow &&
        this.currentCollectionMethod !== this.collectionMethod.INFLIGHT_PICKUP) ||
      this.pageFrom === 'myListPage'
    ) {
      this.relationShipArray = this.relationShipArray.filter((item) => {
        return item.collectionMethod !== this.collectionMethod.INFLIGHT_PICKUP;
      });
    }
    // ground or inflight flow， relationshipArray product is current product
    if (this.relationShipArray.length === 1) {
      this.showDeliveryOptions = false;
    }
  }

  switchCollectionMethod(code: string): void {
    if (this.pageFrom === 'myListPage') {
      this.emitProductCode.emit(code);
      // this.productService.get(code).subscribe((res) => {
      //   this.product = res;
      //   if (this.product) {
      //     this.setRelationShips(this.product);
      //   }
      // });
    } else {
      this.routingService.go({
        cxRoute: 'product',
        params: { code }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
