import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { ThirdPartyLibService } from '@utils/thirdPartyLibLoader/third-party-lib.service';
import { CommomConfigMap } from '../config/store/common-state';

@Injectable({
  providedIn: 'root'
})
export class GlassBoxScriptsService {
  constructor(protected winRef: WindowRef, protected thirdPartyLibService: ThirdPartyLibService) {}

  addLib(amConfig: CommomConfigMap, isAccept: Boolean) {
    if (
      isAccept &&
      ((amConfig.inflight && amConfig.glassBoxInflightIsOn) || (!amConfig.inflight && amConfig.glassBoxOnGroundIsOn))
    ) {
      const scriptSource = amConfig.glassBoxScriptURI;
      if (!!scriptSource) {
        this.thirdPartyLibService.embedScript({
          src: scriptSource,
          attributes: {
            type: 'text/javascript',
            charset: 'utf-8',
            id: '_cls_detector',
            'data-clsconfig': `reportURI=${amConfig.glassBoxReportURL}`
          }
        });
      }
    }
  }
}
