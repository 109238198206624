<div class="recomment-product-categories-wrapper">
  <h3 class="title">
    {{ 'amRecommendProduct.moreToExplore' | cxTranslate }}
  </h3>
  <div class="content" *ngIf="details$ | async as detail">
    <div class="cateogry-item" *ngFor="let categoryItem of detail">
      <div>
        <a
          [href]="categoryItem.url"
          [target]="categoryItem.isOpenInNewTab === 'true' ? '_blank' : ''"
          [class.no-icon]="!categoryItem.thumbnail"
          data-cxlinktag-placement="category thumbanil"
          [attr.data-cxlinktag-element-position]="i"
          [attr.data-cxlinktag-name]="categoryItem?.categoryName"
          >{{ categoryItem.categoryName?.substr(0, 30) }}</a
        >
        <am-svg-container
          class="icon-light-grey-miles"
          [style]="style_arrow_icon"
          [type]="svgType.ARROWFORWARD"></am-svg-container>
      </div>
    </div>
  </div>
</div>
