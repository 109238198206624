import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { RoutingService, WindowRef } from '@spartacus/core';
import { AmActiveCartService } from 'src/app/amiredeem/pages/cart/service/active-cart.service';

@Component({
  selector: 'am-price-change-dialog',
  templateUrl: './price-change-dialog.component.html',
  styleUrls: ['./price-change-dialog.component.scss']
})
export class PriceChangeDialogComponent implements OnInit {
  @ViewChild('titleSel') titleSel: ElementRef;

  constructor(
    protected launchDialogService: AmLaunchDialogService,
    protected routingService: RoutingService,
    protected activeCartService: AmActiveCartService,
    protected winRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.winRef.isBrowser() &&
      setTimeout(() => {
        this.titleSel.nativeElement.focus();
      }, 200);
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
    this.activeCartService.reloadActiveCart();
    this.winRef.localStorage?.setItem('priceChange', 'Y');
    this.routingService.go('/cart');
  }

  onLastElementTab(): void {
    this.titleSel.nativeElement.focus();
  }
}
