import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmToolTipModule } from '@components/content/common/tool-tip/am-tool-tip.module';
import { AmItemCounterModule } from '@components/content/item-counter/item-counter.module';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { FavoriteNotifyReminderModule } from '../../../favorite-notify-products/components/favorite-notify-reminder/favorite-notify-reminder.module';
import { AmAddToCartDialogModule } from './add-to-cart-dialog/add-to-cart-dialog.module';
import { AmAddToCartComponent } from './add-to-cart.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CartSharedModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    FeaturesConfigModule,
    UrlModule,
    IconModule,
    I18nModule,
    AmItemCounterModule,
    KeyboardFocusModule,
    AmAddToCartDialogModule,
    MessageTipModule,
    SvgContainerModule,
    FavoriteNotifyReminderModule,
    AmToolTipModule
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ProductAddToCartComponent: {
          component: AmAddToCartComponent,
          data: {
            inventoryDisplay: false
          }
        }
      }
    })
  ],
  declarations: [AmAddToCartComponent],
  exports: [AmAddToCartComponent]
})
export class AmAddToCartModule {}
