import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { RemoveAllErrorDialogModule } from '../remove-all-error-dialog/remove-all-error-dialog.module';
import { CartMessagesComponent } from './cart-messages.component';
import { CustomTaxPopupModule } from './custom-tax-popup/custom-tax-popup.module';

@NgModule({
  declarations: [CartMessagesComponent],
  exports: [CartMessagesComponent],
  imports: [
    CommonModule,
    I18nModule,
    SafeCodeModule,
    SvgContainerModule,
    RemoveAllErrorDialogModule,
    CustomTaxPopupModule,
    MessageTipModule
  ]
})
export class AmCartMessagesModule {}
