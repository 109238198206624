import { Injectable } from '@angular/core';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { WindowRef } from '@spartacus/core';
import { ThirdPartyLibService } from '@utils/thirdPartyLibLoader/third-party-lib.service';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { filter, take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';

@Injectable({
  providedIn: 'root'
})
export class VerachatService {
  private resizeSubscription: Subscription = null;
  isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected winRef: WindowRef,
    protected authService: AmAuthService,
    protected countryService: CountryService,
    protected amConfigService: AmConfigService,
    protected thirdPartyLibService: ThirdPartyLibService
  ) {}
  protected subscription = new Subscription();
  addVeraChatLib(): void {
    this.subscription.add(
      this.amConfigService
        .getCommonConfig()
        .pipe(
          filter((data) => !!data),
          take(1)
        )
        .subscribe((amConfig) => {
          if (!!amConfig && amConfig.veraChatEnable) {
            const scriptSource = amConfig.veraChatJsUrl;
            const styleLink = amConfig.veraChatCssUrl;
            this.thirdPartyLibService.embedScript({
              src: scriptSource
            });
            // this.thirdPartyLibService.addScript(scriptSource);
            this.thirdPartyLibService.addStyleLink(styleLink);
          }
        })
    );
  }

  updateVeraChatLanguage(activeLanguage) {
    // @ts-ignore
    if (typeof (window as any).zE === 'function') {
      let veraChatLanguage = activeLanguage;
      if (activeLanguage === 'zh_TW') {
        veraChatLanguage = 'zh-tw';
      } else if (activeLanguage === 'zh') {
        veraChatLanguage = 'zh-cn';
      }
      (window as any).zE('webWidget', 'setLocale', veraChatLanguage);
    }
  }

  openVeraChat() {
    this.isOpen.next(true);
  }

  closeVeraChat() {
    this.isOpen.next(false);
  }

  OnDestroy() {
    this.resizeSubscription && this.resizeSubscription.unsubscribe();
    this.isOpen.complete();
  }
}
