import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AuthFlowRoutesService,
  AuthRedirectService,
  AuthRedirectStorageService,
  RoutingService
} from '@spartacus/core';
import UrlHelper from '@utils/url-helper';
import { Subscription } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { AmLoginService } from '../facade/am-login.service';

/**
 * Responsible for saving last accessed page (or attempted) before login and for redirecting to that page after login.
 */
@Injectable({
  providedIn: 'root'
})
export class AmAuthRedirectService extends AuthRedirectService {
  constructor(
    protected routing: RoutingService,
    protected router: Router,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected amLoginService: AmLoginService,
    protected authFlowRoutesService: AuthFlowRoutesService
  ) {
    super(routing, router, authRedirectStorageService, authFlowRoutesService);
    this.initialize();
  }

  protected subscriptions: Subscription = new Subscription();
  // override the init function for it can get the fetchLoginUrlObs objects
  protected init() {}
  protected initialize() {
    this.subscription = this.router.events
      .pipe(withLatestFrom(this.amLoginService.loginProgress$))
      .pipe(filter(([, status]) => status !== true))
      .subscribe(([event]) => {
        if (event instanceof NavigationEnd) {
          this.setRedirectUrl(event.urlAfterRedirects);
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  redirect() {
    this.subscriptions.add(
      this.authRedirectStorageService
        .getRedirectUrl()
        .pipe(take(1))
        .subscribe((redirectUrl) => {
          if (redirectUrl === undefined) {
            this.routing.go('/');
          } else {
            this.routing.goByUrl(redirectUrl);
          }
          this.clearRedirectUrl();
        })
    );
  }

  saveCurrentNavigationUrl(): void {
    const navigation = this.router.getCurrentNavigation();
    if (!navigation?.finalUrl) {
      this.setRedirectUrl(this.router.url);
      return;
    }

    const url = this.router.serializeUrl(navigation.finalUrl);
    this.setRedirectUrl(url);
  }

  setRedirectUrl(url: string): void {
    if (!this.authFlowRoutesService.isAuthFlow(url)) {
      url = this.replaceParamsFromInapp(url);
      this.authRedirectStorageService.setRedirectUrl(url);
    }
  }

  reportAuthGuard(): void {
    this.saveCurrentNavigationUrl();
  }

  replaceParamsFromInapp(url) {
    // to be removed: change for old params in hash
    url = decodeURIComponent(url);
    if (url.includes('#refreshToken') || url.includes('#LSapp')) {
      url = url.replace(/#refreshToken\/*/, '');
      url = url.replace(/#LSapp\/*/, '');
      return url;
    }
    const splitArr = url.split('?');
    const queryParamStr = splitArr.length > 1 ? splitArr[1] : '';
    if (queryParamStr.includes('refreshToken') || queryParamStr.includes('LSapp')) {
      const searchParamsObj = UrlHelper.parseQueryStringToObject(url);

      delete searchParamsObj['refreshToken'];
      delete searchParamsObj['LSapp'];
      let newParamsStr = UrlHelper.stringifyObjectToUrlParams(searchParamsObj);
      if (newParamsStr) {
        newParamsStr = '?' + newParamsStr;
      }
      return splitArr[0] + newParamsStr;
    }

    return url;
  }

  protected clearRedirectUrl(): void {
    this.authRedirectStorageService.setRedirectUrl(undefined);
  }
}
