<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title" aria-live="polite" aria-atomic="true">
          {{ 'amCartDetails.slider.promoDetails' | cxTranslate }}
        </div>
        <button type="button" class="close" (click)="closeModal('close current popup')">
          <span aria-hidden="true">
            <!-- <span class="icon-close-round"></span> -->
            <!-- <cx-icon type="CLOSE"></cx-icon> -->
            <svg class="icon-close">
              <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
            </svg>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main d-flex">
          <div class="promotion-img">
            <svg class="icon-tooltip" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path
                  fill="#272F38"
                  d="M2.614 5.262a.383.383 0 0 0-.379.387v12.217c0 .213.17.386.38.386h18.77c.21 0 .38-.173.38-.386V5.649a.384.384 0 0 0-.38-.387H2.616zm18.772 14.252H2.614c-.89 0-1.614-.74-1.614-1.648V5.649C1 4.74 1.724 4 2.614 4h18.772C22.276 4 23 4.74 23 5.65v12.216c0 .909-.724 1.648-1.614 1.648z" />
                <path
                  fill="#272F38"
                  fill-rule="nonzero"
                  d="M8.5 12.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0-1a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM14.5 15.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0-1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                <path fill="#333D47" d="M14.27 8h1.43l-6.57 7.5H7.7z" />
              </g>
            </svg>
          </div>
          <div class="section" *ngIf="promotion">
            <p class="name font-bold">{{ promotion.name }}</p>
            <p class="detail" *ngIf="promotion.description" [innerHTML]="promotion.description | safeHtml"></p>
            <ng-container *ngIf="promotion.promotionPeriod; else promotionPeriod">
              <p class="time font-bold">
                {{ 'amCartDetails.slider.promotion.popup.promotionPeriod' | cxTranslate }}
                {{ promotion.promotionPeriod }}
              </p>
            </ng-container>
            <ng-template #promotionPeriod>
              <ng-container *ngIf="promotion.startDate || promotion.endDate">
                <p class="time font-bold">
                  {{ 'amCartDetails.slider.promotion.popup.promotionPeriod' | cxTranslate }}
                  {{ promotion.startDate | amDate }} - {{ promotion.endDate | amDate }}
                </p>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="promotion.termsAndConditions">
              <a
                aria-label="Go to terms and conditions"
                class="terms-conditions font-size-xs"
                [href]="promotion.termsAndConditions | safeUrl">
                {{ 'amCartDetails.slider.promotion.popup.termsAndCondition' | cxTranslate }}
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
