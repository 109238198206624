import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Title } from '@spartacus/core';
import { AddressMap, DeliveryAddressInfo, Region } from '../../components/model/deliveryAddressInfo';
import {
  AmCheckoutState,
  AM_CHECKOUT_FEATURE,
  DeliveryAddressesState,
  StateWithAmCheckout
} from '../am-checkout-state';

const deliveryAddressInfo = (state: DeliveryAddressesState) => state.deliveyAndAddressInfo;
const titles = (state: DeliveryAddressesState) => state.deliveyAndAddressInfo.deliveryInfo.titles;
const addresses = (state: DeliveryAddressesState) => state.deliveyAndAddressInfo.address;

export const getCheckoutState: MemoizedSelector<StateWithAmCheckout, AmCheckoutState> =
  createFeatureSelector<AmCheckoutState>(AM_CHECKOUT_FEATURE);

export const getShippingAddressState: MemoizedSelector<StateWithAmCheckout, DeliveryAddressesState> = createSelector(
  getCheckoutState,
  (state: AmCheckoutState) => state.shippingAddress
);

export const getDeliveryAndAddressInfo: MemoizedSelector<StateWithAmCheckout, DeliveryAddressInfo> = createSelector(
  getShippingAddressState,
  (state: DeliveryAddressesState) => state.deliveyAndAddressInfo
);

export const getActiveAddressCode: MemoizedSelector<StateWithAmCheckout, string> = createSelector(
  getShippingAddressState,
  (state: DeliveryAddressesState) => state.activeAddressCode
);

export const getAddresses: MemoizedSelector<StateWithAmCheckout, AddressMap> = createSelector(
  getDeliveryAndAddressInfo,
  (state: DeliveryAddressInfo) => state.address
);

export const getTitles: MemoizedSelector<StateWithAmCheckout, Title[]> = createSelector(
  getDeliveryAndAddressInfo,
  (state: DeliveryAddressInfo) => state.deliveryInfo.titles
);

export const getRegions: MemoizedSelector<StateWithAmCheckout, Region[]> = createSelector(
  getDeliveryAndAddressInfo,
  (state: DeliveryAddressInfo) => state.deliveryInfo.regionMap
);
