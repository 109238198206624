import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitize: DomSanitizer) {}

  transform(v: string): SafeHtml {
    if (v === undefined) {
      return '';
    }
    return this.sanitize.bypassSecurityTrustHtml(v);
  }
}

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitize: DomSanitizer) {}

  transform(v: string): SafeHtml {
    if (v === undefined) {
      return '';
    }
    return this.sanitize.bypassSecurityTrustResourceUrl(v);
  }
}
