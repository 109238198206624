import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorHandler, HttpResponseStatus, Priority } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class AmGatewayTimeoutHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.GATEWAY_TIMEOUT;

  handleError(_request: HttpRequest<any>, errorResponse: HttpErrorResponse): void {
    // this.globalMessageService.add(
    //   { key: 'httpHandlers.gatewayTimeout' },
    //   GlobalMessageType.MSG_TYPE_ERROR
    // );
    if (isDevMode() || this.isSsr()) {
      console.warn(`An http 504 error occurred\n`, errorResponse.message);
    }
  }

  getPriority(): Priority {
    return Priority.NORMAL;
  }
}
