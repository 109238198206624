<!-- /wine checkbox -->
<!-- <ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="country === 'HK' && product.specialProductType === 'ELECTRONIC'"> -->
<ng-container *ngIf="electronicForm">
  <div *ngIf="pageFrom === 'mylistPage'" class="waste-appliance">
    {{ 'amWishProductList.wasteAppliance' | cxTranslate }}
  </div>
  <div class="regulated-electronics-equipment row" [formGroup]="electronicForm">
    <div class="col-xl-12 col-md-12">
      <div class="message-claim">
        {{ 'amProductDetails.electronic.messageClaim' | cxTranslate }}
      </div>
      <div
        class="error-message alert-error-message-ree"
        *ngIf="
          electronicForm.get('electronicRadio').dirty &&
          electronicForm.get('electronicRadio').errors &&
          !electronicForm.get('electronicRadio').valid
        ">
        <i class="icon-error"></i>
        <span class="error-message-text font-bold">
          {{ 'amProductDetails.electronic.errorMessage' | cxTranslate }}
        </span>
      </div>
      <ng-container *ngFor="let item of electronicArr">
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            [value]="item.value"
            formControlName="electronicRadio"
            [id]="item.value"
            name="electronicRadio" />
          <label [for]="item.value" class="field-label">
            {{ 'amProductDetails.electronic.' + item.label | cxTranslate }}
          </label>
          <ng-container *ngIf="item.lable === 'determined'">
            <div class="field-label contact-delivery" *ngIf="language === 'en'">
              {{ 'amProductDetails.electronic.info1' | cxTranslate }}
              <a aria-label="Go to contact details" id="go-to-contact-details font-bold" href="javascript:void(0);">
                {{ 'amProductDetails.electronic.info2' | cxTranslate }}
              </a>
              {{ 'amProductDetails.electronic.info3' | cxTranslate }}
            </div>
            <div class="field-label contact-delivery" *ngIf="language !== 'en'">
              {{ 'amProductDetails.electronic.info1' | cxTranslate }}
              <a aria-label="Go to contact details" id="go-to-contact-details" href="javascript:void(0);">
                {{ 'amProductDetails.electronic.info2' | cxTranslate }}
              </a>
              {{ 'amProductDetails.electronic.info3' | cxTranslate }}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <!-- REE checkbox-->
    <div class="col-xl-12 col-md-12 ree-checkbox">
      <div class="checkbox-group">
        <input class="agree-checkbox tnc-for-ree" type="checkbox" id="tncforree" formControlName="electronicCheckbox" />
        <ng-container *ngIf="language === 'ja' || language === 'ko'; else elseTncforree">
          <label class="agree-form" for="tncforree" id="tncforree-label">
            <span [class.ischeck]="electronicForm.get('electronicCheckbox').value" style="flex: 1">
              <a
                aria-label="Go to electronic terms and conditions"
                [href]="generalConditionUrl"
                target="_blank"
                class="font-bold">
                {{ 'amProductDetails.electronic.termsAndConditions1' | cxTranslate }}
              </a>
              &nbsp;<span>
                {{ 'amProductDetails.electronic.termsAndConditions2' | cxTranslate }}
              </span>
            </span>
          </label>
        </ng-container>
        <ng-template #elseTncforree>
          <label class="agree-form" for="tncforree" id="tncforree-label">
            <span [class.ischeck]="electronicForm.get('electronicCheckbox').value" style="flex: 1">
              <span> {{ 'amProductDetails.electronic.termsAndConditions1' | cxTranslate }} </span>&nbsp;
              <a
                aria-label="Go to terms and conditions, Opens in a new window"
                [href]="generalConditionUrl"
                target="_blank"
                class="font-bold">
                {{ 'amProductDetails.electronic.termsAndConditions2' | cxTranslate }}
              </a>
            </span>
          </label>
        </ng-template>
      </div>
      <am-message-tip
        *ngIf="!electronicForm.get('electronicCheckbox')?.value"
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true">
        <span class="font-bold">
          {{ 'amProductDetails.electronic.termsAndConditionsAlert' | cxTranslate }}
        </span>
      </am-message-tip>
    </div>
    <!-- /REE checkbox -->
  </div>
  <!-- </ng-container>
</ng-container> -->
</ng-container>
