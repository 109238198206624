import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmQualtricsComponent } from './qualtrics.component';

@NgModule({
  declarations: [AmQualtricsComponent],
  imports: [CommonModule],
  exports: [AmQualtricsComponent],
})
export class FeedbackModule {}
