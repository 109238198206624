import { Injectable } from '@angular/core';
import { CookieBarService } from '@components/layout/footer/cookie-bar/cookie-bar.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AmConsentConnector } from '../../connector/am-consents.connector';
import { AmConsentService } from '../../services/am-consents.service';
import { AmConsentsActions } from '../actions';

@Injectable()
export class AmConsentsEffects {
  constructor(
    private actions$: Actions,
    protected amConsentConnector: AmConsentConnector,
    protected amConsentService: AmConsentService,
    protected cookieBarService: CookieBarService
  ) {}

  loadConsentTemplates$: Observable<AmConsentsActions.AmConsentsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(AmConsentsActions.LOAD_CONSENT_TEMPLATES),
      switchMap(() => this.amConsentConnector.getTemplates()),
      switchMap((consentTemplateRes) => {
        if (consentTemplateRes && consentTemplateRes.consentTemplates) {
          let consents = this.amConsentService.generateConsents(consentTemplateRes.consentTemplates);
          consents = consents ?? [];
          if (!this.amConsentService.checkCdsConsentWhenAcceptedInCookieBar(consents)) {
            this.cookieBarService.deleteAmlCookies();
            this.cookieBarService.initCookieBar();
          }
          return [
            new AmConsentsActions.LoadConsentTemplatesSuccess(consentTemplateRes.consentTemplates),
            new AmConsentsActions.SetConsents(consents)
          ];
        }
      }),
      catchError((error) => of(new AmConsentsActions.LoadConsentTemplatesFail(normalizeHttpError(error))))
    )
  );
}
