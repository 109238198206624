import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { LanguageService, OccEndpointsService, Product, RoutingService } from '@spartacus/core';
import { getTagGroupAColor } from '@utils/getProductTagGroupAColor';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, take } from 'rxjs/operators';

export interface CategoryItem {
  code: string;
  name: string;
}

export interface RecommendData {
  products: Array<Product>;
  categories: Array<CategoryItem>;
}

@Component({
  selector: 'am-recommend-product-list',
  templateUrl: './recommend-product-list.component.html',
  styleUrls: ['./recommend-product-list.component.scss']
})
export class RecommendProductListComponent implements OnInit {
  recommendData$: Observable<RecommendData>;
  getTagGroupAColor = getTagGroupAColor;
  constructor(
    protected httpClient: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected routingService: RoutingService,
    protected languageService: LanguageService,
    protected countryService: CountryService
  ) {
    this.getRecommendProducts();
  }

  getRecommendProducts() {
    this.recommendData$ = combineLatest([this.languageService.getActive(), this.countryService.getActive()]).pipe(
      distinctUntilChanged(),
      switchMap(() => {
        return this.routingService
          .getRouterState()
          .pipe(take(1))
          .pipe(
            switchMap((router) => {
              const productCode = router.state.params.productCode;
              const geRecommendProductsUrl = this.occEndpointSvc.buildUrl('getRecommentProducts', {
                urlParams: { productCode }
              });
              return this.httpClient.get<RecommendData>(geRecommendProductsUrl);
            })
          );
      })
    );
  }

  ngOnInit(): void {}
}
