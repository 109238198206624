import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsmAdapter, CUSTOMER_LISTS_NORMALIZER, CUSTOMER_SEARCH_PAGE_NORMALIZER } from '@spartacus/asm/core';
import {
  AsmConfig,
  BindCartParams,
  CustomerListsPage,
  CustomerSearchOptions,
  CustomerSearchPage
} from '@spartacus/asm/root';
import {
  BaseSiteService,
  ConverterService,
  InterceptorUtil,
  normalizeHttpError,
  OccEndpointsService,
  User,
  USE_CUSTOMER_SUPPORT_AGENT_TOKEN
} from '@spartacus/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AmAsmAdapter implements AsmAdapter {
  private activeBaseSite: string;
  protected subscription = new Subscription();
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    protected config: AsmConfig,
    protected baseSiteService: BaseSiteService
  ) {
    this.subscription.add(this.baseSiteService.getActive().subscribe((value) => (this.activeBaseSite = value)));
  }

  customerSearch(options: CustomerSearchOptions): Observable<CustomerSearchPage> {
    const headers = InterceptorUtil.createHeader(USE_CUSTOMER_SUPPORT_AGENT_TOKEN, true, new HttpHeaders());
    let params: HttpParams = new HttpParams().set('baseSite', this.activeBaseSite).set('sort', 'byNameAsc');

    if (typeof options['query'] !== 'undefined') {
      params = params.set('query', '' + options.query);
    }

    if (typeof options['pageSize'] !== 'undefined') {
      params = params.set('pageSize', '' + options.pageSize);
    }

    //const url = this.occEndpointSvc.getBaseUrl() + '/'

    const url = this.occEndpointsService.buildUrl('asmCustomerSearch', {}, {});

    return this.http
      .get<CustomerSearchPage>(url, { headers, params })
      .pipe(this.converterService.pipeable(CUSTOMER_SEARCH_PAGE_NORMALIZER));
  }

  customerLists(): Observable<CustomerListsPage> {
    const headers = this.getHeaders();
    const params: HttpParams = new HttpParams().set('baseSite', this.activeBaseSite);

    const url = this.occEndpointsService.buildUrl(
      'asmCustomerLists',
      {},
      {
        baseSite: false,
        prefix: false
      }
    );

    return this.http.get<CustomerListsPage>(url, { headers, params }).pipe(
      catchError((error) => throwError(normalizeHttpError(error))),
      this.converterService.pipeable(CUSTOMER_LISTS_NORMALIZER)
    );
  }

  protected getHeaders(): HttpHeaders {
    return InterceptorUtil.createHeader(USE_CUSTOMER_SUPPORT_AGENT_TOKEN, true, new HttpHeaders());
  }

  bindCart({ cartId, customerId }: BindCartParams): Observable<unknown> {
    const headers = InterceptorUtil.createHeader(USE_CUSTOMER_SUPPORT_AGENT_TOKEN, true, new HttpHeaders());
    const params: HttpParams = new HttpParams()
      .set('baseSite', this.activeBaseSite)
      .set('cartId', cartId)
      .set('customerId', customerId);

    const url = this.occEndpointsService.buildUrl(
      'asmBindCart',
      {},
      {
        baseSite: false,
        prefix: false
      }
    );

    return this.http
      .post<void>(url, {}, { headers, params })
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }

  createCustomer(user): Observable<User> {
    const headers = this.getHeaders();
    const params: HttpParams = new HttpParams().set('baseSite', this.activeBaseSite);

    const url = this.occEndpointsService.buildUrl(
      'asmCreateCustomer',
      {},
      {
        baseSite: false,
        prefix: false
      }
    );
    return this.http
      .post<User>(url, user, { headers, params })
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
