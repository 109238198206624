import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store/src';
import { AmConsentsState } from '../am-consents-state';
import * as fromAmConsents from './am-consents.reducer';

export function getReducers(): ActionReducerMap<AmConsentsState> {
  return {
    amConsents: fromAmConsents.reducer
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<AmConsentsState>> = new InjectionToken<
  ActionReducerMap<AmConsentsState>
>('AmConsentReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers
};
