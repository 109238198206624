import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HotTags } from '@model/search.model';
import { map, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { SearchActions } from '@components/layout/header/am-site-context/store/actions';
import { SearchSelector } from '@components/layout/header/am-site-context/store/selectors';

@Injectable({
  providedIn: 'root'
})
export class AmSearchBoxService {
  private queryHotTags: ReplaySubject<any> = new ReplaySubject<any>(null);
  public queryHotTagsObs: Observable<any> = this.queryHotTags.asObservable();
  public searchCookies: string[] = [];

  constructor(private http: HttpClient, private occEndpointSvc: OccEndpointsService, private store: Store) {}

  getSearchBoxState() {
    return this.store.pipe(
      select(SearchSelector.getSearchState),
      tap((state) => {
        if (!state.loaded) {
          this.store.dispatch(new SearchActions.LoadHotTags());
        }
      })
    );
  }

  fetchHotSearchList() {
    const url = this.occEndpointSvc.buildUrl('searchHogTags');
    return this.http
      .get(url, {
        responseType: 'json'
      })
      .pipe(
        map((res: HotTags) => {
          const { popularTags } = res;
          let popularTagsList = [];
          if (popularTags.trim() !== '') {
            popularTagsList =
              popularTags?.split(',').filter((item) => {
                return !!item;
              }) ?? [];
          }

          return { ...res, popularTagsList };
        })
      );
  }
}
