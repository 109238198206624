import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CookieBarService } from '@components/layout/footer/cookie-bar/cookie-bar.service';
import { LiveChatService } from '@components/layout/footer/livechat/livechat.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { Country, Language, TranslationService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, SiteContextComponentService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CountryService } from '../../services/country/country.service';
import { AmLanguageService } from '../../services/language/language.service';
import { FlightInfoService, FlightItem } from './services/flight-info.service';
import {
  FlightStatusStorageService,
  INFLIGHT_NON_PICKUP_FLOW_VALUE,
  INFLIGHT_PICKUP_FLOW_VALUE
} from './services/flight-status-storage.service';

export enum SiteContextType {
  LANGUAGE = 'language',
  CURRENCY = 'currency'
}
export interface DropdownType {
  value?: string;
  label?: string;
}

@Component({
  selector: 'am-inflight-site-context-selector-popup',
  templateUrl: './inflight-site-context-selector-popup.component.html'
})
export class InflightSiteContextSelectorPopupComponent implements OnInit, OnDestroy {
  @ViewChild('dialog') dialog: ElementRef;

  // currencyItems: DropdownType[] = [
  //   { value: 'HKD', label: 'HKD' },
  //   { value: 'USD', label: 'USD' }
  // ];
  languageContext = SiteContextType.LANGUAGE;
  currencyContext = SiteContextType.CURRENCY;

  steps = {
    step1: 'welcome',
    step2: 'detail'
  };

  svgType = svgConfig;
  style_svg = 'width: 24px; height: 24px; margin-right: 14px';

  selectedLanguage: string;
  selectedCurrency: string = 'HKD';
  selectedCountry: string;
  logoMedia: any;
  flowIndicator: string;
  flightNumber: string;
  flightInfoDisplay: string;
  welcomeOnboardDisplay: string;
  currentStep = this.steps.step1;
  focused = false;

  isInflightFlow1: boolean = false;
  showHideSection: boolean = false;
  flightInfoStorage: FlightItem = {};
  showErrorFlag: boolean = false;
  openTooltipFlag: boolean = false;
  subscription: Subscription = new Subscription();
  countries: Country[];
  languages: Language[];
  renderListen: () => void;

  constructor(
    protected languageService: AmLanguageService,
    protected countryService: CountryService,
    protected siteContextService: SiteContextComponentService,
    protected cookieBarService: CookieBarService,
    protected liveChatService: LiveChatService,
    protected winRef: WindowRef,
    protected flightInfoService: FlightInfoService,
    protected translate: TranslationService,
    protected flightStatusStorageSvc: FlightStatusStorageService,
    private readonly render: Renderer2,
    protected launchDialogService: AmLaunchDialogService // protected amCartService: AmCartService
  ) {
    this.initData();
    this.updatePopupHeight();
  }

  initData() {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        this.languages = data.languages;
        this.countries = data.countries;
      })
    );
    this.subscription.add(
      this.languageService.getActive().subscribe((lan) => {
        this.selectedLanguage = lan;
      })
    );
    this.subscription.add(
      this.countryService.getActiveCountryEntity().subscribe((country) => {
        this.selectedCountry = country.isocode;
      })
    );
  }

  ngOnInit(): void {
    this.renderListen = this.render.listen('window', 'resize', (data) => {
      this.updatePopupHeight();
    });
  }

  updatePopupHeight(): void {
    this.winRef.isBrowser() &&
      setTimeout(() => {
        this.render.removeStyle(this.dialog.nativeElement, 'height');
        let currentWindowHeight = this.winRef.nativeWindow?.screen.availHeight;
        let currentDomHeight = this.winRef.document?.getElementById('language-flight-dialog').offsetHeight || 1060;
        // console.log(currentDomHeight, currentWindowHeight, 'height');
        if (currentWindowHeight < 1060) {
          currentWindowHeight = 1060;
        }
        if (currentWindowHeight > currentDomHeight) {
          this.render.setStyle(this.dialog.nativeElement, 'height', currentWindowHeight + 'px');
        }
      }, 100);
  }

  next(): void {
    const flightInfo = 'CX' + this.flightNumber;
    this.subscription.add(
      this.flightInfoService.getFlightList(flightInfo).subscribe((flightItem) => {
        if (!!flightItem) {
          const from = flightItem.flightOrigin.portCode;
          const to = flightItem.flightDest.portCode;
          this.flightInfoStorage = flightItem;
          this.subscription.add(
            this.translate
              .translate('languageAndFlightPopup.welcomeOnboard', { value0: flightInfo })
              .subscribe((item) => {
                this.welcomeOnboardDisplay = item;
              })
          );
          this.subscription.add(
            this.translate
              .translate('languageAndFlightPopup.flyingFromTo', { value0: from, value1: to })
              .subscribe((item) => {
                this.flightInfoDisplay = item;
              })
          );
        }
        this.isInflightFlow1 = flightItem && flightItem.inflightSalesChannel.indexOf('Online') > 0 ? true : false;
        if (!this.isInflightFlow1) {
          this.flightInfoStorage.posCode = 'CX' + this.flightNumber;
        }
        this.currentStep = this.steps.step2;
        this.updatePopupHeight();
      })
    );
  }

  back(): void {
    this.currentStep = this.steps.step1;
  }

  updateCountries() {
    this.subscription.add(
      this.countryService.getFormattedCountries().subscribe((countries) => {
        this.countries = countries;
      })
    );
  }

  inputFocused(e): void {
    this.focused = true;
    this.openTooltipFlag = false;
  }

  inputBlured(e): void {
    this.focused = false;
    this.removeLeadingZero();
    this.checkFlightNumber();
  }

  removeLeadingZero() {
    if (!this.flightNumber) {
      return;
    }
    this.flightNumber = this.flightNumber.replace(/\b(0+)/gi, '');
  }

  checkFlightNumber(): void {
    const numerical = /^[0-9]*$/;
    if (!this.flightNumber || !numerical.test(this.flightNumber)) {
      this.showErrorFlag = true;
    } else {
      this.showErrorFlag = false;
    }
  }

  startShoping(): void {
    // let activeFlow = this.isInflightPos ? 'inflight' : 'ground';
    // this.amCartService.switchInflightOrGround(activeFlow, false);
    this.setCurrentFlow();
    this.flightStatusStorageSvc.storeFlightStatus(this.flightInfoStorage, this.flowIndicator);
    this.winRef.location.href = this.winRef.location.href;
  }

  setCurrentFlow() {
    this.countryService.setActive(this.selectedCountry);
    let activeCurrency = this.countryService.getCurrencyFromActiveCountryEntity(this.selectedCountry);
    this.siteContextService.setActive(activeCurrency, this.currencyContext);
    if (this.isInflightFlow1) {
      // this.siteContextService.setActive(this.selectedCurrency, this.currencyContext);
      this.flowIndicator = INFLIGHT_PICKUP_FLOW_VALUE;
    } else {
      // this.countryService.setActive(this.selectedCountry);
      // let activeCurrency = this.countryService.getCurrencyFromActiveCountryEntity(this.selectedCountry);
      // this.siteContextService.setActive(activeCurrency, this.currencyContext);
      this.flowIndicator = INFLIGHT_NON_PICKUP_FLOW_VALUE;
    }
  }

  setActiveLanguage() {
    // this.siteContextService.setActive(this.selectedLanguage, this.languageContext);
    this.languageService.setActive(this.selectedLanguage);
    this.liveChatService.updateLiveChatLanguage(this.selectedLanguage);
    this.cookieBarService.updateCookieBarFunction(this.selectedLanguage);
    this.updateCountries();
  }

  getFooter() {
    return this.winRef.document?.getElementsByClassName('Footer')[0].innerHTML;
  }

  getHeader() {
    return this.winRef.document?.getElementsByClassName('SiteLogo')[0].innerHTML;
  }

  openTooltip(): void {
    this.openTooltipFlag = !this.openTooltipFlag;
  }

  selectListFocused(e): void {
    this.openTooltipFlag = false;
  }

  openShoppingTipModal(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.SHOPPING_TIP_DIALOG, undefined, undefined, {
      isFlow1Flag: this.isInflightFlow1,
      languages: this.languages,
      countries: this.countries
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  ngOnDestroy(): void {
    this.launchDialogService.closeDialog('');
    this.subscription?.unsubscribe();
    this.renderListen && this.renderListen();
  }
}
