<am-generic-link class="am-site-logo" *ngIf="component.data$ | async as data" [url]="data.logourl">
  <ng-container *ngIf="activeCountry$ | async as activeCountry">
    <ng-container *ngIf="activeCountry === 'HK' && !isFlightPickUpFlow">
      <am-media *ngIf="data.media" [container]="data.media" [role]="data.mime" [alt]="data.media.altText"></am-media>
    </ng-container>

    <ng-container *ngIf="activeCountry === 'TW' && !isFlightPickUpFlow">
      <am-media
        *ngIf="data.logoMediaTW"
        [container]="data.logoMediaTW"
        [role]="data.mime"
        [alt]="data.logoMediaTW.altText"></am-media>
    </ng-container>

    <ng-container *ngIf="activeCountry === 'CN' && !isFlightPickUpFlow">
      <am-media
        *ngIf="data.logoMediaCN"
        [container]="data?.logoMediaCN"
        [role]="data.mime"
        [alt]="data.logoMediaCN.altText"></am-media>
    </ng-container>

    <ng-container
      *ngIf="activeCountry !== 'HK' && activeCountry !== 'TW' && activeCountry !== 'CN' && !isFlightPickUpFlow">
      <am-media
        *ngIf="data.logoMedia"
        [container]="data.logoMedia"
        [role]="data.mime"
        [alt]="data.logoMedia.altText"></am-media>
    </ng-container>

    <ng-container *ngIf="isFlightPickUpFlow">
      <am-media
        *ngIf="data.logoMediaInFlight"
        [container]="data.logoMediaInFlight"
        [role]="data.mime"
        [alt]="data.logoMediaInFlight?.altText"></am-media>
    </ng-container>
  </ng-container>
</am-generic-link>
