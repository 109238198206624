import { isPlatformServer } from '@angular/common';
import { Component, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { AM_LOGGED } from 'server-token';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'asiamilesspartacusfront';
  isServerSide: boolean = false;

  constructor(@Inject(PLATFORM_ID) platformId: Object, @Optional() @Inject(AM_LOGGED) public amLogged) {
    this.isServerSide = isPlatformServer(platformId);
  }
}
