<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cx-dialog-body modal-body">
      <div class="title" #titleSel>
        <h3>{{ 'priceChangeDialog.priceChangeTitle' | cxTranslate }}</h3>
      </div>

      <div class="description">
        <span>
          {{ 'priceChangeDialog.priceChangeContent' | cxTranslate }}
        </span>
      </div>
      <div class="btn-confirm">
        <button class="button-primary" (click)="closeModal('click confirm button')" (blur)="onLastElementTab()">
          <a> {{ 'priceChangeDialog.priceChangeBtn' | cxTranslate }} </a>
        </button>
      </div>
    </div>
  </div>
</div>
