import { environment } from '@env/environment';
import { CdsConfig } from '@spartacus/cds';

export const AmCds: CdsConfig = {
  cds: {
    tenant: environment.cdsTenant,
    // baseUrl: 'https://htp740963945-main.api.us.context.cloud.sap',
    baseUrl: environment.cdsBaseUrl,
    endpoints: {
      strategyProducts: environment.cdsStrategyProducts
    },
    merchandising: {
      defaultCarouselViewportThreshold: 80
    },
    consentTemplateId: 'PROFILE',
    profileTag: {
      javascriptUrl: environment.cdsJavascriptUrl,
      // configUrl: 'https://tag.static.us.context.cloud.sap/config/5fc2a950-9312-11ec-9241-21cfcaf07828',
      configUrl: environment.cdsConfigUrl,
      allowInsecureCookies: true
    }
  }
};
