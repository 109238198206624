import { ConsentRecord, ConsentTemplate } from '../model/consent.model';

export const AM_CONSENTS_STORE_FEATURE = 'am-consents';
export const AM_CONSENTS = '[Am Consents] Consents';

export interface StateWithAmConsents {
  [AM_CONSENTS_STORE_FEATURE]: AmConsentsTemplateState;
}

export interface AmConsentsState {
  amConsents: AmConsentsTemplateState;
}

export interface AmConsentsTemplateState {
  templates: ConsentTemplate[];
  consents: ConsentRecord[];
}
