<div class="am-site-footer">
  <div class="footer-left-part">
    <div class="footer-navigation">
      <ul *ngIf="navigationItems$ | async as navigationItems">
        <ng-container *ngFor="let node of navigationItems; let i = index">
          <li *ngIf="node">
            <span *ngIf="i > 0" aria-hidden="true">|</span>
            <a href="{{ node?.url }}" [attr.aria-label]="setAriaLabel(navigationItems, i, node)">
              {{ node?.linkName }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="footer-copyright" *ngIf="data$ | async as data">
      <p *ngIf="data?.notice" class="copyright-info">
        {{ data.notice }}
      </p>

      <div class="footer-image-box" *ngIf="activeCountry$ | async as activeCountry">
        <ng-container *ngIf="activeCountry === 'HK' && !isFlightPickUpFlow">
          <am-media *ngIf="data?.firstLogo" [container]="data?.firstLogo" [alt]="data?.firstLogo?.altText"> </am-media>
          <am-media *ngIf="data?.secondLogo" [container]="data?.secondLogo" [alt]="data?.secondLogo?.altText">
          </am-media>
        </ng-container>

        <ng-container *ngIf="activeCountry === 'TW' && !isFlightPickUpFlow">
          <am-media *ngIf="data?.firstLogoTW" [container]="data?.firstLogoTW">
            [alt]="data?.firstLogoTW?.altText">
          </am-media>
          <am-media *ngIf="data?.secondLogoTW" [container]="data?.secondLogoTW">
            [alt]="data?.secondLogoTW?.altText">
          </am-media>
        </ng-container>

        <ng-container *ngIf="activeCountry === 'CN' && !isFlightPickUpFlow">
          <am-media *ngIf="data?.firstLogoCN" [container]="data?.firstLogoCN">
            [alt]="data?.firstLogoCN?.altText">
          </am-media>
          <am-media *ngIf="data?.secondLogoCN" [container]="data?.secondLogoCN">
            [alt]="data?.secondLogoCN?.altText">
          </am-media>
        </ng-container>

        <ng-container
          *ngIf="activeCountry !== 'HK' && activeCountry !== 'TW' && activeCountry !== 'CN' && !isFlightPickUpFlow">
          <am-media
            *ngIf="data?.firstLogoNonHK"
            [container]="data?.firstLogoNonHK"
            [alt]="data?.firstLogoNonHK?.altText">
          </am-media>
          <am-media
            *ngIf="data?.secondLogoNonHK"
            [container]="data?.secondLogoNonHK"
            [alt]="data?.secondLogoNonHK?.altText">
          </am-media>
        </ng-container>

        <ng-container *ngIf="isFlightPickUpFlow">
          <am-media *ngIf="data?.firstLogoInFlight" [container]="data?.firstLogoInFlight">
            [alt]="data?.firstLogoInFlight?.altText">
          </am-media>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="socialItems$ | async as socialItems" class="footer-social-share">
    <ng-container *ngFor="let item$ of socialItems; let i = index">
      <ng-container *ngIf="item$ | async as socialItem">
        <a
          *ngIf="!!socialItem?.media"
          class="am-site-logo"
          href="{{ socialItem.urlLink }}"
          [attr.tabindex]="0"
          [attr.data-am-event-component]="social_shares"
          attr.data-am-event-value="{{ socialItem.iconType }}"
          attr.shareLink="{{ socialItem.iconType }}"
          attr.aria-label="Visit {{
            socialItem.name
          }} page of iRedeem - Graphics - Opens in a new window. Operated by external parties and may not confirm to the same accessibility policies as Cathay Pacific.">
          <am-media
            [container]="socialItem.media"
            [alt]="socialItem.media?.name ?? 'Social share item ' + (i + 1)"></am-media>
        </a>
      </ng-container>
    </ng-container>
  </div>
  <am-livechat></am-livechat>
  <am-qualtrics></am-qualtrics>
</div>
