import { Injectable } from '@angular/core';
import { CookieBarService } from '@components/layout/footer/cookie-bar/cookie-bar.service';
import { AmConsentService } from '@core/user/consents/services/am-consents.service';
import { ProfileTagEventService, ProfileTagPushEventsService } from '@spartacus/cds';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { AmProfileTagLifecycleService } from '../../services/am-profile-tag-lifecycle.service';

@Injectable({
  providedIn: 'root'
})
export class AmTrackingService {
  protected subscription = new Subscription();

  constructor(
    protected profileTagLifecycleService: AmProfileTagLifecycleService,
    protected profileTagPushEventsService: ProfileTagPushEventsService,
    protected profileTagEventTracker: ProfileTagEventService,
    protected cookieBarService: CookieBarService,
    protected winRef: WindowRef,
    protected amConsentService: AmConsentService
  ) {}
  static factory(trackingService: AmTrackingService): () => void {
    const factoryFunction = () => {
      trackingService.trackEvents();
    };
    return factoryFunction;
  }
  trackEvents(): void {
    this.winRef.isBrowser() &&
      this.subscription.add(
        this.profileTagPushEventsService
          .getPushEvents()
          .pipe(
            withLatestFrom(
              this.profileTagLifecycleService.consentChanged().pipe(
                tap((event) => {
                  // always notify of consent changes
                  this.profileTagEventTracker.notifyProfileTagOfEventOccurrence(event);
                })
              )
            ),
            filter(([_event]) => true), //don't notify other events until consent is granted
            tap(([event]) => {
              if (this.amConsentService.hasAcceptedInCookieBar()) {
                this.profileTagEventTracker.notifyProfileTagOfEventOccurrence(event);
              } else {
                this.amConsentService.withdrawnCdsConsent();
                this.cookieBarService.initCookieBar();
              }
            })
          )
          .subscribe()
      );
    // this.subscription.add(
    //   this.profileTagLifecycleService
    //     .consentChanged()
    //     .pipe(
    //       tap((event) => {
    //         // always notify of consent changes
    //         this.profileTagEventTracker.notifyProfileTagOfEventOccurrence(event);
    //         if (!this.amConsentService.hasAcceptedInCookieBar()) {
    //           this.amConsentService.withdrawnCdsConsent();
    //           this.cookieBarService.initCookieBar();
    //         }
    //       })
    //     )
    //     .subscribe()
    // );
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
