import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { svgConfig } from '@config/am-svg-sprite.config';
import { ProductSearchPage, RoutingService, WindowRef } from '@spartacus/core';
import { BreakpointService, FacetService, ICON_TYPE } from '@spartacus/storefront';
import { asapScheduler, BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';
import { delayWhen, distinctUntilChanged, filter, observeOn, switchMap } from 'rxjs/operators';
import { AmFacetService, onlyCashQueryParm } from '../../services/facet.service';
import { AmProductListComponentService } from '../../services/product-list-component.service';
import { AmFacetListComponent } from './facet-list/am-facet-list.component';

@Component({
  selector: 'am-product-facet-navigation',
  templateUrl: './am-product-facet-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmProductFacetNavigationComponent implements OnDestroy {
  iconTypes = ICON_TYPE;
  protected CLOSE_DELAY = 300;

  svgType = svgConfig;

  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;

  @ViewChild('trigger') trigger: ElementRef<HTMLElement>;
  protected open$ = new BehaviorSubject(false);

  @ViewChild('facetList')
  facetList: AmFacetListComponent;

  isOpen$: Observable<boolean> = this.breakpointService.breakpoint$.pipe(
    observeOn(asapScheduler),
    switchMap(() => (this.hasTrigger ? this.open$ : of(true))),
    delayWhen((launched) => interval(launched ? 0 : this.CLOSE_DELAY))
  );

  /**
   * Emits the active state that indicates whether the facet list is activated. Activation
   * is related to the css, so that a animation or transition can visualize opening/closing
   * the list (i.e. dialog).
   */
  isActive$ = this.open$.pipe(
    // deffer emitting a new value to the next micro-task to ensure the active class is
    //  applied after the DOM is created
    observeOn(asapScheduler)
  );
  searchTextFromRouter: string;
  categoryCodeFromRouter: any;
  payByCashStatus: boolean;
  protected subscription = new Subscription();

  constructor(
    protected breakpointService: BreakpointService,
    protected productListComponentService: AmProductListComponentService,
    private readonly renderer: Renderer2,
    protected facetService: AmFacetService,
    protected routingService: RoutingService,
    protected facetService2: FacetService,
    protected winRef: WindowRef,
    protected router: Router
  ) {
    this.subscription.add(
      this.productListComponentService.showMobileFacetModalObs.subscribe((data) => {
        if (data) {
          this.launch();
        }
      })
    );
    this.listenOnPageChange();
  }

  listenOnPageChange() {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(
          filter(
            (router) =>
              router.nextState === undefined &&
              (router.state.context.type === 'CategoryPage' || router.state.context.id === 'search')
          ),
          distinctUntilChanged((pre, cur) => {
            if (cur.state.context.type === 'CategoryPage') {
              return pre.state.context.id === cur.state.context.id;
            }
            if (cur.state.context.id === 'search') {
              return pre.state.params.query === cur.state.params.query;
            }
          })
        )
        .subscribe((data) => {
          this.payByCashStatus = data.state.url.includes(onlyCashQueryParm) ? true : false;
          // this.facetList?.setPayByCashStatus(this.payByCashStatus);
          this.categoryCodeFromRouter = data.state.queryParams.categoryCode ?? data.state.params.categoryCode;
          if (data.state.context.id === 'search') {
            this.searchTextFromRouter = data.state.params.query;
          }
        })
    );
  }

  launch(): void {
    this.open$.next(true);
    this.renderer.addClass(this.winRef.document?.body, 'modal-open');
  }

  close(): void {
    this.open$.next(false);
    this.productListComponentService.closeFacetModal();
    this.trigger.nativeElement.focus();
  }

  /**
   * Indicates that the facet navigation should be open explicitely by a trigger.
   * This is fully controlled by CSS, where the trigger button can be hidden
   * (display:none) for certain screen sizes.
   */
  get hasTrigger(): boolean {
    if (!this.trigger || !this.trigger.nativeElement) {
      return false;
    }
    return this.trigger.nativeElement.offsetParent !== null;
  }
  launchFacetModal(): void {
    this.productListComponentService.openFacetModal();
  }

  updatePayByCashStatus(payByCashStatus: boolean): void {
    this.payByCashStatus = payByCashStatus;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    // this.facetService.removeAccordionStatus();
  }
}
