import { Component, OnInit } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CmsService, OccConfig, PageType, Product, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { getTagGroupAColor } from '@utils/getProductTagGroupAColor';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, map, mergeMap, shareReplay } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { v4 as uuidv4 } from 'uuid';
import { AmlMerchandisingCarouselService } from './service/aml-merchandising-carousel.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'aml-merchandising-carousel',
  templateUrl: './aml-merchandising-carousel.component.html'
})
export class AmlMerchandisingCarouselComponent implements OnInit {
  products$: Observable<Array<Product>>;
  title: string = '';
  uid: string = '';
  pageType: string = '';
  pageLabel: string = '';
  getTagGroupAColor = getTagGroupAColor;
  subscription = new Subscription();
  constructor(
    public componentData: CmsComponentData<any>,
    protected occConfig: OccConfig,
    private merchandisingCarouselService: AmlMerchandisingCarouselService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected cmsservice: CmsService,
    protected routingService: RoutingService,
    private winRef: WindowRef
  ) {
    this.cmsservice
      .getCurrentPage()
      .subscribe((data) => {
        this.pageLabel = data.label;
      })
      .unsubscribe();
  }

  navBtnId = uuidv4().substr(0, 8);

  lgSwiperConfig: SwiperOptions = {
    spaceBetween: 30,
    observer: true,

    navigation: {
      nextEl: `.swiper-button-next_${this.navBtnId}`,
      prevEl: `.swiper-button-prev_${this.navBtnId}`
    },
    slidesPerView: 4,
    slidesPerGroup: 4
  };

  mdSwiperConfig: SwiperOptions = {
    spaceBetween: 30,
    observer: true,

    navigation: {
      nextEl: `.swiper-button-md-next_${this.navBtnId}`,
      prevEl: `.swiper-button-md-prev_${this.navBtnId}`
    },
    slidesPerView: 3,
    slidesPerGroup: 3
  };

  smSwiperConfig: SwiperOptions = {
    spaceBetween: 30,
    observer: true,
    pagination: {
      clickable: true
    },
    slidesPerView: 1,
    slidesPerGroup: 1
  };

  ngOnInit(): void {
    this.products$ = this.routingService.getPageContext().pipe(
      mergeMap((val) => {
        return this.componentData.data$.pipe(
          distinctUntilKeyChanged('strategy'),
          mergeMap((data) => {
            this.title = data.title;
            this.uid = data.uid;
            if (this.flightStatusStorageService.isInflightPickFlow && val.type === PageType.PRODUCT_PAGE) {
              return of(null);
            }
            if (this.pageLabel === 'pagenotfound' || this.pageLabel === 'search') {
              return this.merchandisingCarouselService.getPageNotFoundData().pipe(
                map((products) => {
                  return products.map((product) => ({
                    ...product,
                    img1ForPLP: product.img1ForPLP ? this.getBaseUrl() + product.img1ForPLP : ''
                  }));
                })
              );
            } else {
              return this.merchandisingCarouselService.getMerchandisingData(data['uid'])
            }
          }),
          shareReplay({ bufferSize: 1, refCount: true })
        );
      })
    );
  }

  protected getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  setCodeOnSessionStorage(product: Product): void {
    let categoryCode = '';
    if (product.masterCategoryCode) {
      categoryCode = product.masterCategoryCode;
    } else {
      if (product.categories?.length > 0) {
        product.categories.forEach((category) => {
          if (category.code.startsWith('t2')) {
            categoryCode = category.code;
          }
        });
      }
    }
    if (!!product.code) {
      this.winRef.sessionStorage?.setItem(
        'productCode',
        (product?.baseOptions && product?.baseOptions[0]?.selected.code) ?? product.code
      );
      this.winRef.sessionStorage?.setItem(
        'plpAnchor',
        (product?.baseOptions && product?.baseOptions[0]?.selected.code) ?? product.code
      );
    }
    if (!!categoryCode) {
      this.winRef.sessionStorage?.setItem('categoryCode', categoryCode);
    }
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
