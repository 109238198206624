import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { FavoriteAndNotifyProductsMap, ProductListPageState } from '../favorite-notify-product-list-state';
import * as productListReducers from './favorite-notify-product-list.reducer';

// export function getReducers(): ActionReducerMap<ProductListPageState> {
//   return productListReducers.reducer;
// }

export function getReducers(): ActionReducerMap<FavoriteAndNotifyProductsMap> {
  return {
    favoriteAndNotifyProducts: productListReducers.reducer
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<ProductListPageState>> = new InjectionToken<
  ActionReducerMap<ProductListPageState>
>('ProductListReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers
};
