<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content custom-tax-modal">
    <div class="custom-tax-popup" *ngIf="cart$ | async as cart">
      <div class="headerline">
        <span [innerHtml]="cart.customTaxPopupTitle | safeHtml"></span>
        <span (click)="closeModal()">
          <am-svg-container
            [type]="svgType.CLOSEGREYSM"
            [style]="style_icon_close"
            svgDescription="close popup"></am-svg-container>
        </span>
      </div>
      <div class="custom-tax-content" [innerHtml]="cart.customTaxPopup | safeHtml"></div>
    </div>
  </div>
</div>
