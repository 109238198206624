import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FavoriteProduct } from '../../services/favorite-notify-products.service';
import {
  AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE,
  FavoriteAndNotifyProductsMap,
  ProductListPageState,
  StateWithFavoriteAndNotifyProducts
} from '../favorite-notify-product-list-state';

export const getMyListDataMap: MemoizedSelector<StateWithFavoriteAndNotifyProducts, FavoriteAndNotifyProductsMap> =
  createFeatureSelector<FavoriteAndNotifyProductsMap>(AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE);

export const getMyListPageData: MemoizedSelector<StateWithFavoriteAndNotifyProducts, ProductListPageState> =
  createSelector(getMyListDataMap, (state) => state.favoriteAndNotifyProducts);

export const getDisplayProducts: MemoizedSelector<StateWithFavoriteAndNotifyProducts, FavoriteProduct[]> =
  createSelector(getMyListPageData, (state) => state.displayEntries);

export const getFavoriteProducts: MemoizedSelector<StateWithFavoriteAndNotifyProducts, FavoriteProduct[]> =
  createSelector(getMyListPageData, (state) => state.favoriteEntries);

export const getNotifyProducts: MemoizedSelector<StateWithFavoriteAndNotifyProducts, FavoriteProduct[]> =
  createSelector(getMyListPageData, (state) => state.notifyMeEntries);

export const getActiveType: MemoizedSelector<StateWithFavoriteAndNotifyProducts, string> = createSelector(
  getMyListPageData,
  (state) => state.activeType
);
