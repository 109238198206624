import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FlightInfoService,
  PointOfServiceStoreStatus
} from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { select, Store } from '@ngrx/store';
import { CmsService, isNotNullable, LanguageService, OccEndpointsService, WindowRef } from '@spartacus/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, distinctUntilKeyChanged, filter, switchMap } from 'rxjs/operators';
import { CommonActions } from './store/actions';
import { CommomConfigMap } from './store/common-state';
import { getCommonConfigDetails } from './store/selectors/common.selector';

@Injectable({
  providedIn: 'root'
})
export class AmConfigService {
  private getInflightEnvSub: BehaviorSubject<{ data: boolean }> = new BehaviorSubject<{ data: boolean }>(null);
  public getInflightEnvObs: Observable<{ data: boolean }> = this.getInflightEnvSub.asObservable();

  private getPosStatusSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public getPosStatusObs: Observable<boolean> = this.getPosStatusSub.asObservable();

  envChangeSubscription = new Subscription();
  private _getOTPToggleStatus: boolean;

  subscription = new Subscription();
  constructor(
    protected occEndpointSvc: OccEndpointsService,
    protected httpClient: HttpClient,
    protected winRef: WindowRef,
    protected languageService: LanguageService,
    protected cmsService: CmsService,
    protected flightStatusStorageSvc: FlightStatusStorageService,
    protected flightInfoService: FlightInfoService,
    protected store: Store
  ) {}

  init() {
    this.subscription.add(
      this.getCommonConfig().subscribe((res) => {
        if (!res) {
          this.loadCommonConfig();
        } else {
          this.setCommonConfig(res);
        }
      })
    );
  }

  loadCommonConfig() {
    this.store.dispatch(new CommonActions.LoadCommonConfig());
  }

  getCommonConfig(): Observable<CommomConfigMap> {
    return this.store.pipe(select(getCommonConfigDetails));
  }
  listenAndSetConfigData() {
    this.listenAndSetInflightEnv();
  }

  setCommonConfig(data: CommomConfigMap) {
    this._getOTPToggleStatus = data.inflightOtpToggle;
    this.subscription.add(
      this.languageService
        .getActive()
        .pipe(distinctUntilChanged())
        .subscribe((language) => {
          this.setHtmlLangAttribute(language);
        })
    );
    this.storeFlightInfoExpiredPerriod(data);
  }

  setHtmlLangAttribute(lang) {
    if (this.winRef.document) {
      this.winRef.document.documentElement.lang = lang;
    }
  }

  listenAndSetInflightEnv(): void {
    const getIsInflightEnv = this.occEndpointSvc.buildUrl('getIsInflightEnv');
    this.subscription.add(
      this.cmsService
        .getCurrentPage()
        .pipe(
          filter((data) => {
            return !!data;
          }),
          distinctUntilKeyChanged('pageId'),
          switchMap((data) => {
            // console.log(data, 'pageID');
            const pk = this.flightStatusStorageSvc.getFlightPk();
            const url = !!pk && this.occEndpointSvc.buildUrl('getPointOfServiceStoreStatus', { urlParams: { pk } });
            return forkJoin([
              this.httpClient.get<{ data: boolean }>(getIsInflightEnv).pipe(
                catchError((error) => of(error)),
                filter((configObject) => !!configObject),
                distinctUntilChanged((prev, current) => {
                  return prev.data === current.data;
                })
              ),
              pk ? this.httpClient.get<PointOfServiceStoreStatus>(url).pipe(catchError((error) => of(error))) : of(null)
            ]);
          })
        )
        .subscribe(([inflightEnvObj, posStatusInfo]) => {
          if (inflightEnvObj?.data === false) {
            this.flightStatusStorageSvc.removeFlightStatus();
            this.flightInfoService.cleanFlightInfoTimer();
          }
          this.getInflightEnvSub.next(inflightEnvObj);
          if (posStatusInfo && posStatusInfo.statusCode != '00') {
            this.flightStatusStorageSvc.updateInflightSalesChannel(
              this.flightStatusStorageSvc.getInflightSalesChannel()
            );
            this.flightStatusStorageSvc.inflightSalesChannel = this.flightStatusStorageSvc.getInflightSalesChannel();
            this.getPosStatusSub.next(false);
          } else if (posStatusInfo && posStatusInfo.statusCode == '00') {
            this.flightStatusStorageSvc.updateInflightSalesChannel(posStatusInfo.data.inflightSalesChannel);
            this.flightStatusStorageSvc.inflightSalesChannel = posStatusInfo.data.inflightSalesChannel;
            this.getPosStatusSub.next(posStatusInfo.data.isStoreOpen);
          } else {
            this.getPosStatusSub.next(true);
          }
        })
    );
  }

  set inflightOtpToggle(v) {
    this._getOTPToggleStatus = v;
  }
  get inflightOtpToggle() {
    return this._getOTPToggleStatus;
  }
  getIsInflightEnv(): Observable<{
    data: boolean;
  }> {
    return this.getInflightEnvObs.pipe(filter(isNotNullable));
  }
  getPosStatus(): Observable<boolean> {
    return this.getPosStatusObs;
  }

  storeFlightInfoExpiredPerriod(data: CommomConfigMap): void {
    this.winRef.localStorage?.setItem('FLIGHT_INFO_EXPIRED_PERIOD', data?.flightInfoExpiredPeriod?.toString());
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.getInflightEnvSub.complete();
  }
}
