import { Component } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { map } from 'rxjs/operators';
import { AmCartService } from '../../service/am-cart.service';

@Component({
  selector: 'am-cart-empty',
  templateUrl: './cart-empty.component.html'
  // styleUrls:['./cart-empty.component.scss']
})
export class AmCartEmptyComponent {
  voucherError$ = this.amCartService
    .getVoucherError()
    .pipe(map((v) => (v.code === 'amErrorMapping.cartErrorPromoCodeMaxTry' ? v : null)));
  constructor(
    protected amCartService: AmCartService,
    protected flightStatusStorageService: FlightStatusStorageService
  ) {}
}
