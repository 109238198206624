<ng-container *ngIf="cart$ | async as cart">
  <div class="container">
    <div
      [ngClass]="{
        'cart-head-message':
          verifyOtpMaxTimeError ||
          (inflightPickUpError && isStoreOpenStatus !== false) ||
          ((cart.errorMegFlag || cart.insufficientBalance) &&
            !inflightPickUpError &&
            !(isStoreOpenStatus === false && activeTab === 'inflight')) ||
          (isStoreOpenStatus === false && activeTab === 'inflight') ||
          (cart.validationDatas?.length && topLowStockFlag(cart.validationDatas)) ||
          reeStatusAlertMesgFlag(cart) ||
          (accInfoMsgs && accInfoMsgs.length > 0) ||
          winRef.localStorage?.getItem('priceChange') ||
          (amConfigService.inflightOtpToggle && cart.isInflightEnv && !checkIfFlightPickUp(cart.deliveryMethod)) ||
          (cart.customTaxReminderBar && activeTab !== 'inflight') ||
          (cart?.flatRateDeliveryEnabled && cart?.containsHDorRL) ||
          showRemoveSuccessMessage ||
          showValidateZeroMessage ||
          isDelistedOrOffline ||
          cart.showFreeGiftAlertMsg
      }">
      <div class="balance-mobile" *ngIf="user$ | async as user" #balanceMobile>
        <span>{{ 'amCartDetails.accountBalance' | cxTranslate }}</span>
        <span class="balance">
          <span class="am-icon-grey"></span>
          {{ user.customerRecord?.account | number }}
        </span>
      </div>
      <!-- <div class="cartPage-headline">
        {{ 'amCartDetails.titleItems' | cxTranslate : { count: cart.totalUnitCount } }}
      </div> -->
      <div class="cartPage-error-message">
        <!-- top error message -->
        <div *ngIf="verifyOtpMaxTimeError">
          <am-message-tip
            messageType="error"
            [iconType]="svgType.ICONWARNINGRED"
            [messageContent]="'amCartDetails.error.verifyOtpMaxTime' | cxTranslate"></am-message-tip>
        </div>

        <div *ngIf="inflightPickUpError && isStoreOpenStatus !== false">
          <am-message-tip [iconType]="svgType.ICONWARNINGRED" messageType="error">
            <div>
              {{ 'amCartItems.error.deliveryMethodUnavailable' | cxTranslate }}
              <a
                aria-label="Please remove the item."
                href="javascript:void(0)"
                class="font-bold link-green"
                (click)="removeUnavailable(cart)">
                {{ 'amCartItems.error.removeUnavailable' | cxTranslate }}
              </a>
            </div>
          </am-message-tip>
        </div>
        <div
          *ngIf="
            (cart.errorMegFlag || cart.insufficientBalance) &&
            !inflightPickUpError &&
            !(isStoreOpenStatus === false && activeTab === 'inflight')
          ">
          <am-message-tip
            messageType="error"
            [iconType]="svgType.ICONWARNINGRED"
            [messageContent]="'amCartDetails.error.common.message' | cxTranslate"></am-message-tip>
        </div>
        <!-- class="info-box warning-info d-flex" -->
        <div *ngIf="isStoreOpenStatus === false && activeTab === 'inflight'">
          <am-message-tip
            messageType="warning-yellow"
            [iconType]="svgType.ICONWARNINGYELLOW"
            [messageContent]="'amCartItems.error.inflightShopClose' | cxTranslate"></am-message-tip>
        </div>
        <!-- /top error message -->
        <div *ngIf="showValidateZeroMessage">
          <am-message-tip
            messageType="error"
            [iconType]="svgType.ICONWARNINGRED"
            [messageContent]="'amCartDetails.error.payMilesAndCashCheckoutError' | cxTranslate"></am-message-tip>
        </div>

        <!-- top alert message -->
        <div *ngIf="cart.validationDatas?.length && topLowStockFlag(cart.validationDatas)">
          <am-message-tip
            [iconType]="svgType.ICONWARNINGYELLOW"
            messageType="warning-yellow"
            [messageContent]="'amCartDetails.alert.insufficientStock' | cxTranslate"></am-message-tip>
        </div>
        <!-- class="info-box warning-info d-flex"  -->
        <div *ngIf="reeStatusAlertMesgFlag(cart)">
          <am-message-tip
            [iconType]="svgType.ICONWARNINGYELLOW"
            messageType="warning-yellow"
            [messageContent]="'amCartItems.alert.reeNotInHkEeMsg' | cxTranslate"></am-message-tip>
        </div>
        <!-- /top alert message -->
        <div class="" *ngFor="let msg of accInfoMsgs; let i = index">
          <div class="remove-item-info info-box success-info">
            <span style="padding-left: 5px">
              <i class="icon-success"></i>
              <span class="text success-text">
                {{ msg.type | cxTranslate }}
              </span>
            </span>
            <button aria-label="Close current message" class="link" (click)="closeMessage(i)">
              <svg
                class="icon-close"
                tabindex="0"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                  <g>
                    <g transform="translate(2.000000, 2.000000)">
                      <path
                        d="M10,8.79200422 L18.7920042,0 L20,1.20799578 L11.2079958,10 L20,18.7920042 L18.7920042,20 L10,11.2079958 L1.20799578,20 L0,18.7920042 L8.79200422,10 L0,1.20799578 L1.20799578,0 L10,8.79200422 Z"></path>
                      <path
                        fill="#116F9A"
                        fill-rule="nonzero"
                        d="M10,8.79200422 L18.7920042,0 L20,1.20799578 L11.2079958,10 L20,18.7920042 L18.7920042,20 L10,11.2079958 L1.20799578,20 L0,18.7920042 L8.79200422,10 L0,1.20799578 L1.20799578,0 L10,8.79200422 Z"></path>
                      <g>
                        <mask>
                          <path
                            d="M10,8.79200422 L18.7920042,0 L20,1.20799578 L11.2079958,10 L20,18.7920042 L18.7920042,20 L10,11.2079958 L1.20799578,20 L0,18.7920042 L8.79200422,10 L0,1.20799578 L1.20799578,0 L10,8.79200422 Z"></path>
                        </mask>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <!-- price change message -->
        <div *ngIf="winRef.localStorage?.getItem('priceChange')">
          <am-message-tip
            messageType="success"
            iconClass="icon-success"
            [showCloseBtn]="true"
            [messageContent]="'amErrorMapping.cartAlertPriceChange' | cxTranslate"
            (closeMsgEmit)="closeMessage(0, 'priceChange')"></am-message-tip>
        </div>
        <!-- when remove item successfully message -->
        <ng-container *ngIf="showRemoveSuccessMessage">
          <am-message-tip
            messageType="success"
            iconClass="icon-success"
            [showCloseBtn]="true"
            [messageContent]="'amCartDetails.alert.removeProduct' | cxTranslate"
            (closeMsgEmit)="closeRemoveMessage()"></am-message-tip>
        </ng-container>

        <!-- delist or out of online period error message -->
        <ng-container *ngIf="isDelistedOrOffline">
          <am-message-tip [iconType]="svgType.ICONWARNINGRED" messageType="error">
            <div>
              {{ 'amCartItems.error.delistOrOffline' | cxTranslate }}
              <a
                aria-label="Please remove the item."
                href="javascript:void(0)"
                class="font-bold link-green"
                (click)="removeDelistOrOffline(cart)">
                {{ 'amCartItems.error.removeItems' | cxTranslate }}
              </a>
            </div>
          </am-message-tip>
        </ng-container>

      </div>

      <div class="cartPage-warning-message">
        <div *ngIf="cart.showFreeGiftAlertMsg">
          <am-message-tip
            messageType="warning-yellow"
            [iconType]="svgType.ICONWARNINGYELLOW"
            [messageContent]="'amCartDetails.alert.unavailableFreeGift' | cxTranslate"></am-message-tip>
        </div>
      </div>

      <div
        *ngIf="amConfigService.inflightOtpToggle && cart.isInflightEnv && !checkIfFlightPickUp(cart.deliveryMethod)"
        class="bar-container">
        <div class="post-flight">
          <span class="font-bold imported-duties-lable"
            >{{ 'amDelivery.notice.postFlightVerification' | cxTranslate }}
          </span>
          <span>
            {{ 'amDelivery.notice.inflight' | cxTranslate }}
          </span>
        </div>
      </div>
      <div *ngIf="cart?.flatRateDeliveryEnabled && cart?.containsHDorRL" class="bar-container">
        <div class="flat-rate-delivery">
          <ng-container *ngIf="cart?.allHDOrRLFreeDeliverySKU; else noFreeDeliverySKU">
            <span class="font-bold imported-duties-lable">{{
              'amCartDetails.flatRateDelivery.deliveryFee' | cxTranslate
            }}</span>
            <span>{{ 'amCartDetails.flatRateDelivery.freeHomeDelivery' | cxTranslate }}</span>
          </ng-container>
          <ng-template #noFreeDeliverySKU>
            <ng-container *ngIf="cart?.appliedDeliveryFeePromotion; else noFreeDelivery">
              <span class="font-bold imported-duties-lable">{{
                'amCartDetails.flatRateDelivery.deliveryFee' | cxTranslate
              }}</span>
              <span>{{ 'amCartDetails.flatRateDelivery.freeHomeDelivery' | cxTranslate }}</span>
            </ng-container>

            <ng-template #noFreeDelivery>
              <span class="font-bold imported-duties-lable">{{
                'amCartDetails.flatRateDelivery.deliveryFee' | cxTranslate
              }}</span>
              <span
                class="no-free-delivery"
                [innerHtml]="
                  'amCartDetails.flatRateDelivery.enjoyFreeDelivery'
                    | cxTranslate
                      : {
                          current: cart.totalPriceCash.currencyIso,
                          totalDeliveryFeeCash: cart?.flatRateProgressBarData?.remainingCash | number : '1.0',
                          totalDeliveryFeeMiles: cart?.flatRateProgressBarData?.remainingMiles | number : '1.0'
                        }
                "></span>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div *ngIf="cart.customTaxReminderBar && activeTab !== 'inflight'" class="bar-container">
        <div class="imported-duties">
          <span class="font-bold imported-duties-lable">{{
            'amCartDetails.customTax.importedDuties' | cxTranslate
          }}</span>
          <span [innerHtml]="cart.customTaxReminderBar"></span>
          <span (click)="openCustomPopup()">
            <am-svg-container
              [type]="svgType.ICONINFOPROMOTION"
              [style]="style_icon_imported_duties"
              svgDescription="icon-warning"></am-svg-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
