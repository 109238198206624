<ng-container *ngIf="cart$ | async as cart">
  <div class="container">
    <div class="cartPage-headline">
      <ng-container *ngIf="flightStatusStorageService.isInflightPickFlow">{{
        'amCartDetails.titleFlow1' | cxTranslate
      }}</ng-container>
      <ng-container *ngIf="!flightStatusStorageService.isInflightPickFlow">{{
        'amCartDetails.titleItems' | cxTranslate : { count: cart.totalUnitCount }
      }}</ng-container>
    </div>
  </div>
  <ng-container *ngIf="entries$ | async as entries">
    <ng-container *ngIf="!flightStatusStorageService.isInflightPickFlow; else flow1CartDetail">
      <!-- <div class="light-grey-background">
        <am-cart-messages
          [isStoreOpenStatus]="isStoreOpenStatus"
          [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(cart)"></am-cart-messages>
        <div class="container">
          <div class="cart-item-container">
            <div class="cart-item-left">
              <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
                <am-cart-item-list
                  [items]="entries"
                  [cartIsLoading]="!(cartLoaded$ | async)"
                  [isInflightEnv]="cart.isInflightEnv"
                  [isStoreOpenStatus]="isStoreOpenStatus"
                  [promotionLocation]="promotionLocation"
                  [validationDatas]="cart.validationDatas"></am-cart-item-list>
              </div>
            </div>

            <div class="cart-item-right">
              <am-cart-totals
                [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(cart)"
                [isStoreOpenStatus]="isStoreOpenStatus"
                (isStoreOpenStatusOff)="getStoreOpenStatusOff($event)"></am-cart-totals>
            </div>
          </div>
        </div>
      </div> -->
      <am-cart-detail-nonflow1-container
        [isStoreOpenStatus]="isStoreOpenStatus"
        [cart]="cart"
        [entries]="entries"
        [cartLoaded]="!(cartLoaded$ | async)"></am-cart-detail-nonflow1-container>
    </ng-container>
    <ng-template #flow1CartDetail>
      <am-am-cart-detail-flow1-container
        [isStoreOpenStatus]="isStoreOpenStatus"
        [cart]="cart"
        [entries]="entries"
        [cartLoaded]="!(cartLoaded$ | async)"></am-am-cart-detail-flow1-container>
    </ng-template>
  </ng-container>
</ng-container>
