<ng-container *ngIf="data$ | async as data">
  <div
    *ngIf="messageItems?.length && !hideComponent"
    class="am-message-box"
    (keyup.tab)="closeTimer()"
    [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }">
    <div class="message-wrapper" (mouseenter)="pauseAnimation()" (mouseleave)="startAnimation()">
      <div
        class="message-item-box"
        *ngFor="let item of messageItems"
        [ngClass]="{ active: item.key === messageItems[activeIndex]?.key }">
        <div class="content-wrapper">
          <div class="row-wrapper">
            <div class="message-info">
              <p class="description" [attr.hasPromotionCode]="item.hasPromotionCode ? true : false">
                <span [outerHTML]="item.messageDescription | safeHtml"></span>
              </p>
            </div>
            <div class="closs-button-wrapper" (click)="storeExpiredDate($event)">
              <span class="close-button"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-successfully-tip" [ngClass]="{ show: showAlert }">
      <span>
        {{ 'notificationBar.copied' | cxTranslate }}
      </span>
      <span class="icon-alert-success"></span>
    </div>
  </div>
</ng-container>
