import { I18nConfig } from '@spartacus/core';
import { amTranslationChunksConfig } from './am-translate-chunks.config';

export const AmI18n: I18nConfig = {
  i18n: {
    backend: {
      loader: (lng: string, ns: string) => import(`../../../assets/i18n/${lng}/${ns}.json`)
    },
    chunks: amTranslationChunksConfig,
    fallbackLang: 'en'
  }
};
