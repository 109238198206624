import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[amRichtext]'
})
export class RichTextDirective {
  @Input() amRichtext = '';

  protected replaceMap = {
    '{{amicon}}': "<span class='am-icon-light-grey'></span>"
  };

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amRichtext.currentValue !== changes.amRichtext?.previousValue) {
      this.setHtml();
    }
  }

  setHtml() {
    let renderText = '';
    Object.keys(this.replaceMap).forEach((key) => {
      renderText = this.amRichtext.replace(key, this.replaceMap[key]);
    });
    this.el.nativeElement.innerHTML = renderText;
  }
}
