import { registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeZh from '@angular/common/locales/zh';
import localzh_Hant from '@angular/common/locales/zh-Hans-HK';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AmlSiteContextInterceptor } from '@components/layout/header/am-site-context/interceptor/am.context.interceptor';

import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from '@spartacus/storefront';
import { SharerMethod } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ErrorMappingResponseInterceptor } from './amiredeem/common/interceptor/response-error-transform/response-error-transform.intercept';

import { AmModule } from './amiredeem/am.module';
import { KongApikeyInterceptor } from './amiredeem/common/interceptor/kong-api-key/kong-api-key.interceptor';
import { SwBypassInterceptor } from './amiredeem/common/interceptor/service-worker/sw-bypass.interceptor';
import './amiredeem/model/index';
import { FavoriteNotifyReminderModule } from './amiredeem/pages/favorite-notify-products/components/favorite-notify-reminder/favorite-notify-reminder.module';
import { FavoriteNotifyProductStoreModule } from './amiredeem/pages/favorite-notify-products/store/favorite-notify-product-store.module';
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';

registerLocaleData(localzh_Hant);
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeZh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    FavoriteNotifyReminderModule,
    FavoriteNotifyProductStoreModule,
    ShareButtonsModule.withConfig({
      sharerMethod: SharerMethod.Window
    }),
    AmModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      features: {
        pause: false,
        lock: true,
        persist: true
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMappingResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KongApikeyInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SwBypassInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: AmlSiteContextInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
