<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title" aria-live="polite" aria-atomic="true">
          {{ 'languageAndFlightPopup.inflightShoppingTipTitle' | cxTranslate }}
        </div>
        <button type="button" class="close" (click)="closeModal('close current popup')">
          <am-svg-container [type]="svgType.CLOSEGREYSM"></am-svg-container>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main">
          <div class="tab-title-container" *ngIf="isFlow1Flag">
            <button
              (click)="switchTab('inflight')"
              [ngClass]="{ 'active-btn': activeTab === 'inflight' }"
              aria-label="Inflight delivery tab">
              {{ 'languageAndFlightPopup.inflightTab' | cxTranslate }}
            </button>
            <button
              (click)="switchTab('ground')"
              [ngClass]="{ 'active-btn': activeTab !== 'inflight' }"
              aria-label="On ground delivery tab">
              {{ 'languageAndFlightPopup.groundTab' | cxTranslate }}
            </button>
          </div>
          <div class="tab-content">
            <div class="shopping-step">
              <ng-container *ngIf="activeTab === 'inflight' && isFlow1Flag">
                <div class="shopping-step-item">
                  <am-message-tip [iconType]="svgType.SHOPPING" iconDescription="" hasBackgroudColor="false">
                    <div class="shopping-step-title">
                      1. {{ 'languageAndFlightPopup.shoppingStep1Title' | cxTranslate }}
                    </div>
                    <div class="shopping-step-content">
                      {{ 'languageAndFlightPopup.shoppingStepFlow1' | cxTranslate }}
                    </div>
                  </am-message-tip>
                </div>
                <div class="shopping-step-item">
                  <am-message-tip [iconType]="svgType.SEAT" iconDescription="" hasBackgroudColor="false">
                    <div class="shopping-step-title">
                      2. {{ 'languageAndFlightPopup.shoppingStep2Title' | cxTranslate }}
                    </div>
                    <div class="shopping-step-content">{{ 'languageAndFlightPopup.shoppingStep2' | cxTranslate }}</div>
                  </am-message-tip>
                </div>
                <div class="shopping-step-item">
                  <am-message-tip [iconType]="svgType.NEWPAYMENT" iconDescription="" hasBackgroudColor="false">
                    <div class="shopping-step-title">
                      3. {{ 'languageAndFlightPopup.shoppingStep3Title' | cxTranslate }}
                    </div>
                    <div class="shopping-step-content">{{ 'languageAndFlightPopup.shoppingStep3' | cxTranslate }}</div>
                  </am-message-tip>
                </div>
              </ng-container>
              <ng-container *ngIf="activeTab === 'ground'">
                <div class="shopping-step-item">
                  <am-message-tip [iconType]="svgType.SHOPPING" iconDescription="" hasBackgroudColor="false">
                    <div class="shopping-step-title">
                      1. {{ 'languageAndFlightPopup.shoppingStep1Title' | cxTranslate }}
                    </div>
                    <div class="shopping-step-content">
                      {{ 'languageAndFlightPopup.shoppingStepFlow2' | cxTranslate }}
                    </div>
                  </am-message-tip>
                </div>
                <ng-container *ngIf="amConfigService.inflightOtpToggle">
                  <div class="shopping-step-item">
                    <am-message-tip [iconType]="svgType.LANDING" iconDescription="" hasBackgroudColor="false">
                      <div class="shopping-step-title">
                        2. {{ 'languageAndFlightPopup.shoppingStep2Title' | cxTranslate }}
                      </div>
                      <div class="shopping-step-content">
                        {{ 'languageAndFlightPopup.shoppingStep4' | cxTranslate }}
                      </div>
                    </am-message-tip>
                  </div>
                  <div class="shopping-step-item">
                    <am-message-tip [iconType]="svgType.NEWCAR" iconDescription="" hasBackgroudColor="false">
                      <div class="shopping-step-title">
                        3. {{ 'languageAndFlightPopup.shoppingStep3Title' | cxTranslate }}
                      </div>
                      <div class="shopping-step-content">
                        <div class="shopping-step-content">
                          {{ 'languageAndFlightPopup.shoppingStep5' | cxTranslate }}
                        </div>
                      </div>
                    </am-message-tip>
                  </div>
                </ng-container>
                <ng-container *ngIf="!amConfigService.inflightOtpToggle">
                  <div class="shopping-step-item">
                    <am-message-tip [iconType]="svgType.NEWPAYMENT" iconDescription="" hasBackgroudColor="false">
                      <div class="shopping-step-title">
                        2. {{ 'languageAndFlightPopup.shoppingStep3Title' | cxTranslate }}
                      </div>
                      <div class="shopping-step-content">
                        {{ 'languageAndFlightPopup.shoppingStep6' | cxTranslate }}
                      </div>
                    </am-message-tip>
                  </div>
                  <div class="shopping-step-item">
                    <am-message-tip [iconType]="svgType.NEWCAR" iconDescription="" hasBackgroudColor="false">
                      <div class="shopping-step-title">
                        3. {{ 'languageAndFlightPopup.shoppingStep2Title' | cxTranslate }}
                      </div>
                      <div class="shopping-step-content">
                        {{ 'languageAndFlightPopup.shoppingStep5' | cxTranslate }}
                      </div>
                    </am-message-tip>
                  </div></ng-container
                >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
