import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FlightInfoService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { AddToCartParams } from '@model/cart.model';
import { CollectionMethod } from '@model/collection-method.enum';
import { Product, ProductScope, ProductService, RoutingService, WindowRef } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { CxDataLayerService } from 'src/app/amiredeem/common/data-layer/dataLayer.service';
import { AmCartService } from '../../../cart/service/am-cart.service';
import { FavoriteNotifyReminderService } from '../../../favorite-notify-products/components/favorite-notify-reminder/favorite-notify-reminder.service';
import { FavoriteNotifyProductsService } from '../../../favorite-notify-products/services/favorite-notify-products.service';
import { ProductDetailService } from '../../service/product.service';
import { AmAddToCartService } from './add-to-cart-dialog/add-to-cart.service';

@Component({
  selector: 'am-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class AmAddToCartComponent implements OnDestroy, AfterViewInit {
  @Input() set productCode(v) {
    if (v) {
      this._productCode = v;
      this.product$ = this.productService.get(this.productCode, ProductScope.DETAILS).pipe(
        tap((product) => {
          this.amCartService.addToCartResult$.next(null);
          this.quantity = 1;
          this.hasStock = true;
          if (product) {
            this.setStockInfo(product);
            this.setProductFavoriteIcon(this.productCode);
            this.showNotifyMeBtn(product);
            this.setProductNotifyMeStatus(this.productCode);
          }
        })
      );
    }
  }
  get productCode() {
    return this._productCode;
  }
  private _productCode: string;

  @Input() showQuantity = true;

  @Input() product: Product;
  @Input() mileValue: number = 0;
  @Input() electronicForm: FormGroup;
  @Input() alcoholicForm: FormGroup;

  @Input() isShopOpen: boolean = true;
  @Input() isNotifyMe: boolean = false;
  @Input() isFavoriteNotifyBottomSheet: boolean = false;
  @Input() pageFrom: string = '';
  @Output() emitAddCartResult: EventEmitter<any> = new EventEmitter();

  svgType = svgConfig;
  style_add_to_cart = 'width: 21px; height: 21px;';

  isShowNotifyMeBtn: boolean = false;
  collectionMethod = CollectionMethod;

  product$: Observable<Product>;

  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();

  maxQuantity: number;

  hasStock: boolean = false;
  inventoryThreshold: boolean = false;

  isFavouriteProduct: boolean = false;
  getFavoirteList$: Subscription = null;

  isNotifyMeProduct: boolean = false;
  getNotifyMeList$: Subscription = null;
  getCommonConfig$: Subscription = null;

  @Output() quantity = 1;
  protected numberOfEntriesBeforeAdd = 0;

  addToCartForm = new FormGroup({
    quantity: new FormControl(1, { updateOn: 'blur' })
  });
  addToCartError = false;
  addToCartResult$ = this.amCartService.getAddToCartResult().pipe(
    map((val) => {
      this.addToCartError = false;
      if (val?.statusCode !== 'success' && !!val?.errorMsg) {
        this.addToCartError = true;
      }
      return val || {};
    })
  );
  country: string = 'HK';
  _form: ElementRef;
  @ViewChild('form') form;

  isLoading$ = this.routingService.isNavigating();
  countrySub: Subscription;
  protected subscription = new Subscription();

  constructor(
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected amAddToCartService: AmAddToCartService,
    protected amCartService: AmCartService,
    protected authService: AmAuthService,
    protected breakpointService: BreakpointService,
    protected productService: ProductService,
    protected productDetailService: ProductDetailService,
    protected cxDataLayerService: CxDataLayerService,
    protected countryService: CountryService,
    protected routingService: RoutingService,
    protected flightInfoService: FlightInfoService,
    protected windowRef: WindowRef,
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected favoriteNotifyReminderService: FavoriteNotifyReminderService,
    protected amConfigService: AmConfigService,
    @Optional() protected component?: CmsComponentData<any>
  ) {
    this.subscription.add(
      (this.countrySub = this.countryService.getActive().subscribe((country) => {
        this.country = country;
      }))
    );
    this.favoriteNotifyProductsService.loadFavoriteAndNotifyData();
  }

  protected setStockInfo(product: Product): void {
    // this.quantity = 1;

    if (product.maxQuantity >= 0 && product.stock?.stockLevel) {
      this.maxQuantity = Math.min(product.maxQuantity, product.stock?.stockLevel);
    } else if (product.stock?.stockLevel) {
      this.maxQuantity = product.stock.stockLevel;
    } else {
      this.maxQuantity = product.maxQuantity;
    }
  }

  /**
   * In specific scenarios, we need to omit displaying the stock level or append a plus to the value.
   * When backoffice forces a product to be in stock, omit showing the stock level.
   * When product stock level is limited by a threshold value, append '+' at the end.
   * When out of stock, display no numerical value.
   */
  getInventory(): string {
    if (this.hasStock) {
      const quantityDisplay = this.maxQuantity ? this.maxQuantity.toString() : '';
      return this.inventoryThreshold ? quantityDisplay + '+' : quantityDisplay;
    } else {
      return '';
    }
  }

  updateCount(value: number): void {
    this.quantity = value;
  }

  addToCart(): void {
    this.subscription.add(
      this.product$.pipe(take(1)).subscribe((product) => {
        // this.productCode = this.getProductCode(product);
        if (this.electronicForm) {
          Object.values(this.electronicForm.controls).forEach((i: AbstractControl) => {
            i.markAsDirty();
            i.updateValueAndValidity();
          });
          if (this.electronicForm.invalid) {
            this.electronicForm.get('electronicCheckbox').setValue(false);
            return;
          }
        }
        if (this.alcoholicForm) {
          Object.values(this.alcoholicForm.controls).forEach((i: AbstractControl) => {
            i.markAsDirty();
            i.updateValueAndValidity();
          });
          if (this.alcoholicForm.invalid) {
            return;
          }
        }
        const quantity = this.addToCartForm.get('quantity').value;
        if (!this.productCode || quantity <= 0) {
          return;
        }
        this.subscription.add(
          this.authService
            .isUserLoggedIn()
            .pipe(
              mergeMap((val) => {
                if (val) {
                  let param: AddToCartParams = {};
                  if (this.electronicForm) {
                    param.removalServiceStatus = this.electronicForm.value.electronicRadio;
                  }
                  return this.amCartService.addToCart(this.productCode, quantity, param).pipe(
                    tap((data) => {
                      this.emitAddCartResult.emit(data);
                    })
                  );
                  // this.cxDataLayerService.loadEventDataLayer('ADD_TO_CART', this.mileValue);
                  // return of(val);
                } else {
                  this.authService.loginWithRedirect();
                  return of(null);
                }
              })
            )
            .subscribe((val) => {
              if (val) {
                this.amAddToCartService.open();
              }
            })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.countrySub?.unsubscribe();
    this.getFavoirteList$?.unsubscribe();
    this.getNotifyMeList$?.unsubscribe();
    this.getCommonConfig$?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  isDisabled(product): boolean {
    if ((this.electronicForm && this.country === 'HK') || (this.alcoholicForm && !product.externalRedirect)) {
      return (
        (this.product.isInflightEnv && !this.isShopOpen) ||
        !!this.electronicForm?.get('electronicCheckbox').invalid ||
        this.alcoholicForm?.invalid ||
        this.quantity <= 0 ||
        this.quantity > this.maxQuantity ||
        !!product?.responseInfo?.globalMessages?.accErrorMsgs ||
        this.addToCartError
      );
    }
    return (
      (this.product.isInflightEnv && !this.isShopOpen) ||
      this.quantity <= 0 ||
      this.quantity > this.maxQuantity ||
      !!product?.responseInfo?.globalMessages?.accErrorMsgs ||
      this.addToCartError
    );
  }

  getProductCode(product: Product): string {
    if (product.baseOptions?.length) {
      return product.baseOptions[0].selected.code;
    } else {
      return product.code ?? '';
    }
  }
  ngAfterViewInit(): void {
    this.windowRef.isBrowser() && setTimeout(() => this.footerStyle(), 300);
    // this.footerStyle();
  }

  footerStyle(): void {
    this.subscription.add(
      this.breakpointService.isEqual(BREAKPOINT.xs).subscribe((d) => {
        const offsetHeight = this.form?.nativeElement?.clientHeight;
        const footer = this.windowRef.document?.querySelector('cx-page-slot.Footer') as any;
        if (!d) {
          footer.removeAttribute('style');
        } else {
          if (offsetHeight > 0) {
            footer.style.marginBottom = offsetHeight + 'px';
          }
        }
      })
    );
  }

  clickFavourites(): void {
    this.favoriteNotifyReminderService.openReminder(this.productCode);
    if (!this.isFavouriteProduct) {
      this.favoriteNotifyProductsService.addProductIntoFavoriteList(this.productCode);
    } else {
      this.favoriteNotifyProductsService.removeProductFromFavoirteList(this.productCode);
    }
  }

  setProductFavoriteIcon(productCode: string) {
    this.getFavoirteList$ = this.favoriteNotifyProductsService.getFavoirteList().subscribe((favoriteList) => {
      this.isFavouriteProduct = false;
      this.cd.detectChanges();
      favoriteList.forEach((item: any) => {
        if (item.product.code === productCode) {
          this.isFavouriteProduct = true;
          this.cd.detectChanges();
        }
      });
    });
  }

  setProductNotifyMeStatus(productCode: string) {
    this.getNotifyMeList$ = this.favoriteNotifyProductsService.getNotifyList().subscribe((notifyMeList) => {
      this.isNotifyMeProduct = false;
      this.cd.detectChanges();
      notifyMeList.forEach((item: any) => {
        if (item.product.code === productCode) {
          this.isNotifyMeProduct = true;
          this.cd.detectChanges();
        }
      });
    });
  }

  showNotifyMeBtn(product: Product) {
    this.isShowNotifyMeBtn = false;
    this.cd.detectChanges();
    this.getCommonConfig$ = this.amConfigService.getCommonConfig().subscribe((data) => {
      if (
        (this.getIsComingSoonStatus(product) || product.stock?.stockLevelStatus === 'outOfStock') &&
        !product.excludeNotifyMe &&
        product.collectionMethod !== CollectionMethod.INFLIGHT_PICKUP &&
        data.notifySwitch &&
        !product.isGiftPromotion &&
        !product.isGiveaway
      ) {
        this.isShowNotifyMeBtn = true;
        this.cd.detectChanges();
      }
    });
  }

  getIsComingSoonStatus(product: Product): boolean {
    let isComingSoon = false;
    if (
      product.redemptionStartDate &&
      new Date(product.redemptionStartDate).getTime() > new Date().getTime() &&
      !!product.productTagGroupA?.tagName
    ) {
      isComingSoon = true;
    }
    return isComingSoon;
  }

  onClickNotifyMe(): void {
    this.favoriteNotifyReminderService.openReminder(this.productCode);
    if (!this.isNotifyMeProduct) {
      this.favoriteNotifyProductsService.addProductIntoNotifyList(this.productCode);
    } else {
      this.favoriteNotifyProductsService.removeProductFromNotifyList(this.productCode);
    }
  }
}
