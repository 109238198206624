import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CollectionMethod, CollectionMethodType } from '@model/collection-method.enum';
import { ActiveCartFacade, CartOutlets, OrderEntry, PromotionLocation } from '@spartacus/cart/base/root';
import { BaseOption, Product, RoutingService } from '@spartacus/core';
import { ICON_TYPE, LAUNCH_CALLER } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FavoriteNotifyReminderService } from 'src/app/amiredeem/pages/favorite-notify-products/components/favorite-notify-reminder/favorite-notify-reminder.service';
import { FavoriteNotifyProductsService } from 'src/app/amiredeem/pages/favorite-notify-products/services/favorite-notify-products.service';
import { AmCartService } from '../../../service/am-cart.service';
import { CartItemContextSource } from './model/cart-item-context-source.model';
import { CartItemContext } from './model/cart-item-context.model';

export interface CartItemComponentOptions {
  isSaveForLater?: boolean;
  optionalBtn?: any;
}

@Component({
  selector: 'am-cart-item',
  templateUrl: './cart-item.component.html',
  providers: [CartItemContextSource, { provide: CartItemContext, useExisting: CartItemContextSource }]
})
export class AmCartItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() compact = false;
  @Input() item: OrderEntry;
  @Input() readonly = false;
  @Input() quantityControl: FormControl;
  @Input() showGroupBy = false;
  @Input() isInflightEnv = false;
  @Input() showCollectionMethod = false;
  collectionMethodType = CollectionMethodType;
  @Input() promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;
  @Input() options: CartItemComponentOptions = {
    isSaveForLater: false,
    optionalBtn: null
  };
  iconTypes = ICON_TYPE;
  readonly CartOutlets = CartOutlets;
  @Input() lowStockFlag;
  country: string;

  svgType = svgConfig;
  collectionMethod = CollectionMethod;
  style_warning = 'width: 20px; height: 20px;';
  @Input() isStoreOpenStatus: boolean | null;

  isFavouriteProduct: boolean = false;
  getFavoirteList$;
  protected subscription = new Subscription();
  constructor(
    protected cartItemContextSource: CartItemContextSource,
    private countryService: CountryService,
    protected activeCartService: ActiveCartFacade,
    protected amCartService: AmCartService,
    protected routingService: RoutingService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected launchDialogService: AmLaunchDialogService,
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected favoriteNotifyReminderService: FavoriteNotifyReminderService,
    protected cd: ChangeDetectorRef
  ) {
    this.subscription.add(
      this.countryService.getActive().subscribe((country) => {
        this.country = country;
      })
    );
    this.favoriteNotifyProductsService.loadFavoriteAndNotifyData();
  }

  ngOnInit() {
    this.setProductFavoriteIcon(this.item.product?.code);
  }

  onClickProductImg(entry) {
    if (!entry.product.isGiftPromotion) {
      this.routingService.go({
        cxRoute: 'product',
        params: entry.product
      });
    }
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.compact) {
      this.cartItemContextSource.compact$.next(this.compact);
    }
    if (changes?.readonly) {
      this.cartItemContextSource.readonly$.next(this.readonly);
    }
    if (changes?.item) {
      this.cartItemContextSource.item$.next(this.item);
    }
    if (changes?.quantityControl) {
      this.cartItemContextSource.quantityControl$.next(this.quantityControl);
    }
    if (changes?.promotionLocation) {
      this.cartItemContextSource.location$.next(this.promotionLocation);
    }
    if (changes?.options) {
      this.cartItemContextSource.options$.next(this.options);
    }
  }

  isProductOutOfStock(product: Product): boolean {
    // TODO Move stocklevelstatuses across the app to an enum
    return product?.stock?.stockLevelStatus === 'outOfStock';
  }

  removeItem(item: Product): void {
    this.activeCartService.removeEntry(this.item);
  }
  isShowQuantity(item: OrderEntry): boolean {
    return (
      !item.cannotRedeemed &&
      !item.cannotDelivery &&
      item.isPurchasable &&
      !item.isOutOfStock &&
      !item.isOverMinimumMiles &&
      !item.productShouldBeHidden &&
      item.enableInflightRedemption
    );
  }

  maxQuantity(item: OrderEntry): number {
    if (this.lowStockFlag || item.product.isGiftPromotion) {
      return item.quantity;
    }
    if (item.product.stock?.stockLevel > 0 && item.product.stock?.stockLevel <= item.maxOrderQuantity) {
      return item.product.stock?.stockLevel;
    }
    return item.maxOrderQuantity || item.product.stock?.stockLevel || 5;
  }

  getCurrentBaseOptions(baseOptions: BaseOption[], code: string): BaseOption {
    return baseOptions.filter((option) => option.selected.code === code)[0];
  }

  openPromotion(): void {
    this.subscription.add(
      this.amCartService.getCartEntryDiscount(this.item.entryNumber).subscribe((product) => {
        const dialog = this.launchDialogService.openDialog(
          LAUNCH_CALLER.DISPLAY_APPLIED_OFFER_DETAIL,
          undefined,
          undefined,
          {
            product
          }
        );
        if (dialog) {
          this.subscription.add(dialog.pipe(take(1)).subscribe());
        }
      })
    );
  }

  hasDiscount(item: OrderEntry): boolean {
    return (
      item &&
      !item.isOutOfStock &&
      !item.cannotDelivery &&
      !item.cannotRedeemed &&
      item.isPurchasable &&
      !item.isOverMinimumMiles &&
      !item.productShouldBeHidden &&
      ((item.product?.discountFullCashPrice &&
        item.product?.paymentMethod !== 'MilesOnly' &&
        item.product?.originFullCashPrice > item.product?.discountFullCashPrice) ||
        (item.product?.promotionPrice?.value && item.product?.paymentMethod !== 'CashOnly'))
    );
  }

  getTypeCollectionMethod(value): string {
    return CollectionMethodType[value];
  }
  isInflightStock(): boolean {
    const { product } = this.item;
    if (this.flightStatusStorageService.isInflightPickFlow && this.isStoreOpenStatus !== true) {
      // if (this.isStoreOpenStatus !== true) {
      // return true;
      // } else {
      return product.collectionMethod === CollectionMethod.INFLIGHT_PICKUP;
      // }
    } else if (!this.flightStatusStorageService.isInflightPickFlow) {
      return product.collectionMethod === CollectionMethod.INFLIGHT_PICKUP;
    }
    return false;
  }

  onClickPromotionLabel(entry, promotionCode) {
    this.subscription.add(
      this.amCartService.getCartPageGiftOffer(entry.entryNumber, promotionCode).subscribe((item) => {
        const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_OFFER_DETAILS, undefined, undefined, {
          product: item,
          promoPopName: 'freeGiftTag',
          code: item.code,
          pageFrom: 'cartPage'
        });
        if (dialog) {
          this.subscription.add(dialog.pipe(take(1)).subscribe());
        }
      })
    );
  }

  onClickFavouriteIcon(): void {
    // console.log('product code: ', this.item.product.code);
    this.favoriteNotifyReminderService.openReminder(this.item.product.code);
    if (!this.isFavouriteProduct) {
      this.favoriteNotifyProductsService.addProductIntoFavoriteList(this.item.product.code);
    } else {
      this.favoriteNotifyProductsService.removeProductFromFavoirteList(this.item.product.code);
    }
  }

  setProductFavoriteIcon(productCode: string): void {
    this.subscription.add(
      this.favoriteNotifyProductsService.getFavoirteList().subscribe((favoriteList) => {
        this.isFavouriteProduct = false;
        this.cd.detectChanges();
        favoriteList.forEach((item: any) => {
          if (item.product.code === productCode) {
            this.isFavouriteProduct = true;
            this.cd.detectChanges();
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.getFavoirteList$?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
