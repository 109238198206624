import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { ProductPriceModule } from '@components/content/common/product-price/product-price.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RecommendProductListComponent } from './recommend-product-list.component';
import { RecommentProductCategoriesComponent } from './recommendProductCategories/recomment-product-categories.component';

@NgModule({
  declarations: [RecommendProductListComponent, RecommentProductCategoriesComponent],
  imports: [
    CommonModule,
    I18nModule,
    AmMediaModule,
    ProductPriceModule,
    SvgContainerModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RecommendProductListComponent: {
          // typeCode
          component: RecommendProductListComponent
        }
      }
    } as CmsConfig)
  ]
})
export class RecommendProductListModule {}
