import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { CustomTaxPopupComponent } from './custom-tax-popup.component';

@NgModule({
  declarations: [CustomTaxPopupComponent],
  imports: [CommonModule, I18nModule, SvgContainerModule, SafeCodeModule, KeyboardFocusModule],
  exports: [CustomTaxPopupComponent]
})
export class CustomTaxPopupModule {}
