import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { LanguageService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { OrderList } from '../orderList.model';

@Injectable({
  providedIn: 'root'
})
export class OrderListService {
  protected subscription = new Subscription();

  constructor(
    private occEndpointSvc: OccEndpointsService,
    protected userIdService: UserIdService,
    private http: HttpClient,
    private languageService: LanguageService,
    private countryService: CountryService
  ) {}
  orderListData: any = new Subject();
  orderListData$: Observable<OrderList> = this.orderListData.asObservable();

  previousLanguage: any;
  previousCountry: any;

  /**
   * Return order list
   */
  getOrderList(page: number, pageSize: number): Observable<OrderList> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    return combineLatest([this.languageService.getActive(), this.countryService.getActiveCountryEntity()]).pipe(
      // filter(([language, country]) => {
      //   return language !== this.previousLanguage || country?.isocode !== this.previousCountry?.isocode;
      // }),
      switchMap(([language, country]) => {
        this.previousLanguage = language;
        this.previousCountry = country;
        const url = this.occEndpointSvc.buildUrl('orderHistory', {
          urlParams: { userId }
        });
        let httpParams = new HttpParams().set('currentPage', page).set('pageSize', pageSize);
        return this.http.get<OrderList>(url, { params: httpParams }).pipe(
          tap((val) => {
            this.orderListData.next(val);
          })
        );
      })
    );
  }
}
