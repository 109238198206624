import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SwiperModule } from 'swiper/angular';
import { NavigationBarComponent } from './navigation-bar.component';

@NgModule({
  declarations: [NavigationBarComponent],
  imports: [
    CommonModule,
    SwiperModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CategoryNavigationComponent: {
          // typeCode
          component: NavigationBarComponent
        }
      }
    } as CmsConfig),
    KeyboardFocusModule
  ],
  exports: [NavigationBarComponent],
  entryComponents: [NavigationBarComponent]
})
export class AmNavigationBarModule {}
