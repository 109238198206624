import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { OccEndpointsService, UserIdService, WindowRef } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserCartId } from '../../facade/am-checkout-delivery.service';
import { PaymentResponse, RequestFiled, ResendOtpInfo, VerifyOtpInfo } from './am-verify-otp.component';

@Injectable({
  providedIn: 'root'
})
export class AmCheckoutVerifyOtpService {
  constructor(
    protected userIdService: UserIdService,
    protected activeCartService: ActiveCartFacade,
    protected occEndpointSvc: OccEndpointsService,
    protected httpClient: HttpClient,
    protected winRef: WindowRef
  ) {}

  userId: string;
  cartId: string;

  fetchOtpInfo(): Observable<VerifyOtpInfo> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()])
      .pipe(
        map(([userId, cartId]) => {
          return { userId, cartId };
        })
      )
      .pipe(
        switchMap((urlParams: UserCartId) => {
          this.userId = urlParams.userId;
          this.cartId = urlParams.cartId;
          const url = this.occEndpointSvc.buildUrl('paymentMethod', {
            urlParams: { userId: this.userId, cartId: this.cartId }
          });
          // const url = this.occEndpointSvc.getBaseUrl() + `/users/${urlParams.userId}/carts/${urlParams.cartId}/checkout/payment-method`
          return this.httpClient.get<VerifyOtpInfo>(url);
        })
      );
  }

  generatePaymentForm(formId: string, paymentUrl: string, requestFileds: Array<RequestFiled>): HTMLFormElement {
    const form = this.winRef.document?.getElementById(formId) as HTMLFormElement;
    form.innerHTML = '';
    form.setAttribute('action', paymentUrl);
    requestFileds.forEach((item) => {
      const input = this.winRef.document?.createElement('input');
      input.name = item.key;
      input.id = item.key;
      input.value = item.value;
      form.appendChild(input);
    });
    return form;
  }

  resendOtp(): Observable<ResendOtpInfo> {
    const url = this.occEndpointSvc.buildUrl('smsVerifycode', {
      urlParams: { userId: this.userId }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${this.userId}/checkout/sms/verifycode/resend`
    return this.httpClient.get<ResendOtpInfo>(url);
  }

  submitOtp(smsCode: string): Observable<PaymentResponse> {
    const url = this.occEndpointSvc.buildUrl('paymentMethod', {
      urlParams: { userId: this.userId, cartId: this.cartId }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${this.userId}/carts/${this.cartId}/checkout/payment-method`
    return this.httpClient.post<PaymentResponse>(url, {
      smsCode,
      cashPaymentBackUrl: this.winRef.location.href
    });
  }
}
