import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmlHomeMerchandisingCarouselComponentModule } from '@components/content/aml-merchandising-carousel/aml-merchandising-carousel.module';
import { MessageBoxModule } from '@components/content/banner-carousel/message-box/message-box.module';
import { AmVideoBannerModule } from '@components/content/banner-carousel/video-banner/video-banner.module';
import { CategoryThumbnailPnfModule } from '@components/content/category-thumbanil-pnf/category-thumbanil-pnf.module';
import { AmPageTitleModule } from '@components/content/common/campaignPageTitle/am-page-title.module';
import { ContentHighlightWithImageModule } from '@components/content/content-highlight-with-image/content-highlight-with-image.module';
import { HeadingsModule } from '@components/content/headings/headings.module';
import { InflightDiagnosticModule } from '@components/content/inflight-diagnostic/inflight-diagnostic.module';
import { LrpHeaderBannerModule } from '@components/content/lrp-header-banner/lrp-header-banner.module';
import { AmNavigationBarModule } from '@components/content/navigation-bar/navigation-bar.module';
import { RecommendProductListModule } from '@components/content/product/recommendProductList/recommend-product-list.module';
import { AmHamburgerMenuModule } from '@components/layout/header/am-hamburger-menu/am-hamburger-menu.module';
import { AmHeaderSiteContentSelectorModule } from '@components/layout/header/am-site-context/components/header-site-context-selector/header-site-context-selector.module';
import { InflightSiteContextSelectorPopupModule } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/inflight-site-context-selector-popup.module';
import { SiteContextSelectionPopupModule } from '@components/layout/header/am-site-context/components/site-context-selector-popup/site-context-selector-popup.module';
import { AmSiteContextStoreModule } from '@components/layout/header/am-site-context/store/am-site-context-store.module';
import { AmMiniCartModule } from '@components/layout/header/mini-cart/mini-cart.module';
import { DividerModule } from '@components/layout/separator/divider/divider.module';
import { SpaceModule } from '@components/layout/separator/space/space.module';
import { AmAuthModule } from '@core/auth/am-auth.module';
import { AmGlobalMessageModule } from '@core/global-message/am-global-message.module';
import { AmPageMetaModule } from '@core/seo/page-meta/am-page-meta-resolver.module';
import { AmConsentsModule } from '@core/user/consents/am-consent.module';
import { AmCommonModule } from './common/config/store/common.module';
import { PendingOrderPopupModule } from './components/content/pending-order-popup/pending-order-popup.module';
import { FooterModule } from './components/layout/footer/footer.module';
import { AmSiteLoginModule } from './components/layout/header/am-site-login/am-site-login.module';
import { AmSiteLogoModule } from './components/layout/header/am-site-logo/am-site-logo.module';
import { DisplaySearchBoxModule } from './components/layout/header/display-search-box/display-search-box.module';
import { ErrorPageModule } from './pages/error/error-page.module';
import { FavoriteNotifyProductsModule } from './pages/favorite-notify-products/favorite-notify-products.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AmAuthModule,
    AmConsentsModule.forRoot(),
    // AmCommonStoreModule,
    AmCommonModule.forRoot(),
    AmGlobalMessageModule.forRoot(),
    AmSiteContextStoreModule,
    AmPageMetaModule,
    // header
    AmSiteLogoModule,
    DisplaySearchBoxModule,
    AmSiteLoginModule,
    AmHeaderSiteContentSelectorModule,
    SiteContextSelectionPopupModule,
    InflightSiteContextSelectorPopupModule,
    AmHamburgerMenuModule,
    AmMiniCartModule,
    // navigation bar
    AmNavigationBarModule,
    // footer
    FooterModule,
    PendingOrderPopupModule,
    // shared componts
    AmPageTitleModule,
    MessageBoxModule,
    AmlHomeMerchandisingCarouselComponentModule,
    CategoryThumbnailPnfModule,
    AmVideoBannerModule,
    // error info
    ErrorPageModule,
    // Separator componement
    DividerModule,
    SpaceModule,
    LrpHeaderBannerModule,
    RecommendProductListModule,
    // headings component
    HeadingsModule,
    // Content Highlight With Image Component
    ContentHighlightWithImageModule,
    FavoriteNotifyProductsModule,
    InflightDiagnosticModule
  ]
})
export class AmFeaturesModule {}
