import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'am-balance-error',
  templateUrl: './balance-error.component.html'
})
export class BalanceErrorComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  cart$ = this.activeCartService.getActive();
  protected subscription = new Subscription();

  constructor(
    protected activeCartService: ActiveCartFacade,
    protected authService: AmAuthService,
    private userAccountFacade: UserAccountFacade,
    protected routingService: RoutingService
  ) {
    this.subscription.add(
      this.activeCartService.getActiveCartId().subscribe((cartId) => {
        console.log('cartId', cartId);
      })
    );
  }

  ngOnInit(): void {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
  }

  goBackHome(): void {
    this.routingService.go('');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
