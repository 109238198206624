import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CdsBackendNotificationAdapter } from '@spartacus/cds';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

@Injectable()
export class AmBackendNotification implements CdsBackendNotificationAdapter {
  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private userIdService: UserIdService
  ) {}
  protected subscription = new Subscription();

  notifySuccessfulLogin(): Observable<void> {
    let userId = 'current';
    this.userIdService
      .getUserId()
      .subscribe((data) => {
        userId = data;
      })
      .unsubscribe();
    return this.http
      .post<{}>(`${this.occEndpoints.getBaseUrl()}/users/${userId}/loginnotification`, {})
      .pipe(switchMapTo(of()));
  }
}
