import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { ShoppingTipComponent } from './shopping-tip.component';

@NgModule({
  declarations: [ShoppingTipComponent],
  imports: [CommonModule, MessageTipModule, SvgContainerModule, I18nModule, BrowserModule, KeyboardFocusModule],
  exports: [ShoppingTipComponent]
})
export class ShoppingTipModule {}
