import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IntersectionObserverHooks } from '../lazy-image/intersection-observer-hooks/intersection-observer-hooks';
import { LazyLoadImageModule } from '../lazy-image/lazyload-image.module';
import { LAZYLOAD_IMAGE_HOOKS } from '../lazy-image/token';
import { AmMediaComponent } from './media.component';
@NgModule({
  imports: [CommonModule, I18nModule, LazyLoadImageModule],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: IntersectionObserverHooks }],
  declarations: [AmMediaComponent],
  exports: [AmMediaComponent]
})
export class AmMediaModule {
  static forRoot(): ModuleWithProviders<AmMediaModule> {
    return {
      ngModule: AmMediaModule
    };
  }
}
