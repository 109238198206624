import { Component, Input } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';

import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';

import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Component({
  selector: 'am-custom-tax-popup',
  templateUrl: './custom-tax-popup.component.html'
})
export class CustomTaxPopupComponent {
  @Input() waiveDeliveryFee: boolean = false;
  cart$ = this.activeCartService.getActive();

  svgType = svgConfig;
  style_icon_close = 'width: 38px; height: 38px';

  constructor(protected launchDialogService: AmLaunchDialogService, protected activeCartService: ActiveCartFacade) {}

  closeModal(): void {
    this.launchDialogService.closeDialog('');
  }
}
