<div class="am-price-slider-bar" [class.equalMode]="equalMode" *ngIf="model$ | async">
  <p class="slider-label">
    <span>{{ displayMinValue | number }}&nbsp;-&nbsp;</span>
    <span>{{ displayMaxValue | number }}</span>
    <span>
      {{ 'amProductFacet.miles' | cxTranslate }}
    </span>
  </p>
  <ngx-slider
    *ngIf="isBrowser"
    [(value)]="displayMinValue"
    [(highValue)]="displayMaxValue"
    [options]="sliderConfig"
    (userChangeEnd)="onUserChangeEnd($event)">
  </ngx-slider>
</div>
