import { DeferLoadingStrategy } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

export const layoutConfig: LayoutConfig = {
  deferredLoading: {
    strategy: DeferLoadingStrategy.DEFER,
    intersectionMargin: '500px'
  },
  layoutSlots: {
    header: {
      lg: {
        slots: ['SiteLogo', 'HeaderDeliveryZone', 'SearchBox', 'MiniCart', 'SiteLogin']
      },
      md: {
        slots: ['SiteLogo', 'HeaderDeliveryZone', 'SearchBox', 'MiniCart', 'SiteLogin']
      },
      sm: {
        slots: ['SiteLogo', 'MiniCart', 'PreHeader', 'SiteLogin', 'SearchBox']
      },
      xs: {
        slots: ['SiteLogo', 'MiniCart', 'PreHeader', 'SiteLogin', 'SearchBox']
      },
      slots: ['SiteLogo', 'HeaderDeliveryZone', 'SearchBox', 'MiniCart', 'SiteLogin']
    },
    navigation: {
      lg: {
        slots: ['NavigationBar']
      },
      md: {
        slots: ['NavigationBar']
      },
      sm: {
        slots: ['NavigationBar', 'HeaderDeliveryZone']
      },
      xs: {
        slots: ['NavigationBar', 'HeaderDeliveryZone']
      }
    },
    footer: {
      slots: ['Footer']
    },
    LandingPage1Template: {
      pageFold: 'SectionA1',
      slots: [
        'HeaderBannerSection',
        'SectionA1',
        'SectionA2',
        'Section3',
        'SectionA3',
        'SectionA4',
        'SectionA5',
        'SectionA6',
        'SectionA7',
        'SectionA8',
        'SectionA9',
        'SectionA10',
        'SectionA4-inflightLiteHomepage'
      ]
    },
    LandingPage2Template: {
      pageFold: 'Section2B',
      slots: [
        'HeaderBannerSection',
        'Section1',
        'Section2A',
        'Section2B',
        'Section2C',
        'Section3',
        'Section4',
        'Section5'
      ]
    },
    ContentPage1Template: {
      slots: ['HeaderBannerSection', 'Section2A', 'Section2B']
    },
    CategoryPageTemplate: {
      pageFold: 'Section2',
      slots: ['HeaderBannerSection', 'Section1', 'Section2', 'Section3']
    },
    ProductListPageTemplate: {
      slots: [
        'HeaderBannerSection',
        'ProductTabSlot',
        'ProductLeftRefinements',
        'ProductListSlot',
        'EmptyProductListSlot'
      ]
    },
    ProductGridPageTemplate: {
      slots: ['HeaderBannerSection', 'ProductLeftRefinements', 'ProductGridSlot']
    },
    SearchResultsListPageTemplate: {
      slots: [
        'HeaderBannerSection',
        'Section2',
        'ProductLeftRefinements',
        'SearchResultsListSlot',
        'EmptyProductListSlot',
        'SearchResultsGridSlot'
      ]
    },
    SearchResultsGridPageTemplate: {
      slots: ['HeaderBannerSection', 'ProductLeftRefinements', 'ProductListSlot', 'EmptyProductListSlot', 'Section4']
    },
    ProductDetailsPageTemplate: {
      lg: {
        pageFold: 'UpSelling'
      },
      pageFold: 'Summary',
      slots: [
        // 'SectionA3',
        'HeaderBannerSection',
        'Tabs',
        'UpSelling',
        // 'TopHeaderSlot',
        'AddToCart',
        'PlaceholderContentSlot',
        // 'HeaderLinks',
        'SpartacusProductdetail',
        'SectionA4'
      ]
    },
    CartPageTemplate: {
      slots: ['HeaderBannerSection', 'TopContent', 'CenterLeftContentSlot', 'EmptyCartMiddleContent']
    },
    AccountPageTemplate: {
      slots: ['HeaderBannerSection', 'BodyContent', 'SideContent']
    },
    AccountOrderTemplate: {
      slots: ['HeaderBannerSection', 'BodyContent', 'SideContent', 'SectionA5']
    },
    AccountOrderDetailTemplate: {
      slots: ['HeaderBannerSection', 'BodyContent']
    },
    LoginPageTemplate: {
      slots: ['LeftContentSlot', 'RightContentSlot']
    },
    ErrorPageTemplate: {
      slots: ['HeaderBannerSection', 'TopContent', 'MiddleContent', 'BottomContent', 'BodyContent', 'SectionA1']
    },
    OrderConfirmationPageTemplate: {
      slots: ['HeaderBannerSection', 'BodyContent', 'SideContent']
    },
    MultiStepCheckoutSummaryPageTemplate: {
      slots: ['HeaderBannerSection', 'TopContent', 'BodyContent', 'BottomContent']
    },
    CheckoutLoginPageTemplate: {
      slots: ['HeaderBannerSection', 'RightContentSlot']
    },
    LandingPage3Template: {
      slots: [
        'HeaderBannerSection',
        'BodyContent',
        // 'SectionA1',
        // 'SectionA2',
        'SectionA3',
        'SectionA4',
        'SectionA5',
        'SectionA6',
        'SectionA7',
        'SectionA8',
        'SectionA9',
        'SectionA10',
        'TopHeaderSlot'
      ]
    }
  }
};
