import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { UnavailableInflightProductComponent } from './unavailable-inflight-product.component';

@NgModule({
  declarations: [UnavailableInflightProductComponent],
  imports: [CommonModule, I18nModule],
  exports: [UnavailableInflightProductComponent],
  providers: [
    provideOutlet({
      id: 'ErrorPageTemplate',
      position: OutletPosition.BEFORE,
      component: UnavailableInflightProductComponent
    })
  ]
})
export class UnavailableInflightProductModule {}
