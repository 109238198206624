import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmChunkConfigModule } from './am-chunks.module';
import { AmConfigurationModule } from './am-configuration.module';
import { AmFeaturesModule } from './am-features.module';
import { AmProviderModule } from './am-provider.module';
import { AmStateModule } from './common/config/state/state.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AmConfigurationModule,
    AmFeaturesModule,
    AmChunkConfigModule,
    AmProviderModule,
    AmStateModule.forRoot()
  ]
})
export class AmModule {}
