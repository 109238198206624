import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { SvgContainerModule } from '../../../../components/content/svg-container/svg-container.module';
import { CartDeliverFeeComponent } from './cart-deliver-fee.component';
import { DeliveryFeeDialogModule } from './delivery-fee-dialog/delivery-fee-dialog.module';
import { FlatRateDeliveryFeeDialogModule } from './flat-rate-delivery-fee-dialog/flat-rate-delivery-fee-dialog.module';

@NgModule({
  imports: [CommonModule, I18nModule, SvgContainerModule, DeliveryFeeDialogModule, FlatRateDeliveryFeeDialogModule],
  exports: [CartDeliverFeeComponent],
  declarations: [CartDeliverFeeComponent]
})
export class CartDeliverFeeModule {}
