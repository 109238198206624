import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OAuthLibWrapperService, WindowRef } from '@spartacus/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { AmAuthConfigService } from './am-auth-config.service';

/**
 * Wrapper service on the library OAuthService. Normalizes the lib API for services.
 * Use this service when you want to access low level OAuth library methods.
 */
@Injectable({
  providedIn: 'root'
})
export class AmOAuthLibWrapperService extends OAuthLibWrapperService {
  private setAuthConfigSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public setAuthConfigObs: Observable<any> = this.setAuthConfigSub.asObservable();
  protected subscription = new Subscription();

  // TODO: Remove platformId dependency in 4.0
  constructor(
    protected oAuthService: OAuthService,
    protected authConfigService: AmAuthConfigService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected amConfigService: AmConfigService,
    protected winRef: WindowRef
  ) {
    super(oAuthService, authConfigService, platformId, winRef);
    this.init();
  }

  // override the init function for it can get the fetchLoginUrlObs objects
  protected initialize() {}
  protected init(): void {
    const isSSR = !this.winRef.isBrowser();

    this.subscription = this.amConfigService
      .getCommonConfig()
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        const { inflight, mlcLoginUrl, idpLogoutUrl, mlcLogoutUrl, ccsiEnable, clientId } = data;
        this.setAuthConfigSub?.complete();
        this.oAuthService.configure({
          tokenEndpoint: this.authConfigService.getTokenEndpoint(),
          loginUrl: this.authConfigService.getMlcLoginUrl(mlcLoginUrl, inflight),
          logoutUrl: this.authConfigService.getAmLogoutUrl(mlcLogoutUrl, idpLogoutUrl),
          clientId: this.authConfigService.getClientId(),
          dummyClientSecret: this.authConfigService.getClientSecret(),
          revocationEndpoint: this.authConfigService.getRevokeEndpoint(),
          userinfoEndpoint: this.authConfigService.getUserinfoEndpoint(),
          // postLogoutRedirectUri: this.winRef.location.href,
          issuer: this.authConfigService.getOAuthLibConfig()?.issuer ?? this.authConfigService.getBaseUrl(),
          customQueryParams: ccsiEnable
            ? { clientid: clientId, redirecturi: this.authConfigService.getTokenEndpoint() }
            : null,
          redirectUri:
            this.authConfigService.getOAuthLibConfig()?.redirectUri ??
            (!isSSR
              ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.winRef.nativeWindow!.location.origin
              : ''),
          ...this.authConfigService.getOAuthLibConfig()
        });
      });
  }

  revokeAndLogout(customParameters?: object): Promise<void> {
    return new Promise((resolve) => {
      this.oAuthService
        .revokeTokenAndLogout(customParameters)
        .catch(() => {
          // when there would be some kind of error during revocation we can't do anything else, so at least we logout user.
          this.oAuthService.logOut(customParameters);
        })
        .finally(() => {
          resolve();
        });
    });
  }

  refreshToken(): void {
    this.setAuthConfigObs.toPromise().then(() => {
      return this.oAuthService.refreshToken();
    });
  }

  initLoginFlow() {
    return this.setAuthConfigObs.toPromise().then(() => {
      this.oAuthService.initLoginFlow();
    });
  }

  tryLogin(): Promise<any> {
    return new Promise((resolve) => {
      // We use the 'token_received' event to check if we have returned
      // from the auth server.
      let tokenReceivedEvent: OAuthEvent | undefined;
      const subscription = this.events$
        .pipe(
          filter((event) => event.type === 'token_received'),
          take(1)
        )
        .subscribe((event) => (tokenReceivedEvent = event));

      this.setAuthConfigObs
        .toPromise()
        .then(() => {
          return this.oAuthService.tryLogin({
            // We don't load discovery document, because it doesn't contain revoke endpoint information
            disableOAuth2StateCheck: true
          });
        })
        .then((result: boolean) => {
          resolve({
            result: result,
            tokenReceived: !!tokenReceivedEvent
          });
        })
        .finally(() => {
          subscription.unsubscribe();
        });
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.setAuthConfigSub.complete();
  }
}
