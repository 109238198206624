import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { I18nModule } from '@spartacus/core';
import { PopoverModule } from '@spartacus/storefront';
import { PromotionCashSpendDialogModule } from '../promotion-cash-spend-dialog/promotion-cash-spend-dialog.module';
import { ProductSliderAlcoholicComponent } from './product-slider-alcoholic.component';

@NgModule({
  declarations: [ProductSliderAlcoholicComponent],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    PromotionCashSpendDialogModule,
    MessageTipModule
  ],
  exports: [ProductSliderAlcoholicComponent]
})
export class ProductSliderAlcoholicModule {}
