import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { FavoriteNotifyProductsService } from '../../services/favorite-notify-products.service';
import { FavoriteNotifyReminderService } from './favorite-notify-reminder.service';

@Component({
  selector: 'am-favorite-notify-reminder',
  templateUrl: './favorite-notify-reminder.component.html',
  styleUrls: ['./favorite-notify-reminder.component.scss']
})
export class FavoriteNotifyReminderComponent implements OnInit, OnDestroy {
  showReminderObs$: Subscription = null;
  showRminderAction: string = '';
  getActiveType$: Subscription = null;
  getNotifyList$: Subscription = null;
  timer: any;
  svgType = svgConfig;
  params: any = {};

  productCode: string;
  protected subscription = new Subscription();
  constructor(
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected cdr: ChangeDetectorRef,
    protected favoriteNotifyReminderService: FavoriteNotifyReminderService,
    protected winRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.showReminderFun();
  }

  showReminderFun() {
    this.favoriteNotifyReminderService.isOpenReminderObj.subscribe((productCode) => {
      this.productCode = productCode;
    });

    this.showReminderObs$ = this.favoriteNotifyProductsService.showReminderObs.subscribe((data) => {
      if (!!data && Object.keys(data).length > 0) {
        clearTimeout(this.timer);
        if (this.productCode === data.productCode) {
          this.showRminderAction = data.action;
          this.params = data.params;
          this.cdr.detectChanges();
          this.timer =
            this.winRef.isBrowser() &&
            setTimeout(() => {
              this.showRminderAction = '';
              this.cdr.detectChanges();
              // this.favoriteNotifyProductsService.showReminderSub.next({});
            }, 5000);
        }
      }
    });
  }

  isErrorInfo() {
    return (
      this.showRminderAction === 'addFavoriteFail' ||
      this.showRminderAction === 'addNotifyMeFail' ||
      this.showRminderAction === 'addToCartMaxError'
    );
  }

  clickUndoBtn() {
    if (this.showRminderAction === 'removeFavoriteSuccess') {
      this.favoriteNotifyProductsService.addProductIntoFavoriteList(this.productCode);
    } else if (this.showRminderAction === 'removeNotifyMeSuccess') {
      this.favoriteNotifyProductsService.addProductIntoNotifyList(this.productCode);
    }
  }

  ngOnDestroy() {
    this.showReminderObs$?.unsubscribe();
    this.getActiveType$?.unsubscribe();
    this.getNotifyList$?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
