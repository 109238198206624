import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  CmsActivatedRouteSnapshot,
  CmsService,
  Page,
  PageContext,
  PageType,
  RoutingService,
  SemanticPathService
} from '@spartacus/core';
import { CmsComponentsService, CmsGuardsService, CmsI18nService, CmsRoutesService } from '@spartacus/storefront';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AmCmsPageConnector } from '../connectors/page/am-cms-page.connector';

/**
 * Helper service for `CmsPageGuard`
 */
@Injectable({
  providedIn: 'root'
})
export class AmCmsPageGuardService {
  protected subscription = new Subscription();

  constructor(
    protected semanticPathService: SemanticPathService,
    protected cmsService: CmsService,
    protected cmsRoutes: CmsRoutesService,
    protected cmsI18n: CmsI18nService,
    protected cmsGuards: CmsGuardsService,
    protected cmsComponentsService: CmsComponentsService,
    protected routing: RoutingService,
    protected amCmsPageConnector: AmCmsPageConnector
  ) {}

  canActivatePage(
    pageContext: PageContext,
    pageData: Page,
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.cmsService.getPageComponentTypes(pageContext).pipe(
      take(1),
      switchMap((componentTypes) => this.cmsComponentsService.determineMappings(componentTypes)),
      switchMap((componentTypes) =>
        this.cmsGuards.cmsPageCanActivate(componentTypes, route, state).pipe(withLatestFrom(of(componentTypes)))
      ),
      tap(([canActivate, componentTypes]) => {
        if (canActivate === true) {
          this.cmsI18n.loadForComponents(componentTypes);
        }
      }),
      map(([canActivate, componentTypes]) => {
        const pageLabel = pageData.label || pageContext.id; // for content pages the page label returned from backend can be different than ID initially assumed from route
        if (canActivate === true && !route?.data?.cxCmsRouteContext) {
          return this.cmsRoutes.handleCmsRoutesInGuard(pageContext, componentTypes, state.url, pageLabel);
        }
        return canActivate;
      })
    );
  }

  canActivateNotFoundPage(
    pageContext: PageContext,
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    let notFoundLabel = null;

    this.amCmsPageConnector.loadPageErrorObs
      .subscribe((error: any) => {
        let errorType = '';
        if (!!error?.error?.errors && error.error.errors.length > 0) {
          errorType = error?.error?.errors[0].type;
        }
        if (errorType === 'unavailableInflightProductError') {
          notFoundLabel = this.semanticPathService.get('unavailableInflightProduct');
        } else if (errorType === 'outDateProductError') {
          notFoundLabel = this.semanticPathService.get('outDateProduct');
        } else {
          notFoundLabel = this.semanticPathService.get('notFound');
        }
      })
      .unsubscribe();

    if (!notFoundLabel) {
      return of(false);
    }
    const notFoundCmsPageContext: PageContext = {
      type: PageType.CONTENT_PAGE,
      id: notFoundLabel
    };

    return this.cmsService.getPage(notFoundCmsPageContext).pipe(
      switchMap((notFoundPage) => {
        if (notFoundPage) {
          return this.cmsService.getPageIndex(notFoundCmsPageContext).pipe(
            tap((notFoundIndex) => {
              this.cmsService.setPageFailIndex(pageContext, notFoundIndex);
              this.routing.changeNextPageContext(notFoundCmsPageContext);
            }),
            switchMap((notFoundIndex) =>
              this.cmsService.getPageIndex(pageContext).pipe(
                // we have to wait for page index update
                filter((index) => index === notFoundIndex)
              )
            ),
            switchMap(() => this.canActivatePage(pageContext, notFoundPage, route, state))
          );
        }
        return of(false);
      })
    );
  }
}
