import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { PendingOrderPopupComponent } from './pending-order-popup.component';

// export const pendingOrderDialogLayoutConfig: LayoutConfig = {
//   launch: {
//     SHOW_PENDING_ORDER: {
//       inlineRoot: true,
//       component: PendingOrderPopupComponent,
//       dialogType: DIALOG_TYPE.DIALOG
//     }
//   }
// };

@NgModule({
  declarations: [PendingOrderPopupComponent],
  imports: [CommonModule, I18nModule, KeyboardFocusModule],
  exports: [PendingOrderPopupComponent]
})
export class PendingOrderPopupModule {}
