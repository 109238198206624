import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmDatePipe } from './datePipe';

@NgModule({
  declarations: [AmDatePipe],
  imports: [CommonModule],
  exports: [AmDatePipe]
})
export class DateTimeModule {}
