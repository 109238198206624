import { Component } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { QualtricsLoaderService } from "@spartacus/qualtrics/components";
import { LanguageService } from '@spartacus/core';
import { Subscription, combineLatest } from 'rxjs';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { UpdateQtDataLayerService } from 'src/app/amiredeem/common/data-layer/qualtrics/updateQtDatalayer.service';

@Component({
  selector: 'am-qualtrics',
  template: '',
})
export class AmQualtricsComponent {
  protected subscription = new Subscription();
  protected qualtricsJs: string;

  constructor(
    protected qualtricsLoader: QualtricsLoaderService,
    // protected config: QualtricsConfig
    // protected thirdPartyLibService: ThirdPartyLibService,
    protected amConfigService: AmConfigService,
    protected winRef: WindowRef,
    protected languageService: LanguageService,
    protected updateQtDataLayerService: UpdateQtDataLayerService
  ) {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.subscription.add(
      combineLatest(
        this.amConfigService.getCommonConfig(),
        this.amConfigService.getIsInflightEnv()
      ).subscribe(([commonConfig, isInflightData]) => {
        if (isInflightData.data === false && !this.qualtricsJs) {
          let { env } = commonConfig;
          if (env) {
            env = env?.toLocaleLowerCase() === 'prod' ? 'prod' : 'dev';
            this.qualtricsJs = `./assets/lib/qualtrics/qualtrics.${env}.js`;
            this.addQualtricsJS();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  addQualtricsJS() {
    this.subscription.add(
      this.languageService.getActive().subscribe(lang => {
        this.updateQtDataLayerService.updateQualtricsLanguage(lang);
        this.qualtricsLoader.addScript(this.qualtricsJs);
      })
    );
  }
}
