import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { CartOfferDetailsDialogComponent } from './cart-offer-details-dialog.component';

@NgModule({
  imports: [CommonModule, IconModule, I18nModule, SafeCodeModule, DateTimeModule, KeyboardFocusModule],
  declarations: [CartOfferDetailsDialogComponent],
  exports: [CartOfferDetailsDialogComponent]
})
export class CartOfferDetailsDialogModule {}
