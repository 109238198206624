import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { CommonState } from '../common-state';
import { configReducer } from './common.reducer';

export function getReducers(): ActionReducerMap<CommonState, any> {
  return {
    commonConfig: configReducer
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<CommonState>> = new InjectionToken<
  ActionReducerMap<CommonState>
>('CommonReducers');
export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers
};
