import { Component, Input, OnInit } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CategoryItem } from '../recommend-product-list.component';

@Component({
  selector: 'am-recomment-product-categories',
  templateUrl: './recomment-product-categories.component.html',
  styleUrls: ['./recomment-product-categories.component.scss']
})
export class RecommentProductCategoriesComponent implements OnInit {
  svgType = svgConfig;
  style_arrow_icon = 'width: 32px; height: 32px;';

  @Input()
  categories: Array<CategoryItem>;

  constructor() {}

  ngOnInit(): void {}
}
