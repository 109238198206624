import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStorageService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AmAuthConfigService } from '../services/am-auth-config.service';
import { AmAuthHttpHeaderService } from '../services/am-auth-http-header.service';

/**
 * This interceptor is dedicated for Hybris OAuth server which requires `Authorization` header for revoke token calls.
 */
@Injectable({ providedIn: 'root' })
export class AmTokenRevocationInterceptor implements HttpInterceptor {
  constructor(
    protected authStorageService: AuthStorageService,
    protected authConfigService: AmAuthConfigService,
    protected amAuthHttpHeaderService: AmAuthHttpHeaderService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isTokenRevocationRequest = this.isTokenRevocationRequest(request);
    const isRefreshTokenExpired = this.amAuthHttpHeaderService.getRefreshTokenExpired();
    return this.authStorageService.getToken().pipe(
      take(1),
      switchMap((token) => {
        if (isTokenRevocationRequest) {
          if (!isRefreshTokenExpired) {
            request = request.clone({
              setHeaders: {
                Authorization: `${token.token_type || 'Bearer'} ${token.access_token}`,
                sessionTokenIdentifier: `${token?.sessionTokenIdentifier}`
              }
            });
          } else {
            request = request.clone({
              setHeaders: {
                sessionTokenIdentifier: `${token?.sessionTokenIdentifier}`
              }
            });
          }
        }
        return next.handle(request);
      })
    );
  }

  protected isTokenRevocationRequest(request: HttpRequest<any>): boolean {
    return request.url === this.authConfigService.getRevokeEndpoint();
  }
}
