import { supportCountries } from '@components/layout/header/am-site-context/services/country/default-country-config';
import { SiteContextConfig } from '@spartacus/core';

export const AmSiteContext: SiteContextConfig = {
  // when set the default value, it will use the first item.
  // the first one of country is HK, which currency is HKD, this should be align with the first item of currency
  context: {
    currency: ['HKD', 'USD'],
    language: ['en', 'ja', 'zh', 'ko', 'zh_HK', 'zh_TW'],
    country: supportCountries,
    baseSite: ['asiamiles'],
    urlParameters: ['language', 'country']
  }
};
