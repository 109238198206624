import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FavoriteNotifyProductsConnector } from '../../connectors/favorite-notify-products.connector';
import { FavoriteNotifyProductsService } from '../../services/favorite-notify-products.service';
import { FavoriteAndNotifyProductListActions } from '../actions';

@Injectable()
export class FavoriteAndNotifyProductListEffects {
  constructor(
    private actions$: Actions,
    protected favoriteNotifyProductsConnector: FavoriteNotifyProductsConnector,
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService
  ) {}

  loadFavoriteAndNotifyProductList$: Observable<FavoriteAndNotifyProductListActions.FavoriteAndNotifyProductListActions> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(FavoriteAndNotifyProductListActions.LOAD_FAVORITY_AND_NOTIFY_PRODUCT_LIST_PAGE_DATA),
        switchMap((action: FavoriteAndNotifyProductListActions.LoadFavoriteAndNotifyProducts) =>
          this.favoriteNotifyProductsConnector.getFavoriteAndNotifyProductList(
            action.payload?.activeType,
            action.payload?.sortCode
          )
        ),
        switchMap((data) => {
          return [new FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts(data)];
        })
      )
    );

  addProductIntoFavoriteList$: Observable<
    | FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts
    | FavoriteAndNotifyProductListActions.AddProductIntoFavoriteListFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteAndNotifyProductListActions.ADD_PRODUCT_INTO_FAVORITE_LIST),
      map((action: FavoriteAndNotifyProductListActions.AddProductIntoNotifyList) => action.payload),
      switchMap((payload) =>
        this.favoriteNotifyProductsConnector.addProductIntoFavoriteList(payload.productCode, payload.sortCode).pipe(
          map((data) => {
            const result = {
              favoriteEntries: data.favoriteEntries
            };
            this.handleException(data, 'addFavorite');
            return new FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts(result);
          }),
          catchError((error) => {
            this.favoriteNotifyProductsService.showReminderSub.next(error);
            return of(
              new FavoriteAndNotifyProductListActions.AddProductIntoFavoriteListFail(normalizeHttpError(error))
            );
          })
        )
      )
    )
  );

  removeProductFromFavoriteList$: Observable<
    | FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts
    | FavoriteAndNotifyProductListActions.RemoveProductFromFavoriteListFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteAndNotifyProductListActions.REMOVE_PRODUCT_FROM_FAVORITE_LIST),
      map((action: FavoriteAndNotifyProductListActions.RemoveProductFromFavoriteList) => action.payload),
      switchMap((payload) =>
        this.favoriteNotifyProductsConnector
          .removeProductFromFavoriteList(payload.productCode, payload.sortCode, payload.removeAllVariantProducts)
          .pipe(
            map((data) => {
              const result = {
                favoriteEntries: data.favoriteEntries
              };
              this.handleException(data, 'removeFavorite');
              return new FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts(result);
            }),
            catchError((error) => {
              this.favoriteNotifyProductsService.showReminderSub.next(error);
              return of(
                new FavoriteAndNotifyProductListActions.RemoveProductFromFavoriteListFail(normalizeHttpError(error))
              );
            })
          )
      )
    )
  );

  addProductIntoNotifyList$: Observable<
    | FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts
    | FavoriteAndNotifyProductListActions.AddProductIntoNotifyListFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteAndNotifyProductListActions.ADD_PRODUCT_INTO_NOTIFY_LIST),
      map((action: FavoriteAndNotifyProductListActions.RemoveProductFromFavoriteList) => action.payload),
      switchMap((payload) =>
        this.favoriteNotifyProductsConnector.addProductIntoNotifyList(payload.productCode, payload.sortCode).pipe(
          map((data) => {
            const result = {
              notifyMeEntries: data.notifyMeEntries
            };
            this.handleException(data, 'addNotifyMe');
            return new FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts(result);
          }),
          catchError((error) => {
            this.favoriteNotifyProductsService.showReminderSub.next(error);
            return of(new FavoriteAndNotifyProductListActions.AddProductIntoNotifyListFail(normalizeHttpError(error)));
          })
        )
      )
    )
  );

  removeProductFromNotifyList$: Observable<
    | FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts
    | FavoriteAndNotifyProductListActions.RemoveProductFromNotifyListFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteAndNotifyProductListActions.REMOVE_PRODUCT_FROM_NOTIFY_LIST),
      map((action: FavoriteAndNotifyProductListActions.RemoveProductFromFavoriteList) => action.payload),
      switchMap((payload) =>
        this.favoriteNotifyProductsConnector.removeProductFromNotifyList(payload.productCode, payload.sortCode).pipe(
          map((data) => {
            const result = {
              notifyMeEntries: data.notifyMeEntries
            };
            this.handleException(data, 'removeNotifyMe');
            return new FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts(result);
          }),
          catchError((error) => {
            this.favoriteNotifyProductsService.showReminderSub.next(error);
            return of(
              new FavoriteAndNotifyProductListActions.RemoveProductFromNotifyListFail(normalizeHttpError(error))
            );
          })
        )
      )
    )
  );

  private handleException(data, action) {
    if (data?.errorMeg?.errorCode === 'MEET_MAX_LENGTH') {
      action = action += 'Fail';
    } else {
      action += 'Success';
    }
    const obj = {
      productCode: data.productCode,
      isSuccess: data.isSuccess,
      action
    };
    this.favoriteNotifyProductsService.showReminderSub.next(obj);
  }
}
