import { ConsentTemplate } from '@spartacus/core';
import { ConsentRecord, CONSENT_STATUS } from '../../model/consent.model';
import { AmConsentsActions } from '../actions';
import { AmConsentsTemplateState } from '../am-consents-state';

export const initialState: AmConsentsTemplateState = {
  templates: [],
  consents: []
};

export function toggleConsentStatus(
  amConsentsTemplateState: AmConsentsTemplateState,
  templateCode: string,
  status: CONSENT_STATUS
): AmConsentsTemplateState {
  const consents = amConsentsTemplateState.consents;
  if (!consents) {
    return amConsentsTemplateState;
  }
  const newConsents = consents.map((consent) => {
    if (consent.templateCode === templateCode) {
      consent = {
        ...consent,
        consentState: status
      };
    }
    return consent;
  });
  return {
    ...amConsentsTemplateState,
    consents: newConsents
  };
}

export function reducer(state = initialState, action: AmConsentsActions.AmConsentsActions): AmConsentsTemplateState {
  switch (action.type) {
    case AmConsentsActions.LOAD_CONSENT_TEMPLATES_SUCCESS: {
      const templates: ConsentTemplate[] = action.payload;
      return {
        ...state,
        templates
      };
    }
    case AmConsentsActions.SET_CONSENTS: {
      const consents: ConsentRecord[] = action.payload;
      return {
        ...state,
        consents
      };
    }
    case AmConsentsActions.GIVEN_CONSENT: {
      return toggleConsentStatus(state, action.payload.templateCode, CONSENT_STATUS.GIVEN);
    }
    case AmConsentsActions.WITHDRAWN_CONSENT: {
      return toggleConsentStatus(state, action.payload.templateCode, CONSENT_STATUS.WITHDRAWN);
    }
  }
  return state;
}
