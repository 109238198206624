import { Component, OnDestroy } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CmsService, OccConfig } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'am-category-thumbanil-pnf',
  templateUrl: './category-thumbanil-pnf.component.html'
})
export class CategoryThumbanilPnfComponent implements OnDestroy {
  svgType = svgConfig;
  details$ = this.componentData.data$.pipe(
    switchMap(
      (data) =>
        data?.categoryThumbnails &&
        combineLatest(
          data?.categoryThumbnails
            .trim()
            .split(' ')
            .map((component) =>
              this.cmsService.getComponentData(component).pipe(
                map((detailItem) => {
                  if (!detailItem) {
                    return null;
                  }
                  return detailItem;
                })
              )
            )
        )
    )
  );
  breakpoint: BREAKPOINT;
  subscription = new Subscription();
  constructor(
    public componentData: CmsComponentData<any>,
    protected occConfig: OccConfig,
    protected cmsService: CmsService,
    protected breakpointService: BreakpointService
  ) {
    this.subscription.add(
      this.breakpointService.breakpoint$.subscribe((d) => {
        this.breakpoint = d;
      })
    );
  }
  isFlexGrow(data): boolean {
    if ((this.breakpoint === BREAKPOINT.lg || this.breakpoint === BREAKPOINT.xl) && data?.length < 6) {
      return true;
    } else if (this.breakpoint === BREAKPOINT.md && data?.length < 3) {
      return true;
    }
    return false;
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
