import { Component, Input, OnInit } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { Cart, OrderEntry } from '@spartacus/cart/base/root';
import { AmCartService } from '../../service/am-cart.service';

@Component({
  selector: 'am-cart-detail-nonflow1-container',
  templateUrl: './cart-detail-nonflow1-container.component.html',
  styleUrls: ['./cart-detail-nonflow1-container.component.scss']
})
export class CartDetailNonflow1ContainerComponent implements OnInit {
  @Input() isStoreOpenStatus: boolean;
  @Input() cart: Cart;
  @Input() entries: OrderEntry[] = [];
  @Input() cartLoaded: boolean;

  constructor(
    protected amCartService: AmCartService,
    protected flightStatusStorageService: FlightStatusStorageService
  ) {}

  ngOnInit(): void {
    // console.log(
    //   this.cart.deliveryMethod === 'inflight',
    //   !this.flightStatusStorageService.isInflightPickFlow,
    //   'non flow 1'
    // );
    if (this.cart.deliveryMethod === 'inflight' && !this.flightStatusStorageService.isInflightPickFlow) {
      this.amCartService.switchInflightOrGround('ground');
    }
  }

  getStoreOpenStatusOff(ev): void {
    if (ev === false) {
      this.isStoreOpenStatus = ev;
    }
  }

  checkCartItemIfDelistOrOffline(cart: Cart): boolean {
    let invalidCart = cart.entries?.find((item) => item.isDelisted || item.isOffline);
    return invalidCart ? true : false;
  }
}
