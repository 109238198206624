<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog">
      <div class="cx-dialog-header modal-header">
        <div class="d-flex">
          <div class="cx-dialog-title modal-title promtion-title" aria-live="polite" aria-atomic="true">
            {{ 'amCartDetails.slider.promotion.popup.title' | cxTranslate }}
          </div>
          <button type="button" class="close" (click)="closeModal('close current popup')">
            <!-- <span aria-hidden="true"> <i class="amicon-am-close"></i></span> -->
            <svg class="icon-close">
              <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
            </svg>
          </button>
        </div>
        <div class="title-below">
          {{ 'amCartDetails.slider.promotion.popup.description' | cxTranslate }}
        </div>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body" style="max-height: calc(100vh - 107px); overflow: auto">
        <ng-container *ngIf="!!product">
          <div class="price-wrapper">
            <ng-container
              *ngIf="
                product.paymentMethod === 'MilesPlusCash' || product.paymentMethod === 'FullCashOrMpcWithMinMiles'
              ">
              <ng-container *ngIf="product.originFullCashPrice && product.originFullCashPrice !== '0'">
                <p class="sale-price">
                  <span class="cash-box">
                    <span class="currency">{{ product.currency }}</span>
                    <ng-container *ngIf="!product.discountFullCashPrice">
                      {{ product.originFullCashPrice | number : '1.0-' + decimal }}
                    </ng-container>
                    <ng-container *ngIf="product.discountFullCashPrice">
                      {{ product.discountFullCashPrice | number : '1.0-' + decimal }}
                    </ng-container>
                  </span>
                  <ng-container *ngIf="product.minimumMilesSpend > 0 && product.paymentMethod === 'MilesPlusCash'">
                    <span class="miles-box">
                      <span class="icon-plus">+</span>
                      <span class="am-icon-grey"></span>
                      {{ product.minimumMilesSpend | number : '1.0' }}
                    </span>
                  </ng-container>
                </p>
              </ng-container>

              <p class="full-miles-price">
                <span class="sale-miles-price miles-box">
                  <span *ngIf="product.originFullCashPrice > 0" class="text-or">
                    {{ 'amProductItem.or' | cxTranslate }}
                  </span>
                  <span class="am-icon-grey"></span>
                  <span class="miles">
                    <ng-container *ngIf="product.promotionPrice?.value">
                      {{ product.promotionPrice.value | number : '1.0' }}
                    </ng-container>
                    <ng-container *ngIf="!product.promotionPrice?.value">
                      {{ product.price?.value | number : '1.0' }}
                    </ng-container>
                  </span>
                </span>
              </p>
            </ng-container>

            <ng-container *ngIf="product.paymentMethod === 'CashOnly'">
              <p class="sale-price only-cash">
                <span class="cash-box">
                  <span class="currency">{{ product.currency }}</span>
                  <span>
                    <ng-container *ngIf="!product.discountFullCashPrice">
                      {{ product.originFullCashPrice | number : '1.0-' + decimal }}
                    </ng-container>
                    <ng-container *ngIf="product.discountFullCashPrice">
                      {{ product.discountFullCashPrice | number : '1.0-' + decimal }}
                    </ng-container>
                  </span>
                </span>
              </p>
            </ng-container>

            <ng-container *ngIf="product.paymentMethod === 'MilesOnly'">
              <p class="sale-price onlyMiles">
                <span class="sale-miles-price miles-box">
                  <span class="am-icon-grey"></span>
                  <ng-container *ngIf="product.promotionPrice?.value">
                    {{ product.promotionPrice.value | number : '1.0' }}
                  </ng-container>
                  <ng-container *ngIf="!product.promotionPrice?.value">
                    {{ product.price?.value | number : '1.0' }}
                  </ng-container>
                </span>
              </p>
            </ng-container>

            <ng-container>
              <div class="original-miles-price-container">
                <span> {{ 'amCartDetails.originPrice' | cxTranslate }}: </span>
                <ng-container
                  *ngIf="
                    product.discountFullCashPrice &&
                    product.paymentMethod !== 'MilesOnly' &&
                    product.originFullCashPrice * 1 > product.discountFullCashPrice * 1
                  ">
                  <span class="original-miles-price miles-box">
                    <span class="currency">{{ product.currency }}</span>
                    <del>
                      {{ product.originFullCashPrice | number : '1.0-' + decimal }}
                    </del>
                    <ng-container *ngIf="product.minimumMilesSpend > 0 && product.paymentMethod === 'MilesPlusCash'">
                      <span class="miles-box">
                        <span class="icon-plus">+</span>
                        <span class="am-icon-grey"></span>
                        {{ product.minimumMilesSpend | number : '1.0' }}
                      </span>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        product.promotionPrice && product.promotionPrice.value && product.paymentMethod !== 'CashOnly'
                      ">
                      <span class="slash-or">/</span>
                    </ng-container>
                  </span>
                </ng-container>

                <ng-container *ngIf="product.promotionPrice?.value && product.paymentMethod !== 'CashOnly'">
                  <div class="original-miles-price miles-box">
                    <span class="am-icon-light-grey"></span>
                    <del class="origin-product-price">
                      {{ product.price?.value | number : '1.0' }}
                    </del>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div class="main">
            <div class="section" *ngFor="let discount of product.discountRows">
              <div class="d-flex">
                <div class="discount-img">
                  <img
                    src="/assets/images/icons/discount-yellow.svg"
                    class="img-fluid"
                    *ngIf="discount.discountType === 'OTHER'"
                    alt="" />
                  <img
                    src="/assets/images/icons/discount-mpo-member.svg"
                    class="img-fluid"
                    *ngIf="discount.discountType === 'MPO'"
                    alt="" />
                  <img
                    src="/assets/images/icons/discount-cobrand-card.svg"
                    class="img-fluid"
                    *ngIf="discount.discountType === 'CO_BRAND'"
                    alt="" />
                </div>
                <div class="font-bold font-size-sm">
                  {{ discount.description }}
                </div>
              </div>
              <div class="discount-detail">
                <div
                  class="detail font-size-xs"
                  *ngIf="discount.descriptionAML"
                  [innerHTML]="discount.descriptionAML | safeHtml"></div>
                <p class="detail font-bold font-size-xs">
                  {{ 'amCartDetails.slider.promotion.popup.promotionPeriod' | cxTranslate }}
                  {{ discount.startTime | amDate }} - {{ discount.endTime | amDate }}
                </p>
                <a
                  aria-label="Go to terms and conditions"
                  class="detail font-size-xs"
                  [href]="discount.termsAndCondition">
                  {{ 'amCartDetails.slider.promotion.popup.termsAndCondition' | cxTranslate }}
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
