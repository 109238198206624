import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface inflightDiagnosticModel {
  environment?: string;
  flightNo?: string;
  inflightSalesChannel?: string;
  ipAddress?: string;
  sessionID?: string;
  isSuccess?: string;
}

@Injectable({
  providedIn: 'root'
})
export class InflightDiagnosticService {
  constructor(private occEndpointSvc: OccEndpointsService, private http: HttpClient) {}

  getInflightDiagnosticInfo(): Observable<inflightDiagnosticModel> {
    const url = this.occEndpointSvc.buildUrl('getInflightDiagnosticInfo');
    return this.http.get<inflightDiagnosticModel>(url).pipe(
      map((object) => {
        return object;
      })
    );
  }
}
