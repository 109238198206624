import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideDefaultConfigFactory, StateConfig, StateTransferType } from '@spartacus/core';
import { effects } from './effects';
import { AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE } from './favorite-notify-product-list-state';
import { reducerProvider, reducerToken } from './reducers';

export function favoriteNotifyProductStoreConfigFactory(): StateConfig {
  // if we want to reuse SITE_CONTEXT_FEATURE const in config, we have to use factory instead of plain object
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: { [AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE]: StateTransferType.TRANSFER_STATE }
      }
    }
  };
  return config;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(AM_FAVORITE_AND_NOTIFY_PRODUCT_LIST_FEATURE, reducerToken),
    EffectsModule.forFeature(effects)
  ],
  providers: [provideDefaultConfigFactory(favoriteNotifyProductStoreConfigFactory), reducerProvider]
})
export class FavoriteNotifyProductStoreModule {
  static forRoot(): ModuleWithProviders<FavoriteNotifyProductStoreModule> {
    return {
      ngModule: FavoriteNotifyProductStoreModule,
      providers: []
    };
  }
}
