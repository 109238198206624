import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { WindowRef } from '@spartacus/core';
import { FlightStatusStorageService } from '../am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';

@Component({
  selector: 'am-change-flight-confiramtion-popup',
  templateUrl: './change-flight-confiramtion-popup.component.html',
  styleUrls: ['./change-flight-confiramtion-popup.component.scss']
})
export class ChangeFlightConfiramtionPopupComponent implements OnInit {
  @ViewChild('changeFlightContent') changeFlightContent: ElementRef;
  @ViewChild('title') title: ElementRef;

  svgType = svgConfig;
  style_close_btn = 'width:36px; height: 36px; margin-left: 20px; margin-top: 10px';

  constructor(
    protected launchDialogService: AmLaunchDialogService,
    protected flightStatusStorageSvc: FlightStatusStorageService,
    protected winRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.winRef.isBrowser() &&
      setTimeout(() => {
        this.changeFlightContent.nativeElement.focus();
      }, 500);
  }

  closeModal(): void {
    this.launchDialogService.closeDialog('');
  }

  onFirstElementTab(): void {
    this.changeFlightContent.nativeElement.focus();
  }

  onLastElementTab(): void {
    this.title.nativeElement.focus();
  }

  leaveShop(): void {
    this.flightStatusStorageSvc.removeFlightStatus();
    this.winRef.location.reload();
  }
}
