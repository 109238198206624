<ng-container *ngIf="cart$ | async as cart">
  <div class="total-price-delivery-fee delivery-fee">
    <div class="total-price-label">
      <span class="delivery-fee-label">{{ 'amCartDetails.summary.detailsDeliveryFeeLabel' | cxTranslate }}</span>
      <button class="link" aria-label="popup deliver fee details" (click)="openDeliveryFeeDialog(cart)">
        <am-svg-container [type]="svgType.ICONINFOPROMOTION" [style]="style_info_link"></am-svg-container>
      </button>
    </div>

    <div *ngIf="showDeliveryFee" class="deliver-fee-value">
      <ng-container *ngIf="!cart.waiveDeliveryFee; else elseWaiveDeliveryFee">
        <ng-container *ngIf="cart.totalDeliveryFee > 0; else DeliverFeeFree">
          <ng-container *ngIf="cart.deliveryFeeCashOrMilesToggle; else deliveryFeeCash">
            <span class="am-icon-grey"></span>
            <span>
              {{ cart.totalDeliveryFee | number : '1.0' }}
            </span>
          </ng-container>
          <ng-template #deliveryFeeCash>
            <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
            <span class="font-bold">
              {{ cart.totalDeliveryFee | number : '1.2-2' }}
            </span>
          </ng-template>
        </ng-container>
        <ng-template #DeliverFeeFree>
          <span *ngIf="hasInclude" class="font-bold">{{ 'deliveryFee.Included' | cxTranslate }}</span>
          <span *ngIf="!hasInclude && hasFree" class="font-bold">{{ 'deliveryFee.Free' | cxTranslate }}</span>
          <span *ngIf="!hasInclude && !hasFree && hasNa" class="font-bold">{{ 'deliveryFee.NA' | cxTranslate }}</span>
        </ng-template>
      </ng-container>

      <ng-template #elseWaiveDeliveryFee>
        <span class="font-bold font-gold">
          {{ 'amCartDetails.summary.deliverFeeFree' | cxTranslate }}
        </span>
      </ng-template>
    </div>
    <div *ngIf="!showDeliveryFee" class="deliver-fee-value">
      <span class="font-bold">
        {{ 'deliveryFee.NA' | cxTranslate }}
      </span>
    </div>
  </div>
</ng-container>
