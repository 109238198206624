import { Action } from '@ngrx/store';
import { DeliveryAddressInfo } from '../../components/model/deliveryAddressInfo';

export const LOAD_DELIVERY_AND_ADDRESSES = '[Am-checkout-out] Load DeliveryAddressInfo';
export const LOAD_DELIVERY_AND_ADDRESSES_FAIL = '[Am-checkout-out] Load DeliveryAddressInfo Fail';
export const LOAD_DELIVERY_AND_ADDRESS_INFO_SUCCESS = '[Am-checkout-out] Load DeliveryAddressInfo Success';
export const SET_ACTIVE_ADDRESS_CODE = '[Am-checkout-out] Set Active Address Code';

export class LoadDeliveryAddresses implements Action {
  readonly type = LOAD_DELIVERY_AND_ADDRESSES;
  constructor() {}
}

export class LoadDeliveryAddressesFail implements Action {
  readonly type = LOAD_DELIVERY_AND_ADDRESSES_FAIL;
  constructor(public payload: any) {}
}

export class LoadDeliveryAddressesSuccess implements Action {
  readonly type = LOAD_DELIVERY_AND_ADDRESS_INFO_SUCCESS;
  constructor(public payload: DeliveryAddressInfo) {
    this.payload = payload;
  }
}

export type DeliveryAddressesAction = LoadDeliveryAddresses | LoadDeliveryAddressesFail | LoadDeliveryAddressesSuccess;
