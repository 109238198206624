import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { VerachatModule } from '../verachat/verachat.module';
import { LivechatComponent } from './livechat.component';

@NgModule({
  declarations: [LivechatComponent],
  imports: [CommonModule, SvgContainerModule, I18nModule, VerachatModule],
  exports: [LivechatComponent]
})
export class LivechatModule {}
