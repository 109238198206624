import { CommonActions } from '../actions/index';

export function configReducer(state, action) {
  switch (action.type) {
    case CommonActions.CREATE_COMMON_CONFIG:
      return {};
    case CommonActions.LOAD_COMMON_CONFIG_SUCCESS:
      return action.payload;
  }
  return state;
}
