import { NgModule } from '@angular/core';
import { AsmRootModule, ASM_FEATURE } from "@spartacus/asm/root";
import { CmsConfig, provideConfig } from "@spartacus/core";

@NgModule({
  declarations: [],
  imports: [
    AsmRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [ASM_FEATURE]: {
        module: () =>
          import('@spartacus/asm').then((m) => m.AsmModule),
      },
    }
  }),
  // provideConfig(<I18nConfig>{
  //   i18n: {
  //     resources: asmTranslations,
  //     chunks: asmTranslationChunksConfig,
  //   },
  // })
  ]
})
export class AsmFeatureModule { }
