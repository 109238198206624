<div class="bottom-sheet-container" *ngIf="product" 
  [cxFocus]="{ trap: true, block: true, autofocus: true, focusOnEscape: true }">
  <div class="bottom-sheet-header">
    <button aria-label="close promotion details popup" class="close-popup-btn link" (click)="closeModal('Cross click')">
      <am-svg-container
        [type]="svgType.CLOSEGREYSM"
        [style]="style_icon_close"
        svgDescription="close popup"></am-svg-container>
    </button>
  </div>
  <div class="bottom-sheet-content">
    <div class="product-info-wrapper">
      <div class="image-container">
        <a class="product-image-box">
          <am-media [container]="product?.images?.PRIMARY" format="cartIcon"></am-media>
        </a>
      </div>
      <div class="product-info">
        <div class="product-description">
          <h5 class="brand-name font-bold" *ngIf="product.brandName">{{ product.brandName }}</h5>
          <p class="product-name">{{ product.name }}</p>
        </div>
        <div class="product-sale">
          <div class="product-price">
            <am-plp-product-price [product]="product"></am-plp-product-price>
          </div>
        </div>
      </div>
    </div>

    <am-relation-ship
      [product]="product"
      [currentCollectionMethod]="product.collectionMethod"
      [pageFrom]="'myListPage'"
      (emitProductCode)="getCurrentProductCode($event)"></am-relation-ship>
    <ng-container *ngIf="!product.externalRedirect">
      <am-product-variants-container
        [pageFrom]="'mylistPage'"
        [productCode]="product.code"
        (emitProductCode)="getCurrentProductCode($event)"></am-product-variants-container>
    </ng-container>

    <ng-container *ngIf="!product.responseInfo?.globalMessages?.accErrorMsgs?.length">
      <am-product-slider-electronic
        [pageFrom]="'mylistPage'"
        [product]="product"
        [electronicForm]="electronicForm"></am-product-slider-electronic>
      <am-product-slider-alcoholic
        [pageFrom]="'mylistPage'"
        [product]="product"
        [alcoholicForm]="alcoholicForm"></am-product-slider-alcoholic>
    </ng-container>
  </div>

  <div class="bottom-sheet-footer">
    <div class="view-full-product" (click)="goProductDetails(product)">
      <span>{{ 'amWishProductList.viewProductDetails' | cxTranslate }}</span>
      <am-svg-container
        [type]="svgType.ICONARROWFORWARD"
        [style]="style_icon_close"
        svgDescription="go to product details"></am-svg-container>
    </div>

    <div class="error-message" *ngFor="let item of product?.responseInfo?.globalMessages?.accErrorMsgs">
      <am-message-tip
        *ngIf="item.type !== 'amErrorMapping.default'"
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="item.type | cxTranslate"></am-message-tip>

      <am-message-tip
        *ngIf="item.type === 'amErrorMapping.default'"
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="item.code"></am-message-tip>
    </div>

    <am-add-to-cart
      [showQuantity]="false"
      [(mileValue)]="mileValue"
      [product]="product"
      [electronicForm]="electronicForm"
      [alcoholicForm]="alcoholicForm"
      [productCode]="product?.oldCode ? product?.oldCode : product?.code"
      [isNotifyMe]="isNotifyMe"
      [isFavoriteNotifyBottomSheet]="true"
      (emitAddCartResult)="getAddCartResult($event)"></am-add-to-cart>
  </div>
</div>
