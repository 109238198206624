<ng-container *ngIf="product && delivery$ | async as deliveryObj">
  <div class="d-flex" #parentElement>
    <div [innerHTML]="deliveryObj | safeHtml" #parentElement></div>
    <ng-container
      *ngIf="
        product.collectionMethod === collectionMethod.SHOP_PICKUP && deliveryPointOfService && this.pageFrom !== 'pdp'
      ">
      <button
        [amToolTip]="deliveryPointDetail1"
        [amPopoverOptions]="{ parentElement: parentElement }"
        class="link"
        aria-label="click pick up details">
        <!-- <span class="amicon-info color-info"> </span> -->
        <am-svg-container [type]="svgType.ICONINFOFILL" class="color-info icon-info-sm"></am-svg-container>
      </button>
      <ng-template #deliveryPointDetail1>
        <div class="product-bubbling">
          <p class="bubbling-title font-bold">
            {{ deliveryPointOfService.storeName }}
          </p>
          <p class="bubbling-text" [innerHTML]="deliveryPointOfService.storeAddress | safeHtml">
            {{ deliveryPointOfService.storeAddress }}
          </p>
          <p class="bubbling-text">
            {{ 'amCartItems.openingHour' | cxTranslate }} {{ deliveryPointOfService.openingHours?.name }}
          </p>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
