import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageMetaResolver } from '@spartacus/core';
import { AmCategoryPageMetaResolver } from './pages/am-category-page-meta.resolver';
import { AmCheckoutPageMetaResolver } from './pages/am-checkout-page-meta.resolver';
import { AmContentPageMetaResolver } from './pages/am-content-page-meta.resolver';
import { AmHomePageMetaResolver } from './pages/am-home-page-meta.resolver';
import { AmProductPageMetaResolver } from './pages/am-product-page-meta.resolver';
import { AmSearchPageMetaResolver } from './pages/am-search-page-meta.resolver';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: PageMetaResolver,
      useExisting: AmHomePageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmCategoryPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmSearchPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmProductPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmContentPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmCheckoutPageMetaResolver,
      multi: true
    }
  ]
})
export class AmPageMetaModule {}
