import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface AccordionConfig {
  headerLabel: string;
  expanded: boolean;
  expandedChange: EventEmitter<boolean>;
  counterTotal: string;
}
@Component({
  selector: 'am-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  ACCORDION_TYPE = {
    arrowIconType: 'arrowType',
    plusIconType: 'plusType'
  };

  @Input() headerLabel: string;
  @Input() expanded: boolean = false;
  @Input() classRef: string;
  @Input() idRef: string;
  @Input() displayIconPosition: string = 'left';
  @Input() accordionType: string = this.ACCORDION_TYPE.arrowIconType;
  @Input() ariaLabel: string;
  @Output() expandedChange = new EventEmitter<object>();

  constructor(protected ref: ElementRef) {}

  ngOnInit(): void {}

  headerClicked(e): void {
    this.expanded = !this.expanded;
    let clickObj = {
      accordionName: this.idRef,
      isExpanded: this.expanded
    };
    this.expandedChange.next(clickObj);
  }
}
