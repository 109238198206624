import { Provider } from '@angular/core';
import {
  ActiveCartService,
  CartValidationService,
  CartVoucherService,
  MultiCartService,
  SelectiveCartService
} from '@spartacus/cart/base/core';
import {
  ActiveCartFacade,
  CartValidationFacade,
  CartVoucherFacade,
  MultiCartFacade,
  SelectiveCartFacade
} from '@spartacus/cart/base/root';

export const facadeProviders: Provider[] = [
  ActiveCartService,
  {
    provide: ActiveCartFacade,
    useExisting: ActiveCartService
  },
  CartVoucherService,
  {
    provide: CartVoucherFacade,
    useExisting: CartVoucherService
  },
  MultiCartService,
  {
    provide: MultiCartFacade,
    useExisting: MultiCartService
  },
  SelectiveCartService,
  {
    provide: SelectiveCartFacade,
    useExisting: SelectiveCartService
  },
  CartValidationService,
  {
    provide: CartValidationFacade,
    useExisting: CartValidationService
  }
];
