import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, KeyboardFocusModule, PageSlotModule } from '@spartacus/storefront';
import { SvgContainerModule } from '../../../content/svg-container/svg-container.module';
import { AmSiteLoginComponent } from './am-site-login.component';

@NgModule({
  declarations: [AmSiteLoginComponent],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    UrlModule,
    PageSlotModule,
    I18nModule,
    SvgContainerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        LoginComponent: {
          // typeCode
          component: AmSiteLoginComponent
        }
      }
    } as CmsConfig),
    KeyboardFocusModule
  ]
})
export class AmSiteLoginModule {}
