import { Routes } from '@angular/router';
import { AuthGuard, RouteLoadStrategy, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard } from '@spartacus/storefront';
import { AmCheckoutLoadingComponent } from '../pages/checkout/components/am-checkout-loading/am-checkout-loading.component';
import { BalanceErrorComponent } from '../pages/error/balance-error/balance-error.component';
import { GeneralErrorComponent } from '../pages/error/general-error/general-error.component';

// productCode放到posParams，让路由能够正常地拿到productCode进行匹配
// export function coustomProductUrlMatcher(segments: UrlSegment[]): UrlMatchResult | null {
//   if(segments.length === 1 && segments[0].path.endsWith("-p")) {
//     const productCode = segments[0].path.slice(0, -2)
//     return {
//       consumed: segments,
//       posParams: { productCode: new UrlSegment(productCode, {})}
//     }
//   }
//   return null;
// }

export const STATIC_ROUTES: Routes = [
  {
    path: 'checkout/balanceError',
    component: BalanceErrorComponent,
    canActivate: [CmsPageGuard, AuthGuard]
  },
  {
    path: 'checkout/generalError',
    component: GeneralErrorComponent,
    canActivate: [CmsPageGuard, AuthGuard]
  },
  {
    path: 'checkout/loading',
    component: AmCheckoutLoadingComponent,
    canActivate: [CmsPageGuard, AuthGuard]
  }
];

export const routingConfig: RoutingConfig = {
  routing: {
    protected: false,
    routes: {
      home: { paths: [''] },
      campaign: { paths: ['campaign/:labelOrId'] },
      // notFound: { paths: ['notFound'],  protected: false },
      notFound: { paths: ['campaign/pagenotfound'] }, //overwrite not-found
      productNotFound: { paths: ['productNotFound'], protected: true },
      unavailableInflightProduct: { paths: ['unavailableInflightProduct'], protected: true },
      outDateProduct: { paths: ['reminder/suggestions'] },
      // 修改product的路由
      product: {
        paths: [
          'Lifestyle-Awards/:Category/:subCategory/:thirdCategory/:fourCategory/:fiveCategory/p/:productCode',
          'Lifestyle-Awards/:Category/:subCategory/:thirdCategory/:fourCategory/p/:productCode',
          'Lifestyle-Awards/:Category/:subCategory/:thirdCategory/p/:productCode',
          'Lifestyle-Awards/:Category/:subCategory/p/:productCode',
          'Lifestyle-Awards/:Category/p/:productCode',
          'Lifestyle-Awards/p/:productCode',
          'p/:productCode'
        ],
        paramsMapping: { productCode: 'code' }
      },
      category: {
        paths: ['c/:categoryCode'],
        paramsMapping: { categoryCode: 'code' }
      },
      brand: {
        paths: ['brands/:brandName/c/:brandCode', 'brands/c/:brandCode']
      },
      search: {
        paths: ['search/:query']
      },

      cart: { paths: ['cart'], protected: true },
      //for smartedit ProtectedRoutesGuard
      cxpreview: {
        paths: ['cx-preview']
      },
      orders: {
        paths: ['my-account/orders'],
        protected: true
      },
      wishList: { paths: ['my-account/wishlist'], protected: true },
      orderDetails: {
        paths: ['my-account/order/:orderCode'],
        paramsMapping: { orderCode: 'code' },
        protected: true
      },
      orderReview: {
        paths: ['my-account/order/:orderCode/review'],
        paramsMapping: { orderCode: 'code' }
      },
      checkout: { paths: ['checkout'], protected: true },
      checkoutShippingAddress: { paths: ['checkout/multi/delivery-address/add'], protected: true },
      checkoutVerifyOtp: { paths: ['checkout/multi/payment-method/add'], protected: true },
      checkoutInflightVerifyPayment: { paths: ['checkout/multi/payment-verify/:orderCode'], protected: true },
      orderConfirmation: { paths: ['checkout/orderConfirmation/:orderCode'], protected: true },
      pendingOrderConfirmation: {
        paths: ['checkout/multi/payment-pending/orderConfirmation/:orderCode'],
        protected: true
      },
      inflightPickupOrderConfirmation: {
        paths: ['checkout/inflightPickupOrderConfirmation/:orderCode'],
        protected: true
      },
      checkoutInflightOrderComplete: { paths: ['checkout/orderCompletion/:orderCode'], protected: true },
      waitingPayment: { paths: ['checkout/multi/waiting-payment/:orderCode'], protected: true },
      inflightDiagostic: { paths: ['ifsnet'], protected: true }
    },
    loadStrategy: RouteLoadStrategy.ONCE
  }
};
