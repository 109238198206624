import { Action } from '@ngrx/store';
import { ConsentTemplate } from '@spartacus/core';
import { ConsentRecord } from '../../model/consent.model';

export const LOAD_CONSENT_TEMPLATES = '[Am Consents] Load Consent Templates';
export const LOAD_CONSENT_TEMPLATES_FAIL = '[Am Consents] Load Consent Templates Fail';
export const LOAD_CONSENT_TEMPLATES_SUCCESS = '[Am Consents] Load Consent Templates Success';
export const SET_CONSENTS = '[Am Consents] Set Consents';
export const GIVEN_CONSENT = '[Am Consents] Given Consent';
export const WITHDRAWN_CONSENT = '[Am Consents] Withdrawn Consent';

export class LoadConsentTemplates implements Action {
  readonly type = LOAD_CONSENT_TEMPLATES;
}

export class LoadConsentTemplatesFail implements Action {
  readonly type = LOAD_CONSENT_TEMPLATES_FAIL;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

export class LoadConsentTemplatesSuccess implements Action {
  readonly type = LOAD_CONSENT_TEMPLATES_SUCCESS;
  constructor(public payload: ConsentTemplate[]) {
    this.payload = payload;
  }
}

export class SetConsents implements Action {
  readonly type = SET_CONSENTS;
  constructor(public payload: ConsentRecord[]) {
    this.payload = payload;
  }
}

export class GivenConsent implements Action {
  readonly type = GIVEN_CONSENT;
  constructor(public payload: { templateCode: string }) {
    this.payload = payload;
  }
}

export class WithdrawnConsent implements Action {
  readonly type = WITHDRAWN_CONSENT;
  constructor(public payload: { templateCode: string }) {
    this.payload = payload;
  }
}

// action types
export type AmConsentsActions =
  | LoadConsentTemplates
  | LoadConsentTemplatesFail
  | LoadConsentTemplatesSuccess
  | SetConsents
  | GivenConsent
  | WithdrawnConsent;
