import { Component, OnDestroy } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { SiteContextType } from '@config/am-constant.config';
import { ActiveCartFacade, FlatDeliverFeeData } from '@spartacus/cart/base/root';
import { LAUNCH_CALLER, SiteContextComponentService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { svgConfig } from '../../../../config/am-svg-sprite.config';
import { AmCartService } from '../../service/am-cart.service';

@Component({
  selector: 'am-cart-deliver-fee',
  templateUrl: './cart-deliver-fee.component.html',
  styleUrls: ['./cart-deliver-fee.component.scss']
})
export class CartDeliverFeeComponent implements OnDestroy {
  cart$ = this.activeCartService.getActive().pipe(
    distinctUntilChanged(),
    tap((cart) => {
      this.showDeliveryFee = false;
      this.hasFree = false;
      this.hasInclude = false;
      this.hasNa = false;
      this.showFlatRateDeliveryPopup =
        cart.flatRateDeliveryEnabled === true && !this.amCartService.inflightFlowIfInfligthActive(cart.deliveryMethod);

      this.flatDeliverFeeObject = {
        cashDeliveryFee: Number(cart.flatRateDelivery?.cashPrice),
        cashCurrency: cart.flatRateDelivery?.cashCurrency,
        milesDeliveryFee: Number(cart.flatRateDelivery?.milesPrice),
        flatRateProgressBarData: cart.flatRateProgressBarData,
        deliveryFeeCashOrMilesToggle: cart.deliveryFeeCashOrMilesToggle,
        totalDeliveryFee: Number(cart.totalDeliveryFee)
      };
      cart.entries
        ?.filter((e) => e.selected)
        .forEach((entry) => {
          if (
            entry.product.collectionMethod === 'REDEMPTION_LETTER' ||
            entry.product.collectionMethod.startsWith('HOME')
          ) {
            this.showDeliveryFee = true;
            if (entry.product.deliveryCost > 0) {
              if (!entry.product.separateDeliveryFee) {
                this.hasInclude = true;
              }
            } else {
              this.hasFree = true;
            }
          } else {
            this.hasNa = true;
          }
        });
    })
  );

  currencyContext = SiteContextType.CURRENCY;
  // currency$ = this.siteContextService.getActiveItem(this.currencyContext);

  svgType = svgConfig;
  style_info_link = 'width: 16px; height: 16px; vertical-align: sub;';

  showDeliveryFee: boolean = false;
  hasInclude: boolean = false;
  hasFree: boolean = true;
  hasNa: boolean = false;
  showFlatRateDeliveryPopup: boolean = false;
  flatDeliverFeeObject: FlatDeliverFeeData;
  protected subscription = new Subscription();
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected launchDialogService: AmLaunchDialogService,
    protected siteContextService: SiteContextComponentService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected amCartService: AmCartService
  ) {}

  openDeliveryFeeDialog(): void {
    let dialog = null;
    if (this.showFlatRateDeliveryPopup) {
      dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.FLAT_RATE_DELIVERY_FEE, undefined, undefined, {
        flatDeliverFeeObject: this.flatDeliverFeeObject
      });
    } else {
      dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_DELIVE_FREE, undefined, undefined, {
        hasInclude: this.hasInclude,
        hasFree: this.hasFree,
        hasNa: this.hasNa,
        showDeliveryFee: this.showDeliveryFee
      });
    }
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
