import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmAsmAuthService } from '@core/asm/root/services/am-asm-auth.service';
import { AmAsmEnablerService } from '@core/asm/root/services/am-asm-enable.service';
import { AmLoginService } from '@core/auth/user-auth/facade/am-login.service';
import { OccEndpointsService, OCC_USER_ID_ANONYMOUS, User, UserIdService, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, race, timer } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmGetConfigService {
  constructor(
    protected occEndpointSvc: OccEndpointsService,
    protected httpClient: HttpClient,
    protected userIdService: UserIdService,
    protected authService: AmAsmAuthService,
    protected userAccountFacade: UserAccountFacade,
    protected amLoginService: AmLoginService,
    protected winRef: WindowRef,
    protected amAsmEnableService: AmAsmEnablerService
  ) {}

  getHashId(): Observable<{ data: string }> {
    if (this.amAsmEnableService.isEnabled()) {
      return of({ data: '' });
    }
    return this.amLoginService.loginProgress$.pipe(
      filter((status) => status !== true),
      switchMap(() => {
        return this.userIdService
          .getUserId()
          .pipe(distinctUntilChanged())
          .pipe(
            switchMap((userId) => {
              if (userId === OCC_USER_ID_ANONYMOUS) {
                this.removeStoreHashId();
                return of({ data: '' });
              }
              const hashId = this.getStoreHashId();
              if (!!hashId) {
                return of({ data: hashId });
              }
              const url = this.occEndpointSvc.buildUrl('generateHashId', {
                urlParams: { userId }
              });
              return this.httpClient.post<{ data: string }>(url, {}).pipe(
                tap((object) => {
                  this.storeHashId(object.data);
                })
              );
            })
          );
      })
    );
  }
  storeHashId(hashId): void {
    this.winRef.localStorage?.setItem('hashId', hashId);
  }

  getStoreHashId(): string {
    return this.winRef.localStorage?.getItem('hashId');
  }

  removeStoreHashId(): void {
    this.winRef.localStorage?.removeItem('hashId');
  }

  getUserInfo(): Observable<User> {
    return race(timer(2000), this.authService.isUserLoggedIn().pipe(filter((status) => status === true))).pipe(
      switchMap((status) => {
        if (status) {
          return this.userAccountFacade.get();
        }
        return of(null);
      })
    );
  }

  ngOnDestroy(): void {}
}
