import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { InflightTabType } from '@config/am-constant.config';
import { CheckoutPreCheckMsgCode } from '@model/cart.enum';
import { AddToCartParams } from '@model/cart.model';
import { Store } from '@ngrx/store';
import { ActiveCartFacade, CartModification } from '@spartacus/cart/base/root';
import { OccEndpointsService, UserIdService, WindowRef } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FavoriteNotifyProductsService } from '../../favorite-notify-products/services/favorite-notify-products.service';
import { AmOccCartEntryAdapter } from '../occ/am-occ-cart-entry.adapter';
import { AmOccCartValidationAdapter } from '../occ/am-occ-cart-validation.adapter';
import { AmOccCartVoucherAdapter } from '../occ/am-occ-cart-voucher.adapter';
import { CartMileAdapter } from '../occ/cart-mile.adapter';
import { CartActions } from '../store';
import { AmValidateCartService } from './am-validate-cart.service';
import { AmMultiCartService } from './multi-cart.service';

interface CartsData {
  carts: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class AmCartService {
  protected userId: string = '';
  protected cartId: string = '';

  // new add: override the existing function
  addToCartResult$ = new BehaviorSubject({});
  protected subscription = new Subscription();
  constructor(
    protected store: Store<any>,
    protected amOccCartVoucherAdapter: AmOccCartVoucherAdapter,
    protected cartMileAdapter: CartMileAdapter,
    protected activeCartService: ActiveCartFacade,
    protected amMultiCartService: AmMultiCartService,
    protected userIdService: UserIdService,
    protected amOccCartValidationAdapter: AmOccCartValidationAdapter,
    protected amOccCartEntryAdapter: AmOccCartEntryAdapter,
    protected http: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected amValidateCartService: AmValidateCartService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected winRef: WindowRef
  ) {
    this.subscription.add(
      combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()])
        .pipe(map(([userId, cartId]) => ({ userId, cartId })))
        .subscribe((data) => {
          this.userId = data.userId;
          this.cartId = data.cartId;
        })
    );
  }

  private changeRemove = new BehaviorSubject<boolean>(false);
  public removeSuccess$ = this.changeRemove.asObservable();

  updateMiles(miles): void {
    this.amValidateCartService.triggerValidateCartError(null);
    this.store.dispatch(new CartActions.UpdateCartMiles({ userId: this.userId, cartId: this.cartId, miles }));
  }

  removeEntries(num: Array<number>): void {
    // return combineLatest([this.getActiveCartId(), this.userIdService.takeUserId()]).pipe(
    //   switchMap(([cartId, userId]) => {
    return this.amMultiCartService.removeEntries(this.userId, this.cartId, num);
    // })
    // );
  }

  // refer the logic of the toggleDeliveryFee$ in am-cart-miles.effect.ts,
  // remove the am-cart-miles.effect.ts, because:
  // updateCart$ in the am-cart-miles.effect, and toggleDeliveryFee$, toggleDeliveryFeeSuccess$ doesn't depend on the redux
  toggleDeliveryFee(isOpen?: boolean): void {
    this.amValidateCartService.triggerValidateCartError(null);
    this.subscription.add(
      this.cartMileAdapter.toggleDeliveryFeeMiles(this.userId, this.cartId, isOpen).subscribe(() => {
        this.amMultiCartService.loadCart({ userId: this.userId, cartId: this.cartId });
      })
    );
  }

  toggleFcom(isOpen: boolean): void {
    this.amValidateCartService.triggerValidateCartError(null);
    this.subscription.add(
      this.cartMileAdapter.toggleFCOM(this.userId, this.cartId, isOpen).subscribe(() => {
        this.amMultiCartService.loadCart({ userId: this.userId, cartId: this.cartId });
      })
    );
  }

  switchInflightOrGround(activeTab: string, ifNeedReloadCart: boolean = true): void {
    this.subscription.add(
      this.cartMileAdapter.toggleDeliveryMethod(this.userId, this.cartId, activeTab).subscribe(() => {
        if (ifNeedReloadCart) {
          this.amMultiCartService.loadCart({ userId: this.userId, cartId: this.cartId });
        }
      })
    );
  }

  validateCart(): Observable<any> {
    return combineLatest([
      this.activeCartService.getActiveCartId(),
      this.userIdService.takeUserId(),
      this.activeCartService.isStable()
    ]).pipe(
      filter(([_, __, loaded]) => loaded),
      take(1),
      switchMap(([cartId, userId]) => this.amOccCartValidationAdapter.validate(cartId, userId)),
      tap((val) => {
        if (val.msgCode !== CheckoutPreCheckMsgCode.SUCCESS) {
          this.amValidateCartService.triggerValidateCartError(val);
        }
      })
    );
  }

  getCartEntryDiscount(entryNumber): Observable<any> {
    const url = this.occEndpointSvc.buildUrl('cartProductDiscount', {
      urlParams: {
        userId: this.userId,
        cartId: this.cartId,
        entryNumber
      }
    });
    return this.http.get(url);
  }

  getCartPageGiftOffer(entryNumber, promotionCode): Observable<any> {
    const url = this.occEndpointSvc.buildUrl('cartPageGiftOffer', {
      urlParams: {
        userId: this.userId,
        cartId: this.cartId,
        entryNumber
      }
    });
    return this.http.get(url, { params: { promotionCode } });
  }

  fetchCarts(userId?: string): Observable<CartsData> {
    if (!userId) {
      userId = this.userId;
    }
    const url = this.occEndpointSvc.buildUrl('carts', {
      urlParams: {
        userId: this.userId
      }
    });
    return this.http.get<CartsData>(url);
  }

  // update the voucherError$ from cart-voucher.effect to AmOccCartVoucherAdapter
  getVoucherError(): Observable<any> {
    this.amValidateCartService.triggerValidateCartError(null);
    return AmOccCartVoucherAdapter.voucherError$;
  }

  displayRemoveEntrySuccess(): void {
    this.changeRemove.next(true);
  }

  closeRemoveEntrySuccess(): void {
    this.changeRemove.next(false);
  }

  getRemoveEntrySuccessObs(): Observable<boolean> {
    return this.removeSuccess$;
  }

  /**
   * Add entry to active cart
   *
   * @param productCode
   * @param quantity
   */
  addToCart(productCode: string, quantity: number, param?: AddToCartParams): Observable<CartModification> {
    return this.activeCartService.requireLoadedCart().pipe(
      switchMap((cartState) => {
        if (!cartState) {
          return of(null);
        }
        return this.amOccCartEntryAdapter.add(this.userId, this.cartId, productCode, quantity, null, param).pipe(
          tap((result: any) => {
            result.productCode = productCode;
            this.addToCartResult$.next(result);
            if (!result.cart) {
              return;
            }
            if (result?.myList) {
              this.favoriteNotifyProductsService.setMyFavoriteAndNotifyMe(result?.myList);
            }
            this.amMultiCartService.cartUpdateByAddToCart(this.userId, this.cartId, result.cart);
          })
        );
      })
    );
  }

  getAddToCartResult(): Observable<CartModification> {
    return this.addToCartResult$;
  }

  inflightFlowIfInfligthActive(deliveryMethod: string): boolean {
    // console.log(deliveryMethod, 'deliveryMethod');
    let inflightFLow1ActiveInflightFlow = !deliveryMethod
      ? false
      : this.flightStatusStorageService.isInflightPickFlow && deliveryMethod === InflightTabType.INFLIGHT;
    this.winRef.localStorage?.setItem('InflightFLow1ActiveInflightFlow', inflightFLow1ActiveInflightFlow + '');

    return inflightFLow1ActiveInflightFlow;
  }
  /**
   * clean cart
   */
  cartClean(): void {
    this.amMultiCartService.cartClean(this.userId, this.cartId);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
