import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AmCheckoutConnector } from '../../facade/am-checkout-connector';
import { AmCheckoutActions } from '../actions/index';

@Injectable()
export class DeliveryAddressesEffects {
  constructor(private actions$: Actions, private amCheckoutDeliveryConnector: AmCheckoutConnector) {}

  loadDeliveryAndAddressInfo$: Observable<
    AmCheckoutActions.LoadDeliveryAddressesSuccess | AmCheckoutActions.LoadDeliveryAddressesFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(AmCheckoutActions.LOAD_DELIVERY_AND_ADDRESSES),
      mergeMap(() => {
        return this.amCheckoutDeliveryConnector.queryDeliveryAndAddresses().pipe(
          map((deliveryAddresses) => {
            return new AmCheckoutActions.LoadDeliveryAddressesSuccess(deliveryAddresses);
          }),
          catchError((error) => of(new AmCheckoutActions.LoadDeliveryAddressesFail(normalizeHttpError(error))))
        );
      })
    )
  );
}
