import { SearchActions } from '../actions/index';
import { SearchState } from '../state';

export const initialState: SearchState = {
  popularTags: null,
  popularBrands: [],
  cathayFeature: [],
  popularTagsList: [],
  loaded: false
};

export function reducer(state = initialState, action: SearchActions.SearchAction): SearchState {
  switch (action.type) {
    case SearchActions.LOAD_HOT_TAGS_SUCCESS: {
      const hotTagResponse = action.payload;

      return {
        ...state,
        ...hotTagResponse,
        loaded: true
      };
    }
    case SearchActions.LOAD_HOT_TAGS_FAIL: {
      return {
        ...state,
        loaded: true
      };
    }
  }
  return state;
}
