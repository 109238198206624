import { PendingOrderPopupComponent } from '@components/content/pending-order-popup/pending-order-popup.component';
import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { InflightSiteContextSelectorPopupComponent } from '../../header/am-site-context/components/inflight-site-context-selector-popup/inflight-site-context-selector-popup.component';
import { SiteContextSelectorPopupComponent } from '../../header/am-site-context/components/site-context-selector-popup/site-context-selector-popup.component';
import { ChangeFlightConfiramtionPopupComponent } from '../../header/change-flight-confirmation-popup/change-flight-confiramtion-popup.component';
import { ShoppingTipComponent } from '../../header/shopping-tip/shopping-tip.component';
import { SearchDialogComponent } from '@components/layout/header/display-search-box/search-dialog/search-dialog.component';

export const globalDialogLayoutConfig: LayoutConfig = {
  launch: {
    SELECT_SITE_CONTEXT: {
      inlineRoot: true,
      component: SiteContextSelectorPopupComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    INFLIGHT_SELECT_SITE_CONTEXT: {
      inlineRoot: true,
      component: InflightSiteContextSelectorPopupComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    INFLIGHT_CHANGE_FLIGHT_CONTEXT: {
      inlineRoot: true,
      component: ChangeFlightConfiramtionPopupComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    SHOW_PENDING_ORDER: {
      inlineRoot: true,
      component: PendingOrderPopupComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    SHOPPING_TIP_DIALOG: {
      inlineRoot: true,
      component: ShoppingTipComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    GLOBAL_SEARCH_DIALOG: {
      inlineRoot: true,
      component: SearchDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
  }
};
