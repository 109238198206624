import { Injectable } from '@angular/core';
import { Image } from '@spartacus/core';
import { Media, MediaContainer, MediaService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root'
})
export class AmMediaService extends MediaService {
  getMedia(mediaContainer?: MediaContainer | Image, format?: string, alt?: string, role?: string): Media | undefined {
    if (!mediaContainer) {
      return;
    }

    const mainMedia: Image = mediaContainer.url
      ? mediaContainer
      : this.resolveMedia(mediaContainer as MediaContainer, format);

    const responsiveImageObject = this.resolveResponsiveImageObject(mediaContainer, format);

    return {
      src: this.resolveAbsoluteUrl(mainMedia?.url),
      alt: alt ?? mainMedia?.altText,
      role: role ?? mainMedia?.role,
      srcset: this.resolveSrcSet(mediaContainer, format),
      ...responsiveImageObject
    };
  }

  protected resolveResponsiveImageObject(media: MediaContainer | Image, maxFormat?: string): any {
    if (!media) {
      return undefined;
    }

    // Only create srcset images that are smaller than the given `maxFormat` (if any)
    let formats = this.sortedFormats;
    const max: number = formats.findIndex((f) => f.code === maxFormat);
    if (max > -1) {
      formats = formats.slice(0, max + 1);
    }
    const imageObject = {};
    formats.forEach((format) => {
      if (!!media[format.code]) {
        imageObject[format.code] = this.resolveAbsoluteUrl(media[format.code].url);
      }
    });
    return imageObject;
  }
}
