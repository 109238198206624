import { TranslationChunksConfig } from '@spartacus/core';

export const spartacusLibTranslationChunksConfig: TranslationChunksConfig = {
  checkout: [
    'checkout',
    'checkoutAddress',
    'checkoutOrderConfirmation',
    'checkoutReview',
    'checkoutShipping',
    'checkoutProgress',
    'checkoutPO'
  ],
  asm: ['asm'],
  order: [
    'orderDetails',
    'orderHistory',
    'AccountOrderHistoryTabContainer',
    'returnRequestList',
    'returnRequest',
    'reorder'
  ],
  'import-export': ['exportEntries', 'importEntries', 'importEntriesDialog'],
  'quick-order': ['quickOrderCartForm', 'quickOrderForm', 'quickOrderList', 'quickOrderTable'],
  'saved-cart': ['savedCartDetails', 'savedCartList', 'savedCartCartPage', 'savedCartDialog', 'addToSavedCart']
};

export const amTranslationChunksConfig: TranslationChunksConfig = {
  'am-common': [
    'amCommon',
    'amCommonPhrase',
    'amSearchBox',
    'multiSearchBox',
    'reminder',
    'notificationBar',
    'liveChat',
    'formValidateErrors',
    'amDelivery',
    'amFormat',
    'amMinilogin',
    'amErrorMapping',
    'pendingOrderPopup',
    'languageAndDelivery',
    'languageAndFlightPopup',
    'changeFlightPopup',
    'priceChangeDialog',
    'amImg',
    'amProductItem',
    'deliveryFee',
    'favouriteNotifyMe',
    'destination'
    // ...translationChunksConfig.common
  ],
  'am-checkout': [
    'amCheckoutProgress',
    'amShippingAddress',
    'verificationPayment',
    'orderSummary',
    'pendingOrderConfirmation',
    'verifyPendingOrder',
    'inflightOrderComplete',
    'pendingOrderDetail',
    'paymentOffers',
    'amCheckoutLoading',
    'amGeneralError',
    'amBalanceError',
    'amAddressForm'
  ],
  'am-cart': ['amCartDetails', 'amCartItems', 'amVoucher', 'amEmptyCart'],
  'am-product': [
    'amProductDetails',
    'amAddToCart',
    'amVariant',
    'amStockNotification',
    'amItemCounter',
    'amProductImage',
    'amCobrandCards',
    'amCobrandCardsPopup',
    'amRecommendProduct',
    'freeGift'
  ],
  'am-order-list': ['amOrderListDetail', 'amEmptyOrderList'],
  'am-order-details': ['amOrderDetails'],
  'am-product-list': [
    'amProductFacet',
    'amProductListDetail',
    'amSearchPage',
    'amProductScroll',
    'amEmptyProduct',
    'amWishProductList'
  ]
  // "am-checkout-error": [
  //   'amGeneralError',
  //   'amBalanceError'
  // ],
  // ,
  // common: [
  //   'common',
  //   'spinner',
  //   'searchBox',
  //   'navigation',
  //   'sorting',
  //   'httpHandlers',
  //   'pageMetaResolver',
  //   'miniCart',
  //   'skipLink',
  //   'formErrors',
  //   'errorHandlers',
  //   'carousel',
  // ],
  // address: [
  //   'addressForm',
  //   'addressBook',
  //   'addressCard',
  //   'addressSuggestion',
  //   'addressMessages',
  // ],
  // payment: [
  //   'paymentForm',
  //   'paymentMethods',
  //   'paymentCard',
  //   'paymentTypes',
  //   'paymentMessages',
  // ],
  // pwa: ['pwa'],
  // product: [
  //   'productDetails',
  //   'productList',
  //   'productFacetNavigation',
  //   'productCarousel',
  //   'productSummary',
  //   'productReview',
  //   'addToCart',
  //   'addToWishList',
  //   'CMSTabParagraphContainer',
  //   'stockNotification',
  //   'TabPanelContainer',
  //   'itemCounter',
  //   'productView',
  // ],
  // user: ['anonymousConsents', 'loginRegister', 'checkoutLogin', 'authMessages'],
};
