<ng-container *ngIf="cart$ | async as cart">
  <!-- <div class="" [cxFocus]="{refreshFocus:cart}"> -->
  <div class="main-title">
    <am-svg-container [type]="svgType.PROMOCODE" svgDescription=""></am-svg-container
    >{{ 'amVoucher.mainTitle' | cxTranslate }}
  </div>
  <div class="cart-coupon" *ngIf="!couponCodeApplied">
    <form (ngSubmit)="applyVoucher()" [formGroup]="couponForm">
      <ng-container
        *ngIf="
          cart.appliedVouchers?.length && !cart.appliedProductPromotions?.length && !cart.usedCouponFlag;
          else elseCoupon
        ">
        <div class="cx-cart-coupon-container">
          <label class="input-wrapper">
            <input
              type="text"
              formControlName="couponCode"
              (ngModelChange)="removeSpace()"
              (change)="removeChangeVoucher(cart, false)" />
            <span
              class="label-content"
              *ngIf="!couponForm.get('couponCode').value"
              [ngClass]="{ 'not-empty': couponForm.get('couponCode').value }">
              {{ 'amCartDetails.slider.addAPromoCode' | cxTranslate }}
            </span>
            <span
              class="label-content"
              *ngIf="couponForm.get('couponCode').value"
              [ngClass]="{ 'not-empty': couponForm.get('couponCode').value }">
              {{ 'amCartDetails.slider.promoCode' | cxTranslate }}
            </span>
          </label>
          <button
            class="button-secondary"
            type="submit"
            aria-label="Apply Promo code"
            [disabled]="cartIsLoading$ | async"
            [class.button-disable]="cartIsLoading$ | async">
            {{ 'amVoucher.apply' | cxTranslate }}
          </button>
        </div>
        <ng-container *ngIf="showError">
          <div class="alert-message">
            <i class="icon-warning"></i>
            <span>
              {{ 'amCartDetails.error.promoCode' | cxTranslate }}
            </span>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #elseCoupon>
        <ng-container *ngIf="isWarnCouponCode(cart); else elseAppliedVouchers">
          <div class="cx-cart-coupon-container">
            <label class="input-wrapper">
              <input type="text" formControlName="couponCode" (ngModelChange)="removeChangeVoucher(cart, false)" />
              <span
                class="label-content"
                *ngIf="!couponForm.get('couponCode').value"
                [ngClass]="{ 'not-empty': couponForm.get('couponCode').value }">
                {{ 'amCartDetails.slider.addAPromoCode' | cxTranslate }}
              </span>
              <span
                class="label-content"
                *ngIf="couponForm.get('couponCode').value"
                [ngClass]="{ 'not-empty': couponForm.get('couponCode').value }">
                {{ 'amCartDetails.slider.promoCode' | cxTranslate }}
              </span>
            </label>
            <button
              aria-label="Apply Promo code"
              class="button-secondary"
              type="submit"
              [disabled]="cartIsLoading$ | async"
              [class.button-disable]="cartIsLoading$ | async">
              {{ 'amVoucher.apply' | cxTranslate }}
            </button>
          </div>
          <div class="alert-message" *ngIf="showError">
            <i class="icon-warning"></i>
            <span>
              {{ 'amCartDetails.error.promoCode' | cxTranslate }}
            </span>
          </div>
        </ng-container>
        <ng-template #elseAppliedVouchers>
          <ng-container *ngIf="!cart.appliedVouchers?.length">
            <div class="cx-cart-coupon-container">
              <label class="input-wrapper">
                <input
                  type="text"
                  formControlName="couponCode"
                  (change)="changeCoupon()"
                  (ngModelChange)="removeSpace()" />
                <span
                  class="label-content"
                  *ngIf="!couponForm.get('couponCode').value"
                  [ngClass]="{ 'not-empty': couponForm.get('couponCode').value }">
                  {{ 'amCartDetails.slider.addAPromoCode' | cxTranslate }}
                </span>
                <span
                  class="label-content"
                  *ngIf="couponForm.get('couponCode').value"
                  [ngClass]="{ 'not-empty': couponForm.get('couponCode').value }">
                  {{ 'amCartDetails.slider.promoCode' | cxTranslate }}
                </span>
              </label>
              <button
                aria-label="Apply Promo code"
                class="button-secondary"
                type="submit"
                [disabled]="cartIsLoading$ | async"
                [class.button-disable]="cartIsLoading$ | async">
                {{ 'amVoucher.apply' | cxTranslate }}
              </button>
            </div>
            <ng-container *ngIf="voucherError$ | async as voucherError">
              <div class="error-message" *ngIf="showError">
                <i class="icon-warning"></i>
                <span>
                  {{ voucherError.code | cxTranslate }}
                </span>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-template>
    </form>
  </div>
  <!-- Coupon code info after applying -->
  <ng-container *ngIf="couponCodeApplied">
    <div class="promo-code-box">
      <div class="promo-code-container d-flex">
        <span class="icon-success"></span>
        <div class="promo-code-info">
          <!-- Promotion on product -->
          <ng-container *ngIf="couponType === 1">
            <span
              [innerHTML]="
                'amCartDetails.slider.promo.successProduct' | cxTranslate : { p1: couponCodeApplied, p2: couponValue }
              "></span>
          </ng-container>

          <!-- Promotion on cart -->
          <ng-container *ngIf="couponType === 2">
            <span
              [innerHTML]="
                'amCartDetails.slider.promo.successOrderMiles'
                  | cxTranslate : { p1: couponCodeApplied, p2: couponValue }
              "></span>
          </ng-container>
          <ng-container *ngIf="couponType === 3">
            <ng-container *ngIf="couponValue | number : '1.2-2'; let couponCashValue">
              <span
                [innerHTML]="
                  'amCartDetails.slider.promo.successOrderCash'
                    | cxTranslate : { p1: couponCodeApplied, p2: currency, p3: couponCashValue }
                "></span>
            </ng-container>
          </ng-container>
          <!-- Promotion on bonus -->
          <ng-container *ngIf="couponType === 4">
            <span
              [innerHTML]="
                'amCartDetails.slider.promo.successEarnmiles' | cxTranslate : { p1: couponCodeApplied, p2: couponValue }
              "></span>
          </ng-container>
          <!-- Promotion on cobrand -->
          <ng-container *ngIf="couponType === 5">
            <span
              [innerHTML]="
                'amCartDetails.slider.promo.successCobrandCash' | cxTranslate : { p1: couponCodeApplied }
              "></span>
          </ng-container>

          <ng-container *ngIf="couponType === 6">
            <span
              [innerHTML]="
                'amCartDetails.slider.promo.successFreeGiftPromotion' | cxTranslate : { p1: couponCodeApplied }
              "></span>
          </ng-container>

          <a
            aria-label="Remove current coupon code"
            href="javascript:void(0)"
            class="font-bold remove-code"
            (click)="removeVoucher(cart, true)">
            {{ 'amCartDetails.slider.removePromoCode' | cxTranslate }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- </div> -->
</ng-container>
