<div class="cx-modal-container" [cxFocus]="{ trap: true, block: true, autofocus: true, focusOnEscape: true }">
  <div class="cx-modal-content">
    <div class="cart-dialog" *ngIf="cart$ | async as cart">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title-left" aria-live="polite" aria-atomic="true">
          {{ 'amCartDetails.summary.detailsDeliveryFeeLabel' | cxTranslate }}
        </div>
        <button aria-label="close popup" type="button" class="close-popup-btn link" (click)="closeModal('Cross click')">
          <svg class="icon-close">
            <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
          </svg>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main">
          <!-- Product entries info break down -->
          <ul class="entries-delivery-fee" *ngFor="let entry of filterEntries(cart.entries)">
            <li class="entries-name">
              <div>{{ entry.product.name }}</div>
            </li>
            <ng-container
              *ngIf="
                !(
                  entry.product.collectionMethod.startsWith('HOME') ||
                  entry.product.collectionMethod === 'REDEMPTION_LETTER'
                );
                else showDeliveryFee
              ">
              <li>
                <span class="font-bold">
                  {{ 'deliveryFee.NA' | cxTranslate }}
                </span>
              </li>
            </ng-container>

            <ng-template #showDeliveryFee>
              <li>
                <ng-container *ngIf="entry.product.deliveryFeeCash > 0; else elseCash">
                  <ng-container *ngIf="entry.product.separateDeliveryFee; else noSeparateDeliveryFee">
                    <ng-container *ngIf="cart.deliveryFeeCashOrMilesToggle; else elseFcomToggle">
                      <span class="am-icon-grey"></span>
                      <span>
                        {{ entry.product.deliveryFeeMiles * entry.quantity | number : '1.0' }}
                      </span>
                    </ng-container>
                    <ng-template #elseFcomToggle>
                      <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
                      <span class="font-bold">
                        {{ entry.product.deliveryFeeCash * entry.quantity | number : '1.2-2' }}
                      </span>
                    </ng-template>
                  </ng-container>
                  <ng-template #noSeparateDeliveryFee>
                    <span class="font-bold">{{ 'deliveryFee.Included' | cxTranslate }}</span>
                  </ng-template>
                </ng-container>
                <ng-template #elseCash>
                  <span class="font-bold">
                    {{ 'deliveryFee.Free' | cxTranslate }}
                  </span>
                </ng-template>
              </li>
            </ng-template>
          </ul>
          <!-- Waive delivery fee break down  -->
          <ul
            class="entries-delivery-fee font-green"
            *ngIf="cart.waiveDeliveryFee && !checkIfInflightFlow1ActiveInflight(cart.deliveryMethod)">
            <li class="d-flex entries-name">
              <span [innerHTML]="cart.appliedDeliveryFeePromotion.promotion.richTextSummary | safeHtml"></span>
              <button
                aria-label="Show waive delivery details"
                class="link"
                (click)="openWaiveDialog(cart.appliedDeliveryFeePromotion.promotion)">
                <i class="icon-promo delivery-fee-link"></i>
              </button>
            </li>
            <li>
              <span>-</span>
              <ng-container *ngIf="cart.deliveryFeeCashOrMilesToggle; else elseFcomToggle1">
                <span class="am-icon-green"></span>
                <span>
                  {{ cart.totalDeliveryFee | number : '1.0' }}
                </span>
              </ng-container>
              <ng-template #elseFcomToggle1>
                <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
                <span class="font-bold">
                  {{ cart.totalDeliveryFee | number : '1.2-2' }}
                </span>
              </ng-template>
            </li>
          </ul>

          <!-- Delivery fee total -->
          <div class="subtotal">
            <span>
              {{ 'amCartDetails.summary.detailsSubtotal' | cxTranslate }}
              <!-- <spring:theme code="text.cart.page.popup.delivery.fee.subtotal.label" /> -->
            </span>
            <span *ngIf="showDeliveryFee">
              <ng-container *ngIf="!cart.waiveDeliveryFee; else elseWaiveDeliveryFee">
                <ng-container *ngIf="cart.totalDeliveryFee > 0; else DeliverFeeFree">
                  <ng-container *ngIf="cart.deliveryFeeCashOrMilesToggle; else elseFcomToggle2">
                    <span class="am-icon-grey"></span>
                    <span>
                      {{ cart.totalDeliveryFee | number : '1.0' }}
                    </span>
                  </ng-container>
                  <ng-template #elseFcomToggle2>
                    <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
                    <span class="font-bold">
                      {{ cart.totalDeliveryFee | number : '1.2-2' }}
                    </span>
                  </ng-template>
                </ng-container>
                <ng-template #DeliverFeeFree>
                  <span *ngIf="hasInclude" class="font-bold">{{ 'deliveryFee.Included' | cxTranslate }}</span>
                  <span *ngIf="!hasInclude && hasFree" class="font-bold">{{ 'deliveryFee.Free' | cxTranslate }}</span>
                  <span *ngIf="!hasInclude && !hasFree && hasNa" class="font-bold">{{
                    'deliveryFee.NA' | cxTranslate
                  }}</span>
                </ng-template>
              </ng-container>
              <ng-template #elseWaiveDeliveryFee>
                <span class="font-bold" [class.font-green]="cart.waiveDeliveryFee">
                  {{ 'amCartDetails.summary.deliverFeeFree' | cxTranslate }}
                </span>
              </ng-template>
            </span>
            <span *ngIf="!showDeliveryFee" class="font-bold">{{ 'deliveryFee.NA' | cxTranslate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
