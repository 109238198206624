import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FreeGiftPromotion } from '@model/product.model';

@Component({
  selector: 'am-popup-item-gift-offer',
  templateUrl: './popup-item-gift-offer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggle', [
      state('collapse', style({ height: '0', overflow: 'hidden' })),
      state('expand', style({ height: '*', overflow: 'auto' })),
      transition('collapse => expand', animate('400ms ease-out')), // 第一个参数:动画时间, 第二个参数:动画延迟时间
      transition('expand => collapse', animate('400ms ease-in'))
    ]),
    trigger('lessOrMore', [
      state('less', style({ height: '0', overflow: 'hidden' })),
      state('more', style({ height: '*', overflow: 'hidden' })),
      transition('less => more', animate('400ms ease-out')),
      transition('more => less', animate('400ms ease-in'))
    ])
  ]
})
export class PopupItemGiftOfferComponent implements OnChanges {
  showState: string = 'less';
  toggleState: string;
  isSelected: boolean;
  @Input() freeGiftPromotion: FreeGiftPromotion;
  @Input() multiple: Boolean;
  @Input() activeCode: string;

  @Output()
  clickPromotion = new EventEmitter<any>();

  ngOnChanges(): void {
    this.isSelected = this.activeCode === this.freeGiftPromotion.code ? true : false;
    if (this.multiple) {
      this.toggleState = this.activeCode === this.freeGiftPromotion.code ? 'expand' : 'collapse';
    } else {
      this.toggleState = 'expand';
    }
  }

  onPromotionClick(): void {
    this.isSelected = !this.isSelected;
    if (this.multiple && this.isSelected) {
      this.toggleState = this.toggleState === 'collapse' ? 'expand' : 'collapse';
    } else {
      this.toggleState = 'expand';
    }

    if (this.isSelected) {
      this.clickPromotion.emit(this.freeGiftPromotion.code);
    } else {
      this.clickPromotion.emit('');
    }
  }

  onLessOrMoreClick(): void {
    if (this.showState === 'less') {
      this.showState = 'more';
    } else {
      this.showState = 'less';
    }
  }
}
