import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { ConsentTemplateResponse } from '../model/consent.model';

@Injectable({
  providedIn: 'root'
})
export class AmConsentConnector {
  protected subscription = new Subscription();
  constructor(
    protected occEndpointSvc: OccEndpointsService,
    protected userIdService: UserIdService,
    protected http: HttpClient
  ) {}

  getTemplates(): Observable<ConsentTemplateResponse> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    const url = this.occEndpointSvc.buildUrl('consenttemplates', {
      urlParams: { userId }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${userId}/consenttemplates`
    return this.http.get<ConsentTemplateResponse>(url);
  }
}
