import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { DeliveryAddressInfo } from '../components/model/deliveryAddressInfo';
import { StateWithAmCheckout } from '../store';

@Injectable({
  providedIn: 'root'
})
export class AmCheckoutConnector {
  observable: Observable<DeliveryAddressInfo>;
  loading = false;
  protected subscription = new Subscription();

  constructor(
    protected checkoutStore: Store<StateWithAmCheckout>,
    protected activeCartService: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected occEndpointSvc: OccEndpointsService,
    protected http: HttpClient
  ) {}

  queryDeliveryAndAddresses(): Observable<DeliveryAddressInfo> {
    if (this.loading && this.observable) {
      return this.observable;
    }
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    let cartId;
    this.activeCartService
      .getActiveCartId()
      .subscribe((activeCartId) => (cartId = activeCartId))
      .unsubscribe();
    const url = this.occEndpointSvc.buildUrl('deliveryAddress', {
      urlParams: { userId: userId, cartId: cartId }
    });
    this.loading = true;
    this.observable = this.http.get<DeliveryAddressInfo>(url).pipe(
      tap(() => {
        this.loading = false;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    return this.observable;
  }
}
