import { Component, OnInit } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CmsService, PageMetaService, WindowRef } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'am-page-title',
  templateUrl: './am-page-title.component.html'
})
export class AmPageTitleComponent implements OnInit {
  title$: Observable<string>;

  CAMPAIGN_URL_PREFIX = '/campaign';
  PAGE_NOT_FOUND = 'pagenotfound';

  constructor(
    protected pageMetaService: PageMetaService,
    protected cms: CmsService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected winRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.setTitle();
  }

  private setTitle(): void {
    this.title$ = this.cms.getCurrentPage().pipe(
      filter((page) => !!page),
      switchMap((page) => {
        if (!page || page?.properties?.titleToggle === 'false') {
          return of(null);
        }
        let pageTitle = page.title ?? '';
        let currentUrl = this.winRef.location?.href;
        // distinguish campagin page or non-campagin page
        if (currentUrl.indexOf(this.CAMPAIGN_URL_PREFIX) > 0) {
          // yes campagin page
          // check if it's inflight flow 1 or not found page
          if (!this.flightStatusStorageService.isInflightPickFlow || page.name.indexOf(this.PAGE_NOT_FOUND) != -1) {
            return of(pageTitle);
          } else {
            return this.removeSitename(pageTitle);
          }
        } else {
          return this.removeSitename(pageTitle);
        }
      })
    );
  }

  removeSitename(title: string) {
    let temp = title.split('|');
    if (temp.length >= 2) {
      temp.pop();
      return of(temp.join('|'));
    } else {
      return of(title);
    }
  }
}
