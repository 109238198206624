import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

const mapping_qualtricsLanguage = new Map([
  ['EN', 'EN'],
  ['ZH_TW', 'ZH-T'],
  ['ZH', 'ZH-S'],
  ['JA', 'JA'],
  ['KO', 'KO'],
]);

@Injectable({
  providedIn: 'root'
})
export class UpdateQtDataLayerService {
  window = (this.winRef.nativeWindow as any) ?? {};

  constructor(
    protected winRef: WindowRef,
  ) {
    if (!this.winRef.isBrowser()) {
      return;
    }
    // this.updateQualtricsLanguage = this.updateQualtricsLanguage.bind(this);
  }

  updateQualtricsLanguage(lang: string): void {
    this.window.qtDataLayer = {
      Q_Language: mapping_qualtricsLanguage.get(lang.toUpperCase())
    }
  }

}
