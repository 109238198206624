import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { InflightDiagnosticComponent } from './inflight-diagnostic.component';
import { InflightDiagnosticService } from './inflight-diagnostic.service';

@NgModule({
  declarations: [InflightDiagnosticComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        InflightDiagnosticComponent: {
          // typeCode
          component: InflightDiagnosticComponent
        }
      }
    } as CmsConfig)
  ],
  providers: [InflightDiagnosticService]
})
export class InflightDiagnosticModule {}
