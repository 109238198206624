/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartEntryConnector } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { normalizeHttpError, SiteContextActions, withdrawOn } from '@spartacus/core';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AmCartService } from '../../service/am-cart.service';
import { CartActions } from '../actions';

@Injectable()
export class CartEntryEffects {
  private contextChange$ = this.actions$.pipe(
    ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE)
  );

  addEntry$: Observable<CartActions.CartAddEntrySuccess | CartActions.CartAddEntryFail | CartActions.LoadCart> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(CartActions.CART_ADD_ENTRY),
        map((action: CartActions.CartAddEntry) => action.payload),
        concatMap((payload) => {
          return this.cartEntryConnector
            .add(payload.userId, payload.cartId, payload.productCode, payload.quantity)
            .pipe(
              map(
                (cartModification: CartModification) =>
                  new CartActions.CartAddEntrySuccess({
                    ...payload,
                    ...(cartModification as Required<CartModification>)
                  })
              ),
              catchError((error) =>
                from([
                  new CartActions.CartAddEntryFail({
                    ...payload,
                    error: normalizeHttpError(error)
                  }),
                  new CartActions.LoadCart({
                    cartId: payload.cartId,
                    userId: payload.userId
                  })
                ])
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );

  removeEntry$: Observable<
    CartActions.CartRemoveEntrySuccess | CartActions.CartRemoveEntryFail | CartActions.LoadCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.CART_REMOVE_ENTRY),
      map((action: CartActions.CartRemoveEntry) => action.payload),
      concatMap((payload) =>
        this.cartEntryConnector.remove(payload.userId, payload.cartId, payload.entryNumber).pipe(
          map((cart) => {
            this.amCartService.displayRemoveEntrySuccess();
            return new CartActions.CartRemoveEntrySuccess({
              ...payload,
              ...cart
            });
          }),
          catchError((error) =>
            from([
              new CartActions.CartRemoveEntryFail({
                ...payload,
                error: normalizeHttpError(error)
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId
              })
            ])
          )
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  updateEntry$: Observable<
    CartActions.CartUpdateEntrySuccess | CartActions.CartUpdateEntryFail | CartActions.LoadCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.CART_UPDATE_ENTRY),
      map((action: CartActions.CartUpdateEntry) => action.payload),
      concatMap((payload) =>
        this.cartEntryConnector.update(payload.userId, payload.cartId, payload.entryNumber, payload.quantity).pipe(
          map((cart) => {
            return new CartActions.CartUpdateEntrySuccess({
              ...payload,
              cart
            });
          }),
          catchError((error) =>
            from([
              new CartActions.CartUpdateEntryFail({
                ...payload,
                error: normalizeHttpError(error)
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId
              })
            ])
          )
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private cartEntryConnector: CartEntryConnector,
    private amCartService: AmCartService
  ) {}
}
