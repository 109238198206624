import { Component } from '@angular/core';
import { AmAsmComponentService } from '@core/asm/root/services/am-asm-component.service';
import { CustomerEmulationComponent } from '@spartacus/asm/components';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'am-customer-emulation',
  templateUrl: './am-customer-emulation.component.html',
  styleUrls: ['./am-customer-emulation.component.scss']
})
export class AmCustomerEmulationComponent extends CustomerEmulationComponent {
  constructor(protected asmComponentService: AmAsmComponentService, protected userAccountFacade: UserAccountFacade) {
    super(asmComponentService, userAccountFacade);
  }

  logoutCustomer(customerId?: string): void {
    this.asmComponentService.logoutCustomer(customerId);
  }
}
