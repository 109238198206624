import { Component, HostBinding, HostListener } from '@angular/core';
import { AmAsmAuthService } from '@core/asm/root/services/am-asm-auth.service';
import { AmAsmComponentService } from '@core/asm/root/services/am-asm-component.service';
import { AmAsmEnablerService } from '@core/asm/root/services/am-asm-enable.service';
import { AmCsAgentAuthService } from '@core/asm/root/services/am-csagent-auth.service';
import { AsmService } from '@spartacus/asm/core';
import { AsmUi } from '@spartacus/asm/root';
import {
  AuthStorageService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  User,
  WindowRef
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'am-asm-main-ui',
  templateUrl: './am-asm-main-ui.component.html'
})
export class AmAsmMainUiComponent {
  customerSupportAgentLoggedIn$: Observable<boolean>;
  csAgentTokenLoading$: Observable<boolean>;
  userAgent$: Observable<User | undefined>;
  customer$: Observable<User | undefined>;
  isCollapsed$: Observable<boolean>;
  bodyElement = null;

  @HostBinding('class.hidden') disabled = false;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    if (!this.bodyElement) {
      this.bodyElement = this.winRef.document?.getElementsByTagName('body')[0];
    }
    if (this.winRef.nativeWindow?.scrollY > 10) {
      this.bodyElement.classList.add('asm-scroll');
    } else {
      this.bodyElement.classList.remove('asm-scroll');
    }
  }

  protected startingCustomerSession = false;
  protected subscription: Subscription = new Subscription();
  constructor(
    protected authService: AmAsmAuthService,
    protected csAgentAuthService: AmCsAgentAuthService,
    protected userAccountFacade: UserAccountFacade,
    protected asmComponentService: AmAsmComponentService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected asmService: AsmService,
    protected amAsmEnableService: AmAsmEnablerService,
    protected authStorageService: AuthStorageService,
    protected winRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.customerSupportAgentLoggedIn$ = this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
    this.csAgentTokenLoading$ = this.csAgentAuthService.getCustomerSupportAgentTokenLoading();
    this.subscription.add(
      this.authStorageService.getToken().subscribe((token) => {
        if (!!token && !!token.access_token) {
          this.userAgent$ = this.asmComponentService.queryUserAgent();
        }
      })
    );
    this.customer$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          this.handleCustomerSessionStartRedirection();
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
    this.isCollapsed$ = this.asmService
      .getAsmUiState()
      .pipe(map((uiState: AsmUi) => (uiState.collapsed === undefined ? false : uiState.collapsed)));
  }

  protected handleCustomerSessionStartRedirection(): void {
    this.subscription.add(
      this.asmComponentService
        .isCustomerEmulationSessionInProgress()
        .pipe(take(1))
        .subscribe((isCustomerEmulated) => {
          if (this.startingCustomerSession && isCustomerEmulated) {
            this.startingCustomerSession = false;
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.routingService.go('/', { queryParams: { asm: true } });
          }
        })
    );
  }

  startCustomerEmulationSession({ customerId }: { customerId?: string }): void {
    if (customerId) {
      this.csAgentAuthService.startCustomerEmulationSession(customerId);
      this.startingCustomerSession = true;
    } else {
      this.globalMessageService.add({ key: 'asm.error.noCustomerId' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  logout(): void {
    this.authService.coreLogout();
  }

  closeAsmMode() {
    this.disabled = true;
    this.amAsmEnableService.removeAsmStoreStatus();
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
