import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { CartOfferDetailsDialogModule } from '../../cart-offer-details-dialog/cart-offer-details-dialog.module';
import { FlatRateDeliveryFeeDialogComponent } from './flat-rate-delivery-fee-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    SafeCodeModule,
    CartOfferDetailsDialogModule,
    SvgContainerModule,
    KeyboardFocusModule
  ],
  declarations: [FlatRateDeliveryFeeDialogComponent],
  exports: [FlatRateDeliveryFeeDialogComponent]
})
export class FlatRateDeliveryFeeDialogModule {}
