<ng-container>
  <div class="am-product-list-title" *ngIf="model$ | async as model">
    <div
      *ngIf="model.queryResult?.categoryNameForBanner && model?.queryResult?.bannerImageUrl; else titleWithoutBanner">
      <div *ngIf="model.queryResult?.isAlcoholCategory" class="alcohol-tip">
        <p>
          {{ 'amProductListDetail.alcoholEnTip' | cxTranslate }}
        </p>
        <p>
          {{ 'amProductListDetail.alcoholTcTip' | cxTranslate }}
        </p>
      </div>

      <div class="product-banner-withImg">
        <div class="product-banner-withImg__title">
          <h3>
            {{ model.queryResult?.categoryNameForBanner }}
          </h3>
        </div>
        <!-- [ngClass]="{ 'image-box-dummy': !isNotShowDummy }" -->
        <div class="product-banner-withImg__img">
          <div class="image-box">
            <am-media [container]="makeUpMediaContainer(model.queryResult?.bannerImageUrl)" alt=""></am-media>
            <!-- <img [src]="model.queryResult?.bannerImageUrl" /> -->
          </div>
        </div>
      </div>
    </div>

    <ng-template #titleWithoutBanner>
      <div class="product-banner-withIntro">
        <div class="product-banner-withIntro__title">
          <h3>{{ model.queryResult?.categoryName }}</h3>
        </div>
        <div *ngIf="model.queryResult?.isAlcoholCategory" class="alcohol-tip">
          <p>
            {{ 'amProductListDetail.alcoholEnTip' | cxTranslate }}
          </p>
          <p>
            {{ 'amProductListDetail.alcoholTcTip' | cxTranslate }}
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
