import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError, SiteContextActions, withdrawOn } from '@spartacus/core';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CartMileAdapter } from '../../occ/cart-mile.adapter';
import { CartActions } from '../actions';

@Injectable()
export class AmCartMilesEffects {
  private contextChange$ = this.actions$.pipe(
    ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE)
  );

  constructor(private actions$: Actions, private cartMileAdapter: CartMileAdapter) {}

  updateCartMiles$: Observable<
    | CartActions.UpdateCartMilesSuccess
    | CartActions.UpdateCartMilesFail
    | CartActions.LoadCart
    | CartActions.CartProcessesDecrement
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.UPDATE_CART_MILES),
      map((action: CartActions.UpdateCartMiles) => action.payload),
      concatMap((payload) => {
        return this.cartMileAdapter.update(payload.userId, payload.cartId, payload.miles).pipe(
          map((cart) => {
            return new CartActions.UpdateCartMilesSuccess({
              ...payload,
              ...cart
            });
          }),
          catchError((error) =>
            from([
              new CartActions.UpdateCartMilesFail({
                ...payload,
                error: normalizeHttpError(error)
              }),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId
              })
            ])
          )
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );
}
