import '@spartacus/checkout/base/root';

export enum CheckoutPageType {
  INFLIGHT = 'inflight',
  INFLIGHT_PICKUP = 'inglight_pickup',
  ONGROUND = 'onground',
  BOTH_SUPPORT = 'bothSupport'
}

declare module '@spartacus/checkout/base/root' {
  const enum CheckoutStepType {
    SHIPPING_ADDRESS = 'shippingAddress',
    VERIFY_PAYMENT = 'verifyPayment',
    INFLIGHT_PENDING_ORDER = 'verifyOnHold',
    INFLIGHT_PICKUP_ORDER = '',
    INFLIGHT_ORDER_VERIFY = 'verifyPayment',
    WAITINGPAYMENT = 'waitingPayment'
  }

  interface CheckoutStep {
    checkoutEnv?: CheckoutPageType;
    supportAsm?: Boolean;
    supportAsmDummyMember?: Boolean;
  }
}
