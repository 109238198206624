import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AmLoadingModule } from '@components/content/loading/loading.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { AmMediaModule } from '../media/media.module';
import { MediaPlayerComponent } from './media-player.component';
import { VimeoPlayerModule } from './vimeo-player/vimeo-player.module';
import { YoukuPlayerModule } from './youku-player/youku-player.module';
import { AmYoutubePlayerModule } from './youtube-player/youtube-player.module';

@NgModule({
  imports: [
    CommonModule,
    AmYoutubePlayerModule,
    AmMediaModule,
    // GenericLinkModule,
    I18nModule,
    UrlModule,
    // Html5VideoPlayerModule,
    // DailymotionPlayerModule,
    SafeCodeModule,
    // AmVimePlayerModule,
    VimeoPlayerModule,
    YoukuPlayerModule,
    AmLoadingModule
  ],
  declarations: [MediaPlayerComponent],
  exports: [MediaPlayerComponent]
})
export class MediaPlayerModule {
  static forRoot(): ModuleWithProviders<MediaPlayerModule> {
    return {
      ngModule: MediaPlayerModule
    };
  }
}
