import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SiteContextSelectorPopupComponent } from './site-context-selector-popup.component';

// export const siteContextDialogLayoutConfig: LayoutConfig = {
//   launch: {
//     SELECT_SITE_CONTEXT: {
//       inlineRoot: true,
//       component: SiteContextSelectorPopupComponent,
//       dialogType: DIALOG_TYPE.DIALOG
//     }
//   }
// };

@NgModule({
  declarations: [SiteContextSelectorPopupComponent],
  imports: [CommonModule, I18nModule, FormsModule, NgSelectModule, KeyboardFocusModule],
  exports: [SiteContextSelectorPopupComponent]
  // providers: [provideDefaultConfig(siteContextDialogLayoutConfig)]
})
export class SiteContextSelectionPopupModule {}
