import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { LanguageService, OccEndpointsService, RoutingService, UserIdService, WindowRef } from '@spartacus/core';
import UrlHelper from '@utils/url-helper';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {
  protected subscription = new Subscription();

  constructor(
    private occEndpointSvc: OccEndpointsService,
    protected userIdService: UserIdService,
    private http: HttpClient,
    private languageService: LanguageService,
    private router: RoutingService,
    private countryService: CountryService,
    protected winRef: WindowRef
  ) {}

  orderDetail: any = new Subject();
  orderDetail$: Observable<any> = this.orderDetail.asObservable();

  previousLanguage: any;
  previousCountry: any;

  getOrderDetail(): Observable<any> {
    let orderCode = '';

    this.router
      .getParams()
      .subscribe((occRoute) => (orderCode = occRoute.orderCode))
      .unsubscribe();
    // add for my-account/order/:orderCode/review
    orderCode = !!orderCode ? orderCode : UrlHelper.getOrderCodeFromUrl(this.winRef.location.href);
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();

    return combineLatest([this.languageService.getActive(), this.countryService.getActiveCountryEntity()]).pipe(
      filter(([language, country]) => {
        return !(language === this.previousLanguage && country?.isocode === this.previousCountry?.isocode);
      }),
      switchMap(([language, country]) => {
        this.previousCountry = country;
        this.previousLanguage = language;
        const url = this.occEndpointSvc.buildUrl('orderDetail', {
          urlParams: { userId, orderId: orderCode }
        });
        return this.http.get<any>(url).pipe(
          tap((val) => {
            this.orderDetail.next(val);
          })
        );
      })
    );
  }

  sendVoucher(orderNum, consigmentCode): Observable<any> {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    const header = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8'
    });
    const url = this.occEndpointSvc.buildUrl('sendVoucher', {
      urlParams: { userId, orderNum, consigmentCode }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${userId}/orders/${orderNum}/sendVoucher?code=${consigmentCode}`;
    return this.http.get(url, { headers: header, responseType: 'text' });
  }

  getFreeProductsStatus(orderData) {
    if (!orderData.entries || orderData.entries.length <= 0) {
      return false;
    }
    let isAllFreeProduct = true;
    orderData.entries?.forEach((entry) => {
      if (entry?.product?.isGiveaway !== true) {
        isAllFreeProduct = false;
      }
      return;
    });
    return isAllFreeProduct;
  }
}
