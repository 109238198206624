import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SliderToggleButtonModule } from '@components/content/common/slider-toggle-button/slider-toggle-button.module';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { PriceChangeDialogModule } from '@components/content/price-change-dialog/price-change-dialog.module';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule, PopoverModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { AmCartCouponModule } from '../cart-coupon/cart-coupon.module';
import { CartDeliverFeeModule } from '../cart-deliver-fee/cart-deliver-fee.module';
import { AmCartItemListModule } from '../cart-item-list/cart-item-list.module';
import { CartLoadingDialogModule } from '../cart-loading-dialog/cart-loading-dialog.module';
import { CartOfferDetailsDialogModule } from '../cart-offer-details-dialog/cart-offer-details-dialog.module';
import { AmPromotionCashDialogModule } from '../promotion-cash-dialog/promotion-cash-dialog.module';
import { AmCartTotalsComponent } from './cart-totals.component';
import { MilesEarnDialogModule } from './miles-earn-dialog/miles-earn-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SafeCodeModule,
    UrlModule,
    RouterModule,
    PopoverModule,
    IconModule,
    CartDeliverFeeModule,
    NgxSliderModule,
    ReactiveFormsModule,
    FormsModule,
    SliderToggleButtonModule,
    AmCartItemListModule,
    AmCartCouponModule,
    AmPromotionCashDialogModule,
    MilesEarnDialogModule,
    CartOfferDetailsDialogModule,
    KeyboardFocusModule,
    MessageTipModule,
    SvgContainerModule,
    PriceChangeDialogModule,
    CartLoadingDialogModule
  ],
  exports: [AmCartTotalsComponent],
  declarations: [AmCartTotalsComponent]
})
export class AmCartTotalsModule {}
