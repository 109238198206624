<div class="cx-modal-content">
  <div #dialog class="language-flight-dialog" id="language-flight-dialog" [ngSwitch]="currentStep">
    <div class="header-section">
      <div class="header-logo" [innerHtml]="getHeader() | safeHtml"></div>
      <div class="language-selection">
        <label>
          <ng-select
            [searchable]="false"
            [clearable]="false"
            [items]="languages"
            bindLabel="nativeName"
            bindValue="isocode"
            [(ngModel)]="selectedLanguage"
            (ngModelChange)="setActiveLanguage()">
          </ng-select>
        </label>
      </div>
    </div>
    <ng-container *ngSwitchCase="'welcome'">
      <div class="body-section">
        <div class="content-section">
          <h1 class="title">
            {{ 'languageAndFlightPopup.welcomeToInflightStore' | cxTranslate }}
          </h1>
          <div class="flight-select margin-top">
            <div class="form-group">
              <span [ngClass]="{ 'has-error': showErrorFlag }">CX</span>
              <label
                for="flight-number"
                [ngClass]="{
                  'floating-label-up': flightNumber || focused,
                  'floating-label': !flightNumber && !focused,
                  'error-msg': showErrorFlag
                }"
                >{{ 'languageAndFlightPopup.flightNumber' | cxTranslate }}</label
              >
              <input
                id="flight-number"
                class="form-field"
                [ngClass]="{ 'has-error': showErrorFlag }"
                type="text"
                maxlength="4"
                [(ngModel)]="flightNumber"
                (ngModelChange)="checkFlightNumber()"
                (focus)="inputFocused($event)"
                (blur)="inputBlured($event)" />
              <div class="tool-tip-btn">
                <button class="link" aria-label="Flight number" (click)="openTooltip()">
                  <am-svg-container [type]="svgType.TOOLTIPQUESTIONMARK"></am-svg-container>
                </button>
                <div class="error-icon" *ngIf="showErrorFlag">
                  <am-svg-container [type]="svgType.ICONWARINING1"></am-svg-container>
                </div>
              </div>
            </div>
            <div *ngIf="showErrorFlag" class="error-msg">{{ 'languageAndFlightPopup.errorMsg' | cxTranslate }}</div>
            <div class="tooltip-text" tabindex="0" role="text" *ngIf="openTooltipFlag">
              <div class="tip-question">{{ 'languageAndFlightPopup.tipQuestion' | cxTranslate }}</div>
              <div class="tip-answer">{{ 'languageAndFlightPopup.tipAnswer' | cxTranslate }}</div>
              <div
                class="border-up-triangle"
                [ngClass]="{ 'border-up-triangle-padding': showErrorFlag }"
                aria-hidden="true"></div>
            </div>
          </div>
          <div class="next-btn">
            <button
              aria-label="Next"
              class="btn button-primary font-bold"
              [ngClass]="{ 'btn-grey': !flightNumber || showErrorFlag }"
              (click)="next()"
              [disabled]="!flightNumber || showErrorFlag"
              cxModalReason="provide flight info to move to next step"
              cxModal="dismiss">
              {{ 'languageAndFlightPopup.next' | cxTranslate }}
            </button>
          </div>
        </div>
        <div class="image-section">
          <picture>
            <source media="(min-width: 1280px)" srcset="/assets/images/email/popup_image/popup-welcome@4x.png" />
            <source media="(min-width: 992px)" srcset="/assets/images/email/popup_image/popup-welcome@3x.png" />
            <source media="(min-width: 600px)" srcset="/assets/images/email/popup_image/popup-welcome@2x.jpg" />
            <img src="/assets/images/email/popup_image/popup-welcome.jpg" alt="Onboard shopping picture" />
          </picture>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'detail'">
      <div class="body-section">
        <div class="content-section">
          <a autofocus class="back-backcramb" href="javascript:void(0)" (click)="back()">{{
            'languageAndFlightPopup.back' | cxTranslate
          }}</a>
          <ng-container *ngIf="isInflightFlow1; else flow2Flag">
            <h1 class="title">{{ welcomeOnboardDisplay }}</h1>
            <div class="flight-info">{{ flightInfoDisplay }}</div>
            <div class="description">{{ 'languageAndFlightPopup.flow1Desctiption' | cxTranslate }}</div>
          </ng-container>
          <ng-template #flow2Flag>
            <h1 class="title">{{ 'languageAndFlightPopup.welcomeInCathay' | cxTranslate }}</h1>
            <div class="description">{{ 'languageAndFlightPopup.flow2Desctiption' | cxTranslate }}</div>
          </ng-template>
          <div class="delivery-select">
            <span class="car-icon"><am-svg-container [type]="svgType.NEWCAR"></am-svg-container></span>
            <label class="floating-label-up"> {{ 'languageAndFlightPopup.deliveryLocation' | cxTranslate }} </label>
            <ng-select
              [searchable]="false"
              [clearable]="false"
              [items]="countries"
              [(ngModel)]="selectedCountry"
              bindValue="isocode"
              bindLabel="displayName">
              <ng-template ng-option-tmp let-item="item">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
          <a class="how-to-shop-tip" href="javascript:void(0)" (click)="openShoppingTipModal()"
            >{{ 'languageAndFlightPopup.shopTip' | cxTranslate }} ></a
          >
          <div class="start-shopping-btn">
            <button
              aria-label="start shopping"
              class="btn button-primary remove-button font-bold"
              (click)="startShoping()"
              cxModalReason="start shopping"
              cxModal="dismiss">
              {{ 'languageAndFlightPopup.startShopping' | cxTranslate }}
            </button>
          </div>
        </div>
        <ng-container *ngIf="isInflightFlow1; else flow2FlagImage">
          <div class="image-section">
            <picture>
              <source
                media="(min-width: 1280px)"
                srcset="/assets/images/email/popup_image/popup-delivery-onboard@4x.png" />
              <source
                media="(min-width: 992px)"
                srcset="/assets/images/email/popup_image/popup-delivery-onboard@3x.png" />
              <source
                media="(min-width: 600px)"
                srcset="/assets/images/email/popup_image/popup-delivery-onboard@2x.jpg" />
              <img src="/assets/images/email/popup_image/popup-delivery-onboard.jpg" alt="delivery onboard picture" />
            </picture>
          </div>
        </ng-container>
        <ng-template #flow2FlagImage>
          <div class="image-section">
            <picture>
              <source
                media="(min-width: 1280px)"
                srcset="/assets/images/email/popup_image/popup-delivery-indoor@4x.png" />
              <source
                media="(min-width: 992px)"
                srcset="/assets/images/email/popup_image/popup-delivery-indoor@3x.png" />
              <source
                media="(min-width: 600px)"
                srcset="/assets/images/email/popup_image/popup-delivery-indoor@2x.jpg" />
              <img src="/assets/images/email/popup_image/popup-delivery-indoor.jpg" alt="delivery indoor picture" />
            </picture>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <div class="footer-section" [innerHtml]="getFooter() | safeHtml"></div>
  </div>
</div>
