import { ImageLoadingStrategy, MediaConfig } from '@spartacus/storefront';

export const amMediaConfig: MediaConfig = {
  mediaFormats: {
    // banner formats
    mobile: { width: 768 },
    tablet: { width: 992 },
    desktop: { width: 1280 },
    widescreen: { width: 1400 },
    // product formats
    cartIcon: { width: 65 },
    thumbnail: { width: 96 },
    product: { width: 284 },
    zoom: { width: 515 }
  },
  imageLoadingStrategy: ImageLoadingStrategy.LAZY
};
