import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ProductPriceModule } from 'src/app/amiredeem/components/content/common/product-price/product-price.module';
import { SwiperModule } from 'swiper/angular';
import { AmMediaModule } from '../common/media/media.module';
import { AmlMerchandisingCarouselComponent } from './aml-merchandising-carousel.component';

@NgModule({
  declarations: [AmlMerchandisingCarouselComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    RouterModule,
    SwiperModule,
    ProductPriceModule,
    AmMediaModule,
    ProductPriceModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AmlMerchandisingCarouselComponent: {
          // typeCode
          component: AmlMerchandisingCarouselComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [AmlMerchandisingCarouselComponent],
  entryComponents: [AmlMerchandisingCarouselComponent]
})
export class AmlHomeMerchandisingCarouselComponentModule {}
