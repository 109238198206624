/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { SiteContextConfig, StatePersistenceService } from '@spartacus/core';
import { Observable, ReplaySubject } from 'rxjs';
import { COUNTRY_CONTEXT_ID } from '../../providers/am-context-ids';
import { CountryService } from './country.service';

@Injectable({ providedIn: 'root' })
export class CountryStatePersistenceService {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected countryService: CountryService,
    protected config: SiteContextConfig
  ) {}

  protected initialized$ = new ReplaySubject<unknown>(1);

  public initSync(): Observable<unknown> {
    this.statePersistenceService.syncWithStorage({
      key: COUNTRY_CONTEXT_ID,
      state$: this.countryService.getActive(),
      onRead: (state) => this.onRead(state)
    });
    return this.initialized$;
  }

  protected onRead(valueFromStorage: string | undefined): void {
    if (!this.countryService.isInitialized() && valueFromStorage) {
      this.countryService.setActive(valueFromStorage);
    }

    if (!this.initialized$.closed) {
      this.initialized$.next();
      this.initialized$.complete();
    }
  }
}
