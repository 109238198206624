import { Injectable } from '@angular/core';
import { EventService, RoutingService, SearchboxService, TranslationService, WindowRef } from '@spartacus/core';
import { SearchBoxComponentService, SearchBoxConfig } from '@spartacus/storefront';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmSearchResults } from '@components/content/mulit-search-box/search-box/am-search-box.model';

@Injectable({
  providedIn: 'root',
})
export class AmSearchBoxComponentService extends SearchBoxComponentService {
  constructor(
    public searchService: SearchboxService,
    protected routingService: RoutingService,
    protected translationService: TranslationService,
    protected winRef: WindowRef,
    protected eventService: EventService) {
    super(searchService, routingService, translationService, winRef, eventService);
  }

  getResults(config: SearchBoxConfig): Observable<AmSearchResults> {
    return combineLatest([
      super.getResults(config),
      this.getProductResults(config),
    ]).pipe(
      map(([results, productResults]) => {
        const { suggestProducts } = productResults?.queryResult as any ?? {};
        return {
          ...results,
          totalResults: productResults?.pagination?.totalResults,
          suggestProducts: suggestProducts ?? [],
        }
      })
    );
  }

}