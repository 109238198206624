import { Injectable } from '@angular/core';
import { AmConsentService } from '@core/user/consents/services/am-consents.service';
import { CdsConfig } from '@spartacus/cds';
import { LanguageService, OccEndpointsService, UserIdService, WindowRef } from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { ThirdPartyLibService } from '@utils/thirdPartyLibLoader/third-party-lib.service';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { GlassBoxScriptsService } from 'src/app/amiredeem/common/glassbox/glassbox-scripts.service';

export interface CookieBarOptions {
  locale: string;
  checkLocation: boolean;
  onStatusChanged?: Function;
  onInitialized?: Function;
  footerFix?: { method: string };
}

@Injectable({
  providedIn: 'root'
})
export class CookieBarService {
  constructor(
    protected languageService: LanguageService,
    protected userIdService: UserIdService,
    protected occEndpointSvc: OccEndpointsService,
    protected amConsentService: AmConsentService,
    protected cdsConfig: CdsConfig,
    protected winRef: WindowRef,
    protected thirdPartyLibService: ThirdPartyLibService,
    protected amConfigService: AmConfigService,
    protected glassBoxScriptsService: GlassBoxScriptsService
  ) {
    this.updateCookieBarLanguage = this.updateCookieBarLanguage.bind(this);
    this.initCookieBar = this.initCookieBar.bind(this);
  }

  private window = this.winRef.nativeWindow as any;
  protected subscription = new Subscription();
  addCookieBarLib(): void {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.subscription.add(
      this.amConfigService.getCommonConfig().subscribe((data) => {
        if (!data) {
          return;
        }
        let { trackingEnv } = data;
        trackingEnv = trackingEnv?.toLocaleLowerCase();
        const configFolds: Array<String> = ['prod', 't0', 't1', 't2', 't3'];
        if (!!trackingEnv && configFolds.indexOf(trackingEnv) > -1) {
          trackingEnv = trackingEnv === 'prod' ? 'p0' : trackingEnv;
        } else {
          trackingEnv = 'p0';
        }
        const scriptSource = `./assets/lib/cookiebar/${trackingEnv}/cookie-consent.min.js`;
        const styleLink = `./assets/lib/cookiebar/${trackingEnv}/cookie-consent.min.css`;
        this.thirdPartyLibService.embedScript({
          src: scriptSource,
          callback: () => {
            this.initCookieBar();
          }
        });
        // this.thirdPartyLibService.addScript(scriptSource, this.initCookieBar);
        this.thirdPartyLibService.addStyleLink(styleLink);
      })
    );
  }

  initCookieBar(): void {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.subscription.add(
      combineLatest([this.amConfigService.getCommonConfig(), this.languageService.getActive()])
        .pipe(take(1))
        .subscribe(([amConfig, activeLanguage]) => {
          if (!!activeLanguage) {
            if (typeof this.window?.amlcc?.initialize === 'function') {
              // if have accept the consent in cookie bar
              if (this.window.amlcc.isAllowed) {
                this.glassBoxScriptsService.addLib(amConfig, true);
                this.amConsentService.giveCdsConsent();
              } else {
                // if haven't accept the consent in cookie bar
                const language = this.transformLanguage(activeLanguage);
                // init params
                let cookieBarOptions: CookieBarOptions = {
                  locale: language,
                  checkLocation: false,
                  footerFix: { method: 'none' }
                };
                // the callback function when the cookie bar accpet or has been inited
                const callback = (isAccept?: boolean) => {
                  this.glassBoxScriptsService.addLib(amConfig, isAccept);
                  if (isAccept) {
                    this.amConsentService.giveCdsConsent();
                  }
                };
                cookieBarOptions = {
                  ...cookieBarOptions,
                  onStatusChanged: callback,
                  onInitialized: callback
                };
                !!this.window.amlcc && this.window.amlcc.initialize(cookieBarOptions);
              }
            }
          }
        })
    );
  }

  // when user select the language/country in siteContext component,
  // need to update the cookie bar.
  updateCookieBarFunction = (() => {
    if (this.amConsentService.hasAcceptedInCookieBar()) {
      return () => {};
    }
    return this.updateCookieBarLanguage;
  })();

  deleteAmlCookies(): void {
    const amlCookiesKey = ['aml_cc_advertising', 'aml_cc_functionality', 'aml_cc_necessary', 'aml_cc_performance'];
    amlCookiesKey.forEach((cookieKey) => {
      CookiesHelper.deleteCookie(cookieKey, '/', '.asiamiles.com');
    });
  }

  updateCookieBarLanguage(activeLanguage): void {
    if (typeof (window as any).amlcc?.setLanguage === 'function') {
      const language = this.transformLanguage(activeLanguage);
      !!(window as any).amlcc &&
        (window as any).amlcc.setLanguage({
          locale: language,
          checkLocation: false
        });
    }
  }

  transformLanguage(activeLanguage): string {
    if (activeLanguage === 'zh') {
      activeLanguage = 'sc';
    } else if (activeLanguage === 'zh_TW') {
      activeLanguage = 'zh';
    }
    return activeLanguage;
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
