import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { FlightInfoService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { SiteContextType } from '@config/am-constant.config';
import { AmAsmEnablerService } from '@core/asm/root/services/am-asm-enable.service';
import { CheckoutPreCheckMsgCode } from '@model/cart.enum';
import { CollectionMethod } from '@model/collection-method.enum';
import { CartConfigService, SelectiveCartService } from '@spartacus/cart/base/core';
import { Cart, PromotionLocation, PromotionOrderEntryConsumed, PromotionResult } from '@spartacus/cart/base/root';
import { AuthService, RoutingService, User, WindowRef } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, LAUNCH_CALLER, SiteContextComponentService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AmActiveCartService } from 'src/app/amiredeem/pages/cart/service/active-cart.service';
import { svgConfig } from '../../../../config/am-svg-sprite.config';
import { AmCartService } from '../../service/am-cart.service';

@Component({
  selector: 'am-cart-totals',
  templateUrl: './cart-totals.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class AmCartTotalsComponent implements OnInit, AfterViewInit {
  svgType = svgConfig;
  style_info_link = 'width: 16px; height: 16px; margin-bottom: 5px';
  style_cash_mile_off = 'width: 24px; height: 24px;';
  style_cathay_cart = 'width: 24px; height: 24px; margin-bottom: 2px';
  style_asia_mile_grey = 'width: 12px; height: 12px; margin: 0 0 3px 5px ';

  inFlightPickUpError = false;
  isBrowser: boolean = false;

  @Input() isStoreOpenStatus: boolean | null;
  @Input() isDelistedOrOffline: boolean | null;
  @Input() activeTab: string;
  totalAmount: number = 0;

  @ViewChild('checkoutForm') checkoutForm;
  cart$: Observable<Cart> = this.activeCartService.getActive().pipe(
    tap((cart: Cart) => {
      this.isErrorMinMile = false;
      this.maxValue = cart.maxMiles;
      this.mileValue = cart.selectionMiles;
      this.sliderMoved = cart.selectionMiles > cart.minMiles ? true : false;
      this.hasCartPromoRules =
        cart.appliedAmlOrderPromotions?.length || cart.appliedCashPromotions?.length ? true : false;
      this.cashAfterDiscount = 0;
      this.milesAfterDiscount = 0;
      let subtotal = 0;
      this.totalAmount = 0;
      this.inFlightPickUpError = cart.entries?.some((val) => {
        return (
          // (val.product.collectionMethod !== CollectionMethod.INFLIGHT_PICKUP &&
          //   this.flightStatusStorageService.isInflightPickFlow) ||
          val.product.collectionMethod === CollectionMethod.INFLIGHT_PICKUP &&
          !this.flightStatusStorageService.isInflightPickFlow
        );
      });
      cart.entries
        ?.filter((e) => e.selected)
        .forEach((entry) => {
          if (entry.product?.paymentMethod === 'FullCashOrMpcWithMinMiles') {
            if (!cart.fcomToggle) {
              if (entry.product?.discountFullCashPrice) {
                this.cashAfterDiscount =
                  this.cashAfterDiscount + Number(entry.product.discountFullCashPrice) * entry.quantity;
              } else {
                this.cashAfterDiscount =
                  this.cashAfterDiscount + Number(entry.product.originFullCashPrice) * entry.quantity;
              }
            } else {
              this.cashAfterDiscount =
                this.cashAfterDiscount + Number(entry.product.mpcDiscountFullCashPrice) * entry.quantity;
              this.milesAfterDiscount = this.milesAfterDiscount + entry.product.minimumMilesSpend * entry.quantity;
            }
          } else {
            if (entry.product.paymentMethod !== 'MilesOnly') {
              if (entry.product?.discountFullCashPrice) {
                this.cashAfterDiscount =
                  this.cashAfterDiscount + Number(entry.product.discountFullCashPrice) * entry.quantity;
              } else {
                this.cashAfterDiscount =
                  this.cashAfterDiscount + Number(entry.product.originFullCashPrice) * entry.quantity;
              }
              this.milesAfterDiscount = this.milesAfterDiscount + entry.product.minimumMilesSpend * entry.quantity;
            } else {
              if (entry.product.promotionPrice?.value) {
                this.milesAfterDiscount = this.milesAfterDiscount + entry.product.promotionPrice.value * entry.quantity;
              } else {
                this.milesAfterDiscount = this.milesAfterDiscount + entry.product.price.value * entry.quantity;
              }
            }
          }
          this.totalAmount += entry.quantity;
        });
      cart.appliedProductPromotions?.forEach((promotion) => {
        let sum = 0;
        if (!promotion.bonusMiles && !promotion.orderPromo && promotion.consumedEntries) {
          sum = sum + this.totalDiscountUnitPrice(promotion.consumedEntries);
          promotion.consumedEntries.forEach((consumed) => {
            cart.entries?.forEach((entryItem) => {
              if (
                entryItem.product.paymentMethod === 'MilesOnly' &&
                entryItem.entryNumber === consumed.orderEntryNumber
              ) {
                subtotal = subtotal + (consumed.discountUnitPrice || 0);
              }
            });
          });
        }
        if (sum > 0) {
          this.applyProductDiscountRule = true;
        }
      });
      if (
        (this.applyProductDiscountRule && this.sliderMoved) ||
        (this.applyProductDiscountRule && this.hasCartPromoRules)
      ) {
        this.milesAfterDiscount = this.milesAfterDiscount - subtotal < 0 ? 0 : this.milesAfterDiscount - subtotal;
      }
      if ((cart.containMPC && !cart.containFullCashOrMPC) || (cart.containFullCashOrMPC && cart.fcomToggle)) {
        this.showSlider = true;
      } else {
        this.showSlider = false;
      }
    })
  );
  @Output() isStoreOpenStatusOff: EventEmitter<boolean> = new EventEmitter<boolean>();

  cartLoaded$: Observable<boolean>;
  cartIsLoading$ = this.activeCartService.isStable().pipe(
    map((loaded) => {
      this.isLoading = !loaded;
      return !loaded;
    })
  );
  isLoading: boolean;
  currencyContext = SiteContextType.CURRENCY;
  // currency$ = this.siteContextService.getActiveItem(this.currencyContext);

  mileValue: number = 0;
  maxValue: number = 0;
  sliderMoved: boolean = false;
  hasCartPromoRules: boolean = false;
  cashAfterDiscount: number = 0;
  milesAfterDiscount: number = 0;
  applyProductDiscountRule: boolean = false;
  showSlider: boolean = false;
  termCondition = false;
  isSmallScreen$ = this.breakpointService.isDown(BREAKPOINT.md);
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;
  loggedIn: boolean;
  selectiveCartEnabled: boolean;
  isErrorMinMile = false;
  // isFlightPickUp: boolean;
  isNonAsmStoreAssistant$: Observable<boolean>;
  isNonAsmStoreAssistant: boolean;
  disabledButton: boolean;
  protected subscription = new Subscription();
  constructor(
    protected activeCartService: AmActiveCartService,
    protected amCartService: AmCartService,
    protected launchDialogService: AmLaunchDialogService,
    protected authService: AuthService,
    protected selectiveCartService: SelectiveCartService,
    protected breakpointService: BreakpointService,
    protected routingService: RoutingService,
    protected userAccountFacade: UserAccountFacade,
    protected winRef: WindowRef,
    protected flightInfoService: FlightInfoService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected amAsmEnablerService: AmAsmEnablerService,
    private siteContextService: SiteContextComponentService,
    protected cartConfig: CartConfigService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.getUserStatus();
  }

  ngOnInit(): void {
    this.selectiveCartEnabled = this.cartConfig.isSelectiveCartEnabled();
    this.cartLoaded$ = combineLatest([
      this.activeCartService.isStable(),
      this.selectiveCartEnabled ? this.activeCartService.isStable() : of(false),
      this.authService.isUserLoggedIn()
    ]).pipe(
      tap(([, , loggedIn]) => (this.loggedIn = loggedIn)),
      map(([cartLoaded, sflLoaded, loggedIn]) =>
        loggedIn && this.selectiveCartEnabled ? cartLoaded && sflLoaded : cartLoaded
      )
    );
    // this.isFlightPickUp = this.flightStatusStorageService.isInflightPickFlow;
  }

  totalDiscountUnitPrice(data: PromotionOrderEntryConsumed[]): number {
    let sum = 0;
    data?.forEach((e) => (sum += e.discountUnitPrice || 0));
    return sum;
  }
  update(cart: Cart, value?): void {
    if (value?.target) {
      if (Number(value.target.value) > cart.maxMiles) {
        this.mileValue = cart.maxMiles;
      } else if (Number(value.target.value) < cart.minMiles) {
        this.mileValue = cart.minMiles;
        this.isErrorMinMile = true;
      } else {
        this.mileValue = Number(value.target.value);
      }
      value.target.value = this.mileValue;
    }
    this.amCartService.updateMiles(this.mileValue);
  }
  toggleDeliveryFee(e: boolean): void {
    this.amCartService.toggleDeliveryFee(e);
  }

  toggleFcom(e: boolean): void {
    this.amCartService.toggleFcom(e);
  }

  openPromotionCashModal(item: PromotionResult, name: string): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_CASH_PROMOTION, undefined, undefined, {
      promotion: item?.promotion,
      promoPopName: name
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  openOfferDetailsModal(item: PromotionResult): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_CART_OFFER_DETAIL, undefined, undefined, {
      promotion: item
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  openMilesEarnDialog(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_EARN_MILES, undefined, undefined);
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  openLoadingDialog(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.CART_LOADING_DIALOG, undefined, undefined, {
      activeTab: this.activeTab
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  goToCheckout(cart) {
    if (!this.isLoading && this.termCondition && !cart.errorMegFlag) {
      if (this.flightStatusStorageService.isInflightPickFlow) {
        this.openLoadingDialog();
      }
      this.disabledButton = true;
      this.subscription.add(
        this.flightInfoService.getPointOfServiceStatus().subscribe(async (d) => {
          this.flightStatusStorageService.inflightSalesChannel = d?.inflightSalesChannel;
          this.flightStatusStorageService.updateInflightSalesChannel(d?.inflightSalesChannel);
          if (!this.flightStatusStorageService.isInflightPickFlow && cart.deliveryMethod === 'inflight') {
            // this.launchDialogService.closeDialog('close cart loading dialog', LAUNCH_CALLER.CART_LOADING_DIALOG);
            this.winRef.location.reload();
          }
          if (
            (d && d.isStoreOpen) ||
            d === null ||
            this.activeTab === 'ground' ||
            (d && d.inflightSalesChannel.indexOf('Online') < 0)
          ) {
            this.subscription.add(
              this.amCartService.validateCart().subscribe((obj) => {
                // if (this.flightStatusStorageService.isInflightPickFlow) {
                // setTimeout(() => {
                this.launchDialogService.closeDialog('close cart loading dialog', LAUNCH_CALLER.CART_LOADING_DIALOG);
                // }, 1000);
                // }
                this.disabledButton = false;
                if (obj.msgCode === CheckoutPreCheckMsgCode.MOBILE_VERIFY_REDIRECT) {
                  this.winRef.location.href = obj.verifyMobileUrl;
                } else if (obj.nextStep === 'PAYMENT_COMFIRM' && !!obj.code) {
                  this.routingService.go({
                    cxRoute: 'waitingPayment',
                    params: {
                      orderCode: obj.code
                    }
                  });
                } else if (obj.msgCode === CheckoutPreCheckMsgCode.SUCCESS) {
                  this.routingService.go({
                    cxRoute: 'checkoutShippingAddress'
                  });
                } else if (obj.msgCode !== CheckoutPreCheckMsgCode.SUCCESS && obj.msgCode !== 'PRICE_CHANGE') {
                  this.activeCartService.reloadActiveCart();
                  // return this.router.parseUrl(this.semanticPathService.get('cart'));
                } else if (obj.msgCode === 'PRICE_CHANGE') {
                  this.openPriceChangeDialog();
                }
              })
            );
          } else {
            this.disabledButton = false;
            this.isStoreOpenStatusOff.emit(false);
          }
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.winRef.isBrowser() && setTimeout(() => this.footerStyle(), 300);
  }

  footerStyle(): void {
    this.subscription.add(
      this.breakpointService.isDown(BREAKPOINT.md).subscribe((d) => {
        const offsetHeight = this.checkoutForm?.nativeElement?.clientHeight;
        const footer = this.winRef.document?.querySelector('cx-page-slot.Footer') as any;
        if (!d) {
          footer?.removeAttribute('style');
        } else {
          if (offsetHeight > 0) {
            footer.style.marginBottom = offsetHeight + 'px';
          }
        }
      })
    );
  }

  protected getUserStatus() {
    if (this.amAsmEnablerService.isEnabled()) {
      this.isNonAsmStoreAssistant$ = of(false);
      this.isNonAsmStoreAssistant = false;
      return;
    }

    this.userAccountFacade.get().pipe();
    this.isNonAsmStoreAssistant$ = this.userAccountFacade.get().pipe(
      map((data: User) => {
        this.isNonAsmStoreAssistant = data.isStoreAssistant;
        return data.isStoreAssistant;
      })
    );
  }

  openPriceChangeDialog(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.PRICE_CHANGE_REMINDER, undefined, undefined);
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }
  ngOnDestroy() {
    this.disabledButton = false;
    const footer = this.winRef.document?.querySelector('cx-page-slot.Footer') as any;
    footer.removeAttribute('style');
    this.subscription?.unsubscribe();
  }
}
