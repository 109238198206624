import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { environment } from '@env/environment';
import { Airports, AirportsList } from '@model/airport.model';
import { select, Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  Country,
  LanguageService,
  OccEndpointsService,
  RoutingService,
  Title,
  UserIdService,
  WindowRef
} from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { configDeliveryMethodCodes } from '../components/constants';
import {
  DeliveryAddressFormData,
  DeliveryAddressInfo,
  LockerData,
  PhysicalDeliveryInfo,
  SubmitAddressResponse
} from '../components/model/deliveryAddressInfo';
import { AmCheckoutActions, DeliveryAddressesSelector, StateWithAmCheckout } from '../store';

export interface UserCartId {
  userId: string;
  cartId: string;
}
export interface operateMap {
  operateName: string;
  addressCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class AmCheckoutDeliveryService {
  cartId: string = '';
  userId: string = '';
  baseUrl: string;
  items: Array<string> = [];
  titles: Array<Title> = [];

  private operateAddressSub: BehaviorSubject<operateMap> = new BehaviorSubject<operateMap>(null);
  public operateAddressObs: Observable<operateMap> = this.operateAddressSub.asObservable();

  private selectActiveAddressSub: Subject<string> = new Subject<string>();
  public selectActiveAddressObs: Observable<string> = this.selectActiveAddressSub.asObservable();

  private updateSubmitBtnStatusSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public submitBtnStatusObs: Observable<boolean> = this.updateSubmitBtnStatusSub.asObservable();

  private verifyMaxOtpTimeError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public verifyMaxOtpTimeErrorObs: Observable<boolean> = this.verifyMaxOtpTimeError.asObservable();

  public displayModeSub$: Subject<string> = new Subject<string>();

  public preFormData$: BehaviorSubject<DeliveryAddressFormData> = new BehaviorSubject<DeliveryAddressFormData>(null);

  public activePhysicalAddress$: BehaviorSubject<PhysicalDeliveryInfo> = new BehaviorSubject<PhysicalDeliveryInfo>(
    null
  );
  protected subscription = new Subscription();

  constructor(
    protected activeCartService: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected occEndpointSvc: OccEndpointsService,
    protected countryService: CountryService,
    protected http: HttpClient,
    protected routingService: RoutingService,
    protected store: Store<StateWithAmCheckout>,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected languageService: LanguageService,
    protected winRef: WindowRef
  ) {}

  initQueryParams() {
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (this.userId = occUserId))
      .unsubscribe();

    this.activeCartService
      .getActiveCartId()
      .subscribe((activeCartId) => (this.cartId = activeCartId))
      .unsubscribe();

    this.baseUrl = this.occEndpointSvc.getBaseUrl();
  }

  getAndLoadDeliveryAndAddressInfo(skipReload?: boolean) {
    return this.store.pipe(
      select(DeliveryAddressesSelector.getDeliveryAndAddressInfo),
      tap((deliveryAddressInfo) => {
        if (!deliveryAddressInfo && skipReload !== true) {
          this.store.dispatch(new AmCheckoutActions.LoadDeliveryAddresses());
        }
      }),
      filter((deliveryAddressInfo) => Boolean(deliveryAddressInfo))
    );
  }

  queryDeliveryAddressInfo() {
    return this.store.pipe(select(DeliveryAddressesSelector.getDeliveryAndAddressInfo));
  }

  setActiveAddressCode(addressCode) {
    this.selectActiveAddressSub.next(addressCode);
  }

  addAddressItem(address: DeliveryAddressFormData) {
    const url = this.occEndpointSvc.buildUrl('addOneSelfAddres', {
      urlParams: { userId: this.userId, cartId: this.cartId }
    });
    // const url = this.baseUrl + `/users/${this.userId}/carts/${this.cartId}/checkout/addOneSelfAddres`
    this.http
      .post(url, address)
      .toPromise()
      .then(() => {
        this.reloadDeliveryAddress();
      })
      .catch((err) => {
        console.log('add address fail', err);
      });
  }

  editAddressItem(addressCode: string, address: DeliveryAddressFormData): void {
    const url = this.occEndpointSvc.buildUrl('editOneSelfAddress', {
      urlParams: { userId: this.userId, cartId: this.cartId, addressCode }
    });
    // const url = this.baseUrl + `/users/${this.userId}/carts/${this.cartId}/checkout/editOneSelfAddress?addressCode=${addressCode}`
    this.http
      .post(url, address)
      .toPromise()
      .then(() => {
        this.reloadDeliveryAddress();
      })
      .catch((err) => {
        console.log('add address fail', err);
      });
  }

  removeDeliveryAddress(addressCode: string) {
    const url = this.occEndpointSvc.buildUrl('removeOneSelfAddres', {
      urlParams: { userId: this.userId, cartId: this.cartId, addressCode }
    });
    // const url = this.baseUrl + `/users/${this.userId}/carts/${this.cartId}/checkout/removeOneSelfAddres?addressCode=${addressCode}`
    this.subscription.add(
      this.http.get<void>(url).subscribe(
        () => {
          this.reloadDeliveryAddress();
        },
        (error) => {
          console.log('remove address item fail', error);
        }
      )
    );
  }

  submitDeliveryAddress(address) {
    address.cashPaymentBackUrl = this.winRef.location.href;
    const url = this.occEndpointSvc.buildUrl('deliveryAddress', {
      urlParams: { userId: this.userId, cartId: this.cartId }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${this.userId}/carts/${this.cartId}/checkout/deliveryAddress`
    return this.http.post<SubmitAddressResponse>(url, address);
  }

  reloadDeliveryAddress() {
    this.store.dispatch(new AmCheckoutActions.LoadDeliveryAddresses());
  }

  fetchTitles(): Observable<Array<Title>> {
    const url = this.occEndpointSvc.buildUrl('titleList');
    if (!this.titles || this.titles?.length <= 0) {
      return this.http.get<Array<Title>>(url).pipe(
        map((data) => {
          this.titles = data;
          return data;
        })
      );
    }
    return of(this.titles);
  }

  fetchDistricts(deliveryMethodCode: string): Observable<Object> {
    const url = this.occEndpointSvc.buildUrl('getDistrict', {
      urlParams: { userId: this.userId, cartId: this.cartId, deliveryMethodCode }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${this.userId}/carts/${this.cartId}/checkout/getDistrict?deliveryMethod=${deliveryMethodCode}`
    return this.http.get<Object>(url);
  }

  fetchLockers(deliveryMethodCode: string, logisticCompanyCode: string): Observable<LockerData> {
    const url = this.occEndpointSvc.buildUrl('getAddressOfLockersOrStores', {
      urlParams: { userId: this.userId, cartId: this.cartId, deliveryMethodCode, logisticCompanyCode }
    });
    // const url = this.occEndpointSvc.getBaseUrl() + `/users/${this.userId}/carts/${this.cartId}/checkout/getAddressOfLockersOrStores?deliveryMethod=${deliveryMethodCode}&district=${logisticCompanyCode}`
    return this.http.get<LockerData>(url);
  }

  getPrefillDataFromDeliveryInfo(deliveryAddressInfo: DeliveryAddressInfo, countryEntity: Country) {
    const { phoneCode, isocode, name } = countryEntity;
    const {
      deliveryInfo: {
        recipientInfo: { title, familyName, givenName, phoneNo, countryCode } = {},
        baseInfo: { emailAddress } = {},
        recipientInfo: { enAddress } = {}
      } = {}
    } = deliveryAddressInfo;

    const prefillFormData = {
      selectedTitle: title,
      familyName,
      givenName,
      recipientCountryCode: isocode === 'HK' ? phoneCode : countryCode,
      countryCode: isocode,
      country: name,
      enAddress1: enAddress,
      deliveryMethodCode: configDeliveryMethodCodes[0],
      // if the selected phoneCode equal to the default phoneCode of use, the the
      // phoneNo can be prefilled
      recipientPhoneNo: isocode === 'HK' && countryCode !== phoneCode ? '' : phoneNo,
      emailAddress,
      pointOfService: this.flightStatusStorageService.getFlightNumber(),
      cashPaymentNotifyUrl: ''
    };
    return prefillFormData;
  }

  getPrefillDataFromPhysicalAddress(physicalDeliveryInfo, countryEntity) {
    const { phoneCode, isocode, name } = countryEntity;
    const {
      familyName,
      givenName,
      localFamilyName,
      localGivenName,
      phoneNo,
      countryCode,
      companyName,
      enCity,
      enAddress1,
      enAddress2,
      enAddress3,
      localAddress1,
      localAddress2,
      localAddress3,
      addressCode,
      enPostalCode,
      enState,
      localCity,
      localPostalCode,
      localState,
      localCompanyName,
      hasLocalAddress,
      deliveryMethodCode,
      district,
      selectedTitle,
      logisticCompanyCodeOfLockersOrStores
    } = physicalDeliveryInfo;
    const prefillFormData = {
      selectedTitle,
      familyName,
      givenName,
      localFamilyName,
      localGivenName,
      recipientCountryCode: phoneCode,
      countryCode: isocode,
      recipientPhoneNo: phoneNo,
      recipientInfoCountryCode: countryCode,
      companyName,
      enAddress1,
      enAddress2,
      enAddress3,
      localAddress1,
      localAddress2,
      localAddress3,
      addressCode,
      country: name,
      enCity,
      enState,
      enPostalCode,
      localCompanyName,
      localCity,
      localState,
      localPostalCode,
      hasLocalAddress,
      deliveryMethodCode,
      district,
      logisticCompanyCodeOfLockersOrStores
    };
    return prefillFormData;
  }

  getActiveAddress(deliveryAndAddressInfo: DeliveryAddressInfo, addressCode: string) {
    let selectedAddress = null;
    const validAddressList = deliveryAndAddressInfo.address?.validAddress;
    if (!!validAddressList) {
      selectedAddress = validAddressList.filter((addressItem) => {
        addressItem.addressCode === addressCode;
      });
    }
    return selectedAddress;
  }

  isEnableGoogleMap(deliveryAddressInfo: DeliveryAddressInfo): boolean {
    const {
      address: { isInFlight },
      isUseGoogleMap
    } = deliveryAddressInfo;
    let isEnableGoogleMap = isInFlight === true ? 'false' : isUseGoogleMap;
    if (isEnableGoogleMap === 'false') {
      return false;
    }
    const geoCode = CookiesHelper.getCookie('geoCode');
    if (!!geoCode && geoCode.toLocaleUpperCase() === 'CN') {
      return false;
    }
    return true;
  }

  getSubmitedAddressInfo(deliveryAddressInfo: DeliveryAddressInfo, activePhysicalAddress: PhysicalDeliveryInfo) {
    // if(deliveryAddressInfo?.address?.validAddress && deliveryAddressInfo.deliveryInfo.recipientInfo && deliveryAddressInfo.deliveryInfo.physicalDeliveryInfo) {
    //   const validAddress = deliveryAddressInfo.address.validAddress[0]

    // }
    const {
      deliveryInfo: {
        physicalDeliveryInfo: {
          codeOfPackagingDimensionWeight,
          logisticCompanyCodeOfLockersOrStores,
          needPhysicalDelivery,
          needEmailDelivery,
          phoneAreaCode: recipientCountryCode,
          phoneNo: recipientPhoneNo
        } = {},
        recipientInfo: { phoneNo: smsDeliveryPhoneNo, countryCode: smsDeliveryCountryCode } = {},
        baseInfo: { emailAddress }
      } = {}
    } = deliveryAddressInfo;

    const selectedTitle = activePhysicalAddress.title.toLowerCase();

    return {
      ...activePhysicalAddress,
      codeOfPackagingDimensionWeight,
      logisticCompanyCodeOfLockersOrStores,
      needPhysicalDelivery,
      recipientCountryCode,
      recipientPhoneNo,
      smsDeliveryPhoneNo,
      smsDeliveryCountryCode,
      selectedTitle,
      emailAddress,
      needEmailDelivery
    };
  }

  setSubmitBtnStatus(status: boolean) {
    this.updateSubmitBtnStatusSub.next(status);
  }

  setDisplayModeSub(displayMode) {
    this.displayModeSub$.next(displayMode);
  }

  setPreFormDataSub(preFormData) {
    this.preFormData$.next(preFormData);
  }

  setActivePhysicalAddressSub(activePhysicalAddress) {
    this.activePhysicalAddress$.next(activePhysicalAddress);
  }

  getOnlyEmailSubmitInfo(prefillFormData: DeliveryAddressFormData) {
    return {
      countryCode: prefillFormData.country,
      deliveryEmailAddress: prefillFormData.emailAddress,
      emailAddress: prefillFormData.emailAddress,
      familyName: prefillFormData.familyName,
      givenName: prefillFormData.givenName,
      needPhysicalDelivery: false,
      needEmailDelivery: true,
      recipientCountryCode: prefillFormData.recipientCountryCode,
      recipientPhoneNo: prefillFormData.recipientPhoneNo,
      selectedTitle: prefillFormData.selectedTitle
    };
  }

  getOnlyShopPickupInfo(deliveryAndAddressInfo: DeliveryAddressInfo) {
    return {
      recipientPhoneNo: deliveryAndAddressInfo.deliveryInfo.recipientInfo.phoneNo,
      recipientCountryCode: deliveryAndAddressInfo.deliveryInfo.recipientInfo.countryCode
    };
  }

  getOnlyInflightPickupInfo(prefillFormData: DeliveryAddressFormData) {
    return {
      pointOfService: prefillFormData.pointOfService,
      seatNo: prefillFormData.seatNo,
      cashPaymentNotifyUrl: prefillFormData.cashPaymentNotifyUrl,
      // destinationCountryCode: prefillFormData.destinationCountryCode,
      cityCode: prefillFormData.cityCode,
      countryDefaultName: prefillFormData.countryDefaultName,
      cityName: prefillFormData.cityName,
      cityDefaultName: prefillFormData.cityDefaultName,
      cityCountryName: prefillFormData.cityCountryName
    };
  }

  setVerifyMaxOtpTimeStatus(status: boolean) {
    this.verifyMaxOtpTimeError.next(status);
  }

  getDestinationCountries(): Observable<Airports[]> {
    return this.languageService.getActive().pipe(
      switchMap((lang) => {
        let url = environment.airportUrl[lang];
        return this.http.get<AirportsList>(url).pipe(map((d) => d.airports));
      })
    );
  }

  /**
   * @description search destination coutry list
   * @returns Observable<[regionName, Airports[]][]>
   */
  destinationCountryList(): Observable<[string, Airports[]][]> {
    return this.getDestinationCountries().pipe(
      map((d: Airports[]) => {
        let _airports = new Map<string, Array<Airports>>();
        d?.map((v) => {
          const countries = _airports.get(v.regionName);
          if (countries) {
            const hasSame = countries.some((d) => d.airportDetails.city.code === v.airportDetails.city.code);
            if (!hasSame) {
              countries.push(v);
            }
          } else {
            _airports.set(v.regionName, [v]);
          }
        });
        return [..._airports];
      })
    );
  }

  /**
   * @description searcg airport infomation according to city code
   * @param code city code
   * @returns Observable<Airports>
   */
  getAirportMapping(cityCode): Observable<Airports> {
    return this.getDestinationCountries().pipe(
      map((d: Airports[]) => {
        return d?.filter((v) => v.airportDetails.city.code === cityCode)[0];
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
