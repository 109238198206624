import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { CmsComponentData, Media, MediaContainer, MediaService } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import SwiperCore, { Autoplay, Navigation, Pagination, Swiper, SwiperOptions } from 'swiper';

SwiperCore.use([Autoplay, Navigation, Pagination]);

export interface PromotionBannerData extends CmsComponent {
  displayImageDetailInfoComponents?: String;
  title?: string;
  description?: string;
}

export interface DisplayImage {
  url: string;
  code?: string;
  mime?: string;
}

export interface PromotionBannerDetailData extends CmsComponent {
  code?: string;
  container?: boolean;
  url?: string;
  isFullImage?: boolean;
  textInWhite?: boolean;
  displayMedia?: DisplayImage;
  displayImage?: Media;
  displayButtonName: string;
  displayContentImageComponents: string;
  displayDescription1?: string;
  displayDescription2?: string;
  mediaPostImageUrl?: string;
  displayMobileImage?: Media;
  mediaContainer?: MediaContainer;
}

@Component({
  selector: 'am-promotion-banner',
  templateUrl: './promotion-banner.component.html'
})
export class PromotionBannerComponent {
  pageName: string; // for AA

  @ViewChild('swiper') swiperContainer;
  aTags: Array<HTMLElement> = [];
  activeIndex = 0;
  isBrowser: boolean;
  constructor(
    public componentData: CmsComponentData<any>,
    public cmsService: CmsService,
    public mediaService: MediaService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    centeredSlides: true,
    navigation: true,
    loop: true,
    autoplay: {
      delay: 8000
    },
    speed: 800,
    pagination: { clickable: true },
    breakpoints: {
      '0': {
        spaceBetween: 0
      },
      '768': {
        spaceBetween: 32
      },
      '992': {
        spaceBetween: 90
      }
    }
  };

  detailItems$: Observable<PromotionBannerDetailData[]> = this.cmsService.getCurrentPage().pipe(
    filter((pages) => !!pages),
    switchMap((pages) => {
      this.pageName = pages.name;
      return this.componentData.data$.pipe(
        switchMap((bannerData: PromotionBannerData) => {
          if (bannerData && bannerData.displayImageDetailInfoComponents) {
            return combineLatest(
              bannerData.displayImageDetailInfoComponents
                .trim()
                .split(' ')
                .map((component) =>
                  this.cmsService.getComponentData<PromotionBannerDetailData>(component).pipe(
                    map((detailItem) => {
                      if (!detailItem) {
                        return undefined;
                      }
                      if (!!detailItem.displayMedia && !!detailItem.displayImage) {
                        detailItem = {
                          ...detailItem,
                          mediaPostImageUrl: this.mediaService.getMedia(detailItem.displayImage)?.src
                        };
                      } else if (!!detailItem.displayImage || !!detailItem.displayMobileImage) {
                        detailItem = {
                          ...detailItem,
                          mediaContainer: {
                            ...this.getImageContainer(detailItem)
                          }
                        };
                      }
                      return detailItem;
                    })
                  )
                )
            );
          }
          return of([]);
        }),
        tap((data) => {
          // this.setSwiperConfig(data);
          if (data.length <= 1) {
            this.swiperConfig = {
              ...this.swiperConfig,
              loop: false,
              navigation: false,
              autoplay: false
            };
          }
        })
      );
    })
  );

  getImageContainer(detailItem): MediaContainer {
    const mediaContainer: MediaContainer = {};
    if (detailItem.displayImage) {
      mediaContainer.desktop = {
        url: this.mediaService.getMedia(detailItem.displayImage)?.src
      };
    }
    if (detailItem.displayMobileImage) {
      mediaContainer.mobile = {
        url: this.mediaService.getMedia(detailItem.displayMobileImage)?.src
      };
    }
    return mediaContainer;
  }

  protected setSwiperConfig(bannerData): void {
    this.swiperConfig = {
      slidesPerView: 'auto',
      centeredSlides: true,
      navigation: bannerData.length > 1 ? true : false,
      loop: bannerData.length > 1 ? true : false,
      autoplay:
        bannerData.length > 1
          ? {
              delay: 8000
            }
          : false,
      speed: 800,
      pagination: { clickable: true },
      breakpoints: {
        '0': {
          spaceBetween: 0
        },
        '768': {
          spaceBetween: 32
        },
        '992': {
          spaceBetween: 90
        }
      }
    };
  }

  toggleTabIndex(swiperArray: Array<Swiper>): void {
    if (!this.isBrowser) {
      return;
    }
    const swiperInstant = swiperArray?.length > 0 && swiperArray[0];
    this.activeIndex = swiperInstant?.realIndex || 0;
    if (swiperInstant?.update && typeof swiperInstant.update === 'function') {
      swiperInstant.update();
    }
    const swiperContainerElement = this.swiperContainer && this.swiperContainer.nativeElement;
    if (!swiperContainerElement) {
      return;
    }
    if (!this.aTags || this.aTags.length <= 0) {
      this.aTags = swiperContainerElement.querySelectorAll('.swiper-slide a');
    }
    if (!this.aTags || this.aTags.length <= 0) {
      return;
    }
    for (let i = 0; i < this.aTags.length; i++) {
      this.aTags[i].setAttribute('tabindex', '-1');
    }
    const buttonAtags = swiperContainerElement.querySelectorAll('.swiper-slide-active a.button-primary');
    const linkAtags = swiperContainerElement.querySelectorAll('.swiper-slide-active am-generic-link>a');
    const activeAtags = [...buttonAtags, ...linkAtags];
    if (activeAtags && activeAtags.length > 0) {
      for (let i = 0; i < activeAtags.length; i++) {
        activeAtags[i].setAttribute('tabindex', '0');
      }
    }
  }
}
