import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  ActivatedRouterStateSnapshot,
  Breadcrumb,
  Facet,
  PageType,
  ProductSearchPage,
  RoutingService
} from '@spartacus/core';
import { FacetList } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { AmProductListComponentService } from './product-list-component.service';

/**
 * Provides access to all the facets and active facets for the Product Listing Page.
 */
@Injectable({
  providedIn: 'root'
})
export class AmProductFacetService {
  constructor(
    protected routing: RoutingService,
    protected productListComponentService: AmProductListComponentService
  ) {}

  protected readonly routeState$ = this.routing.getRouterState().pipe(pluck('state'));

  /**
   * Returns the search results for the current page.
   */
  protected readonly searchResult$: Observable<ProductSearchPage> = this.routeState$.pipe(
    switchMap((state) => {
      return this.productListComponentService.model$.pipe(
        map((page) => {
          return {
            ...page,
            facets: this.filterFacets(page.facets, page.queryResult),
            breadcrumbs: this.filterBreadcrumbs(page?.breadcrumbs ?? [], state.params)
          };
        })
      );
    })
  );

  /**
   * Observes the facets and active facets for the given page. The facet data
   * is provided in a `FacetList`.
   */
  readonly facetList$: Observable<FacetList> = this.searchResult$.pipe(
    map(
      (result: ProductSearchPage) =>
        ({
          facets: result.facets,
          activeFacets: result.breadcrumbs
        } as FacetList)
    )
  );

  /**
   * Filters the current result by verifying if the result is related to the page.
   * This is done to avoid a combination of the next page and the current search results.
   */
  protected filterForPage(state: ActivatedRouterStateSnapshot, page: ProductSearchPage): boolean {
    if (!page.currentQuery?.query?.value) {
      return false;
    }
    if (state.context.type === PageType.CATEGORY_PAGE) {
      return page.currentQuery.query.value.indexOf(`allCategories&#x3a;${state.context.id}`) > -1;
    }

    if (state.context.type === PageType.CONTENT_PAGE && state.context.id === 'search') {
      return page.currentQuery.query.value.startsWith(`${state.params.query}:`);
    }
    return false;
  }

  protected filterFacets(facets: Facet[], queryResult: any): Facet[] {
    const { filterCategoryLevel, ifMatchCoBrandDiscount, ifMatchMPODiscount, ifShowBestDealsTag } = queryResult;
    const categories = ['popular_filter', filterCategoryLevel, 'brand', 'best_deal'];
    const newFacters =
      facets
        ?.filter((facet) => {
          if (facet.code === 'best_deal') {
            if (Boolean(ifShowBestDealsTag) === true) {
              return facet.values.filter(
                (facetValue) =>
                  facetValue.code === 'SPECIAL_OFFER' ||
                  (Boolean(ifMatchMPODiscount) && facetValue.code === 'MARCO_POLO_CLUB_DISCOUNT') ||
                  (Boolean(ifMatchCoBrandDiscount) && facetValue.code === 'COBRANDED_CREDIT_CARD_DISCOUNT')
              );
            }
            return false;
          }
          return categories.includes(facet.code);
        })
        .sort((facet1, facet2) => {
          return categories.indexOf(facet1.code) - categories.indexOf(facet2.code);
        }) || [];
    return newFacters;
  }

  /**
   * Filter breadcrumbs which are not actively selected but coming from
   * the route navigation.
   *
   * The breadcrumbs might include the active category page code, which is not actively
   * selected by the user.
   */
  protected filterBreadcrumbs(breadcrumbs: Breadcrumb[], params: Params): Breadcrumb[] {
    return breadcrumbs
      ? breadcrumbs.filter(
          (breadcrumb) =>
            breadcrumb.facetCode !== 'paymentMethod' &&
            !(breadcrumb.facetValueCode === params.categoryCode || breadcrumb.facetValueCode === params.brandCode)
        )
      : [];
  }
}
