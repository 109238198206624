import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ProductSearchPage, RoutingService, WindowRef } from '@spartacus/core';
import UrlHelper from '@utils/url-helper';
import { tap } from 'rxjs/operators';
import { AmProductListComponentService } from '../../../services/product-list-component.service';

@Component({
  selector: 'am-price-slider-bar',
  templateUrl: './am-price-slider-bar.component.html'
})
export class AmPriceSliderBarComponent {
  @Input()
  searchTextFromRouter: string;

  displayMinValue: number;
  displayMaxValue: number;
  minRangeValue: number = 0;
  maxRangeValue: number = 0;
  equalMode: boolean = false;
  sliderConfig: Options = new Options();
  model$: any;
  categoryCodeFromRouter: string;
  routerState$: any;

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  isBrowser: boolean = false;

  constructor(
    protected winRef: WindowRef,
    protected productListComponentService: AmProductListComponentService,
    private router: Router,
    protected routingService: RoutingService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.model$ = this.productListComponentService.model$.pipe(
      // take(1),
      tap((data: ProductSearchPage) => {
        const { queryResult } = data;
        const { minimumMiles, maximumMiles, currentMinMiles, currentMaxMiles } = queryResult;
        if (this.displayMinValue !== Number(currentMinMiles) || this.displayMaxValue !== Number(currentMaxMiles)) {
          this.minRangeValue = minimumMiles;
          this.maxRangeValue = maximumMiles;
          this.displayMinValue = currentMaxMiles;
          this.displayMaxValue = currentMinMiles;
          this.sliderConfig = {
            floor: this.minRangeValue,
            ceil: this.maxRangeValue,
            hideLimitLabels: true,
            hidePointerLabels: true
          };
          if (this.minRangeValue === this.maxRangeValue) {
            this.equalMode = true;
          } else {
            this.equalMode = false;
          }
        }
      })
    );
  }

  onUserChangeEnd = (changeContext: ChangeContext): void => {
    if (this.equalMode) {
      return;
    }
    const url = this.winRef.location.href;
    let originQuery = UrlHelper.getSearchParamByName('query', url) ?? '';
    let query = originQuery;
    if (!!this.searchTextFromRouter && !query.includes(this.searchTextFromRouter)) {
      query = this.searchTextFromRouter + query;
    }
    if (!originQuery) {
      query = query += `:onlineDate-desc`;
    }
    this.displayMinValue = changeContext.value;
    this.displayMaxValue = changeContext.highValue;
    let priceParam = `:zonePrice:${this.displayMinValue}_${this.displayMaxValue}`;
    if (query?.includes('zonePrice')) {
      const regex = new RegExp(':zonePrice:([0-9]{0,8}_[0-9]{0,8})');
      query = query.replace(regex, priceParam);
    } else {
      query += priceParam;
    }

    this.router.navigate([], {
      queryParams: {
        ...UrlHelper.parseQueryStringToObject(url),
        query,
        categoryCode: this.categoryCodeFromRouter
      }
    });
  };
}
