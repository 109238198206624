import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  Renderer2,
  TemplateRef
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { svgConfig } from '@config/am-svg-sprite.config';
import { WindowRef } from '@spartacus/core';
import { PositioningService, UIPositionRectangle } from '@spartacus/storefront';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { HorizontalAlign, ToolTipEvent } from './am-tool-tip.model';

@Component({
  selector: 'am-tool-tip',
  templateUrl: './am-tool-tip.component.html',
  styleUrls: ['./am-tool-tip.component.scss']
})
export class AmToolTipComponent implements OnInit {
  eventSubject: Subject<ToolTipEvent>;

  content: string | TemplateRef<any>;

  popoverInstance: ComponentRef<AmToolTipComponent>;

  triggerElement: ElementRef;

  resizeSub: Subscription;

  routeChangeSub: Subscription;

  toolTipElement: HTMLElement;

  parentElement: HTMLElement;

  horizontalAlign: HorizontalAlign;

  toolTipElementWidth: number;

  isTemplate: boolean;

  displayCloseButton: boolean;

  className: string;

  svgType = svgConfig;
  style = 'width: 12px; height: 12px';

  arrowElement: any;

  @HostBinding('className') baseClass: string;

  @HostListener('click')
  insideClick() {
    this.eventSubject.next(ToolTipEvent.INSIDE_CLICK);
  }

  /**
   * Listens for every document click and ignores clicks
   * inside component.
   */
  @HostListener('document:click', ['$event'])
  outsideClick(event: MouseEvent) {
    if (!this.isClickedOnPopover(event) && !this.isClickedOnDirective(event)) {
      this.eventSubject.next(ToolTipEvent.OUTSIDE_CLICK);
    }
  }

  close(event: MouseEvent | KeyboardEvent | Event) {
    event.preventDefault();
    if (event instanceof MouseEvent) {
      this.eventSubject.next(ToolTipEvent.CLOSE_BUTTON_CLICK);
    } else {
      this.eventSubject.next(ToolTipEvent.CLOSE_BUTTON_KEYDOWN);
    }
  }
  subscription = new Subscription();
  protected isClickedOnPopover(event) {
    return this.popoverInstance.location.nativeElement.contains(event.target);
  }

  protected isClickedOnDirective(event) {
    return this.triggerElement.nativeElement.contains(event.target);
  }

  positionPopover() {
    if (!this.arrowElement || !this.toolTipElement) {
      return;
    }

    const targetElement = this.triggerElement.nativeElement;
    const targetElPosition = this.offset(targetElement);
    const topPosition = targetElPosition.top + targetElPosition.height;

    const arrowLeftPosition = targetElPosition.left + targetElPosition.width / 2;
    // set the arrow position: under the target element
    this.arrowElement.style.transform = `translate(${arrowLeftPosition}px, ${topPosition}px)`;
    const alignParentElement = this.parentElement ? this.parentElement : targetElement;
    const parentElPosition = this.parentElement ? this.offset(alignParentElement) : targetElPosition;

    const tooltipContentleftPosition = this.calculateLeftPosition(parentElPosition, targetElPosition);
    this.toolTipElement.style.transform = `translate(${tooltipContentleftPosition}px, ${topPosition + 5}px)`;
    this.changeDetectionRef.markForCheck();
    this.baseClass = 'opened ' + this.className;
  }

  calculateLeftPosition(parentElPosition, targetElPosition) {
    const leftSpace = parentElPosition.right;
    const rightSpace = this.window.innerWidth - parentElPosition.left;
    const targetElLeftSpace = targetElPosition.left + targetElPosition.width / 2;
    const targetElRightSpace = this.window.innerWidth - targetElLeftSpace;
    const contentElHalfWidth = this.toolTipElementWidth / 2;
    let leftPosition = 0;
    const paddingGap = 16;
    const textWidth = targetElPosition.left - parentElPosition.left;
    // 当title的长度小于toolTip content的长度的时候，才能尝试左右对齐
    if (textWidth < this.toolTipElementWidth && this.horizontalAlign !== 'center') {
      // 左对齐, 要看右边的空间够不够放
      if (this.horizontalAlign === 'left' && rightSpace >= this.toolTipElementWidth) {
        return parentElPosition.left;
      }
      // 左对齐失败了,或者传了参数要右对齐,尝试右对齐
      else if (leftSpace >= this.toolTipElementWidth) {
        // leftPosition = targetElPosition.right - this.toolTipElementWidth;
        return parentElPosition.right - this.toolTipElementWidth;
      }
      // 传了参数要右对齐但是失败了,那还是左对齐
      else if (!!this.horizontalAlign && this.horizontalAlign !== 'left' && rightSpace >= this.toolTipElementWidth) {
        return parentElPosition.left;
      }
    }

    // 如果右对齐也失败了,尝试基于targetIcon居中
    if (targetElLeftSpace >= contentElHalfWidth && targetElRightSpace >= contentElHalfWidth) {
      leftPosition = targetElLeftSpace - contentElHalfWidth;
    } // 如果居中也失败了,说明targetIcon的位置要么偏左边,要么偏右边，这个时候，应该让它把空间少的那边占满,剩下的放空间大的那边
    else {
      if (targetElLeftSpace < targetElRightSpace) {
        leftPosition = paddingGap;
      } else {
        leftPosition = this.window.innerWidth - this.toolTipElementWidth;
      }
    }
    return leftPosition;
  }

  ngOnInit(): void {
    this.isTemplate = this.content instanceof TemplateRef;
    this.routeChangeSub = this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.eventSubject.next(ToolTipEvent.ROUTE_CHANGE);
    });
    this.initResizeEvent();
  }

  initResizeEvent() {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.resizeSub = fromEvent(this.winRef.nativeWindow, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.eventSubject.next(ToolTipEvent.CLOSE_BUTTON_CLICK);
        // this.resizeSub.unsubscribe()
      });
  }

  ngAfterViewChecked(): void {
    if (!this.toolTipElement || !this.arrowElement) {
      const container = this.popoverInstance.location.nativeElement;
      if (!!container) {
        this.toolTipElement = container.getElementsByClassName('tooltip-content')[0];
        this.arrowElement = container.getElementsByClassName('arrow')[0];
      }
    }
    if (this.toolTipElement.getBoundingClientRect().width === this.toolTipElementWidth) {
      return;
    }
    this.toolTipElementWidth = this.toolTipElement.getBoundingClientRect().width;
    this.positionPopover();
  }

  ngOnDestroy(): void {
    this.eventSubject.next(ToolTipEvent.CLOSE_BUTTON_CLICK);
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }

    if (this.routeChangeSub) {
      this.routeChangeSub.unsubscribe();
    }
    this.subscription?.unsubscribe();
  }

  // position service
  protected get window(): Window | undefined {
    return this.winRef.nativeWindow;
  }

  protected get document(): Document {
    return this.winRef.document;
  }

  protected offset(element: HTMLElement, round = true): UIPositionRectangle {
    const elBcr = element.getBoundingClientRect();
    const viewportOffset = {
      top: (this.window && this.window.pageYOffset - this.winRef.document?.documentElement.clientTop) || 0,
      left: (this.window && this.window.pageXOffset - this.winRef.document?.documentElement.clientLeft) || 0
    };

    const elOffset = {
      height: elBcr.height || element.offsetHeight,
      width: elBcr.width || element.offsetWidth,
      top: elBcr.top + viewportOffset.top,
      bottom: elBcr.bottom + viewportOffset.top,
      left: elBcr.left + viewportOffset.left,
      right: elBcr.right + viewportOffset.left
    };

    if (round) {
      elOffset.height = Math.round(elOffset.height);
      elOffset.width = Math.round(elOffset.width);
      elOffset.top = Math.round(elOffset.top);
      elOffset.bottom = Math.round(elOffset.bottom);
      elOffset.left = Math.round(elOffset.left);
      elOffset.right = Math.round(elOffset.right);
    }

    return elOffset;
  }

  constructor(
    protected positioningService: PositioningService,
    protected winRef: WindowRef,
    protected changeDetectionRef: ChangeDetectorRef,
    protected renderer: Renderer2,
    protected router: Router
  ) {}
}
