import { Component } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { Promotion } from '@spartacus/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-promotion-cash-dialog',
  templateUrl: './promotion-cash-dialog.component.html'
})
export class PromotionCashDialogComponent {
  promotion: Promotion;
  promoPopName: string;
  subscription: Subscription = new Subscription();

  constructor(protected launchDialogService: AmLaunchDialogService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        if (!!data) {
          this.promotion = data.promotion;
          this.promoPopName = data.promoPopName;
        }
      })
    );
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
