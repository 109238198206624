<div *ngIf="inflightDiagnosticInfo$ | async as inflightDiagnosticInfo" class="inflight-diagnostic-container container">
  <div class="text-content">
    <div class="text-row">
      <span class="text-title">Environment:</span
      ><span class="text-value">{{ inflightDiagnosticInfo.environment }}</span>
    </div>
    <div class="text-row">
      <span class="text-title">Flight Number:</span>
      <span class="text-value">{{ inflightDiagnosticInfo.flightNo ? inflightDiagnosticInfo.flightNo : '-' }}</span>
    </div>
    <div class="text-row">
      <span class="text-title">Inflight Sales Channel:</span>
      <span class="text-value">{{
        inflightDiagnosticInfo.inflightSalesChannel ? inflightDiagnosticInfo.inflightSalesChannel : '-'
      }}</span>
    </div>
    <div class="text-row">
      <span class="text-title">IP Address:</span><span class="text-value"> {{ inflightDiagnosticInfo.ipAddress }}</span>
    </div>
    <div class="text-row">
      <span class="text-title">Session ID:</span><span class="text-value">{{ inflightDiagnosticInfo.sessionID }}</span>
    </div>
  </div>
</div>
