import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { svgConfig } from '@config/am-svg-sprite.config';
import { Facet, FacetValue, RouterState, RoutingService } from '@spartacus/core';
import { FacetCollapseState, FacetList, FocusDirective, ICON_TYPE } from '@spartacus/storefront';
import UrlHelper from '@utils/url-helper';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AmFacetService } from '../../../services/facet.service';

@Component({
  selector: 'am-facet',
  templateUrl: './am-facet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmFacetComponent implements OnDestroy {
  toggleGroup(_arg0: UIEvent): void {
    throw new Error('Method not implemented.');
  }
  protected _facet: Facet;

  state$: Observable<FacetCollapseState>;
  categoryCodeFromRouter: string;

  /** configurable icon that is used to collapse the facet group  */
  @Input() expandIcon: ICON_TYPE = ICON_TYPE.EXPAND;
  @Input() collapseIcon: ICON_TYPE = ICON_TYPE.COLLAPSE;
  @Input() facetStatus: any;

  @HostBinding('class.multi-select') isMultiSelect: boolean;

  @ViewChildren('facetValue') values: QueryList<ElementRef<HTMLElement>>;

  @ViewChild(FocusDirective) keyboardFocus: FocusDirective;

  routerState$: Observable<RouterState>;

  @Input()
  set facet(value: Facet) {
    value.values &&
      value.values.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    this._facet = value;
    this.isMultiSelect = !!value.multiSelect;
    this.state$ = this.facetService.getState(value);
  }

  get facet(): Facet {
    return this._facet;
  }

  @Input()
  payByCashStatus: boolean;

  @Input()
  searchTextFromRouter: string;

  brandNameValue: string;
  svgType = svgConfig.SEARCH;
  isFocused = false;
  facetList: FacetList;
  protected subscription = new Subscription();

  constructor(
    protected facetService: AmFacetService,
    protected elementRef: ElementRef<HTMLElement>,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected routingService: RoutingService
  ) {
    this.routerState$ = this.routingService.getRouterState().pipe(
      tap((data) => {
        this.categoryCodeFromRouter = data.state.queryParams.categoryCode ?? data.state.params.categoryCode;
      })
    );
    this.subscription.add(
      this.facetService.facetList$.subscribe((facetList) => {
        this.facetList = facetList;
      })
    );
  }

  get isExpanded(): boolean {
    return this.values.first.nativeElement.offsetParent !== null;
  }

  openLink(event: KeyboardEvent): void {
    (event.target as HTMLElement).click();
    event.preventDefault();
  }

  /**
   * Increases the number of visible values for the facet. This is delegated
   * to `facetService.increaseVisibleValues`.
   */
  increaseVisibleValues(): void {
    this.facetService.increaseVisibleValues(this.facet);
  }

  /**
   * Decreases the number of visible values for the facet. This is delegated
   * to `facetService.decreaseVisibleValues`.
   */
  decreaseVisibleValues(): void {
    this.facetService.decreaseVisibleValues(this.facet);
  }

  getLinkParams(value: FacetValue, categoryName: string): { [key: string]: string } {
    return this.facetService.getLinkParams(
      value.query?.query.value,
      categoryName,
      value.code,
      this.categoryCodeFromRouter
    );
  }

  filterFacet(facetValue): any {
    if (!this.brandNameValue || this.brandNameValue === '') {
      return facetValue;
    } else {
      let temp = this.brandNameValue.toLowerCase();
      return facetValue.filter((item) => item.name.toLowerCase().indexOf(temp) > -1);
    }
  }

  inputFocused(e): void {
    this.isFocused = true;
  }

  inputBlured(): void {
    this.isFocused = false;
  }

  isSelectBrand(): boolean {
    return this.facetList.activeFacets.some((activeFacet) => activeFacet.facetCode === 'brand');
  }
  unselectAll(): Promise<boolean> {
    const url = window.location.href;
    const queryParams = UrlHelper.parseQueryStringToObject(url) as any;
    let newQuery = queryParams.query;
    this.facetList.activeFacets.forEach((activeFacet) => {
      if (activeFacet.facetCode === 'brand') {
        this.facetStatus['brand'] = false;
        this.facetService.storeAccordionStatus(this.facetStatus);
        const queryItem = `:${activeFacet.facetCode}:${activeFacet.facetValueCode}`;
        newQuery = newQuery.replace(queryItem, '');
      }
    });
    return this.router.navigate([], {
      queryParams: {
        ...queryParams,
        query: newQuery
      }
    });
  }

  storeFacetStatus(e) {
    if (e.accordionName.indexOf('category') > -1) {
      e.accordionName = 'category';
    }
    this.facetStatus[e.accordionName] = e.isExpanded;
    this.facetService.storeAccordionStatus(this.facetStatus);
  }

  isFacetExpanded(key: string): boolean {
    if (key.indexOf('category') > -1) {
      key = 'category';
    }
    return this.facetService.getAccordionStatus(key) && this.facetList.activeFacets.length > 0;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
