import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import occConfig from '@config/am-occ.config';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { AmCds } from './config/am-cds.config';
import { AmI18n } from './config/am-i18n.config';
import { layoutConfig } from './config/am-layout.config';
import { amMediaConfig } from './config/am-media.config';
import { amPageMetaConfig } from './config/am-page-meta.config';
import { amPWAConfig } from './config/am-pwa.config';
import { routingConfig, STATIC_ROUTES } from './config/am-routing.config';
import { AmSiteContext } from './config/am-site-context.config';
import { amSmartEditConfig } from './config/am-smartedit.config';
import { viewConfig } from './config/am-view.config';
import { defaultPaginationConfig } from './config/default-pagination.config';
import { AmProductFacetNavigationComponent } from './pages/product-list/component/product-facet-navigation/am-product-facet-navigation.component';
import { ProductPageTitleComponent } from './pages/product-list/component/product-page-title/product-page-title.component';
import { SearchPageTitleComponent } from './pages/productSearchList/serchPageTitle/search-page-title.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(STATIC_ROUTES),
    ConfigModule.withConfig({
      ...amMediaConfig,
      ...layoutConfig,
      ...occConfig,
      ...routingConfig,
      ...viewConfig,
      ...defaultPaginationConfig,
      ...amSmartEditConfig,
      ...amPWAConfig,
      ...amPageMetaConfig,
      ...AmCds,
      ...AmI18n,
      ...AmSiteContext
    }),
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ProductRefinementComponent: {
          component: AmProductFacetNavigationComponent
        }
      }
    })
  ],
  providers: [
    provideOutlet({
      id: 'ProductListPageTemplate',
      position: OutletPosition.BEFORE,
      component: ProductPageTitleComponent
    }),
    provideOutlet({
      id: 'SearchResultsGridPageTemplate',
      position: OutletPosition.BEFORE,
      component: SearchPageTitleComponent
    })
  ]
})
export class AmConfigurationModule {}
