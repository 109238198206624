<div class="am-recommend-follow-categories" *ngIf="detailItems$ | async as detailItems">
  <div class="top-content" *ngIf="pageType === 'wishList'">
    <p *ngIf="myListActiveType === activeTabCodeMap.favourite">
      {{ 'amWishProductList.emptyFavoriteListTip' | cxTranslate }}
    </p>
    <p *ngIf="myListActiveType === activeTabCodeMap.notify">
      {{ 'amWishProductList.emptyNotifyListTip' | cxTranslate }}
    </p>
    <a [routerLink]="{ cxRoute: 'home' } | cxUrl">
      {{ 'amWishProductList.showNow' | cxTranslate }}
      <am-svg-container
        class="icon-light-grey-miles"
        [style]="style_arrow_icon"
        [type]="svgType.ARROWFORWARD"></am-svg-container>
    </a>
  </div>

  <div class="main-content">
    <p>
      {{ 'amWishProductList.inspireCategory' | cxTranslate }}
    </p>
    <div class="categories">
      <a class="category-item" *ngFor="let detailItem of detailItems" [routerLink]="[detailItem.url]">
        <div class="image-box">
          <am-media [container]="{ url: detailItem.thumbnail?.url }"></am-media>
        </div>

        <p>{{ detailItem.categoryName }}</p>
      </a>
    </div>
  </div>
</div>
