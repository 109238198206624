import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { SvgContainerModule } from '../../../../components/content/svg-container/svg-container.module';
import { MessageBoxComponent } from './message-box.component';

@NgModule({
  declarations: [MessageBoxComponent],
  imports: [
    CommonModule,
    KeyboardFocusModule,
    SvgContainerModule,
    SafeCodeModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSHeaderBannerComponent: {
          // typeCode
          component: MessageBoxComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [MessageBoxComponent]
})
export class MessageBoxModule {}
