<ng-container>
  <div class="variant-selector d-flex">
    <div class="variant-label">{{ variants?.selected?.name }}:</div>
    <div>
      <div class="font-bold">
        {{ firstUpperCase(variants.selected.value) }}
      </div>
      <div class="d-flex">
        <div class="variant-select variant-color" *ngFor="let v of variants?.options; index as idx">
          <a
            [attr.aria-label]="v.valueEn + ' Color, Button, ' + (v.code === variants.selected.code ? 'Selected, ' : 'Unselected, ') + +(idx+1) + ' of ' + variants?.options.length"
            href="javascript:void(0)"
            class="variant-color-link"
            (click)="changeColor(v.code, v.name)"
            [ngClass]="{ active: v.code === variants.selected.code }">
            <span class="variant-color-value" [style]="{ background: v.hexCode }"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
