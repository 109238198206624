import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'am-offer-details-popup-item',
  templateUrl: './offer-details-popup-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggle', [
      state('collapse', style({ height: '0', overflow: 'hidden' })),
      state('expand', style({ height: '*', overflow: 'auto' })),
      transition('collapse => expand', animate('400ms ease-out')), // 第一个参数:动画时间, 第二个参数:动画延迟时间
      transition('expand => collapse', animate('400ms ease-in'))
    ])
  ]
})
export class OfferDetailsPopupItemComponent implements OnChanges {
  @Input() discountOrBonusRule: any;
  @Input() isPotentialRule: boolean;
  @Input() multiple: boolean;
  @Input() activeCode: string;

  @Output()
  clickPromotion = new EventEmitter<any>();

  isSelected: boolean;
  toggleState: string;
  el: ElementRef;
  constructor() {}

  ngOnChanges(): void {
    this.isSelected = this.activeCode === this.discountOrBonusRule.code ? true : false;
    if (this.multiple) {
      this.toggleState = this.activeCode === this.discountOrBonusRule.code ? 'expand' : 'collapse';
    } else {
      this.toggleState = 'expand';
    }
  }

  onPromotionClick(): void {
    if (this.multiple) {
      this.toggleState = this.toggleState === 'collapse' ? 'expand' : 'collapse';
    } else {
      this.toggleState = 'expand';
    }
    this.isSelected = !this.isSelected;

    if (this.isSelected) {
      this.clickPromotion.emit(this.discountOrBonusRule.code);
    } else {
      this.clickPromotion.emit('');
    }
  }
}
