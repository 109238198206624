<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cobrand-coupon">
      <div class="cx-dialog-header">
        <div class="cx-dialog-title modal-title">
          {{ 'amCartDetails.popup.cobrandCashCoupon.title' | cxTranslate }}
        </div>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main">
          <div class="section">
            <p>
              {{ 'amCartDetails.popup.cobrandCashCoupon.info' | cxTranslate : { name: cobrandCardName } }}
            </p>
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="cx-dialog-buttons">
        <button aria-label="Cancel popup" class="button-primary font-bold" (click)="closeModal('cancel')">
          <span aria-hidden="true">
            {{ 'amCartDetails.popup.cobrandCashCoupon.cancel' | cxTranslate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
