<div class="am-site-context">
  <div class="selected-box">
    <a *ngIf="isInflightEnv" href="javascript:void(0)" class="inflight-shopping-guide" (click)="openShoppingTipModal()">
      <am-svg-container
        [style]="style_leave_shop"
        [type]="svgType.INFLIGHTSHOPPINGGUIDE"
        svgDescription="InflightShoppingGuide"></am-svg-container>
    </a>

    <a href="javascript:void(0)" (click)="toggleSelector('country')" class="selected-country">
      <div class="select-btn-wrapper" [ngClass]="countrySelectorOpen ? 'active' : ''">
        <am-svg-container
          *ngIf="!isInflightEnv"
          [style]="style"
          [type]="svgType.DELIVERY"
          svgDescription="Delivery"></am-svg-container>
        <am-svg-container
          *ngIf="isInflightEnv"
          [style]="style"
          [type]="svgType.INFLIGHTICONGREY"
          svgDescription="Delivery"></am-svg-container>
        <div class="selected-label">
          <p *ngIf="!isInflightEnv">
            {{ 'languageAndDelivery.deliveryTo' | cxTranslate }}
          </p>
          <p *ngIf="isInflightEnv">
            {{ 'languageAndDelivery.changeFlight' | cxTranslate }}
          </p>
          <p>
            <ng-container *ngIf="!isInflightEnv">
              <span>{{ activeCountryEntity?.name }}</span>
              <span class="country-currency">({{ activeCountryEntity?.currency }})</span>
            </ng-container>
            <ng-container *ngIf="isInflightEnv">
              <span>{{ flightStatusStorageSvc.getFlightNumber() }}</span>
            </ng-container>

            <svg class="icon-mini-drop-down" viewBox="0 0 24 24">
              <path
                fill="none"
                fill-rule="evenodd"
                stroke="#116f9a"
                stroke-width="1.5"
                d="M8 10.129L11.607 14l3.726-4"></path>
            </svg>
          </p>
        </div>
      </div>
    </a>
    <!-- duplicate struct for wa -->
    <ng-container
      *ngTemplateOutlet="selectContainer; context: { active: countrySelectorOpen, name: 'countrySelector' }">
    </ng-container>

    <a href="javascript:void(0)" class="selected-language" (click)="toggleSelector('language')">
      <div class="select-btn-wrapper" [ngClass]="languageSelectorOpen ? 'active' : ''">
        <span>
          {{ languageMap[activeLanguage] }}
        </span>
      </div>
    </a>
    <!-- duplicate struct for wa -->
    <ng-container
      *ngTemplateOutlet="selectContainer; context: { active: languageSelectorOpen, name: 'languageSelector' }">
    </ng-container>
  </div>

  <ng-template #selectContainer let-active="active" let-name="name">
    <div class="select-container" [ngClass]="active ? 'active' : ''" (click)="preventSelectorContainerEvent($event)">
      <div
        class="language-selector"
        *ngIf="(languageItems$ | async)?.length > 1 && (languageItems$ | async) as languageItems">
        <h2 class="title">
          {{ 'languageAndDelivery.chooseLanguage' | cxTranslate }}
        </h2>
        <div class="radio-selector-container">
          <div class="form-check" *ngFor="let item of languageItems">
            <input
              class="am-radio-selector"
              type="radio"
              id="{{ name }}_{{ item.isocode }}"
              name="{{ name }}-language-selector"
              value="{{ item.isocode }}"
              [(ngModel)]="selectedLanguage" />
            <label for="{{ name }}_{{ item.isocode }}">{{ item.nativeName }}</label>
          </div>
        </div>
      </div>
      <div class="country-and-currency-selector">
        <div class="flight-selector" [ngClass]="{ 'flight-selector-inflight': isInflightEnv }">
          <h2 class="title">
            {{ 'languageAndDelivery.changeFlight' | cxTranslate }}
          </h2>
          <div class="flight-info-wrap">
            <span class="flight-info">
              {{ flightStatusStorageSvc.getFlightNumber() }}
              <ng-container *ngIf="flightStatusStorageSvc.isInflightPickFlow">
                ({{ flightInfoService.getFlightOriginAndDest() }})
              </ng-container>
            </span>
          </div>
        </div>

        <div class="delivery-selector">
          <h2 class="title">
            {{ 'languageAndDelivery.chooseDelivery' | cxTranslate }}
          </h2>
          <div class="delivery-select">
            <span class="car-icon"><am-svg-container [type]="svgType.NEWCAR"></am-svg-container></span>
            <label class="floating-label-up"> {{ 'languageAndFlightPopup.deliveryLocation' | cxTranslate }} </label>
            <ng-container *ngIf="countryItems$ | async as countryItems">
              <ng-select
                [searchable]="true"
                [clearable]="false"
                [items]="countryItems"
                [(ngModel)]="selectedCountry"
                bindValue="isocode"
                bindLabel="name">
                <ng-template ng-option-tmp let-item="item">
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button *ngIf="isInflightEnv" class="btn-change button-secondary" (click)="openChangeFlightPopup()">
          {{ 'languageAndDelivery.change' | cxTranslate }}
        </button>
        <button class="button-apply button-primary" (click)="onSubmitSiteContext()">
          {{ 'languageAndDelivery.apply' | cxTranslate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>

<div class="am-mobile-site-context">
  <div class="leave-shop-btn-wrap" *ngIf="isInflightEnv">
    <span>
      <am-svg-container [type]="svgType.INFLIGHTSHOPPINGGUIDE" [style]="style_leave_shop"></am-svg-container>
    </span>
    <button class="leave-shop-btn" (click)="openShoppingTipModal()" type="button">
      {{ 'languageAndDelivery.inflightShoppingGuide' | cxTranslate }}
    </button>
  </div>

  <div class="selected-country">
    <span>
      <am-svg-container [type]="svgType.DELIVERTOCOUNTRY" [style]="style"></am-svg-container>
    </span>
    <ng-container *ngIf="countryItems$ | async as countryItems">
      <label>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [items]="countryItems"
          bindLabel="displayName"
          bindValue="isocode"
          [(ngModel)]="selectedCountry"
          (change)="onSubmitSiteContext()">
          <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </label>
    </ng-container>
  </div>

  <div class="selected-language">
    <span>{{ languageMap[selectedLanguage] }}</span>
    <ng-container *ngIf="languageItems$ | async as languageItems">
      <label>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [items]="languageItems"
          bindLabel="nativeName"
          bindValue="isocode"
          [(ngModel)]="selectedLanguage"
          (change)="onSubmitSiteContext()"></ng-select>
      </label>
    </ng-container>
  </div>

  <div class="leave-shop-btn-wrap" *ngIf="isInflightEnv">
    <span>
      <am-svg-container [type]="svgType.LEAVEINFLIGHTSHOPPING" [style]="style_leave_shop"></am-svg-container>
    </span>
    <button class="leave-shop-btn" (click)="openChangeFlightPopup()" type="button">
      {{ 'languageAndDelivery.leaveShopBtn' | cxTranslate : { value0: this.flightStatusStorageSvc.getFlightNumber() } }}
    </button>
  </div>

  <!-- value1: this.filightInfoService.getFlightOriginAndDest()
  ? '(' + this.filightInfoService.getFlightOriginAndDest() + ')'
  : '' -->

  <a (blur)="onLastElementTab()" aria-hidden="true" class="is-visually-hidden" href="javascript:void(0)">
    <span>This is the last element, will be back to first elememt!</span>
  </a>
</div>
