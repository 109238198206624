import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { svgConfig } from '@config/am-svg-sprite.config';
import { Product } from '@spartacus/core';

@Component({
  selector: 'am-product-slider-alcoholic',
  templateUrl: './product-slider-alcoholic.component.html'
})
export class ProductSliderAlcoholicComponent {
  svgType = svgConfig;
  error_style = 'margin: 0 5px 0 0';

  @Input() product: Product;
  @Input() alcoholicForm: FormGroup;
  @Input() pageFrom: string;
}
