import { Component } from '@angular/core';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { ActiveCartFacade, Cart, OrderEntry } from '@spartacus/cart/base/root';
import { isNotNullable } from '@spartacus/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'am-miles-earn-dialog',
  templateUrl: './miles-earn-dialog.component.html'
})
export class MilesEarnDialogComponent {
  cart$ = this.activeCartFacade.getActive().pipe(
    tap((cart: Cart) => {
      cart.entries?.filter((e) => e.selected).forEach((entry) => {});
    })
  );
  currency$ = this.countryService.getActiveCountryEntity().pipe(
    filter(isNotNullable),
    map((v) => v.currency)
  );
  protected subscription = new Subscription();
  constructor(
    protected countryService: CountryService,
    protected activeCartFacade: ActiveCartFacade,
    protected launchDialogService: AmLaunchDialogService
  ) {}

  openOfferDetailsModal(promotion): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.DISPLAY_CART_OFFER_DETAIL, undefined, undefined, {
      promotion,
      isEarnMiles: true
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason, LAUNCH_CALLER.DISPLAY_EARN_MILES);
  }

  filterEntries(orderEntries: OrderEntry[]): OrderEntry[] {
    return orderEntries.filter((e) => e.selected);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
