import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { TermsAndConditionsModule } from '../terms-and-conditions/terms-and-conditions.module';
import { PromotionCashSpendDialogComponent } from './promotion-cash-spend-dialog.component';

@NgModule({
  declarations: [PromotionCashSpendDialogComponent],
  exports: [PromotionCashSpendDialogComponent],
  imports: [CommonModule, TermsAndConditionsModule, IconModule, I18nModule, SafeCodeModule, KeyboardFocusModule]
})
export class PromotionCashSpendDialogModule {}
