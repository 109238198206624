import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { ChangeFlightConfiramtionPopupComponent } from './change-flight-confiramtion-popup.component';

@NgModule({
  declarations: [ChangeFlightConfiramtionPopupComponent],
  imports: [CommonModule, SvgContainerModule, I18nModule, KeyboardFocusModule],
  exports: [ChangeFlightConfiramtionPopupComponent]
})
export class ChangeFlightConfirmationPopupModule {}
