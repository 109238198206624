<div class="cart-detail-flow1-container">
  <div class="container">
    <div class="tab-title-container">
      <button
        (click)="switchTab('inflight')"
        [ngClass]="{ 'active-btn': activeTab === 'inflight' }"
        [disabled]="cartLoaded"
        aria-label="Inflight cart tab">
        {{ 'amCartDetails.inflightCart' | cxTranslate }} ({{ infligtAmount }})
      </button>
      <button
        (click)="switchTab('ground')"
        [ngClass]="{ 'active-btn': activeTab !== 'inflight' }"
        [disabled]="cartLoaded"
        aria-label="On ground cart tab">
        {{ 'amCartDetails.groundCart' | cxTranslate }} ({{ groundAmount }})
      </button>
    </div>
  </div>

  <div class="tab-content" [ngClass]="{ 'orange-background': activeTab === 'inflight' }">
    <ng-container *ngIf="activeTab === 'inflight'">
      <ng-container *ngIf="inflightCartEntries.length > 0; else emptyInflightCart">
        <am-cart-messages
          [isStoreOpenStatus]="isStoreOpenStatus"
          [activeTab]="activeTab"
          [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(inflightCartEntries)"></am-cart-messages>
        <div class="container">
          <ng-container *ngIf="entries">
            <div class="cart-item-container">
              <div class="cart-item-left">
                <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
                  <am-cart-item-list
                    [items]="inflightCartEntries"
                    [cartIsLoading]="cartLoaded"
                    [isInflightEnv]="cart.isInflightEnv"
                    [isStoreOpenStatus]="isStoreOpenStatus"
                    [promotionLocation]="promotionLocation"
                    [validationDatas]="cart.validationDatas"></am-cart-item-list>
                </div>
              </div>
              <div class="cart-item-right">
                <am-cart-totals
                  [isStoreOpenStatus]="isStoreOpenStatus"
                  [activeTab]="activeTab"
                  (isStoreOpenStatusOff)="getStoreOpenStatusOff($event)"
                  [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(inflightCartEntries)"></am-cart-totals>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #emptyInflightCart>
        <div class="empty-cart-content">
          <p class="title">
            {{ 'amEmptyCart.emptyCart' | cxTranslate }}
          </p>
          <p class="detail">
            {{ 'amEmptyCart.addItems' | cxTranslate }}
          </p>
          <a aria-label="Continue shopping,Go to home" href="/" class="btn btn-primary">
            {{ 'amEmptyCart.continueShopping' | cxTranslate }}
          </a>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="activeTab !== 'inflight'">
      <ng-container *ngIf="groundCartEntries.length > 0; else emptyGroundCart">
        <am-cart-messages
          [isStoreOpenStatus]="isStoreOpenStatus"
          [activeTab]="activeTab"
          [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(groundCartEntries)"></am-cart-messages>
        <div class="container">
          <ng-container *ngIf="entries">
            <div class="cart-item-container">
              <div class="cart-item-left">
                <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
                  <am-cart-item-list
                    [items]="groundCartEntries"
                    [cartIsLoading]="cartLoaded"
                    [isInflightEnv]="cart.isInflightEnv"
                    [isStoreOpenStatus]="isStoreOpenStatus"
                    [promotionLocation]="promotionLocation"
                    [validationDatas]="cart.validationDatas"></am-cart-item-list>
                </div>
              </div>
              <div class="cart-item-right">
                <am-cart-totals
                  [isStoreOpenStatus]="isStoreOpenStatus"
                  [activeTab]="activeTab"
                  (isStoreOpenStatusOff)="getStoreOpenStatusOff($event)"
                  [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(groundCartEntries)"></am-cart-totals>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #emptyGroundCart>
        <div class="empty-cart-content">
          <p class="title">
            {{ 'amEmptyCart.emptyCart' | cxTranslate }}
          </p>
          <p class="detail">
            {{ 'amEmptyCart.addItems' | cxTranslate }}
          </p>
          <a aria-label="Continue shopping,Go to home" href="/" class="btn btn-primary">
            {{ 'amEmptyCart.continueShopping' | cxTranslate }}
          </a>
        </div></ng-template
      >
    </ng-container>
  </div>
</div>
