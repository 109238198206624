<form (ngSubmit)="onSubmit()" [formGroup]="customerSelectionForm">
  <div class="form-group col-lg-3 col-md-4 col-sm-12">
    <label>
      <input
        #searchTerm
        type="text"
        formControlName="searchTerm"
        placeholder="{{ 'asm.customerSearch.searchTerm.label' | cxTranslate }}" />
      <span class="user-icon">
        <img src="/assets/images/icons/user.svg" />
      </span>
    </label>
    <cx-form-errors [control]="customerSelectionForm.get('searchTerm')"></cx-form-errors>
  </div>

  <div class="form-group col-lg-3 col-md-4 col-sm-12">
    <label>
      <ng-select [items]="carts" bindLabel="code" bindValue="code"> </ng-select>
      <span class="user-icon">
        <img src="/assets/images/icons/cart.svg" />
      </span>
    </label>
  </div>

  <div class="form-group col-lg-3 col-md-4 col-sm-12">
    <button type="submit">
      {{ 'asm.customerSearch.submit' | cxTranslate }}
    </button>
  </div>
</form>

<div *ngIf="searchResults | async as results" class="asm-results" #resultList>
  <button *ngFor="let result of results.entries" (click)="selectCustomerFromList(result)">
    <span class="result-name">{{ result.name }}</span>
    <span class="result-id">{{ result.uid }}</span>
  </button>
  <button
    (click)="closeResults()"
    *ngIf="
      !(searchResultsLoading$ | async) &&
      searchTerm.value.length >= 3 &&
      !!results.entries &&
      results.entries.length <= 0
    ">
    {{ 'asm.customerSearch.noMatch' | cxTranslate }}
  </button>
</div>

<div class="asm-results" *ngIf="searchResultsLoading$ | async">
  <div class="spinner" aria-hidden="false" [attr.aria-label]="'common.loading' | cxTranslate">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
