<div class="arrow"></div>
<div class="tooltip-content">
  <a class="close-btn" *ngIf="displayCloseButton" (click)="close($event)">
    <am-svg-container [type]="svgType.AMCLOSE" [style]="style"></am-svg-container>
  </a>
  <ng-container *ngIf="isTemplate">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
  <span *ngIf="!isTemplate">{{ content }}</span>
</div>
