<div class="am-favorite-notify-switch-tab">
  <p class="title">
    {{ 'amWishProductList.myList' | cxTranslate }}
  </p>
  <div class="tab">
    <a
      href="javascript:void(0)"
      (click)="switchTab(activeTabCodeMap.favourite)"
      [ngClass]="activeTabCode === activeTabCodeMap.favourite ? 'active' : ''">
      {{ 'amWishProductList.favourites' | cxTranslate }}</a
    >
    <a
      *ngIf="disableNotifyTab !== true"
      href="javascript:void(0)"
      (click)="switchTab(activeTabCodeMap.notify)"
      [ngClass]="activeTabCode === activeTabCodeMap.notify ? 'active' : ''">
      {{ 'amWishProductList.notifyMe' | cxTranslate }}</a
    >
  </div>
</div>
