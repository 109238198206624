import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PendingOrderPopupService } from '@components/content/pending-order-popup/pending-order-popup.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { AmLoginService } from '@core/auth/user-auth/facade/am-login.service';
import { AuthRedirectStorageService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { combineLatest, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { FlightInfoService } from '../../../header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '../../../header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { SiteContextSelectorStorageService } from '../../../header/am-site-context/components/site-context-selector-popup/site-context-selector-popup-stroage.service';
import { CountryService } from '../../../header/am-site-context/services/country/country.service';
import { AmLanguageService } from '../../../header/am-site-context/services/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalPopupService {
  isInflightEnv: boolean;
  isBrowser: boolean = false;
  protected subscription: Subscription = new Subscription();
  constructor(
    protected siteContextSelectorStorageService: SiteContextSelectorStorageService,
    protected amConfigService: AmConfigService,
    protected flightStatusStorageSvc: FlightStatusStorageService,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authService: AmAuthService,
    protected pendingOrderPopupService: PendingOrderPopupService,
    protected languageService: AmLanguageService,
    protected countryService: CountryService,
    protected flightInfoService: FlightInfoService,
    protected launchDialogService: AmLaunchDialogService,
    private amLoginService: AmLoginService,
    protected winRef: WindowRef,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.subscription.add(
      this.flightInfoService.timeOut.subscribe((item) => {
        if (item) {
          this.checkIfDisplaySiteContextPopup();
        }
      })
    );
  }

  checkIfDisplayPopup() {
    this.checkIfDispalyPendingPopup();
    this.checkIfDisplaySiteContextPopup();
  }

  checkIfDispalyPendingPopup() {
    this.subscription.add(
      this.authService
        .isUserLoggedIn()
        .pipe(
          filter(Boolean),
          tap(() => this.authRedirectStorageService.getRedirectUrl()),
          take(1)
        )
        .subscribe((redirectUrl: string) => {
          const smsVerifyPatten = /^[\s\S]*(my-account\/order\/D\d{8}\/review)/;
          if (smsVerifyPatten.test(redirectUrl)) {
            this.winRef.localStorage?.setItem('pendingPopup', 'true');
            return;
          }
          this.subscription.add(this.pendingOrderPopupService.showCustomerPendingPopup().subscribe());
        })
    );
  }

  checkIfDisplaySiteContextPopup(): void {
    if (!this.isBrowser) {
      return;
    }
    this.subscription.add(
      this.amConfigService
        .getIsInflightEnv()
        .pipe(
          // take(1),
          distinctUntilChanged(),
          map((item) => {
            this.isInflightEnv = item.data;
            let dialogKey = null;
            if (!this.isInflightEnv && this.siteContextSelectorStorageService.isFirstVisit()) {
              dialogKey = LAUNCH_CALLER.SELECT_SITE_CONTEXT;
            } else if (this.isInflightEnv && !this.flightStatusStorageSvc.getInFlightIndicator()) {
              dialogKey = LAUNCH_CALLER.INFLIGHT_SELECT_SITE_CONTEXT;
            }
            return dialogKey;
          }),
          switchMap((dialogKey) => {
            if (!!dialogKey) {
              return combineLatest([
                this.languageService.getSortedLanguage(),
                this.countryService.getFormattedCountries(),
                this.amLoginService.loginProgress$
              ]).pipe(
                filter(
                  ([lanugaes, countries, loginInProgress]) => !!lanugaes && !!countries && loginInProgress !== true
                ),
                take(1),
                tap(([languages, countries]) => {
                  const dialog = this.launchDialogService.openDialog(dialogKey, undefined, undefined, {
                    languages,
                    countries
                  });
                  if (dialog) {
                    this.subscription.add(dialog.pipe(take(1)).subscribe());
                  }
                })
              );
            }
            return of(null);
          })
        )
        .subscribe()
    );
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
