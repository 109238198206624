import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CmsService,
  EventService,
  OccEndpointsService,
  RoutingService,
  TranslationService,
  WindowRef
} from '@spartacus/core';
import * as dayjs from 'dayjs';
import { Subscription, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { AmCartService } from '../../pages/cart/service/am-cart.service';
import { UpdateDataLayerService } from './adobe/updateDatalayer.service';
import { DataLayerSourceService } from './dataSource.service';
import { UpdateCxDataLayerService } from './tealium/updateCxDatalayer.service';
import { TealiumUtagService } from './tealium/utag.service';

@Injectable({
  providedIn: 'root'
})
export class CxDataLayerService {
  window = (this.winRef.nativeWindow as any) ?? {};
  prevPageType = '';
  isLogin: boolean = false;
  subScribe: Subscription;
  dataSoruceConfig: any;
  env: string;
  dateFormat: string;
  activeCartSubscription: Subscription;
  subscription = new Subscription();
  constructor(
    protected tealiumUtagService: TealiumUtagService,
    protected httpClient: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected winRef: WindowRef,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected dataLayerSourceService: DataLayerSourceService,
    private cms: CmsService,
    protected activeCartService: ActiveCartFacade,
    protected amCartService: AmCartService,
    protected updateCxDataLayerSvc: UpdateCxDataLayerService,
    protected updateAdobeDataLayerSvc: UpdateDataLayerService,
    protected amConfigService: AmConfigService,
    protected router: Router,
    private translate: TranslationService
  ) {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.dataSoruceConfig = this.dataLayerSourceService.getDataSourceConfig();
    this.subscription.add(
      this.translate.translate('amFormat.time.pattern.normal').subscribe((val) => {
        this.dateFormat = val;
      })
    );
    this.listenOnAddToCartEvent();
  }

  listenPageChange(): void {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(
          filter((router) => router.nextState === undefined),
          map((routingData) => {
            const id = routingData.state.context.id;
            const pageType = routingData.state.semanticRoute;
            const url = routingData.state.url;
            const query = routingData.state.params['query'];
            const pageObject = {
              contextId: id,
              url,
              query,
              pageType
            };
            return pageObject;
          }),
          // distinctUntilKeyChanged('contextId'),
          distinctUntilChanged((pre, cur) => {
            return pre.url === cur.url && pre.query === cur.query;
          }),
          map((pageObject) => {
            if (!!pageObject.pageType && pageObject.pageType !== 'orderDetails') {
              this.loadDataLayer(pageObject.pageType, pageObject.contextId);
              return null;
            }
            return pageObject;
          }),
          switchMap((pageObject) => {
            if (pageObject) {
              // page not found，orderDetails ,campaign，  pageType need to  be reassign
              return this.cms.getCurrentPage().pipe(
                distinctUntilKeyChanged('pageId'),
                take(1),
                map((data) => {
                  let _pageObject = pageObject;
                  _pageObject.pageType = data.pageId;
                  if (this.winRef.location?.pathname?.indexOf('campaign') !== -1) {
                    _pageObject.pageType = data.pageId === 'pagenotfound' ? 'notFound' : 'campaign';
                  }
                  this.loadDataLayer(_pageObject.pageType);
                  return _pageObject;
                })
              );
            }
            return of(pageObject);
          })
        )
        .subscribe()
    );
  }

  loadDataLayer(pageType: string, contextId?: string): void {
    const commonSourceKeys = this.dataLayerSourceService.commonSourceKeys;
    let currentPageDataSourceConfig = null;
    if (pageType === 'category') {
      currentPageDataSourceConfig = this.dataLayerSourceService.getCategorySource(contextId);
    } else {
      currentPageDataSourceConfig = this.dataSoruceConfig[pageType];
    }
    if (currentPageDataSourceConfig) {
      const transformObject = {};
      const sources$ = currentPageDataSourceConfig.source$?.map((source) => {
        if (typeof source === 'function') {
          source = source();
        }
        return source;
      });
      this.subScribe && this.subScribe.unsubscribe();
      this.subScribe = combineLatest([...sources$])
        .pipe(
          filter((arr) => !!arr && Object.keys(arr[arr.length - 1]).length > 0),
          distinctUntilChanged((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)),
          take(1)
        )
        .subscribe((arr) => {
          for (let i = 0; i < commonSourceKeys.length; i++) {
            transformObject[commonSourceKeys[i]] = arr[i];
          }
          if (currentPageDataSourceConfig.dataKey && arr.length > commonSourceKeys.length) {
            transformObject[currentPageDataSourceConfig.dataKey] = arr[commonSourceKeys.length];
          }
          this.updateCxDataLayerSvc.updateCommonPageInfo(pageType, transformObject);
          this.updateAdobeDataLayerSvc.updateCommonPageInfo(transformObject);
          if (currentPageDataSourceConfig.callback) {
            currentPageDataSourceConfig.callback(transformObject, pageType);
          }
          if (currentPageDataSourceConfig.dataKey) {
            Object.keys(arr[commonSourceKeys.length]).length > 0 &&
              this.tealiumUtagService.view(this.window.cxDataLayer);
          } else {
            this.tealiumUtagService.view(this.window.cxDataLayer);
          }
        });
    }
  }

  listenOnAddToCartEvent() {
    this.subscription.add(
      this.amCartService
        .getAddToCartResult()
        .pipe(
          filter((data) => data && !!data.totalUnitCount),
          distinctUntilChanged((prev, cur) => prev.totalUnitCount === cur.totalUnitCount)
        )
        .subscribe((result) => {
          this.tealiumUtagService.link(this.updateCxDataLayerSvc.updateAddToCartEvent(result));
        })
    );
  }

  loadEventDataLayer(eventType, parameter?): void {
    if (eventType === 'MEMBER_VERIFICATION_ERROR') {
      this.updateCxDataLayerSvc.updateMemberVerificationError(parameter);
      this.tealiumUtagService.view(this.window.cxDataLayer);
    } else if (eventType === 'ORDER_HISTORY') {
      let orderList = [];
      parameter.forEach((order) => {
        orderList.push({
          order_date: dayjs(order.placed).format(this.dateFormat),
          order_number: order.code,
          order_value_total_cash: order.totalPriceCash.toFixed(2),
          order_value_total_miles: order.totalMiles
        });
      });
      this.window.cxDataLayer.orders = this.window.cxDataLayer.orders?.concat(orderList);
      this.tealiumUtagService.view(this.window.cxDataLayer);
    } else if (eventType === 'SEND_OTP') {
      this.tealiumUtagService.link(this.updateCxDataLayerSvc.updatePaymentVerificationOTP());
    } else if (eventType === 'PAYMENT_VERIFICATION_ERROR') {
      this.updateCxDataLayerSvc.updatePaymentVerificationError(parameter);
      this.tealiumUtagService.view(this.window.cxDataLayer);
    }
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subScribe?.unsubscribe();
  }
}
