import { Component } from '@angular/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { AmAddToCartService } from 'src/app/amiredeem/pages/product-detail/component/add-to-cart/add-to-cart-dialog/add-to-cart.service';
import { OrderSummaryNoticeService } from '../../../../pages/checkout/components/am-inflight-pickup-order-confirmation/order-summary-notice/order-summary-notice.service';
import { AmlHeaderService } from '../service/aml-header.service';
import { AmMiniCartComponentService } from './mini-cart-component.service';

@Component({
  selector: 'am-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss']
})
export class AmMiniCartComponent {
  iconTypes = ICON_TYPE;

  quantity$: Observable<number> = this.miniCartComponentService.getQuantity();
  isShowCartNotice$ = this.amAddToCartService.isShow();
  isShowOrderSummaryNotice$ = this.orderSummaryNotice.isShow();
  constructor(
    public miniCartComponentService: AmMiniCartComponentService,
    // public amCartService: AmCartService,
    // protected activeCartService: ActiveCartFacade,
    private amlHeaderService: AmlHeaderService,
    private amAddToCartService: AmAddToCartService,
    private orderSummaryNotice: OrderSummaryNoticeService
  ) {}
  protected subscription = new Subscription();
  reloadCart() {
    this.amAddToCartService.close();
    this.amlHeaderService.isShowAccountInfo.next(false);
    // this.activeCartService
    //   .getActiveCartId()
    //   .pipe(
    //     take(1),
    //     switchMap((d) => {
    //       if (d) {
    //         this.activeCartService.reloadActiveCart();
    //         return of(null);
    //       } else {
    //         return this.activeCartService.requireLoadedCart();
    //       }
    //     })
    //   )
    //   .subscribe();
  }
}
