import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { MilesEarnDialogComponent } from './miles-earn-dialog.component';

@NgModule({
  imports: [CommonModule, IconModule, I18nModule, SafeCodeModule, KeyboardFocusModule],
  declarations: [MilesEarnDialogComponent],
  exports: [MilesEarnDialogComponent]
})
export class MilesEarnDialogModule {}
