import { ModuleWithProviders, NgModule } from '@angular/core';
import { AmConsentsStoreModule } from './store/am-consents-store.module';

@NgModule({
  imports: [AmConsentsStoreModule]
})
export class AmConsentsModule {
  static forRoot(): ModuleWithProviders<AmConsentsModule> {
    return {
      ngModule: AmConsentsModule,
      providers: []
    };
  }
}
