export enum ToolTipEvent {
  INSIDE_CLICK = 'insideClick',
  OUTSIDE_CLICK = 'outsideClick',
  ESCAPE_KEYDOWN = 'escapeKeydown',
  CLOSE_BUTTON_CLICK = 'closeButtonClick',
  CLOSE_BUTTON_KEYDOWN = 'closeButtonKeydown',
  ROUTE_CHANGE = 'routeChange',
  OPEN = 'open',
  OPEN_BY_KEYBOARD = 'openByKeyboard'
}

export type HorizontalAlign = 'left' | 'right' | 'center';

export interface ToolTipOptions {
  parentElement?: HTMLElement;
  horizontalAlign?: HorizontalAlign;
  displayCloseButton?: boolean;
  className?: string;
}
