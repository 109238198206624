<div class="am-vera-chat is-active">
  <div class="animate-wrapper btn-chat-bot">
    <!--<ng-container>
      <div id="cf-chat-window-popup" class="show">
        <div id="cf-chat-window-drag-handle"></div>
        <iframe id="cf-chat-window-wrapper" [src]="veraChatUrl | safeUrl" allowtransparency="false" frameborder="0">
        </iframe>
      </div>
    </ng-container>-->
    &nbsp;
  </div>
</div>
