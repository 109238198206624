<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header d-flex">
      <div class="cx-dialog-title modal-title promtion-title-left" aria-live="polite" aria-atomic="true">
        {{ 'amCartDetails.summary.detailsDeliveryFeeLabel' | cxTranslate }}
      </div>
      <button aria-label="close popup" type="button" class="close-popup-btn link" (click)="closeModal('Cross click')">
        <am-svg-container [type]="svgType.AMCLOSE" [style]="style"></am-svg-container>
      </button>
    </div>
    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <div class="wrapper">
        <div class="home-delivery">
          <p class="headline">
            {{ 'amCartDetails.deliveryFee.homeDelivery' | cxTranslate }}
          </p>
          <ng-container
            *ngIf="
              flatDeliverFeeObject?.flatRateProgressBarData?.cashThreshold > 0 ||
              flatDeliverFeeObject?.flatRateProgressBarData?.milesThreshold > 0
            ">
            <div
              class="content"
              *ngIf="
                flatDeliverFeeObject?.flatRateProgressBarData?.cashThreshold > 0 &&
                flatDeliverFeeObject?.flatRateProgressBarData?.milesThreshold > 0
              ">
              <ul>
                <!-- [innerHTML]="'amMinilogin.userGreeting' | cxTranslate: { name: user.name }" -->
                <li
                  [innerHTML]="
                    'amCartDetails.deliveryFee.freeDeliveryTip'
                      | cxTranslate
                        : {
                            currency: flatDeliverFeeObject.cashCurrency,
                            freeFeeCashThreshold:
                              flatDeliverFeeObject.flatRateProgressBarData.cashThreshold | number : '1.0',
                            freeFeeMilesThreshold:
                              flatDeliverFeeObject.flatRateProgressBarData.milesThreshold | number : '1.0'
                          }
                  "></li>

                <li
                  *ngIf="!flatDeliverFeeObject.deliveryFeeCashOrMilesToggle"
                  [innerHTML]="
                    'amCartDetails.deliveryFee.deliveryCashCostTip'
                      | cxTranslate
                        : {
                            currency: flatDeliverFeeObject.cashCurrency,
                            deliveryFee: flatDeliverFeeObject.cashDeliveryFee,
                            freeFeeCashThreshold:
                              flatDeliverFeeObject.flatRateProgressBarData.cashThreshold | number : '1.0',
                            freeFeeMilesThreshold:
                              flatDeliverFeeObject.flatRateProgressBarData.milesThreshold | number : '1.0'
                          }
                  "></li>

                <li
                  *ngIf="flatDeliverFeeObject.deliveryFeeCashOrMilesToggle"
                  [innerHTML]="
                    'amCartDetails.deliveryFee.deliveryMilesCostTip'
                      | cxTranslate
                        : {
                            currency: flatDeliverFeeObject.cashCurrency,
                            deliveryFee: flatDeliverFeeObject.milesDeliveryFee,
                            freeFeeCashThreshold:
                              flatDeliverFeeObject.flatRateProgressBarData.cashThreshold | number : '1.0',
                            freeFeeMilesThreshold:
                              flatDeliverFeeObject.flatRateProgressBarData.milesThreshold | number : '1.0'
                          }
                  "></li>
              </ul>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              flatDeliverFeeObject?.flatRateProgressBarData?.cashThreshold <= 0 &&
              flatDeliverFeeObject?.flatRateProgressBarData?.milesThreshold <= 0
            ">
            <div class="content">
              <p>
                {{ 'amCartDetails.deliveryFee.freeDeliveryFeeTip' | cxTranslate }}
              </p>
            </div>
          </ng-container>
        </div>
        <div class="shop-pickup">
          <p class="headline">
            {{ 'amCartDetails.deliveryFee.inShopPick' | cxTranslate }}
          </p>
          <div class="content">
            <p>{{ 'amCartDetails.deliveryFee.shopPickTip' | cxTranslate }}</p>
          </div>
        </div>

        <div class="email-delivery">
          <p class="headline">
            {{ 'amCartDetails.deliveryFee.emailDelivery' | cxTranslate }}
          </p>
          <div class="content">
            <p>
              {{ 'amCartDetails.deliveryFee.emailTip' | cxTranslate }}
            </p>
          </div>
        </div>
        <!-- <div class="more-info">
          <p [innerHTML]="'amCartDetails.deliveryFee.moreInfo' | cxTranslate | safeHtml"></p>
        </div> -->
      </div>
    </div>
  </div>
</div>
