import { InjectionToken } from '@angular/core';

export const SITE_CONTEXT_FROM_COOKIE = new InjectionToken<{ country: string; language: string }>(
  'SiteContextFromCookie'
);

export const CLIENT_IP = new InjectionToken<string>('ClientIp');

export const INFLIGHT_POS_CODE = new InjectionToken<{ posCode: string }>('InflightPosCode');

export const AM_LOGGED = new InjectionToken<string>('amLogged');
