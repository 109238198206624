import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  constructor(protected winRef: WindowRef) {}

  updateLiveChatLanguage(activeLanguage) {
    // @ts-ignore
    if (typeof (window as any).zE === 'function') {
      let liveCharLanguage = activeLanguage;
      if (activeLanguage === 'zh_TW') {
        liveCharLanguage = 'zh-tw';
      } else if (activeLanguage === 'zh') {
        liveCharLanguage = 'zh-cn';
      }
      (window as any).zE('webWidget', 'setLocale', liveCharLanguage);
    }
  }

  // updateLiveChatPosisitionByCookie(isAccept?: boolean) {
  //   isAccept = isAccept ?? this.window.amlcc.isAllowed;
  //   if (this.window.$zopim) {
  //     setTimeout(() => {
  //       this.setLiveChatPosition(isAccept);
  //       this.toggleResizeEvent(isAccept);
  //     }, 2000);
  //   } else {
  //     setTimeout(() => {
  //       if (this.window.$zopim) {
  //         this.setLiveChatPosition();
  //         this.toggleResizeEvent(isAccept);
  //       }
  //     }, 2000);
  //   }
  // }

  // setLiveChatPosition(isAccept?: boolean) {
  //   this.liveChatDom = this.liveChatDom ?? this.winRef.document?.getElementById('launcher');
  //   if (isAccept) {
  //     // comment for this api doesn't work for the mobile icon
  //     // this.window.$zopim.livechat.window.setOffsetVertical(0);
  //     this.liveChatDom.style.bottom = '0px';
  //   } else {
  //     const cookiebar = this.winRef.document?.getElementById('amlcc-warp');
  //     const height = !!cookiebar ? this.winRef.document?.getElementById('amlcc-warp').clientHeight : 0;
  //     // this.window.$zopim.livechat.window.setOffsetVertical(height);
  //     this.liveChatDom.style.bottom = `${height}px`;
  //   }
  // }

  // toggleResizeEvent(isAccept: boolean) {
  //   if (!isAccept && !this.resizeSubscription) {
  //     this.resizeSubscription = this.winRef.resize$.subscribe(() => {
  //       this.setLiveChatPosition();
  //     });
  //   } else {
  //     this.resizeSubscription && this.resizeSubscription.unsubscribe();
  //   }
  // }

  // OnDestroy() {
  //   this.resizeSubscription && this.resizeSubscription.unsubscribe();
  // }
}
