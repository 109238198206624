import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MultiCartSelectors, MultiCartService } from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';
import { UserIdService } from '@spartacus/core';
import { ProcessesLoaderState } from '@spartacus/core/src/state/utils/processes-loader';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CartActions } from '../store';

@Injectable({
  providedIn: 'root'
})
export class AmMultiCartService extends MultiCartService {
  private changeRemove = new BehaviorSubject<boolean>(false);
  public removeSuccess$ = this.changeRemove.asObservable();
  protected subscription = new Subscription();
  constructor(protected store: Store<any>, protected userIdService: UserIdService) {
    super(store, userIdService);
  }

  // move to am-cart
  // updateMile(userId: string, cartId: string, mile: number): void {
  //   this.store.dispatch(new AmCartActions.CartMile({ userId, cartId, mile }));
  // }

  getCartEntity(cartId: string): Observable<ProcessesLoaderState<Cart>> {
    // if(!!UrlHelper.getSearchParamByName("cartRemoved") && cartId !== OCC_CART_ID_CURRENT) {
    //   this.userIdService.takeUserId().subscribe((userId) => {
    //     this.store.dispatch(
    //       new CartActions.DeleteCartSuccess({
    //         userId,
    //         cartId,
    //       })
    //     );
    //   })
    //   return of({
    //     error: false,
    //     loading: false,
    //     processesCount: 0,
    //     success: true,
    //     value: undefined
    //   })
    // }
    return this.store.pipe(select(MultiCartSelectors.getCartEntitySelectorFactory(cartId)));
  }

  // toggleDeliveryFee(cartId: string, userId: string, isOpen: boolean): void {
  //   this.store.dispatch(
  //     new AmCartActions.CartToggleDelivery({
  //       cartId,
  //       userId,
  //       isOpen
  //     })
  //   );
  // }

  // toggleFcom(cartId: string, userId: string, isOpen: boolean): void {
  //   this.store.dispatch(
  //     new AmCartActions.CartToggleFcom({
  //       cartId,
  //       userId,
  //       isOpen
  //     })
  //   );
  // }

  /**
   * Update entry in cart. For quantity = 0 it removes entry
   *
   * @param userId
   * @param cartId
   * @param entryNumber
   * @param quantity
   */
  updateEntry(userId: string, cartId: string, entryNumber: number, quantity: number): void {
    if (quantity > 0) {
      this.store.dispatch(
        new CartActions.CartUpdateEntry({
          userId,
          cartId,
          entryNumber: `${entryNumber}`,
          quantity: quantity
        })
      );
    } else {
      this.removeEntry(userId, cartId, entryNumber);
    }
  }

  /**
   * Remove entry from cart
   *
   * @param userId
   * @param cartId
   * @param entryNumber
   */
  removeEntry(userId: string, cartId: string, entryNumber: number): void {
    this.store.dispatch(
      new CartActions.CartRemoveEntry({
        userId,
        cartId,
        entryNumber: `${entryNumber}`
      })
    );
  }

  /**
   * Remove mutilple entry from cart
   *
   * @param userId
   * @param cartId
   * @param entryNumber
   */
  removeEntries(userId: string, cartId: string, entryNumber: Array<number>): void {
    this.store.dispatch(
      new CartActions.CartRemoveEntries({
        userId,
        cartId,
        entriesNumber: entryNumber
      })
    );
  }

  /**
   * update cart by add to cart
   *
   * @param userId
   * @param cartId
   * @param cart
   */
  cartUpdateByAddToCart(userId: string, cartId: string, cart: Cart): void {
    this.store.dispatch(
      new CartActions.CartUpdateByAddToCart({
        userId,
        cartId,
        cart: cart
      })
    );
  }

  /**
   * clean cart
   * @param userId
   * @param cartId
   */
  cartClean(userId: string, cartId: string): void {
    this.subscription.add(
      this.getCart(cartId)
        .pipe(
          take(1),
          map((v) => {
            return { ...v, totalUnitCount: 0 };
          })
        )
        .subscribe((cart) => {
          this.cartUpdateByAddToCart(userId, cartId, cart);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
