import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { SpaceComponent } from './space.component';

@NgModule({
  declarations: [SpaceComponent],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    AmMediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SpaceComponent: {
          // typeCode
          component: SpaceComponent
        }
      }
    } as CmsConfig)
  ]
})
export class SpaceModule {}
