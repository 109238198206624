<a [routerLink]="{ cxRoute: 'cart' } | cxUrl" (click)="reloadCart()" class="am-mini-cart">
  <!-- <img src="/assets/images/icons/icon_shopping_car.svg" alt="Mini cart" /> -->
  <!-- <span class="total" *ngIf="total$ | async as quantity">
    {{ total }}
  </span> -->

  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-9 -6 50 50"
    xml:space="preserve">
    <style type="text/css">
      .st0 {
        fill: none;
        stroke: #4d4c4d;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st1 {
        fill: none;
        stroke: #4d4c4d;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
    </style>
    <g id="Guides"></g>
    <g id="Icons">
      <g>
        <polyline class="st0" points="6.7,8.75 9.98,8.75 12.44,20.51 21.47,20.51 		" />
        <polyline class="st1" points="10.25,10.67 25.3,10.67 23.11,17.78 12.17,18.87 		" />
        <circle class="st1" cx="21.47" cy="21.88" r="1.37" />
        <circle class="st1" cx="12.17" cy="21.88" r="1.37" />
      </g>
    </g>
  </svg>
  <span class="is-visually-hidden">Mini cart</span>
  <span class="quantity" *ngIf="quantity$ | async as quantity">
    {{ quantity }}
  </span>
</a>

<am-add-to-cart-dialog *ngIf="isShowCartNotice$ | async as isShowCartNotice"></am-add-to-cart-dialog>
<order-summary-notice
  [entryNumber]="orderSummaryNotice.entryNumber"
  *ngIf="isShowOrderSummaryNotice$ | async as isShowOrderSummaryNotice"></order-summary-notice>
