import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { ChangeFlightConfirmationPopupModule } from '../../../change-flight-confirmation-popup/change-flight-confirmation-popup.module';
import { CountryService } from '../../services/country/country.service';
import { HeaderSiteContentSelectorComponent } from './header-site-context-selector.component';

@NgModule({
  declarations: [HeaderSiteContentSelectorComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    I18nModule,
    SvgContainerModule,
    ChangeFlightConfirmationPopupModule,
    ConfigModule.withConfig({
      cmsComponents: {
        DisplayDeliveryZoneComponent: {
          // typeCode
          component: HeaderSiteContentSelectorComponent
        }
      }
    } as CmsConfig)
  ],
  providers: [CountryService]
})
export class AmHeaderSiteContentSelectorModule {}
