import { Injectable } from '@angular/core';
import { ProductSearchPage, WindowRef } from '@spartacus/core';
import UrlHelper from '@utils/url-helper';
import * as dayjs from 'dayjs';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { ProductDetailService } from 'src/app/amiredeem/pages/product-detail/service/product.service';

export const pageNamePrefix = 'am:LifestyleAwards';
@Injectable({
  providedIn: 'root'
})
export class UpdateDataLayerService {
  window = (this.winRef.nativeWindow as any) ?? {};
  device = '';

  constructor(
    protected winRef: WindowRef,
    protected amConfigService: AmConfigService,
    protected productDetailService: ProductDetailService
  ) {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.initEnvData();
    this.updateSearchDataLayer = this.updateSearchDataLayer.bind(this);
    this.updatePLPDataLayer = this.updatePLPDataLayer.bind(this);
    this.updatePDPDataLayer = this.updatePDPDataLayer.bind(this);
    this.updateCartDataLayer = this.updateCartDataLayer.bind(this);
    this.updateCheckoutDataLayer = this.updateCheckoutDataLayer.bind(this);
    this.updateOrderConfrimationDataLayer = this.updateOrderConfrimationDataLayer.bind(this);
    this.updateOrderHistoryDataLayer = this.updateOrderHistoryDataLayer.bind(this);
    this.updatePageErrorDataLayer = this.updatePageErrorDataLayer.bind(this);
    this.updateOrderConfrimationDataLayer = this.updateOrderConfrimationDataLayer.bind(this);
    this.updateCartCdsData = this.updateCartCdsData.bind(this);
  }

  initEnvData(): void {
    this.device = this.getDevice();
  }

  getDevice() {
    const agent = this.window.navigator?.userAgent.toLowerCase();
    if (/windows/.test(agent)) {
      return 'web';
    } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
      return 'ios';
    } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
      return 'ios';
    } else if (/android/.test(agent) && /mobile/.test(agent)) {
      return 'android';
    } else if (/linux/.test(agent)) {
      return 'web';
    } else if (/mac/.test(agent)) {
      return 'web';
    } else {
      return 'other';
    }
  }

  updateCommonPageInfo(data): void {
    const pageInfo = {
      page_country: data.country,
      page_language: data.language,
      member_country: data.country,
      member_language: data.language,
      page_locale: data.language + '_' + data.country,
      member_miles_balance: data.userService?.customerRecord?.account ?? '',
      member_hash_ID: data.userService?.hashedMemberID ?? '',
      member_type: data.userService?.customerRecord?.tierCode ?? '',
      page_timestamp_local: new Date().toISOString(),
      page_environment: this.device
    };
    const cdsDataInfo = {
      page_session_id: '',
      page_name: pageNamePrefix + ':' + this.winRef.document?.title
    };
    this.window.searchEngine = {};
    this.window.cdsData = cdsDataInfo;
    this.window.dataLayer = { ...cdsDataInfo, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updateSearchDataLayer(data): void {
    const searchText = this.replaceQuotationMarks(data.search?.freeTextSearch);
    const pageInfo = {
      page_name: `${pageNamePrefix}:Search ${searchText} | Online Shopping - Cathay Pacific`,
      product_geo_filter: data.language + '_' + data.country,
      product_keyword_filter: searchText
    };
    const cdsDataInfo = {
      page_name: pageInfo.page_name
    };
    this.window.cdsData = cdsDataInfo;
    this.window.dataLayer = { ...this.window.dataLayer, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updatePLPDataLayer(data: { plp: ProductSearchPage; language: string; country: string }): void {
    const searchObject: any = UrlHelper.parseQueryStringToObject(this.winRef.location.href);
    const { plp: { queryResult, breadcrumbs } = {} } = data;
    const pageInfo: any = {
      page_name: pageNamePrefix + this.replaceQuotationMarks(data.plp.responseInfo.pageTitle),
      product_geo_filter: data.language + '_' + data.country,
      product_sortby_filter: searchObject.sortCode ?? 'onlineDate-asc',
      product_miles_filter: searchObject.query?.includes('zonePrice')
        ? queryResult.currentMinMiles + '_' + queryResult.currentMaxMiles
        : ''
    };
    if (breadcrumbs?.length > 0) {
      let brandNameStr = '';
      let categoryStr = '';
      breadcrumbs.forEach((bread) => {
        if (bread.facetCode === 'brandName') {
          brandNameStr += bread.facetValueName + '|';
        }
        if (bread.facetCode.includes('categoryLevel')) {
          categoryStr += bread.facetValueName + '|';
        }
      });
      pageInfo.product_brand_filter = brandNameStr.substring(0, brandNameStr.length - 1);
      pageInfo.product_category_filter = categoryStr.substring(0, categoryStr.length - 1);
    }
    const cdsDataInfo = {
      page_name: pageInfo.page_name
    };
    this.window.cdsData = cdsDataInfo;
    this.window.dataLayer = { ...this.window.dataLayer, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updateCartDataLayer(data): void {
    const { activeCart } = data;
    let cart_promo_code = '';
    let cart_promo_code_discount = 0;
    if (activeCart.appliedVouchers?.length > 0) {
      cart_promo_code = activeCart.appliedVouchers[0].code;
    }
    activeCart?.appliedProductPromotions?.forEach((p) => {
      p.consumedEntries?.forEach((c) => {
        cart_promo_code_discount = cart_promo_code_discount + c.discountUnitPrice;
      });
    });
    const products = [];
    activeCart?.entries?.forEach((e) => {
      const variance = this.getProductVariance(e.product);
      products.push({
        product_name: this.replaceQuotationMarks(e.product?.nameEn) ?? '',
        product_brand: this.replaceQuotationMarks(e.product?.brandNameEn).toUpperCase() ?? '',
        product_cash: e.totalPriceCash.value,
        product_miles: e.totalPrice.value,
        product_instant_discount: this.replaceQuotationMarks(e.product.discountRows[0]?.description ?? ''),
        product_category_master: this.replaceQuotationMarks(e.product.masterCategoryName),
        product_category_supercategory: this.replaceQuotationMarks(this.getSupercategory(e.product.categories)),
        product_partner: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
        product_date: this.getProductDate(e.product),
        product_variance1: this.replaceQuotationMarks(variance.get('variance_1')),
        product_variance2: this.replaceQuotationMarks(variance.get('variance_2')),
        product_package_code: this.replaceQuotationMarks(e.product?.code) ?? '',
        product_quantity: this.replaceQuotationMarks(e.quantity + ''),
        product_currency: this.replaceQuotationMarks(e.product?.currency)
      });
    });

    const { responseInfo: { pageTitle } = {} as any } = activeCart;
    const pageInfo = {
      page_name: pageTitle ? pageNamePrefix + this.replaceQuotationMarks(pageTitle) : this.window.dataLayer.page_name,
      cart_promo_code: this.replaceQuotationMarks(cart_promo_code),
      cart_promo_code_discount: cart_promo_code_discount > 0 ? cart_promo_code_discount : '',
      cart_total_miles: activeCart?.totalPrice?.value,
      cart_total_cash: activeCart?.totalPriceCash?.value,
      products: products
    };
    this.window.cdsData = {
      page_name: pageInfo.page_name
    };
    this.updateCartCdsData(data);
    this.window.dataLayer = { ...this.window.contextData, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updatePDPDataLayer(data): void {
    let pageInfo = {};
    const variance = this.getProductVariance(data.pdpData);
    const baseProductInfo = {
      page_name: pageNamePrefix + this.replaceQuotationMarks(data.pdpData.responseInfo?.pageTitle),
      product_brand: this.replaceQuotationMarks(data.pdpData?.brandNameEn)?.toUpperCase() ?? '',
      product_delivery: this.replaceQuotationMarks(data.pdpData.collectionMethod),
      product_partner: this.replaceQuotationMarks(data.pdpData?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
      product_tagA: this.replaceQuotationMarks(data.pdpData.productTagGroupA?.tagName),
      product_tagB: this.replaceQuotationMarks(data.pdpData.productTagGroupB?.name),
      product_package_code: this.replaceQuotationMarks(data.pdpData?.code) ?? ''
    };
    if (data.pdpData.externalRedirect == '') {
      pageInfo = {
        ...baseProductInfo,
        product_name: this.replaceQuotationMarks(data.pdpData?.nameEn) ?? '',
        product_miles: data.pdpData.maxMilesSpend,
        product_quantity: '1',
        product_currency: data.pdpData?.currency,
        product_category_master: this.replaceQuotationMarks(data.pdpData.masterCategoryName),
        product_category_supercategory: this.replaceQuotationMarks(this.getSupercategory(data.pdpData.categories)),
        product_date: this.getProductDate(data.pdpData),
        product_variance1: this.replaceQuotationMarks(variance.get('variance_1')),
        product_variance2: this.replaceQuotationMarks(variance.get('variance_2'))
      };
    } else {
      pageInfo = {
        ...baseProductInfo,
        product_name: '',
        product_miles: '',
        product_quantity: '',
        product_currency: ''
      };
    }
    this.window.dataLayer = { ...this.window.contextData, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
    this.updateProductCdsData(data);
    this.updateProductsearchEngine(data);
  }

  updateOrderConfrimationDataLayer(data): void {
    if (!data) {
      return;
    }
    const products = [];
    let cart_promo_code = '';
    if (data.order.orderData.appliedVouchers?.length !== 0) {
      cart_promo_code = data.order.orderData.appliedVouchers[0];
    }
    data.order?.orderData?.entries?.forEach((e) => {
      const variance = this.getProductVariance(e.product);
      products.push({
        product_name: this.replaceQuotationMarks(e.product?.nameEn) ?? '',
        product_brand: this.replaceQuotationMarks(e.product?.brandNameEn).toUpperCase() ?? '',
        product_cash: e.totalPriceCash.value,
        product_miles: e.totalPrice.value,
        product_category_master: this.replaceQuotationMarks(e.product.masterCategoryName),
        product_category_supercategory: this.replaceQuotationMarks(this.getSupercategory(e.product.categories)),
        product_partner: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
        product_date: this.getProductDate(e.product),
        product_variance1: this.replaceQuotationMarks(variance.get('variance_1')),
        product_variance2: this.replaceQuotationMarks(variance.get('variance_2')),
        product_package_code: this.replaceQuotationMarks(e.product?.code) ?? '',
        product_quantity: this.replaceQuotationMarks(e.quantity + ''),
        product_currency: this.replaceQuotationMarks(e.product?.currency)
      });
    });
    const pageInfo = {
      page_name: `${pageNamePrefix}:Order confirmation page | Online Shopping - Cathay Pacific`,
      order_number: this.replaceQuotationMarks(data.order.orderData.code),
      order_email_delivery: data.order.orderData.deliveryAddress.needEmailDelivery == true ? 'Y' : 'N',
      order_delivery_country: data.country,
      cart_total_miles: data.order.orderData.totalPrice.value,
      cart_total_cash: data.order.orderData.totalPriceCash.value,
      cart_delivery_address: data.order.orderData.deliveryAddress.needPhysicalDelivery == true ? 'Y' : 'N',
      cart_promo_code: this.replaceQuotationMarks(cart_promo_code),
      cart_discount_total: this.replaceQuotationMarks(data.order.orderData.totalDiscounts?.value),
      products: products
    };
    this.window.cdsData = {
      page_name: pageInfo.page_name
    };
    this.window.dataLayer = { ...this.window.contextData, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updateOrderHistoryDataLayer(data): void {
    let orders = [];
    data.orderList?.orders.forEach((order) => {
      orders.push({
        order_date: dayjs(order.placed).format('D/MM/YYYY'),
        order_number: this.replaceQuotationMarks(order.code),
        order_value_total_miles: order.totalMiles,
        order_value_total_cash: order.totalPriceCash
      });
    });
    this.window.dataLayer.page_name = `${pageNamePrefix}:Order History | Online Shopping - Cathay Pacific`;
    this.window.dataLayer.orders = orders;
    this.window.contextData = this.window.dataLayer;
  }

  updateCheckoutDataLayer(data): void {
    const products = [];
    const { activeCart = {} } = data;
    activeCart?.entries?.forEach((e) => {
      const variance = this.getProductVariance(e.product);
      products.push({
        product_name: this.replaceQuotationMarks(e.product?.nameEn) ?? '',
        product_brand: this.replaceQuotationMarks(e.product?.brandNameEn).toUpperCase() ?? '',
        product_date: this.replaceQuotationMarks(this.getProductDate(e.product)),
        product_variance1: this.replaceQuotationMarks(variance.get('variance_1')),
        product_variance2: this.replaceQuotationMarks(variance.get('variance_2')),
        product_package_code: this.replaceQuotationMarks(e.product?.code) ?? '',
        product_quantity: this.replaceQuotationMarks(e.quantity + ''),
        product_currency: this.replaceQuotationMarks(e.product?.currency)
      });
    });
    const { responseInfo: { pageTitle } = {} as any } = activeCart;
    const pageInfo = {
      page_name: pageTitle ? pageNamePrefix + pageTitle : this.window.contextData.page_name,
      cart_total_miles: activeCart?.totalPrice?.value,
      cart_total_cash: activeCart?.totalPriceCash?.value,
      products: products
    };
    const cdsDataInfo = {
      page_name: pageInfo.page_name
    };
    this.window.cdsData = cdsDataInfo;
    this.window.dataLayer = { ...this.window.contextData, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updatePageErrorDataLayer(data, pageType?: string): void {
    let pageInfo = {};
    const pageName = `${pageNamePrefix}:Online Shopping - Cathay Pacific`;
    if (pageType == 'generalError') {
      pageInfo = {
        page_name: pageName,
        error_code:
          'We are unable to process your order. Please try again. If you need any help completing your purchase,chat with us now.',
        miles_spend: '',
        miles_balance: '',
        miles_difference: ''
      };
    } else if (pageType == 'balanceError') {
      pageInfo = {
        page_name: pageName,
        error_code:
          "Sorry, you don't have enough miles in your account to redeem this item. You can check your balance in the Account Summary page.",
        miles_spend: data.error?.require,
        miles_balance: data.error?.available,
        miles_difference: data.error?.need
      };
    }
    this.window.cdsData = {
      page_name: pageName
    };
    this.window.dataLayer = { ...this.window.dataLayer, ...pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updateProductCdsData(data): void {
    let pageInfo = {
      page_name: pageNamePrefix + this.replaceQuotationMarks(data.pdpData.responseInfo?.pageTitle),
      product_package_code: this.replaceQuotationMarks(data.pdpData?.code) ?? '',
      product_name: this.replaceQuotationMarks(data.pdpData?.nameEn) ?? '',
      product_brand: this.replaceQuotationMarks(data.pdpData?.brandNameEn)?.toUpperCase() ?? '',
      product_brandid: this.replaceQuotationMarks(data.pdpData?.brandCode) ?? '',
      product_category_master: this.replaceQuotationMarks(data.pdpData.masterCategoryName),
      product_category_masterid: this.replaceQuotationMarks(data.pdpData.masterCategoryCode),
      product_category_supercategory: this.getCdsProductSupercategory(
        data.pdpData.masterCategoryName,
        data.pdpData.categories
      ), //to do
      product_category_supercategoryid: this.getCdsProductSupercategoryId(
        data.pdpData.masterCategoryCode,
        data.pdpData.categories
      ),
      product_partner: this.replaceQuotationMarks(data.pdpData?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
      product_miles: data.pdpData.maxMilesSpend,
      product_currency: this.replaceQuotationMarks(data.pdpData?.currency)
    };
    this.window.cdsData = { ...this.window.cdsData, pageInfo };
    this.window.contextData = this.window.dataLayer;
  }

  updateCartCdsData(data): void {
    const products = [];
    data.activeCart?.entries?.forEach((e) => {
      const product = {
        [e.product.code]: {
          product_brandid: e.product.brandCode,
          product_miles: e.totalPrice.value,
          product_category_supercategoryid: this.getCdsProductSupercategoryId(
            e.product.masterCategoryCode,
            e.product.categories
          ),
          product_package_code: e.product.code
        }
      };
      products.push(product);
    });
    this.window.cdsData.products = products;
  }

  updateProductsearchEngine(data): void {
    let productInfo = {
      product_package_code: this.replaceQuotationMarks(data.pdpData?.code) ?? '',
      product_name: this.replaceQuotationMarks(data.pdpData?.nameEn) ?? '',
      product_brand: this.replaceQuotationMarks(data.pdpData?.brandNameEn)?.toUpperCase() ?? '',
      product_miles: data.pdpData.maxMilesSpend,
      page_name: pageNamePrefix + this.replaceQuotationMarks(data.pdpData.responseInfo?.pageTitle),
      disc_product_miles: data.pdpData.promotionPrice?.value ?? ''
    };
    this.window.searchEngine = productInfo;
  }

  getSupercategory(categories): string {
    let supercategory = '';
    categories?.forEach((val) => {
      supercategory = supercategory + val.name + '|';
    });
    supercategory = supercategory.substring(0, supercategory.lastIndexOf('|'));
    return supercategory;
  }

  getCdsProductSupercategory(masterCategoryName, categories): string {
    let supercategory = "['" + masterCategoryName + "',";
    categories?.forEach((val) => {
      supercategory = supercategory + "'" + val.name + "',";
    });
    supercategory = supercategory.substring(0, supercategory.lastIndexOf(','));
    supercategory = supercategory + ']';
    return supercategory;
  }

  getCdsProductSupercategoryId(masterCategoryCode, categories): string {
    let supercategoryId = "['" + masterCategoryCode + "',";
    categories?.forEach((val) => {
      supercategoryId = supercategoryId + "'" + val.code + "',";
    });
    supercategoryId = supercategoryId.substring(0, supercategoryId.lastIndexOf(','));
    supercategoryId = supercategoryId + ']';
    return supercategoryId;
  }

  replaceQuotationMarks(str): string {
    if (str == null || str == '') {
      return str;
    }
    return str.replace(new RegExp("/'/gi"), "\\\\'");
  }

  getProductVariance(productData): Map<string, string> {
    let varianceMap = new Map([
      ['variance_1', ''],
      ['variance_2', '']
    ]);
    let i = 1;
    if (productData == null) {
      return varianceMap;
    }
    productData.baseOptions &&
      productData.baseOptions[0]?.selected?.variantOptionQualifiers.forEach((v) => {
        varianceMap.set('variance_' + i, v.name + ':' + v.value);
        i++;
      });
    return varianceMap;
  }

  getProductDate(productData): string {
    let value = '';
    productData.baseOptions[0]?.selected?.variantOptionQualifiers.forEach((v) => {
      if (v.qualifier === 'dateAndTime') {
        value = v.value;
      }
    });
    return value;
  }
}
