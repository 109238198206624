import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PendingOrderPopupService } from '@components/content/pending-order-popup/pending-order-popup.service';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthService,
  OccEndpointsService,
  OCC_USER_ID_CURRENT,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
  WindowRef
} from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import UrlHelper from '@utils/url-helper';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AmAuthRedirectService } from '../services/am-auth-redirect.service';
import { AmOAuthLibWrapperService } from '../services/am-oauth-wrapper.service';
import { AmThirdPartyKeepAliveService } from '../services/am-third-party-keep-alive.service';
import { AmLoginService } from './am-login.service';

export interface ResultStringData {
  data: string;
}
const loginCookieFlag = 'am_logged';
/**
 * Auth service for normal user authentication.
 * Use to check auth status, login/logout with different OAuth flows.
 */
@Injectable({
  providedIn: 'root'
})
export class AmAuthService extends AuthService {
  protected subscription = new Subscription();

  constructor(
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: AmOAuthLibWrapperService,
    protected authStorageService: AsmAuthStorageService,
    protected authRedirectService: AmAuthRedirectService,
    protected routingService: RoutingService,
    protected oAuthService: OAuthService,
    protected http: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected winRef: WindowRef,
    protected router: Router,
    protected amThirdPartyKeepAliveService: AmThirdPartyKeepAliveService,
    protected amLoginService: AmLoginService,
    protected pendingOrderPopupService: PendingOrderPopupService
  ) {
    super(store, userIdService, oAuthLibWrapperService, authStorageService, authRedirectService, routingService);
  }

  async checkOAuthParamsInUrl(): Promise<void> {
    // do this change for mobile app
    const params = UrlHelper.parseQueryStringToObject(this.winRef.location.href);
    const code = params['code'];
    const refreshToken = params['refreshToken'];
    this.storeInAppFlag(params['LSapp']);
    if (code) {
      this.getTokenWithCode(code);
    } else if (refreshToken) {
      this.reloginForInapp();
    } else {
      this.syncLoginStatusWithMlcCookie();
    }
  }

  async getTokenWithCode(code: string): Promise<void> {
    if (code === 'redirect' || code === 'error' || code === 'error_home') {
      //invalid code
      return;
    }
    if (code) {
      this.amLoginService.setLoginProgress(true);
    }
    try {
      // this.authStorageService.removeItem()
      const loginResult = await this.oAuthLibWrapperService.tryLogin();
      const token = this.authStorageService?.getItem('access_token');
      // We get the result in the code flow even if we did not logged in that why we also need to check if we have access_token
      if (loginResult.result && token) {
        this.userIdService.setUserId(OCC_USER_ID_CURRENT);
        this.store.dispatch(new AuthActions.Login());
        if (loginResult.tokenReceived) {
          this.authRedirectService.redirect();
          this.amLoginService.setLoginProgress(false);
          this.amThirdPartyKeepAliveService.storeThirdTokenKeepAliveStartTime();
          this.amThirdPartyKeepAliveService.setupThirdPartyTokenTimer();
        }
      }
    } catch {}
  }

  storeInAppFlag(hasInAppFlag: boolean) {
    if (hasInAppFlag) {
      this.winRef.sessionStorage?.setItem('isMobileView', 'true');
    }
  }

  isUserLoggedIn(): Observable<boolean> {
    return this.authStorageService.getToken().pipe(
      map((userToken) => Boolean(userToken?.access_token)),
      distinctUntilChanged()
    );
  }

  syncLoginStatusWithMlcCookie(): void {
    let isLoggedInFlag = false;
    this.isUserLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn: boolean) => {
        isLoggedInFlag = isLoggedIn;
      })
      .unsubscribe();
    const mlcLoginCookie = CookiesHelper.getCookie('mlc_prelogin');
    if (!isLoggedInFlag && mlcLoginCookie === '1') {
      this.tryMlcSingleSignOn();
    } else if (isLoggedInFlag && mlcLoginCookie !== '1') {
      this.coreLogout();
    }
  }

  /**
   * as the in-app will always do the sso when open our page
   * we need to refresh our login status to sync the mlc token in backend
   */
  reloginForInapp() {
    let isLoggedInFlag = false;
    this.isUserLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn: boolean) => {
        isLoggedInFlag = isLoggedIn;
      })
      .unsubscribe();
    const mlcLoginCookie = CookiesHelper.getCookie('mlc_prelogin');
    if (isLoggedInFlag && mlcLoginCookie === '1') {
      this.oAuthLibWrapperService.revokeAndLogout({ noRedirectToLogoutUrl: true }).then(() => {
        this.setLogoutStatus();
        this.tryMlcSingleSignOn();
      });
    } else if (!isLoggedInFlag && mlcLoginCookie === '1') {
      this.tryMlcSingleSignOn();
    }
  }

  /**
   * auto login when the user login in cx
   */
  tryMlcSingleSignOn() {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(async (navigationEvent: NavigationEnd) => {
          const nonce = await this.oAuthService.createAndSaveNonce();
          const fetchMlcSessionValidateUrlEndpoint = this.occEndpointSvc.getBaseUrl() + '/mlc/sessionValidateUrl';
          let httpParams = null;
          const { url } = navigationEvent;
          if (url.includes('/c/') || url.includes('/p/') || url.includes('/search/') || url.includes('/campaign/')) {
            httpParams = new HttpParams()
              .set('state', encodeURIComponent(nonce))
              .set('targetUrl', this.winRef.location.href);
          } else {
            httpParams = new HttpParams().set('state', encodeURIComponent(nonce));
          }
          this.amLoginService.setLoginProgress(true);
          this.http
            .get<ResultStringData>(fetchMlcSessionValidateUrlEndpoint, { params: httpParams })
            .toPromise()
            .then((result) => {
              if (result.data) {
                location.href = result.data;
              }
            })
            .catch((err) => {
              console.log('fetch login url fail, err info:', err);
            });
        })
    );
  }

  coreLogout(thirdTokenExpired?: boolean, isIdpTokenExpired?: boolean): Promise<void> {
    this.setLogoutProgress(true);
    return new Promise((resolve) => {
      this.oAuthLibWrapperService.revokeAndLogout({ noRedirectToLogoutUrl: true }).finally(() => {
        this.store.dispatch(new AuthActions.Logout());
        this.setLogoutStatus();
        this.pendingOrderPopupService.removePopupStatus();
        if (thirdTokenExpired !== true) {
          resolve();
          this.callThirdPartyLogoutUrl();
        } else {
          const queryParams = isIdpTokenExpired ? { asm: false } : {};
          this.routingService.go({ cxRoute: 'home' }, { queryParams });
          resolve();
        }
      });
    });
  }

  callThirdPartyLogoutUrl(): void {
    const logoutRedirectUrl = this.routingService.getFullUrl({ cxRoute: 'home' });
    // this is the mlc or idp logout url
    let logoutUrl = this.oAuthService.logoutUrl;
    logoutUrl = logoutUrl + (logoutUrl.indexOf('?') > -1 ? '&' : '?') + `logout_redirect_uri=${logoutRedirectUrl}`;
    this.winRef.location.href = logoutUrl;
  }

  setLogoutStatus(): void {
    this.userIdService.clearUserId();
    CookiesHelper.deleteCookie(loginCookieFlag);
    this.amThirdPartyKeepAliveService.removeThirdTokenKeepAliveStartTime();
    this.store.dispatch(new AuthActions.Logout());
  }

  // override the spartacus logout function
  logout() {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
