import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StateModule } from '@spartacus/core';
import { AmConsentsStatePersistenceService } from '../services/am-consents-persistence';
import { AM_CONSENTS_STORE_FEATURE } from './am-consents-state';
import { effects } from './effects';
import { reducerProvider, reducerToken } from './reducer';

export function amConsentsStatePersistenceFactory(
  amConsentsStatePersistenceService: AmConsentsStatePersistenceService
): () => void {
  const result = () => amConsentsStatePersistenceService.initSync();
  return result;
}

@NgModule({
  imports: [
    CommonModule,
    StateModule,
    StoreModule.forFeature(AM_CONSENTS_STORE_FEATURE, reducerToken),
    EffectsModule.forFeature(effects)
  ],
  providers: [
    reducerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: amConsentsStatePersistenceFactory,
      deps: [AmConsentsStatePersistenceService],
      multi: true
    }
  ]
})
export class AmConsentsStoreModule {}
