import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthStorageService,
  AuthToken,
  GlobalMessageService,
  OccEndpointsService,
  RoutingService,
  StateWithClientAuth
} from '@spartacus/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { combineLatest, defer, EMPTY, Observable, queueScheduler, Subject, Subscription, using } from 'rxjs';
import {
  filter,
  map,
  observeOn,
  pairwise,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { AmAuthService } from '../facade/am-auth.service';
import { AmAuthRedirectService } from './am-auth-redirect.service';
import { AmOAuthLibWrapperService } from './am-oauth-wrapper.service';

/**
 * Extendable service for `AuthInterceptor`.
 */
@Injectable({
  providedIn: 'root'
})
export class AmAuthHttpHeaderService {
  protected refreshInProgress = false;

  protected refreshTokenExpired: boolean = false;

  protected refreshTokenTrigger$ = new Subject<AuthToken>();

  protected token$: Observable<AuthToken | undefined> = this.authStorageService
    .getToken()
    .pipe(map((token) => (token?.access_token ? token : undefined)));

  protected stopProgress$ = this.token$.pipe(
    // Keeps the previous and the new token
    pairwise(),
    tap(([oldToken, newToken]) => {
      // if we got the new token we know that either the refresh or logout finished
      if (oldToken?.access_token !== newToken?.access_token) {
        this.authService.setLogoutProgress(false);
        this.authService.setRefreshProgress(false);
      }
    })
  );

  protected refreshToken$ = this.refreshTokenTrigger$.pipe(
    withLatestFrom(this.authService.refreshInProgress$, this.authService.logoutInProgress$),
    filter(([, refreshInProgress, logoutInProgress]) => !refreshInProgress && !logoutInProgress),
    tap(([token]) => {
      if (token?.refresh_token) {
        this.oAuthLibWrapperService.refreshToken();
        this.authService.setRefreshProgress(true);
      } else {
        this.handleExpiredRefreshToken();
      }
    })
  );

  protected tokenToRetryRequest$ = using(
    () => this.refreshToken$.subscribe(),
    () => this.getStableToken()
  ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  protected subscriptions = new Subscription();

  constructor(
    protected authService: AmAuthService,
    protected authStorageService: AuthStorageService,
    protected oAuthLibWrapperService: AmOAuthLibWrapperService,
    protected routingService: RoutingService,
    protected occEndpoints: OccEndpointsService,
    protected globalMessageService: GlobalMessageService,
    protected authRedirectService: AmAuthRedirectService,
    protected store: Store<StateWithClientAuth>,
    protected oAuthService: OAuthService
  ) {
    this.subscriptions.add(this.stopProgress$.subscribe());
  }

  /**
   * Checks if request should be handled by this service (if it's OCC call).
   */
  public shouldCatchError(request: HttpRequest<any>): boolean {
    return this.isOccUrl(request.url);
  }

  public shouldAddAuthorizationHeader(request: HttpRequest<any>): boolean {
    const hasAuthorizationHeader = !!this.getAuthorizationHeader(request);
    const isOccUrl = this.isOccUrl(request.url);
    const isExcludeUrl = this.isExcludeUrl(request.url);
    return !hasAuthorizationHeader && isOccUrl && !isExcludeUrl;
  }

  public alterRequest(request: HttpRequest<any>, token?: AuthToken): HttpRequest<any> {
    const hasAuthorizationHeader = !!this.getAuthorizationHeader(request);
    const isOccUrl = this.isOccUrl(request.url);
    const isExcludeUrl = this.isExcludeUrl(request.url);
    if (!hasAuthorizationHeader && isOccUrl && !isExcludeUrl) {
      return request.clone({
        setHeaders: {
          ...this.createAuthorizationHeader(token)
        }
      });
    }
    return request;
  }

  protected isExcludeUrl(url: string): boolean {
    return url.includes('logout') || url.includes('auto-login');
  }

  protected isOccUrl(url: string): boolean {
    let result =
      url.includes(this.occEndpoints.getBaseUrl()) ||
      url.includes('/assisted-service/customers/search') ||
      url.includes('assistedservicewebservices');
    return result;
  }

  protected getAuthorizationHeader(request: HttpRequest<any>): string | null {
    const rawValue = request.headers.get('Authorization');
    return rawValue;
  }

  protected createAuthorizationHeader(token?: AuthToken): { Authorization: string } | {} {
    if (token?.access_token) {
      return {
        Authorization: `${token.token_type || 'Bearer'} ${token.access_token}`,
        // @ts-ignore
        sessionTokenIdentifier: `${token?.sessionTokenIdentifier}`
      };
    }
    let currentToken: AuthToken | undefined;
    this.authStorageService
      .getToken()
      .subscribe((token) => (currentToken = token))
      .unsubscribe();

    if (currentToken?.access_token) {
      return {
        Authorization: `${currentToken.token_type || 'Bearer'} ${currentToken.access_token}`,
        // @ts-ignore
        sessionTokenIdentifier: `${currentToken?.sessionTokenIdentifier}`
      };
    }
    return {};
  }

  getStableToken(): Observable<AuthToken | undefined> {
    return combineLatest([this.token$, this.authService.refreshInProgress$, this.authService.logoutInProgress$]).pipe(
      observeOn(queueScheduler),
      filter(([_, refreshInProgress, logoutInProgress]) => !refreshInProgress && !logoutInProgress),
      switchMap(() => this.token$)
    );
  }

  public getRefreshTokenExpired() {
    return this.refreshTokenExpired;
  }

  public handleExpiredAccessToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    // TODO:#13421 make required
    initialToken?: AuthToken
  ): Observable<HttpEvent<AuthToken>> {
    // TODO:#13421 remove this if-statement, and just return the stream.
    if (initialToken) {
      return this.getValidToken(initialToken).pipe(
        switchMap((token) =>
          // we break the stream with EMPTY when we don't have the token. This prevents sending the requests with `Authorization: bearer undefined` header
          token ? next.handle(this.createNewRequestWithNewToken(request, token)) : EMPTY
        )
      );
    }

    // TODO:#13421 legacy - remove in 5.0
    return this.handleExpiredToken().pipe(
      switchMap((token) => {
        return token ? next.handle(this.createNewRequestWithNewToken(request, token)) : EMPTY;
      })
    );
  }

  protected createNewRequestWithNewToken(request: HttpRequest<any>, token: AuthToken): HttpRequest<any> {
    request = request.clone({
      setHeaders: {
        Authorization: `${token.token_type || 'Bearer'} ${token.access_token}`
      }
    });
    return request;
  }

  protected getValidToken(requestToken: AuthToken): Observable<AuthToken | undefined> {
    return defer(() => {
      // flag to only refresh token only on first emission
      let refreshTriggered = false;
      return this.tokenToRetryRequest$.pipe(
        tap((token) => {
          // we want to refresh the access token only when it is old.
          // this is a guard for the case when there are multiple parallel http calls
          if (token?.access_token === requestToken?.access_token && !refreshTriggered) {
            this.refreshTokenTrigger$.next(token);
          }
          refreshTriggered = true;
        }),
        skipWhile((token) => token?.access_token === requestToken.access_token),
        take(1)
      );
    });
  }

  public handleExpiredRefreshToken(): void {
    this.authRedirectService.saveCurrentNavigationUrl();
    this.refreshTokenExpired = true;
    this.authService.coreLogout();
  }

  protected handleExpiredToken(): Observable<AuthToken | undefined> {
    const stream = this.authStorageService.getToken();
    let oldToken: AuthToken;
    return stream.pipe(
      tap((token) => {
        if (token.access_token && token.refresh_token && !oldToken && !this.refreshInProgress) {
          this.refreshInProgress = true;
          this.oAuthLibWrapperService.refreshToken();
        } else if (!token.refresh_token) {
          this.handleExpiredRefreshToken();
        }
        oldToken = oldToken || token;
      }),
      filter((token) => oldToken.access_token !== token.access_token),
      tap(() => {
        this.refreshInProgress = false;
      }),
      map((token) => (token?.access_token ? token : undefined)),
      take(1)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
