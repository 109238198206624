import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { AmDeliveryModule } from '@components/content/format/delivery/delivery.module';
import { AmItemCounterModule } from '@components/content/item-counter/item-counter.module';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { ReminderModule } from '@components/content/reminder/reminder.module';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, OutletModule } from '@spartacus/storefront';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { ProductPriceModule } from 'src/app/amiredeem/components/content/common/product-price/product-price.module';
import { FavoriteNotifyReminderModule } from 'src/app/amiredeem/pages/favorite-notify-products/components/favorite-notify-reminder/favorite-notify-reminder.module';
import { SvgContainerModule } from '../../../../../components/content/svg-container/svg-container.module';
import { AmCartRemoveDialogModule } from '../../cart-remove-dialog/cart-remove-dialog.module';
import { AmOfferAppliedDialogModule } from '../../offer-applied-dialog/offer-applied-dialog.module';
import { AmCartItemComponent } from './cart-item.component';

@NgModule({
  declarations: [AmCartItemComponent],
  exports: [AmCartItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    UrlModule,
    FormsModule,
    I18nModule,
    IconModule,
    FeaturesConfigModule,
    OutletModule,
    AmDeliveryModule,
    SafeCodeModule,
    AmCartRemoveDialogModule,
    AmItemCounterModule,
    DateTimeModule,
    AmMediaModule,
    ProductPriceModule,
    AmOfferAppliedDialogModule,
    SvgContainerModule,
    MessageTipModule,
    ReminderModule,
    FavoriteNotifyReminderModule
  ]
})
export class AmCartItemModule {}
