import { PageMetaConfig } from '@spartacus/core/src/cms/page/config/page-meta.config';

export const amPageMetaConfig: PageMetaConfig = {
  pageMeta: {
    resolvers: [
      {
        property: 'title',
        method: 'resolveTitle'
      },
      {
        property: 'heading',
        method: 'resolveHeading'
      },
      {
        property: 'canonicalUrl',
        method: 'resolveCanonicalUrl'
      },
      {
        property: 'description',
        method: 'resolveDescription'
      },
      {
        property: 'image',
        method: 'resolveImage',
        disabledInCsr: true
      },
      {
        property: 'robots',
        method: 'resolveRobots',
        disabledInCsr: true
      }
      // {
      //   property: 'breadcrumbs',
      //   method: 'resolveBreadcrumbs'
      // },
    ],
    canonicalUrl: {
      forceHttps: true,
      forceWww: false,
      removeQueryParams: true,
      forceTrailingSlash: false
    }
  }
};
