<ng-container *ngIf="recommendData$ | async as recommendData">
  <div class="recommend-product-list-wrapper">
    <h3 class="title">
      {{ 'amRecommendProduct.delistTip' | cxTranslate }}
    </h3>
    <p class="sub-title">
      {{ 'amRecommendProduct.recommendTip' | cxTranslate }}
    </p>

    <div class="product-container">
      <a
        class="product-item"
        *ngFor="let productItem of recommendData?.products"
        [routerLink]="{ cxRoute: 'product', params: { code: productItem?.code } } | cxUrl">
        <div class="product-image product-img">
          <am-media [container]="productItem?.images"></am-media>
          <div
            *ngIf="productItem.productTagGroupA?.tagName"
            class="font-bold product-img-tag product-img-tag-{{
              getTagGroupAColor(productItem.productTagGroupA?.type)
            }}">
            {{ productItem.productTagGroupA.tagName }}
          </div>
        </div>
        <div class="product-description">
          <p class="brand-name font-bold">
            {{ productItem?.brandName }}
          </p>
          <p class="product-name">
            {{ productItem?.name }}
          </p>
          <div class="product-price">
            <am-product-price [product]="productItem"></am-product-price>
          </div>
        </div>
      </a>
    </div>
  </div>

  <am-recomment-product-categories [categories]="recommendData.categories"></am-recomment-product-categories>
</ng-container>
