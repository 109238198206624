// import {
//   OAuthEvent,
//   OAuthInfoEvent,
//   OAuthErrorEvent,
//   OAuthSuccessEvent
// } from './events';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AmOAuthService extends OAuthService {
  /**
   * Removes all tokens and logs the user out.
   * If a logout url is configured, the user is
   * redirected to it with optional state parameter.
   * @param noRedirectToLogoutUrl
   * @param state
   */
  public logOut(): void;
  public logOut(customParameters: object): void;
  public logOut(noRedirectToLogoutUrl: boolean): void;
  public logOut(noRedirectToLogoutUrl: boolean, state: string): void;
  public logOut(customParameters: boolean | object = {}, state = ''): void {
    let noRedirectToLogoutUrl = false;
    if (typeof customParameters === 'boolean') {
      noRedirectToLogoutUrl = customParameters;
      customParameters = {};
    }

    if (customParameters['noRedirectToLogoutUrl']) {
      noRedirectToLogoutUrl = customParameters['noRedirectToLogoutUrl'];
    }

    const id_token = this.getIdToken();
    this._storage.removeItem('access_token');
    this._storage.removeItem('id_token');
    this._storage.removeItem('refresh_token');

    if (this.saveNoncesInLocalStorage) {
      localStorage?.removeItem('nonce');
      localStorage?.removeItem('PKCE_verifier');
    } else {
      this._storage.removeItem('nonce');
      this._storage.removeItem('PKCE_verifier');
    }

    this._storage.removeItem('expires_at');
    this._storage.removeItem('id_token_claims_obj');
    this._storage.removeItem('id_token_expires_at');
    this._storage.removeItem('id_token_stored_at');
    this._storage.removeItem('access_token_stored_at');
    this._storage.removeItem('granted_scopes');
    this._storage.removeItem('session_state');
    if (this.config.customTokenParameters) {
      this.config.customTokenParameters.forEach((customParam) => this._storage.removeItem(customParam));
    }
    this.silentRefreshSubject = null;

    this.eventsSubject.next(new OAuthInfoEvent('logout'));

    if (!this.logoutUrl) {
      return;
    }
    if (noRedirectToLogoutUrl) {
      return;
    }

    if (!id_token && !this.postLogoutRedirectUri) {
      return;
    }

    let logoutUrl: string;

    if (!this.validateUrlForHttps(this.logoutUrl)) {
      throw new Error(
        "logoutUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS)."
      );
    }

    // For backward compatibility
    if (this.logoutUrl.indexOf('{{') > -1) {
      logoutUrl = this.logoutUrl.replace(/\{\{id_token\}\}/, id_token).replace(/\{\{client_id\}\}/, this.clientId);
    } else {
      let params = new HttpParams();

      if (id_token) {
        params = params.set('id_token_hint', id_token);
      }

      const postLogoutUrl = this.postLogoutRedirectUri || this.redirectUri;
      if (postLogoutUrl) {
        params = params.set('post_logout_redirect_uri', postLogoutUrl);

        if (state) {
          params = params.set('state', state);
        }
      }

      for (let key in customParameters) {
        params = params.set(key, customParameters[key]);
      }

      logoutUrl = this.logoutUrl + (this.logoutUrl.indexOf('?') > -1 ? '&' : '?') + params.toString();
    }
    this.config.openUri(logoutUrl);
  }
}
