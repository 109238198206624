import { Cart } from '@spartacus/cart/base/root';
import { StateUtils } from '@spartacus/core';
import { MULTI_CART_DATA } from '../multi-cart-state';

export const UPDATE_CART_MILES = '[Cart] Update Cart Miles';
export const UPDATE_CART_MILES_SUCCESS = '[Cart] Update Cart Miles Success';
export const UPDATE_CART_MILES_FAIL = '[Cart] Update Cart Miles Fail';

export class UpdateCartMiles extends StateUtils.EntityProcessesIncrementAction {
  readonly type = UPDATE_CART_MILES;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      miles: number;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class UpdateCartMilesSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = UPDATE_CART_MILES_SUCCESS;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      miles: number;
      cart?: Cart;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class UpdateCartMilesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = UPDATE_CART_MILES_FAIL;
  constructor(
    public payload: {
      cartId: string;
      error: any;
      userId: string;
      miles: number;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export type AmCartMilesAction = UpdateCartMiles | UpdateCartMilesSuccess | UpdateCartMilesFail;
