import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CookieBarService } from '@components/layout/footer/cookie-bar/cookie-bar.service';
import { LiveChatService } from '@components/layout/footer/livechat/livechat.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { AmAuthRedirectService } from '@core/auth/user-auth/services/am-auth-redirect.service';
import { Store } from '@ngrx/store';
import {
  Country,
  CurrencyService,
  Language,
  SiteContextActions,
  StateWithSiteContext,
  WindowRef
} from '@spartacus/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { combineLatest, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { svgConfig } from '../../../../../../config/am-svg-sprite.config';
import { CountryService } from '../../services/country/country.service';
import { AmLanguageService } from '../../services/language/language.service';
import { FlightInfoService } from '../inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '../inflight-site-context-selector-popup/services/flight-status-storage.service';

// export enum SiteContextType {
//   LANGUAGE = 'language',
//   CURRENCY = 'currency'
// }

@Component({
  selector: 'am-header-site-context-selector',
  templateUrl: './header-site-context-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSiteContentSelectorComponent implements OnInit {
  languageItems$: Observable<Language[]> = this.languageService.getSortedLanguage();
  countryItems$: Observable<Country[]> = this.countryService.getFormattedCountries();
  languageMap = {
    en: 'EN',
    zh_TW: 'TC',
    zh: 'SC',
    ja: 'JP',
    ko: 'KR'
  };
  currencyItems = [
    { label: 'HKD', value: 'HKD' },
    { label: 'USD', value: 'USD' }
  ];
  activeLanguage: string;
  selectedLanguage: string;
  selectedCountry: string;
  selectedCurrency: string;
  countrySelectorOpen: boolean = false;
  languageSelectorOpen: boolean = false;

  svgType = svgConfig;
  style = 'width: 24px;height: 24px';
  style_leave_shop = 'width: 24px;height: 24px';

  siteContextSubscription: Subscription;
  activeCountryEntity: Country;
  activeCurrency: string;

  isInflightEnv: boolean = false;

  constructor(
    protected languageService: AmLanguageService,
    protected countryService: CountryService,
    protected currencyService: CurrencyService,
    protected cookieBarService: CookieBarService,
    protected authRedirectService: AmAuthRedirectService,
    protected liveChatService: LiveChatService,
    protected store: Store<StateWithSiteContext>,
    protected launchDialogService: AmLaunchDialogService,
    protected ref: ChangeDetectorRef,
    public flightStatusStorageSvc: FlightStatusStorageService,
    public flightInfoService: FlightInfoService,
    protected winRef: WindowRef,
    protected amConfigService: AmConfigService
  ) {
    this.subscription.add(
      this.amConfigService.getIsInflightEnv().subscribe((data) => {
        this.isInflightEnv = data.data;
        this.ref.markForCheck();
      })
    );
  }

  toggleSelector(selectedType?: string) {
    if (selectedType === 'language') {
      this.languageSelectorOpen = !this.languageSelectorOpen;
      this.countrySelectorOpen = false;
    } else {
      this.countrySelectorOpen = !this.countrySelectorOpen;
      this.languageSelectorOpen = false;
    }
  }

  closeSelector() {
    this.countrySelectorOpen = false;
    this.languageSelectorOpen = false;
  }

  onSubmitSiteContext() {
    let activeCurrency = this.selectedCurrency;
    activeCurrency = this.countryService.getCurrencyFromActiveCountryEntity(this.selectedCountry);
    this.countryService.setActive(this.selectedCountry);
    this.languageService.setActive(this.selectedLanguage);
    this.currencyService.setActive(activeCurrency);
    this.liveChatService.updateLiveChatLanguage(this.selectedLanguage);
    this.cookieBarService.updateCookieBarFunction(this.selectedLanguage);
    this.authRedirectService.saveCurrentNavigationUrl();
    this.closeSelector();
  }

  preventSelectorContainerEvent(event: Event) {
    if (event) {
      event.stopPropagation();
    }
  }
  protected subscription = new Subscription();
  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.languageService.getActive(),
        this.countryService.getActiveCountryEntity(),
        this.currencyService.getActive()
      ])
        .pipe(distinctUntilChanged())
        .subscribe(([activeLanguage, activeCountryEntity, activeCurrency]) => {
          if (this.activeLanguage !== activeLanguage) {
            this.selectedLanguage = activeLanguage;
          }
          if (this.activeCountryEntity?.isocode !== activeCountryEntity?.isocode) {
            this.selectedCountry = activeCountryEntity.isocode;
          }
          if (this.activeCurrency !== activeCurrency) {
            this.selectedCurrency = activeCurrency;
          }
          if (
            this.activeLanguage === this.selectedLanguage &&
            this.activeCountryEntity.isocode !== this.selectedCountry
          ) {
            this.store.dispatch(
              new SiteContextActions.LanguageChange({ previous: activeLanguage, current: activeLanguage })
            );
          }
          this.activeCountryEntity = activeCountryEntity;
          this.activeLanguage = activeLanguage;
          this.activeCurrency = activeCurrency;
          this.ref.markForCheck();
        })
    );
    // if (this.flightStatusStorageSvc.isInflightPickFlow) {
    //   this.siteContextSubscription = combineLatest([
    //     this.languageService.getActive(),
    //     this.siteContextService.getActiveItem(this.currencyContext)
    //   ])
    //     .pipe(distinctUntilChanged())
    //     .subscribe(([activeLanguage, activeCurrency]) => {
    //       this.selectedLanguage = activeLanguage;
    //       this.selectedCurrency = activeCurrency;
    //     });
    // } else {
    //   this.siteContextSubscription = combineLatest([
    //     this.languageService.getActive(),
    //     this.countryService.getActive(),
    //     this.siteContextService.getActiveItem(this.currencyContext)
    //   ])
    //     .pipe(distinctUntilChanged())
    //     .subscribe(([activeLanguage, currentActiveCurrency, activeCountry]) => {
    //       // the first time refresh the page
    //       if (!this.selectedLanguage && !this.activeLanguage) {
    //         this.selectedLanguage = activeLanguage;
    //         this.activeLanguage = activeLanguage;
    //         return;
    //       }
    //       // when select the language
    //       if (this.selectedLanguage === activeLanguage && this.activeLanguage !== this.selectedLanguage) {
    //         this.activeLanguage = activeLanguage;
    //       } else {
    //         // only the country change
    //         // temp fix: when country change, also trigger the language change event to reload something
    //         this.store.dispatch(
    //           new SiteContextActions.LanguageChange({ previous: activeLanguage, current: activeLanguage })
    //         );
    //       }

    //       let activeCurrency = this.countryService.getCurrencyFromActiveCountryEntity(activeCountry);
    //       if (currentActiveCurrency !== activeCurrency) {
    //         this.siteContextService.setActive(activeCurrency, this.currencyContext);
    //       }
    //     });
    // }
  }

  openChangeFlightPopup(): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.INFLIGHT_CHANGE_FLIGHT_CONTEXT,
      undefined,
      undefined
    );
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  openShoppingTipModal(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.SHOPPING_TIP_DIALOG, undefined, undefined, {
      isFlow1Flag: this.flightStatusStorageSvc.isInflightPickFlow ? true : false,
      ppopupName: 'shopping-tip',
      languages: this.selectedLanguage,
      countries: this.selectedCountry
    });
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  onLastElementTab(): void {
    this.winRef.isBrowser() &&
      setTimeout(() => {
        this.winRef.document?.getElementById('cx-hamburger').focus();
      }, 200);
  }

  ngOnDestroy(): void {
    this.siteContextSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
