import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorHandler, HttpResponseStatus, Priority } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class AmForbiddenHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.FORBIDDEN;

  handleError(_request: HttpRequest<any>, errorResponse: HttpErrorResponse): void {
    if (isDevMode() || this.isSsr()) {
      console.warn(`An http 403 error occurred\n`, errorResponse.message);
    }
    // if (
    //   request.url.endsWith(
    //     this.occEndpoints.buildUrl('user', {
    //       urlParams: { userId: 'current' },
    //     })
    //   )
    // ) {
    //   this.authService.logout();
    // }
    // this.globalMessageService.add(
    //   { key: 'httpHandlers.forbidden' },
    //   GlobalMessageType.MSG_TYPE_ERROR
    // );
  }

  getPriority(): Priority {
    return Priority.NORMAL;
  }

  // constructor(
  //   protected globalMessageService: GlobalMessageService,
  //   protected authService: AuthService,
  //   protected occEndpoints: OccEndpointsService
  // ) {
  //   super(globalMessageService);
  // }
}
