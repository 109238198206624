export const environment = {
  production: true,
  baseUrl: '',
  cdsTenant: 'htp740963945',
  cdsBaseUrl: 'https://htp740963945.api.us.context.cloud.sap',
  cdsStrategyProducts: '/strategy/${tenant}/strategies/${strategyId}/products',
  cdsJavascriptUrl: 'https://tag.static.us.context.cloud.sap/js/profile-tag.js',
  cdsConfigUrl: 'https://tag.static.us.context.cloud.sap/config/5fc2a950-9312-11ec-9241-21cfcaf07828',
  keepAliveCycle: 20 * 60 * 1000,
  mlcv2KeepAliveUrl: 'https://t0.api.osc1.ct1.cathaypacific.com/mlc2/keep-alive',
  idpKeepAliveUrl: 'https://backoffice.ete.asiamiles.com/amptnr-idp/keep-alive',
  kongApiKey: 'abcd1234',
  shareUrl: 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d5e0eb7892483b2',
  contactUsUrl: 'https://www-t0.ete.cathaypacific.com/cx/en_HK/contact-us.html',
  airportUrl: {
    en: 'https://www-t0.ete.cathaypacific.com/content/cx/servlet.airport.en.JSON',
    ja: 'https://www-t0.ete.cathaypacific.com/content/cx/servlet.airport.ja.JSON',
    ko: 'https://www-t0.ete.cathaypacific.com/content/cx/servlet.airport.ko.JSON',
    zh: 'https://www-t0.ete.cathaypacific.com/content/cx/servlet.airport.sc.JSON',
    zh_TW: 'https://www-t0.ete.cathaypacific.com/content/cx/servlet.airport.zh.JSON'
  }
};
