import { Provider, Type } from '@angular/core';
import {
  BaseSiteService,
  BASE_SITE_CONTEXT_ID,
  CurrencyService,
  CURRENCY_CONTEXT_ID,
  LanguageService,
  LANGUAGE_CONTEXT_ID,
  SiteContext
} from '@spartacus/core';
import { CountryService } from '../services/country/country.service';
import { COUNTRY_CONTEXT_ID } from './am-context-ids';

export abstract class ContextServiceMap {
  [context: string]: Type<SiteContext<any>>;
}

export function amServiceMapFactory() {
  return {
    [LANGUAGE_CONTEXT_ID]: LanguageService,
    [CURRENCY_CONTEXT_ID]: CurrencyService,
    [COUNTRY_CONTEXT_ID]: CountryService,
    [BASE_SITE_CONTEXT_ID]: BaseSiteService
  };
}

export const amContextServiceMapProvider: Provider = {
  provide: ContextServiceMap,
  useFactory: amServiceMapFactory
};
