<button
  type="button"
  [cxFocus]="{ key: 'key1' + productCode }"
  (click)="decrement()"
  [disabled]="control.disabled || control.value <= min || readonly"
  attr.aria-label="{{ 'itemCounter.removeOne' | cxTranslate }}">
  <span class="subtract"></span>
</button>
<input
  #qty
  type="number"
  [cxFocus]="{ key: 'key2' + productCode }"
  [min]="min"
  [max]="max"
  [step]="step"
  [readonly]="readonly"
  [tabindex]="readonly ? -1 : 0"
  [formControl]="control"
  attr.aria-label="{{ 'itemCounter.quantity' | cxTranslate }}" />
<button
  type="button"
  [cxFocus]="{ key: 'key3' + productCode }"
  (click)="increment()"
  [disabled]="control.disabled || control.value >= max || readonly"
  tabindex="0"
  attr.aria-label="{{ 'itemCounter.addOneMore' | cxTranslate }}">
  <span class="add"></span>
</button>
