import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { LoadingBase64 } from '@model/lazy-loading.model';
import { WindowRef } from '@spartacus/core';
import { MediaComponent } from '@spartacus/storefront';
import { tap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { v4 as uuidv4 } from 'uuid';
import { StateChange } from '../lazy-image/types';
import { AmMediaService } from './media.service';

@Component({
  selector: 'am-media',
  templateUrl: './media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmMediaComponent extends MediaComponent {
  responsiveImage: boolean = false;
  uuidRef: string;
  /**
   * Whether to use lazy loading, default true;
   */
  @Input() useLazyLoading = true;
  @Input() scrollTarget?: any;
  /**
   *  Set the height of the preload, if 0（unpreload）
   */
  @Input() offset = 0;
  /**
   * The location where the image starts to load，default 0,
   */
  @Input() threshold = 0;
  /**
   * Listen for horizontal slide events,mainly used for swiper, default false
   */
  @Input() transitionEvent = false;
  // @Input() defaultImage = '/assets/images/loading-300.png';
  @Input() defaultImage = LoadingBase64.W300H300;
  isInflight$ = this.amConfigService.getIsInflightEnv().pipe(
    tap((v) => {
      if (this.offset) {
        return;
      }
      if (v?.data) {
        // inflight preload  500px
        this.offset = 500;
      } else {
        // onground preload  1000px
        this.offset = 1000;
      }
    })
  );
  constructor(
    protected mediaService: AmMediaService,
    protected cdr: ChangeDetectorRef,
    protected winRef: WindowRef,
    protected amConfigService: AmConfigService
  ) {
    super(mediaService);
    this.uuidRef = uuidv4().substr(0, 7);
  }

  protected create(): void {
    // remove suffix from alt if exist
    if (this.alt) {
      const imgList = ['.jpg', '.png', '.jpeg', '.bmp', '.gif'];
      const arr = this.alt.split('.');
      const suffix = '.' + arr[arr.length - 1];
      if (imgList.includes(suffix)) {
        this.alt = this.alt.substring(0, this.alt.indexOf(suffix));
      }
    }

    this.media = this.mediaService.getMedia(
      this.container instanceof Array ? this.container[0] : this.container,
      this.format,
      this.alt,
      this.role
    );
    if (!this.media?.src) {
      this.handleMissing();
    } else if (!!this.media.src && (!!this.media.mobile || !!this.media.tablet)) {
      this.responsiveImage = true;
    }
  }

  protected handleMissing(): string | void {
    this.isLoading = false;
    this.isInitialized = true;
    this.isMissing = true;
    this.loaded.emit(false);
    this.cdr.detectChanges();
  }

  onStateChange(state: StateChange) {
    if (state.reason === 'loading-failed') {
      this.handleMissing();
    } else if (state.reason === 'loading-succeeded') {
      this.loadHandler();
    }
  }
}
