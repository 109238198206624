import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe, SafeUrlPipe } from './safe-code.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SafeHtmlPipe, SafeUrlPipe],
  exports: [SafeHtmlPipe, SafeUrlPipe]
})
export class SafeCodeModule {}
