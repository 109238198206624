<div *ngIf="model$ | async as model" class="search-result-and-operate-wrapper">
  <div class="search-results">
    <p
      class="total-amount"
      [innerHTML]="'amProductListDetail.products' | cxTranslate : { totalCounts: model.pagination?.totalResults }"></p>
  </div>

  <div class="total-clear-container">
    <p
      class="total-amount"
      [innerHTML]="'amProductListDetail.products' | cxTranslate : { totalCounts: model.pagination?.totalResults }"></p>
    <button class="refine-btn" (click)="launchFacetModal()">
      <am-svg-container [type]="svgType.REFINE"></am-svg-container
      ><span>{{ 'amProductFacet.Refine' | cxTranslate }}</span>
    </button>
  </div>
  <am-facet-list
    #facetList
    *ngIf="isOpen$ | async"
    [isDialog]="hasTrigger"
    [searchTextFromRouter]="searchTextFromRouter"
    [categoryCodeFromRouter]="categoryCodeFromRouter"
    [model]="model"
    [payByCashStatus]="payByCashStatus"
    (updatePayByCashStatus)="updatePayByCashStatus($event)"
    (closeList)="close()"
    [class.active]="isActive$ | async"
    [class.dialog]="hasTrigger"></am-facet-list>
</div>

<span #trigger class="active-mobile-mode"></span>
