import { empty, Observable, of, Subject } from 'rxjs';
import { filter, sampleTime, share, startWith } from 'rxjs/operators';
import { SharedHooks } from '../shared-hooks/shared-hooks';
import { Attributes } from '../types';
import { Rect } from '../util/rect';

export class IntersectionObserverHooks extends SharedHooks<{ isIntersecting: boolean } | Event | string> {
  private readonly observers = new WeakMap<Element | {}, IntersectionObserver>();
  private readonly intersectionSubject = new Subject<IntersectionObserverEntry>();
  private readonly uniqKey = {};
  protected getWindow = () => window;
  private readonly scrollListeners = new WeakMap<any, Observable<any>>();

  getObservable(attributes: Attributes<any>): Observable<{ isIntersecting: boolean } | Event | string> {
    if (!attributes.transitionEvent) {
      if (this.skipLazyLoading(attributes)) {
        return of({ isIntersecting: true });
      }
      if (attributes.customObservable) {
        return attributes.customObservable;
      }
      const scrollContainerKey = attributes.scrollContainer || this.uniqKey;
      const options: ObserverOptions = {
        root: attributes.scrollContainer || null
      };

      if (attributes.offset) {
        options.rootMargin = `${attributes.offset}px`;
      }
      if (attributes.threshold) {
        options.threshold = attributes.threshold;
      }
      let observer = this.observers.get(scrollContainerKey);

      if (!observer) {
        observer = new IntersectionObserver((entrys) => this.loadingCallback(entrys), options);
        this.observers.set(scrollContainerKey, observer);
      }

      observer.observe(attributes.element);

      return Observable.create((obs: Subject<IntersectionObserverEntry>) => {
        const subscription = this.intersectionSubject
          .pipe(
            filter((entry) => {
              return entry.target === attributes.element;
            })
          )
          .subscribe(obs);
        return () => {
          subscription.unsubscribe();
          observer!.unobserve(attributes.element);
        };
      });
    } else {
      if (this.skipLazyLoading(attributes)) {
        return of('load');
      } else if (attributes.customObservable) {
        return attributes.customObservable.pipe(startWith(''));
      } else if (attributes.scrollContainer) {
        return this.getScrollListener(attributes.scrollContainer, attributes.transitionEvent);
      }
      return this.getScrollListener(this.getWindow(), attributes.transitionEvent);
    }
  }

  isVisible(event: { isIntersecting: boolean }, attributes: Attributes): boolean {
    if (!attributes.transitionEvent) {
      return event.isIntersecting;
    } else {
      const elementBounds = Rect.fromElement(attributes.element);
      if (elementBounds === Rect.empty) {
        return false;
      }
      const windowBounds = Rect.fromWindow(this.getWindow());
      elementBounds.inflate(attributes.offset);

      if (attributes.scrollContainer) {
        const scrollContainerBounds = Rect.fromElement(attributes.scrollContainer);
        const intersection = scrollContainerBounds.getIntersectionWith(windowBounds);
        return elementBounds.intersectsWith(intersection);
      } else {
        return elementBounds.intersectsWith(windowBounds);
      }
    }
  }

  private loadingCallback(entrys: IntersectionObserverEntry[]) {
    entrys.forEach((entry) => {
      this.intersectionSubject.next(entry);
    });
  }

  sampleObservable<T>(obs: Observable<T>, scheduler?: any): Observable<T | ''> {
    return obs.pipe(sampleTime(100, scheduler), share(), startWith('')) as Observable<T | ''>;
  }

  // Only create one scroll listener per target and share the observable.
  // Typical, there will only be one observable per application
  getScrollListener = (scrollTarget?: HTMLElement | Window, transitionEvent = false): Observable<Event | ''> => {
    if (!scrollTarget || typeof scrollTarget.addEventListener !== 'function') {
      console.warn('`addEventListener` on ' + scrollTarget + ' (scrollTarget) is not a function. Skipping this target');
      return empty();
    }
    const scrollListener = this.scrollListeners.get(scrollTarget);
    if (scrollListener) {
      return scrollListener;
    }

    const srollEvent: Observable<Event> = Observable.create((observer: Subject<Event>) => {
      const eventName = 'scroll';
      const handler = (event: Event) => observer.next(event);
      const options = { passive: true, capture: false };
      scrollTarget.addEventListener(eventName, handler, options);
      let eventName1;
      if (transitionEvent) {
        eventName1 = 'transitionend';
        scrollTarget.addEventListener(eventName1, handler, options);
      }
      return (): void => {
        scrollTarget.removeEventListener(eventName, handler, options);
        if (transitionEvent) {
          scrollTarget.removeEventListener(eventName1, handler, options);
        }
        return null;
      };
    });

    const listener = this.sampleObservable(srollEvent);
    this.scrollListeners.set(scrollTarget, listener);
    return listener;
  };
}

interface ObserverOptions {
  root: Element | null;
  rootMargin?: string;
  threshold?: number;
}
