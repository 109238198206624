import { Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { HamburgerMenuService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmlHeaderService } from '../service/aml-header.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'am-hamburger-menu',
  templateUrl: './am-hamburger-menu.component.html'
})
export class AmHamburgerMenuComponent {
  showMenu$ = this.componentData.getContentSlot('NavigationBar').pipe(
    map((slotContent) => {
      return (
        slotContent.components?.length > 0 &&
        slotContent.components.filter((component) => component.typeCode === 'CategoryNavigationComponent').length > 0
      );
    })
  );
  protected subscription = new Subscription();
  constructor(
    private hamburgerMenuService: HamburgerMenuService,
    private amlHeaderService: AmlHeaderService,
    protected componentData: CmsService
  ) {}

  toggle(): void {
    this.hamburgerMenuService.toggle();
    if (this.hamburgerMenuService.isExpanded.value) {
      this.amlHeaderService.isShowAccountInfo.next(false);
    }
    this.amlHeaderService.deliveryAddStyle();
  }

  get isExpanded(): Observable<boolean> {
    return this.hamburgerMenuService.isExpanded;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
