import { ModuleWithProviders, NgModule } from '@angular/core';
import { stateMetaReducers } from './reducers/index';

@NgModule({})
export class AmStateModule {
  static forRoot(): ModuleWithProviders<AmStateModule> {
    return {
      ngModule: AmStateModule,
      providers: [...stateMetaReducers]
    };
  }
}
