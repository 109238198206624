<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.ITEM">
  <div class="main-title" *ngIf="showCollectionMethod">
    <ng-container [ngSwitch]="getTypeCollectionMethod(item.product.collectionMethod)">
      <span class="" *ngSwitchCase="collectionMethodType.SHOP_PICKUP">
        <am-svg-container [type]="svgType.INSTOREGREEN" svgDescription="Asia miles"></am-svg-container>
      </span>
      <span class="" *ngSwitchCase="collectionMethodType.HOME_DELIVERY"
        ><am-svg-container [type]="svgType.NEWCARGREEN" svgDescription="Asia miles"></am-svg-container
      ></span>
      <span class="" *ngSwitchCase="collectionMethodType.INFLIGHT_PICKUP">
        <am-svg-container [type]="svgType.INFLIGHTICONGREEN" svgDescription="Asia miles"></am-svg-container
      ></span>
      <span class="" *ngSwitchDefault
        ><am-svg-container [type]="svgType.EMAILGREEN" svgDescription="Asia miles"></am-svg-container
      ></span>
    </ng-container>
    <span>
      {{ 'amDelivery.collectionMethod.' + item.product.collectionMethod | cxTranslate }}
    </span>
  </div>
  <ng-container *ngIf="showGroupBy">
    <am-delivery
      [product]="item.product"
      [isInflightEnv]="isInflightEnv"
      [class.d-flex]="item.product.collectionMethod === collectionMethodType.SHOP_PICKUP"
      [deliveryPointOfService]="item.deliveryPointOfService"></am-delivery>
  </ng-container>

  <div class="am-item" [ngClass]="compact ? 'cx-compact row' : 'row'">
    <div class="col-sm-11 d-flex cart-left-image">
      <!-- Item Image -->
      <div class="col-3 cx-image-container" [class.pointer-events]="isInflightStock()">
        <a aria-label="Go to current product" (click)="onClickProductImg(item)" cxModal="dismiss">
          <am-media [container]="item.product.images?.PRIMARY" format="cartIcon"></am-media>
        </a>

        <!-- Item Quantity -->
        <div class="cx-value" *ngIf="isShowQuantity(item)">
          <am-item-counter
            [control]="quantityControl"
            [readonly]="!item.updateable || readonly"
            [productCode]="item.productCode"
            [max]="maxQuantity(item)">
          </am-item-counter>
        </div>
      </div>
      <!-- Item Information -->
      <div class="cx-info col-9">
        <div class="cx-info-container">
          <!-- Item Description -->
          <div *ngIf="item.product.brandName" class="cx-code font-bold">
            {{ item.product.brandName }}
          </div>
          <div *ngIf="item.product.name" class="cx-name">
            {{ item.product.name }}
          </div>

          <!-- Item Details Outlet -->
          <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"> </ng-template>

          <!-- Variants -->
          <ul
            class="cx-property"
            *ngIf="getCurrentBaseOptions(item.product.baseOptions, item.product.code); let productOption">
            <li *ngFor="let variant of productOption.selected?.variantOptionQualifiers; let i = index" class="cx-label">
              <ng-container *ngIf="i === 0; else elseVariant">
                <span class="variant-name">
                  <ng-container *ngIf="variant.qualifier === 'typeValue'; else elseQualifier">
                    {{ productOption.selected.typeName }}:
                  </ng-container>
                  <ng-template #elseQualifier> {{ variant.name }}: </ng-template>
                </span>
                <span class="variant-value font-bold">
                  <ng-container *ngIf="variant.qualifier === 'dateAndTime'; else elseVariantValue">
                    {{ variant.value | amDate : 'long' }}
                  </ng-container>
                  <ng-template #elseVariantValue>
                    {{ variant.value }}
                  </ng-template>
                </span>
              </ng-container>
              <ng-template #elseVariant>
                <span class="variant-name">
                  {{
                    !!productOption.selected?.variantTypeName ? productOption.selected?.variantTypeName : variant.name
                  }}:
                </span>
                <span class="variant-value font-bold">
                  {{ variant.value }}
                </span>
              </ng-template>
            </li>
          </ul>

          <!-- Item Price -->
          <div
            *ngIf="item.basePrice && item.product?.isGiveaway !== true && item.product.isGiftPromotion !== true"
            class="cx-price">
            <div class="cx-value">
              <div class="product-price">
                <am-product-price [product]="item.product">
                  <button
                    aria-label="Show discount details"
                    icon
                    class="link"
                    (click)="openPromotion()"
                    aria-label="promotion discount"
                    *ngIf="hasDiscount(item)">
                    <!-- <i class="amicon-info"></i> -->
                    <am-svg-container [type]="svgType.ICONINFOFILL" class="color-info icon-info-sm"></am-svg-container>
                  </button>
                </am-product-price>
              </div>
            </div>
            <div *ngIf="item.customTaxReminder" class="import-duties-included">
              <span [innerHtml]="item.customTaxReminder"></span>
            </div>
          </div>

          <div
            class="free-give-info"
            *ngIf="item.product?.isGiveaway === true || item.product.isGiftPromotion === true">
            <div class="free-price">
              {{ 'amProductItem.free' | cxTranslate }}
            </div>

            <div class="free-promotion-name" *ngIf="item.product?.potentialFreeGiftMessageRule?.title">
              {{ item.product?.potentialFreeGiftMessageRule?.title }}
            </div>
          </div>

          <div
            class="ree-text"
            *ngIf="
              country === 'HK' && item.product.specialProductType && item.product.specialProductType === 'ELECTRONIC'
            ">
            {{ 'amCartItems.reeText' | cxTranslate }}
            <span class="font-bold" *ngIf="!item.statutoryRemovalService">
              {{ 'amCartItems.TO_BE_DETERMINED' | cxTranslate }}
            </span>
            <span class="font-bold" *ngIf="item.statutoryRemovalService">
              {{ 'amCartItems.' + item.statutoryRemovalService | cxTranslate }}
            </span>
          </div>

          <div class="free-gift-promotion" *ngIf="item?.freeGiftPromotionRelatedInfo">
            <ng-container *ngFor="let freeGiftPromotion of item?.freeGiftPromotionRelatedInfo">
              <span (click)="onClickPromotionLabel(item, freeGiftPromotion.code)">{{ freeGiftPromotion.title }}</span>
            </ng-container>
          </div>

          <!-- svgType.ICONFAVORITESSELECTED -->
          <div
            *ngIf="
              item.product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP &&
              !item.product.isGiftPromotion &&
              !item.product.isGiveaway
            "
            class="add-favourite-list">
            <button
              (click)="onClickFavouriteIcon()"
              [attr.aria-label]="
                (isFavouriteProduct ? 'favouriteNotifyMe.addedFavouriteList' : 'favouriteNotifyMe.addToFavourite')
                  | cxTranslate
              ">
              <am-svg-container
                [type]="
                  isFavouriteProduct ? svgType.ICONFAVORITESSELECTED : svgType.ICONFAVORITESGREEN
                "></am-svg-container>
              <span *ngIf="isFavouriteProduct">{{ 'favouriteNotifyMe.addedFavouriteList' | cxTranslate }}</span>
              <span *ngIf="!isFavouriteProduct">{{ 'favouriteNotifyMe.addToFavourite' | cxTranslate }}</span>
            </button>
          </div>
        </div>

        <!-- Item Bundle Details Outlet -->
        <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"> </ng-template>
      </div>
    </div>
    <div class="col-sm-1 d-flex cart-actions">
      <!-- Actions -->
      <div *ngIf="(!readonly || options.isSaveForLater) && item.updateable" class="cart-actions-remove">
        <button
          aria-label="Remove current product from cart"
          class="close link cx-action-link"
          [disabled]="quantityControl.disabled"
          (click)="removeItem(item.product)"
          attr.aria-label="{{ 'amCartItems.removeFromCart' | cxTranslate }}">
          <!-- <span class="amicon-close color-primary"></span> -->
          <am-svg-container [type]="svgType.AMCLOSE" class="color-primary" svgDescription="Remove"></am-svg-container>
        </button>
      </div>
    </div>
  </div>

  <!-- flow one -->
  <!-- <div class="bottom-message" *ngIf="flightStatusStorageService.isInflightPickFlow">
    <ng-container
      *ngIf="item.product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP && isStoreOpenStatus !== false">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        iconStyle="margin:0 5px 0 0px "
        messageType="error"
        [hasBackgroudColor]="false"
        [messageContent]="
          'amCartItems.error.deliveryMethodItemUnavailable'
            | cxTranslate : { fulfillmentName: item.product.fulfillmentName }
        "></am-message-tip>
    </ng-container>
  </div> -->

  <!-- flow two -->
  <div class="bottom-message" *ngIf="!flightStatusStorageService.isInflightPickFlow">
    <ng-container *ngIf="item.product.collectionMethod === collectionMethod.INFLIGHT_PICKUP">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        iconStyle="margin:0 5px 0 0px "
        messageType="error"
        [hasBackgroudColor]="false"
        [messageContent]="
          'amCartItems.error.deliveryMethodItemUnavailable'
            | cxTranslate : { fulfillmentName: item.product.fulfillmentName }
        "></am-message-tip>
    </ng-container>
  </div>

  <div
    class="bottom-message"
    *ngIf="
      !isInflightStock() &&
      (item.cannotRedeemed ||
        item.cannotDelivery ||
        !item.isPurchasable ||
        lowStockFlag ||
        item.isOutOfStock ||
        item.isOverMinimumMiles ||
        item.productShouldBeHidden ||
        item.isMaxRedemptionTimes ||
        !item.statutoryRemovalService ||
        !item.enableInflightRedemption ||
        item.quantity === item.maxOrderQuantity ||
        isStoreOpen)
    ">
    <ng-container *ngIf="item.quantity === item.maxOrderQuantity && item.freeGiftPromotionRelatedInfo.length <= 0">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGYELLOW"
        messageType="warning-yellow"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.alert.maximumQuantity' | cxTranslate"></am-message-tip>
    </ng-container>

    <ng-container *ngIf="item.cannotRedeemed">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.error.redemptionPeriodOver' | cxTranslate"></am-message-tip>
    </ng-container>
    <ng-container
      *ngIf="
        item.isOutOfStock &&
        !(isStoreOpenStatus == false && item.product.collectionMethod == collectionMethod.INFLIGHT_PICKUP)
      ">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.alert.outOfStock' | cxTranslate"></am-message-tip>

      <ng-container *ngIf="!item.product.baseOptions?.length; else errorBaseOptions">
        <am-message-tip
          [iconType]="svgType.ICONWARNINGRED"
          messageType="error"
          [hasBackgroudColor]="false"
          [isSmallMessageTip]="true"
          [messageContent]="'amCartItems.error.outOfStock' | cxTranslate"></am-message-tip>
      </ng-container>
      <ng-template #errorBaseOptions>
        <am-message-tip
          [iconType]="svgType.ICONWARNINGRED"
          messageType="error"
          [hasBackgroudColor]="false"
          [isSmallMessageTip]="true"
          [messageContent]="'amCartItems.error.variantOutOfStock' | cxTranslate"></am-message-tip>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="item.isOverMinimumMiles">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartDetails.slider.error.unredeemable' | cxTranslate"></am-message-tip>
    </ng-container>

    <ng-container *ngIf="item.cannotDelivery">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.error.deliveryCountryNotAvailable' | cxTranslate"></am-message-tip>
    </ng-container>
    <ng-container *ngIf="item.isMaxRedemptionTimes">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.error.maximumTimes' | cxTranslate"></am-message-tip>
    </ng-container>
    <ng-container *ngIf="!item.isPurchasable && item.alertMessagesForExclusive">
      <ng-container *ngFor="let message of item.alertMessagesForExclusive">
        <am-message-tip
          [iconType]="svgType.ICONWARNINGRED"
          messageType="error"
          [hasBackgroudColor]="false"
          [isSmallMessageTip]="true"
          [messageContent]="message"></am-message-tip>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="item.productShouldBeHidden && item.alertMessagesForHiddenPageActive">
      <ng-container *ngFor="let message of item.alertMessagesForHiddenPageActive">
        <am-message-tip
          [iconType]="svgType.ICONWARNINGRED"
          messageType="error"
          [hasBackgroudColor]="false"
          [isSmallMessageTip]="true"
          [messageContent]="message"></am-message-tip>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="lowStockFlag">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGYELLOW"
        messageType="warning-yellow"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.alert.lowStock' | cxTranslate">
      </am-message-tip>
      <am-message-tip
        [iconType]="svgType.ICONWARNINGYELLOW"
        messageType="warning-yellow"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.warning.quantityAdjustment' | cxTranslate">
      </am-message-tip>
    </ng-container>
    <ng-container
      *ngIf="country === 'HK' && item.product?.specialProductType === 'ELECTRONIC' && !item.statutoryRemovalService">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGYELLOW"
        messageType="warning-yellow"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.alert.reeNotInHkEeMsg' | cxTranslate">
      </am-message-tip>
    </ng-container>
    <ng-container *ngIf="!item.enableInflightRedemption">
      <am-message-tip
        [iconType]="svgType.ICONWARNINGRED"
        messageType="error"
        [hasBackgroudColor]="false"
        [isSmallMessageTip]="true"
        [messageContent]="'amCartItems.error.excludeInFlight' | cxTranslate"></am-message-tip>
    </ng-container>
  </div>

  <ng-container *ngIf="item.isDelisted || item.isOffline">
    <am-message-tip
      [iconType]="svgType.ICONWARNINGRED"
      iconStyle="margin:0 5px 0 0px "
      messageType="error"
      [hasBackgroudColor]="false"
      [messageContent]="
        (item.hasAvailedVariant ? 'amCartItems.error.optionUnavailable' : 'amCartItems.error.allItemUnavailable')
          | cxTranslate
      "></am-message-tip>
  </ng-container>
</ng-template>

<am-favorite-notify-reminder></am-favorite-notify-reminder>
