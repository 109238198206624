import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Facet, RoutingService, WindowRef } from '@spartacus/core';
import { FacetCollapseState, FacetGroupCollapsedState, FacetList } from '@spartacus/storefront';
import UrlHelper from '@utils/url-helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AmProductFacetService } from './product-facet.service';

export const onlyCashQueryParm =
  ':paymentMethod:MilesPlusCash:paymentMethod:CashOnly:paymentMethod:FullCashOrMpcWithMinMiles';

export const ACCORDION_INFO_KEY = 'ACCORDION_INFO';

/**
 * Provides access to the facets as well as their UI state. The UI state
 * represents user related changes on the facets, such as expanding or
 * collapsing a facet group or expanding the number of _visible_ facet values.
 */
@Injectable({
  providedIn: 'root'
})
export class AmFacetService {
  /**
   * An internal map where we keep the UI state of the facets.
   */
  protected facetState = new Map<string, BehaviorSubject<FacetCollapseState>>();
  searchTextFromRouter: string = '';

  public facetStatus = {
    sort_by: false,
    popular_filter: false,
    category: false,
    brand: false,
    miles_range: false,
    best_deal: false
  };

  constructor(
    protected productFacetService: AmProductFacetService,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    private router: Router
  ) {
    // this.getRouterState();
  }

  /**
   * Observes the facets for the given page and configures the initial UI state.
   *
   * Facets are configured on each emission so that we keep the facet UI state.
   * This is mainly done to keep the state during usage of the facet, but also
   * benefitial when the facets are rebuild while using them.
   */
  facetList$: Observable<FacetList> = this.productFacetService.facetList$.pipe(
    tap((facetList) => {
      facetList.facets.forEach((facet) => this.initialize(facet));
    })
  );

  /**
   * Returns the observed UI state for the facet.
   *
   * The state is initialized using the `initialize` method.
   */
  getState(facet: Facet): Observable<FacetCollapseState> {
    this.initialize(facet);
    return this.facetState.get(facet.name);
  }

  /**
   * Returns the UI state for the facet.
   *
   * The state is initialized using the `initialize` method.
   */
  protected getStateSnapshot(facet: Facet): FacetCollapseState {
    return (this.getState(facet) as BehaviorSubject<FacetCollapseState>).value;
  }

  /**
   * Toggles the facet expanded state. If the expanded state becomes false,
   * the visible values will decrease to the top values only.
   *
   * If the optional value argument is provided the expanded state will be set
   * to this value, regardless of the current `expanded` state.
   */
  toggle(facet: Facet, isExpanded: boolean): void {
    const state = this.getStateSnapshot(facet);

    const toggledState = {
      toggled: isExpanded ? FacetGroupCollapsedState.COLLAPSED : FacetGroupCollapsedState.EXPANDED
    } as FacetCollapseState;

    if (toggledState.toggled === FacetGroupCollapsedState.COLLAPSED) {
      toggledState.maxVisible = state.topVisible;
    }

    this.updateState(facet, toggledState);
  }

  /**
   * Increases the visible values to the maximum values of the facet.
   */
  increaseVisibleValues(facet: Facet): void {
    this.updateState(facet, { maxVisible: facet.values.length });
  }

  /**
   * Decreases the visible values to the topValueCount.
   *
   * The topValueCount defaults to 6, but can be controlled in
   * the backend as well.
   */
  decreaseVisibleValues(facet: Facet): void {
    this.updateState(facet, { maxVisible: facet.topValueCount });
  }

  /**
   * Persists the facet state and initializes the default values for the top
   * and max visible values.
   */
  protected initialize(facet: Facet): void {
    const topFacets = facet.topValueCount > 0 ? facet.topValueCount : facet.values?.length || 0;
    if (!this.hasState(facet)) {
      this.facetState.set(
        facet.name,
        new BehaviorSubject({
          topVisible: topFacets,
          maxVisible: topFacets
        } as FacetCollapseState)
      );
    }
  }

  /**
   * Updates the state of the facet in the local facet map.
   */
  protected updateState(facet: Facet, property: FacetCollapseState): void {
    const state = { ...this.getStateSnapshot(facet), ...property };
    this.facetState.get(facet.name).next(state);
  }

  protected hasState(facet: Facet): boolean {
    return this.facetState.has(facet.name);
  }

  getLinkParams(value: string, categoryType?: string, categoryCode?: string, categoryCodeFromRouter?: string): any {
    let handleNCRStr = new DOMParser().parseFromString(value, 'text/html').body.firstChild.textContent;
    return Object.assign(
      {
        query: new HttpUrlEncodingCodec().decodeValue(handleNCRStr).replace(/\+/g, ' ')
      },
      categoryType === 'Categories' && { categoryCode: categoryCode },
      !!categoryType && categoryType !== 'Categories' && { categoryCode: categoryCodeFromRouter }
    );
  }

  togglePaymentMethod(payByCashStatus: boolean, categoryCodeFromRouter?: string, searchTextFromRouter?: string) {
    const url = this.winRef.location.href;
    let originQuery = UrlHelper.getSearchParamByName('query', url) ?? '';
    const zonePriceMatch = originQuery.match(/:zonePrice:\d+_\d+/);
    if (zonePriceMatch?.length > 0) {
      originQuery = originQuery.replace(zonePriceMatch[0], '');
    }
    let query = originQuery;
    if (!!searchTextFromRouter && !query.includes(searchTextFromRouter)) {
      query = searchTextFromRouter + query;
    }

    let commomQueryParam = `:onlineDate-desc:allCategories:${categoryCodeFromRouter ?? 1}`;
    if (categoryCodeFromRouter && categoryCodeFromRouter !== '1') {
      commomQueryParam = `:onlineDate-desc:allCategories:${categoryCodeFromRouter}`;
    } else {
      commomQueryParam = `:onlineDate-desc`;
    }

    if (!originQuery) {
      query += commomQueryParam;
    }
    if (payByCashStatus === true && !query.includes(onlyCashQueryParm)) {
      query += onlyCashQueryParm;
      this.router.navigate([], {
        queryParams: {
          ...UrlHelper.parseQueryStringToObject(url),
          query,
          categoryCode: categoryCodeFromRouter
        }
      });
    } else if (payByCashStatus === false) {
      if (query.includes(commomQueryParam + onlyCashQueryParm)) {
        query = query.replace(commomQueryParam + onlyCashQueryParm, '');
      } else {
        query = query.replace(onlyCashQueryParm, '');
      }
      this.router.navigate([], {
        queryParams: {
          query
        }
      });
    }
  }

  storeAccordionStatus(accordionInfoStorage): void {
    this.removeAccordionStatus();
    this.winRef.localStorage?.setItem(ACCORDION_INFO_KEY, JSON.stringify(accordionInfoStorage));
  }

  removeAccordionStatus(): void {
    this.winRef.localStorage?.removeItem(ACCORDION_INFO_KEY);
  }

  getAccordionStatus(key: string): any {
    return this.winRef.localStorage?.getItem(ACCORDION_INFO_KEY)
      ? JSON.parse(this.winRef.localStorage?.getItem(ACCORDION_INFO_KEY))[key]
      : null;
  }

  resetFacetStatus(): void {
    this.facetStatus = {
      sort_by: false,
      popular_filter: false,
      category: false,
      brand: false,
      miles_range: false,
      best_deal: false
    };
  }
}
