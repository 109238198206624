import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { AmCartCouponComponent } from './cart-coupon.component';
import { CoBrandCouponDialogModule } from './co-brand-coupon-dialog/co-brand-coupon-dialog.module';

@NgModule({
  declarations: [AmCartCouponComponent],
  exports: [AmCartCouponComponent],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CoBrandCouponDialogModule,
    KeyboardFocusModule,
    SvgContainerModule
  ]
})
export class AmCartCouponModule {}
