import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { select, Store } from '@ngrx/store';
import {
  ActivatedRouterStateSnapshot,
  CurrencyService,
  LanguageService,
  Product,
  ProductSearchService,
  RouterState,
  RoutingService
} from '@spartacus/core';
import UrlHelper from '@utils/url-helper';
import { combineLatest, Observable, Subject, Subscription, using } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, take, tap } from 'rxjs/operators';
import { activeTabCodeMap } from '../constants/favorite-notify-products-contants';
import { FavoriteAndNotifyProductListActions } from '../store/actions';
import { StateWithFavoriteAndNotifyProducts } from '../store/favorite-notify-product-list-state';
import { FavoriteNotifyProductListSelector } from '../store/selectors';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';

export interface SearchCriteria {
  type?: string;
  sortCode?: string;
}

export interface FavoriteProduct {
  product?: Product;
  productName?: string;
  isDeliveryCountry?: boolean;
  isDiscontinued?: boolean;
  emailTriggerTime?: string;
  isInRedeemPeriod?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteNotifyProductsService {
  protected subscription = new Subscription();

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected routing: RoutingService,
    protected router: Router,
    protected languageService: LanguageService,
    protected countryService: CountryService,
    protected productSearchService: ProductSearchService,
    protected currencyService: CurrencyService,
    protected store: Store<StateWithFavoriteAndNotifyProducts>,
    protected authService: AmAuthService
  ) {}

  public showReminderSub: Subject<any> = new Subject<any>();
  public showReminderObs: Observable<any> = this.showReminderSub.asObservable();

  readonly products$: Observable<FavoriteProduct[]> = using(
    () => this.searchByRouting$.subscribe(),
    () => this.searchResults$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  protected searchResults$: Observable<any> = this.getDisplayProducts();

  protected searchByRouting$: Observable<ActivatedRouterStateSnapshot> = combineLatest([
    this.routing.getRouterState().pipe(
      distinctUntilChanged((x, y) => {
        // router emits new value also when the anticipated `nextState` changes
        // but we want to perform search only when current url changes
        return x.state.url === y.state.url;
      })
    ),
    ...this.siteContext
  ]).pipe(
    debounceTime(0),
    map(([routerState, ..._context]) => (routerState as RouterState).state),
    tap((state: ActivatedRouterStateSnapshot) => {
      const activeType = this.getActiveTypeFromParam(state.queryParams);
      const sortCode = state.queryParams?.sortCode;
      this.loadFavoriteAndNotifyData({ activeType, sortCode }, true);
    })
  );

  getActiveTypeFromParam(queryParams) {
    let activeType = activeTabCodeMap.favourite;
    if (queryParams?.type) {
      activeType =
        queryParams.type === activeTabCodeMap.favourite ? activeTabCodeMap.favourite : activeTabCodeMap.notify;
    }

    return activeType;
  }

  route(queryParams: SearchCriteria): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute
    });
  }

  private get siteContext(): Observable<string>[] {
    return [this.languageService.getActive(), this.currencyService.getActive(), this.countryService.getActive()];
  }

  loadFavoriteAndNotifyData(
    queryParams?: { activeType?: string; sortCode?: string },
    forceReload?: boolean,
    loadedCallback?: Function
  ) {
    this.subscription.add(
      combineLatest([this.getMyListPageData(), this.authService.isUserLoggedIn()])
        .pipe(take(1))
        .subscribe(([listData, isLoggedIn]) => {
          if (isLoggedIn && (listData.loaded === false || forceReload)) {
            this.store.dispatch(new FavoriteAndNotifyProductListActions.LoadFavoriteAndNotifyProducts(queryParams));
          } else if (loadedCallback) {
            loadedCallback();
          }
        })
    );
  }

  sort(sortCode: string) {
    this.route({ sortCode });
  }

  setActiveType(activeType: string) {
    this.store.dispatch(new FavoriteAndNotifyProductListActions.SetActiveType({ activeType }));
  }

  setMyFavoriteAndNotifyMe(data) {
    this.store.dispatch(new FavoriteAndNotifyProductListActions.SaveFavoriteAndNotifyProducts(data));
  }

  getMyListPageData() {
    return this.store.pipe(select(FavoriteNotifyProductListSelector.getMyListPageData));
  }

  getDisplayProducts() {
    return this.store.pipe(select(FavoriteNotifyProductListSelector.getDisplayProducts));
  }

  addProductIntoFavoriteList(productCode: string) {
    const queryParams: { productCode: string; sortCode?: string } = { productCode };
    const sortCode = UrlHelper.getSearchParamByName('sortCode');
    if (sortCode) {
      queryParams.sortCode = sortCode;
    }
    this.store.dispatch(new FavoriteAndNotifyProductListActions.AddProductIntoFavoriteList(queryParams));
  }

  addProductIntoNotifyList(productCode: string) {
    const queryParams: { productCode: string; sortCode?: string } = { productCode };
    const sortCode = UrlHelper.getSearchParamByName('sortCode');
    if (sortCode) {
      queryParams.sortCode = sortCode;
    }
    this.store.dispatch(new FavoriteAndNotifyProductListActions.AddProductIntoNotifyList(queryParams));
  }

  getActiveType() {
    return this.store.pipe(select(FavoriteNotifyProductListSelector.getActiveType));
  }

  getFavoirteList() {
    return this.store.pipe(select(FavoriteNotifyProductListSelector.getFavoriteProducts));
  }

  getNotifyList() {
    return this.store.pipe(select(FavoriteNotifyProductListSelector.getNotifyProducts));
  }

  removeProductFromFavoirteList(productCode: string, removeAllVariantProducts?: boolean) {
    const queryParams: { productCode: string; sortCode?: string; removeAllVariantProducts?: boolean } = { productCode };
    const sortCode = UrlHelper.getSearchParamByName('sortCode');
    if (sortCode) {
      queryParams.sortCode = sortCode;
    }
    if (removeAllVariantProducts !== undefined) {
      queryParams.removeAllVariantProducts = removeAllVariantProducts;
    }
    return this.store.dispatch(new FavoriteAndNotifyProductListActions.RemoveProductFromFavoriteList(queryParams));
  }

  removeProductFromNotifyList(productCode: string) {
    const queryParams: { productCode: string; sortCode?: string } = { productCode };
    const sortCode = UrlHelper.getSearchParamByName('sortCode');
    if (sortCode) {
      queryParams.sortCode = sortCode;
    }
    return this.store.dispatch(new FavoriteAndNotifyProductListActions.RemoveProductFromNotifyList(queryParams));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
