/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { PaginationConfig } from '@spartacus/storefront';

export const defaultPaginationConfig: PaginationConfig = {
  pagination: {
    addStart: false,
    addEnd: false,
    addPrevious: true,
    addNext: true,
    addDots: true,
    addFirst: true,
    addLast: true,
    rangeCount: 9,
    substituteDotsForSingularPage: true,
    previousLabel: '←',
    nextLabel: '→',
    xsRangeCount: 5
  }
};
