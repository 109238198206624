<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-loading-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <am-loading></am-loading>
      </div>
      <div class="cx-dialog-body modal-body">
        <div class="loading-box">
          <div class="loading-body-title">
            {{ (activeTab === 'inflight' ? 'amCartDetails.inflightCart' : 'amCartDetails.groundCart') | cxTranslate }}
          </div>
          <div class="loading-body-content">{{ 'amCartDetails.cartLoadingContent' | cxTranslate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
