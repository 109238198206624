import { Injectable } from '@angular/core';
import { LanguageService } from '@spartacus/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh';
import 'dayjs/locale/zh-tw';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// require('dayjs/locale/en');
// require('dayjs/locale/zh');
// require('dayjs/locale/zh-tw');
// require('dayjs/locale/ja');
// require('dayjs/locale/ko');

@Injectable({
  providedIn: 'root'
})
export class DayjsService {
  changeLanguage$: Observable<string>;

  constructor(protected languageService: LanguageService) {
    this.changeLanguage$ = this.languageService.getActive().pipe(
      map((lang) => {
        let l;
        if (lang === 'zh_TW') {
          l = 'zh-tw';
        } else {
          l = lang;
        }
        dayjs.locale(l);
        return lang;
      })
    );
  }

  initDayjs(): Observable<string> {
    return this.changeLanguage$;
  }
}
