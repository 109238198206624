import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { HeadingsComponent } from './headings.component';

@NgModule({
  declarations: [HeadingsComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HeadingComponent: {
          // typeCode
          component: HeadingsComponent
        }
      }
    } as CmsConfig)
  ]
})
export class HeadingsModule {}
