import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { AmCartItemListModule } from '../cart-item-list/cart-item-list.module';
import { AmCartMessagesModule } from '../cart-messages/cart-messages.module';
import { AmCartTotalsModule } from '../cart-totals/cart-totals.module';
import { AmCartDetailFlow1ContainerComponent } from './am-cart-detail-flow1-container.component';

@NgModule({
  declarations: [AmCartDetailFlow1ContainerComponent],
  imports: [CommonModule, AmCartMessagesModule, AmCartTotalsModule, AmCartItemListModule, I18nModule],
  exports: [AmCartDetailFlow1ContainerComponent]
})
export class AmCartDetailFlow1ContainerModule {}
