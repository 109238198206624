import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
  Facet,
  Occ,
  OccConfig,
  ProductSearchPage,
  PRODUCT_NORMALIZER
} from '@spartacus/core';
import { escaper } from '@utils/escaper';
// import { decode } from 'he';
// import * as escaper from "true-html-escape";

@Injectable({ providedIn: 'root' })
export class AmProductSearchPageNormalizer implements Converter<Occ.ProductSearchPage, ProductSearchPage> {
  constructor(private converterService: ConverterService, protected config: OccConfig) {}

  /**
   * Specifies the minimal number of top values in case
   * non have been setup by the business.
   */
  protected DEFAULT_TOP_VALUES = 5;

  convert(source: Occ.ProductSearchPage, target: ProductSearchPage = {}): ProductSearchPage {
    target = {
      ...target,
      ...(source as any)
    };

    this.normalizeFacets(target);
    if (target.breadcrumbs && target.breadcrumbs.length === 0) {
      delete target.breadcrumbs;
    }
    if (target.queryResult?.crossSaleImages1) {
      target.queryResult = {
        ...target.queryResult,
        crossSaleImages1: this.crossSaleImagesUrl(target.queryResult.crossSaleImages1)
      };
    }
    if (target.queryResult?.crossSaleImages2) {
      target.queryResult = {
        ...target.queryResult,
        crossSaleImages2: this.crossSaleImagesUrl(target.queryResult.crossSaleImages2)
      };
    }
    if (target.queryResult?.bannerImageUrl) {
      target.queryResult = {
        ...target.queryResult,
        bannerImageUrl: this.addBaseUrl(target.queryResult.bannerImageUrl)
      };
    }
    if (source.products) {
      target.products = source.products.map((product) => this.converterService.convert(product, PRODUCT_NORMALIZER));
    }
    return target;
  }

  private normalizeFacets(target: ProductSearchPage): void {
    this.normalizeFacetValues(target);
    // this.normalizeUselessFacets(target);
  }

  protected getBaseUrl(): string {
    return this.config.backend?.media?.baseUrl ?? this.config.backend?.occ?.baseUrl ?? '';
  }

  protected addBaseUrl(url) {
    if (new RegExp(/^(http|data:image|\/\/)/i).test(url)) {
      return url;
    }
    return this.getBaseUrl() + url;
  }
  private crossSaleImagesUrl(crossSaleImages) {
    if (crossSaleImages) {
      return {
        desktopImage: this.addBaseUrl(crossSaleImages?.desktopImage),
        mobileImage: this.addBaseUrl(crossSaleImages?.mobileImage),
        tabletImage: this.addBaseUrl(crossSaleImages?.tabletImage),
        url: crossSaleImages?.url
      };
    }
    return {};
  }

  /**
   * The (current) backend returns facets with values that do not contribute
   * to the facet navigation much, as the number in the result list will not get
   * behavior, see https://jira.hybris.com/browse/CS-427.
   *
   * As long as this is not in place, we manually filter the facet from the list;
   * any facet that does not have a count < the total results will be dropped from
   * the facets.
   */
  private normalizeUselessFacets(target: ProductSearchPage): void {
    if (target.facets) {
      target.facets = target.facets.filter((facet) => {
        return (
          !target.pagination ||
          !target.pagination.totalResults ||
          ((!facet.hasOwnProperty('visible') || facet.visible) &&
            facet.values &&
            facet.values.find((value) => {
              return value.selected || value.count < target.pagination.totalResults;
            }))
        );
      });
    }
  }

  /*
   * In case there are so-called `topValues` given for the facet values,
   * values are obsolete.
   *
   * `topValues` is a feature in Adaptive Search which can limit a large
   * amount of facet values to a small set (5 by default). As long as the backend
   * provides all facet values AND topValues, we normalize the data to not bother
   * the UI with this specific feature.
   */
  private normalizeFacetValues(target: ProductSearchPage): void {
    if (target.facets) {
      target.freeTextSearch = escaper.decode(target.freeTextSearch);
      target.facets = target.facets.map((facetSource: Facet) => {
        const { topValues, ...facetTarget } = facetSource;
        facetTarget.topValueCount = topValues?.length > 0 ? topValues.length : this.DEFAULT_TOP_VALUES;
        if (facetTarget?.values && facetTarget.code === 'brandName') {
          facetTarget.values = facetTarget.values.slice(0).sort((facetValue1, facetValue2) => {
            return facetValue1.name.charCodeAt(0) - facetValue2.name.charCodeAt(0);
          });
        }
        return facetTarget;
      });
    }
  }
}
