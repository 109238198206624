import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsmComponentService } from '@spartacus/asm/components';
import { OccEndpointsService, WindowRef } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { AmAsmAuthService } from './am-asm-auth.service';
import { AmCsAgentAuthService } from './am-csagent-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AmAsmComponentService extends AsmComponentService {
  protected subscription = new Subscription();
  constructor(
    protected authService: AmAsmAuthService,
    protected csAgentAuthService: AmCsAgentAuthService,
    protected winRef: WindowRef,
    protected httpClient: HttpClient,
    protected occEndpointSvc: OccEndpointsService
  ) {
    super(authService, csAgentAuthService, winRef);
  }

  logoutCustomerSupportAgentAndCustomer(): void {
    this.csAgentAuthService.logoutCustomerSupportAgent();
  }

  logoutCustomer(customerId?: string): void {
    const personifyStopUrl = this.occEndpointSvc.getBaseUrl() + '/assisted-service/personify-stop';
    if (!!customerId) {
      let params: HttpParams = new HttpParams().set('emulator', customerId);
      this.subscription.add(
        this.httpClient.post(personifyStopUrl, params, {}).subscribe(() => {
          this.authService.setAsmEndSessionStatus();
        })
      );
    } else {
      this.authService.setAsmEndSessionStatus();
    }
  }

  queryUserAgent(): Observable<any> {
    const url = this.occEndpointSvc.getBaseUrl() + '/assisted-service/agent';
    return this.httpClient.get(url);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
