<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="change-flight-dialog">
      <span aria-hidden="true" class="is-visually-hidden"></span>
      <div class="cx-dialog-header modal-header d-flex">
        <span class="title" #title>{{ 'changeFlightPopup.changeFlightTitle' | cxTranslate }}</span>
        <button (click)="closeModal()" class="close-btn">
          <am-svg-container [type]="svgType.CLOSEBLUESM" [style]="style_close_btn"></am-svg-container>
        </button>
      </div>

      <div class="modal-body">
        <div class="change-flight-content" #changeFlightContent>
          {{ 'changeFlightPopup.changeFlightContent' | cxTranslate }}
        </div>
      </div>

      <div class="change-flight-button">
        <button class="button-secondary btn-margin" (click)="closeModal()">
          {{ 'changeFlightPopup.stay' | cxTranslate }}
        </button>

        <button class="button-primary" (click)="leaveShop()" (blur)="onLastElementTab()">
          {{ 'changeFlightPopup.leave' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
