<div class="am-slider-toggle-button">
  <div class="button-wrapper">
    <input
      type="checkbox"
      [id]="name"
      [(ngModel)]="status"
      [disabled]="disabled"
      [cxFocus]="{ key: 'toggle' + label }"
      (keyup.enter)="changed.emit(!status)"
      (change)="changed.emit(status)" />
    <label class="toggle-button-switch" [for]="name">
      <span class="circle">
        <span class="is-visually-hidden">{{ name }}</span>
      </span>
    </label>
  </div>
  <span>
    {{ label }}
  </span>
</div>
