import { Component, ElementRef, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AmSearchResults } from '@components/content/mulit-search-box/search-box/am-search-box.model';
import { AmSearchBoxService } from '@components/content/mulit-search-box/search-box/am-search-box.service';
import { SEARCH_BOX_CONTANST } from '@components/layout/globalPopup/contants/global-popup.contansts';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { HotTags } from '@model/search.model';
import {
  CmsSearchBoxComponent,
  CmsService,
  ProductSearchPage,
  ProductSearchService,
  RoutingService,
  WindowRef
} from '@spartacus/core';
import {
  BREAKPOINT,
  BreakpointService,
  CmsComponentData,
  GenericLinkComponentService,
  SearchBoxComponent,
  SearchBoxComponentService
} from '@spartacus/storefront';
import CookiesHelper, { CookiesNameSpace } from '@utils/cookies/cookies.helper';
import { combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap
} from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';

@Component({
  selector: 'am-search-dialog',
  templateUrl: './search-dialog.component.html'
})
export class SearchDialogComponent extends SearchBoxComponent {
  @Input() isOpen: boolean = false;
  @Input() placeholder;
  @Output() onClose = new EventEmitter();
  // results: AmSearchResults;
  amResults$: Observable<AmSearchResults>;
  getResults$;
  subscription = new Subscription();
  svgType = svgConfig;
  searchValue = '';
  hotTags: HotTags;
  private searchTerm$ = new Subject<string>();
  private suggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX;
  private ourSuggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX;
  private markSuggestionsMax = SEARCH_BOX_CONTANST.SUGGESTED_RESULTS_MAX;
  private markProducts = Array(SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_L).fill('');
  displayBlock = {
    popularSearches: false,
    popularBrands: false,
    cathayFeatures: false,
    psORpb: false,
    suggestedResults: false,
    suggestedItems: false,
    noResult: null,
    isLoading: false
  };
  get isEmptySearch() {
    return this.searchValue.trim() === '';
  }
  @ViewChild('searchInput') private searchInputEle: ElementRef;

  constructor(
    protected launchDialogService: AmLaunchDialogService,
    protected amServerBoxService: AmSearchBoxService,
    public router: Router,
    protected searchBoxComponentService: SearchBoxComponentService,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    protected cmsService: CmsService,
    protected productSearchService: ProductSearchService,
    private breakpointService: BreakpointService,
    protected genericLinkComponentService: GenericLinkComponentService,
    protected amConfigService: AmConfigService
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
    this.breakpointService.isEqual(BREAKPOINT.md).subscribe((isMd) => {
      this.setSuggestedItemsMax(isMd);
    });
    this.amResults$ = this.results$.pipe(
      debounceTime(100),
      // distinctUntilChanged(),
      map((results: AmSearchResults) => {
        this.displayBlock.suggestedResults = results.suggestions?.length > 0;
        this.displayBlock.suggestedItems = results.products?.length > 0;
        this.displayBlock.noResult = results.suggestions?.length === 0 && results.products?.length === 0;
        this.setSuggestedItemsMax();
        const suggestions = results.suggestions?.slice(0, SEARCH_BOX_CONTANST.SUGGESTED_RESULTS_MAX) ?? [];
        const products = results.products?.slice(0, this.suggestedItemsMax) ?? [];
        const suggestProducts = results.suggestProducts?.slice(0, this.ourSuggestedItemsMax) ?? [];
        this.displayBlock.isLoading = false;
        return {
          ...results,
          suggestions,
          products,
          suggestProducts
        };
      })
    );
  }

  ngOnInit(): void {
    try {
      this.amServerBoxService.searchCookies =
        JSON.parse(CookiesHelper.getCookie(CookiesNameSpace.TEXT_SEARCH_HISTORY)) || [];
    } catch (err) {
      this.amServerBoxService.searchCookies = [];
    }
    this.subscription.add(
      this.amServerBoxService.getSearchBoxState().subscribe((res) => {
        if (res) {
          this.hotTags = res;
          this.displayBlock.popularSearches = res.popularTagsList?.length > 0;
          this.displayBlock.popularBrands = res.popularBrands?.length > 0;
          this.displayBlock.cathayFeatures = res.cathayFeature?.length > 0;
          this.displayBlock.psORpb = this.displayBlock.popularSearches || this.displayBlock.popularBrands;
        }
      })
    );
    // this.amServerBoxService.getSearchBoxState().subscribe((state) => {
    //   if (state) {
    //     this.hotTags = state;
    //     this.displayBlock.ps = state.popularTagsList?.length > 0;
    //     this.displayBlock.pb = state.popularBrands?.length > 0;
    //     this.displayBlock.cf = state.cathayFeature?.length > 0;
    //     this.displayBlock.psOpb = this.displayBlock.ps || this.displayBlock.pb;
    //   }
    // })
    // this.subscription.add(
    //   this.router.events
    //     // .pipe(filter(event => event instanceof NavigationEnd))
    //     .subscribe(() => {
    //       this.closeDialog();
    //     })
    // );
    this.subscription.add(
      this.searchTerm$
        .pipe(
          debounceTime(500),
          // distinctUntilChanged(),
          filter(() => this.isOpen),
          switchMap((searchValue) => of(super.search(searchValue)))
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  open() {
    super.open();
  }

  close(event: UIEvent) {
    super.close(event, true);
    this.onClose.emit();
  }

  // closeDialog() {
  //   this.onClose.emit();
  //   // this.launchDialogService.closeDialog('close icon');
  // }

  handleClearInput() {
    this.clearInput();
    timer(1).subscribe(() => {
      this.activeSearchInput();
    });
  }

  clearInput() {
    this.searchValue = '';
    this.searchBoxComponentService.clearResults();
  }

  clearCookies() {
    this.amServerBoxService.searchCookies = [];
    CookiesHelper.deleteCookie(CookiesNameSpace.TEXT_SEARCH_HISTORY);
  }

  goSearch(evt: UIEvent, enterFlag?: boolean) {
    if (!this.isEmptySearch) {
      const searchValue = this.searchValue;
      this.afterLaunchSearch(enterFlag, searchValue);
      // launchSearchResult will close
      // this.close(evt, true);
      this.clearInput();
      this.launchSearchResult(evt, searchValue);
      this.updateChosenWord(searchValue);
    }
  }

  searchTerm() {
    if (!this.isEmptySearch) {
      if (
        this.config?.minCharactersBeforeRequest &&
        this.searchValue.length >= +this.config.minCharactersBeforeRequest
      ) {
        this.displayBlock.isLoading = true;
      }
      this.searchTerm$.next(this.searchValue);
    } else {
      this.searchBoxComponentService.clearResults();
    }
  }

  activeSearchInput() {
    this.searchInputEle.nativeElement.focus();
  }

  saveSearchCookies(searchValue: string) {
    if (searchValue.trim().length > 0 && this.amServerBoxService.searchCookies.indexOf(searchValue) < 0) {
      const num = this.amServerBoxService.searchCookies.length;
      if (num > SEARCH_BOX_CONTANST.SEARCH_HISTORY_MAX) {
        this.amServerBoxService.searchCookies.splice(0, num - SEARCH_BOX_CONTANST.SEARCH_HISTORY_MAX + 1);
      } else if (num === SEARCH_BOX_CONTANST.SEARCH_HISTORY_MAX) {
        this.amServerBoxService.searchCookies.pop();
      }
      this.amServerBoxService.searchCookies.unshift(searchValue);
      CookiesHelper.setCookie(
        CookiesNameSpace.TEXT_SEARCH_HISTORY,
        JSON.stringify(this.amServerBoxService.searchCookies)
      );
    }
  }

  redirectByKeyword(productSearchPage: ProductSearchPage) {
    if (productSearchPage?.keywordRedirectUrl) {
      if (productSearchPage.keywordRedirectUrl === '/') {
        this.productSearchService.clearResults();
        this.routingService.go({
          cxRoute: 'home'
        });
      } else if (this.genericLinkComponentService.isExternalUrl(productSearchPage.keywordRedirectUrl)) {
        if (this.winRef.isBrowser()) {
          this.winRef.nativeWindow.open(productSearchPage.keywordRedirectUrl);
        }
      }
      // else if (/^\//.test(productSearchPage.keywordRedirectUrl)) {
      //   this.routingService.go(productSearchPage.keywordRedirectUrl);
      // }
      else {
        this.routingService.go(productSearchPage.keywordRedirectUrl);
      }
    }
  }

  setSuggestedItemsMax(isMd?: boolean) {
    if (this.winRef.isBrowser() && isMd === undefined) {
      const windowWidth = window.screen.width || window.screen.availWidth;
      isMd =
        windowWidth < this.breakpointService.getSize(BREAKPOINT.md) &&
        windowWidth >= this.breakpointService.getSize(BREAKPOINT.sm);
    }
    if (isMd) {
      this.markProducts = Array(SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_M).fill('');
    } else {
      this.markProducts = Array(SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_L).fill('');
    }
    if (this.displayBlock.suggestedResults && this.winRef.isBrowser()) {
      if (isMd) {
        this.suggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_M;
      } else {
        // MAX_L equal MAX_S
        this.suggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_L;
      }
    } else {
      this.suggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX;
    }
    if (this.displayBlock.psORpb && this.winRef.isBrowser()) {
      if (isMd) {
        this.ourSuggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_M;
      } else {
        this.ourSuggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX_L;
      }
    } else {
      this.ourSuggestedItemsMax = SEARCH_BOX_CONTANST.SUGGESTED_ITEMS_MAX;
    }
  }

  // after launch search take once action
  afterLaunchSearch(enterFlag: boolean, searchValue: string) {
    this.getResults$?.unsubscribe();
    this.getResults$ = combineLatest(
      this.routingService.getRouterState().pipe(
        distinctUntilChanged((x, y) => {
          return x.state.url === y.state.url;
        }),
        filter((routerState) => routerState.state.semanticRoute === 'search')
      ),
      this.productSearchService.getResults().pipe(filter((searchResult) => Object.keys(searchResult).length > 0))
    )
      .pipe(
        map(([routerState, productSearchPage]) => productSearchPage),
        distinctUntilKeyChanged('freeTextSearch'),
        // wait for launchSearchResult() redirect, keyword redirect at last
        delay(1)
      )
      .subscribe((productSearchPage: ProductSearchPage) => {
        if (productSearchPage.freeTextSearch === searchValue) {
          this.getResults$?.unsubscribe();
          if (enterFlag) {
            this.redirectByKeyword(productSearchPage);
          }
          if (productSearchPage?.pagination?.totalResults > 0 && productSearchPage?.freeTextSearch) {
            this.saveSearchCookies(productSearchPage.freeTextSearch);
          }
        }
      });
  }
}
