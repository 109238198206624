import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { LanguageService, Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-product-slider-electronic',
  templateUrl: './product-slider-electronic.component.html'
})
export class ProductSliderElectronicComponent implements OnDestroy {
  svgType = svgConfig;
  error_style = 'margin: 0 5px 0 0';

  @Input() product: Product;
  @Input() pageFrom: string;

  product$;

  country: string;
  wineCheckbox = false;
  @Input() electronicForm: FormGroup;
  electronicArr = [
    { label: 'accepted', value: 'ACCEPTED' },
    { label: 'declined', value: 'DECLINED' },
    { label: 'determined', value: 'TO_BE_DETERMINED' }
  ];
  alcoholicForm: FormGroup;
  language = 'en';
  protected subscription = new Subscription();

  constructor(
    private countryService: CountryService,
    protected currentProductService: CurrentProductService,
    protected languageService: LanguageService,
    private fb: FormBuilder
  ) {
    this.subscription.add(
      this.countryService.getActive().subscribe((country) => {
        this.country = country;
      })
    );
    this.subscription.add(
      this.languageService.getActive().subscribe((language) => {
        this.language = language;
      })
    );
  }

  clearFalseValidator(): ValidatorFn {
    return (control): object => {
      return control.value === true ? null : { error: 'required' };
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
