import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { MobileVerificationDialogComponent } from './mobile-verification-dialog.component';

@NgModule({
  imports: [CommonModule, I18nModule, SafeCodeModule, KeyboardFocusModule],
  declarations: [MobileVerificationDialogComponent],
  exports: [MobileVerificationDialogComponent]
})
export class MobileVerificationDialogModule {}
