import { Component } from '@angular/core';
import { AsmService } from '@spartacus/asm/core';
import { AsmUi } from '@spartacus/asm/root';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-asm-toggle-ui',
  templateUrl: './am-asm-toggle-ui.component.html',
  styleUrls: ['./am-asm-toggle-ui.component.scss']
})
export class AmAsmToggleUiComponent {
  protected subscription = new Subscription();
  isCollapsed: boolean;

  constructor(protected asmService: AsmService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.asmService.getAsmUiState().subscribe((uiState: AsmUi) => {
        this.isCollapsed = uiState.collapsed === undefined ? false : uiState.collapsed;
      })
    );
  }

  toggleUi(): void {
    this.asmService.updateAsmUiState({ collapsed: !this.isCollapsed });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
