import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { CmsConfig, ConfigModule, DeferLoadingStrategy, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { GenericLinkModule, NavigationModule } from '@spartacus/storefront';
import { globalDialogLayoutConfig } from '../launch-dialog/global-dialog/global-dialog-layout.config';
import { FooterComponent } from './footer.component';
import { LivechatModule } from './livechat/livechat.module';
import { FeedbackModule } from './feedback/feedback.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    GenericLinkModule,
    AmMediaModule,
    I18nModule,
    LivechatModule,
    FeedbackModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterNavigationComponent: {
          // typeCode
          component: FooterComponent,
          deferLoading: DeferLoadingStrategy.INSTANT
        }
      }
    } as CmsConfig)
  ],
  providers: [provideDefaultConfig(globalDialogLayoutConfig)]
})
export class FooterModule {}
