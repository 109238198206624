import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { AmGenericLinkModule } from '@components/content/generic-link/generic-link.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { AmSiteLogoComponent } from './am-site-logo.component';

@NgModule({
  declarations: [AmSiteLogoComponent],
  imports: [
    CommonModule,
    RouterModule,
    AmGenericLinkModule,
    AmMediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AMLSiteLogoResponsiveComponent: {
          // typeCode
          component: AmSiteLogoComponent
        }
      }
    } as CmsConfig)
  ]
})
export class AmSiteLogoModule {}
