import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-offer-applied-dialog',
  templateUrl: './offer-applied-dialog.component.html'
})
export class OfferAppliedDialogComponent implements OnInit, OnDestroy {
  product;
  decimal: number = 2;
  subscription: Subscription = new Subscription();
  constructor(protected launchDialogService: AmLaunchDialogService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        if (!!data?.product) {
          this.product = data.product;
          this.decimal = this.product.currency == 'HKD' ? 0 : 2;
        }
      })
    );
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
