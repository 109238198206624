import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { amServiceMapFactory } from '@components/layout/header/am-site-context/providers/am-context-service-map';
import {
  CountryInitializer,
  initializeCountry
} from '@components/layout/header/am-site-context/services/country/country-intializer';
import { AmLanguageInitializer } from '@components/layout/header/am-site-context/services/language/language-intializer';
import { AmLanguageService } from '@components/layout/header/am-site-context/services/language/language.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { AmProfileTagEventService } from '@core/cds/profiletag/services/am-profiletag-event.service';
import { AmCmsPageConnector } from '@core/cms/connectors/page/am-cms-page.connector';
import { AmCmsPageGuardService } from '@core/cms/guards/am-cms-page-guard.service';
import { AmCmsPageGuard } from '@core/cms/guards/cms-page.guard';
import { AmProductSearchPageNormalizer } from '@core/product/converters/am-product-search-page-normalizer';
import { ProductNormalizer } from '@core/product/converters/product-normalizer';
import { AmCategoryPageMetaResolver } from '@core/seo/page-meta/pages/am-category-page-meta.resolver';
import { AmContentPageMetaResolver } from '@core/seo/page-meta/pages/am-content-page-meta.resolver';
import { AmHomePageMetaResolver } from '@core/seo/page-meta/pages/am-home-page-meta.resolver';
import { AmProductPageMetaResolver } from '@core/seo/page-meta/pages/am-product-page-meta.resolver';
import { AmSearchPageMetaResolver } from '@core/seo/page-meta/pages/am-search-page-meta.resolver';
import { AmLoginGuard } from '@core/user/guard/am-login.guard';
import { CdsBackendNotificationAdapter, ProfileTagEventService, TrackingService } from '@spartacus/cds';
import {
  CmsPageConnector,
  ContextServiceMap,
  LanguageInitializer,
  LanguageService,
  PageMetaResolver,
  ProductAdapter,
  ProductSearchAdapter,
  PRODUCT_NORMALIZER,
  PRODUCT_SEARCH_PAGE_NORMALIZER
} from '@spartacus/core';
import { CmsPageGuard, CmsPageGuardService, LaunchDialogService, LoginGuard } from '@spartacus/storefront';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { AmBackendNotification } from './core/cds/profiletag/adapters/am-backend-notification-adapter';
import { AmTrackingService } from './core/cds/profiletag/tracking/services/am-tracking.service';
import { AmOccProductAdapter } from './pages/product-detail/occ/occ-product.adapter';
import { AmOccProductSearchAdapter } from './pages/product-list/occ/am-occ-product-search.adapter';

export function listenAndSetConfigData(amConfig: AmConfigService) {
  return (): void => {
    return amConfig.listenAndSetConfigData();
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ProductSearchAdapter,
      useClass: AmOccProductSearchAdapter
    },
    {
      provide: TrackingService,
      useClass: AmTrackingService
    },
    {
      provide: CdsBackendNotificationAdapter,
      useClass: AmBackendNotification
    },
    {
      provide: PageMetaResolver,
      useExisting: AmProductPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmCategoryPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmContentPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmSearchPageMetaResolver,
      multi: true
    },
    {
      provide: PageMetaResolver,
      useExisting: AmHomePageMetaResolver,
      multi: true
    },
    {
      provide: ContextServiceMap,
      useFactory: amServiceMapFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCountry,
      deps: [CountryInitializer],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: listenAndSetConfigData,
      deps: [AmConfigService],
      multi: true
    },
    {
      provide: ProductAdapter,
      useClass: AmOccProductAdapter
    },
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: ProductNormalizer,
      multi: true
    },
    {
      provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
      useExisting: AmProductSearchPageNormalizer,
      multi: true
    },
    {
      provide: LaunchDialogService,
      useExisting: AmLaunchDialogService
    },
    {
      provide: CmsPageConnector,
      useExisting: AmCmsPageConnector
    },
    {
      provide: CmsPageGuardService,
      useExisting: AmCmsPageGuardService
    },
    {
      provide: LoginGuard,
      useExisting: AmLoginGuard
    },
    {
      provide: LanguageService,
      useExisting: AmLanguageService
    },
    {
      provide: LanguageInitializer,
      useExisting: AmLanguageInitializer
    },
    {
      provide: ProfileTagEventService,
      useClass: AmProfileTagEventService
    },
    {
      provide: CmsPageGuard,
      useClass: AmCmsPageGuard
    }
  ]
})
export class AmProviderModule {}
