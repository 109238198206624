import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { SiteContextType } from '@config/am-constant.config';
import { ActiveCartFacade, OrderEntry } from '@spartacus/cart/base/root';
import { LAUNCH_CALLER, SiteContextComponentService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AmCartService } from '../../../service/am-cart.service';

@Component({
  selector: 'am-delivery-fee-dialog',
  templateUrl: './delivery-fee-dialog.component.html',
  styleUrls: ['./delivery-fee-dialog.component.scss']
})
export class DeliveryFeeDialogComponent {
  cart$ = this.activeCartService.getActive();
  currencyContext = SiteContextType.CURRENCY;
  // currency$ = this.siteContextService.getActiveItem(this.currencyContext);
  isInflightPickup = this.flightStatusStorageService.isInflightPickFlow;

  showDeliveryFee: boolean = false;
  hasInclude: boolean = false;
  hasFree: boolean = false;
  hasNa: boolean = false;

  @ViewChild('element') element: ElementRef;
  protected subscription = new Subscription();
  constructor(
    protected countryService: CountryService,
    protected launchDialogService: AmLaunchDialogService,
    protected activeCartService: ActiveCartFacade,
    private siteContextService: SiteContextComponentService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected vcr: ViewContainerRef,
    protected amCartService: AmCartService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data) => {
        const { showDeliveryFee, hasInclude, hasFree, hasNa } = data;
        this.showDeliveryFee = showDeliveryFee;
        this.hasInclude = hasFree;
        this.hasNa = hasNa;
        this.hasInclude = hasInclude;
      })
    );
  }

  checkIfInflightFlow1ActiveInflight(deliveryMethod: string): boolean {
    return this.amCartService.inflightFlowIfInfligthActive(deliveryMethod);
  }
  openWaiveDialog(promotion): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.DISPLAY_CART_OFFER_DETAIL,
      this.element,
      this.vcr,
      {
        promotion
      }
    );
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason, LAUNCH_CALLER.DISPLAY_DELIVE_FREE);
  }

  filterEntries(orderEntries: OrderEntry[]): OrderEntry[] {
    return orderEntries.filter((e) => e.selected);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
