import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { svgConfig } from '@config/am-svg-sprite.config';
import { EventService, Facet, RoutingService, WindowRef } from '@spartacus/core';
import { FacetGroupCollapsedState, FacetList, FocusConfig, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmFacetService } from '../../../services/facet.service';
import { AmProductListComponentService } from '../../../services/product-list-component.service';
import { AmFacetComponent } from '../facet/am-facet.component';

@Component({
  selector: 'am-facet-list',
  templateUrl: './am-facet-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmFacetListComponent implements OnInit, OnChanges {
  private _isDialog: boolean;
  /**
   * Indicates that the facet navigation is rendered in dialog.
   */
  @Input()
  set isDialog(value: boolean) {
    this._isDialog = value;
    // if (value) {
    //   this.renderer.addClass(this.winRef.document?.body, 'modal-open');
    // }
  }

  get isDialog(): boolean {
    return this._isDialog;
  }

  @Input()
  searchTextFromRouter: string;

  @Input()
  model: any;

  @Input()
  categoryCodeFromRouter: any;

  /** Emits when the list must close */
  @Output() closeList = new EventEmitter();

  /** The list of all facet and values related to the products in the list */
  facetList$: Observable<FacetList> = this.facetService.facetList$;

  iconTypes = ICON_TYPE;

  dialogFocusConfig: FocusConfig = {
    trap: true,
    block: true,
    focusOnEscape: true,
    autofocus: 'am-facet'
  };

  selectedSortItem: string;
  sortingOption: any = [];

  svgType = svgConfig;

  @Input()
  payByCashStatus: boolean = false;

  @Output() updatePayByCashStatus: EventEmitter<boolean> = new EventEmitter();

  @HostListener('click') handleClick(): void {
    this.close();
  }

  constructor(
    protected facetService: AmFacetService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    protected eventService: EventService,
    protected productListComponentService: AmProductListComponentService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    // this.filterSorting(this.model);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterSorting(this.model);
  }

  /**
   * Toggles the facet group in case it is not expanded.
   */
  expandFacetGroup(facet: Facet, ref: AmFacetComponent): void {
    if (!ref.isExpanded) {
      this.facetService.toggle(facet, ref.isExpanded);
    }
  }

  /**
   * Indicates that the facet group has been expanded.
   */
  isExpanded(facet: Facet): Observable<boolean> {
    return this.facetService.getState(facet).pipe(map((value) => value.toggled === FacetGroupCollapsedState.EXPANDED));
  }

  /**
   * Indicates that the facet group has been collapsed.
   */
  isCollapsed(facet: Facet): Observable<boolean> {
    return this.facetService.getState(facet).pipe(map((value) => value.toggled === FacetGroupCollapsedState.COLLAPSED));
  }

  close(event?: boolean): void {
    this.renderer.removeClass(this.winRef.document?.body, 'modal-open');
    this.closeList.emit(event);
  }

  block(event?: MouseEvent): void {
    event.stopPropagation();
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  togglePaymentMethod(payByCashStatus: boolean) {
    this.updatePayByCashStatus.emit(payByCashStatus);
    this.facetService.togglePaymentMethod(payByCashStatus, this.categoryCodeFromRouter, this.searchTextFromRouter);
  }

  filterSorting(model): void {
    this.selectedSortItem = model?.pagination?.sort;
    const sortCodes = [
      'paymentMethod',
      'relevance',
      'onlineDate-desc',
      'onlineDate-asc',
      'name-asc',
      'name-desc',
      'zonePrice-asc',
      'zonePrice-desc',
      'zone'
    ];
    this.sortingOption =
      model?.sorts
        ?.filter((sortItem) => {
          return sortCodes.includes(sortItem.code) && sortItem.name;
        })
        .sort((sortItem1, sortItem2) => {
          return sortCodes.indexOf(sortItem1.code) - sortCodes.indexOf(sortItem2.code);
        }) || [];
  }

  // clearAll(facetList: FacetList): void {
  //   const url = this.winRef.location.href;
  //   const queryParams = UrlHelper.parseQueryStringToObject(url);
  //   if (!url.includes(onlyCashQueryParm)) {
  //     this.router.navigate([], {
  //       queryParams: {
  //         ...queryParams,
  //         query: undefined,
  //         categoryCode: undefined
  //       }
  //     });
  //   } else {
  //     // @ts-ignore
  //     let newQuery = queryParams.query;
  //     facetList.activeFacets.forEach((activeFacet) => {
  //       const queryItem = `:${activeFacet.facetCode}:${activeFacet.facetValueCode}`;
  //       newQuery = newQuery.replace(queryItem, '');
  //     });
  //     this.router.navigate([], {
  //       queryParams: {
  //         ...queryParams,
  //         query: newQuery
  //       }
  //     });
  //   }
  // }

  storeFacetStatus(e) {
    this.facetService.facetStatus['sort_by'] = e.isExpanded;
    this.facetService.storeAccordionStatus(this.facetService.facetStatus);
  }

  isFacetExpanded(isActiveFacet: boolean): boolean {
    return this.facetService.getAccordionStatus('sort_by') && isActiveFacet;
  }
}
