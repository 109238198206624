import { Component } from '@angular/core';
import { BREAKPOINT, BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'am-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent {
  mapping = {
    spacing_xxs: '4',
    spacing_xs: '8',
    spacing_s: '16',
    spacing_m: '24',
    spacing_l: '40',
    spacing_xl: '40'
  };
  data$ = combineLatest(this.breakpointService.breakpoint$, this.component.data$).pipe(
    map(([breakpoint, data]) => {
      let height;
      height = this.mapping[data.spacingType] || 40;
      if ((breakpoint === BREAKPOINT.lg || breakpoint === BREAKPOINT.xl) && data.spacingType === 'spacing_xl') {
        height = 80;
      }
      return height + 'px';
    })
  );
  constructor(public component: CmsComponentData<any>, private breakpointService: BreakpointService) {}
}
