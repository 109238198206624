import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FlightInfoService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-info.service';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { CartConfigService, SelectiveCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart, OrderEntry, PromotionLocation } from '@spartacus/cart/base/root';
import { AuthService, RoutingService, WindowRef } from '@spartacus/core';
import { BreakpointService, LAUNCH_CALLER } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { AmConfigService } from '../../common/config/am-config.service';
import { svgConfig } from '../../config/am-svg-sprite.config';
import { AmCartService } from './service/am-cart.service';
import { AmValidateCartService } from './service/am-validate-cart.service';

export interface cartPriceInfo {
  subTotal?: string;
  subTotalPriceCash?: string;
}
@Component({
  selector: 'am-cart-details',
  templateUrl: './am-cart-details.component.html',
  // styleUrls: ['./am-cart-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmCartDetailsComponent implements OnInit, OnDestroy {
  cart$: Observable<Cart>;
  entries$: Observable<OrderEntry[]>;
  cartLoaded$: Observable<boolean>;
  loggedIn = false;
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;
  selectiveCartEnabled: boolean;
  // isSmallScreen$ = this.breakpointService.isDown(BREAKPOINT.md);
  // isFlightPickUp: boolean;

  svgType = svgConfig;
  isStoreOpenStatus: boolean | null;

  // activeTab: string;
  protected subscription = new Subscription();
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected amCartService: AmCartService,
    protected amValidateCartService: AmValidateCartService,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected breakpointService: BreakpointService,
    protected flightInfoService: FlightInfoService,
    protected launchDialogService: AmLaunchDialogService,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected cartConfig: CartConfigService,
    protected winRef: WindowRef,
    protected amConfigService: AmConfigService
  ) {
    this.subscription.add(
      this.amValidateCartService.validateMobileError().subscribe((msg) => {
        if (msg) {
          const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.MOBILE_VERIFICATION, undefined, undefined, {
            msg
          });
          if (dialog) {
            this.subscription.add(dialog.pipe(take(1)).subscribe());
          }
        }
      })
    );
  }

  ngOnInit(): void {
    this.cart$ = combineLatest([this.activeCartService.getActive(), this.amConfigService.getPosStatus()]).pipe(
      distinctUntilChanged(),
      map(([cart, posStatus]) => {
        this.isStoreOpenStatus = posStatus;
        // this.flightStatusStorageService.updateInflightSalesChannel(storeInfo?.inflightSalesChannel);
        return cart;
      })
    );
    this.entries$ = this.activeCartService.getEntries().pipe(filter((entries) => entries.length > 0));
    this.selectiveCartEnabled = this.cartConfig.isSelectiveCartEnabled();
    // this.isFlightPickUp = this.flightStatusStorageService.isInflightPickFlow;

    this.cartLoaded$ = combineLatest([
      this.activeCartService.isStable(),
      this.selectiveCartEnabled ? this.selectiveCartService.isStable() : of(false),
      this.authService.isUserLoggedIn()
    ]).pipe(
      tap(([, , loggedIn]) => (this.loggedIn = loggedIn)),
      map(([cartLoaded, sflLoaded, loggedIn]) =>
        loggedIn && this.selectiveCartEnabled ? cartLoaded && sflLoaded : cartLoaded
      )
    );
  }
  // getStoreOpenStatusOff(ev): void {
  //   if (ev === false) {
  //     this.isStoreOpenStatus = ev;
  //   }
  // }

  // checkCartItemIfDelistOrOffline(cart: Cart): boolean {
  //   let invalidCart = cart.entries?.find((item) => item.isDelisted || item.isOffline);
  //   return invalidCart ? true : false;
  // }

  // switchTab(activeTab: string): void {
  //   this.activeTab = activeTab;
  // }

  ngOnDestroy(): void {
    if (this.winRef.localStorage?.getItem('priceChange')) {
      this.winRef.localStorage?.removeItem('priceChange');
    }
    this.subscription?.unsubscribe();
  }
}
