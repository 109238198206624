<div
  class="cx-item-list-row"
  *ngFor="let item of items; let i = index; trackBy: trackByIndex"
  [ngClass]="{
    'merge-row': !isGroupBy(item, i)
  }">
  <div
    *ngIf="getControl(item) | async as control"
    class="cx-item-list-items bgwhite"
    [ngClass]="{
      'is-changed': control.get('quantity').disabled
    }">
    <div class="am-item-list-box">
      <am-cart-item
        [item]="item"
        [quantityControl]="control.get('quantity')"
        [readonly]="readonly"
        [validationDatas]="validationDatas"
        [promotionLocation]="promotionLocation"
        [options]="options"
        [isStoreOpenStatus]="isStoreOpenStatus"
        [showGroupBy]="isGroupBy(item, i)"
        [showCollectionMethod]="isCollectionMethod(item, i)"
        [isInflightEnv]="isInflightEnv"
        [lowStockFlag]="validateLowStock(item)">
      </am-cart-item>
    </div>
  </div>
</div>
