import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { I18nModule, UrlModule } from '@spartacus/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { AmSearchBoxModule } from '@components/content/mulit-search-box/search-box/am-search-box.module';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { AmProductCardModule } from '@components/content/product/subComponent/product-card/product-card.module';
// import { HighlightPipe } from '@components/content/mulit-search-box/search-box/highlight.pipe';
import { LoadingMaskModule } from '@utils/directive/loading-mask/loading-mask.module';
import { SearchDialogComponent } from './search-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    I18nModule,
    UrlModule,
    SvgContainerModule, 
    AmSearchBoxModule,
    AmMediaModule,
    AmProductCardModule,
    LoadingMaskModule,
  ],
  declarations: [SearchDialogComponent],
  exports: [SearchDialogComponent],
})
export class SearchDialogModule {}
