<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="addToCartResult$ | async as addToCartResult">
    <div class="quantity" *ngIf="showQuantity">
      <label>{{ 'amAddToCart.quantity' | cxTranslate }}:</label>
      <am-item-counter [max]="maxQuantity" [control]="addToCartForm.get('quantity')"></am-item-counter>
    </div>
    <form
      #form
      *ngIf="productCode"
      [formGroup]="addToCartForm"
      (submit)="addToCart()"
      class="add-to-cart-form"
      [ngClass]="{ 'form-with-error': addToCartResult?.errorMsg }">
      <!-- <div class="error-message font-blod" *ngIf="addToCartResult?.errorMsg">
        <i class="icon-error"></i>
        {{ addToCartResult.errorMsg | cxTranslate : { count: addToCartResult.cartItemMax } }}
      </div> -->
      <div *ngIf="addToCartResult?.errorMsg" class="error-message">
        <am-message-tip
          *ngIf="addToCartResult?.errorMsg && addToCartResult.statusCode !== 'maxOrderQuantityExceeded'"
          [iconType]="svgType.ICONWARNINGRED"
          messageType="error"
          [hasBackgroudColor]="false"
          [isSmallMessageTip]="true">
          <div class="error-text font-blod">
            {{ addToCartResult.errorMsg | cxTranslate : { count: addToCartResult.cartItemMax } }}
          </div>
        </am-message-tip>
        <am-message-tip
          *ngIf="addToCartResult?.statusCode === 'maxOrderQuantityExceeded'"
          [iconType]="svgType.ICONWARNINGRED"
          messageType="error"
          [hasBackgroudColor]="false"
          [isSmallMessageTip]="true">
          <div class="error-text font-blod">
            {{ addToCartResult.errorMsg | cxTranslate : { maxQuantity: addToCartResult.maxQuantity } }}
          </div>
        </am-message-tip>
      </div>

      <div class="add-to-cart-button">
        <ng-container *ngIf="isShowNotifyMeBtn; else showAddToCart">
          <ng-container *ngIf="userLoggedIn$ | async; else loginPrompt">
            <button
              class="button-primary"
              [class.button-remove-notify]="isNotifyMeProduct"
              type="button"
              (click)="onClickNotifyMe()">
              <am-svg-container
                *ngIf="!isNotifyMeProduct"
                [type]="svgType.ICONREMINDERWHITE"
                [style]="style_add_to_cart"></am-svg-container>
              <span *ngIf="!isNotifyMeProduct">{{ 'favouriteNotifyMe.addNotifyMe' | cxTranslate }}</span>
              <span *ngIf="isNotifyMeProduct">{{ 'favouriteNotifyMe.removeNotifyMe' | cxTranslate }}</span>
            </button>
          </ng-container>
          <ng-template #loginPrompt>
            <button class="button-primary" type="button" [routerLink]="{ cxRoute: 'login' } | cxUrl">
              <am-svg-container [type]="svgType.ICONREMINDERWHITE" [style]="style_add_to_cart"></am-svg-container>
              <span>{{ 'favouriteNotifyMe.addNotifyMe' | cxTranslate }}</span>
            </button>
          </ng-template>
        </ng-container>

        <ng-template #showAddToCart>
          <button
            aria-label="Add to cart"
            class="button-primary"
            type="submit"
            [class.button-disable]="(isLoading$ | async) || isDisabled(product)"
            [disabled]="(isLoading$ | async) || isDisabled(product)">
            <am-svg-container
              [type]="(isLoading$ | async) || isDisabled(product) ? svgType.ICONADDTOCARTGREY : svgType.ICONADDTOCART"
              [style]="style_add_to_cart"></am-svg-container>
            <span *ngIf="!isNotifyMe">
              {{ 'amAddToCart.addToCart' | cxTranslate }}
            </span>
            <span *ngIf="isNotifyMe">
              {{ 'amWishProductList.moveToCart' | cxTranslate }}
            </span>
          </button>
        </ng-template>

        <ng-container
          *ngIf="
            product.collectionMethod !== collectionMethod.INFLIGHT_PICKUP &&
            pageFrom === 'productDetail' &&
            !product.isGiftPromotion &&
            !product.isGiveaway
          ">
          <ng-container *ngIf="userLoggedIn$ | async; else loginPrompt">
            <button type="button" class="favorites-icon" (click)="clickFavourites()">
              <am-svg-container
                [type]="isFavouriteProduct ? svgType.ICONFAVORITESSELECTED : svgType.ICONFAVORITES"></am-svg-container>
            </button>
          </ng-container>
          <ng-template #loginPrompt>
            <button
              type="button"
              class="favorites-icon"
              [amToolTip]="loginGuide"
              [amPopoverOptions]="{
                className: 'login-wrapper add-to-cart-login-guide',
                horizontalAlign: 'right',
                displayCloseButton: true,
              }">
              <am-svg-container [type]="svgType.ICONFAVORITES"></am-svg-container>
            </button>
          </ng-template>
        </ng-container>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-template #loginGuide>
  <div class="login-guide">
    <div class="left-icon">
      <am-svg-container [type]="svgType.ICONFAVORITESGOLD" [style]="favoriteIconStyle"></am-svg-container>
    </div>
    <div class="right-content">
      <p class="title">{{ 'amWishProductList.favourites' | cxTranslate }}</p>
      <p class="description">
        {{ 'amWishProductList.signInTip' | cxTranslate }}
      </p>
      <a class="login-btn" [routerLink]="{ cxRoute: 'login' } | cxUrl">
        {{ 'amWishProductList.signBtn' | cxTranslate }}
        <am-svg-container [type]="svgType.ICONARROWFORWARD" svgDescription="go to product details"></am-svg-container>
      </a>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!isFavoriteNotifyBottomSheet">
  <am-favorite-notify-reminder></am-favorite-notify-reminder>
</ng-container>
