import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideDefaultConfigFactory, StateConfig, StateTransferType } from '@spartacus/core';
import { effects } from './effects/index';
import { reducerProvider, reducerToken } from './reducers/index';
import { SITE_CONTEXT_FEATURE } from './state';

export function siteContextStoreConfigFactory(): StateConfig {
  // if we want to reuse SITE_CONTEXT_FEATURE const in config, we have to use factory instead of plain object
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: { [SITE_CONTEXT_FEATURE]: StateTransferType.TRANSFER_STATE }
      }
    }
  };
  return config;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(SITE_CONTEXT_FEATURE, reducerToken),
    EffectsModule.forFeature(effects)
  ],
  providers: [provideDefaultConfigFactory(siteContextStoreConfigFactory), reducerProvider]
})
export class AmSiteContextStoreModule {
  static forRoot(): ModuleWithProviders<AmSiteContextStoreModule> {
    return {
      ngModule: AmSiteContextStoreModule,
      providers: []
    };
  }
}
