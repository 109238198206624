import { Injectable } from '@angular/core';
import { Converter, Images, Occ, OccConfig, Product } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class ProductNormalizer implements Converter<Occ.Product, Product> {
  constructor(protected config: OccConfig) {}

  convert(source: any, target?: any): Product {
    if (source.url) {
      let categoryObj = this.covertCategory(source);
      target = { ...target, ...categoryObj };
    }
    if (source.baseOptions) {
      let baseOptions = source.baseOptions?.map((v) => {
        let options = v.options?.map((k) => ({ ...k, ...this.covertCategory(k) }));
        return { ...v, options };
      });
      target = { ...source, ...target, baseOptions: baseOptions };
    }
    // if (source.images) {
    //   target.images = this.normalize(source.images);
    // }
    // for test: add the category to the product
    // if (source?.categories?.length) {
    //   target.category = source.categories[0].name
    // }
    return target;
  }

  /**
   * @desc
   * Creates the image structure we'd like to have. Instead of
   * having a single list with all images despite type and format
   * we create a proper structure. With that we can do:
   * - images.primary.thumnail.url
   * - images.GALLERY[0].thumnail.url
   */
  normalize(source: Occ.Image[]): Images {
    const images = {};
    if (source) {
      for (const image of source) {
        const isList = image.hasOwnProperty('galleryIndex');
        if (!images.hasOwnProperty(image.imageType)) {
          images[image.imageType] = isList ? [] : {};
        }

        let imageContainer;
        if (isList && !images[image.imageType][image.galleryIndex]) {
          images[image.imageType][image.galleryIndex] = {};
        }

        if (isList) {
          imageContainer = images[image.imageType][image.galleryIndex];
        } else {
          imageContainer = images[image.imageType];
        }

        const targetImage = { ...image };
        targetImage.url = this.normalizeImageUrl(targetImage.url);
        imageContainer[image.format] = targetImage;
      }
    }
    return images;
  }
  /**
   * Traditionally, in an on-prem world, medias and other backend related calls
   * are hosted at the same platform, but in a cloud setup, applications are are
   * typically distributed cross different environments. For media, we use the
   * `backend.media.baseUrl` by default, but fallback to `backend.occ.baseUrl`
   * if none provided.
   */
  private normalizeImageUrl(url: string): string {
    if (new RegExp(/^(http|data:image|\/\/)/i).test(url)) {
      return url;
    }
    return (this.config.backend.media.baseUrl || this.config.backend.occ.baseUrl || '') + url;
  }

  covertCategory(source): any {
    let categoryObj = {};
    if (!source.url) {
      return categoryObj;
    }
    let categoryName = source.url.split('/p/')[0].replace(/^\//, '') as string;
    const listName = ['Category', 'subCategory', 'thirdCategory', 'fourCategory', 'fiveCategory'];
    const categoryList = categoryName.split('/');

    if (categoryList.length) {
      categoryList.forEach((v, i) => {
        categoryObj[listName[i]] = v;
      });
    }
    return categoryObj;
  }
}
