<ng-container *ngIf="product$ | async as product">
  <div class="variant-section">
    <ng-container *ngFor="let item of product.baseOptions; let i = index">
      <am-product-variant-size-selector
        [pageFrom]="pageFrom"
        (emitProductCode)="getProductCode($event)"
        *ngIf="!getHasColor(item, i)"
        [variants]="covertVariants(item, i)">
      </am-product-variant-size-selector>
      <am-product-variant-color-selector
        [pageFrom]="pageFrom"
        (emitProductCode)="getProductCode($event)"
        *ngIf="getHasColor(item, i)"
        [variants]="covertVariants(item, i)">
      </am-product-variant-color-selector>
    </ng-container>
  </div>
</ng-container>
