import { Component } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-cart-offer-details-dialog',
  templateUrl: './cart-offer-details-dialog.component.html',
  styleUrls: ['./cart-offer-details-dialog.component.scss']
})
export class CartOfferDetailsDialogComponent {
  isEarnMiles: boolean = false;
  subscription: Subscription = new Subscription();
  promotion: any;

  constructor(protected launchDialogService: AmLaunchDialogService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        const { promotion, isEarnMiles } = data;
        this.promotion = promotion;
        this.isEarnMiles = !!isEarnMiles;
      })
    );
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason, LAUNCH_CALLER.DISPLAY_CART_OFFER_DETAIL);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
