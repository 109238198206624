import { Component } from '@angular/core';
import { InflightDiagnosticService } from './inflight-diagnostic.service';

@Component({
  selector: 'am-inflight-diagnostic',
  templateUrl: './inflight-diagnostic.component.html',
  styleUrls: ['./inflight-diagnostic.component.scss']
})
export class InflightDiagnosticComponent {
  constructor(protected inflightDiagnosticService: InflightDiagnosticService) {}
  inflightDiagnosticInfo$ = this.inflightDiagnosticService.getInflightDiagnosticInfo();
}
