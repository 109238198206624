import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { AmAddToCartDialogComponent } from './add-to-cart-dialog.component';

@NgModule({
  imports: [CommonModule, I18nModule, RouterModule, UrlModule],
  exports: [AmAddToCartDialogComponent],
  declarations: [AmAddToCartDialogComponent]
})
export class AmAddToCartDialogModule {}
