import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { normalizeHttpError, SiteConnector, SiteContextActions, WindowRef } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { bufferCount, catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { CountriesActions } from '../actions/index';
import { getActiveCountry } from '../selectors/countries.selectors';
import { StateWithSiteContext } from '../state';

@Injectable()
export class CountriesEffects {
  loadCountries$: Observable<CountriesActions.LoadCountriesSuccess | CountriesActions.LoadCountriesFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteContextActions.LANGUAGE_CHANGE, CountriesActions.LOAD_COUNTRIES),
        exhaustMap(() => {
          return this.siteConnector.getCountries().pipe(
            map((countrys) => {
              return new CountriesActions.LoadCountriesSuccess(countrys);
            }),
            catchError((error) => of(new CountriesActions.LoadCountriesFail(normalizeHttpError(error))))
          );
        })
      )
  );

  // @Effect()
  // persist$: Observable<void> = this.actions$.pipe(
  //   ofType(CountriesActions.SET_ACTIVE_COUNTRY),
  //   tap((action: CountriesActions.SetActiveCountry) => {
  //     if (this.winRef.localStorage) {
  //       this.winRef.localStorage?.setItem('country', action.payload);
  //     }
  //   }),
  //   switchMapTo(NEVER)
  // );

  activateCountry$: Observable<CountriesActions.CountryChange> = createEffect(() =>
    this.state.select(getActiveCountry).pipe(
      bufferCount(2, 1),
      // avoid dispatching `change` action when we're just setting the initial value:
      filter(([previous]) => !!previous),
      map(([previous, current]) => new CountriesActions.CountryChange({ previous, current }))
    )
  );

  constructor(
    private actions$: Actions,
    private siteConnector: SiteConnector,
    private winRef: WindowRef,
    private state: Store<StateWithSiteContext>
  ) {}
}
