import { Component, OnDestroy } from '@angular/core';
import { RoutingService, WindowRef } from '@spartacus/core';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { activeTabCodeMap, defaultSortCode } from '../../constants/favorite-notify-products-contants';
import { FavoriteNotifyProductsService } from '../../services/favorite-notify-products.service';

@Component({
  selector: 'am-favorite-notify-switch-tab',
  templateUrl: './favorite-notify-switch-tab.component.html',
  styleUrls: ['./favorite-notify-switch-tab.component.scss']
})
export class FavoriteNotifySwitchTabComponent implements OnDestroy {
  activeTabCodeMap = activeTabCodeMap;
  activeTabCode: string = this.activeTabCodeMap.favourite;
  pageTemplateElement: Element = null;
  disableNotifyTab: boolean = false;
  protected subscription = new Subscription();
  constructor(
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected routing: RoutingService,
    protected amConfigService: AmConfigService,
    protected winRef: WindowRef
  ) {
    this.setActiveTypeFromUrlParam();
  }

  setActiveTypeFromUrlParam() {
    this.subscription.add(
      combineLatest([
        this.routing.getRouterState().pipe(take(1)),
        this.amConfigService.getCommonConfig().pipe(take(1))
      ]).subscribe(([routerState, config]) => {
        this.activeTabCode = this.favoriteNotifyProductsService.getActiveTypeFromParam(routerState.state?.queryParams);
        if (config.notifySwitch === false) {
          this.disableNotifyTab = true;
          if (this.activeTabCode === activeTabCodeMap.notify) {
            this.activeTabCode = activeTabCodeMap.favourite;
            this.favoriteNotifyProductsService.route({ type: this.activeTabCode });
          }
        }
      })
    );
  }

  switchTab(activeTabCode: string) {
    this.activeTabCode = activeTabCode;
    this.favoriteNotifyProductsService.route({ type: activeTabCode, sortCode: defaultSortCode });
  }

  ngOnInit() {
    if (this.winRef.isBrowser()) {
      this.pageTemplateElement = this.winRef.document?.getElementsByClassName('ProductListPageTemplate')[0];
      this.pageTemplateElement.classList?.add('favorite-notify-page');
    }
  }

  ngOnDestroy() {
    if (this.winRef.isBrowser() && !!this.pageTemplateElement) {
      this.pageTemplateElement.classList?.remove('favorite-notify-page');
    }
    this.subscription?.unsubscribe();
  }
}
