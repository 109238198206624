import { Injectable } from '@angular/core';
import { WebServiceResponse } from '@model/WebServiceResponse.model';
import {
  BasePageMetaResolver,
  CmsService,
  PageMetaResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  PageType,
  ProductSearchPage,
  ProductSearchService
} from '@spartacus/core';
import { escaper } from '@utils/escaper';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
interface AmProductSearchPage extends ProductSearchPage {
  responseInfo?: WebServiceResponse;
}

@Injectable({
  providedIn: 'root'
})
export class AmSearchPageMetaResolver
  extends PageMetaResolver
  implements PageMetaResolver, PageTitleResolver, PageRobotsResolver
{
  pageTitle: string = '';
  pageId: string = '';

  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    protected productSearchService: ProductSearchService,
    protected readonly cms: CmsService
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'SearchResultsGridPageTemplate';
  }

  protected search$: Observable<AmProductSearchPage | undefined> = this.productSearchService.getResults().pipe(
    filter((data) => !!data?.pagination),
    map((results) => {
      return results;
    })
  );

  resolveTitle(): Observable<string> {
    return this.search$.pipe(
      switchMap((search) => {
        let tempTitles = search.responseInfo.pageTitle.split('|');
        let siteName = tempTitles.length > 1 ? tempTitles[1] : tempTitles[0];
        if (search.facets.length > 0) {
          this.pageTitle = `Search ${search.freeTextSearch} | ${siteName}`;
        } else {
          this.pageTitle = `No Result | ${siteName}`;
        }
        return of(escaper.decode(this.pageTitle));
      })
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }

  resolveDescription(): Observable<string | undefined> {
    return of('homepage desctiption');
  }
}
