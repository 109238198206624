import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { AmAsmEnablerService } from '@core/asm/root/services/am-asm-enable.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { OccEndpointsService, RoutingService, UserIdService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { combineLatest, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { RequestFileds } from '../am-verify-otp/am-verify-otp.component';
import { AmCheckoutVerifyOtpService } from '../am-verify-otp/am-verify-otp.service';

export interface PaymentResponse {
  orderCode: string;
  requestFields?: RequestFileds;
  requestPostUrl?: string;
  requestUrlType?: string;
  status: string;
  code?: string;
}

@Component({
  selector: 'am-checkout-loading',
  templateUrl: './am-checkout-loading.component.html'
})
export class AmCheckoutLoadingComponent implements OnInit, OnDestroy {
  protected subscription = new Subscription();
  constructor(
    protected winRef: WindowRef,
    protected occEndpointSvc: OccEndpointsService,
    protected http: HttpClient,
    protected routingService: RoutingService,
    protected userIdService: UserIdService,
    protected checkoutVerifyOtpService: AmCheckoutVerifyOtpService,
    protected amAsmEnableService: AmAsmEnablerService,
    protected activeCartService: ActiveCartFacade,
    protected launchDialogService: AmLaunchDialogService
  ) {
    this.getCCSIValidateResult();
  }

  getCCSIValidateResult() {
    this.subscription.add(
      combineLatest([
        this.userIdService.getUserId().pipe(filter((cartId) => !!cartId)),
        this.activeCartService.getActiveCartId().pipe(filter((cartId) => !!cartId))
      ])
        .pipe(
          take(1),
          switchMap(([userId, cartId]) => {
            const url = new URL(this.winRef.location.href);
            const uid = url.searchParams?.get('UID');
            const ccsiValidationLoadingUrl = this.occEndpointSvc.buildUrl('ccsiValidationLoading', {
              urlParams: { userId, cartId, uid }
            });
            return this.http.post(ccsiValidationLoadingUrl, {});
          })
        )
        .subscribe((data) => {
          this.handleCCSIResponse(data);
        })
    );
  }

  handleCCSIResponse(data) {
    //When password-authore validation is failed
    if (data && data.errorMeg) {
      const { errorCode } = data.errorMeg;
      let extra: NavigationExtras = { queryParams: { errorMeg: errorCode } };
      if (errorCode === 'CCSI_CALLBACK_VALIDATION_FAILED') {
        this.routingService.go({ cxRoute: 'checkoutShippingAddress' }, extra);
      }
    }
    //When password-authore validation is success, will go to do payment process
    else if (data?.paymentRequest?.requestFields?.entry?.length > 0) {
      const uid = data.paymentRequest.requestFields.entry.filter((data) => {
        return data.key === 'UID';
      })[0];
      this.subscription.add(
        this.http
          .post<PaymentResponse>(data.paymentRequest.requestUrl, {
            smsCode: uid,
            cashPaymentBackUrl: data.paymentRequest.backUrl
          })
          .subscribe((response) => {
            //If CLS validaton is success, and order is with cash, go to CPP
            if (response.status === 'success' && !!response.requestFields) {
              const isAsmEnable = this.amAsmEnableService.isEnabled();
              if (isAsmEnable) {
                this.routingService.go({
                  cxRoute: 'waitingPayment',
                  params: { orderCode: response.orderCode }
                });
              } else {
                const form: HTMLFormElement = this.checkoutVerifyOtpService.generatePaymentForm(
                  'paymentRequestForm',
                  response.requestPostUrl,
                  response.requestFields.entry
                );
                form.submit();
              }
              return;
            }
            //Redirect to checkout page with error if CLS validation is failed.
            else if (response.status === 'redirect:') {
              const { requestUrlType, orderCode } = response;
              switch (requestUrlType) {
                case 'CHECKOUT_GENERALERROR':
                  this.routingService.go('checkout/generalError');
                  return;
                case 'BALANCE_ERROR':
                  this.routingService.go('checkout/balanceError');
                  return;
                case 'CART':
                  this.routingService.go({ cxRoute: 'cart' });
                  return;
                case 'ORDER_CONFIRMATION':
                  this.routingService.go(
                    { cxRoute: 'orderConfirmation', params: { orderCode } },
                    { queryParams: { cartRemoved: true } }
                  );
                  return;
                case 'CHECKOUT':
                  let extra: NavigationExtras = { queryParams: { errorMeg: 'CCSI_CALLBACK_VALIDATION_FAILED' } };
                  this.routingService.go({ cxRoute: 'checkoutShippingAddress' }, extra);
                  return;
              }
              return;
            } else if (response.status === 'popup') {
              this.openDialog();
            }
          })
      );
    }
  }

  openDialog(): void {
    const dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.PRICE_CHANGE_REMINDER, undefined, undefined);
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
