import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  BasePageMetaResolver,
  CmsService,
  PageDescriptionResolver,
  PageHeadingResolver,
  PageImageResolver,
  PageLinkService,
  PageMetaResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  PageType,
  TranslationService
} from '@spartacus/core';
import { escaper } from '@utils/escaper';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AmHomePageMetaResolver
  extends PageMetaResolver
  implements PageHeadingResolver, PageTitleResolver, PageDescriptionResolver, PageRobotsResolver, PageImageResolver
{
  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    protected readonly cms: CmsService,
    protected readonly router: Router,
    protected readonly pageLinkService: PageLinkService,
    protected translationService: TranslationService
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'LandingPage3Template' || 'LandingPage3Template';
  }

  resolveTitle(): Observable<string | undefined> {
    return this.cms.getCurrentPage().pipe(
      filter((page) => !!page),
      switchMap((page) => {
        let pageTitle = 'Asia Miles - Lifestyle Awards';
        if (page.title?.includes('|')) {
          const lastSplitIndex = page.title.lastIndexOf('|');
          pageTitle = page.title.substring(0, lastSplitIndex);
        }
        return of(escaper.decode(pageTitle));
      })
    );
  }

  /**
   * Resolves the page heading for the Checkout Page.
   * The page heading is used in the UI (`<h1>`), where as the page
   * title is used by the browser and crawlers.
   */
  resolveHeading(): Observable<string> {
    return of('homepage heading');
  }

  resolveDescription(): Observable<string | undefined> {
    return this.cms.getCurrentPage().pipe(
      switchMap((page) => {
        return of(page.properties?.description);
      })
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return of([PageRobotsMeta.FOLLOW]);
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      startWith(null),
      map(() => this.pageLinkService.getCanonicalUrl()),
      tap((data) => console.log('data=====', data))
    );
  }

  // resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
  //   return of([{ label: 'homepageaaa', link: '/homepageaaa' }]);
  // }

  resolveImage(): Observable<string | undefined> {
    return of(
      'https://lifestyle-api.asiamiles.com/medias/cx-siteLogo-restOfWorld-green-en.png?context=bWFzdGVyfHJvb3R8NjgxN3xpbWFnZS9wbmd8YUdZeUwyZ3dOaTh4TVRVNE1qQTVOVFkxTkRrME1pOWplRjl6YVhSbFRHOW5iMTl5WlhOMFQyWlhiM0pzWkY5bmNtVmxibDlsYmk1d2JtY3xlMzc5MjE0YTRhZGI1N2Q2ZjhiZTM2NGIxYjZhNzNhZjA1ZTU1ZTk2NDJlMDRhY2Q2N2M2NmQzY2MwMmY0NmFh'
    );
  }
}
