import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SwBypassInterceptor implements HttpInterceptor {
  constructor(private occEndpoints: OccEndpointsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.occEndpoints.getBaseUrl()) && request.urlWithParams.includes('cmsTicketId')) {
      request = request.clone({
        setHeaders: {
          'ngsw-bypass': 'true'
        }
      });
    }
    if (/assets[\s\S]*.json/.test(request.url)) {
      request = request.clone({
        setHeaders: {
          'ngsw-bypass': 'true'
        }
      });
    }
    return next.handle(request);
  }
}
