import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class TealiumUtagService {
  scriptSrc = '';
  window = (this.winRef.nativeWindow as any) ?? {};

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(protected winRef: WindowRef) {
    (this.window as any).utag_cfg_ovrd = { noview: true };
    (this.window as any).utag_data = {};
  }

  setConfig(config: { account: string; profile: string; environment: string }) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
      this.scriptSrc =
        'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  addScript(src: string, callback?: () => void) {
    const d = document;
    const fn = () => {};
    const o = { callback: callback || fn };
    let s: any;
    let t: any;
    if (typeof src === 'undefined') {
      return;
    }
    s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = src;
    if (typeof o.callback === 'function') {
      if (d.addEventListener) {
        s.addEventListener(
          'load',
          () => {
            o.callback();
          },
          false
        );
      } else {
        // old IE support
        s.onreadystatechange = function () {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null;
            o.callback();
          }
        };
      }
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  track(tealiumEvent: string, data?: any) {
    if (this.window?.cxTealium?.utag === undefined) {
      console.warn('the tealium isnot ready');
      return;
    }
    this.window.cxTealium.utag[tealiumEvent](data);
  }

  view(data?: any) {
    if (this.winRef.isBrowser()) {
      this.track('view', data);
    }
  }

  link(data?: any) {
    if (this.winRef.isBrowser()) {
      this.track('link', data);
    }
  }
}
