<div class="site-login-container" [ngClass]="{ hadLogin: hadLogin }">
  <ng-container *ngIf="user$ | async as user; else login">
    <div class="am-user-icon-mobile" (click)="this.showAccountInfo()">
      <button type="button" [attr.aria-expanded]="showUserInfo" [attr.aria-label]="showUserInfo ? 'Close' : 'Profile'">
        <ng-container *ngIf="!(isShowAccountInfo | async)">
          <am-svg-container [type]="svgType.USERINFO" [style]="style_user_info"></am-svg-container>
        </ng-container>
        <span class="close-btn" *ngIf="isShowAccountInfo | async"></span>
      </button>
    </div>

    <!-- (mouseenter)="enterLoginInfoSec()"
    (mouseleave)="leaveLoginInfoSec()" -->
    <a
      class="am-login-greet"
      href="javascript:void(0)"
      (click)="focusLoginInfoSec($event)"
      [attr.aria-label]="'hi ' + user.name + ', ' + user.customerRecord?.account + ', ' + ' Link, Collapsed.'">
      <div>
        <p [innerHTML]="'amMinilogin.userGreeting' | cxTranslate : { name: user.name }">
          <!-- {{ 'amMinilogin.userGreeting' | cxTranslate: { name: user.name } }} -->
        </p>
        <p class="customer-record">
          <am-svg-container
            class="icon-grey-miles"
            [type]="svgType.AMICONGREY"
            svgDescription="Asia miles"></am-svg-container>
          <span>{{ user.customerRecord?.account | number }}</span>
        </p>
      </div>
      <span class="icon-arrow-down" [ngClass]="{ 'icon-arrow-up': showUserInfo }"></span>
    </a>

    <!-- <cx-page-slot position="HeaderLinks"></cx-page-slot> -->
    <!-- (mouseenter)="mouseenter()" (mouseleave)="mouseLeave()" -->
    <div class="am-login-user-info">
      <div class="userInfo-row">
        <div class="account-info">
          <p class="userName gb_mask">
            {{ user.firstName }},
            {{ user.name }}
          </p>
          <p>
            {{ 'amMinilogin.memberShipNo' | cxTranslate }}
            <span class="gb_mask">{{ user.customerId }}</span>
          </p>
        </div>
        <div class="account-overview-link">
          <a class="arrow-link" [href]="accountOverveiwUrl" (click)="OnAccountOverviewClick()">
            {{ 'amMinilogin.accoutnOverview' | cxTranslate }}<span class="icon-arrow-right"></span>
          </a>
        </div>
      </div>
      <div class="operate-row">
        <a class="your-orders-link" (click)="goOrders()" [routerLink]="{ cxRoute: 'orders' } | cxUrl" cxModal="dismiss">
          {{ 'amMinilogin.myOrders' | cxTranslate }}
        </a>
        <a class="my-list-link" href="javascript:void(0)" (click)="goMyList()" cxModal="dismiss">{{
          'amMinilogin.myLists' | cxTranslate
        }}</a>
        <a class="arrow-link logout-link" href="javascript:void(0)" (click)="logout()" (blur)="blurLoginInfoSec()">
          {{ 'amMinilogin.LogOut' | cxTranslate }}<span class="icon-arrow-right"></span>
        </a>
      </div>
    </div>

    <div class="am-login-user-info-mobile" [ngClass]="{ show: isShowAccountInfo | async }">
      <p class="userName gb_mask">
        {{ user.firstName }},
        {{ user.name }}
      </p>

      <div class="account-info">
        <div class="userInfo">
          <p>{{ 'amMinilogin.memberShipNo' | cxTranslate }}</p>
          <p class="gb_mask">{{ user.customerId }}</p>
        </div>

        <div class="accountBalance">
          <p>{{ 'amMinilogin.accountBalance' | cxTranslate }}</p>
          <p class="customer-record">
            <am-svg-container
              class="icon-light-grey-miles"
              [type]="svgType.AMICONLIGHTGREY"
              svgDescription="Golden asia miles"></am-svg-container>
            <span>{{ user.customerRecord?.account | number }}</span>
          </p>
        </div>
      </div>

      <div class="link-wrapper">
        <a class="your-orders-link" [routerLink]="{ cxRoute: 'orders' } | cxUrl" (click)="showAccountInfo()">
          {{ 'amMinilogin.myOrders' | cxTranslate }}
        </a>

        <a class="my-list-link" [routerLink]="{ cxRoute: 'wishList' } | cxUrl" (click)="showAccountInfo()">{{
          'amMinilogin.myLists' | cxTranslate
        }}</a>

        <p>
          {{ 'amMinilogin.accountManager' | cxTranslate }}
        </p>

        <a class="arrow-link" [href]="accountOverveiwUrl">
          {{ 'amMinilogin.accoutnOverview' | cxTranslate }}
        </a>

        <a class="arrow-link logout-link" href="javascript:void(0)" (click)="logout()" (blur)="focusBackToCloseBtn()">
          {{ 'amMinilogin.LogOut' | cxTranslate }}
        </a>
      </div>
    </div>
  </ng-container>

  <ng-template #login>
    <div class="am-login-btn-wrapper">
      <a class="am-signup-link" [href]="signUrl">
        {{ 'amMinilogin.signUp' | cxTranslate }}
      </a>
      <div class="am-login-split"></div>
      <a class="am-login-link" href="javascript:void(0)" (click)="loginWithRedirect()">
        {{ 'amMinilogin.signIn' | cxTranslate }}
      </a>
    </div>
  </ng-template>
</div>
