import { Component, OnInit } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';

@Component({
  selector: 'am-shopping-tip',
  templateUrl: './shopping-tip.component.html',
  styleUrls: ['./shopping-tip.component.scss']
})
export class ShoppingTipComponent implements OnInit {
  svgType = svgConfig;
  isFlow1Flag: boolean;
  popupName: string;
  activeTab: string;
  protected subscription: Subscription = new Subscription();
  constructor(protected launchDialogService: AmLaunchDialogService, protected amConfigService: AmConfigService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        if (!!data) {
          this.isFlow1Flag = data.isFlow1Flag;
          if (this.isFlow1Flag) {
            this.activeTab = 'inflight';
          } else {
            this.activeTab = 'ground';
          }
        }
      })
    );
  }

  closeModal(reason?: any): void {
    // console.log(this.constructor.name, 'namename');
    this.launchDialogService.closeDialog(reason, LAUNCH_CALLER.SHOPPING_TIP_DIALOG);
  }

  switchTab(tabName: string): void {
    this.activeTab = tabName;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
