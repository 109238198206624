import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { SvgContainerModule } from '../../../content/svg-container/svg-container.module';
import { AmSearchBoxComponent } from './am-search-box.component';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
  declarations: [AmSearchBoxComponent, HighlightPipe],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    AmMediaModule,
    I18nModule,
    UrlModule,
    IconModule,
    KeyboardFocusModule,
    SvgContainerModule
  ],
  exports: [AmSearchBoxComponent, HighlightPipe]
})
export class AmSearchBoxModule {}
