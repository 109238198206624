import { Injectable } from '@angular/core';
import { AmAsmEnablerService } from '@core/asm/root/services/am-asm-enable.service';
import { AuthConfig, AuthConfigService, OccConfig, WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class AmAuthConfigService extends AuthConfigService {
  constructor(
    protected authConfig: AuthConfig,
    protected occConfig: OccConfig,
    protected amAsmEnableSvc: AmAsmEnablerService,
    protected winRef: WindowRef
  ) {
    super(authConfig, occConfig);
  }

  public getBaseUrl(): string {
    return (
      this.authConfig?.authentication.baseUrl ??
      (this.occConfig?.backend?.occ?.baseUrl ?? '') + '/asiamilesspartacuswebservices/v2/asiamiles'
    );
  }

  getMlcLoginUrl(mlcLoginUrl: string, inflight?: boolean): string {
    const validateSessionUrl = this.getBaseUrl() + '/validateSession';
    const targetUrl = encodeURIComponent(this.winRef.nativeWindow?.location.origin);
    mlcLoginUrl = inflight ? `${mlcLoginUrl}?inflight=true&` : `${mlcLoginUrl}?`;
    const loginUrl = `${mlcLoginUrl}loginreferrer=${validateSessionUrl}?targetUrl=${targetUrl}`;
    return loginUrl;
  }

  getAmLogoutUrl(mlcLogoutUrl, idpLogoutUrl): string {
    const isAsm = this.amAsmEnableSvc.isEnabled();
    return isAsm ? idpLogoutUrl : mlcLogoutUrl;
  }

  getTokenEndpoint() {
    const isAsm = this.amAsmEnableSvc.isEnabled();
    const tokenEndpoint = super.getTokenEndpoint();
    return isAsm ? `${tokenEndpoint}?asm=true` : tokenEndpoint;
  }
}
