import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { Product, ProductService, RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { FavoriteNotifyProductsService } from '../../services/favorite-notify-products.service';

@Component({
  selector: 'am-favorite-notify-bottom-sheet',
  templateUrl: './favorite-notify-bottom-sheet.component.html'
})
export class FavoriteNotifyBottomSheetComponent {
  subscription: Subscription = new Subscription();
  product: Product;
  productCode: string;
  isNotifyMe: boolean;
  svgType = svgConfig;
  style_icon_close = 'width: 32px; height: 32px';
  electronicForm: FormGroup;
  alcoholicForm: FormGroup;
  country: string;
  mileValue: number = 1;

  constructor(
    protected launchDialogService: AmLaunchDialogService,
    protected routingService: RoutingService,
    protected productService: ProductService,
    protected cdr: ChangeDetectorRef,
    private countryService: CountryService,
    private fb: FormBuilder,
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService
  ) {
    this.subscription.add(
      this.countryService.getActive().subscribe((country) => {
        this.country = country;
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        if (!!data) {
          this.productCode = data.productCode;
          this.isNotifyMe = data.isNotifyMe;
        }
      })
    );
    this.subscription.add(
      this.productService.get(this.productCode).subscribe((product) => {
        if (product) {
          this.product = product;
          this.setElectronicForm(this.product);
          this.setAlcoholicForm(this.product);
        }
      })
    );
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  goProductDetails(product: Product): void {
    this.launchDialogService.closeDialog('');
    this.routingService.go({
      cxRoute: 'product',
      params: product
    });
  }

  getCurrentProductCode(productCode: string): void {
    this.setIsNotifyMe(productCode);
    this.subscription.add(
      this.productService.get(productCode).subscribe((product) => {
        if (product) {
          this.product = product;
          this.setElectronicForm(this.product);
          this.setAlcoholicForm(this.product);
          this.cdr.detectChanges();
        }
      })
    );
  }

  setIsNotifyMe(productCode: string) {
    this.isNotifyMe = false;
    this.subscription.add(
      this.favoriteNotifyProductsService.getNotifyList().subscribe((notifyMeList) => {
        notifyMeList.forEach((item) => {
          if (item.product.code === productCode) {
            this.isNotifyMe = true;
            this.cdr.detectChanges();
          }
        });
      })
    );
  }

  setElectronicForm(product: Product): void {
    this.electronicForm = null;
    if (product.specialProductType === 'ELECTRONIC' && this.country === 'HK') {
      this.electronicForm = this.fb.group({
        electronicRadio: [null, [Validators.required]],
        electronicCheckbox: [false, [Validators.required, this.clearFalseValidator()]]
      });
    }
  }

  setAlcoholicForm(product: Product): void {
    this.alcoholicForm = null;
    if (product.specialProductType === 'ALCOHOLIC' && !product.externalRedirect) {
      this.alcoholicForm = this.fb.group({
        wineCheckbox: [false, [Validators.required, this.clearFalseValidator()]]
      });
    }
  }

  clearFalseValidator(): ValidatorFn {
    return (control): object => {
      return control.value === true ? null : { error: 'required' };
    };
  }

  getAddCartResult(result): void {
    if (!result.errorMsg) {
      this.launchDialogService.closeDialog('');
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
