<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title" aria-live="polite" aria-atomic="true">
          {{ 'amCartDetails.slider.offerDetails' | cxTranslate }}
        </div>
        <button aria-label="Close current popup" type="button" class="close" (click)="closeModal('Cross click')">
          <span aria-hidden="true">
            <!-- <span class="icon-close-round"></span> -->
            <svg class="icon-close">
              <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
            </svg>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main d-flex">
          <div class="promotion-img">
            <img src="/assets/images/icons/discount-yellow.svg" *ngIf="!isEarnMiles" height="24" alt="" />
            <img src="/assets/images/icons/earn-yellow.svg" *ngIf="isEarnMiles" height="24" alt="" />
          </div>
          <div class="section">
            <p class="name font-bold">{{ promotion?.name }}</p>
            <p class="detail" *ngIf="promotion?.description" [innerHTML]="promotion.description | safeHtml"></p>
            <ng-container *ngIf="promotion?.promotionPeriod; else promotionPeriod">
              <p class="time font-bold">
                {{ 'amCartDetails.slider.promotion.popup.promotionPeriod' | cxTranslate }}
                {{ promotion?.promotionPeriod }}
              </p>
            </ng-container>
            <ng-template #promotionPeriod>
              <ng-container *ngIf="promotion?.startDate || promotion?.endDate">
                <p class="time font-bold">
                  {{ 'amCartDetails.slider.promotion.popup.promotionPeriod' | cxTranslate }}
                  {{ promotion?.startDate | amDate }} - {{ promotion?.endDate | amDate }}
                </p>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="promotion?.termsAndConditions">
              <a
                aria-label="Go to terms and conditions"
                class="terms-conditions font-size-xs"
                [href]="promotion?.termsAndConditions | safeUrl">
                {{ 'amCartDetails.slider.promotion.popup.termsAndCondition' | cxTranslate }}
              </a>
            </ng-container>
            <!-- <am-terms-and-conditions [promotion]="promotion" [source]="'cart'"></am-terms-and-conditions> -->
          </div>

          <div class="icon-tooltip-right" *ngIf="promotion?.summary">
            <span class="earn-miles-icon">
              <img src="/assets/images/icons/earn-yellow.svg" height="18" alt="" />
            </span>
            <span class="earn-miles-tag-content-block" [innerHTML]="promotion?.summary | safeHtml"> </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
