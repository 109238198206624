<div class="cart-detail-nonflow1-container">
  <div class="light-grey-background">
    <am-cart-messages
      [isStoreOpenStatus]="isStoreOpenStatus"
      [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(cart)"></am-cart-messages>
    <div class="container">
      <div class="cart-item-container">
        <div class="cart-item-left">
          <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
            <am-cart-item-list
              [items]="entries"
              [cartIsLoading]="cartLoaded"
              [isInflightEnv]="cart.isInflightEnv"
              [isStoreOpenStatus]="isStoreOpenStatus"
              [promotionLocation]="promotionLocation"
              [validationDatas]="cart.validationDatas"></am-cart-item-list>
          </div>
        </div>

        <div class="cart-item-right">
          <am-cart-totals
            [isDelistedOrOffline]="checkCartItemIfDelistOrOffline(cart)"
            [isStoreOpenStatus]="isStoreOpenStatus"
            (isStoreOpenStatusOff)="getStoreOpenStatusOff($event)"></am-cart-totals>
        </div>
      </div>
    </div>
  </div>
</div>
