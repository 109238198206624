import { Injectable } from '@angular/core';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { Product, TranslationService, User, WindowRef } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { ProductDetailService } from 'src/app/amiredeem/pages/product-detail/service/product.service';
import { AmGetConfigService } from '../../config/am-getconfig.service';
import {
  deliveryMethod,
  deliveryStatus,
  displayFullfillmentType,
  mapping_deliveryMethod,
  mapping_errorCode,
  mapping_errorMsg,
  mapping_errorType,
  mapping_fulfillmentType,
  mapping_mpcOptioin,
  mapping_pageName,
  mapping_paymentMethod,
} from './cxDataLayerMapping';

interface userExtend extends User {
  customerRecord?: { tierCode?: string };
}

@Injectable({
  providedIn: 'root'
})
export class UpdateCxDataLayerService {
  window = (this.winRef.nativeWindow as any) ?? {};
  env = '';
  version = '';
  dateFormat = '';
  subscription = new Subscription();
  constructor(
    protected winRef: WindowRef,
    protected amConfigService: AmConfigService,
    protected productDetailService: ProductDetailService,
    protected currentProductService: CurrentProductService,
    protected authService: AmAuthService,
    protected amGetConfigService: AmGetConfigService,
    private translate: TranslationService
  ) {
    if (!this.winRef.isBrowser()) {
      return;
    }
    this.initEnvData();
    this.subscription.add(
      this.translate.translate('amFormat.time.pattern.normal').subscribe((val) => {
        this.dateFormat = val;
      })
    );
    this.updatePLPDataLayer = this.updatePLPDataLayer.bind(this);
    this.updatePDPDataLayer = this.updatePDPDataLayer.bind(this);
    this.updateSeachDataLayer = this.updateSeachDataLayer.bind(this);
    this.updateCartDataLayer = this.updateCartDataLayer.bind(this);
    this.updateCheckoutDataLayer = this.updateCheckoutDataLayer.bind(this);
    this.updateMemberVerificationDataLayer = this.updateMemberVerificationDataLayer.bind(this);
    // this.updateOrderConfrimationDataLayer = this.updateOrderConfrimationDataLayer.bind(this);
    this.updateOrderHistoryDataLayer = this.updateOrderHistoryDataLayer.bind(this);
    this.updateOrderDetailDataLayer = this.updateOrderDetailDataLayer.bind(this);
    this.updatePageErrorDataLayer = this.updatePageErrorDataLayer.bind(this);
    this.updateCampaignPage = this.updateCampaignPage.bind(this);
    this.updatePendingOrderConfrimationDataLayer = this.updatePendingOrderConfrimationDataLayer.bind(this);
    this.updateOrderConfrimationDataLayer = this.updateOrderConfrimationDataLayer.bind(this);
  }

  initEnvData(): void {
    this.subscription.add(
      this.amConfigService.getCommonConfig().subscribe((data) => {
        if (data) {
          this.env = data.env;
          this.version = data.dataLayerVersion;
        }
      })
    );
  }

  updateCampaignPage(data): void {
    this.window.cxDataLayer.page.name =
      this.window.cxDataLayer.page.name + '/' + data.campaign?.label.toUpperCase() ?? '';
  }

  // ------------------------- udpate for page -------------------------
  updateCartDataLayer(data): void {
    let products = [];
    data.activeCart?.entries?.forEach((e) => {
      let variance = this.getProductVariance(e.product);
      products.push({
        id: this.replaceQuotationMarks(e.product?.code) ?? '',
        name: this.replaceQuotationMarks(e.product?.nameEn) ?? '',
        brand: this.replaceQuotationMarks(e.product?.brandNameEn).toUpperCase() ?? '',
        fulfilment_type: mapping_fulfillmentType.get(e.product.collectionMethod.toUpperCase()),
        category_t1: e.product?.category_t1.toUpperCase() ?? '',
        category_t2: e.product?.category_t2.toUpperCase() ?? '',
        category_t3: e.product?.category_t3.toUpperCase() ?? '',
        category_t4: e.product?.category_t4.toUpperCase() ?? '',
        variance_1: variance.get('variance_1'),
        variance_2: variance.get('variance_2'),
        qty: e.quantity + '',
        currency: e.product?.currency,
        partner_code: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCode) ?? '',
        partner_name: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
        mpc_option:
          e.product?.paymentMethod == 'MilesPlusCash' && e.product?.minimumMilesSpend < 0
            ? 'C'
            : mapping_mpcOptioin.get(e.product.paymentMethod),
        price_cash: Number(
          e.product?.discountFullCashPrice ? e.product.discountFullCashPrice : e.product.originFullCashPrice
        ).toFixed(2),
        price_miles: e.product?.promotionPrice?.value
          ? e.product.promotionPrice?.value.toString()
          : e.product?.price?.value.toString()
      });
    });
    this.window.cxDataLayer = {
      ...this.window.cxDataLayer,
      cart: {
        currency: data.activeCart?.entries?.length > 0 ? data.activeCart?.entries[0].product.currency ?? '' : '',
        preferred_total_miles: (data.activeCart?.totalPrice?.value ?? '0').toString(),
        preferred_total_cash: Number(data.activeCart?.totalPriceCash?.value ?? '0').toFixed(2)
      },
      product: products
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer);
  }

  updateCheckoutDataLayer(data): void {
    let cxDataLayer = {};
    let products = [];
    data.activeCart?.entries?.forEach((entry) => {
      const { product, totalPriceCash, totalPrice, quantity } = entry;
      const {
        category_t1,
        category_t2,
        category_t3,
        category_t4,
        code,
        nameEn,
        currency,
        brandNameEn,
        collectionMethod,
        productPartnerInfo,
        minimumMilesSpend,
        paymentMethod
      } = product;
      let variance = this.getProductVariance(product);
      products.push({
        id: this.replaceQuotationMarks(code) ?? '',
        name: this.replaceQuotationMarks(nameEn) ?? '',
        brand: this.replaceQuotationMarks(brandNameEn).toUpperCase() ?? '',
        fulfilment_type: mapping_fulfillmentType.get(collectionMethod.toUpperCase()),
        category_t1: category_t1?.toUpperCase() ?? '',
        category_t2: category_t2?.toUpperCase() ?? '',
        category_t3: category_t3.toUpperCase() ?? '',
        category_t4: category_t4.toUpperCase() ?? '',
        variance_1: variance?.get('variance_1'),
        variance_2: variance?.get('variance_2'),
        qty: quantity + '',
        currency: currency,
        partner_code: this.replaceQuotationMarks(productPartnerInfo?.partnerCode) ?? '',
        partner_name: this.replaceQuotationMarks(productPartnerInfo?.partnerCompanyNameEn) ?? '',
        mpc_option:
          paymentMethod == 'MilesPlusCash' && minimumMilesSpend < 0 ? 'C' : mapping_mpcOptioin.get(paymentMethod),
        price_cash: totalPriceCash?.value?.toFixed(2) ?? '0',
        price_miles: totalPrice?.value ?? '0',
        subtotal: totalPriceCash?.value?.toFixed(2) ?? '0'
      });
    });
    cxDataLayer = {
      cart: {
        preferred_mpc_option:
          data.activeCart?.totalPrice.value <= 0 && data.activeCart?.totalPriceCash.value > 0
            ? 'C'
            : data.activeCart?.totalPrice.value > 0 && data.activeCart?.totalPriceCash.value <= 0
            ? 'M'
            : 'MPC',
        preferred_total_miles: data.activeCart?.totalPrice?.value + '',
        preferred_total_cash: data.activeCart?.totalPriceCash?.value + '',
        currency: data.activeCart?.entries?.length > 0 ? data.activeCart?.entries[0].product.currency ?? '' : '',
        mpc_adjustment: data.activeCart?.selectionMiles > data.activeCart?.minMiles ? 'YES' : 'NO'
      },
      product: products
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, cxDataLayer);
  }

  updateOrderDetailDataLayer(data: any, cxDataLayer: {}): void {
    let consignments = [];

    data?.order?.orderData?.entries?.forEach((e) => {
      consignments.push({
        consignment_delivery_status: deliveryStatus(
          e?.deliveryStatus.toUpperCase(),
          e?.product?.collectionMethod.toUpperCase(),
          e.subMethod
        ),
        consignment_delivery_method: deliveryMethod(
          e?.product?.collectionMethod.toUpperCase(),
          data?.order?.orderData?.deliveryMethod
        ),
        consignment_delivery_partner_code: this.replaceQuotationMarks(e.product.deliveryPartnerInfo?.partnerCode) ?? '',
        consignment_delivery_partner_name:
          this.replaceQuotationMarks(e.product.deliveryPartnerInfo?.partnerCompanyNameEn) ?? '',
        consignment_product_partner_code: this.replaceQuotationMarks(e.product.productPartnerInfo?.partnerCode) ?? '',
        consignment_product_partner_name:
          this.replaceQuotationMarks(e.product.productPartnerInfo?.partnerCompanyNameEn) ?? '',
        consignment_fullfillment_type: displayFullfillmentType(e?.product?.collectionMethod.toUpperCase())
      });
    });
    cxDataLayer = {
      consignment: consignments
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, cxDataLayer);
  }

  updateCommonPageInfo(pageType, data): void {
    let userInfo: userExtend = null;
    this.amGetConfigService
      .getUserInfo()
      .subscribe((user) => {
        userInfo = user;
      })
      .unsubscribe();

    let commonPageInfo = {
      application: {
        name: 'CATHAYSHOP',
        version: this.version,
        env: this.env
      },

      page: {
        name: 'CATHAYSHOP/' + mapping_pageName.get(pageType),
        country: 'DELIVERY::' + data.country,
        language: data.language.toUpperCase(),
        mobile_app_screen_view: this.isInMobileAppView(),
        pillar: 'SHOPPING',
        flow: data.isInflight?.data ? 'INFLIGHT' : 'GROUND',
        stream: 'CATHAYSHOP'
      },
      registration: {
        type: userInfo?.customerRecord?.tierCode ?? '',
        hash_id: data.hashId?.data ?? '', //need backend returndata.hashId?.data
        login_status: data.userService ? 'LOGGED IN' : 'LOGGED OUT' //need user login info
      }
    };
    if (pageType != 'generalError' && pageType != 'balanceError' && pageType != 'notFound') {
      let conversion = {
        conversion: {
          name: 'WEB::CATHAYSHOP::' + mapping_pageName.get(pageType)
        }
      };
      Object.assign(commonPageInfo, conversion);
    }
    this.window.cxDataLayer = Object.assign(commonPageInfo);
  }

  isInMobileAppView() {
    return this.winRef.sessionStorage?.getItem('isMobileView') ?? 'false';
  }

  updateSeachDataLayer(data): void {
    let sortBy = '';
    data.search?.sorts?.forEach((e) => {
      if (e.selected) {
        sortBy = e.name;
      }
    });
    let templateInfo = {
      search: {
        type: 'CATHAYSHOP',
        keyword: data.search?.freeTextSearch,
        sort_by: sortBy.toUpperCase()
      }
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, templateInfo);
  }

  updatePLPDataLayer(data): void {
    let sortBy = '';
    data.plp?.sorts?.forEach((e) => {
      if (e.selected) {
        sortBy = e.name.toUpperCase();
      }
    });
    let templateInfo = {
      search: {
        product_category_t1: data?.plp?.queryResult?.category_t1
          ? data?.plp?.queryResult?.category_t1.toUpperCase()
          : '',
        product_category_t2: data?.plp?.queryResult?.category_t2
          ? data?.plp?.queryResult?.category_t2.toUpperCase()
          : '',
        product_category_t3: data?.plp?.queryResult?.category_t3
          ? data?.plp?.queryResult?.category_t3.toUpperCase()
          : '',
        product_category_t4: data?.plp?.queryResult?.category_t4
          ? data?.plp?.queryResult?.category_t4.toUpperCase()
          : '',
        sort_by: sortBy
      }
    };
    let category_t1 = (
        data?.plp?.queryResult?.category_t1 ? '/' + data?.plp?.queryResult?.category_t1 : ''
      ).toUpperCase(),
      category_t2 = (
        data?.plp?.queryResult?.category_t2 ? '/' + data?.plp?.queryResult?.category_t2 : ''
      ).toUpperCase(),
      category_t3 = (
        data?.plp?.queryResult?.category_t3 ? '/' + data?.plp?.queryResult?.category_t3 : ''
      ).toUpperCase(),
      category_t4 = (
        data?.plp?.queryResult?.category_t4 ? '/' + data?.plp?.queryResult?.category_t4 : ''
      ).toUpperCase();
    this.window.cxDataLayer.page.name = `CATHAYSHOP/PRODUCT_LISTING${category_t1}${category_t2}${category_t3}${category_t4}`;
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, templateInfo);
  }

  updatePDPDataLayer(object): void {
    const data = object.pdpData;
    let variance = this.getProductVariance(data);
    let tempPageInfo = {
      product: [
        {
          id: this.replaceQuotationMarks(data?.code),
          name: this.replaceQuotationMarks(data?.nameEn),
          brand: this.replaceQuotationMarks(data?.brandNameEn?.toUpperCase()),
          category_t1: data?.category_t1?.toUpperCase() ?? '',
          category_t2: data?.category_t2?.toUpperCase() ?? '',
          category_t3: data?.category_t3?.toUpperCase() ?? '',
          category_t4: data?.category_t4?.toUpperCase() ?? '',
          fulfilment_type: mapping_fulfillmentType.get(data.productType?.toUpperCase()),
          variance_1: variance.get('variance_1'),
          variance_2: variance.get('variance_2'),
          availability: data.isSalable && data.isInRedeemPeriod ? 'TRUE' : 'FASLE',
          partner_code: this.replaceQuotationMarks(data?.productPartnerInfo?.partnerCode ?? ''),
          partner_name: this.replaceQuotationMarks(data?.productPartnerInfo?.partnerCompanyNameEn ?? ''),
          price_cash: Number(
            data?.discountFullCashPrice ? data.discountFullCashPrice : data.originFullCashPrice
          ).toFixed(2),
          price_miles: data?.promotionPrice?.value
            ? data.promotionPrice.value.toString()
            : data.price?.value.toString(),
          currency: data?.currency,
          mpc_option:
            data?.paymentMethod == 'MilesPlusCash' && data?.minimumMilesSpend < 0
              ? 'C'
              : mapping_mpcOptioin.get(data?.paymentMethod)
        }
      ]
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, tempPageInfo);
    this.window.cxDataLayer.page.name =
      'CATHAYSHOP/PRODUCT_DETAILS/' +
      data?.category_t1?.toUpperCase() +
      '/' +
      data?.code.substring(0, 8) +
      '_' +
      data?.brandName?.toUpperCase() +
      '_' +
      data?.name?.toUpperCase();
  }

  updateMemberVerificationDataLayer(data): void {
    const { checkoutInfo } = data;
    const { cart } = checkoutInfo ?? {};
    const addressInfo = checkoutInfo?.deliveryInfo ?? {};
    let products = [];
    cart?.entries?.forEach((e) => {
      let variance = this.getProductVariance(e.product);
      products.push({
        id: this.replaceQuotationMarks(e.product?.code) ?? '',
        name: this.replaceQuotationMarks(e.product?.nameEn) ?? '',
        brand: this.replaceQuotationMarks(e.product?.brandNameEn).toUpperCase() ?? '',
        fulfilment_type: mapping_fulfillmentType.get(e.product.collectionMethod.toUpperCase()),
        category_t1: e.product?.category_t1.toUpperCase() ?? '',
        category_t2: e.product?.category_t2.toUpperCase() ?? '',
        category_t3: e.product?.category_t3.toUpperCase() ?? '',
        category_t4: e.product?.category_t4.toUpperCase() ?? '',
        variance_1: variance.get('variance_1'),
        variance_2: variance.get('variance_2'),
        qty: e.quantity + '',
        currency: e.product?.currency,
        partner_code: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCode) ?? '',
        partner_name: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
        mpc_option:
          e.product?.paymentMethod == 'MilesPlusCash' && e.product?.minimumMilesSpend < 0
            ? 'C'
            : mapping_mpcOptioin.get(e.product.paymentMethod),
        price_cash: e.totalPriceCash?.value?.toFixed(2) ?? 0,
        price_miles: e.totalPrice?.value ?? 0,
        subtotal: e.totalPriceCash?.value?.toFixed(2) ?? '0'
      });
    });

    let otpObject = {
      cart: !!cart
        ? {
            preferred_mpc_option:
              cart.totalPrice?.value <= 0 && cart.totalPriceCash?.value > 0
                ? 'C'
                : cart.totalPrice?.value > 0 && cart.totalPriceCash?.value <= 0
                ? 'M'
                : 'MPC',
            preferred_total_miles: cart.totalPrice?.value + '',
            preferred_total_cash: Number(cart.totalPriceCash?.value ?? '0').toFixed(2),
            currency: cart.entries?.length > 0 ? cart.entries[0].product.currency ?? '' : '',
            mpc_adjustment: cart.selectionMiles > cart.minMiles ? 'YES' : 'NO',
            delivery_method: mapping_deliveryMethod.get(addressInfo.physicalDeliveryInfo.deliveryMethodCode)
          }
        : {},
      product: products
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, otpObject);
  }

  updateOrderConfrimationDataLayer(data): void {
    if (!data) {
      return;
    }
    let products = [];
    let currency = '';
    data?.order?.orderDetailData?.totalDelivery?.forEach((e) => {
      const { product } = e;
      const variance = this.getProductVariance(product);
      if (!!product) {
        products.push({
          id: this.replaceQuotationMarks(product.code) ?? '',
          name: this.replaceQuotationMarks(product.nameEn) ?? '',
          brand: this.replaceQuotationMarks(product.brandNameEn).toUpperCase() ?? '',
          fulfilment_type: mapping_fulfillmentType.get(product.collectionMethod.toUpperCase()),
          category_t1: product.category_t1.toUpperCase() ?? '',
          category_t2: product.category_t2.toUpperCase() ?? '',
          category_t3: product.category_t3.toUpperCase() ?? '',
          category_t4: product.category_t4.toUpperCase() ?? '',
          variance_1: variance.get('variance_1'),
          variance_2: variance.get('variance_2'),
          qty: e.quantity ?? e.qty,
          currency: product.currency,
          partner_code: this.replaceQuotationMarks(product.productPartnerInfo?.partnerCode) ?? '',
          partner_name: this.replaceQuotationMarks(product.productPartnerInfo?.partnerCompanyNameEn) ?? '',
          mpc_option:
            product.paymentMethod == 'MilesPlusCash' && product.minimumMilesSpend <= 0
              ? 'C'
              : mapping_mpcOptioin.get(product.paymentMethod),
          price_cash: e?.totalPriceCash?.value?.toFixed(2) ?? e.totalPriceCash.toFixed(2),
          price_miles: e?.totalMiles ?? '0', // error
          subtotal: Number(e?.totalPriceCash?.value ?? e.totalPriceCash).toFixed(2)
        });
        currency = product?.currency;
      }
    });
    const { orderData } = data.order ?? {};
    let tempPageInfo = {
      transaction: {
        payment_method: mapping_paymentMethod.get(orderData?.paymentMethod) ?? '',
        mpc_option:
          orderData?.totalMiles <= 0 && orderData?.totalPriceCash?.value > 0
            ? 'C'
            : orderData?.totalMiles > 0 && orderData?.totalPriceCash?.value <= 0
            ? 'M'
            : 'MPC',
        currency: currency,
        subtotal: Number(orderData?.totalPriceCash?.value ?? '0').toFixed(2),
        // delivery_fee: (data.order?.deliveryFee),
        order_email_delivery: orderData?.deliveryAddress?.needEmailDelivery?.toString().toUpperCase(),
        payment_total_miles: (orderData?.totalMiles ?? '0').toString(),
        payment_total: Number(orderData?.totalPriceCash?.value ?? '0').toFixed(2),
        aa_transaction_id: orderData?.code,
        delivery_method: mapping_deliveryMethod.get(orderData?.deliveryMethod) ?? ''
      },
      product: products
    };
    if (data?.order?.orderData?.status === 'PENDING') {
      tempPageInfo.transaction.order_email_delivery = null;
    }
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, tempPageInfo);
  }

  updateOrderHistoryDataLayer(data): void {
    let orderList = [];
    data.orderList.orders.forEach((order) => {
      orderList.push({
        order_date: dayjs(order.placed).format(this.dateFormat),
        order_number: order.code,
        order_value_total_miles: order.totalMiles,
        order_value_total_cash: order.totalPriceCash
      });
    });
    let templateInfo = {
      orders: orderList
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, templateInfo);
  }

  updatePageErrorDataLayer(data, pageType?: string): void {
    let errors = [];
    let errorCode = Boolean(data?.error?.errorCode) ? data?.error?.errorCode : mapping_errorCode.get(pageType);
    errors.push({
      error_category: 'ERROR::CATHAYSHOP::SYSTEM',
      error_code: 'ERROR::CATHAYSHOP::' + errorCode,
      error_description: Boolean(data?.error?.errorDescription)
        ? data?.error?.error_description
        : mapping_errorMsg.get(pageType)
    });
    let tempPageInfo = {
      error: errors
    };
    this.window.cxDataLayer.page.name = 'CATHAYSHOP/' + mapping_pageName.get(pageType);
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, tempPageInfo);
  }

  updatePendingOrderConfrimationDataLayer(data): void {
    let products = [];
    let currency = '';
    data.order.orderData?.entries?.forEach((e) => {
      let variance = this.getProductVariance(e.product);
      products.push({
        id: this.replaceQuotationMarks(e.product?.code) ?? '',
        name: this.replaceQuotationMarks(e.product?.nameEn) ?? '',
        brand: this.replaceQuotationMarks(e.product?.brandNameEn).toUpperCase() ?? '',
        fulfilment_type: mapping_fulfillmentType.get(e.product.collectionMethod.toUpperCase()),
        category_t1: e.product?.category_t1.toUpperCase() ?? '',
        category_t2: e.product?.category_t2.toUpperCase() ?? '',
        category_t3: e.product?.category_t3.toUpperCase() ?? '',
        category_t4: e.product?.category_t4.toUpperCase() ?? '',
        variance_1: variance.get('variance_1'),
        variance_2: variance.get('variance_2'),
        qty: e.quantity + '',
        currency: e.product?.currency,
        partner_code: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCode) ?? '',
        partner_name: this.replaceQuotationMarks(e.product?.productPartnerInfo?.partnerCompanyNameEn) ?? '',
        mpc_option:
          e.product?.paymentMethod == 'MilesPlusCash' && e.product?.minimumMilesSpend <= 0
            ? 'C'
            : mapping_mpcOptioin.get(e.product.paymentMethod),
        price_cash: (e.totalPriceCash?.value ?? '0').toFixed(2),
        price_miles: (e.totalMiles ?? '0').toString(),
        subtotal: (e.totalPriceCash?.value ?? '0').toFixed(2)
      });
      currency = e.product?.currency;
    });
    let tempPageInfo = {
      transaction: {
        payment_method: mapping_paymentMethod.get(data.order?.orderData?.paymentMethod) ?? '',
        mpc_option:
          data.order?.orderData?.totalMiles * 1 <= 0 && data.order?.orderData?.totalPriceCash.value * 1 > 0
            ? 'C'
            : data.order?.orderData?.totalMiles * 1 > 0 && data.order?.orderData?.totalPriceCash.value * 1 <= 0
            ? 'M'
            : 'MPC',
        currency: currency,
        subtotal: Number(data.order?.orderData?.totalPriceCash?.value ?? '0').toFixed(2),
        // order_email_delivery: data.order?.orderData?.deliveryAddress?.needEmailDelivery?.toString().toUpperCase(),
        // delivery_fee: (data.order?.deliveryFee),
        payment_total_miles: (data.order?.orderData?.totalMiles ?? '0').toString(),
        payment_total: Number(data.order?.orderData?.totalPriceCash?.value ?? '0').toFixed(2),
        aa_transaction_id: data.order?.orderData?.code,
        delivery_method: mapping_deliveryMethod.get(data.order?.orderData?.deliveryMethod) ?? ''
      },
      product: products
    };
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, tempPageInfo);
  }

  // ----------------------- Update for Event ----------------------
  updateAddToCartEvent(data): any {
    let addToCartEventInfo = {
      event: {
        event_category: 'USER-INTERACTIONS::CATHAYSHOP',
        event_action: 'CATHAYSHOP::BUTTON::CLICK',
        event_label: 'ADD TO CART'
      },
      product: this.window.cxDataLayer.product,
      conversion: {
        name: 'WEB::CATHAYSHOP::ADD_TO_CART'
      }
    };
    if (addToCartEventInfo.product?.length > 0) {
      addToCartEventInfo.product[0].qty = data.quantityAdded;
    }
    let product: Product;
    this.currentProductService.getProduct().subscribe((val: Product) => {
      product = val;
    });
    if (!product) {
      return;
    }
    // let mpcOption = data.entry.product.paymentMethod,
    //   maxMiles = data.entry.product.maxMilesSpend,
    //   minMiles = data.entry.product.minimumMilesSpend,
    let mpcOption = product.paymentMethod,
      maxMiles = product.maxMilesSpend,
      minMiles = product.minimumMilesSpend,
      selectionMiles = 0,
      selectionCash = 0;
    this.subscription.add(
      this.productDetailService.loadProductSelectedMilesObs.subscribe((miles) => {
        selectionMiles = miles;
      })
    );
    this.subscription.add(
      this.productDetailService.loadProductSelectedCashObs.subscribe((cash) => {
        selectionCash = cash;
      })
    );

    if (mpcOption == 'MilesPlusCash' || mpcOption == 'FullCashOrMpcWithMinMiles') {
      if (maxMiles == selectionMiles) {
        // cash = 0, miles = maxMiles
        addToCartEventInfo.product[0].preferred_mpc_option = 'M';
        addToCartEventInfo.product[0].preferred_cash = 0;
        addToCartEventInfo.product[0].preferred_miles = selectionMiles;
      } else if (minMiles != 0 && minMiles <= selectionMiles && maxMiles != selectionMiles) {
        // cash != 0, miles >= minMiles
        addToCartEventInfo.product[0].preferred_mpc_option = 'MPC';
        addToCartEventInfo.product[0].preferred_cash = selectionCash;
        addToCartEventInfo.product[0].preferred_miles = selectionMiles;
      } else if (minMiles == 0 && minMiles == selectionMiles) {
        // cash != 0, miles = 0
        addToCartEventInfo.product[0].preferred_mpc_option = 'C';
        addToCartEventInfo.product[0].preferred_cash = selectionCash;
        addToCartEventInfo.product[0].preferred_miles = 0;
      }
    } else {
      addToCartEventInfo.product[0].preferred_mpc_option = addToCartEventInfo.product[0].mpc_option;
      addToCartEventInfo.product[0].preferred_cash = addToCartEventInfo.product[0].price_cash;
      addToCartEventInfo.product[0].preferred_miles = addToCartEventInfo.product[0].price_miles;
    }
    addToCartEventInfo.product[0].preferred_cash = Number(addToCartEventInfo.product[0].preferred_cash).toFixed(2);
    addToCartEventInfo.product[0].preferred_miles = addToCartEventInfo.product[0].preferred_miles.toString();
    return addToCartEventInfo;
  }

  updateMemberVerificationError(data): void {
    let memberVerificationError = {
      errors: [
        {
          error_category: 'ERROR::CATHAYSHOP::' + mapping_errorType.get(data?.code) ?? '',
          error_code: 'ERROR::CATHAYSHOP::' + data?.otpInfoCode ?? '',
          error_description: data?.message ?? ''
        }
      ]
    };
    this.window.cxDataLayer.conversion.name = 'WEB::CATHAYSHOP::MEMBER_VERIFICATION_ERROR';
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, memberVerificationError);
  }

  updatePaymentVerificationOTP(): Object {
    let paymentVerificationOTP = {
      event: {
        event_category: 'USER-INTERACTIONS::CATHAYSHOP',
        event_action: 'PAYMENT_VERIFICATION::SEND_OTP::CLICK',
        event_label: 'SEND_OTP',
        event_value: 1
      }
    };
    return paymentVerificationOTP;
  }

  updatePaymentVerificationError(data): void {
    let PaymentVerificationError = {
      errors: [
        {
          error_category: 'ERROR::CATHAYSHOP::CLIENT',
          error_code: 'ERROR::CATHAYSHOP::' + data.code,
          error_description: data.otpMsg
        }
      ]
    };
    this.window.cxDataLayer.conversion.name = 'WEB::CATHAYSHOP::PAYMENT_VERIFICATION_ERROR';
    this.window.cxDataLayer = Object.assign(this.window.cxDataLayer, PaymentVerificationError);
  }

  getProductVariance(productData): Map<string, string> {
    let varianceMap = new Map([
      ['variance_1', ''],
      ['variance_2', '']
    ]);
    let i = 1;
    if (productData == null) {
      return varianceMap;
    }
    productData.baseOptions &&
      productData.baseOptions[0]?.selected?.variantOptionQualifiers.forEach((v) => {
        varianceMap.set('variance_' + i, v.name + ':' + v.value);
        i++;
      });
    return varianceMap;
  }

  replaceQuotationMarks(str): any {
    if (str == null || str == '') {
      return str;
    }
    return str.replace(new RegExp("/'/gi"), "\\\\'");
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
