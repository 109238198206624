import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoriteNotifyReminderService {
  productCode: string;
  private isOpenReminderSub = new BehaviorSubject<string>('');
  public isOpenReminderObj = this.isOpenReminderSub.asObservable();

  openReminder(productCode: string) {
    this.isOpenReminderSub.next(productCode);
  }
}
