import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WebServiceResponse } from '@model/WebServiceResponse.model';
import {
  BasePageMetaResolver,
  CmsService,
  PageLinkService,
  PageMetaResolver,
  PageTitleResolver,
  PageType,
  ProductSearchPage
} from '@spartacus/core';
import { escaper } from '@utils/escaper';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { AmProductListComponentService } from '../../../../pages/product-list/services/product-list-component.service';
interface AmProductSearchPage extends ProductSearchPage {
  responseInfo?: WebServiceResponse;
}

@Injectable({
  providedIn: 'root'
})
export class AmCategoryPageMetaResolver extends PageMetaResolver implements PageTitleResolver {
  pageTitle: string = '';

  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    private productListComponentService: AmProductListComponentService,
    protected readonly cms: CmsService,
    protected readonly pageLinkService: PageLinkService,
    protected readonly router: Router
  ) {
    super();
    this.pageType = PageType.CATEGORY_PAGE;
  }

  protected search$: Observable<AmProductSearchPage> = this.productListComponentService.model$;

  resolveTitle(): Observable<string> {
    let title: string = '',
      searchStr: string = '',
      replaceStr: string = '';
    return this.search$.pipe(
      switchMap((category) => {
        const pageTitle = category?.responseInfo?.pageTitle.split('|');
        if (!pageTitle) {
          return of('');
        }
        if (pageTitle?.length === 1) {
          title = category.queryResult?.categoryName + ' | ' + category.responseInfo.pageTitle;
        } else {
          searchStr = category.responseInfo?.pageTitle.split('|')[0].trim();
          replaceStr = category.queryResult?.categoryName;
          title = category.responseInfo.pageTitle.replace(searchStr, replaceStr);
        }
        return of(escaper.decode(title));
      })
    );
  }

  resolveDescription(): Observable<string | undefined> {
    return this.search$.pipe(
      switchMap((category) => {
        const categoryName = category.queryResult.categoryName;
        return this.cms.getCurrentPage().pipe(
          filter((page) => !!page),
          switchMap((page) => {
            const pageMetaDesription = page.properties?.description?.replace('{main_category}', categoryName);
            return of(pageMetaDesription);
          })
        );
      })
    );
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      startWith(null),
      map(() => this.pageLinkService.getCanonicalUrl())
    );
  }
}
