import { Component, Input, SimpleChanges } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CollectionMethod } from '@model/collection-method.enum';
import { Product, TranslationService } from '@spartacus/core';
import * as dayjs from 'dayjs';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, mergeMap, switchMap } from 'rxjs/operators';
import { DayjsService } from '../dayjs.service';

@Component({
  selector: 'am-delivery',
  templateUrl: './delivery.component.html'
})
export class DeliveryComponent {
  /**
   * whether inflight env
   */
  @Input() isInflightEnv: boolean = false;

  /**
   * delivery point of service info
   */
  @Input() deliveryPointOfService;
  /**
   *  from which page
   */
  @Input() pageFrom: string = '';

  @Input() showPopoverButton: boolean = true;
  collectionMethod = CollectionMethod;

  @Input() product;

  // @Output() clickEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  delivery$;

  svgType = svgConfig;
  constructor(private translate: TranslationService, protected dayjsService: DayjsService) {}

  handleDeliveryPeriod(product: Product): Observable<string> {
    // this.isInflightEnv = this.isInflightEnv || product.isInflightEnv;

    const showLeadTime = this.isInflightEnv && !product.productDeliveryTime.isFixDeliveryDate;
    let text = '';
    let param = {} as { param0: any; param1: any };
    let deliveryStartDate, deliveryEndDate;
    let productDeliveryTime = product.productDeliveryTime || {};
    let deliveryPeriod$;
    // handle not pdp page shop pickup and have delivery point of service product
    if (
      product.collectionMethod === CollectionMethod.SHOP_PICKUP &&
      this.pageFrom !== 'pdp' &&
      this.deliveryPointOfService
    ) {
      return this.translate.translate('amDelivery.period.fulfillmentType.shopPickup', {
        param0: this.deliveryPointOfService.deliveryTime,
        param1: this.deliveryPointOfService.storeName,
        param2: this.deliveryPointOfService.storeLocation
      });
    }

    // handle inflight pickup delivery
    if (product.collectionMethod === CollectionMethod.INFLIGHT_PICKUP) {
      if (this.pageFrom !== 'pdp') {
        return this.translate.translate('amDelivery.period.fulfillmentType.inflightPickup');
      } else {
        return this.translate.translate('amDelivery.period.fulfillmentType.inflightPickupPdp');
      }
    }
    // pdp page show inflight env delivery time
    if (showLeadTime) {
      text = 'amDelivery.period.dayMax';
      param.param0 = productDeliveryTime.deliveryTimeMax;
      if (productDeliveryTime.deliveryTimeMin > 0) {
        text = 'amDelivery.period.dayMinMax';
        param.param0 = productDeliveryTime.deliveryTimeMin;
        param.param1 = productDeliveryTime.deliveryTimeMax;
      }
      if (productDeliveryTime.deliveryTimeMax === 1) {
        text = 'amDelivery.period.dayOne';
      }
      if (productDeliveryTime.deliveryTimeMax === 0) {
        text = 'amDelivery.period.dayZero';
      }
      deliveryPeriod$ = this.translate.translate(text, param);
    } else {
      let today, deliveryStartDateName, deliveryEndDateName, tomorrow, deliveryStartDateText, deliveryEndDateText;
      let deleteWord$ = this.translate.translate('amFormat.deleteWordWeek');
      let deliveryDatePtn$ = this.dayjsService.changeLanguage$.pipe(
        mergeMap((d) => this.translate.translate('amFormat.time.pattern.week'))
      );
      deliveryStartDate = productDeliveryTime.deliveryStartDate
        ? dayjs(productDeliveryTime.deliveryStartDate).startOf('day').valueOf()
        : productDeliveryTime.deliveryStartDate;
      deliveryEndDate = productDeliveryTime.deliveryEndDate
        ? dayjs(productDeliveryTime.deliveryEndDate).startOf('day').valueOf()
        : productDeliveryTime.deliveryEndDate;
      let deliveryStartDateText$;
      let deliveryEndDateText$;
      // not inflight env conversion time
      if (!this.isInflightEnv) {
        // today = dayjs().startOf('day').valueOf();
        // tomorrow = dayjs().add(1, 'day').startOf('day').valueOf();
        // if (today === deliveryStartDate) {
        //   deliveryStartDateName = 'amDelivery.period.dateToday';
        // }
        // if (today === deliveryEndDate) {
        //   deliveryEndDateName = 'amDelivery.period.dateToday';
        // }
        // if (tomorrow === deliveryStartDate) {
        //   deliveryStartDateName = 'amDelivery.period.dateTomorrow';
        // }
        // if (tomorrow === deliveryEndDate) {
        //   deliveryEndDateName = 'amDelivery.period.dateTomorrow';
        // }
        if (deliveryStartDateName) {
          deliveryStartDateText$ = this.translate.translate(deliveryStartDateName);
        }
        if (deliveryEndDateName) {
          deliveryEndDateText$ = this.translate.translate(deliveryEndDateName);
        }
      }
      let arr = [];
      arr.push(deliveryDatePtn$);
      arr.push(deleteWord$);
      deliveryEndDateText$ && arr.push(deliveryEndDateText$);
      if (this.pageFrom !== 'pdp') {
        if (deliveryStartDate) {
          text = 'amDelivery.period.dateStartEnd';
          deliveryStartDateText$ && arr.push(deliveryStartDateText$);
        } else {
          text = 'amDelivery.period.dateEnd';
        }
      } else {
        if (deliveryStartDate) {
          text = 'amDelivery.period.dateStartEnd_pdp';
          deliveryStartDateText$ && arr.push(deliveryStartDateText$);
        } else {
          text = 'amDelivery.period.dateEnd_pdp';
        }
      }

      deliveryPeriod$ = combineLatest(arr).pipe(
        distinctUntilChanged(),
        mergeMap((v) => {
          let param = {} as any;
          if (deliveryEndDateName) {
            deliveryEndDateText = v[2];
          }
          if (deliveryStartDateName) {
            deliveryStartDateText = v[v.length - 1];
          }
          if (deliveryStartDate) {
            param.param0 = this.coverDate(deliveryStartDate, deliveryStartDateText, v[0], v[1]);
            param.param1 = this.coverDate(deliveryEndDate, deliveryEndDateText, v[0], v[1]);
          } else {
            param.param0 = this.coverDate(deliveryEndDate, deliveryEndDateText, v[0], v[1]);
          }
          return this.translate.translate(text, param);
        })
      );
    }
    //  handle not pdp page
    if (this.pageFrom !== 'pdp') {
      const partnerName = this.getPartnerName(product);
      let fulfillment$ = this.translate.translate('amDelivery.fulfillment.' + product.collectionMethod, {
        param0: product.fulfillmentName,
        param1: partnerName
      });
      return combineLatest([deliveryPeriod$, fulfillment$]).pipe(
        switchMap((val) => {
          return this.translate.translate(
            showLeadTime
              ? 'amDelivery.period.fulfillmentType.leadTimeProvider'
              : 'amDelivery.period.fulfillmentType.provider',
            {
              param0: val[0],
              param1: val[1]
            }
          );
        })
      );
    } else {
      return deliveryPeriod$.pipe(
        switchMap((val) => {
          let param = {
            param0: val
          };
          return this.translate.translate(
            showLeadTime ? 'amDelivery.fulfillmentType.leadTimePeriod' : 'amDelivery.fulfillmentType.period',
            param
          );
        })
      );
    }
  }

  coverDate(date, dateName, deliveryDatePtn, deleteWord): string {
    if (deleteWord) {
      return dateName ? dateName : date ? dayjs(date).format(deliveryDatePtn).replace(deleteWord, '') : date;
    }
    return dateName ? dateName : date ? dayjs(date).format(deliveryDatePtn) : date;
  }

  getPartnerName(product: Product) {
    return product.deliveryPartnerInfo?.partnerFEText
      ? product.deliveryPartnerInfo?.partnerFEText
      : product.deliveryPartnerInfo?.partnerCompanyName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product?.currentValue !== changes.product?.previousValue) {
      this.delivery$ = this.handleDeliveryPeriod(this.product).pipe(distinctUntilChanged());
    }
  }

  // clickEventEmit(): void {
  //   this.clickEmit.emit(true);
  // }
}
