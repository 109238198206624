import { Component, ElementRef, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { tap } from 'rxjs/operators';
import { AmActiveCartService } from 'src/app/amiredeem/pages/cart/service/active-cart.service';
import { AmCartService } from 'src/app/amiredeem/pages/cart/service/am-cart.service';
import { AmAddToCartService } from './add-to-cart.service';

@Component({
  selector: 'am-add-to-cart-dialog',
  templateUrl: './add-to-cart-dialog.component.html'
})
export class AmAddToCartDialogComponent {
  @ViewChild('dialog') dialog: ElementRef;
  @ViewChild('arrow', { static: false }) arrow: ElementRef;
  addToCartResult$ = this.amCartService.getAddToCartResult().pipe(
    tap((d) => {
      const that = this;
      this.winRef.isBrowser() &&
        setTimeout(() => {
          that.initDialog();
        });
    })
  );
  autoClose: NodeJS.Timeout;
  @HostListener('window:resize', ['event'])
  onResize(event): void {
    if (this.dialog) {
      this.initDialog();
    }
  }
  constructor(
    protected viewContainer: ViewContainerRef,
    protected amCartService: AmCartService,
    private amAddToCartService: AmAddToCartService,
    private activeCartService: AmActiveCartService,
    private winRef: WindowRef
  ) {}

  initDialog(): void {
    if (this.dialog) {
      const siteLogin = this.winRef.document?.querySelector('am-site-login');
      const miniCart = this.winRef.document?.querySelector('am-mini-cart');
      const hamburgerMenu = this.winRef.document?.querySelector('cx-hamburger-menu');
      const header = this.winRef.document?.querySelector('header cx-page-layout.header');
      const paddingRight = this.winRef.nativeWindow?.getComputedStyle(header).paddingRight;
      this.dialog.nativeElement.style.top = header.clientHeight + 3 + 'px';
      this.dialog.nativeElement.style.right = paddingRight;
      if (this.arrow?.nativeElement) {
        this.arrow.nativeElement.style.right =
          (siteLogin?.clientWidth || 0) +
          (hamburgerMenu?.clientWidth || 0) +
          (miniCart?.clientWidth || 0) / 2 -
          7 +
          'px';
      }
      this.clearAutoClose();
      this.addAutoClose();
    }
  }
  close(): void {
    this.amAddToCartService.close();
  }
  goToCart(): void {
    this.close();
    // this.activeCartService.reloadActiveCart();
  }

  clearAutoClose(): void {
    if (this.autoClose) {
      clearTimeout(this.autoClose);
    }
  }
  addAutoClose(): void {
    this.autoClose =
      this.winRef.isBrowser() &&
      setTimeout(() => {
        this.close();
      }, 3000);
  }
}
