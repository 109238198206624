import { Component, Input, OnInit } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';

@Component({
  selector: 'am-product-price',
  templateUrl: './product-price.component.html'
})
export class ProductPriceComponent implements OnInit {
  @Input() set product(v) {
    if (v) {
      this._product = v;
      this.decimal = this.product.currency == 'HKD' ? 0 : 2;
    }
  }
  get product() {
    return this._product;
  }
  private _product;

  decimal: number;
  svgType = svgConfig;
  style_asia_mile_light_grey = ' width: 15px; height: 15px; margin-bottom: 4px';
  style_asia_mile_grey = 'width: 16px; height: 16px; margin: 0 0 3px 0 ';

  constructor() {}

  ngOnInit(): void {}
}
