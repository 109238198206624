/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { OccCommonConnector } from '../occ/common.connector';
import { AmCommonStoreModule } from './common-store.module';

@NgModule({
  imports: [AmCommonStoreModule],
  providers: [OccCommonConnector]
})
export class AmCommonModule {
  static forRoot(): ModuleWithProviders<AmCommonModule> {
    return {
      ngModule: AmCommonModule,
      providers: []
    };
  }
}
