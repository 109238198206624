<ng-container *ngIf="!product.externalRedirect && product.specialProductType === 'ALCOHOLIC'">
  <div class="product-wine" [formGroup]="alcoholicForm">
    <div *ngIf="pageFrom === 'mylistPage'" class="disclaimer">
      {{ 'amProductDetails.wineAdult.disclaimer' | cxTranslate }}
    </div>
    <div class="checkbox-group">
      <input class="agree-checkbox" type="checkbox" id="wineforpdp" formControlName="wineCheckbox" />
      <label class="agree-form" for="wineforpdp">
        {{ 'amProductDetails.wineAdult.claimMessage' | cxTranslate }}
      </label>
    </div>
    <am-message-tip
      *ngIf="!alcoholicForm.get('wineCheckbox')?.value"
      [iconType]="svgType.ICONWARNINGYELLOW"
      iconStyle="margin: 0 5px 0 0"
      messageType="error"
      [hasBackgroudColor]="false">
      <span class="font-bold">
        {{ 'amProductDetails.wineAdult.checkBox' | cxTranslate }}
      </span>
    </am-message-tip>
  </div>
</ng-container>
