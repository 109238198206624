import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CmsService,
  EventService,
  LanguageService,
  OccEndpointsService,
  ProductSearchService,
  RoutingService,
  UserIdService,
  WindowRef
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, combineLatest, of, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { PendingOrderConfirmationService } from 'src/app/amiredeem/pages/checkout/components/am-pending-order-confirmation/am-pending-order-confirmation.service';
import { AmCheckoutVerifyOtpService } from 'src/app/amiredeem/pages/checkout/components/am-verify-otp/am-verify-otp.service';
import { AmCheckoutDeliveryService } from 'src/app/amiredeem/pages/checkout/facade/am-checkout-delivery.service';
import { OrderDetailsService } from 'src/app/amiredeem/pages/order-details/service/order-details.service';
import { OrderListService } from 'src/app/amiredeem/pages/order-list/service/order-list.service';
import { AmGetConfigService } from '../config/am-getconfig.service';
import { UpdateDataLayerService } from './adobe/updateDatalayer.service';
import { UpdateCxDataLayerService } from './tealium/updateCxDatalayer.service';
import { TealiumUtagService } from './tealium/utag.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerSourceService {
  country$ = this.countryService.getActive();
  language$ = this.languageService.getActive();
  hashId$ = this.amGetConfigService.getHashId();
  isInflight$ = this.amConfigService.getIsInflightEnv();
  userService$ = this.authService.isUserLoggedIn().pipe(
    switchMap((login) => {
      return login ? this.userAccountFacade.get().pipe(filter((data) => !!data)) : of(null);
    })
  );

  pdpModel$ = () => this.currentProductService.getProduct().pipe(distinctUntilChanged());
  checkoutInfo$ = () => this.checkoutDeliveryService.getAndLoadDeliveryAndAddressInfo();
  checkoutCartModel$ = () =>
    this.checkoutDeliveryService.getAndLoadDeliveryAndAddressInfo().pipe(
      filter((checkoutInfo) => !!checkoutInfo),
      map((checkoutInfo) => checkoutInfo.cart)
    );
  cartModel$ = () => this.activeCartService.getActive();
  searchModel$ = () =>
    this.productSearchService.getResults().pipe(
      filter((data) => !!data?.pagination),
      debounce(() => timer(1500))
    );
  orderConfirmData$ = () =>
    this.pendingOrderConfirmationService.fetchOrderInfo('orderConfirmation').pipe(filter((data) => !!data));
  orderDetail$ = () => this.orderDetailsService.orderDetail$.pipe(filter((data) => !!data));
  orderList$ = () => this.orderListService.orderListData$;
  generatorError$ = () => this.getGeneratorErrors();
  balanceError$ = () => this.getBalanceErrorInfo();
  cmsPage$ = () => this.cms.getCurrentPage();
  inflightPendingDetail$ = () => this.pendingOrderConfirmationService.fetchOrderInfo('pendingOrderDetail');
  inflightSummaryPage$ = () => this.pendingOrderConfirmationService.fetchOrderInfo('pendingOrderConfirmation');

  commonSources$ = [this.country$, this.language$, this.userService$, this.isInflight$, this.hashId$];
  public commonSourceKeys = ['country', 'language', 'userService', 'isInflight', 'hashId'];
  prevCategoryCode: any;

  constructor(
    protected tealiumUtagService: TealiumUtagService,
    protected httpClient: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected winRef: WindowRef,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected currentProductService: CurrentProductService,
    protected activeCartService: ActiveCartFacade,
    protected checkoutDeliveryService: AmCheckoutDeliveryService,
    protected checkoutVerifyOtpService: AmCheckoutVerifyOtpService,
    protected orderDetailsService: OrderDetailsService,
    protected orderListService: OrderListService,
    protected productSearchService: ProductSearchService,
    protected pendingOrderConfirmationService: PendingOrderConfirmationService,
    protected countryService: CountryService,
    protected languageService: LanguageService,
    protected authService: AmAuthService,
    protected userIdService: UserIdService,
    protected amConfigService: AmConfigService,
    protected cms: CmsService,
    protected updateCxDataLayerSvc: UpdateCxDataLayerService,
    protected updateAdobeDataLayerSvc: UpdateDataLayerService,
    protected amGetConfigService: AmGetConfigService,
    protected userAccountFacade: UserAccountFacade
  ) {}

  getDataSourceConfig(): any {
    const dataSourceConfig = {
      home: {
        source$: this.commonSources$
      },
      search: {
        //checked
        source$: [...this.commonSources$, this.searchModel$],
        callback: (transformObject): any => {
          this.updateCxDataLayerSvc.updateSeachDataLayer(transformObject);
          this.updateAdobeDataLayerSvc.updateSearchDataLayer(transformObject);
        },
        dataKey: 'search'
      },
      cart: {
        //checked
        source$: [...this.commonSources$, this.cartModel$],
        callback: (transformObject): any => {
          this.updateCxDataLayerSvc.updateCartDataLayer(transformObject);
          this.updateAdobeDataLayerSvc.updateCartDataLayer(transformObject);
        },
        dataKey: 'activeCart'
      },
      product: {
        //checked
        source$: [...this.commonSources$, this.pdpModel$],
        callback: (transformObject): any => {
          this.updateCxDataLayerSvc.updatePDPDataLayer(transformObject);
          this.updateAdobeDataLayerSvc.updatePDPDataLayer(transformObject);
        },
        dataKey: 'pdpData'
      },
      checkoutShippingAddress: {
        //checked
        source$: [...this.commonSources$, this.checkoutCartModel$],
        callback: (transformObject): any => {
          this.updateCxDataLayerSvc.updateCheckoutDataLayer(transformObject);
          this.updateAdobeDataLayerSvc.updateCheckoutDataLayer(transformObject);
        },
        dataKey: 'activeCart'
      },
      checkoutVerifyOtp: {
        source$: [...this.commonSources$, this.checkoutInfo$],
        callback: this.updateCxDataLayerSvc.updateMemberVerificationDataLayer,
        dataKey: 'checkoutInfo'
      },
      orderConfirmation: {
        //checked
        source$: [...this.commonSources$, this.orderConfirmData$],
        callback: (transformObject): any => {
          this.updateCxDataLayerSvc.updateOrderConfrimationDataLayer(transformObject);
          this.updateAdobeDataLayerSvc.updateOrderConfrimationDataLayer(transformObject);
        },
        dataKey: 'order'
      },
      orders: {
        //checked
        source$: [...this.commonSources$, this.orderList$],
        callback: (transformObject): any => {
          this.updateCxDataLayerSvc.updateOrderHistoryDataLayer(transformObject);
          this.updateAdobeDataLayerSvc.updateOrderHistoryDataLayer(transformObject);
        },
        dataKey: 'orderList'
      },
      // order detail
      order: {
        source$: [...this.commonSources$, this.orderDetail$],
        callback: this.updateCxDataLayerSvc.updateOrderDetailDataLayer,
        dataKey: 'order'
      },
      generalError: {
        //checked
        source$: [...this.commonSources$, this.generatorError$],
        callback: (transformObject, type): any => {
          this.updateCxDataLayerSvc.updatePageErrorDataLayer(transformObject, type);
          this.updateAdobeDataLayerSvc.updatePageErrorDataLayer(transformObject, type);
        },
        dataKey: 'error'
      },
      balanceError: {
        //checked
        source$: [...this.commonSources$, this.balanceError$],
        callback: (transformObject, type): any => {
          this.updateCxDataLayerSvc.updatePageErrorDataLayer(transformObject, type);
          this.updateAdobeDataLayerSvc.updatePageErrorDataLayer(transformObject, type);
        },
        dataKey: 'error'
      },
      notFound: {
        source$: this.commonSources$,
        callback: this.updateCxDataLayerSvc.updatePageErrorDataLayer,
        dataKey: 'error'
      },
      campaign: {
        source$: [...this.commonSources$, this.cmsPage$],
        callback: this.updateCxDataLayerSvc.updateCampaignPage,
        dataKey: 'campaign'
      },
      inflightPendingOrderSummary: {
        source$: [...this.commonSources$, this.inflightSummaryPage$],
        callback: this.updateCxDataLayerSvc.updatePendingOrderConfrimationDataLayer,
        dataKey: 'order'
      },
      inflightOrderComplete: {
        source$: [...this.commonSources$, this.inflightSummaryPage$],
        callback: this.updateCxDataLayerSvc.updatePendingOrderConfrimationDataLayer,
        dataKey: 'order'
      },
      accountInflightOrderDetailItems: {
        // pending order detail
        source$: [...this.commonSources$, this.inflightPendingDetail$],
        callback: this.updateCxDataLayerSvc.updateOrderConfrimationDataLayer,
        dataKey: 'order'
      },
      inflightOrderVerify: {
        source$: [...this.commonSources$, this.inflightPendingDetail$],
        callback: this.updateCxDataLayerSvc.updateOrderConfrimationDataLayer,
        dataKey: 'order'
      }
    };
    return dataSourceConfig;
  }

  getCategorySource(contextId): any {
    const plpData$ = this.productSearchService.getResults().pipe(
      filter((data) => {
        return data?.queryResult?.categoryCode === contextId;
      })
    );

    return {
      source$: [...this.commonSources$, plpData$],
      callback: (transformObject): void => {
        this.updateCxDataLayerSvc.updatePLPDataLayer(transformObject);
        this.updateAdobeDataLayerSvc.updatePLPDataLayer(transformObject);
      },
      dataKey: 'plp'
    };
  }

  getBalanceErrorInfo(): any {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      filter(([userid, cartid]) => {
        return !!userid && !!cartid;
      }),
      switchMap(([userId, cartId]) => {
        const url = this.occEndpointSvc.buildUrl('balanceError', {
          urlParams: { userId, cartId }
        });
        // const url = this.occEndpointSvc.getBaseUrl() + `/users/${userId}/carts/${cartId}/checkout/balanceError`;
        return this.httpClient.get<any>(url);
      })
    );
  }

  getGeneratorErrors(): Observable<Object> {
    const generalErrorUrl = this.occEndpointSvc.buildUrl('generalError');
    // const generalErrorUrl = this.occEndpointSvc.getBaseUrl() + `/checkout/generalError`;
    return this.httpClient.get(generalErrorUrl);
  }
}
