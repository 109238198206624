import { PriceChangeDialogComponent } from '@components/content/price-change-dialog/price-change-dialog.component';
import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { FavoriteNotifyBottomSheetComponent } from '../favorite-notify-products/components/favorite-notify-bottom-sheet/favorite-notify-bottom-sheet.component';
import { OfferDetailsPopupComponent } from '../product-detail/component/offer-details/offer-details-popup/offer-details-popup.component';
import { CoBrandCouponDialogComponent } from './component/cart-coupon/co-brand-coupon-dialog/co-brand-coupon-dialog.component';
import { DeliveryFeeDialogComponent } from './component/cart-deliver-fee/delivery-fee-dialog/delivery-fee-dialog.component';
import { FlatRateDeliveryFeeDialogComponent } from './component/cart-deliver-fee/flat-rate-delivery-fee-dialog/flat-rate-delivery-fee-dialog.component';
import { CartLoadingDialogComponent } from './component/cart-loading-dialog/cart-loading-dialog.component';
import { CustomTaxPopupComponent } from './component/cart-messages/custom-tax-popup/custom-tax-popup.component';
import { CartOfferDetailsDialogComponent } from './component/cart-offer-details-dialog/cart-offer-details-dialog.component';
import { MilesEarnDialogComponent } from './component/cart-totals/miles-earn-dialog/miles-earn-dialog.component';
import { MobileVerificationDialogComponent } from './component/mobile-verification-dialog/mobile-verification-dialog.component';
import { OfferAppliedDialogComponent } from './component/offer-applied-dialog/offer-applied-dialog.component';
import { PromotionCashDialogComponent } from './component/promotion-cash-dialog/promotion-cash-dialog.component';
import { removeAllErrorDialogComponent } from './component/remove-all-error-dialog/remove-all-error-dialog.component';

export const amCartDialogLayoutConfig: LayoutConfig = {
  launch: {
    MOBILE_VERIFICATION: {
      inlineRoot: true,
      component: MobileVerificationDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_COBRAND_COUPON: {
      inlineRoot: true,
      component: CoBrandCouponDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_DELIVE_FREE: {
      inlineRoot: true,
      component: DeliveryFeeDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_CART_OFFER_DETAIL: {
      inlineRoot: true,
      component: CartOfferDetailsDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_APPLIED_OFFER_DETAIL: {
      inlineRoot: true,
      component: OfferAppliedDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_OFFER_DETAILS: {
      inlineRoot: true,
      component: OfferDetailsPopupComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    REMOVE_CART_ALL_ERROR_INFO: {
      inlineRoot: true,
      component: removeAllErrorDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_CASH_PROMOTION: {
      inlineRoot: true,
      component: PromotionCashDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    DISPLAY_EARN_MILES: {
      inlineRoot: true,
      component: MilesEarnDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    CUSTOM_TAX: {
      inlineRoot: true,
      component: CustomTaxPopupComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    FLAT_RATE_DELIVERY_FEE: {
      inlineRoot: true,
      component: FlatRateDeliveryFeeDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    PRICE_CHANGE_REMINDER: {
      inlineRoot: true,
      component: PriceChangeDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    CART_LOADING_DIALOG: {
      inlineRoot: true,
      component: CartLoadingDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG
    },
    FAVORITE_NOTIFY_BOTTOM_SHEET: {
      inlineRoot: true,
      component: FavoriteNotifyBottomSheetComponent,
      dialogType: DIALOG_TYPE.DIALOG
    }
  }
};
