import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { ProductPriceModule } from 'src/app/amiredeem/components/content/common/product-price/product-price.module';
import { OfferAppliedDialogComponent } from './offer-applied-dialog.component';

@NgModule({
  declarations: [OfferAppliedDialogComponent],
  exports: [OfferAppliedDialogComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    ProductPriceModule,
    SafeCodeModule,
    DateTimeModule,
    KeyboardFocusModule
  ]
})
export class AmOfferAppliedDialogModule {}
