import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AmAsmAuthHttpHeaderService } from '@core/asm/root/services/am-asm-auth-http-header.service';
import { AmAsmAuthService } from '@core/asm/root/services/am-asm-auth.service';
import { AsmAuthStorageService } from '@spartacus/asm/root';
import {
  AuthConfigService,
  AuthHttpHeaderService,
  AuthInterceptor,
  AuthRedirectService,
  AuthService,
  AuthStatePersistenceService,
  AuthStorageService,
  OAuthLibWrapperService,
  provideDefaultConfig,
  TokenRevocationInterceptor
} from '@spartacus/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './user-auth/config/am-auth-config';
import { AmAuthInterceptor } from './user-auth/http-interceptors/am-auth.interceptor';
import { AmTokenRefreshmentInterceptor } from './user-auth/http-interceptors/am-token-refreshment.interceptor';
import { AmTokenRevocationInterceptor } from './user-auth/http-interceptors/am-token-revocation.interceptor';
import { AmAuthConfigService } from './user-auth/services/am-auth-config.service';
import { AmAuthRedirectService } from './user-auth/services/am-auth-redirect.service';
import { AmAuthStatePersistenceService } from './user-auth/services/am-auth-state-persistence.service';
import { AmOAuthLibWrapperService } from './user-auth/services/am-oauth-wrapper.service';
import { AmOAuthService } from './user-auth/services/am-oauth.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(authConfig),
    {
      provide: AuthService,
      useExisting: AmAsmAuthService
    },
    {
      provide: OAuthService,
      useExisting: AmOAuthService
    },
    {
      provide: AuthStorageService,
      useExisting: AsmAuthStorageService
    },
    {
      provide: AuthHttpHeaderService,
      useExisting: AmAsmAuthHttpHeaderService
    },
    {
      provide: AuthInterceptor,
      useExisting: AmAuthInterceptor
    },
    {
      provide: TokenRevocationInterceptor,
      useExisting: AmTokenRevocationInterceptor
    },
    {
      provide: AuthConfigService,
      useExisting: AmAuthConfigService
    },
    {
      provide: OAuthLibWrapperService,
      useExisting: AmOAuthLibWrapperService
    },
    {
      provide: AuthStatePersistenceService,
      useExisting: AmAuthStatePersistenceService
    },
    {
      provide: AuthRedirectService,
      useExisting: AmAuthRedirectService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: AmTokenRefreshmentInterceptor,
      multi: true
    }
  ]
})
export class AmAuthModule {}
