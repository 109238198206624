import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-mobile-verification-dialog',
  templateUrl: './mobile-verification-dialog.component.html',
  styleUrls: ['./mobile-verification-dialog.component.scss']
})
export class MobileVerificationDialogComponent implements OnInit, OnDestroy {
  params = {} as any;
  msg = { verifyMobileUrl: '' };
  subscription: Subscription = new Subscription();

  constructor(protected launchDialogService: AmLaunchDialogService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        const { msg } = data;
        this.msg = msg;
        let areaCode;
        let phoneNumber;
        let maskPhoneNo;
        let maskSign = '****';
        if (msg.mobilePhoneNumber) {
          let arr = msg.mobilePhoneNumber.split('--');
          areaCode = arr[0];
          phoneNumber = arr[1] ?? '';
          maskPhoneNo = phoneNumber.substring(0, phoneNumber.length - 4);
        }
        this.params = {
          p1: areaCode,
          p2: maskPhoneNo,
          p3: maskSign
        };
      })
    );
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
