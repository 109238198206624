import { Component, OnInit } from '@angular/core';
import { CookieBarService } from '@components/layout/footer/cookie-bar/cookie-bar.service';
import { LiveChatService } from '@components/layout/footer/livechat/livechat.service';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { Country, CurrencyService, Language } from '@spartacus/core';
import { SiteContextComponentService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { CountryService } from '../../services/country/country.service';
import { AmLanguageService } from '../../services/language/language.service';
import { SiteContextSelectorStorageService } from './site-context-selector-popup-stroage.service';

export enum SiteContextType {
  LANGUAGE = 'language'
}

@Component({
  selector: 'am-country-language-selection-popup',
  templateUrl: './site-context-selector-popup.component.html'
})
export class SiteContextSelectorPopupComponent implements OnInit {
  languages: Language[];
  countries: Country[];
  selectedCountry: string;
  selectedLanguage: string;
  languageContext = SiteContextType.LANGUAGE;
  subscription: Subscription = new Subscription();

  constructor(
    protected countryService: CountryService,
    protected liveChatService: LiveChatService,
    protected cookieBarService: CookieBarService,
    protected siteContextService: SiteContextComponentService,
    protected languageService: AmLanguageService,
    protected siteContextSelectorStorageService: SiteContextSelectorStorageService,
    protected launchDialogService: AmLaunchDialogService,
    protected currencyService: CurrencyService
  ) {
    this.initData();
  }

  initData() {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        this.languages = data.languages;
        this.countries = data.countries;
      })
    );
    this.subscription.add(
      this.languageService.getActive().subscribe((lan) => {
        this.selectedLanguage = lan;
      })
    );
    this.subscription.add(
      this.countryService.getActiveCountryEntity().subscribe((country) => {
        this.selectedCountry = country.isocode;
      })
    );
  }

  ngOnInit(): void {}

  confirm() {
    const activeCurrency = this.countryService.getCurrencyFromActiveCountryEntity(this.selectedCountry);
    this.countryService.setActive(this.selectedCountry);
    this.languageService.setActive(this.selectedLanguage);
    this.currencyService.setActive(activeCurrency);
    this.liveChatService.updateLiveChatLanguage(this.selectedLanguage);
    this.cookieBarService.updateCookieBarFunction(this.selectedLanguage);
    // store cookie
    this.siteContextSelectorStorageService.storeFirstVisitCookie(this.selectedLanguage, this.selectedCountry);
    this.launchDialogService.closeDialog('');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
