import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { SvgContainerModule } from '../svg-container/svg-container.module';
import { ContentHighlightWithImageComponent } from './content-highlight-with-image.component';

@NgModule({
  declarations: [ContentHighlightWithImageComponent],
  imports: [
    CommonModule,
    SvgContainerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ContentHighlightBannerContainerComponent: {
          // typeCode
          component: ContentHighlightWithImageComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [ContentHighlightWithImageComponent]
})
export class ContentHighlightWithImageModule {}
