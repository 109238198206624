import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { AmCartItemListComponent } from './cart-item-list.component';
import { AmCartItemModule } from './cart-item/cart-item.module';

@NgModule({
  declarations: [AmCartItemListComponent],
  exports: [AmCartItemListComponent],
  imports: [CommonModule, I18nModule, ReactiveFormsModule, AmCartItemModule]
})
export class AmCartItemListModule {}
