import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { AmCheckoutState, StateWithAmCheckout } from '..';
import * as deliveryAndAddressInfo from './deliveryAndAddressInfo.reducer';

export function getReducers(): ActionReducerMap<AmCheckoutState> {
  return {
    shippingAddress: deliveryAndAddressInfo.reducer
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<StateWithAmCheckout>> = new InjectionToken<
  ActionReducerMap<AmCheckoutState>
>('AmCheckoutReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers
};
