import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { AmMediaModule } from '../common/media/media.module';
import { SvgContainerModule } from '../svg-container/svg-container.module';
import { LrpHeaderBannerComponent } from './lrp-header-banner.component';

@NgModule({
  declarations: [LrpHeaderBannerComponent],
  imports: [
    CommonModule,
    SvgContainerModule,
    AmMediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCtaContentBannerComponent: {
          // typeCode
          component: LrpHeaderBannerComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [LrpHeaderBannerComponent]
})
export class LrpHeaderBannerModule {}
