import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule } from '@spartacus/core';
import { ProductPriceComponent } from './product-price.component';

@NgModule({
  declarations: [ProductPriceComponent],
  imports: [CommonModule, I18nModule, SvgContainerModule],
  exports: [ProductPriceComponent]
})
export class ProductPriceModule {}
