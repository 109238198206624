import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart, CART_NORMALIZER } from '@spartacus/cart/base/root';
import { ConverterService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartMileAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected userIdService: UserIdService,
    protected converterService: ConverterService
  ) {}

  public update(userId: string, cartId: string, mile: number): Observable<Cart> {
    const url = this.occEndpointSvc.buildUrl('milesUpdate', {
      urlParams: {
        userId,
        cartId,
        mile
      }
    });
    return this.http.post<string>(url, {}).pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  public toggleDeliveryFeeMiles(userId: string, cartId: string, isOpen: boolean): Observable<boolean> {
    const url = this.occEndpointSvc.buildUrl('toggleDeliveryFeeMiles', {
      urlParams: {
        userId,
        cartId,
        isOpen
      }
    });
    return this.http.post<boolean>(url, {});
  }
  public toggleFCOM(userId: string, cartId: string, isOpen: boolean): Observable<any> {
    const url = this.occEndpointSvc.buildUrl('toggleFcom', {
      urlParams: {
        userId,
        cartId,
        isOpen
      }
    });
    return this.http.post<boolean>(url, {});
  }

  public toggleDeliveryMethod(userId: string, cartId: string, type: string): Observable<any> {
    const url = this.occEndpointSvc.buildUrl('switchInflightOrGround', {
      urlParams: {
        userId,
        cartId,
        type
      }
    });
    return this.http.post<boolean>(url, {});
  }
}
