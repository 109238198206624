import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductNormalizer } from '@core/product/converters/product-normalizer';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { OccEndpointsService, RoutingService, UserIdService, WindowRef } from '@spartacus/core';
import UrlHelper from '@utils/url-helper';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { OrderDetailsService } from '../../../order-details/service/order-details.service';

export interface OrderDetailInfo {
  isMobileValidated?: any;
  mobile?: any;
  displayOrderExpiryDate?: any;
  orderData: any;
  orderDetailData?: any;
  orderDetail?: any;
  isInflightEnv: boolean;
  isInflightPage?: boolean;
  isOnlyFreeGift?: boolean;
  responseInfo?: {
    globalmessage?: {
      errors: any;
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class PendingOrderConfirmationService {
  selectedId: number;

  urlMap = {
    pendingOrderDetail: 'pendingOrderDetail', // orders
    pendingOrderConfirmation: 'pendingOrderConfirmation',
    orderConfirmation: 'orderConfirmation'
  };
  orderData: any;
  orderData$: any;
  preKey: string;

  constructor(
    protected userIdService: UserIdService,
    protected activeCartService: ActiveCartFacade,
    protected occEndpointSvc: OccEndpointsService,
    protected http: HttpClient,
    protected routingSerivce: RoutingService,
    protected winRef: WindowRef,
    protected productNormalizerService: ProductNormalizer,
    protected amConfigService: AmConfigService,
    private orderDetailsService: OrderDetailsService
  ) {}

  fetchOrderInfo(pageType: string): Observable<OrderDetailInfo> {
    const orderCode = UrlHelper.getOrderCodeFromUrl(this.winRef.location.href);
    const key = pageType + '_' + orderCode;
    if (key === this.preKey && this.orderData$) {
      return this.orderData$;
    }
    this.preKey = pageType + '_' + orderCode;
    this.orderData$ = this.userIdService
      .getUserId()
      .pipe(
        switchMap((userId) => {
          const url = this.occEndpointSvc.buildUrl(this.urlMap[pageType], { urlParams: { userId, orderCode } });
          return combineLatest([this.http.get<OrderDetailInfo>(url), this.amConfigService.getIsInflightEnv()]).pipe(
            map(([data, isInflightObj]) => {
              let ret = null;
              if (pageType !== 'orderConfirmation') {
                const { orderDetailData } = data;
                ret = {
                  ...data,
                  orderDetailData,
                  isInflightEnv: isInflightObj.data,
                  isInflightPage: true,
                  isOnlyFreeGift: this.orderDetailsService.getFreeProductsStatus(data.orderData)
                };
              } else {
                const { orderData } = data;
                ret = {
                  ...data,
                  orderDetailData: {
                    totalQty: orderData.qty,
                    totalDelivery: orderData.entries
                  },
                  isInflightEnv: isInflightObj.data,
                  isInflightPage: false,
                  isOnlyFreeGift: this.orderDetailsService.getFreeProductsStatus(orderData)
                };
              }
              this.orderData = ret;

              return ret;
            })
          );
        })
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    return this.orderData$;
  }
}
