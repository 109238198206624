/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ElementRef, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { LaunchDialogService, LaunchRenderStrategy, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AmLaunchDialogService extends LaunchDialogService {
  constructor(
    @Inject(LaunchRenderStrategy)
    protected renderStrategies: LaunchRenderStrategy[],
    protected layoutConfig: LayoutConfig // protected renderer: Renderer2
  ) {
    super(renderStrategies, layoutConfig);
  }

  openDialog(
    caller: LAUNCH_CALLER | string,
    openElement?: ElementRef,
    vcr?: ViewContainerRef,
    data?: any
  ): Observable<any> | undefined {
    const component = this.launch(caller, vcr, data);
    const config = this.findConfiguration(caller);
    // @ts-ignore
    if (component) {
      return combineLatest([component, this.dialogClose]).pipe(
        filter(([, clossParams]) => {
          return (
            clossParams?.reason !== undefined &&
            (!clossParams.caller ||
              // @ts-ignore
              (clossParams.caller && caller === clossParams.caller))
          );
        }),
        tap(([comp]) => {
          openElement?.nativeElement.focus();
          this.clear(caller);
          comp?.destroy();
        }),
        map(([comp]) => comp)
      );
    }
  }

  closeDialog(reason: any, caller?: string) {
    const params = Object.assign({ reason }, caller && { caller });
    super.closeDialog(params);
  }
}
