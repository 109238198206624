/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideDefaultConfigFactory, StateConfig, StateModule, StateTransferType } from '@spartacus/core';
import { AmConfigService } from '../am-config.service';
import { COMMON_FEATURE } from './common-state';
import { effects } from './effects';
import { reducerProvider, reducerToken } from './reducers';
export function commonConfigStateFactory(configService): () => void {
  const result = () => configService.init();
  return result;
}
export function CommonStoreConfigFactory(): StateConfig {
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: { [COMMON_FEATURE]: StateTransferType.TRANSFER_STATE }
      }
    }
  };
  return config;
}

@NgModule({
  imports: [
    CommonModule,
    StateModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature(COMMON_FEATURE, reducerToken)
  ],
  providers: [
    provideDefaultConfigFactory(CommonStoreConfigFactory),
    reducerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: commonConfigStateFactory,
      deps: [AmConfigService],
      multi: true
    }
  ]
})
export class AmCommonStoreModule {}
