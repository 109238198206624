import { createSelector, MemoizedSelector } from '@ngrx/store';
import { SearchState, SiteContextState, StateWithSiteContext } from '../state';
import { getSiteContextState } from './site-context.selector';

const popularTagsSelector = (state: SearchState) => state.popularTags;
const cathayFeatureSelector = (state: SearchState) => state.cathayFeature;
const popularBrandSelector = (state: SearchState) => state.popularBrands;


export const getSearchState: MemoizedSelector<StateWithSiteContext, SearchState> = createSelector(
  getSiteContextState,
  (state: SiteContextState) => state.search
);

export const getPopularTags: MemoizedSelector<StateWithSiteContext, string> = createSelector(
  getSearchState,
  popularTagsSelector
);

export const getCathayFeatureTags: MemoizedSelector<StateWithSiteContext, any[]> = createSelector(
  getSearchState,
  cathayFeatureSelector
);

export const getPopularBrandSelector: MemoizedSelector<StateWithSiteContext, any[]> = createSelector(
  getSearchState,
  popularBrandSelector
);