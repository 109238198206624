import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmAsmModule } from '@core/asm/am-asm.module';
import { ASM_FEATURE } from '@spartacus/asm/root';
import { CART_BASE_FEATURE } from '@spartacus/cart/base/root';
import { CHECKOUT_FEATURE } from '@spartacus/checkout/base/root';
import { ConfigModule } from '@spartacus/core';
import { InflightDiagnosticModule } from './components/content/inflight-diagnostic/inflight-diagnostic.module';
import { AmCartDetailsModule } from './pages/cart/am-cart-details.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      featureModules: {
        home: {
          module: () => import('../amiredeem/components/content/am-component.module').then((m) => m.AmComponentModule),
          cmsComponents: [
            'DisplayContentInfoComponent',
            'DisplayContentImageComponent',
            'PromotionCardContainerComponent',
            'CMSTextBannerComponent',
            'FeaturedBrandComponent',
            'DisplayContentProductInfoComponent',
            'OurPromiseComponent',
            'MulitSearchBoxComponent',
            'CMSImagesGalleryComponent',
            'CMSDuoComponent',
            'CMSBannerCarouselComponent',
            'AMLParagraphComponent',
            'CategoryThumbnailContainerComponent'
          ]
        },
        productList: {
          module: () =>
            import('../amiredeem/pages/product-list/am-product-list.module').then((m) => m.AmProductListModule),
          cmsComponents: [
            'ProductRefinementComponent',
            'CMSProductListComponent',
            'ProductGridComponent',
            'SearchResultsListComponent',
            'EmptyProductListComponent'
          ]
        },
        productDetail: {
          module: () =>
            import('../amiredeem/pages/product-detail/am-product-detail.module').then((m) => m.AmlProductDetailModule),
          cmsComponents: ['ProductDetailSpartacusComponent']
        },

        orderList: {
          module: () => import('../amiredeem/pages/order-list/am-order-list.module').then((m) => m.AmOrderListModule),
          cmsComponents: ['orderListComponent']
        },
        orderDetail: {
          module: () =>
            import('../amiredeem/pages/order-details/order-details.module').then((m) => m.AmOrderDetailsModule),
          cmsComponents: ['OrderDetailsComponent']
        },
        [CHECKOUT_FEATURE]: {
          module: () => import('../amiredeem/pages/checkout/am-checkout.module').then((m) => m.AmCheckoutModule),
          cmsComponents: [
            'CheckoutProgress',
            'CheckoutShippingAddress',
            'CheckoutVerifyOtp',
            'OrderSummaryComponent',
            'PendingOrderConfirmationComponent',
            'InflightOrderCompleteComponent',
            'PendingOrderDetailComponent',
            'VerifyInflightOrderPageTitleComponent',
            'InflightOrderVerifyComponent',
            'WaitingPaymentComponent',
            'InflightPickupOrderComponent'
          ]
        },
        [CART_BASE_FEATURE]: {
          module: () => import('@spartacus/cart/base').then(() => AmCartDetailsModule),
          cmsComponents: ['CartComponent', 'EmptyCartComponent']
        },
        [ASM_FEATURE]: {
          module: () => import('@spartacus/asm').then(() => AmAsmModule)
        },
        inflightDiagostic: {
          module: () =>
            import('./components/content/inflight-diagnostic/inflight-diagnostic.module').then(
              () => InflightDiagnosticModule
            )
        },
        // cart: {
        //   module: () => import('../amiredeem/pages/cart/am-cart-details.module').then((m) => m.AmCartDetailsModule),
        //   cmsComponents: ['CartComponent', 'EmptyCartComponent']
        // },
        error: {}
      }
    })
  ],
  providers: []
})
export class AmChunkConfigModule {}
