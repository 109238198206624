<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div #dialog class="country-language-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title" aria-live="polite" aria-atomic="true">
          {{ 'languageAndDelivery.readyToshopTitle' | cxTranslate }}
        </div>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main text-center">
          <div class="body-container">
            <div class="language-section">
              <div class="subtitle">{{ 'languageAndDelivery.chooseLanguage' | cxTranslate }}</div>
              <div class="popup-radio-container">
                <div class="form-check" *ngFor="let item of languages">
                  <input
                    class="am-radio-selector"
                    type="radio"
                    id="{{ name }}_{{ item.isocode }}"
                    name="{{ name }}-language-selector"
                    value="{{ item.isocode }}"
                    [(ngModel)]="selectedLanguage" />
                  <label for="{{ name }}_{{ item.isocode }}" class="feild-label">{{ item.nativeName }}</label>
                </div>
              </div>
            </div>
            <div class="location-section">
              <div class="subtitle">
                {{ 'languageAndDelivery.chooseDelivery' | cxTranslate }}
              </div>
              <label>
                <ng-select
                  [searchable]="false"
                  [clearable]="false"
                  [items]="countries"
                  bindLabel="displayName"
                  bindValue="isocode"
                  [(ngModel)]="selectedCountry">
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="cx-dialog-buttons modal-button d-flex">
        <button
          aria-label="apply"
          class="btn button-primary remove-button font-bold"
          (click)="confirm()"
          cxModalReason="provide language and location info"
          cxModal="dismiss"
          autofocus>
          {{ 'languageAndDelivery.apply' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
