<ng-container *ngIf="cart$ | async as cart">
  <div class="cart-right">
    <div class="cart-order-summary summary-before-slider bgwhite">
      <div class="cart-order-summary-title main-title">
        <am-svg-container [type]="svgType.PRICE" svgDescription=""></am-svg-container>
        {{ 'amCartDetails.summary.detailsTitle' | cxTranslate }}
      </div>
      <div class="total-price-before-slider">
        <div class="total-price-label">
          {{
            'amCartDetails.summary.detailsItemsLabel'
              | cxTranslate
                : { count: flightStatusStorageService.isInflightPickFlow ? totalAmount : cart.totalUnitCount }
          }}
        </div>
        <div class="price-wrap">
          <ng-container
            *ngIf="(!cart.allMilesOnly && cashAfterDiscount > 0) || (cart.isOnlyFreeGift && cashAfterDiscount === 0)">
            <span class="price-cash">
              <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
              <span class="font-bold">
                {{ cashAfterDiscount | number : '1.2-2' }}
              </span>
            </span>
          </ng-container>
          <ng-container *ngIf="milesAfterDiscount > 0">
            <span class="price-discounted" [class.only-miles]="cart.allMilesOnly || cart.totalPriceCash.value <= 0">
              <ng-container *ngIf="!cart.allMilesOnly && cashAfterDiscount > 0"> + </ng-container>
              <am-svg-container [type]="svgType.AMICONGREY" svgDescription="Asia miles"></am-svg-container>
              <span>{{ milesAfterDiscount | number : '1.0' }}</span>
            </span>
          </ng-container>
        </div>
      </div>
      <!-- Apply promo code for Product discount promotion -->
      <div class="promo-code-wrap" *ngIf="cart.appliedProductPromotions?.length">
        <ng-container *ngFor="let promotion of cart.appliedProductPromotions">
          <ng-container
            *ngIf="
              !promotion.orderPromo && !promotion.bonusMiles && totalDiscountUnitPrice(promotion.consumedEntries) > 0
            ">
            <div class="promo-code d-flex">
              <div class="promo-code-left">
                <span class="add-promo-code">
                  <ng-container *ngIf="!promotion.appliedVouchers?.length">{{ promotion.promotion.name }}</ng-container>
                  {{ promotion.appliedVouchers && promotion.appliedVouchers[0] }}
                </span>
                <button
                  class="link"
                  (click)="openPromotionCashModal(promotion, 'promo')"
                  aria-label="open promo code details popup">
                  <am-svg-container [type]="svgType.ICONINFOLINK" [style]="style_info_link"></am-svg-container>
                </button>
              </div>
              <div class="promo-code-right">
                <span>-</span>
                <!-- <span class="amicon-asia_miles"></span> -->
                <am-svg-container
                  [type]="svgType.AMICONGREYBIG"
                  class="asia-miles-icon"
                  svgDescription="asia miles"></am-svg-container>
                <span class="font-bold">
                  {{ totalDiscountUnitPrice(promotion.consumedEntries) }}
                </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!sliderMoved && !hasCartPromoRules">
        <am-cart-deliver-fee></am-cart-deliver-fee>
      </ng-container>

      <ng-container *ngIf="applyProductDiscountRule && (sliderMoved || hasCartPromoRules)">
        <!-- <div class="divider"></div> -->
        <div class="cart-order-summary-subtotal">
          <div class="price-wrap">
            <span class="price-label">{{ 'amCartDetails.summary.detailsSubtotalLabel' | cxTranslate }}</span>
            <ng-container *ngIf="!cart.allMilesOnly && cart.totalPriceCash?.value > 0">
              <span class="price-cash">
                <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
                <span class="font-bold">
                  {{ cashAfterDiscount | number : '1.2-2' }}
                </span>
              </span>
            </ng-container>
            <ng-container *ngIf="cart.subTotal.value > 0">
              <span class="price-discounted" [class.only-miles]="cart.allMilesOnly || cart.totalPriceCash.value <= 0">
                <ng-container *ngIf="!cart.allMilesOnly && cart.totalPriceCash.value > 0"> + </ng-container>
                <am-svg-container
                  [type]="svgType.AMICONGREY"
                  [style]="style_asia_mile_grey"
                  svgDescription="Asia miles"></am-svg-container>
                {{ milesAfterDiscount | number : '1.0' }}
              </span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- <div [class.slider-moved]="sliderMoved" class="mobile-slider"> -->

    <!-- Cart Order Summary after slider -->
    <div class="cart-order-summary summary-after-slider bgwhite" [class.slider-moved]="sliderMoved" *ngIf="sliderMoved">
      <div class="cart-order-summary-title sub-title">
        {{ 'amCartDetails.summary.detailsMpcTitle' | cxTranslate }}
      </div>
      <div class="total-price-after-slider">
        <div class="total-price-label">
          {{
            'amCartDetails.summary.detailsItemsLabel'
              | cxTranslate
                : { count: flightStatusStorageService.isInflightPickFlow ? totalAmount : cart.totalUnitCount }
          }}
        </div>
        <div class="price-wrap">
          <ng-container *ngIf="!cart.allMilesOnly && cart.subTotalPriceCash.value > 0">
            <span class="price-cash">
              <span class="currency">{{ cart.subTotalPriceCash.currencyIso }}</span>
              <span class="font-bold">
                {{ cart.subTotalPriceCash.value | number : '1.2-2' }}
              </span>
            </span>
          </ng-container>
          <ng-container *ngIf="cart.subTotal.value > 0">
            <span class="price-discounted" [class.only-miles]="cart.allMilesOnly || cart.totalPriceCash.value <= 0">
              <ng-container *ngIf="!cart.allMilesOnly && cart.subTotalPriceCash.value > 0"> + </ng-container>
              <am-svg-container
                [type]="svgType.AMICONGREY"
                [style]="style_asia_mile_grey"
                svgDescription="Asia miles"></am-svg-container>
              {{ cart.selectionMiles | number : '1.0' }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Cart promotion breakdown -->
    <div class="cart-order-summary cart-promos bgwhite" [class.slider-moved]="sliderMoved">
      <!-- items in cart -->
      <ng-container *ngIf="cart.appliedAmlOrderPromotions?.length || cart.appliedCashPromotions?.length">
        <div class="divider" *ngIf="!sliderMoved && !applyProductDiscountRule && hasCartPromoRules"></div>
        <div class="items-in-cart">
          <div class="promo-discount-content-item d-flex" *ngIf="cart.appliedAmlOrderPromotions?.length">
            <div
              class="promo-discount-content-description"
              *ngIf="cart.appliedAmlOrderPromotions[0]?.promotion; let promotion">
              <span>{{ promotion.name }}</span>
              <button
                class="link"
                (click)="openOfferDetailsModal(cart.appliedAmlOrderPromotions[0]?.promotion)"
                aria-label="open order promotion popup">
                <!-- <i class="amicon-info promo-popup-multiple"></i> -->
                <am-svg-container [type]="svgType.ICONINFOFILL" class="color-info icon-info-sm"></am-svg-container>
              </button>
            </div>
            <div>
              <span class="font-bold">-</span>
              <!-- <span class="amicon-asia_miles"></span> -->
              <am-svg-container
                [type]="svgType.AMICONGREENNEW"
                class="asia-miles-icon"
                svgDescription="asia miles"></am-svg-container>
              <span class="font-bold">
                {{ totalDiscountUnitPrice(cart.appliedAmlOrderPromotions[0]?.consumedEntries) }}
              </span>
            </div>
          </div>

          <div class="promo-discount-content-item d-flex" *ngIf="cart.appliedCashPromotions?.length">
            <div class="promo-discount-content-description">
              <span>{{ cart.appliedCashPromotions[0].promotion.name }}</span>
              <button
                class="link"
                (click)="openOfferDetailsModal(cart.appliedCashPromotions[0].promotion)"
                aria-label="open order promotion popup">
                <!-- <i class="amicon-info promo-popup-multiple"></i> -->
                <am-svg-container [type]="svgType.ICONINFOFILL" class="color-info icon-info-sm"></am-svg-container>
              </button>
            </div>
            <div>
              <span class="font-bold">-</span>
              <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
              <span class="font-bold">
                {{ cart.appliedCashPromotions[0]?.discountCash | number : '1.2-2' }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- /items in cart -->
    </div>

    <!-- Delivery fee - have cart promotion -->
    <ng-container *ngIf="sliderMoved || hasCartPromoRules">
      <div class="cart-order-summary summary-delivery-fee bgwhite" [class.slider-moved]="sliderMoved">
        <am-cart-deliver-fee></am-cart-deliver-fee>
      </div>
    </ng-container>

    <!-- Total Price -->
    <div class="cart-order-summary final-total bgwhite" [class.slider-moved]="sliderMoved">
      <div class="divider"></div>
      <div class="total-price">
        <div class="price-wrap text-right">
          <ng-container
            *ngIf="
              cart.totalPriceCash.value > 0 ||
              (cart.totalPriceCash.value === 0 && (cart.isOnlyFreeGift || cart.totalPrice.value == 0))
            ">
            <p class="price-cash">
              <span>
                {{ 'amCartDetails.summary.detailsSubtotalLabel' | cxTranslate }}
              </span>
              <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
              <span class="font-bold">
                {{ cart.totalPriceCash.value | number : '1.2-2' }}
              </span>
            </p>
          </ng-container>
          <ng-container *ngIf="cart.totalPrice.value > 0">
            <p class="price-discounted">
              <ng-container *ngIf="!cart.allMilesOnly && cart.totalPriceCash.value > 0"> + </ng-container>
              <span *ngIf="!(cart.totalPriceCash.value > 0) && cart.totalPrice.value > 0">
                {{ 'amCartDetails.summary.detailsSubtotalLabel' | cxTranslate }}
              </span>
              <am-svg-container [type]="svgType.AMICONGREY" svgDescription="Asia miles"></am-svg-container>
              <strong>{{ cart.totalPrice?.value | number : '1.0' }}</strong>
            </p>
          </ng-container>
        </div>
        <!-- /miles and cash slider -->
        <ng-container *ngIf="cart.insufficientBalance">
          <am-message-tip
            [iconType]="svgType.ICONWARNINGRED"
            messageType="error"
            [hasBackgroudColor]="false"
            [isSmallMessageTip]="true"
            [messageContent]="'amCartDetails.slider.error.lackOfBalance' | cxTranslate"></am-message-tip>
        </ng-container>
        <ng-container *ngIf="cart.isMobileVerfied === false">
          <am-message-tip
            [iconType]="svgType.ICONWARNINGRED"
            messageType="error"
            [hasBackgroudColor]="false"
            [isSmallMessageTip]="true"
            [messageContent]="'amCartDetails.profileVerifyErrorMsg' | cxTranslate"></am-message-tip>
        </ng-container>
      </div>

      <!-- Earn Miles -->
      <div
        class="earn-miles"
        *ngIf="
          (cart.earnmilesMessageCart && cart.totalPriceCash.value <= 0) ||
          cart.appliedBonusPromotions?.length ||
          cart.totalEarnedMiles > 0
        ">
        <div class="earn-miles-content">
          <div
            *ngIf="!cart.appliedBonusPromotions?.length && cart.totalPriceCash.value <= 0; else appliedBonusPromotions">
            <span class="font-bold">
              {{ 'amCartDetails.miles.cashspend' | cxTranslate }}
            </span>
          </div>
          <ng-template #appliedBonusPromotions>
            <div class="earn-miles-offer">
              <div class="d-flex">
                <div class="earn-miles-offer-left">
                  <span>{{ 'amCartDetails.miles.tobeearned' | cxTranslate }}</span>
                  <am-svg-container
                    [type]="svgType.AMICONLIGHTGOLD"
                    [style]="style_asia_mile_grey"
                    svgDescription="Asia miles"></am-svg-container>
                  <span class="font-bold asia-miles-amount">
                    {{ cart.totalBonusMiles | number }}
                  </span>
                  <button class="link" aria-label="Miles earning on cash spending" (click)="openMilesEarnDialog()">
                    <am-svg-container [type]="svgType.ICONINFOPROMOTION" [style]="style_info_link"></am-svg-container>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <div class="checkout-button-container" #checkoutForm>
      <!-- Checkout condition -->
      <div class="checkout-condition bgwhite">
        <div class="checkbox-group d-flex">
          <input
            type="checkbox"
            class="form-control"
            tabindex="0"
            [(ngModel)]="termCondition"
            id="form-control-label" />
          <label
            class="agree-form agreeForm"
            for="form-control-label"
            [class.font-bold]="termCondition"
            [innerHTML]="cart.termsAndconditionsText | safeHtml">
          </label>
        </div>
      </div>

      <!-- Checkout button -->
      <div
        class="checkout-button bgwhite"
        [ngClass]="{ 'flow1-orange': activeTab === 'inflight', 'flow1-grey': activeTab === 'ground' }">
        <!-- only mobile show -->
        <div class="text-content">
          <div class="cart-final-price-total">
            <p class="price-cash font-bold" *ngIf="!cart.allMilesOnly && cart.totalPriceCash.value > 0">
              <span class="currency">{{ cart.totalPriceCash.currencyIso }}</span>
              <span>
                {{ cart.totalPriceCash.value | number : '1.2-2' }}
              </span>
            </p>
            <ng-container *ngIf="cart.subTotal.value > 0">
              <p class="price-discounted" [class.only-miles]="cart.allMilesOnly || cart.totalPriceCash.value <= 0">
                <ng-container *ngIf="!cart.allMilesOnly && cart.subTotalPriceCash.value > 0"> + </ng-container>
                <span class="am-icon-grey"></span>
                {{ cart.totalPrice.value | number : '1.0' }}
              </p>
            </ng-container>
          </div>
          <div class="cart-delivery-fee-total">
            <span>{{ 'amCartDetails.miles.tobeearned' | cxTranslate }}</span>
            <span class="am-icon-grey"></span>
            <span class="total-miles-to-earn">
              {{ cart.totalBonusMiles | number : '1.0' }}
            </span>
          </div>
        </div>

        <button
          aria-label="Go to checkout"
          (click)="goToCheckout(cart)"
          *ngIf="cart.entries.length"
          class="button-primary button-style"
          [disabled]="
            (cartIsLoading$ | async) ||
            !termCondition ||
            cart.errorMegFlag ||
            inFlightPickUpError ||
            isDelistedOrOffline ||
            (isStoreOpenStatus === false && activeTab === 'inflight') ||
            (isNonAsmStoreAssistant$ | async) ||
            disabledButton
          "
          [class.button-disable]="
            (cartIsLoading$ | async) ||
            !termCondition ||
            cart.errorMegFlag ||
            inFlightPickUpError ||
            isDelistedOrOffline ||
            (isStoreOpenStatus === false && activeTab === 'inflight') ||
            (isNonAsmStoreAssistant$ | async) ||
            disabledButton
          "
          type="button">
          {{ 'amCartDetails.goToCheckout' | cxTranslate }}
          {{ flightStatusStorageService.isInflightPickFlow ? '(' + totalAmount + ')' : '' }}
        </button>
      </div>
    </div>

    <div
      class="bgwhite"
      [class.slider-bar]="
        (cart.containMPC && !cart.containFullCashOrMPC) ||
        cart.containFullCashOrMPC ||
        (cart.noOpenToggle && cart.containFullCashOrMPC) ||
        showSlider ||
        (!cart.waiveDeliveryFee && cart.totalDeliveryFee > 0)
      ">
      <!-- Cart Total Title Info -->
      <ng-container *ngIf="(cart.containMPC && !cart.containFullCashOrMPC) || cart.containFullCashOrMPC">
        <div class="main-title">
          <am-svg-container [type]="svgType.MILESCASH" svgDescription=""></am-svg-container
          >{{ 'amCartDetails.slider.mainTitle' | cxTranslate }}
        </div>
        <div class="mpc-slider-title">
          <am-svg-container
            [type]="svgType.ICONCASHTOMILESOFF"
            [style]="style_cash_mile_off"
            svgDescription="Cash to miles"></am-svg-container>
          <ng-container *ngIf="cart.containFullCashOrMPC">
            <div class="mpc-slider-title-right d-flex">
              <div class="title-info">
                <div class="title font-light">
                  <span class="font-bold">
                    {{ 'amCartDetails.slider.titleToggle' | cxTranslate }}
                  </span>
                </div>
                <div class="hint font-regular">
                  <ng-container *ngIf="cart.fcomToggle">
                    {{ 'amCartDetails.slider.hintWithSlider' | cxTranslate : { num: cart.minMiles } }}
                  </ng-container>
                  <ng-container *ngIf="!cart.fcomToggle">
                    {{ 'amCartDetails.slider.hintWithoutSlider' | cxTranslate : { num: cart.minMiles } }}
                  </ng-container>
                </div>
              </div>
              <div class="cart-toggle">
                <div class="foc-toggle-btn">
                  <am-slider-toggle-button
                    name="toggle-fcom"
                    [status]="cart.fcomToggle"
                    (changed)="toggleFcom($event)"
                    [disabled]="(cartIsLoading$ | async) || cart.noOpenToggle">
                  </am-slider-toggle-button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!cart.containFullCashOrMPC">
            <div class="title-info">
              <div class="title font-light">
                <span class="font-bold">
                  <!-- <spring:theme code="text.cart.page.slider.title" /> -->
                  {{ 'amCartDetails.slider.title' | cxTranslate }}
                </span>
              </div>
              <div class="hint font-regular">
                <ng-container *ngIf="cart.fcomToggle">
                  {{ 'amCartDetails.slider.hintWithSlider' | cxTranslate }}
                </ng-container>
                <ng-container *ngIf="!cart.fcomToggle">
                  {{ 'amCartDetails.slider.hintWithoutSlider' | cxTranslate }}
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="toggle-warning" *ngIf="cart.noOpenToggle && cart.containFullCashOrMPC">
        <am-message-tip
          messageType="warning-yellow"
          iconClass="icon-warning"
          [hasBackgroudColor]="false"
          [messageContent]="
            'amCartDetails.slider.hintCannotToogleFco'
              | cxTranslate : { count: (cart.openToggleMinMiles | number : '1.0') }
          "></am-message-tip>
      </div>

      <!-- miles and cash slider -->
      <ng-container *ngIf="showSlider">
        <div class="mpc-slider">
          <div class="">
            <ngx-slider
              *ngIf="isBrowser"
              [cxFocus]="{ key: 'slider' }"
              [(value)]="mileValue"
              [options]="{
                floor: cart.minMiles,
                ceil: cart.maxMiles > cart.minMiles ? cart.maxMiles : cart.minMiles,
                hideLimitLabels: true,
                hidePointerLabels: true,
                disabled: cart.insufficientBalance && !cart.allMilesOnly
              }"
              (userChangeEnd)="update(cart, $event)">
            </ngx-slider>
          </div>
          <div class="mile-cash-slider-label d-flex">
            <p>{{ 'amCartDetails.slider.cash' | cxTranslate }}</p>
            <p>{{ 'amCartDetails.slider.miles' | cxTranslate }}</p>
          </div>
          <div class="slider-input-container">
            <div class="row slider-input">
              <div class="col-5 slider-cash col-form-label">
                <span class="mile-cash-slider-currency currency">{{ cart.totalPriceCash.currencyIso }}</span>
                <span class="font-bold">
                  {{ cart.subTotalPriceCash.value | number : '1.2-2' }}
                </span>
              </div>
              <div class="col-1 slider-plus col-form-label font-size-xl">+</div>
              <div class="col-lg-6 col-md-5 col-6">
                <div class="field-group">
                  <input
                    class="field-input form-control"
                    [cxFocus]="{ key: 'input-miles' }"
                    [disabled]="cart.insufficientBalance && !cart.allMilesOnly"
                    [value]="mileValue"
                    (change)="update(cart, $event)"
                    type="number"
                    id="miles"
                    name="miles"
                    [placeholder]="cart.minMiles" />
                  <label for="miles" class="is-visually-hidden">Miles input box</label>
                  <!-- <span class="amicon-asia_miles font-size-md mile-icon"></span> -->
                  <am-svg-container
                    [type]="svgType.AMICONGREYBIG"
                    class="mile-icon"
                    svgDescription="asia miles"></am-svg-container>
                </div>
              </div>
            </div>
            <div *ngIf="isErrorMinMile" class="error-min-miles">
              {{ 'amCartDetails.slider.error.minimumMilesSpend1' | cxTranslate }}
              <!-- <span class="amicon-asia_miles font-size-xs"></span> -->
              <am-svg-container
                [type]="svgType.AMICONGREYBIG"
                class="asia-miles-icon"
                svgDescription="asia miles"></am-svg-container>
              {{ cart.minMiles }}
              {{ 'amCartDetails.slider.error.minimumMilesSpend2' | cxTranslate }}
            </div>
          </div>
        </div>
      </ng-container>
      <!-- delivery fee -->
      <ng-container *ngIf="!cart.waiveDeliveryFee && cart.totalDeliveryFee > 0">
        <div class="delivery-fee-box">
          <div class="delivery-fee-container d-flex">
            <div class="delivery-fee-box-label">
              <am-svg-container
                [type]="svgType.CARTCATHAY"
                [style]="style_cathay_cart"
                svgDescription="Home delivery"></am-svg-container>
              <span class="delivery-fee-box-content">
                {{ 'amCartDetails.deliveryFee.toggleLable' | cxTranslate }}
              </span>
            </div>
            <div class="cart-toggle">
              <am-slider-toggle-button
                name="toggle-deliveryfee"
                [status]="cart.deliveryFeeCashOrMilesToggle"
                (changed)="toggleDeliveryFee($event)"
                [disabled]="(cartIsLoading$ | async) || cart.noOpendeliveryFeeCashOrMilesToggle">
              </am-slider-toggle-button>
            </div>
          </div>
          <div class="delivery-fee-tip">
            <span
              *ngIf="cart?.flatRateDelivery?.milesPrice"
              [innerHTML]="
                'amCartDetails.deliveryFee.costTip'
                  | cxTranslate : { count: (cart?.flatRateDelivery?.milesPrice | number : '1.0') }
              ">
            </span>
          </div>
          <ng-container *ngIf="cart.appliedDeliveryFeePromotion?.promotion && !cart.waiveDeliveryFee">
            <am-message-tip
              messageType="warning-yellow"
              iconClass="icon-smart-tip"
              [messageContent]="
                'amCartDetails.deliveryFee.toggleTips'
                  | cxTranslate : cxTranslate : { count: (cart.deliveryFeeMileBalanceDiff | number : '1.0') }
              "></am-message-tip>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <!--promo code link & input-->
    <div class="bgwhite coupon-code">
      <am-cart-coupon></am-cart-coupon>
    </div>
  </div>
</ng-container>
