<div class="am-display-search-box" *ngIf="data$ | async as data">
  <div class="desktop-view">
    <button class="search-svg" (click)="displaySearchContent()">
      <am-svg-container [type]="svgType.SEARCH" size="m" color="dark"></am-svg-container>
      <span class="is-visually-hidden">Search</span>
    </button>
  </div>
  <div class="mobile-view">
    <a
      href="javascript:void(0);"
      class="search-icon"
      (click)="displaySearchContent()"
      [attr.aria-label]="data?.waterMarkForSearchBox">
      <div class="search-svg">
        <am-svg-container [type]="svgType.SEARCH" size="m" color="dark"></am-svg-container>
      </div>
      <span aria-hidden="true">{{ data?.waterMarkForSearchBox }}</span>
    </a>
  </div>
  <!-- <div class="search-container" [ngClass]="activeSearchContent ? 'show' : 'hide'">
    <am-search-box
      #searchBox
      (hideSearchContent)="hideSearchContent()"
      [placeholder]="data?.waterMarkForSearchBox"></am-search-box>
  </div> -->
  <div [hidden]="!activeSearchContent">
    <am-search-dialog #searchDialog
      [isOpen]="activeSearchContent"
      [placeholder]="data?.waterMarkForSearchBox"
      (onClose)="hideSearchContent()">
    </am-search-dialog>
  </div>
</div>
