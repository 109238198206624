<div #swiper class="am-promotion-banner" *ngIf="detailItems$ | async as detailItems">
  <swiper
    [config]="swiperConfig"
    (imagesReady)="toggleTabIndex($event)"
    (slideChangeTransitionEnd)="toggleTabIndex($event)">
    <ng-template swiperSlide *ngFor="let detailItem of detailItems; let i = index">
      <div class="am-hero-promotion-banner-item">
        <div class="inner-container" [ngClass]="{ fullImage: detailItem?.isFullImage === 'true' }">
          <div class="media-container">
            <am-generic-link
              *ngIf="!detailItem?.displayMedia && detailItem?.mediaContainer"
              [url]="detailItem?.url"
              class="media-item-box"
              [placement]="activeIndex === i ? 'Promotion Banner_Image' : null"
              [elementPosition]="activeIndex === i ? i : null"
              [name]="activeIndex === i ? detailItem.name : null"
              [pageName]="pageName"
              type="PROMOTION_BANNER">
              <am-media [container]="detailItem.mediaContainer" [transitionEvent]="true"></am-media>
            </am-generic-link>
            <div *ngIf="detailItem?.displayMedia" class="media-item-box">
              <app-media-player
                [posterImage]="detailItem.mediaPostImageUrl"
                [videoLink]="detailItem.displayMedia"
                [placeHolderMode]="true">
              </app-media-player>
            </div>
          </div>

          <div class="description-container" [ngClass]="{ 'light-text': detailItem?.textInWhite === 'true' }">
            <h3 *ngIf="detailItem?.displayDescription1">
              {{ detailItem.displayDescription1 }}
            </h3>
            <ng-container *ngIf="detailItem?.displayDescription2; else empty">
              <p [amRichtext]="detailItem.displayDescription2"></p>
            </ng-container>
            <ng-template #empty>
              <p class="empty-description"></p>
            </ng-template>

            <!-- <a
              *ngIf="detailItem?.displayButtonName"
              [href]="detailItem?.url"
              class="button-primary"
              [attr.data-cxlinktag-placement]="activeIndex === i ? 'Promotion Banner_CTA' : null"
              [attr.data-cxlinktag-element-position]="activeIndex === i ? '0-' + i : null"
              [attr.data-cxlinktag-name]="activeIndex === i ? detailItem.name : null">
              {{ detailItem.displayButtonName }}
            </a> -->

            <am-generic-link
              *ngIf="detailItem?.displayButtonName"
              [url]="detailItem?.url"
              class="button-primary"
              [placement]="activeIndex === i ? 'Promotion Banner_CTA' : null"
              [elementPosition]="activeIndex === i ? '0-' + i : null"
              [name]="activeIndex === i ? detailItem.name : null"
              [pageName]="pageName"
              type="PROMOTION_BANNER">
              {{ detailItem.displayButtonName }}
            </am-generic-link>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
