export const activeTabCodeMap = {
  favourite: 'favourite',
  notify: 'notify'
};

export const defaultSortCode: string = 'relevance';

export const sortsMap = {
  en: [
    {
      code: defaultSortCode,
      name: 'Default'
    },
    {
      code: 'name-asc',
      name: 'Brand name(A-Z)'
    },
    {
      code: 'name-desc',
      name: 'Brand name(Z-A)'
    },
    {
      code: 'zonePrice-asc',
      name: 'Price(Low-High)'
    },
    {
      code: 'zonePrice-desc',
      name: 'Price(High-Low)'
    }
  ],
  zh: [
    {
      code: defaultSortCode,
      name: '预定'
    },
    {
      code: 'name-asc',
      name: '品牌名称（A-Z）'
    },
    {
      code: 'name-desc',
      name: '品牌名称（Z-A）'
    },
    {
      code: 'zonePrice-asc',
      name: '价格（低到高）'
    },
    {
      code: 'zonePrice-desc',
      name: '价格（高到低）'
    }
  ],
  zh_TW: [
    {
      code: defaultSortCode,
      name: '預定'
    },
    {
      code: 'name-asc',
      name: '品牌名稱（A-Z）'
    },
    {
      code: 'name-desc',
      name: '品牌名稱（Z-A）'
    },
    {
      code: 'zonePrice-asc',
      name: '價格（低至高）'
    },
    {
      code: 'zonePrice-desc',
      name: '價格（高至低）'
    }
  ],
  ja: [
    {
      code: defaultSortCode,
      name: 'デフォルト'
    },
    {
      code: 'name-asc',
      name: 'ブランド名(A-Z)'
    },
    {
      code: 'name-desc',
      name: 'ブランド名(Z-A)'
    },
    {
      code: 'zonePrice-asc',
      name: '価格（低‐高）'
    },
    {
      code: 'zonePrice-desc',
      name: '価格（高‐低）'
    }
  ],
  ko: [
    {
      code: defaultSortCode,
      name: '기본값'
    },
    {
      code: 'name-asc',
      name: '브랜드 이름 (A-Z)'
    },
    {
      code: 'name-desc',
      name: '브랜드 이름 (Z-A)'
    },
    {
      code: 'zonePrice-asc',
      name: '가격 (낮은순)'
    },
    {
      code: 'zonePrice-desc',
      name: '가격 (높은순)'
    }
  ]
};
