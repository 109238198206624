<ng-container *ngIf="pageLabel !== 'pagenotfound' && pageLabel !== 'search'">
  <ng-container *ngIf="products$ | async as products">
    <div
      *ngIf="products.length > 0"
      class="am-merchandising-info swiper-lg-show"
      [ngClass]="{ 'hide-arrow': products.length <= 4 }">
      <h2>{{ title }}</h2>
      <swiper class="swiper-list" [config]="lgSwiperConfig" [loop]="products.length <= 4 ? false : true">
        <ng-template swiperSlide *ngFor="let product of products; let i = index">
          <a
            class="product-card"
            [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
            data-cxlinktag-placement="promotion_nav"
            [attr.data-cxlinktag-element-position]="i"
            attr.data-cxlinktag-name="{{ title }}:{{ product.name }}"
            (click)="setCodeOnSessionStorage(product)">
            <div class="product-img">
              <am-media
                [container]="product.convertedImages?.GALLERY?.[0]?.store"
                [transitionEvent]="true"
                offset="200"
                alt=""></am-media>
              <div
                *ngIf="product.productTagGroupA?.tagName"
                class="font-bold product-img-tag product-img-tag-{{
                  getTagGroupAColor(product.productTagGroupA?.type)
                }}">
                {{ product.productTagGroupA.tagName }}
              </div>
            </div>

            <ng-container *ngIf="product.productTagGroupB">
              <ng-container *ngIf="product.productTagGroupB.colour && product.productTagGroupB.colour === 'GREEN'">
                <p class="font-bold product-tag product-tag-green-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>

              <ng-container *ngIf="product.productTagGroupB.colour && product.productTagGroupB.colour === 'Gold'">
                <p class="font-bold product-tag product-tag-gold-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>

              <ng-container
                *ngIf="
                  product.productTagGroupB.colour &&
                  product.productTagGroupB.colour !== 'GREEN' &&
                  product.productTagGroupB.colour !== 'Gold'
                ">
                <p class="font-bold product-tag">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>
            </ng-container>

            <div class="product-description">
              <h5 class="product-description-title font-bold" *ngIf="product.brandName">{{ product.brandName }}</h5>
              <p class="product-description__p">{{ product.name }}</p>
            </div>

            <div class="product-sale">
              <p *ngIf="product.stock?.stockLevelStatus === 'outOfStock'" class="product-sale-stock">
                {{ 'amProductItem.outOfStock' | cxTranslate }}
              </p>
              <p *ngIf="product.stock?.stockLevelStatus === 'lowStock'" class="product-sale-stock">
                {{ 'amProductItem.lowStock' | cxTranslate }}
              </p>

              <div class="product-price">
                <p *ngIf="product?.variantPriceFromDisplay" class="product-sale-from">
                  {{ 'amProductItem.from' | cxTranslate }}
                </p>
                <am-product-price [product]="product"></am-product-price>
              </div>
              <div *ngIf="product.havePublishedPotentialBonusRules" class="product-sale-earn-mile">
                <span class="icon-yellow">
                  <img src="/assets/images/icons/earn-yellow.svg" alt="" />
                </span>
                <p class="font-bold">{{ 'amProductItem.bonusMessage' | cxTranslate }}</p>
              </div>
            </div>
          </a>
        </ng-template>
      </swiper>
      <div class="swiper-button-prev swiper-button-prev_{{ this.navBtnId }}"></div>
      <div class="swiper-button-next swiper-button-next_{{ this.navBtnId }}"></div>
    </div>

    <div
      *ngIf="products.length > 0"
      class="am-merchandising-info swiper-md-show"
      [ngClass]="{ 'hide-arrow': products.length <= 3 }">
      <h2>{{ title }}</h2>
      <swiper class="swiper-list" [config]="mdSwiperConfig" [loop]="products.length <= 3 ? false : true">
        <ng-template swiperSlide *ngFor="let product of products; let i = index">
          <a
            class="product-card"
            [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
            data-cxlinktag-placement="promotion_nav"
            [attr.data-cxlinktag-element-position]="i"
            (click)="setCodeOnSessionStorage(product)"
            attr.data-cxlinktag-name="{{ title }}:{{ product.name }}">
            <div class="product-img">
              <am-media [container]="product.convertedImages?.GALLERY?.[0]?.store" offset="200"></am-media>
              <div
                *ngIf="product.productTagGroupA?.tagName"
                class="font-bold product-img-tag product-img-tag-{{
                  getTagGroupAColor(product.productTagGroupA?.type)
                }}">
                {{ product.productTagGroupA.tagName }}
              </div>
            </div>

            <ng-container *ngIf="product.productTagGroupB">
              <ng-container *ngIf="product.productTagGroupB.colour && product.productTagGroupB.colour === 'GREEN'">
                <p class="font-bold product-tag product-tag-green-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>

              <ng-container *ngIf="product.productTagGroupB.colour && product.productTagGroupB.colour === 'Gold'">
                <p class="font-bold product-tag product-tag-gold-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>

              <ng-container
                *ngIf="
                  product.productTagGroupB.colour &&
                  product.productTagGroupB.colour !== 'GREEN' &&
                  product.productTagGroupB.colour !== 'Gold'
                ">
                <p class="font-bold product-tag product-tag-black-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>
            </ng-container>

            <div class="product-description">
              <h5 class="product-description-title font-bold" *ngIf="product.brandName">{{ product.brandName }}</h5>
              <p class="product-description__p">{{ product.name }}</p>
            </div>

            <div class="product-sale">
              <p *ngIf="product.stock?.stockLevelStatus === 'outOfStock'" class="product-sale-stock">
                {{ 'amProductItem.outOfStock' | cxTranslate }}
              </p>
              <p *ngIf="product.stock?.stockLevelStatus === 'lowStock'" class="product-sale-stock">
                {{ 'amProductItem.lowStock' | cxTranslate }}
              </p>

              <div class="product-price">
                <p *ngIf="product?.variantPriceFromDisplay" class="product-sale-from">
                  {{ 'amProductItem.from' | cxTranslate }}
                </p>
                <am-product-price [product]="product"></am-product-price>
              </div>
              <div *ngIf="product.havePublishedPotentialBonusRules" class="product-sale-earn-mile">
                <span class="icon-yellow">
                  <img src="/assets/images/icons/earn-yellow.svg" alt="" />
                </span>
                <p class="font-bold">{{ 'amProductItem.bonusMessage' | cxTranslate }}</p>
              </div>
            </div>
          </a>
        </ng-template>
      </swiper>
      <div class="swiper-button-prev swiper-button-md-next_{{ this.navBtnId }}"></div>
      <div class="swiper-button-next swiper-button-md-next_{{ this.navBtnId }}"></div>
    </div>

    <div *ngIf="products.length > 0" class="am-merchandising-info swiper-sm-show">
      <h2>{{ title }}</h2>
      <swiper class="swiper-list" [config]="smSwiperConfig" [loop]="true">
        <ng-template swiperSlide *ngFor="let product of products; let i = index">
          <a
            class="product-card"
            [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
            data-cxlinktag-placement="promotion_nav"
            [attr.data-cxlinktag-element-position]="i"
            (click)="setCodeOnSessionStorage(product)"
            attr.data-cxlinktag-name="{{ title }}:{{ product.name }}">
            <div class="product-img">
              <am-media
                [container]="product.convertedImages?.GALLERY?.[0]?.store"
                [transitionEvent]="true"
                offset="200"
                alt=""></am-media>
              <div
                *ngIf="product.productTagGroupA?.tagName"
                class="font-bold product-img-tag product-img-tag-{{
                  getTagGroupAColor(product.productTagGroupA?.type)
                }}">
                {{ product.productTagGroupA.tagName }}
              </div>
            </div>

            <ng-container *ngIf="product.productTagGroupB">
              <ng-container *ngIf="product.productTagGroupB.colour && product.productTagGroupB.colour === 'GREEN'">
                <p class="font-bold product-tag product-tag-green-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>

              <ng-container *ngIf="product.productTagGroupB.colour && product.productTagGroupB.colour === 'Gold'">
                <p class="font-bold product-tag product-tag-gold-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>

              <ng-container
                *ngIf="
                  product.productTagGroupB.colour &&
                  product.productTagGroupB.colour !== 'GREEN' &&
                  product.productTagGroupB.colour !== 'Gold'
                ">
                <p class="font-bold product-tag product-tag-black-bg">
                  {{ product.productTagGroupB.tagName }}
                </p>
              </ng-container>
            </ng-container>

            <div class="product-description">
              <h5 class="product-description-title font-bold" *ngIf="product.brandName">{{ product.brandName }}</h5>
              <p class="product-description__p">{{ product.name }}</p>
            </div>

            <div class="product-sale">
              <p *ngIf="product.stock?.stockLevelStatus === 'outOfStock'" class="product-sale-stock">
                {{ 'amProductItem.outOfStock' | cxTranslate }}
              </p>
              <p *ngIf="product.stock?.stockLevelStatus === 'lowStock'" class="product-sale-stock">
                {{ 'amProductItem.lowStock' | cxTranslate }}
              </p>

              <div class="product-price">
                <p *ngIf="product?.variantPriceFromDisplay" class="product-sale-from">
                  {{ 'amProductItem.from' | cxTranslate }}
                </p>
                <am-product-price [product]="product"></am-product-price>
              </div>
              <div *ngIf="product.havePublishedPotentialBonusRules" class="product-sale-earn-mile">
                <span class="icon-yellow">
                  <img src="/assets/images/icons/earn-yellow.svg" alt="" />
                </span>
                <p class="font-bold">{{ 'amProductItem.bonusMessage' | cxTranslate }}</p>
              </div>
            </div>
          </a>
        </ng-template>
      </swiper>
    </div>
  </ng-container>
</ng-container>

<div class="page-not-found" *ngIf="pageLabel === 'pagenotfound' || pageLabel === 'search'">
  <ng-container *ngIf="products$ | async as products">
    <div class="recommend-product-list-wrapper">
      <ng-container *ngIf="pageLabel === 'pagenotfound'">
        <h3 class="title">
          {{ 'amRecommendProduct.pageNotFoundTip' | cxTranslate }}
        </h3>
        <p class="sub-title">
          {{ 'amRecommendProduct.pageNotFoundSubTip' | cxTranslate }}
        </p>
      </ng-container>

      <div class="product-container">
        <a
          class="product-item"
          *ngFor="let product of products"
          (click)="setCodeOnSessionStorage(product)"
          [routerLink]="{ cxRoute: 'product', params: { code: product?.code } } | cxUrl">
          <div class="product-image product-img">
            <am-media *ngIf="!!product.img1ForPLP" [container]="{ url: product.img1ForPLP }" offset="200"></am-media>
            <am-media
              *ngIf="!product.img1ForPLP"
              [container]="product.images"
              [format]="product"
              offset="200"></am-media>
            <div
              *ngIf="product.productTagGroupA?.tagName"
              class="font-bold product-img-tag product-img-tag-{{ getTagGroupAColor(product.productTagGroupA?.type) }}">
              {{ product.productTagGroupA.tagName }}
            </div>
          </div>
          <div class="product-description">
            <p class="brand-name font-bold">
              {{ product?.brandName }}
            </p>
            <p class="product-name">
              {{ product?.name }}
            </p>
            <div class="product-price">
              <am-product-price [product]="product"></am-product-price>
            </div>
          </div>
        </a>
      </div>
    </div>
  </ng-container>
</div>
