import { NgModule } from '@angular/core';
import { AsmAdapter, AsmCoreModule } from '@spartacus/asm/core';
import { AsmOccModule } from '@spartacus/asm/occ';
import { AsmEnablerService, CsAgentAuthService } from '@spartacus/asm/root';
import { provideDefaultConfig } from '@spartacus/core';
import { AmAsmComponentsModule, defaultAsmConfig, defaultOccAsmConfig } from './components/am-asm-components.module';
import { AmAsmAdapter } from './occ/adapter/am-asm.adapter';
import { AmAsmEnablerService } from './root/services/am-asm-enable.service';
import { AmCsAgentAuthService } from './root/services/am-csagent-auth.service';

@NgModule({
  imports: [AmAsmComponentsModule, AsmCoreModule, AsmOccModule],
  providers: [
    provideDefaultConfig(defaultAsmConfig),
    provideDefaultConfig(defaultOccAsmConfig),
    {
      provide: AsmAdapter,
      useExisting: AmAsmAdapter
    },
    {
      provide: AsmEnablerService,
      useExisting: AmAsmEnablerService
    },
    {
      provide: CsAgentAuthService,
      useExisting: AmCsAgentAuthService
    }
  ]
})
export class AmAsmModule {}
