import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { RoutingService, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { PendingOrderPopupService } from './pending-order-popup.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pending-order-popup',
  templateUrl: './pending-order-popup.component.html'
})
export class PendingOrderPopupComponent implements OnInit {
  @ViewChild('pendingOrderContent') pendingOrderContent: ElementRef;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  orderExpiryDay: number;
  subscription: Subscription = new Subscription();

  constructor(
    protected routingService: RoutingService,
    private pendingOrderPopupService: PendingOrderPopupService,
    protected launchDialogService: AmLaunchDialogService,
    protected winRef: WindowRef
  ) {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        this.orderExpiryDay = data?.orderExpiryDay;
      })
    );
  }

  ngOnInit(): void {
    this.winRef.isBrowser() &&
      setTimeout(() => {
        this.pendingOrderContent.nativeElement.focus();
      }, 500);
  }

  dismissModal(reason?: any): void {
    this.pendingOrderPopupService.closeModal(reason);
  }

  clickNotNow(): void {
    this.subscription.add(
      this.pendingOrderPopupService.isCancelPendingPopup().subscribe((res) => {
        this.pendingOrderPopupService.closeModal('click not now');
      })
    );
  }

  viewMyOrder(): void {
    this.pendingOrderPopupService.closeModal('click view order');
    this.routingService.go('orders');
  }

  onFirstElementTab(): void {
    this.pendingOrderContent.nativeElement.focus();
  }

  onLastElementTab(): void {
    this.closeBtn.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
