import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MessageTipModule } from '@components/content/message-tip/message-tip.module';
import { CartBaseComponentsModule } from '@spartacus/cart/base/components';
import {
  CartEntryAdapter,
  CartValidationAdapter,
  CartValidationGuard,
  CartValidationService,
  CartVoucherAdapter,
  SelectiveCartService
} from '@spartacus/cart/base/core';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';
import {
  ActiveCartFacade,
  CartValidationFacade,
  CartVoucherFacade,
  MultiCartFacade,
  SelectiveCartFacade
} from '@spartacus/cart/base/root';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { AuthGuard, CmsConfig, I18nModule, UrlModule, provideDefaultConfig } from '@spartacus/core';
import { AmCartDetailsComponent } from './am-cart-details.component';
import { amCartDialogLayoutConfig } from './am-cart-dialog-layout.config';
import { AmCartDetailFlow1ContainerModule } from './component/am-cart-detail-flow1-container/am-cart-detail-flow1-container.module';
import { CartDetailNonflow1ContainerModule } from './component/cart-detail-nonflow1-container/cart-detail-nonflow1-container.module';
import { AmCartEmptyComponent } from './component/cart-empty/cart-empty.component';
import { AmCartItemListModule } from './component/cart-item-list/cart-item-list.module';
import { AmCartMessagesModule } from './component/cart-messages/cart-messages.module';
import { AmCartTotalsModule } from './component/cart-totals/cart-totals.module';
import { MobileVerificationDialogModule } from './component/mobile-verification-dialog/mobile-verification-dialog.module';
import { defaultAmOccCartConfig } from './config/default-occ-cart-config';
import { AmCartBaseCoreModule } from './core/am-cart-base-core.module';
import { AmCartNotEmptyGuard } from './guards/am-cart-not-empty.guard';
import { AmCartValidationGuard } from './guards/am-cart-validation.guard';
import { AmOccCartEntryAdapter } from './occ/am-occ-cart-entry.adapter';
import { AmOccCartValidationAdapter } from './occ/am-occ-cart-validation.adapter';
import { AmOccCartVoucherAdapter } from './occ/am-occ-cart-voucher.adapter';
import { AmActiveCartService } from './service/active-cart.service';
import { AmCartVoucherService } from './service/cart-voucher.service';
import { AmMultiCartService } from './service/multi-cart.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    AmCartTotalsModule,
    AmCartMessagesModule,
    AmCartBaseCoreModule,
    CartBaseOccModule,
    CartBaseComponentsModule,
    AmCartItemListModule,
    MobileVerificationDialogModule,
    // AmAddToCartStoreModule,
    MessageTipModule,
    AmCartDetailFlow1ContainerModule,
    CartDetailNonflow1ContainerModule
  ],
  providers: [
    provideDefaultConfig(defaultAmOccCartConfig),
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CartComponent: {
          component: AmCartDetailsComponent,
          guards: [AuthGuard]
        },
        EmptyCartComponent: {
          component: AmCartEmptyComponent,
          guards: [AuthGuard]
        }
      }
    }),
    provideDefaultConfig(amCartDialogLayoutConfig),
    {
      provide: CartVoucherAdapter,
      useClass: AmOccCartVoucherAdapter
    },
    {
      provide: ActiveCartFacade,
      useExisting: AmActiveCartService
    },
    {
      provide: CartVoucherFacade,
      useExisting: AmCartVoucherService
    },
    {
      provide: MultiCartFacade,
      useExisting: AmMultiCartService
    },
    {
      provide: SelectiveCartFacade,
      useExisting: SelectiveCartService
    },
    {
      provide: CartValidationFacade,
      useExisting: CartValidationService
    },
    {
      provide: CartEntryAdapter,
      useClass: AmOccCartEntryAdapter
    },
    {
      provide: CartValidationGuard,
      useClass: AmCartValidationGuard
    },
    {
      provide: CartNotEmptyGuard,
      useClass: AmCartNotEmptyGuard
    },
    {
      provide: CartValidationAdapter,
      useClass: AmOccCartValidationAdapter
    }
  ],
  declarations: [AmCartDetailsComponent, AmCartEmptyComponent],
  exports: [AmCartDetailsComponent, AmCartEmptyComponent]
})
export class AmCartDetailsModule {}
