import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'am-slider-toggle-button',
  templateUrl: './slider-toggle-button.component.html'
})
export class SliderToggleButtonComponent implements OnInit {
  @Input() name;

  @Input() label: string = '';

  @Input() disabled: boolean = false;
  // @Input() status: boolean = false;

  @Output() changed = new EventEmitter<boolean>();

  @Input() status = false;

  constructor() {}

  ngOnInit(): void {
    this.name = this.name ? this.name : 'toggle-button-checkbox' + uuidv4().substr(0, 8);
  }
}
