import { Component } from '@angular/core';
import { ProductSearchPage } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AmProductListComponentService } from '../../product-list/services/product-list-component.service';

@Component({
  selector: 'am-search-page-title',
  templateUrl: './search-page-title.component.html',
  styleUrls: ['./search-page-title.component.scss']
})
export class SearchPageTitleComponent {
  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;
  constructor(protected productListComponentService: AmProductListComponentService) {}

  dechodeValue(value: string): string {
    return value ? new DOMParser().parseFromString(value, 'text/html').body.firstChild.textContent : '';
  }
}
