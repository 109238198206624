import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { SvgContainerModule } from '../svg-container/svg-container.module';
import { MessageTipComponent } from './message-tip.component';

@NgModule({
  declarations: [MessageTipComponent],
  imports: [CommonModule, SvgContainerModule, I18nModule],
  exports: [MessageTipComponent],
})
export class MessageTipModule {}
