import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmMediaModule } from '@components/content/common/media/media.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { DividerComponent } from './divider.component';

@NgModule({
  declarations: [DividerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    AmMediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        DividerComponent: {
          // typeCode
          component: DividerComponent
        }
      }
    } as CmsConfig)
  ]
})
export class DividerModule {}
