<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title" aria-live="polite" aria-atomic="true">
          {{ 'amCartItems.removeFromCart' | cxTranslate }}
        </div>
        <button aria-label="Close current popup" type="button" class="close" (click)="closeModal('Cross click')">
          <span aria-hidden="true">
            <svg class="icon-close">
              <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
            </svg>
          </span>
        </button>
      </div>

      <div class="cx-dialog-body modal-body" *ngIf="entries">
        <ng-container *ngFor="let entry of entries">
          <div class="main">
            <p class="brand-name font-bold">{{ entry.product.brandName }}</p>
            <p class="product-name">{{ entry.product.name }}</p>
          </div>
        </ng-container>
      </div>

      <!-- Actions -->
      <div class="cx-dialog-buttons d-flex">
        <div class="cx-dialog-buttons d-flex">
          <button aria-label="cancel remove" class="button-secondary cancel-button" (click)="closeModal('cancel')">
            {{ 'amCartItems.removeFromCartCancel' | cxTranslate }}
          </button>
          <button aria-label="remo" class="button-primary remove-button" (click)="remove()" autofocus>
            {{ 'amCartItems.removeFromCartRemove' | cxTranslate : { count: entries?.length } }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
