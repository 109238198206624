import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { svgConfig } from '@config/am-svg-sprite.config';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { CollectionMethod, CollectionMethodType } from '@model/collection-method.enum';
import { LanguageService, OccConfig, Product, RoutingService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { AmCartService } from '../../../cart/service/am-cart.service';
import { AmAddToCartService } from '../../../product-detail/component/add-to-cart/add-to-cart-dialog/add-to-cart.service';
import { activeTabCodeMap, defaultSortCode, sortsMap } from '../../constants/favorite-notify-products-contants';
import { FavoriteNotifyProductsService } from '../../services/favorite-notify-products.service';
import { FavoriteNotifyReminderService } from '../favorite-notify-reminder/favorite-notify-reminder.service';

@Component({
  selector: 'am-favorite-notify-product-list',
  templateUrl: './favorite-notify-product-list.component.html'
})
export class FavoriteNotifyProductListComponent implements OnDestroy {
  products$ = this.favoriteNotifyProductsService.products$.pipe(
    map((products) => {
      return products.map((productItem) => {
        const { product, productName, isDeliveryCountry, isDiscontinued, emailTriggerTime, isInRedeemPeriod } = productItem;
        let isComingSoon = false;
        if (
          product.redemptionStartDate &&
          new Date(product.redemptionStartDate).getTime() > new Date().getTime() &&
          !!product.productTagGroupA?.tagName
        ) {
          isComingSoon = true;
        }
        let isDisabled = false;
        if (isDeliveryCountry === false || isDiscontinued || isInRedeemPeriod === false && !isComingSoon) {
          isDisabled = true;
        }
        return {
          ...product,
          isDeliveryCountry,
          isDiscontinued,
          productName,
          isComingSoon,
          emailTriggerTime,
          isInRedeemPeriod,
          isDisabled
        };
      });
    })
  );
  addToCartResult$ = this.amCartService.getAddToCartResult().subscribe((data) => {
    if (!data?.productCode) {
      return;
    }
    if (data.errorMsg === 'amErrorMapping.quantityNoItemsAddedMaxOrderQuantityExceeded') {
      this.favoriteNotifyProductsService.showReminderSub.next({
        productCode: data.productCode,
        isSuccess: false,
        action: 'addToCartMaxError',
        // @ts-ignore
        params: { maxQuantity: data.maxQuantity }
      });
      this.cdr.markForCheck();
    } else {
      this.favoriteNotifyProductsService.showReminderSub.next({
        productCode: data.productCode,
        isSuccess: true,
        action: ''
      });
    }
  });

  sorts = [];
  sortCode: string = 'relevance';
  activeType = '';
  activeTabCodeMap = activeTabCodeMap;
  collectionMethod = CollectionMethod;
  collectionMethodType = CollectionMethodType;
  nofityCodes = [];
  svgType = svgConfig;
  cartIconStyle = 'width: 15px;height: 12.5px';
  deleteIconStyle = 'width: 24px;height: 24px';
  doneIconStyle = 'width: 18px;height: 18px';
  activeProductCode: string = '';

  getActiveType$: Subscription = null;
  getNotifyList$: Subscription = null;
  getSorts$: Subscription = null;
  getRouterState$: Subscription = null;
  disableNotifyButton: boolean = false;
  protected subscription = new Subscription();
  constructor(
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected occConfig: OccConfig,
    public amCartService: AmCartService,
    protected authService: AmAuthService,
    protected amAddToCartService: AmAddToCartService,
    protected cdr: ChangeDetectorRef,
    protected languageService: LanguageService,
    protected winRef: WindowRef,
    protected launchDialogService: AmLaunchDialogService,
    protected routing: RoutingService,
    protected amConfigService: AmConfigService,
    protected favoriteNotifyReminderService: FavoriteNotifyReminderService
  ) {
    this.setActiveType();
    this.setNotifyProductCodes();
    this.setSorts();
    this.getNotifyButtonConfig();
  }

  getNotifyButtonConfig() {
    this.subscription.add(
      this.amConfigService
        .getCommonConfig()
        .pipe(take(1))
        .subscribe((data) => {
          if (data.notifySwitch === false) {
            this.disableNotifyButton = true;
          }
        })
    );
  }

  getCurrentBaseOptions(baseOptions: [], code: string): any {
    return baseOptions.filter((option: any) => option.selected.code === code)[0];
  }

  setActiveType() {
    this.getActiveType$ = this.favoriteNotifyProductsService
      .getActiveType()
      .pipe(filter((item) => !!item))
      .subscribe((activeType) => {
        this.activeType = activeType;
        this.cdr.markForCheck();
      });
  }

  setNotifyProductCodes() {
    this.getNotifyList$ = this.favoriteNotifyProductsService
      .getNotifyList()
      .pipe(filter((data) => data?.length > 0))
      .subscribe((notifyList) => {
        this.nofityCodes = [];
        notifyList.forEach((item) => {
          this.nofityCodes.push(item.product.code);
        });
        this.cdr.markForCheck();
      });
  }

  setSorts() {
    this.getSorts$ = this.languageService.getActive().subscribe((item) => {
      this.sorts = sortsMap[item];
    });
    this.getRouterState$ = this.routing
      .getRouterState()
      .pipe(
        distinctUntilChanged((x, y) => {
          // router emits new value also when the anticipated `nextState` changes
          // but we want to perform search only when current url changes
          return x.state.queryParams?.sortCode === y.state.queryParams?.sortCode;
        })
      )
      .subscribe((routerState) => {
        const sortCode = routerState.state.queryParams.sortCode;
        this.sortCode = sortCode ?? defaultSortCode;
      });
  }

  changeSortCode(sortCode: string): void {
    this.favoriteNotifyProductsService.sort(sortCode);
  }

  protected getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  addToCart(product: Product) {
    this.activeProductCode = product.code;
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.FAVORITE_NOTIFY_BOTTOM_SHEET,
      undefined,
      undefined,
      {
        productCode: product.code,
        isNotifyMe: false
      }
    );
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  moveNotifyItemToCart(product: Product) {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.FAVORITE_NOTIFY_BOTTOM_SHEET,
      undefined,
      undefined,
      {
        productCode: product.code,
        isNotifyMe: true
      }
    );
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
  }

  addProductIntoNotifyList(productCode: string) {
    this.favoriteNotifyReminderService.openReminder(productCode);
    this.activeProductCode = productCode;
    this.favoriteNotifyProductsService.addProductIntoNotifyList(productCode);
  }

  removeProductFromNotifyList(productCode: string) {
    this.favoriteNotifyReminderService.openReminder(productCode);
    this.activeProductCode = productCode;
    this.favoriteNotifyProductsService.removeProductFromNotifyList(productCode);
  }

  removeProductFromFavoriteList(productCode: string) {
    this.favoriteNotifyReminderService.openReminder(productCode);
    this.activeProductCode = productCode;
    this.favoriteNotifyProductsService.removeProductFromFavoirteList(productCode);
  }

  isInNotifyList(productCode) {
    return this.nofityCodes.indexOf(productCode) > -1 ? true : false;
  }

  hasNotifyMeTip(product) {
    return (
      this.activeType === this.activeTabCodeMap.notify &&
      (product.isComingSoon ||
        product.stock?.stockLevelStatus === 'outOfStock' ||
        (product.stock?.stockLevelStatus !== 'outOfStock' &&
          product.isDeliveryCountry !== false &&
          product.isDiscontinued !== true))
    );
  }

  ngOnDestroy() {
    this.getActiveType$?.unsubscribe();
    this.getNotifyList$?.unsubscribe();
    this.getSorts$?.unsubscribe();
    this.getRouterState$?.unsubscribe();
    this.subscription?.unsubscribe();
    this.addToCartResult$?.unsubscribe();
  }

  retrieveTagAName(productTagA: string) {
    let productTagAArr = productTagA.split(':');
    return productTagAArr[1];
  }
}
