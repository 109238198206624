import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AmAsmEnablerService } from '@core/asm/root/services/am-asm-enable.service';
import { environment } from '@env/environment';
import { AsmAuthStorageService } from '@spartacus/asm/root';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';

export interface KeepAliveUrl {
  [key: string]: string;
}

const thirdTokenKeepAliveStartTime = 'thrid_token_keepalive_startTime';

@Injectable({
  providedIn: 'root'
})
export class AmThirdPartyKeepAliveService implements OnDestroy {
  timer: any;
  protected subscription = new Subscription();

  constructor(
    protected authStorageService: AsmAuthStorageService,
    protected httpClient: HttpClient,
    protected asmEnableSvc: AmAsmEnablerService,
    protected winRef: WindowRef
  ) {
    this.setupThirdPartyTokenTimer();
  }

  storeThirdTokenKeepAliveStartTime(): void {
    const date = new Date().getTime();
    this.winRef.localStorage?.setItem(thirdTokenKeepAliveStartTime, date + '');
  }

  removeThirdTokenKeepAliveStartTime(): void {
    this.winRef.localStorage?.removeItem(thirdTokenKeepAliveStartTime);
    clearTimeout(this.timer);
  }

  getThirdTokenKeepAliveStartTime(): number {
    return Number(this.winRef.localStorage?.getItem(thirdTokenKeepAliveStartTime));
  }

  setupThirdPartyTokenTimer(): void {
    const createdTime = this.getThirdTokenKeepAliveStartTime();
    if (createdTime) {
      const diff = environment.keepAliveCycle - (new Date().getTime() - createdTime);
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (diff <= 0) {
        this.removeThirdTokenKeepAliveStartTime();
        return;
      }
      this.timer =
        this.winRef.isBrowser() &&
        setTimeout(() => {
          console.log('run keep alive');
          this.keepAlive();
        }, diff);
    }
  }

  protected keepAlive(): void {
    let url = environment.mlcv2KeepAliveUrl;
    if (this.asmEnableSvc.isEnabled()) {
      url = environment.idpKeepAliveUrl;
    }
    this.subscription.add(
      this.httpClient.get(url).subscribe(
        () => {
          if (this.winRef.localStorage) {
            this.storeThirdTokenKeepAliveStartTime();
            this.setupThirdPartyTokenTimer();
          }
        },
        (err) => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
          console.log('keep alive fail', err);
        }
      )
    );
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }
}
