<div #swiper class="am-promotion-banner" *ngIf="detailItems$ | async as detailItems">
  <swiper [config]="swiperConfig" (imagesReady)="toggleTabIndex()" (slideChangeTransitionEnd)="toggleTabIndex()">
    <ng-template swiperSlide *ngFor="let detailItem of detailItems">
      <div class="am-hero-promotion-banner-item">
        <div class="inner-container" [ngClass]="{ fullImage: detailItem?.isFullImage === 'true' }">
          <div class="media-container">
            <am-generic-link
              *ngIf="!detailItem?.displayMedia && detailItem?.displayImage"
              [url]="detailItem?.url"
              class="media-item-box">
              <am-media [container]="detailItem.displayImage" [transitionEvent]="true"></am-media>
              <!-- <am-image [image]="detailItem.displayImage.url"></am-image> -->
            </am-generic-link>
            <div *ngIf="detailItem?.displayMedia" class="media-item-box">
              <app-media-player
                [posterImage]="detailItem.mediaPostImageUrl"
                [videoLink]="detailItem.displayMedia"
                [placeHolderMode]="true">
              </app-media-player>
            </div>
          </div>

          <div class="description-container" [ngClass]="{ 'light-text': detailItem?.textInWhite === 'true' }">
            <h3 *ngIf="detailItem?.displayDescription1">
              {{ detailItem.displayDescription1 }}
            </h3>
            <p *ngIf="detailItem?.displayDescription2">
              {{ detailItem.displayDescription2 }}
            </p>
            <button *ngIf="detailItem?.displayButtonName" [href]="detailItem?.url" class="button-secondary">
              {{ detailItem.displayButtonName }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
