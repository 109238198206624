export enum SiteContextType {
  LANGUAGE = 'language',
  CURRENCY = 'currency'
}

export enum InflightTabType {
  INFLIGHT = 'inflight',
  GROUND = 'ground'
}

export enum InflightSalesChannel {
  OFF = 'Off',
  INFLIGHTHD = 'InflightHomedelivery',
  INFLIGHTONLINE = 'InflightOnline',
  INFLIGHTOFFLINE = 'InflightOffline',
  INFLIGHTONLINEANDOFFLINE = 'InflightOnlineOffline',
  INFLIGHTOFFLINEANDHD = 'InflightOfflineHomeDelivery'
}
