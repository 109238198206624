<ng-container *ngIf="contentHighlightWithImageData$ | async as contentHighlightWithImageData">
  <div class="content-highlight-with-image-container">
    <div class="content-width">
      <ng-container *ngFor="let item of contentHighlightWithImageData; let i = index">
        <div class="item-container">
          <div class="image-section" *ngIf="item.image">
            <img [src]="getBaseUrl() + item.image.url" [alt]="item.image.alt ? item.iamge.alt : ''" />
          </div>
          <div class="text-container">
            <div class="text-title">{{ item.title }}</div>
            <div class="text-description">{{ item.description }}</div>
            <ng-container *ngIf="item.link?.display === 'true'">
              <a
                class="link-area"
                [ngClass]="{ isInternalLink: item.link?.newTab !== 'true' }"
                [href]="checkUrlIfContainsDomain(item.link?.localizedUrl)"
                [target]="item.link?.newTab === 'true' ? '_blank' : ''"
                >{{ item.link?.linkName }}
              </a>
              <am-svg-container
                *ngIf="item.link?.newTab === 'true'"
                [type]="svgType.AMICONTRACKING"
                svgDescription="Open in a new tab"></am-svg-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
