export enum CheckoutPreCheckMsgCode {
  INVALID_CART = 'INVALID_CART',
  MOBILE_VERIFY_FAILED = 'MOBILE_VERIFY_FAILED',
  MOBILE_VERIFY_REDIRECT = 'MOBILE_VERIFY_REDIRECT',
  MILES_BALANCE_FAILEED = 'MILES_BALANCE_FAILEED',
  MOBILE_VERIFY_LRP_ENABLED = 'MOBILE_VERIFY_LRP_ENABLED',
  MILES_CASH_ZERO = 'MILES_CASH_ZERO',
  SUCCESS = 'SUCCESS',
  CCSI_VALIDATION_FAILED = 'CCSI_VALIDATION_FAILED'
}
