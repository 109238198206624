import { Component, Input, OnInit } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';

@Component({
  selector: 'am-plp-product-price',
  templateUrl: './plp-product-price.component.html'
})
export class PLPProductPriceComponent implements OnInit {
  @Input() set product(v) {
    if (v) {
      this._product = v;
      this.decimal = this.product.currency == 'HKD' ? 0 : 2;
    }
  }
  get product() {
    return this._product;
  }
  private _product;
  decimal: number;
  svgType = svgConfig;
  constructor() {}

  ngOnInit(): void {}
}
