import { Injectable } from '@angular/core';
import { FIRST_ACCESS_COUNTRY_NAME } from '@components/layout/globalPopup/contants/global-popup.contansts';
import { select, Store } from '@ngrx/store';
import { Country, SiteContext, SiteContextConfig, TranslationService } from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CountriesActions } from '../../store/actions';
import { CountriesSelector } from '../../store/selectors';
import { StateWithSiteContext } from '../../store/state';

@Injectable({ providedIn: 'root' })
export class CountryService implements SiteContext<Country> {
  public countryItems: Country[];
  protected subscription = new Subscription();
  constructor(
    protected store: Store<StateWithSiteContext>,
    protected config: SiteContextConfig,
    private translate: TranslationService
  ) {}

  getAll(): Observable<Country[]> {
    return this.store.pipe(
      select(CountriesSelector.getAllCountries),
      tap((countries) => {
        if (!countries) {
          this.store.dispatch(new CountriesActions.LoadCountries());
        }
      }),
      filter((countries) => Boolean(countries))
    );
  }

  getActive(): Observable<string> {
    return this.store.pipe(
      select(CountriesSelector.getActiveCountry),
      filter((active) => Boolean(active))
    );
  }

  getActiveCountryEntity(): Observable<Country> {
    return this.getAll().pipe(
      switchMap(() => {
        return this.store.pipe(
          select(CountriesSelector.getActiveCountryEntity),
          filter((active) => Boolean(active))
        );
      })
    );
  }

  setActive(isocode: string) {
    this.subscription.add(
      this.store.pipe(select(CountriesSelector.getActiveCountry), take(1)).subscribe((activeCountry) => {
        this.setCookie(isocode);
        if (activeCountry !== isocode) {
          this.store.dispatch(new CountriesActions.SetActiveCountry(isocode));
        }
      })
    );
  }

  setCookie(isocode) {
    if (
      CookiesHelper.getCookie(FIRST_ACCESS_COUNTRY_NAME) &&
      CookiesHelper.getCookie(FIRST_ACCESS_COUNTRY_NAME) !== isocode
    ) {
      CookiesHelper.setCookie(FIRST_ACCESS_COUNTRY_NAME, isocode);
    }
  }

  isInitialized(): boolean {
    let valueInitialized = false;
    this.getActive()
      .subscribe(() => (valueInitialized = true))
      .unsubscribe();

    return valueInitialized;
  }

  getFormattedCountries(): Observable<Country[]> {
    let deliveryInfoLabel = '';
    // this.translate.translate('languageAndDelivery.deliveryTo').subscribe((val) => {
    //   deliveryInfoLabel = val;
    // });
    return this.getAll().pipe(
      map((countryItems: any[]) => {
        countryItems = countryItems.map((countryItem) => {
          return {
            ...countryItem,
            name: `${countryItem.name} (${countryItem.currency})`,
            displayName: `${deliveryInfoLabel} ${countryItem.name} (${countryItem.currency})`
          };
        });
        this.countryItems = countryItems;
        return countryItems;
      })
    );
  }

  getCurrencyFromActiveCountryEntity(activeCountry): string {
    let selectedCountry = this.countryItems.filter((item) => item.isocode === activeCountry);
    if (selectedCountry && selectedCountry.length > 0) {
      return selectedCountry[0].currency;
    }
    return null;
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
