<div class="search-dialog-container">
  <div class="search-dialog-mask"></div>
  <div class="search-dialog-popup">
    <button aria-label="Close current popup" type="button" class="link btn-close" (click)="close($event)" tabindex="0">
      <svg class="icon-close">
        <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
      </svg>
    </button>
    <!-- <button aria-label="close popup" type="button" class="link" (click)="closeDialog()">
      <am-svg-container [type]="svgType.AMCLOSE" size="s" color="dark"></am-svg-container>
    </button> -->
    <div class="sd-input-group">
      <div class="icon-search">
        <am-svg-container [type]="svgType.SEARCHDEX" style="width:18px;height:18px;" alt="search button"></am-svg-container>
      </div>
      <input
        #searchInput
        autocomplete="off"
        aria-autocomplete="both"
        [attr.aria-label]="'common.search' | cxTranslate"
        [placeholder]="placeholder ?? ''"
        [(ngModel)]="searchValue"
        (keydown.enter)="goSearch($event, true)"
        (keydown.escape)="close($event)"
        (input)="searchTerm()"
      />
      <button
        *ngIf="searchValue !== ''"
        type="button"
        [attr.aria-label]="'common.reset' | cxTranslate"
        (mousedown)="handleClearInput()"
        (keydown.enter)="handleClearInput()"
        class="link btn-reset">
        {{ 'amCommon.clearAll' | cxTranslate }}
      </button>
    </div>
    <div *ngIf="isEmptySearch" class="sd-input-before">
      <div class="sd-recent-searches">
        <button
          *ngIf="amServerBoxService.searchCookies.length > 0"
          type="button"
          class="link float-right btn-clear"
          (mousedown)="clearCookies()"
          (keydown.enter)="clearCookies()">
          {{ 'amCommon.clearAll' | cxTranslate }}
        </button>
        <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.recentSearches' | cxTranslate }}</h6>
        <div *ngIf="amServerBoxService.searchCookies.length > 0; else sdNoCookie" class="sd-search-term">
          <a *ngFor="let searchCookie of amServerBoxService.searchCookies"
            href="javascript:void(0)"
            (click)="close($event)"
            [routerLink]="{ cxRoute: 'search', params: { query: searchCookie } } | cxUrl">
            <span>{{ searchCookie }}</span>
          </a>
        </div>
        <ng-template #sdNoCookie>
          <span class="sd-no-cookie sd-font-sm font-gt-regular">{{ 'amSearchBox.noRecentSearches' | cxTranslate }}</span>
        </ng-template>
      </div>
      <hr class="sd-separation">
      <div class="row">
        <div *ngIf="displayBlock.popularSearches" class="col-6 col-md-3">
          <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.popularSearches' | cxTranslate }}</h6>
          <div class="sd-term-vertical">
            <a *ngFor="let tag of hotTags.popularTagsList"
              href="javascript:void(0)"
              (click)="close($event)"
              [routerLink]="{ cxRoute: 'search', params: { query: tag } } | cxUrl">
              <span>{{ tag }}</span>
            </a>
          </div>
        </div>
        <div *ngIf="displayBlock.popularBrands" class="col-6 col-md-3">
          <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.popularBrands' | cxTranslate }}</h6>
          <div class="sd-term-vertical">
            <a *ngFor="let brand of hotTags.popularBrands"
              href="javascript:void(0)"
              (click)="close($event)"
              [routerLink]="brand.url">
              <span>{{ brand.name }}</span>
            </a>
          </div>
        </div>
        <div *ngIf="displayBlock.psORpb && displayBlock.cathayFeatures" class="col-12 d-sm-block d-md-none">
          <hr class="sd-separation">
        </div>
        <div *ngIf="displayBlock.cathayFeatures" class="col-12 col-md-5">
          <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.cathayFeatures' | cxTranslate }}</h6>
          <div *ngFor="let mediaObj of hotTags.cathayFeature" class="sd-picture-link">
            <a 
              href="javascript:void(0)"
              (click)="close($event)"
              [routerLink]="mediaObj.linkUrl">
              <am-media [container]="mediaObj"></am-media>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isEmptySearch" class="sd-input-after">
      <div [hidden]="!displayBlock.isLoading">
        <div class="row">
          <div class="col-12 col-lg-3">
            <h6 class="sd-font-sm">
              <span loadingMask>
                {{ 'amSearchBox.suggestedResults' | cxTranslate }}
              </span>
            </h6>
            <div class="sd-term-vertical">
              <a *ngFor="let suggestion of [].constructor(markSuggestionsMax)"
                href="javascript:void(0)">
                <span loadingMask></span>
              </a>
            </div>
          </div>
          <div class="col-12 d-sm-block d-lg-none">
            <hr class="sd-separation">
          </div>
          <div class="col-12 col-lg-9">
            <h6 class="sd-font-sm">
              <span loadingMask>
                {{ 'amSearchBox.suggestedItems' | cxTranslate }}
              </span>
            </h6>
            <div class="row">
              <!-- <div *ngFor="let product of markProducts; trackBy: index2" class="col-6 col-md-3 col-lg-4"> -->
              <div *ngFor="let product of markProducts" class="col-6 col-md-3 col-lg-4">
                <am-product-card [isLoading]="true"></am-product-card>
              </div>
            </div>
          </div>
        </div>
        <div class="sd-go-btn d-lg-flex justify-content-lg-center">
          <button type="button" class="am-btn btn-md button-secondary" loadingMask>
            {{ 'amProductFacet.apply' | cxTranslate : { total: '999+' } }}
          </button>
        </div>
      </div>
      <ng-container *ngIf="amResults$ | async as results">
        <div [hidden]="displayBlock.isLoading">
          <div class="row">
            <div *ngIf="displayBlock.suggestedResults" class="col-12 col-lg-3">
              <h6 class="sd-font-sm">
                <span class="font-gt-regular">
                  {{ 'amSearchBox.suggestedResults' | cxTranslate }}
                </span>
              </h6>
              <div class="sd-term-vertical">
                <a *ngFor="let suggestion of results?.suggestions"
                  href="javascript:void(0)"
                  (click)="clearInput()"
                  (click)="close($event)"
                  [routerLink]="{ cxRoute: 'search', params: { query: suggestion } } | cxUrl">
                  <span [innerHTML]="suggestion | cxHighlight: searchValue"></span>
                </a>
              </div>
            </div>
            <div *ngIf="displayBlock.suggestedResults && displayBlock.suggestedItems" class="col-12 d-sm-block d-lg-none">
              <hr class="sd-separation">
            </div>
            <div *ngIf="displayBlock.suggestedItems" class="col-12" [ngClass]="displayBlock.suggestedResults ? 'col-lg-9' : 'col-lg-12'">
              <h6 class="sd-font-sm">
                <span class="font-gt-regular">
                  {{ 'amSearchBox.suggestedItems' | cxTranslate }}
                </span>
              </h6>
              <div class="row">
                <div *ngFor="let product of results?.products" class="col-6 col-md-3" [ngClass]="displayBlock.suggestedResults ? 'col-lg-4' : 'col-lg-3'">
                  <am-product-card [productData]="product" (onNavigate)="close($event)"></am-product-card>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="displayBlock.suggestedItems" class="sd-go-btn d-lg-flex justify-content-lg-center">
            <button type="button" class="am-btn btn-md button-secondary" (click)="goSearch($event, false)">
              {{ 'amProductFacet.apply' | cxTranslate : { total: results.totalResults > 999 ? '999+' : results.totalResults } }}
            </button>
          </div>
          <div *ngIf="displayBlock.noResult" class="row">
            <div class="col-12 sd-no-result font-gt-regular">
              {{ 'amSearchBox.stcCouldNotFind' | cxTranslate }}
            </div>
            <div class="col-12">
              <hr class="sd-separation">
            </div>
            <div class="col-12 col-lg-3">
              <div class="row">
                <div *ngIf="displayBlock.popularSearches" class="col-6 col-lg-12 mb-4">
                  <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.popularSearches' | cxTranslate }}</h6>
                  <div class="sd-term-vertical">
                    <a *ngFor="let tag of hotTags.popularTagsList"
                      href="javascript:void(0)"
                      (click)="close($event)"
                      [routerLink]="{ cxRoute: 'search', params: { query: tag } } | cxUrl">
                      <span>{{ tag }}</span>
                    </a>
                  </div>
                </div>
                <div *ngIf="displayBlock.popularBrands" class="col-6 col-lg-12">
                  <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.popularBrands' | cxTranslate }}</h6>
                  <div class="sd-term-vertical">
                    <a *ngFor="let brand of hotTags.popularBrands"
                      href="javascript:void(0)"
                      (click)="close($event)"
                      [routerLink]="brand.url">
                      <span>{{ brand.name }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="displayBlock.psORpb" class="col-12 d-sm-block d-lg-none">
              <hr class="sd-separation">
            </div>
            <div *ngIf="results?.suggestProducts?.length > 0" class="col-12" [ngClass]="displayBlock.psORpb ? 'col-lg-9' : 'col-lg-12'">
              <h6 class="sd-font-sm font-gt-regular">{{ 'amSearchBox.ourSuggestedItems' | cxTranslate }}</h6>
              <div class="row">
                <div *ngFor="let product of results?.suggestProducts" class="col-6 col-md-3" [ngClass]="displayBlock.psORpb ? 'col-lg-4' : 'col-lg-3'">
                  <am-product-card [productData]="product" (onNavigate)="close($event)"></am-product-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>