import { Component } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { OrderEntry } from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { AmCartService } from '../../service/am-cart.service';

@Component({
  selector: 'am-remove-all-error-dialog',
  templateUrl: './remove-all-error-dialog.component.html'
})
export class removeAllErrorDialogComponent {
  entries: OrderEntry[];
  subscription: Subscription = new Subscription();
  constructor(protected launchDialogService: AmLaunchDialogService, protected amCartService: AmCartService) {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        this.entries = data.entries;
      })
    );
  }

  remove(): void {
    const removelist = this.entries.map((v) => v.entryNumber);
    this.amCartService.removeEntries(removelist);
    this.closeModal('close');
  }

  cancel(): void {
    this.closeModal('cancel');
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
