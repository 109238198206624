import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { FlightStatusStorageService } from '../am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '../am-site-context/services/country/country.service';

@Component({
  selector: 'am-site-logo',
  templateUrl: './am-site-logo.component.html',
  styleUrls: ['./am-site-logo.component.scss']
})
export class AmSiteLogoComponent implements OnInit {
  activeCountry$ = this.countryService.getActive();
  isFlightPickUpFlow = this.flightStatusStorageService.isInflightPickFlow;

  constructor(
    public component: CmsComponentData<any>,
    protected countryService: CountryService,
    protected flightStatusStorageService: FlightStatusStorageService
  ) {}

  ngOnInit(): void {}
}
