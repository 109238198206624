import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ResultStringData {
  data: string;
}

/**
 * Auth service for normal user authentication.
 * Use to check auth status, login/logout with different OAuth flows.
 */
@Injectable({
  providedIn: 'root'
})
export class AmLoginService {
  loginProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setLoginProgress(progress): void {
    this.loginProgress$.next(progress);
  }

  ngOnDestroy(): void {}
}
