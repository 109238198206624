<div class="accordion-container {{ classRef }}">
  <div class="accordion-title" [ngClass]="displayIconPosition !== 'left' ? 'is-right' : ''">
    <a
      href="javascript:void(0)"
      [attr.id]="idRef"
      [attr.aria-expanded]="expanded"
      [attr.aria-controls]="idRef + '-body'"
      [attr.aria-label]="ariaLabel || headerLabel"
      (click)="headerClicked($event)">
      <ng-container *ngIf="displayIconPosition === 'left'">
        <ng-container *ngIf="accordionType === ACCORDION_TYPE.arrowIconType">
          <i class="icon-arrow" [ngClass]="expanded ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
        </ng-container>
        <ng-container *ngIf="accordionType === ACCORDION_TYPE.plusIconType">
          <i [ngClass]="expanded ? 'icon-minus' : 'icon-plus'"></i>
        </ng-container>
      </ng-container>
      <span>{{ headerLabel }}</span>
      <ng-container *ngIf="displayIconPosition !== 'left'">
        <span class="icon-section">
          <ng-container *ngIf="accordionType === ACCORDION_TYPE.arrowIconType">
            <i class="icon-arrow" [ngClass]="expanded ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
          </ng-container>
          <ng-container *ngIf="accordionType === ACCORDION_TYPE.plusIconType">
            <i class="icon-others" [ngClass]="expanded ? 'icon-minus' : 'icon-plus'"></i>
          </ng-container>
        </span>
      </ng-container>
    </a>
  </div>
  <div
    class="accordion-content"
    *ngIf="expanded"
    [attr.id]="idRef + '-body'"
    [attr.aria-expanded]="expanded"
    [attr.aria-labelledby]="idRef">
    <ng-content></ng-content>
  </div>
</div>
