import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VerachatService } from '@components/layout/footer/verachat/verachat.service';
import { FlightStatusStorageService } from '@components/layout/header/am-site-context/components/inflight-site-context-selector-popup/services/flight-status-storage.service';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { OccEndpointsService, User, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { of, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

declare var CXChatFrameworkSDK: any;

enum VeraChatAction {
  ADD,
  REMOVE,
  OPEN,
  CLOSE,
  SHOW,
  HIDE,
  SENT_DATA,
  SENT_ONE_TIME_TOKEN
}

enum VeraChatStatus {
  INITIAL,
  SHOW,
  HIDE,
  CLOSE
}

@Component({
  selector: 'am-verachat',
  templateUrl: './verachat.component.html'
})
export class VerachatComponent implements OnInit, OnDestroy {
  oneTimeToken: String;
  externalLinksSub: Subscription;
  // chatWidgetStatus: VeraChatStatus = VeraChatStatus.INITIAL;
  protected subscription = new Subscription();
  protected memberInfo: User;
  private cf;

  @Input() veraChatUrl;

  @Input()
  veraChatLocale = 'en_HK';

  constructor(
    protected winRef: WindowRef,
    protected httpClient: HttpClient,
    protected occEndpointSvc: OccEndpointsService,
    protected authService: AmAuthService,
    protected cdr: ChangeDetectorRef,
    protected flightStatusStorageService: FlightStatusStorageService,
    protected countryService: CountryService,
    protected verachatService: VerachatService,
    private userAccountFacade: UserAccountFacade
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.authService
        .isUserLoggedIn()
        .pipe(
          switchMap((isUserLoggedIn) => {
            if (isUserLoggedIn) {
              return this.userAccountFacade.get();
            } else {
              return of(undefined);
            }
          })
        )
        .subscribe((res) => {
          this.memberInfo = res;
        })
    );
  }

  ngAfterViewInit() {
    this.initVerachat();
    this.subscription.add(
      this.verachatService.isOpen.subscribe(() => {
        this.isOpenChange();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initVerachat(): void {
    if (this.winRef.isBrowser()) {
      this.cf = new CXChatFrameworkSDK({
        domain: this.getVerachatDomain(),
        chatWidget: false,
        chatWindowZIndex: 1051,
        locale: this.veraChatLocale
        // chatWindowStatusCallback: (status) => {
        //   if (status === 'opened') {
        //     this.chatWidgetStatus = VeraChatStatus.SHOW;
        //   } else if (status === 'closed') {
        //     this.chatWidgetStatus = VeraChatStatus.HIDE;
        //   } else if (status === 'minimized') {
        //     this.chatWidgetStatus = VeraChatStatus.HIDE;
        //   }
        // }
      });
    }
  }

  getVerachatDomain(): string {
    return window.location.host === 'lifestyle.asiamiles.com'
      ? 'https://www.cathaypacific.com'
      : 'https://www-t0.ete.cathaypacific.com';
  }

  getVerachatLocale(link: string): string {
    const veraLinkPrefix = '/verachatbot/#/';
    const veraLinkSuffix = '?chat_window';
    if (link.includes('/verachatbot/#/') && link.includes('?chat_window'))
      return link.substring(link.lastIndexOf(veraLinkPrefix) + veraLinkPrefix.length, link.lastIndexOf(veraLinkSuffix));
    else if (link.includes('en_HK')) return 'en_HK';
    else if (link.includes('zh_HK')) return 'zh_HK';
    else if (link.includes('sc_CN')) return 'sc_CN';
    else if (link.includes('ja_JP')) return 'ja_JP';
    else if (link.includes('ko_KR')) return 'ko_KR';

    return 'en_HK';
  }

  sendVeraChatAction(action: VeraChatAction): void {
    switch (action) {
      case VeraChatAction.ADD:
        this.cf.addChatWidget();
        return;
      case VeraChatAction.REMOVE:
        this.cf.removeChatWidget();
        return;
      case VeraChatAction.OPEN:
        this.cf.showChatWindow();
        return;
      case VeraChatAction.CLOSE:
        this.cf.closeChatWindow();
        return;
      case VeraChatAction.SHOW:
        this.cf.showChatWindow();
        return;
      case VeraChatAction.HIDE:
        this.cf.hideChatWindow();
        return;
      case VeraChatAction.SENT_ONE_TIME_TOKEN:
        if (this.memberInfo) {
          this.cf.setLiveChatData({
            loginMemberNumber: this.memberInfo.customerId,
            loginMemberTier: this.memberInfo.customerRecord.tierCode,
            loginName: this.memberInfo.customerRecord.memberName
          });
        }
        return;
      default:
        return;
    }
  }

  isOpenChange() {
    if (!this.veraChatUrl) {
      this.veraChatUrl = 'https://www-t0.ete.cathaypacific.com/verachatbot/#/en_HK?chat_window=floating_window';
    }

    if (this.veraChatLocale !== this.getVerachatLocale(this.getVerachatLocale(this.veraChatUrl))) {
      this.veraChatLocale = this.getVerachatLocale(this.veraChatUrl);
      this.initVerachat();
    }

    if (this.verachatService.isOpen.value) {
      this.sendVeraChatAction(VeraChatAction.SHOW);
      // Vera team suggests delaying by 1 second, so that post message after iframe creation
      timer(1000).subscribe(() => {
        this.sendVeraChatAction(VeraChatAction.SENT_ONE_TIME_TOKEN);
      });
    }
  }
}
