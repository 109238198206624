import { DeliveryAddressInfo } from '../../components/model/deliveryAddressInfo';
import { AmCheckoutActions } from '../actions/index';
import { DeliveryAddressesState } from '../am-checkout-state';

export const initialState: DeliveryAddressesState = {
  deliveyAndAddressInfo: null,
  activeAddressCode: ''
};

export function reducer(
  state = initialState,
  action: AmCheckoutActions.DeliveryAddressesAction
): DeliveryAddressesState {
  switch (action.type) {
    case AmCheckoutActions.LOAD_DELIVERY_AND_ADDRESS_INFO_SUCCESS: {
      const deliveryAddresses: DeliveryAddressInfo = action.payload;
      return {
        ...state,
        deliveyAndAddressInfo: deliveryAddresses
      };
    }
  }
  return state;
}
