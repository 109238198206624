<div class="recomment-product-categories-wrapper">
  <h3 class="title">
    {{ 'amRecommendProduct.moreToExplore' | cxTranslate }}
  </h3>
  <div class="content">
    <div class="cateogry-item" *ngFor="let categoryItem of categories">
      <div>
        <a [routerLink]="{ cxRoute: 'category', params: { code: categoryItem.code } } | cxUrl">{{
          categoryItem.name
        }}</a>
        <am-svg-container
          class="icon-light-grey-miles"
          [style]="style_arrow_icon"
          [type]="svgType.ARROWFORWARD"></am-svg-container>
      </div>
    </div>
  </div>
</div>
