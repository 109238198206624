import { activeTabCodeMap } from '../../constants/favorite-notify-products-contants';
import { FavoriteAndNotifyProductListActions } from '../actions';
import { ProductListPageState } from '../favorite-notify-product-list-state';

export const initialState: ProductListPageState = {
  displayEntries: [],
  favoriteEntries: [],
  notifyMeEntries: [],
  activeType: '',
  loaded: false
};

export function reducer(
  state = initialState,
  action: FavoriteAndNotifyProductListActions.FavoriteAndNotifyProductListActions
): ProductListPageState {
  switch (action.type) {
    case FavoriteAndNotifyProductListActions.SET_ACTIVE_TYPE: {
      const { activeType } = action.payload;
      const displayEntries = activeType === activeTabCodeMap.favourite ? state.favoriteEntries : state.notifyMeEntries;
      return {
        ...state,
        activeType,
        displayEntries
      };
    }
    case FavoriteAndNotifyProductListActions.SAVE_FAVORITY_AND_NOTIFY_PRODUCT_LIST_PAGE_DATA: {
      if (action.payload) {
        const { favoriteEntries, notifyMeEntries, activeType } = action?.payload;
        const currentActiveType = !!activeType ? activeType : state.activeType;
        const mergeObject = Object.assign(
          !!favoriteEntries && { favoriteEntries },
          !!notifyMeEntries && { notifyMeEntries },
          currentActiveType === activeTabCodeMap.favourite && !!favoriteEntries && { displayEntries: favoriteEntries },
          currentActiveType === activeTabCodeMap.notify && !!notifyMeEntries && { displayEntries: notifyMeEntries },
          { activeType: currentActiveType }
        );
        return {
          ...state,
          ...mergeObject,
          loaded: true
        };
      }
      return {
        ...state,
        ...initialState
      };
    }
    case FavoriteAndNotifyProductListActions.REMOVE_FAVORITY_AND_NOTIFY_PRODUCT_LIST_DATA: {
      return {
        ...state,
        ...initialState
      };
    }

    default:
      return state;
  }
}
