import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmLaunchDialogService } from '@components/layout/launch-dialog/service/am-launch-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'am-cart-loading-dialog',
  templateUrl: './cart-loading-dialog.component.html',
  styleUrls: ['./cart-loading-dialog.component.scss']
})
export class CartLoadingDialogComponent implements OnInit, OnDestroy {
  activeTab: string;
  subscription: Subscription = new Subscription();
  constructor(protected launchDialogService: AmLaunchDialogService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe((data) => {
        const { activeTab } = data;
        this.activeTab = activeTab;
      })
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
