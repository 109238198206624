import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KongApikeyInterceptor implements HttpInterceptor {
  constructor(private occEndpoints: OccEndpointsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.occEndpoints.getBaseUrl()) && environment.kongApiKey) {
      request = request.clone({
        setHeaders: {
          apikey: environment.kongApiKey
        }
      });
    }

    return next.handle(request);
  }
}
