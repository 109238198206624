import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { PopoverService } from '@spartacus/storefront';
import { Subject, Subscription } from 'rxjs';
import { AmToolTipComponent } from './am-tool-tip.component';
import { ToolTipEvent, ToolTipOptions } from './am-tool-tip.model';

@Directive({
  selector: '[amToolTip]'
})
export class AmToolTipDirective {
  @HostBinding('class')
  elementClass = 'tool-tip-btn';

  @Input() amToolTip: string | TemplateRef<any>;

  @Input() amPopoverOptions: ToolTipOptions;

  @Output() openPopover: EventEmitter<void> = new EventEmitter();

  /**
   * An event emitted when the popover is closed.
   */
  @Output() closePopover: EventEmitter<void> = new EventEmitter();

  isOpen: boolean;

  eventSubject: Subject<ToolTipEvent> = new Subject<ToolTipEvent>();

  tooltipContainer: ComponentRef<AmToolTipComponent>;

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    event?.preventDefault();
    if (
      (event?.target === this.element.nativeElement || event?.currentTarget === this.element.nativeElement) &&
      !this.isOpen
    ) {
      this.eventSubject.next(ToolTipEvent.OPEN);
    } else if (this.isOpen) {
      // if (this.element.nativeElement.getElementsByClassName('tooltip-content')[0].includes(event?.target)) {
      //   return;
      // }
      this.eventSubject.next(ToolTipEvent.CLOSE_BUTTON_CLICK);
    }
  }

  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  handlePress(event: KeyboardEvent): void {
    event?.preventDefault();
    if (
      (event?.target === this.element.nativeElement || event?.currentTarget === this.element.nativeElement) &&
      !this.isOpen
    ) {
      this.eventSubject.next(ToolTipEvent.OPEN_BY_KEYBOARD);
    } else if (this.isOpen) {
      this.eventSubject.next(ToolTipEvent.CLOSE_BUTTON_KEYDOWN);
    }
  }

  protected openTriggerEvents: ToolTipEvent[] = [ToolTipEvent.OPEN, ToolTipEvent.OPEN_BY_KEYBOARD];

  protected focusPopoverTriggerEvents: ToolTipEvent[] = [ToolTipEvent.OPEN_BY_KEYBOARD];

  protected closeTriggerEvents: ToolTipEvent[] = [
    ToolTipEvent.ROUTE_CHANGE,
    ToolTipEvent.ESCAPE_KEYDOWN,
    ToolTipEvent.OUTSIDE_CLICK,
    ToolTipEvent.CLOSE_BUTTON_KEYDOWN,
    ToolTipEvent.CLOSE_BUTTON_CLICK
  ];

  renderPopover() {
    this.tooltipContainer = this.viewContainer.createComponent(AmToolTipComponent);
    const componentInstance = this.tooltipContainer.instance;
    if (componentInstance) {
      componentInstance.content = this.amToolTip;
      componentInstance.triggerElement = this.element;
      componentInstance.popoverInstance = this.tooltipContainer;
      componentInstance.parentElement = this.amPopoverOptions?.parentElement;
      componentInstance.horizontalAlign = this.amPopoverOptions?.horizontalAlign ?? 'left';
      componentInstance.eventSubject = this.eventSubject;
      componentInstance.displayCloseButton = this.amPopoverOptions.displayCloseButton;
      componentInstance.className = this.amPopoverOptions.className ? this.amPopoverOptions.className : '';
    }
    this.renderer.appendChild(this.winRef.document?.body, this.tooltipContainer.location.nativeElement);
    this.tooltipContainer.changeDetectorRef.detectChanges();
  }

  open(event: ToolTipEvent) {
    this.isOpen = true;
    this.renderPopover();
    this.openPopover.emit();
  }

  /**
   * Method performs close action for popover component.
   */
  close() {
    this.isOpen = false;
    this.viewContainer.clear();
    this.closePopover.emit();
  }
  subscription = new Subscription();
  handlePopoverEvents() {
    this.subscription.add(
      this.eventSubject.subscribe((event: ToolTipEvent) => {
        if (this.openTriggerEvents.includes(event)) {
          this.open(event);
        }
        if (this.closeTriggerEvents.includes(event)) {
          this.close();
        }
      })
    );
  }

  ngOnInit() {
    this.handlePopoverEvents();
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  constructor(
    protected element: ElementRef,
    protected viewContainer: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected renderer: Renderer2,
    protected changeDetectorRef: ChangeDetectorRef,
    protected popoverService: PopoverService,
    protected winRef: WindowRef
  ) {}
}
