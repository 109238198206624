<div class="am-live-chat" [ngClass]="{ 'reset-chat-bottom': showBackToTopBtn }" *ngIf="showLiveChat">
  <div class="trigger-buttons">
    <ng-container *ngIf="chatData">
      <div class="button-wrapper" *ngIf="enable_vera && !chatData.veraChatEnable">
        <div class="image-wrapper">
          <img [src]="chatData.chatWidgetIcon" />
        </div>
        <a
          class="description"
          [href]="chatData.chatWidgetHyperlink"
          target="_blank"
          aria-label="Open Live Chat">
          {{ chatData.chatWidgetHypertext }}
        </a>
      </div>
      <div class="button-wrapper" *ngIf="enable_vera && chatData.veraChatEnable && veraSDKFlag">
        <div class="image-wrapper">
          <img [src]="chatData.chatWidgetIcon" />
        </div>
        <a
          class="description"
          (click)="openChatDialog()"
          href="javascript:void(0)"
          aria-label="Open Vera Chat">
          {{ chatData.chatWidgetHypertext }}
        </a>
        <div class="livechat-dialog">
          <am-verachat [veraChatUrl]="chatData.chatWidgetHyperlink"></am-verachat>
        </div>
      </div>
      <div class="button-wrapper" *ngIf="!enable_vera">
        <div class="image-wrapper">
          <am-svg-container
            class="chatIcon"
            [type]="svg_icon"
            [svgDescription]="svg_desc"
            [style]="style_chat_svg_icon">
          </am-svg-container>
        </div>
        <a class="description" target="_blank" [href]="chat_link" aria-label="Open Live Chat">
          {{ chatData.chatWidgetHypertext }}
        </a>
      </div>
    </ng-container>
    <div class="button-wrapper back-to-top" [ngClass]="{ 'show-icon': showBackToTopBtn }">
      <div class="image-wrapper">
        <am-svg-container
          class="donwIcon"
          [type]="svgType.ICONARROWDOWNS"
          svgDescription="Arrow Down"
          [style]="style_svg_icon"></am-svg-container>
      </div>
      <a class="description" (click)="scrollToTop()" href="javascript:void(0)" aria-label="Back to Top, Link">
        {{ 'liveChat.backToTop' | cxTranslate }}
      </a>
    </div>
  </div>
</div>
