/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { AmCartMilesEffects } from './am-cart-miles.effect';
import { AmCartRemoveEntriesEffects } from './am-cart-remove-entries.effect';
import { CartEntryEffects } from './cart-entry.effect';
import { CartVoucherEffects } from './cart-voucher.effect';
import { CartEffects } from './cart.effect';
import { MultiCartEffects } from './multi-cart.effect';

export const effects: any[] = [
  CartEntryEffects,
  CartVoucherEffects,
  CartEffects,
  MultiCartEffects,
  AmCartMilesEffects,
  AmCartRemoveEntriesEffects
];

export * from './am-cart-miles.effect';
export * from './am-cart-remove-entries.effect';
export * from './cart-entry.effect';
export * from './cart-voucher.effect';
export * from './cart.effect';
export * from './multi-cart.effect';
