import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CmsPageAdapter,
  CmsPageConnector,
  CmsStructureConfigService,
  CmsStructureModel,
  PageContext
} from '@spartacus/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmCmsPageConnector extends CmsPageConnector {
  private loadPageErrorSub: BehaviorSubject<object> = new BehaviorSubject(null);
  public loadPageErrorObs: Observable<object> = this.loadPageErrorSub.asObservable();

  constructor(
    protected cmsPageAdapter: CmsPageAdapter,
    protected cmsStructureConfigService: CmsStructureConfigService
  ) {
    super(cmsPageAdapter, cmsStructureConfigService);
  }

  /**
   * Returns an observable with the page structure. The page structure is
   * typically loaded from a backend, but can also be returned from static
   * configuration (see `CmsStructureConfigService`).
   */
  get(pageContext: PageContext): Observable<CmsStructureModel> {
    return this.cmsStructureConfigService.shouldIgnoreBackend(pageContext.id).pipe(
      switchMap((loadFromConfig) => {
        if (!loadFromConfig) {
          return this.cmsPageAdapter.load(pageContext).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === 400) {
                this.loadPageErrorSub.next(error);
                return of({});
              } else {
                return throwError(error);
              }
            })
          );
        } else {
          return of({});
        }
      }),
      switchMap((page) => this.amMergeDefaultPageStructure(pageContext, page))
    );
  }

  /**
   *
   * Merge default page structure to the given `CmsStructureModel`.
   * This is beneficial for a fast setup of the UI without necessary
   * fine-grained CMS setup.
   */
  public amMergeDefaultPageStructure(
    pageContext: PageContext,
    pageStructure: CmsStructureModel
  ): Observable<CmsStructureModel> {
    return this.cmsStructureConfigService.mergePageStructure(pageContext.id, pageStructure);
  }
}
