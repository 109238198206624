<div *ngIf="!isLoading">
  <a
    href="javascript:void(0)"
    (click)="handleNavigate($event)"
    [routerLink]="{ cxRoute: 'product', params: { code: productData?.code } } | cxUrl">
    <div class="product-card-image">
      <am-media [container]="productData?.images || { url: productData?.img1ForPLP }"></am-media>
      <!-- <img [src]="imgShowUrl" alt="" /> -->
      <div
        *ngIf="productData.productTagGroupA?.tagName"
        class="font-bold product-img-tag product-img-tag-{{
          getTagGroupAColor(productData.productTagGroupA?.type)
        }}">
        {{ productData.productTagGroupA.tagName }}
      </div>
    </div>
    <div class="product-card-description">
      <p class="brand-name font-gt-bold">
        {{ productData?.brandName }}
      </p>
      <p class="product-name">
        {{ productData?.name }}
      </p>
    </div>
  </a>
</div>
<div *ngIf="isLoading">
  <a href="javascript:void(0)">
    <div class="product-card-image" loadingMask>
      <!-- <am-media [container]="productData?.images || { url: productData?.img1ForPLP }"></am-media> -->
    </div>
    <div class="product-card-description">
      <p class="brand-name font-gt-bold">
        <span loadingMask></span>
      </p>
      <p class="product-name">
        <span loadingMask></span>
      </p>
    </div>
  </a>
</div>