import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { CmsComponent, CmsService, OccConfig, RoutingService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { activeTabCodeMap } from '../../constants/favorite-notify-products-contants';
import { FavoriteNotifyProductsService } from '../../services/favorite-notify-products.service';

export interface FollowCateogryComponent extends CmsComponent {
  categoryName: string;
  url: string;
  thumbnail: {
    url: string;
  };
}

@Component({
  selector: 'am-recommend-follow-cateogries',
  templateUrl: './recommend-follow-cateogries.component.html'
})
export class RecommendFollowCateogriesComponent implements OnDestroy {
  pageType: string = '';
  activeTabCodeMap = activeTabCodeMap;
  myListActiveType: string = '';
  getActiveType$: Subscription = null;
  svgType = svgConfig;
  style_arrow_icon = 'width: 32px; height: 32px;';
  protected subscription = new Subscription();

  constructor(
    protected componentData: CmsComponentData<any>,
    protected cmsService: CmsService,
    protected occConfig: OccConfig,
    protected routingService: RoutingService,
    protected favoriteNotifyProductsService: FavoriteNotifyProductsService,
    protected cdr: ChangeDetectorRef
  ) {
    this.getPageType();
    this.getMyListActiveType();
  }

  getPageType(): string {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((route) => {
        this.pageType = route.state.semanticRoute;
      })
    );
    return this.pageType;
  }

  getMyListActiveType() {
    this.subscription.add(
      this.favoriteNotifyProductsService.getActiveType().subscribe((data) => {
        this.myListActiveType = data;
        this.cdr.markForCheck();
      })
    );
  }

  detailItems$: Observable<any> = this.componentData.data$.pipe(
    switchMap((data) => {
      return !!data.categoryThumbnails
        ? combineLatest(
            data.categoryThumbnails
              .trim()
              .split(' ')
              .map((component) => {
                return this.cmsService.getComponentData<FollowCateogryComponent>(component).pipe(
                  map((detailItem) => {
                    return {
                      ...detailItem,
                      thumbnail: {
                        url: this.getBaseUrl() + detailItem.thumbnail?.url
                      }
                    };
                  })
                );
              })
          )
        : of(null);
    }),
    tap((data) => {
      console.log('data', data);
    })
  );

  getBaseUrl(): string {
    return this.occConfig.backend?.media?.baseUrl ?? this.occConfig.backend?.occ?.baseUrl ?? '';
  }

  ngOnDestroy() {
    this.getActiveType$?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
