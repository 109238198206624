<div class="" *ngIf="addToCartResult$ | async as addToCartResult" #dialog class="min-cart-dialog">
  <div
    (mouseenter)="clearAutoClose()"
    (mouseleave)="addAutoClose()"
    class="min-cart-content"
    *ngIf="addToCartResult?.quantityAdded > 0">
    <ng-container>
      <div class="arrow" #arrow></div>
      <div class="popover-body">
        <div class="mini-card-title">
          <span class="title">
            {{ addToCartResult.quantityAdded }}
            {{ 'amAddToCart.itemsNumber' | cxTranslate }}
          </span>
          <button aria-label="close current popup" class="link" (click)="close()">
            <svg
              class="icon-close"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill-rule="evenodd">
                <g>
                  <g transform="translate(2.000000, 2.000000)">
                    <path
                      d="M10,8.79200422 L18.7920042,0 L20,1.20799578 L11.2079958,10 L20,18.7920042 L18.7920042,20 L10,11.2079958 L1.20799578,20 L0,18.7920042 L8.79200422,10 L0,1.20799578 L1.20799578,0 L10,8.79200422 Z"></path>
                    <path
                      fill-rule="nonzero"
                      d="M10,8.79200422 L18.7920042,0 L20,1.20799578 L11.2079958,10 L20,18.7920042 L18.7920042,20 L10,11.2079958 L1.20799578,20 L0,18.7920042 L8.79200422,10 L0,1.20799578 L1.20799578,0 L10,8.79200422 Z"></path>
                    <g>
                      <mask>
                        <path
                          d="M10,8.79200422 L18.7920042,0 L20,1.20799578 L11.2079958,10 L20,18.7920042 L18.7920042,20 L10,11.2079958 L1.20799578,20 L0,18.7920042 L8.79200422,10 L0,1.20799578 L1.20799578,0 L10,8.79200422 Z"></path>
                      </mask>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="mini-card-btns">
          <a
            aria-label="Go to cart"
            [routerLink]="{ cxRoute: 'cart' } | cxUrl"
            (click)="goToCart()"
            class="button-primary">
            {{ 'amAddToCart.viewCart' | cxTranslate }}
          </a>
          <a aria-label="close,continue shopping" (click)="close()" class="button-secondary">
            {{ 'amAddToCart.continueShopping' | cxTranslate }}
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
