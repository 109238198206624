<div class="reminder-container" [style]="'background:' + backgroudColor">
  <div class="reminder-content">
    <am-svg-container class="reminder-icon" [type]="iconType"></am-svg-container>
    <ng-content></ng-content>
  </div>

  <button *ngIf="showCtaButton" (click)="clickCtaBtnFunc()" class="reminder-button font-bold">
    {{ 'amCommon.undo' | cxTranslate }}
  </button>
</div>
