<button
  aria-label="toggle discount or bonus"
  type="button"
  id="{{ discountOrBonusRule.code }}"
  class="offer-title link"
  (click)="onPromotionClick()"
  aria-expanded="true">
  <span class="icon-arrow-down" *ngIf="multiple" [ngClass]="{ selected: isSelected }"></span>
  <div class="single-offer-title-label" [ngClass]="{ 'offer-title-label': multiple }" *ngIf="!isPotentialRule">
    {{ discountOrBonusRule.description }}
  </div>
  <div class="single-offer-title-label" [ngClass]="{ 'offer-title-label': multiple }" *ngIf="isPotentialRule">
    {{ discountOrBonusRule.code }}
  </div>
  <div class="icon-tooltip-right" *ngIf="isPotentialRule && discountOrBonusRule?.summary">
    <span class="earn-miles-icon">
      <img src="/assets/images/icons/earn-yellow.svg" alt="" />
    </span>
    <span class="earn-miles-tag-content-block" [innerHTML]="discountOrBonusRule?.summary"></span>
  </div>
</button>
<div class="earn-miles-offer-detail-row offer-detail toggle" [@toggle]="toggleState">
  <!-- offer period -->
  <div *ngIf="discountOrBonusRule.promotionPeriod; else promotionPeriod">
    <div class="offer-period-lable">{{ 'amProductDetails.promotionDetails.period' | cxTranslate }}</div>
    <div class="offer-period-content">{{ discountOrBonusRule.promotionPeriod }}</div>
  </div>
  <ng-template #promotionPeriod>
    <div *ngIf="discountOrBonusRule.startTime || discountOrBonusRule.endTime">
      <div class="offer-period-lable">{{ 'amProductDetails.promotionDetails.period' | cxTranslate }}</div>
      <div class="offer-period-content">
        {{ discountOrBonusRule.startTime | amDate : ('amProductDetails.timePattern' | cxTranslate) }} -
        {{ discountOrBonusRule.endTime | amDate : ('amProductDetails.timePattern' | cxTranslate) }}
      </div>
    </div>
  </ng-template>

  <!-- offer details -->
  <div class="details-label">{{ 'amProductDetails.offerDetails' | cxTranslate }}</div>
  <div class="detail-content" *ngIf="!isPotentialRule" [innerHTML]="discountOrBonusRule.descriptionAML"></div>
  <div class="detail-content" *ngIf="isPotentialRule" [innerHTML]="discountOrBonusRule.description"></div>

  <!-- terms -->
  <p class="terms-conditions" *ngIf="discountOrBonusRule.termsAndCondition">
    <a
      aria-label="Go to terms and conditions"
      class="font-bold"
      [href]="discountOrBonusRule.termsAndCondition"
      target="_blank">
      {{ 'amProductDetails.termsAndConditions' | cxTranslate }}
    </a>
  </p>
</div>
