import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import Player from '@vimeo/player';
import { MediaConfig, MediaState } from '../media-player.model';

@Component({
  selector: 'am-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent implements AfterViewInit, OnDestroy {
  @Input() mediaConfig: MediaConfig;
  @Input() onPlayStateChange: Function;

  @ViewChild('iframe')
  iframeElement: any;

  vimeoPlayer: any;

  constructor() {}

  addVimeoCallbacks(): void {
    if (!this.iframeElement || !this.iframeElement.nativeElement) {
      // console.log('play vimeo video error: the iframe isnot exist');
      return;
    }
    try {
      this.iframeElement.nativeElement.setAttribute(
        'allow',
        'accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture'
      );
      this.vimeoPlayer = new Player(this.iframeElement.nativeElement);
      this.vimeoPlayer.setMuted(true);
      this.vimeoPlayer.on('bufferstart', () => {
        // console.log('buffering');
        this.onPlayStateChange(MediaState.Buffering);
      });

      this.vimeoPlayer.on('play', () => {
        // console.log('playing');
        this.onPlayStateChange(MediaState.Playing);
      });

      this.vimeoPlayer.on('pause', () => {
        // console.log('pause');
        this.onPlayStateChange(MediaState.Paused);
      });

      this.vimeoPlayer.on('ended', () => {
        // console.log('end');
        this.onPlayStateChange(MediaState.Ended);
      });

      this.vimeoPlayer.on('error', (error) => {
        // this.vimeoPlayer.destroy()
        // this.addVimeoCallbacks()
        // console.log('error');
        this.onPlayStateChange(MediaState.VideoCued);
      });
    } catch (err) {
      // console.log('load the vimeo video error', err);
    }
  }

  startPlay(): void {
    // this.player.playVideo();
    this.vimeoPlayer.play();
  }

  ngAfterViewInit(): void {
    this.addVimeoCallbacks();
  }

  ngOnDestroy(): void {
    this.vimeoPlayer.destroy();
  }
}
