import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { AuthToken } from '@core/auth/user-auth/models/auth-token.model';
import { AmAuthHttpHeaderService } from '@core/auth/user-auth/services/am-auth-http-header.service';
import { AmAuthRedirectService } from '@core/auth/user-auth/services/am-auth-redirect.service';
import { AmOAuthLibWrapperService } from '@core/auth/user-auth/services/am-oauth-wrapper.service';
import { Store } from '@ngrx/store';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  AuthStorageService,
  GlobalMessageService,
  GlobalMessageType,
  InterceptorUtil,
  OccEndpointsService,
  RoutingService,
  StateWithClientAuth,
  USE_CUSTOMER_SUPPORT_AGENT_TOKEN
} from '@spartacus/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmAsmAuthHttpHeaderService extends AmAuthHttpHeaderService {
  // protected subscription = new Subscription();
  constructor(
    protected authService: AmAuthService,
    protected authStorageService: AuthStorageService,
    protected oAuthLibWrapperService: AmOAuthLibWrapperService,
    protected routingService: RoutingService,
    protected occEndpoints: OccEndpointsService,
    protected globalMessageService: GlobalMessageService,
    protected authRedirectService: AmAuthRedirectService,
    protected store: Store<StateWithClientAuth>,
    protected oAuthService: OAuthService,
    protected csAgentAuthService: CsAgentAuthService
  ) {
    super(
      authService,
      authStorageService,
      oAuthLibWrapperService,
      routingService,
      occEndpoints,
      globalMessageService,
      authRedirectService,
      store,
      oAuthService
    );
  }

  /**
   * Checks if the authorization header should be added to the request
   *
   *  @override
   */
  public shouldAddAuthorizationHeader(request: HttpRequest<any>): boolean {
    return super.shouldAddAuthorizationHeader(request) || this.isCSAgentTokenRequest(request);
  }

  /**
   * @override
   *
   * Checks if particular request should be handled by this service.
   */
  public shouldCatchError(request: HttpRequest<any>): boolean {
    return super.shouldCatchError(request) || this.isCSAgentTokenRequest(request);
  }

  /**
   * @override
   *
   * Adds `Authorization` header to occ and CS agent requests.
   * For CS agent requests also removes the `cx-use-csagent-token` header (to avoid problems with CORS).
   */
  public alterRequest(request: HttpRequest<any>, token?: AuthToken): HttpRequest<any> {
    const hasAuthorizationHeader = !!this.getAuthorizationHeader(request);
    const isCSAgentRequest = this.isCSAgentTokenRequest(request);

    let req = super.alterRequest(request, token);

    if (!hasAuthorizationHeader && isCSAgentRequest) {
      req = request.clone({
        setHeaders: {
          ...this.createAuthorizationHeader(token)
        }
      });
      return InterceptorUtil.removeHeader(USE_CUSTOMER_SUPPORT_AGENT_TOKEN, req);
    }
    return req;
  }

  protected isCSAgentTokenRequest(request: HttpRequest<any>): boolean {
    const isRequestWithCSAgentToken = InterceptorUtil.getInterceptorParam(
      USE_CUSTOMER_SUPPORT_AGENT_TOKEN,
      request.headers
    );
    return Boolean(isRequestWithCSAgentToken);
  }

  /**
   * @override
   *
   * On backend errors indicating expired `refresh_token` we need to logout
   * currently logged in user and CS agent.
   */
  public handleExpiredRefreshToken(): void {
    this.subscriptions.add(
      this.csAgentAuthService
        .isCustomerSupportAgentLoggedIn()
        .pipe(take(1))
        .subscribe((csAgentLoggedIn) => {
          if (csAgentLoggedIn) {
            this.authService.setLogoutProgress(true);
            this.csAgentAuthService.logoutCustomerSupportAgent();
            this.globalMessageService.add(
              {
                key: 'asm.csagentTokenExpired'
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          } else {
            super.handleExpiredRefreshToken();
          }
        })
    );
  }
}
