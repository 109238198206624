import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GenericLinkComponent, GenericLinkComponentService } from '@spartacus/storefront';
import { TealiumUtagService } from 'src/app/amiredeem/common/data-layer/tealium/utag.service';
/**
 * This component navigates using [routerLink] attribute when input 'url' is a relative url. Otherwise (when it's absolute), [href] is used.
 */
@Component({
  selector: 'am-generic-link',
  templateUrl: './generic-link.component.html'
})
export class AmGenericLinkComponent extends GenericLinkComponent {
  @Input() placement: string;
  @Input() elementPosition: string;
  @Input() name: string;
  @Input() pageName: string;
  @Input() type: string;
  @Input() title: string;
  constructor(
    protected router: Router,
    protected service: GenericLinkComponentService,
    protected tealiumUtagService: TealiumUtagService
  ) {
    super(router, service);
  }

  tealiumClickEvent() {
    let action =
      (this.type && this.makeupName(this.pageName) + '::' + this.type + '::CLICK') ||
      (this.title &&
        this.makeupName(this.pageName) +
          '::' +
          this.makeupName(this.title) +
          '::' +
          this.makeupName(this.name) +
          '_CARD::CLICK');
    if (!action) {
      return;
    }
    let tealium_event_data = {
      event: {
        event_category: 'USER-INTERACTIONS::CATHAYSHOP',
        event_action: action,
        event_label: this.makeupName(this.name),
        event_value: 1
      }
    };

    console.log(tealium_event_data, 'tealium_event_data');

    this.tealiumUtagService.link(tealium_event_data);
  }

  makeupName(name): string {
    return name && name.toUpperCase().replaceAll(' ', '_');
  }
}
