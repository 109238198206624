import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { SvgContainerModule } from '../svg-container/svg-container.module';
import { ReminderComponent } from './reminder.component';

@NgModule({
  declarations: [ReminderComponent],
  imports: [CommonModule, SvgContainerModule, I18nModule],
  exports: [ReminderComponent]
})
export class ReminderModule {}
