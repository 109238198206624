export const COMMON_FEATURE = 'am-common';

export interface StateWithAmCommon {
  [COMMON_FEATURE]: CommonState;
}

export interface CommonState {
  commonConfig: CommomConfigMap;
}
export interface CommomConfigMap {
  asmSessionTimeout?: number;
  asmSessionTimer?: number;
  cdsConsentCss?: string;
  cdsConsentJs?: string;
  ccsiEnable?: boolean;
  clientId?: String;
  dataLayerVersion?: string;
  trackingEnv?: string;
  env?: string;
  idpKeepAliveUrl?: string;
  idpLogoutUrl?: string;
  inflight?: boolean;
  mlcLoginUrl?: string;
  mlcLogoutUrl?: string;
  mlcv2KeepAliveUrl?: string;
  newChatContactUs?: string;
  newChatEnable?: boolean;
  veraChatEnable?: boolean;
  veraChatUrl?: string;
  veraChatJsUrl?: string;
  veraChatCssUrl?: string;
  loading?: boolean;
  flightInfoExpiredPeriod?: number;
  generalConditionUrl?: string;
  inflightSignUrl?: string;
  signUrl?: string;
  accountOverViewUrl?: string;
  glassBoxInflightIsOn?: boolean;
  glassBoxOnGroundIsOn?: boolean;
  glassBoxReportURL?: string;
  glassBoxScriptURI?: string;
  inflightOrderSummaryNoticeTime?: number;
  notifySwitch?: boolean;
  inflightOtpToggle?: boolean;
}
