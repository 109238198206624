import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStorageService } from '@spartacus/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AmAuthConfigService } from '../services/am-auth-config.service';
import { AmAuthHttpHeaderService } from '../services/am-auth-http-header.service';

@Injectable({ providedIn: 'root' })
export class AmTokenRefreshmentInterceptor implements HttpInterceptor {
  constructor(
    protected authStorageService: AuthStorageService,
    protected authConfigService: AmAuthConfigService,
    protected amAuthHttpHeaderService: AmAuthHttpHeaderService,
    protected oAuthService: OAuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isTokenRefreshRequest = this.isTokenRefreshRequest(request);
    return this.authStorageService.getToken().pipe(
      take(1),
      switchMap((token) => {
        if (isTokenRefreshRequest && token?.sessionTokenIdentifier) {
          request = request.clone({
            setHeaders: {
              sessionTokenIdentifier: `${token.sessionTokenIdentifier}`
            }
          });
        }
        return next.handle(request);
      })
    );
  }

  protected isTokenRefreshRequest(request: HttpRequest<any>): boolean {
    return request.url === this.oAuthService.tokenEndpoint;
  }
}
