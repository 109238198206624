import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { SafeCodeModule } from '@utils/pipe/safe-code/safe-code.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';

@NgModule({
  declarations: [TermsAndConditionsComponent],
  exports: [TermsAndConditionsComponent],
  imports: [CommonModule, I18nModule, DateTimeModule, SafeCodeModule]
})
export class TermsAndConditionsModule {}
