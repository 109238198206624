import { Provider } from '@angular/core';
import { HttpErrorHandler } from '@spartacus/core';
import { AmBadGatewayHandler } from './bad-gateway/bad-gateway.handler';
import { AmBadRequestHandler } from './bad-request/bad-request.handler';
import { AmConflictHandler } from './conflict/conflict.handler';
import { AmForbiddenHandler } from './forbidden/forbidden.handler';
import { AmGatewayTimeoutHandler } from './gateway/gateway-timeout.handler';
import { AmInternalServerErrorHandler } from './internal-server/internal-server.handler';
import { AmNotFoundHandler } from './not-found/not-found.handler';
import { AmUnknownErrorHandler } from './unknow-error/unknown-error.handler';

export const errorHandlers: Provider[] = [
  {
    provide: HttpErrorHandler,
    useExisting: AmBadGatewayHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmBadRequestHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmConflictHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmForbiddenHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmGatewayTimeoutHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmInternalServerErrorHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmNotFoundHandler,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: AmUnknownErrorHandler,
    multi: true
  }
];
