import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, UrlModule } from '@spartacus/core';
import { DateTimeModule } from '@utils/pipe/dateTimeHelper/date-time.module';
import { ProductVariantSizeSelectorComponent } from './product-variant-size-selector.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    DateTimeModule
  ],
  declarations: [ProductVariantSizeSelectorComponent],
  exports: [ProductVariantSizeSelectorComponent]
})
export class ProductVariantSizeSelectorModule {}
