import { Action } from '@ngrx/store';
import { FavoriteProduct } from '../../services/favorite-notify-products.service';

export const ADD_PRODUCT_INTO_FAVORITE_LIST = '[AM FAVORITE AND NOTIFY PRODUCT] Add Product Into Notify List';
export const ADD_PRODUCT_INTO_FAVORITE_LIST_FAIL = '[AM FAVORITE AND NOTIFY PRODUCT] Add Product Into Notify List Fail';
export const ADD_PRODUCT_INTO_NOTIFY_LIST = '[AM NOTIFY AND NOTIFY PRODUCT] Add Product Into Notify List';
export const ADD_PRODUCT_INTO_NOTIFY_LIST_FAIL = '[AM NOTIFY AND NOTIFY PRODUCT] Add Product Into Notify List Fail';
export const REMOVE_PRODUCT_FROM_FAVORITE_LIST = '[AM NOTIFY AND NOTIFY PRODUCT] Remove Product From Notify List ';
export const REMOVE_PRODUCT_FROM_FAVORITE_LIST_FAIL =
  '[AM NOTIFY AND NOTIFY PRODUCT] Remove Product From Notify List Fail';
export const REMOVE_PRODUCT_FROM_NOTIFY_LIST = '[AM NOTIFY AND NOTIFY PRODUCT] Remove Product From Notify List';
export const REMOVE_PRODUCT_FROM_NOTIFY_LIST_FAIL =
  '[AM NOTIFY AND NOTIFY PRODUCT] Remove Product From Notify List Fail';
export const SET_ACTIVE_TYPE = '[AM FAVORITE AND NOTIFY PRODUCT] Set Active Type';

export const LOAD_FAVORITY_AND_NOTIFY_PRODUCT_LIST_PAGE_DATA =
  '[AM FAVORITE AND NOTIFY PRODUCT] Load Favorite and Notify Product Data';
export const SAVE_FAVORITY_AND_NOTIFY_PRODUCT_LIST_PAGE_DATA =
  '[AM FAVORITE AND NOTIFY PRODUCT] Save Favorite and Notify Product Data';
export const REMOVE_FAVORITY_AND_NOTIFY_PRODUCT_LIST_DATA =
  '[AM FAVORITE AND NOTIFY PRODUCT] Remove Favorite and Notify Product Data';

export class SetActiveType implements Action {
  readonly type = SET_ACTIVE_TYPE;
  constructor(
    public payload: {
      activeType?: string;
    }
  ) {
    this.payload = payload;
  }
}

export class AddProductIntoFavoriteList implements Action {
  readonly type = ADD_PRODUCT_INTO_FAVORITE_LIST;
  constructor(
    public payload: {
      productCode?: string;
      sortCode?: string;
    }
  ) {
    this.payload = payload;
  }
}

export class AddProductIntoFavoriteListFail implements Action {
  readonly type = ADD_PRODUCT_INTO_FAVORITE_LIST_FAIL;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

export class AddProductIntoNotifyList implements Action {
  readonly type = ADD_PRODUCT_INTO_NOTIFY_LIST;
  constructor(
    public payload: {
      productCode?: string;
      sortCode?: string;
    }
  ) {
    this.payload = payload;
  }
}

export class AddProductIntoNotifyListFail implements Action {
  readonly type = ADD_PRODUCT_INTO_NOTIFY_LIST_FAIL;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

export class RemoveProductFromFavoriteList implements Action {
  readonly type = REMOVE_PRODUCT_FROM_FAVORITE_LIST;
  constructor(
    public payload: {
      productCode?: string;
      sortCode?: string;
      removeAllVariantProducts?: boolean;
    }
  ) {
    this.payload = payload;
  }
}

export class RemoveProductFromFavoriteListFail implements Action {
  readonly type = REMOVE_PRODUCT_FROM_FAVORITE_LIST_FAIL;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

export class RemoveProductFromNotifyList implements Action {
  readonly type = REMOVE_PRODUCT_FROM_NOTIFY_LIST;
  constructor(
    public payload: {
      productCode?: string;
      sortCode?: string;
    }
  ) {}
}

export class RemoveProductFromNotifyListFail implements Action {
  readonly type = REMOVE_PRODUCT_FROM_NOTIFY_LIST_FAIL;
  constructor(public payload: any) {}
}

export class LoadFavoriteAndNotifyProducts implements Action {
  readonly type = LOAD_FAVORITY_AND_NOTIFY_PRODUCT_LIST_PAGE_DATA;
  constructor(
    public payload: {
      activeType?: string;
      sortCode?: string;
    }
  ) {}
}

export class SaveFavoriteAndNotifyProducts implements Action {
  readonly type = SAVE_FAVORITY_AND_NOTIFY_PRODUCT_LIST_PAGE_DATA;
  constructor(
    public payload: {
      activeType?: string;
      displayEntries?: FavoriteProduct[];
      favoriteEntries?: FavoriteProduct[];
      notifyMeEntries?: FavoriteProduct[];
    }
  ) {
    this.payload = payload;
  }
}

export class RemoveFavoriteAndNotifyProducts implements Action {
  readonly type = REMOVE_FAVORITY_AND_NOTIFY_PRODUCT_LIST_DATA;
  constructor(public payload: {}) {}
}

export type FavoriteAndNotifyProductListActions =
  | SetActiveType
  | AddProductIntoFavoriteList
  | AddProductIntoFavoriteListFail
  | AddProductIntoNotifyList
  | RemoveProductFromFavoriteList
  | RemoveProductFromNotifyList
  | LoadFavoriteAndNotifyProducts
  | SaveFavoriteAndNotifyProducts
  | RemoveFavoriteAndNotifyProducts;
