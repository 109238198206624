<ng-container *ngIf="showDeliveryOptions">
  <div class="link-up-section">
    <span class="title-text">{{ 'amProductDetails.collectionMethod' | cxTranslate }}</span>

    <div class="collection-method-option">
      <ng-container *ngFor="let item of relationShipArray">
        <button
          class="inflight-button button-comm"
          (click)="switchCollectionMethod(item.code)"
          [ngClass]="{ green: currentCollectionMethod === item.collectionMethod }"
          [disabled]="
            !this.flightStatusStorageService.isInflightPickFlow && item.collectionMethod === 'INFLIGHT_PICKUP'
          ">
          <div class="svg-style">
            <am-svg-container
              [type]="currentCollectionMethod === item.collectionMethod ? item.selectedIcon : item.icon"
              [style]="style_cart_cathay_gray"
              [svgDescription]="item.svgDescription"></am-svg-container>
          </div>
          <div class="button-text">
            <div class="fullname-text" [ngClass]="{ green: currentCollectionMethod === item.collectionMethod }">
              {{ item.fulfillmentName }}
            </div>
          </div>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
