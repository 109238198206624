import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { AmAddToCartDialogModule } from 'src/app/amiredeem/pages/product-detail/component/add-to-cart/add-to-cart-dialog/add-to-cart-dialog.module';
import { OrderSummaryNoticeModule } from '../../../../pages/checkout/components/am-inflight-pickup-order-confirmation/order-summary-notice/order-summary-notice.module';
import { AmMiniCartComponent } from './mini-cart.component';

@NgModule({
  declarations: [AmMiniCartComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    AmAddToCartDialogModule,
    OrderSummaryNoticeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          // typeCode
          component: AmMiniCartComponent
        }
      }
    } as CmsConfig)
  ]
})
export class AmMiniCartModule {}
