export enum CollectionMethodType {
  SHOP_PICKUP = 'SHOP_PICKUP',
  REDEMPTION_LETTER = 'HOME_DELIVERY',
  HOME_DELIVERY = 'HOME_DELIVERY',
  HOMEDELIVERY_GROUPSHIPMENT = 'HOME_DELIVERY',
  HOMEDELIVERY_IMMEDIATESHIPMENT = 'HOME_DELIVERY',
  HOMEDELIVERY_MERCHANTDELIVERY = 'HOME_DELIVERY',
  EMAIL_DELIVERY = 'EMAIL_DELIVERY',
  EVOUCHER_QR = 'EMAIL_DELIVERY',
  EVOUCHER_QR_THIRDPARTY = 'EMAIL_DELIVERY',
  EVOUCHER_BAR = 'EMAIL_DELIVERY',
  EVOUCHER_URL = 'EMAIL_DELIVERY',
  EVOUCHER_SMS = 'EMAIL_DELIVERY',
  EVOUCHER_AN = 'EMAIL_DELIVERY',
  INFLIGHT_PICKUP = 'INFLIGHT_PICKUP'
}

export enum CollectionMethod {
  SHOP_PICKUP = 'SHOP_PICKUP',
  REDEMPTION_LETTER = 'REDEMPTION_LETTER',
  HOME_DELIVERY = 'HOME_DELIVERY',
  HOMEDELIVERY_GROUPSHIPMENT = 'HOMEDELIVERY_GROUPSHIPMENT',
  HOMEDELIVERY_IMMEDIATESHIPMENT = 'HOMEDELIVERY_IMMEDIATESHIPMENT',
  HOMEDELIVERY_MERCHANTDELIVERY = 'HOMEDELIVERY_MERCHANTDELIVERY',
  EMAIL_DELIVERY = 'EMAIL_DELIVERY',
  EVOUCHER_QR = 'EVOUCHER_QR',
  EVOUCHER_QR_THIRDPARTY = 'EVOUCHER_QR_THIRDPARTY',
  EVOUCHER_BAR = 'EVOUCHER_BAR',
  EVOUCHER_URL = 'EVOUCHER_URL',
  EVOUCHER_SMS = 'EVOUCHER_SMS',
  EVOUCHER_AN = 'EVOUCHER_AN',
  INFLIGHT_PICKUP = 'INFLIGHT_PICKUP'
}
