import { Country } from '@spartacus/core/src/model';
import { CountriesActions } from '../actions/index';
import { CountriesState } from '../state';

export const initialState: CountriesState = {
  entities: null,
  activeCountry: null
};

export function reducer(state = initialState, action: CountriesActions.CountriesAction): CountriesState {
  switch (action.type) {
    case CountriesActions.LOAD_COUNTRIES_SUCCESS: {
      const countries: Country[] = action.payload;
      const entities = countries.reduce(
        (countryEntities: { [isocode: string]: Country }, country: Country) => {
          return {
            ...countryEntities,
            [country.isocode]: country
          };
        },
        {
          ...state.entities
        }
      );

      return {
        ...state,
        entities
      };
    }

    case CountriesActions.SET_ACTIVE_COUNTRY: {
      const isocode = action.payload;
      return {
        ...state,
        activeCountry: isocode
      };
    }
  }
  return state;
}
