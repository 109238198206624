import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { SemanticPathService, WindowRef } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { AmOccCartValidationAdapter } from '../occ/am-occ-cart-validation.adapter';

@Injectable({
  providedIn: 'root'
})
export class AmCartValidationGuard implements CanActivate {
  constructor(
    protected semanticPathService: SemanticPathService,
    protected router: Router,
    protected winRef: WindowRef,
    protected amOccCartValidationAdapter: AmOccCartValidationAdapter
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return of(true);
    // return this.activeCartService.validateCart().pipe(
    //   map((val) => {
    //     if (val.msgCode === CheckoutPreCheckMsgCode.MOBILE_VERIFY_REDIRECT) {
    //       return (this.winRef.location.href = val.verifyMobileUrl);
    //     } else if (val.msgCode !== CheckoutPreCheckMsgCode.SUCCESS) {
    //       this.activeCartService.reloadActiveCart();
    //       return this.router.parseUrl(this.semanticPathService.get('cart'));
    //     }
    //     return true;
    //   })
    // );
  }
}
