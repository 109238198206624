import { Injectable } from '@angular/core';
import { FIRST_VISIT_COOKIE_NAME } from '@components/layout/globalPopup/contants/global-popup.contansts';
import { select } from '@ngrx/store';
import { Language, LanguageService, SiteContextActions, SiteContextSelectors } from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AmLanguageService extends LanguageService {
  getSortedLanguage(): Observable<Language[]> {
    return this.getAll().pipe(
      map((languages) => {
        let sortedIosCodes = ['en', 'zh_TW', 'zh', 'ja', 'ko'];

        return [...languages].sort((language1, language2) => {
          return sortedIosCodes.indexOf(language1.isocode) - sortedIosCodes.indexOf(language2.isocode);
        });
      })
    );
  }
  protected subscription = new Subscription();
  setActive(isocode: string): void {
    this.subscription.add(
      this.store.pipe(select(SiteContextSelectors.getActiveLanguage), take(1)).subscribe((activeLanguage) => {
        this.setCookie(isocode);
        if (activeLanguage !== isocode && this.isValid(isocode)) {
          this.store.dispatch(new SiteContextActions.SetActiveLanguage(isocode));
        }
      })
    );
  }

  setCookie(isocode) {
    if (
      CookiesHelper.getCookie(FIRST_VISIT_COOKIE_NAME) &&
      CookiesHelper.getCookie(FIRST_VISIT_COOKIE_NAME) !== isocode
    ) {
      CookiesHelper.setCookie(FIRST_VISIT_COOKIE_NAME, isocode);
    }
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
