import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorHandler, HttpResponseStatus, Priority } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class AmNotFoundHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.NOT_FOUND;

  handleError(_request: HttpRequest<any>, errorResponse: HttpErrorResponse): void {
    if (isDevMode() || this.isSsr()) {
      console.warn(`An http 404 error occurred\n`, errorResponse.message);
    }
  }

  getPriority(): Priority {
    return Priority.NORMAL;
  }
}
