import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { BalanceErrorModule } from './balance-error/balance-error.module';
import { GeneralErrorModule } from './general-error/general-error.module';
import { UnavailableInflightProductModule } from './unavailable-inflight-product/unavailable-inflight-product.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, I18nModule, GeneralErrorModule, BalanceErrorModule, UnavailableInflightProductModule]
})
export class ErrorPageModule {}
