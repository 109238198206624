import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PendingOrderPopupService } from '@components/content/pending-order-popup/pending-order-popup.service';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
// TODO:Spartacus - Many methods from 'UserService' were removed, for more details please look into 4.0 migration documentation.
import { AuthRedirectStorageService, RoutingService, WindowRef } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AmConfigService } from 'src/app/amiredeem/common/config/am-config.service';
import { OrderListService } from 'src/app/amiredeem/pages/order-list/service/order-list.service';
import { svgConfig } from '../../../../config/am-svg-sprite.config';
import { AmlHeaderService } from '../service/aml-header.service';

@Component({
  selector: 'am-site-login',
  templateUrl: './am-site-login.component.html'
})
export class AmSiteLoginComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  hadLogin: boolean = false;
  showUserInfo: boolean = false;

  signUrl = '';
  accountOverveiwUrl = '';

  svgType = svgConfig;
  style_user_info = ' width: 24px; height: 24px;';
  style_arrow_down = 'width: 13px; height: 13px;';
  timer: NodeJS.Timeout;

  externalLinksSub: Subscription;

  constructor(
    protected authService: AmAuthService,
    private userAccountFacade: UserAccountFacade,
    protected routingService: RoutingService,
    protected amlHeaderService: AmlHeaderService,
    private orderListService: OrderListService,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected pendingOrderPopupService: PendingOrderPopupService,
    private cdr: ChangeDetectorRef,
    protected amConfigService: AmConfigService,
    protected winRef: WindowRef
  ) {
    this.logout = this.logout.bind(this);
    this.getExternalLinks();
  }
  protected subscription = new Subscription();
  getExternalLinks() {
    this.externalLinksSub = combineLatest([
      this.amConfigService.getCommonConfig(),
      this.amConfigService.getInflightEnvObs
    ]).subscribe(([externalLinks, isInflightObject]) => {
      if (!!isInflightObject && !!externalLinks) {
        this.signUrl = isInflightObject.data ? externalLinks.inflightSignUrl : externalLinks.signUrl;
        this.accountOverveiwUrl = externalLinks.accountOverViewUrl;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.hadLogin = true;
          return this.userAccountFacade.get();
        } else {
          this.hadLogin = false;
          return of(undefined);
        }
      })
    );
  }

  loginWithRedirect(): void {
    this.authService.loginWithRedirect();
  }

  logout(): void {
    this.authService.coreLogout();
  }

  showAccountInfo(): void {
    this.amlHeaderService.toggleAccountInfo();
    this.showUserInfo = !this.showUserInfo;
  }

  get isShowAccountInfo(): Observable<boolean> {
    return this.amlHeaderService.isShowAccountInfo;
  }

  // mouseenter(): void {
  //   this.showUserInfo = true;
  // }

  // mouseLeave(): void {
  //   this.showUserInfo = false;
  // }

  focusLoginInfoSec(e): void {
    // this.showUserInfo = true;
    const amInfo = this.winRef.document?.querySelector('.am-login-user-info');
    // amInfo.style.display = 'block';
    if (amInfo.classList.contains('show')) {
      amInfo.classList.remove('show');
      this.showUserInfo = false;
    } else {
      amInfo.classList.add('show');
      this.showUserInfo = true;
    }
    this.winRef.isBrowser() &&
      setTimeout(() => {
        const arrowLinks: any = this.winRef.document?.querySelectorAll('.account-overview-link');
        if (arrowLinks && arrowLinks.length > 0) {
          arrowLinks[0].focus();
        }
      }, 300);
  }

  // enterLoginInfoSec(): void {
  //   const amInfo = this.document.nativeElement.querySelector('.am-login-user-info');
  //   amInfo.classList.add('show');
  //   this.showUserInfo = true;
  // }

  // leaveLoginInfoSec(): void {
  //   const amInfo = this.document.nativeElement.querySelector('.am-login-user-info');
  //   amInfo.classList.remove('show');
  //   this.showUserInfo = false;
  // }

  blurLoginInfoSec(): void {
    this.showUserInfo = false;
    const amInfo = this.winRef.document.querySelector('.am-login-user-info');
    amInfo.classList.remove('show');
  }

  focusBackToCloseBtn(): void {
    const btnArr = this.winRef.document.querySelector('button');
    btnArr.focus();
  }

  goOrders(): void {
    this.orderListService.previousLanguage = null;
    this.orderListService.previousCountry = null;
    this.routingService.go({
      cxRoute: 'orders'
    });

    const amInfo = this.winRef.document?.querySelector('.am-login-user-info');
    if (amInfo.classList.contains('show')) {
      amInfo.classList.remove('show');
    }
  }

  goMyList(): void {
    this.routingService.go({
      cxRoute: 'wishList'
    });

    const amInfo = this.winRef.document?.querySelector('.am-login-user-info');
    if (amInfo.classList.contains('show')) {
      amInfo.classList.remove('show');
    }
  }

  OnAccountOverviewClick() {
    const amInfo = this.winRef.document?.querySelector('.am-login-user-info');
    if (amInfo.classList.contains('show')) {
      amInfo.classList.remove('show');
    }
  }

  ngOnDestroy(): void {
    this.timer && clearTimeout(this.timer);
    this.externalLinksSub && this.externalLinksSub.unsubscribe();
  }
}
