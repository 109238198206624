import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart, CART_VOUCHER_NORMALIZER } from '@spartacus/cart/base/root';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class AmOccCartVoucherAdapter {
  static voucherError$ = new Subject();

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  add(userId: string, cartId: string, voucherId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('cartVoucher', {
      urlParams: { userId, cartId, voucherId }
    });

    const toAdd = JSON.stringify({});

    const params: HttpParams = new HttpParams().set('voucherId', voucherId);

    return this.http.post(url, toAdd, { params }).pipe(
      map((data: Cart) => {
        if (data.errorMeg) {
          if (data.errorMeg?.exceptionMessage) {
            AmOccCartVoucherAdapter.voucherError$.next(data.errorMeg);
            throw data.errorMeg;
            // return new AmCartActions.AmCartAddVoucherError({ ...payload, error: data.errorMeg })
          }
          // else {
          //   AmOccCartVoucherAdapter.voucherError$.next(null);
          // }
        }
        return data;
      }),
      catchError((error) => {
        if (error.exceptionMessage) {
          const that = this;
          setTimeout(() => {
            AmOccCartVoucherAdapter.voucherError$.next(error);
          }, 100);
          // return new AmCartActions.AmCartAddVoucherError({ ...payload, error: data.errorMeg })
        } else {
          AmOccCartVoucherAdapter.voucherError$.next(null);
        }
        return throwError(error);
      }),
      this.converter.pipeable(CART_VOUCHER_NORMALIZER)
    );
  }

  remove(userId: string, cartId: string, voucherId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('cartRemoveVoucher', {
      urlParams: { userId, cartId, voucherId }
    });

    return this.http.delete(url, {}).pipe(catchError((error: Cart) => throwError(error)));
  }
}
