import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AsmAuthService } from '@spartacus/asm/root';
import { CdsConfig } from '@spartacus/cds';
import { LanguageService, OccEndpointsService, UserIdService } from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ConsentRecord, ConsentTemplate, CONSENT_STATUS } from '../model/consent.model';
import { AmConsentsActions } from '../store/actions';
import { StateWithAmConsents } from '../store/am-consents-state';
import { AmConsentsSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root'
})
export class AmConsentService {
  protected subscription = new Subscription();
  constructor(
    protected authService: AsmAuthService,
    protected cdsConfig: CdsConfig,
    protected languageService: LanguageService,
    protected occEndpointSvc: OccEndpointsService,
    protected userIdService: UserIdService,
    protected httpClient: HttpClient,
    protected store: Store<StateWithAmConsents>
  ) {}

  getTemplates() {
    return this.store.pipe(
      select(AmConsentsSelectors.getAmConsentsTemplateState),
      filter((_templates) => {
        return _templates.templates?.length <= 0 || !this.checkCdsConsentWhenAcceptedInCookieBar(_templates.consents);
      }),
      tap(() => {
        this.store.dispatch(new AmConsentsActions.LoadConsentTemplates());
      })
    );
  }

  // if we have click the accept in the cookie bar while the consent record isn't Given, return false
  // the others, return true
  checkCdsConsentWhenAcceptedInCookieBar(consents: ConsentRecord[]) {
    const cdsConsentRecords = consents.filter(
      (template) => template.templateCode === this.cdsConfig.cds.consentTemplateId
    );
    const hasAcceptedInCookieBar = this.hasAcceptedInCookieBar();
    if (cdsConsentRecords && cdsConsentRecords.length > 0) {
      if (hasAcceptedInCookieBar && cdsConsentRecords[0].consentState !== CONSENT_STATUS.GIVEN) {
        return false;
      }
    }
    return true;
  }

  // when we click the accept button, these two aml_cc keys should appear in cookie bar
  hasAcceptedInCookieBar(): boolean {
    const amlCookiesKey = ['aml_cc_advertising', 'aml_cc_functionality', 'aml_cc_necessary', 'aml_cc_performance'];
    const amlCookies = amlCookiesKey.filter((item) => {
      return !!CookiesHelper.getCookie(item);
    });
    if (amlCookies.length === amlCookiesKey.length) {
      return true;
    }
    return false;
  }

  getConsentByTemplateId(templateCode) {
    return this.store.pipe(select(AmConsentsSelectors.getConsentByTemplateId(templateCode)));
  }

  giveAllConsents() {
    const templates = this.store.pipe(select(AmConsentsSelectors.getConsentTemplates));
    templates
      ?.subscribe((templatesData) => {
        templatesData.forEach((template) => {
          this.givenConsent(template.id);
        });
      })
      .unsubscribe();
  }

  givenConsent(templateCode: string) {
    this.store.dispatch(new AmConsentsActions.GivenConsent({ templateCode }));
  }

  giveCdsConsent(): void {
    let userId = '';
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    const consentUrl = this.occEndpointSvc.buildUrl('givenProfileConsent', {
      urlParams: { userId }
    });
    this.subscription.add(
      this.httpClient.post<any>(consentUrl, {}).subscribe(() => {
        const templateId = this.cdsConfig.cds.consentTemplateId;
        this.givenConsent(templateId);
      })
    );
  }

  withdrawnCdsConsent(): void {
    const templateId = this.cdsConfig.cds.consentTemplateId;
    this.withdrawnConsent(templateId);
  }

  withdrawnConsent(templateCode: string) {
    this.store.dispatch(new AmConsentsActions.WithdrawnConsent({ templateCode }));
  }

  isConsentGiven(consent: ConsentRecord): boolean {
    return Boolean(consent) && Boolean(consent.consentState === CONSENT_STATUS.GIVEN);
  }

  generateConsents(consentTemplates: Array<ConsentTemplate>) {
    const consents: Array<ConsentRecord> = [];
    const hasAcceptedInCookieBar = this.hasAcceptedInCookieBar();
    consentTemplates.forEach((consentTemplate) => {
      const consent: ConsentRecord = {
        templateCode: consentTemplate.id,
        templateVersion: consentTemplate.version,
        consentState: hasAcceptedInCookieBar && consentTemplate.currentConsent ? CONSENT_STATUS.GIVEN : null
      };
      consents.push(consent);
    });

    return consents;
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
