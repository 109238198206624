<button
  *ngIf="multiple"
  aria-label="toggle free gift promotion"
  type="button"
  id="{{ freeGiftPromotion.code }}"
  class="offer-title link"
  (click)="onPromotionClick()"
  aria-expanded="true">
  <span class="icon-arrow-down" *ngIf="multiple" [ngClass]="{ selected: isSelected }"></span>
  <div class="offer-title-label">{{ freeGiftPromotion.code }}</div>
</button>

<div class="gift-offer-container" [ngClass]="{ multiple: multiple }" [@toggle]="toggleState">
  <div class="gift-offer-name" *ngIf="!multiple">{{ freeGiftPromotion.title }}</div>
  <div class="offer-period">
    <span class="title">{{ 'freeGift.offerPeriod' | cxTranslate }}</span>
    <div class="content">
      {{ freeGiftPromotion.startTime | amDate : ('amProductDetails.timePattern' | cxTranslate) }} -
      {{ freeGiftPromotion.endTime | amDate : ('amProductDetails.timePattern' | cxTranslate) }}
    </div>
  </div>
  <div class="offer-details">
    <span class="title">{{ 'freeGift.offerDetails' | cxTranslate }}</span>
    <div class="content" [innerHtml]="freeGiftPromotion.description"></div>
  </div>
  <div class="gift-details">
    <div class="title">{{ 'freeGift.giftDetails' | cxTranslate }}</div>

    <div class="content">
      <div class="product-img">
        <am-media [container]="freeGiftPromotion.freeGiftProduct.images" tabindex="0" format="zoom"> </am-media>
      </div>
      <div class="product-info">
        <div class="brand-name font-bold">{{ freeGiftPromotion.freeGiftProduct.brandName }}</div>
        <div class="product-name">{{ freeGiftPromotion.freeGiftProduct.name }}</div>

        <ng-container *ngIf="freeGiftPromotion?.freeGiftProduct?.responseInfo?.globalMessages?.accErrorMsgs">
          <ng-container
            *ngFor="let errorMsg of freeGiftPromotion.freeGiftProduct.responseInfo.globalMessages.accErrorMsgs">
            <p class="error-msg" *ngIf="errorMsg.code === 'text.product.detail.page.variant.out.of.stock'">
              {{ 'freeGift.outOfStock' | cxTranslate }}
            </p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="freeGiftPromotion?.freeGiftProduct?.stock?.stockLevelStatus === 'lowStock'">
          <p class="notice-msg">
            {{ 'amProductItem.lowStock' | cxTranslate }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="overview">
    <div class="title font-bold">{{ 'freeGift.overview' | cxTranslate }}</div>
    <div class="overview-content" [ngClass]="{ 'show-all': showState === 'more' }">
      <div [innerHtml]="freeGiftPromotion.freeGiftProduct.description"></div>
      <div [innerHtml]="freeGiftPromotion.freeGiftProduct.howToRedeem"></div>
      <div [innerHtml]="freeGiftPromotion.freeGiftProduct.termsAndconditions"></div>
      <!-- </div> -->

      <!-- <div [@lessOrMore]="showState"> -->
      <div class="expected-delivery-time" *ngIf="freeGiftPromotion.freeGiftProduct.productDeliveryTime.deliveryEndDate">
        <div class="title font-bold">{{ 'freeGift.expectedDeliveryTime' | cxTranslate }}</div>
        <div class="content">
          <am-delivery-period
            [isFreeGiftProduct]="true"
            [freeGiftProduct]="freeGiftPromotion.freeGiftProduct"></am-delivery-period>
        </div>
      </div>
      <div class="delivery-method">
        <div class="title font-bold">{{ 'freeGift.deliveryMethod' | cxTranslate }}</div>
        <div class="content">
          <ng-container *ngIf="freeGiftPromotion.freeGiftProduct.collectionMethodTextForDisplay">
            {{ freeGiftPromotion.freeGiftProduct.collectionMethodTextForDisplay }}
          </ng-container>
          <ng-container
            *ngIf="
              freeGiftPromotion.freeGiftProduct.collectionMethod &&
              !freeGiftPromotion.freeGiftProduct.collectionMethodTextForDisplay
            ">
            {{ freeGiftPromotion.freeGiftProduct.fulfillmentName }}
          </ng-container>
        </div>
      </div>
      <div class="merchant">
        <div class="title font-bold">{{ 'freeGift.merchant' | cxTranslate }}</div>
        <div class="content">
          <span class="product-partner-name">{{
            freeGiftPromotion.freeGiftProduct.productPartnerInfo?.partnerCompanyName
          }}</span>
          <span
            class="product-partner-email"
            *ngIf="freeGiftPromotion.freeGiftProduct.productPartnerInfo.partnerEmail"
            [innerHTML]="freeGiftPromotion.freeGiftProduct.productPartnerInfo.partnerEmail | safeHtml"></span>
          <span
            class="product-partner-tele"
            *ngIf="freeGiftPromotion.freeGiftProduct.productPartnerInfo.partnerTelephone"
            >{{ freeGiftPromotion.freeGiftProduct.productPartnerInfo.partnerTelephone }}</span
          >
          <span
            class="product-partner-remarks"
            *ngIf="freeGiftPromotion.freeGiftProduct.productPartnerInfo.partnerRemark"
            >{{ freeGiftPromotion.freeGiftProduct.productPartnerInfo.partnerRemark }}</span
          >
        </div>
      </div>
      <div
        class="delivery-service"
        *ngIf="
          freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerCompanyName ||
          freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerEmail ||
          freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerTelephone ||
          freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerRemark
        ">
        <div class="title font-bold">{{ 'freeGift.deliveryService' | cxTranslate }}</div>
        <div class="content">
          <span class="delivery-partner-name">{{
            freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerCompanyName
          }}</span>
          <span
            class="delivery-partner-email"
            *ngIf="freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerEmail"
            [innerHTML]="freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo.partnerEmail | safeHtml"></span>
          <span
            class="delivery-partner-tel"
            *ngIf="freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerTelephone"
            >{{ freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerTelephone }}</span
          >
          <span
            class="delivery-partner-remarks"
            *ngIf="freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerRemark"
            >{{ freeGiftPromotion.freeGiftProduct.deliveryPartnerInfo?.partnerRemark }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <button *ngIf="showState === 'more'" class="view-less font-bold" (click)="onLessOrMoreClick()">
    {{ 'freeGift.viewLess' | cxTranslate }}
  </button>
  <button *ngIf="showState === 'less'" class="view-more font-bold" (click)="onLessOrMoreClick()">
    {{ 'freeGift.viewMore' | cxTranslate }}
  </button>
  <div class="offer-terms-conditions font-bold" *ngIf="freeGiftPromotion.termsAndCondition">
    <a [href]="freeGiftPromotion.termsAndCondition" target="_blank">{{ 'freeGift.termsConditions' | cxTranslate }}</a>
  </div>
</div>
