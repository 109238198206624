import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { OrderSummaryNoticeComponent } from './order-summary-notice.component';

@NgModule({
  imports: [CommonModule, I18nModule, RouterModule, UrlModule, SvgContainerModule],
  exports: [OrderSummaryNoticeComponent],
  declarations: [OrderSummaryNoticeComponent]
})
export class OrderSummaryNoticeModule {}
