import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgContainerModule } from '@components/content/svg-container/svg-container.module';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { AmToolTipComponent } from './am-tool-tip.component';
import { AmToolTipDirective } from './am-tool-tip.directive';

@NgModule({
  declarations: [AmToolTipComponent, AmToolTipDirective],
  imports: [CommonModule, RouterModule, KeyboardFocusModule, SvgContainerModule, IconModule],
  exports: [AmToolTipComponent, AmToolTipDirective]
})
export class AmToolTipModule {}
