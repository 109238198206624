import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  ConfigInitializerService,
  getContextParameterDefault,
  LanguageInitializer,
  LanguageService,
  LanguageStatePersistenceService,
  LANGUAGE_CONTEXT_ID,
  SiteContextConfig
} from '@spartacus/core';
import { SITE_CONTEXT_FROM_COOKIE } from 'server-token';

@Injectable({ providedIn: 'root' })
export class AmLanguageInitializer extends LanguageInitializer {
  constructor(
    protected languageService: LanguageService,
    protected languageStatePersistenceService: LanguageStatePersistenceService,
    protected configInit: ConfigInitializerService,
    @Optional() @Inject(SITE_CONTEXT_FROM_COOKIE) protected siteContextFromCookie,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {
    super(languageService, languageStatePersistenceService, configInit);
  }

  protected setDefaultFromConfig(config: SiteContextConfig): void {
    let contextParam = null;
    if (isPlatformServer(this.platformId) && this.siteContextFromCookie?.country) {
      contextParam = this.siteContextFromCookie.language;
    } else {
      contextParam = getContextParameterDefault(config, LANGUAGE_CONTEXT_ID);
    }

    if (!this.languageService.isInitialized() && contextParam) {
      this.languageService.setActive(contextParam);
    }
  }
}
