<div
  class="cx-modal-container"
  [cxFocus]="{
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
}">
  <div class="cx-modal-content">
    <div class="cart-dialog" *ngIf="cart$ | async as cart">
      <div class="cx-dialog-header modal-header d-flex">
        <div class="cx-dialog-title modal-title promtion-title-left" aria-live="polite" aria-atomic="true">
          {{ 'amCartDetails.popup.earnmiles.title' | cxTranslate }}
        </div>
        <button type="button" class="close-popup-btn link" (click)="closeModal('Cross click')">
          <svg class="icon-close">
            <use xlink:href="/assets/images/icons/sprite.svg#icon-close" />
          </svg>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="main">
          <!-- Base Miles Breakdown List -->
          <ul class="entries-delivery-fee" *ngFor="let entry of filterEntries(cart.entries)">
            <li class="entries-name word-break">{{ entry.product.name }}</li>
            <li>
              <span class="am-icon-grey"></span>
              <span class="font-bold">
                {{ entry.earnedMiles | number : '1.0' }}
              </span>
            </li>
          </ul>
          <!-- Bonus Miles Promotion Rule -->
          <ng-container *ngFor="let promotion of cart.appliedBonusPromotions">
            <ul class="entries-delivery-fee font-green">
              <li class="d-flex entries-name">
                <span>{{ promotion.promotion.name }}</span>
                <button
                  class="link"
                  (click)="openOfferDetailsModal(promotion.promotion)"
                  aria-label="open miles promotion popup">
                  <span class="icon-promo"></span>
                </button>
              </li>
              <li>
                <span class="am-icon-grey"></span>
                <span class="font-bold">
                  {{ promotion.bonusMiles | number : '1.0' }}
                </span>
              </li>
            </ul>
          </ng-container>

          <!-- earnmiles total -->
          <div class="subtotal">
            <span
              [innerHTML]="
                'amCartDetails.popup.earnmiles.subtotalLabelGrey'
                  | cxTranslate : { count: (cart.totalBonusMiles | number : '1.0') }
                  | safeHtml
              ">
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
