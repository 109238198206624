import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { AmAuthService } from '@core/auth/user-auth/facade/am-auth.service';
import { AmOAuthLibWrapperService } from '@core/auth/user-auth/services/am-oauth-wrapper.service';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService, CsAgentAuthService, TokenTarget } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthToken,
  FeatureConfigService,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  UserIdService
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmCsAgentAuthService extends CsAgentAuthService {
  protected subscription = new Subscription();
  constructor(
    protected authService: AmAuthService,
    protected authStorageService: AsmAuthStorageService,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: AmOAuthLibWrapperService,
    protected store: Store,
    protected userProfileFacade: UserProfileFacade,
    protected userAccountFacade: UserAccountFacade,
    protected occEndpointSvc: OccEndpointsService,
    protected httpClient: HttpClient,
    @Optional() protected featureConfig?: FeatureConfigService
  ) {
    super(
      authService,
      authStorageService,
      userIdService,
      oAuthLibWrapperService,
      store,
      userProfileFacade,
      userAccountFacade,
      featureConfig
    );
  }
  /**
   * Loads access token for a customer support agent.
   * @param userId
   * @param password
   */
  async authorizeCustomerSupportAgent(): Promise<void> {
    let userToken: AuthToken | undefined;
    this.authStorageService
      .getToken()
      .subscribe((token) => (userToken = token))
      .unsubscribe();
    // CSAgent: asm login while doesn't bind the user account
    this.authStorageService.switchTokenTargetToCSAgent();
    try {
      let customerId: string | undefined;
      this.userIdService
        .getUserId()
        .subscribe((userId) => {
          customerId = userId;
        })
        .unsubscribe();
      this.store.dispatch(new AuthActions.Logout());
      // asm login and bind the user account
      if (customerId !== undefined && customerId !== OCC_USER_ID_ANONYMOUS && userToken !== undefined) {
        this.userIdService.setUserId(customerId);
        this.authStorageService.setEmulatedUserToken(userToken);
        this.store.dispatch(new AuthActions.Login());
      } else {
        // asm login while doesn't bind the user account
        this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS);
        this.authStorageService.clearEmulatedUserToken();
      }
    } catch {
      // asm login fail
      this.authStorageService.switchTokenTargetToUser();
    }
  }

  async logoutCustomerSupportAgent(): Promise<void> {
    this.authService.coreLogout();
  }

  /**
   * Starts an ASM customer emulation session.
   * A customer emulation session is stopped by calling logout().
   * @param customerId
   */
  public startCustomerEmulationSession(customerId: string): void {
    this.authStorageService.clearEmulatedUserToken();
    const personifyCustomerUrl = this.occEndpointSvc.getBaseUrl() + '/assisted-service/personify-customer';
    let params: HttpParams = new HttpParams().set('emulator', customerId);
    this.subscription.add(
      this.httpClient.post(personifyCustomerUrl, params, {}).subscribe(
        () => {
          // OCC specific user id handling. Customize when implementing different backend
          this.store.dispatch(new AuthActions.Logout());
          this.userIdService.setUserId(customerId);
          this.store.dispatch(new AuthActions.Login());
        },
        (error) => {
          console.log('query fail', error);
        }
      )
    );
  }

  /**
   * Check if CS agent is currently logged in.
   *
   * @returns observable emitting true when CS agent is logged in or false when not.
   */
  public isCustomerSupportAgentLoggedIn(): Observable<boolean> {
    return combineLatest([this.authStorageService.getToken(), this.authStorageService.getTokenTarget()]).pipe(
      map(([token, tokenTarget]) => {
        return Boolean(token?.access_token && tokenTarget === TokenTarget.CSAgent);
      })
    );
  }

  /**
   * Utility function to determine if customer is emulated.
   *
   * @returns observable emitting true when there is active emulation session or false when not.
   */
  public isCustomerEmulated(): Observable<boolean> {
    return this.userIdService.isEmulated();
  }

  /**
   * Returns the customer support agent's token loading status
   */
  public getCustomerSupportAgentTokenLoading(): Observable<boolean> {
    // TODO(#8248): Create new loading state outside of store
    return of(false);
  }

  /**
   * Logout a customer support agent.
   */

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
