export class CookiesNameSpace {
  static readonly TEXT_SEARCH_HISTORY = 'TEXT_SEARCH_HISTORY';
}

export default class CookiesHelper {
  /**
   *
   * @param key: the key for new cookie, it will be encoded when saved to the cookie
   * @param value: the value for new cookie, it will be encoded when saved to the cookie
   * @param expiredDate: default to be one year later
   * @param path: default to be '/'
   * @param domain: default to be the website domain
   * @param secure
   * @returns
   */
  static setCookie(
    key: string,
    value: string,
    expiredDate?: Number | String | Date,
    path?: string,
    domain?: string,
    secure?: string
  ): void | boolean {
    if (typeof document === 'undefined') {
      return false;
    }
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return false;
    }
    // default expired in next year, align with the cookie bar
    if (!expiredDate) {
      expiredDate = 365 * 24 * 60 * 60;
    }
    let expiredDateStr = '';
    if (expiredDate) {
      switch (expiredDate.constructor) {
        case Number:
          expiredDateStr =
            expiredDate === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + expiredDate;
          break;
        case String:
          expiredDateStr = '; expires=' + expiredDate;
          break;
        case Date:
          expiredDateStr = '; expires=' + (expiredDate as Date).toUTCString();
          break;
      }
    }
    document.cookie =
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(value) +
      expiredDateStr +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '; path=/') +
      (secure ? '; secure' : '');
    return true;
  }

  /**
   *
   * @param name
   * @param cookie
   * @returns the decoded value of cookie
   */
  static getCookie(name: string, cookie?: string): string | null {
    if (typeof document === 'undefined') {
      return '';
    }
    cookie = cookie || document?.cookie;
    const regExp = new RegExp('(?:^|;\\s*)' + name + '=([^;]*)', 'g');
    const result: RegExpExecArray = regExp.exec(cookie);
    return (result && decodeURIComponent(result[1])) || null;
  }

  /**
   *
   * @param key: the delete key, it will be encode then use to get value
   * @param path: as the default path of setCookie is '/', we also let it to be / when delete
   * @param domain
   */
  static deleteCookie(key: string, path?: string, domain?: string): void {
    if (typeof document === 'undefined') {
      return;
    }
    document.cookie =
      encodeURIComponent(key) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '; path=/');
  }
}
