import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BaseOption,
  isNotNullable,
  Product,
  ProductScope,
  ProductService,
  RequiredPick,
  VariantType
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'am-product-variants-container',
  templateUrl: './product-variants-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductVariantsContainerComponent {
  @Input() set productCode(v) {
    this._productCode = v;
    this.product$ = this.productService.get(this.productCode, ProductScope.DETAILS).pipe(
      filter(isNotNullable),
      filter((product): product is RequiredPick<Product, 'baseOptions'> => !!product.baseOptions),
      map((product) => {
        let newBaseOptions = [];
        if (product.baseOptions.length > 1) {
          product.baseOptions.forEach((val) => {
            newBaseOptions.unshift(val);
          });
          return { ...product, baseOptions: newBaseOptions };
        }
        return product;
      }),
      distinctUntilChanged()
    );
  }
  get productCode() {
    return this._productCode;
  }

  private _productCode: string;
  @Input() pageFrom: string = '';
  @Output() emitProductCode: EventEmitter<string> = new EventEmitter();

  constructor(private productService: ProductService) {}

  variants = [];
  variantType = VariantType;
  product$: Observable<Product | null>;

  getHasColor(variantOption: BaseOption, index: number): boolean {
    return variantOption.options?.some((e) => e.variantOptionQualifiers[index]?.qualifier === 'color');
  }

  covertVariants(variant: BaseOption, index: number): BaseOption {
    const variantTep = {} as BaseOption;
    variantTep.options = variant.options?.map((val) => {
      // if no value ,take the first one
      const variantOptionQualifiers = val.variantOptionQualifiers[index] || val.variantOptionQualifiers[0];
      return { ...val, ...variantOptionQualifiers };
    });
    // if no value ,take the first one
    const variantOptionQualifiers =
      variant.selected.variantOptionQualifiers[index] || variant.selected.variantOptionQualifiers[0];
    variantTep.selected = { ...variant.selected, ...variantOptionQualifiers };
    if (index === 0) {
      variantTep.isTheOptionSecondLevel = false;
    } else {
      variantTep.isTheOptionSecondLevel = true;
    }
    return variantTep;
  }

  getProductCode(code: string): void {
    if (this.pageFrom === 'mylistPage') {
      this.emitProductCode.emit(code);
    }
  }
}
