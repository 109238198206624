import { Injectable } from '@angular/core';
import { InflightSalesChannel } from '@config/am-constant.config';
import { WindowRef } from '@spartacus/core';
import CookiesHelper from '@utils/cookies/cookies.helper';
import { FlightItem } from './flight-info.service';

export const FLIGHT_INFO_KEY = 'FLIGHT_INFO';
export const FLOW_INDICATOR_KEY = 'FLOW_INDICATOR';
export const FLIGHT_INFO_SAVE_TIME = 'FLIGHT_INFO_SAVE_TIME';
export const FLIGHT_INFO_EXPIRED_PERIOD = 'FLIGHT_INFO_EXPIRED_PERIOD';
export const INFLIGHT_PICKUP_FLOW_VALUE = 'inflight pickup flow';
export const INFLIGHT_NON_PICKUP_FLOW_VALUE = 'inflight non-pickup flow';

@Injectable({
  providedIn: 'root'
})
export class FlightStatusStorageService {
  constructor(protected winRef: WindowRef) {}

  private inflightSalesChannelValue: string;

  storeFlightStatus(flightInfoStorage: FlightItem, flowIndicator?: string): void {
    this.winRef.localStorage?.setItem(FLIGHT_INFO_KEY, JSON.stringify(flightInfoStorage));
    CookiesHelper.setCookie('posCode', flightInfoStorage.posCode);
    this.winRef.localStorage?.setItem(FLOW_INDICATOR_KEY, flowIndicator);
    this.winRef.localStorage?.setItem(FLIGHT_INFO_SAVE_TIME, new Date().getTime() + '');
  }

  removeFlightStatus(): void {
    this.winRef.localStorage?.removeItem(FLIGHT_INFO_KEY);
    this.winRef.localStorage?.removeItem(FLOW_INDICATOR_KEY);
    this.winRef.localStorage?.removeItem(FLIGHT_INFO_SAVE_TIME);
    this.winRef.localStorage?.removeItem('InflightFLow1ActiveInflightFlow');
  }

  getFilgitInfo(): FlightItem | null {
    return this.winRef.localStorage?.getItem(FLIGHT_INFO_KEY)
      ? JSON.parse(this.winRef.localStorage?.getItem(FLIGHT_INFO_KEY))
      : null;
  }

  getFlightNumber(): string | null {
    const FLIGHTINFOKEY = this.winRef.localStorage?.getItem(FLIGHT_INFO_KEY);
    return FLIGHTINFOKEY ? JSON.parse(FLIGHTINFOKEY).posCode : null;
  }

  getInFlightIndicator(): string {
    return this.winRef.localStorage?.getItem(FLOW_INDICATOR_KEY);
  }

  getInflightSalesChannel(): string {
    const FLIGHTINFOKEY = this.winRef.localStorage?.getItem(FLIGHT_INFO_KEY);
    return FLIGHTINFOKEY ? JSON.parse(FLIGHTINFOKEY).inflightSalesChannel : null;
  }

  updateInflightSalesChannel(inflightSalesChannel: string): void {
    const FLIGHTINFOKEY = this.winRef.localStorage?.getItem(FLIGHT_INFO_KEY);
    if (FLIGHTINFOKEY) {
      let flightInfo = JSON.parse(FLIGHTINFOKEY);
      flightInfo.inflightSalesChannel = inflightSalesChannel ? inflightSalesChannel : InflightSalesChannel.OFF;
      this.winRef.localStorage?.setItem(FLIGHT_INFO_KEY, JSON.stringify(flightInfo));
    }
  }

  get isInflightPickFlow(): boolean {
    return (
      (this.inflightSalesChannel === InflightSalesChannel.INFLIGHTONLINE ||
        this.inflightSalesChannel === InflightSalesChannel.INFLIGHTONLINEANDOFFLINE ||
        (this.getInflightSalesChannel() &&
          this.getInflightSalesChannel().indexOf(InflightSalesChannel.INFLIGHTONLINE) > -1)) &&
      !!this.getFlightNumber()
    );
  }

  get isPosInflightTypeFlow(): boolean {
    return this.getFlightPk() && !!this.getFlightNumber();
  }

  set inflightSalesChannel(value: string) {
    this.inflightSalesChannelValue = value;
  }

  get inflightSalesChannel(): string {
    return this.inflightSalesChannelValue;
  }

  getFlightPk(): string | null {
    const flightInfo = this.winRef.localStorage?.getItem(FLIGHT_INFO_KEY);
    return flightInfo ? JSON.parse(flightInfo).pk : null;
  }

  getFlightInfoSaveTime(): number {
    return Number(this.winRef.localStorage?.getItem(FLIGHT_INFO_SAVE_TIME));
  }

  getFlightInfoExpiredPeriod(): number {
    return parseInt(this.winRef.localStorage?.getItem(FLIGHT_INFO_EXPIRED_PERIOD)) * 60 * 1000;
  }
}
