import { Injectable } from '@angular/core';
import { PageLayoutHandler } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FavoriteNotifyProductsService } from './favorite-notify-products.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteNotifyProductsPageHandler implements PageLayoutHandler {
  constructor(protected favoriteNotifyProductsService: FavoriteNotifyProductsService) {}

  handle(slots$: Observable<string[]>, pageTemplate?: string, section?: string): Observable<string[]> | any {
    if (pageTemplate === 'ProductListPageTemplate' && !section) {
      return slots$.pipe(
        switchMap((slots) => {
          // favorite page share the same page template with plp
          if (slots.includes('ProductTabSlot')) {
            return this.favoriteNotifyProductsService.products$.pipe(
              map((products) => {
                const exclude = (arr, args): string[] => arr.filter((item) => args.every((arg) => arg !== item));
                return products.length === 0
                  ? exclude(slots, ['ProductListSlot', 'ProductLeftRefinements'])
                  : exclude(slots, ['EmptyProductListSlot', 'ProductLeftRefinements']);
              })
            );
          }
          return of(slots);
        })
      );
    }
    return slots$;
  }
}
