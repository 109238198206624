import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessage } from '@model/WebServiceResponse.model';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorTypeMapping } from './response-type-map';

export enum NoNeedNotFoundPage {
  CheckoutError = 'CheckoutError',
  CartError = 'CartError'
}
@Injectable()
export class ErrorMappingResponseInterceptor implements HttpInterceptor {
  constructor(protected routingService: RoutingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (response) => {
          if (response instanceof HttpResponse && response?.status === 200) {
            if (response.body?.responseInfo?.globalMessages?.accErrorMsgs) {
              response.body.responseInfo.globalMessages.accErrorMsgs =
                response.body.responseInfo.globalMessages.accErrorMsgs.map((val: GlobalMessage) => {
                  let type = new ErrorTypeMapping(val.code);
                  return {
                    ...val,
                    type: type.type
                  };
                });
            }
            if (response.body?.responseInfo?.globalMessages?.accInfoMsgs) {
              response.body.responseInfo.globalMessages.accInfoMsgs =
                response.body.responseInfo.globalMessages.accInfoMsgs.map((val: GlobalMessage) => {
                  let type = new ErrorTypeMapping(val.code);
                  return {
                    ...val,
                    type: type.type
                  };
                });
            }
            if (response.body?.errorMeg) {
              response.body.errorMeg.code = new ErrorTypeMapping(response.body.errorMeg.exceptionMessage).type;
            }
            if (response.body?.statusCode !== 'success' && response.body?.errorMsg) {
              response.body.errorMsg = new ErrorTypeMapping(response.body.errorMsg).type;
            }
            if (response.body?.orderDetailData?.totalDelivery?.length > 0) {
              response.body?.orderDetailData?.totalDelivery?.forEach((val) => {
                const consignments = val.consignments.map((cons) => {
                  let cancelAlertMsgType = new ErrorTypeMapping(cons.cancelAlertMsg).type;

                  return {
                    ...cons,
                    cancelAlertMsgType
                  };
                });

                val.consignments = consignments;
              });
            }
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              console.log('HTTP response Angular parse error', error);
              return;
            }
            // if (error.status === 400 && !this.isNeedGoToNotFoundPage(error.error)) {
            //     // to do not found page
            //     this.routingService.go({ cxRoute: 'notFound' });
            //     console.log("not found");
            // }
          }
        }
      )
    );
  }

  isNeedGoToNotFoundPage(error): boolean {
    return error.errors?.some((e) => NoNeedNotFoundPage[e.type]);
  }
}
