import { Action } from '@ngrx/store';
import { Country } from '@spartacus/core/src/model';
import { CountriesState } from '../state';

export const COUNTRY_CONTEXT_ID = 'country';
export const LOAD_COUNTRIES = '[Site-context] Load Countries';
export const LOAD_COUNTRIES_FAIL = '[Site-context] Load Countries Fail';
export const LOAD_COUNTRIES_SUCCESS = '[Site-context] Load Countries Success';
export const SET_ACTIVE_COUNTRY = '[Site-context] Set Active Countries';
export const COUNTRY_CHANGE = '[Site-context] Countries Change';

export class LoadCountries implements Action {
  readonly type = LOAD_COUNTRIES;
}

export class LoadCountriesFail implements Action {
  readonly type = LOAD_COUNTRIES_FAIL;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

export class LoadCountriesSuccess implements Action {
  readonly type = LOAD_COUNTRIES_SUCCESS;
  constructor(public payload: Country[]) {
    this.payload = payload;
  }
}

export class SetActiveCountry implements Action {
  readonly type = SET_ACTIVE_COUNTRY;
  constructor(public payload: string) {
    this.payload = payload;
  }
}

export class CountryChange implements Action {
  readonly type = COUNTRY_CHANGE;
  constructor(public payload: { previous: string; current: string }) {
    this.payload = payload;
  }
}

declare module '@spartacus/core' {
  interface SiteContextState {
    countries?: CountriesState;
  }
}

// action types
export type CountriesAction =
  | LoadCountries
  | LoadCountriesFail
  | LoadCountriesSuccess
  | SetActiveCountry
  | CountryChange;
