import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryService } from '@components/layout/header/am-site-context/services/country/country.service';
import {
  ActivatedRouterStateSnapshot,
  CurrencyService,
  LanguageService,
  ProductSearchPage,
  ProductSearchService,
  RouterState,
  RoutingService,
  WindowRef
} from '@spartacus/core';
import { ProductListRouteParams, SearchCriteria, ViewConfig } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable, Subscription, using } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';

/**
 * The `ProductListComponentService` is used to search products. The service is used
 * on the Product Listing Page, for listing products and the facet navigation.
 *
 * The service exposes the product search results based on the category and search
 * route parameters. The route parameters are used to query products by the help of
 * the `ProductSearchService`.
 */
@Injectable({ providedIn: 'root' })
export class AmProductListComponentService {
  protected readonly RELEVANCE_ALLCATEGORIES = ':relevance:allCategories:';
  protected subscription = new Subscription();

  constructor(
    protected productSearchService: ProductSearchService,
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected config: ViewConfig,
    protected countryService: CountryService,
    protected winRef: WindowRef
  ) {}

  private showMobileFacetModalSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public showMobileFacetModalObs: Observable<boolean> = this.showMobileFacetModalSub.asObservable();

  openFacetModal(): void {
    this.showMobileFacetModalSub.next(true);
  }

  closeFacetModal(): void {
    this.showMobileFacetModalSub.next(false);
  }

  /**
   * Emits the search results for the current search query.
   *
   * The `searchResults$` is _not_ concerned with querying, it only observes the
   * `productSearchService.getResults()`
   */
  protected searchResults$: Observable<ProductSearchPage> = this.productSearchService
    .getResults()
    .pipe(filter((searchResult) => Object.keys(searchResult).length > 0));

  /**
   * Observes the route and performs a search on each route change.
   *
   * Context changes, such as language and currencies are also taken
   * into account, so that the search is performed again.
   */
  protected searchByRouting$: Observable<ActivatedRouterStateSnapshot> = combineLatest([
    this.routing.getRouterState().pipe(
      distinctUntilChanged((x, y) => {
        // router emits new value also when the anticipated `nextState` changes
        // but we want to perform search only when current url changes
        return x.state.url === y.state.url;
      })
    ),
    ...this.siteContext
  ]).pipe(
    debounceTime(0),
    map(([routerState, ..._context]) => routerState as RouterState),
    tap((routerState: RouterState) => {
      // refresh plp , client side no search
      if (routerState.navigationId === 1 && this.winRef.isBrowser()) {
        return;
      }
      const state = routerState.state;
      const criteria = this.getCriteriaFromRoute(state.params, state.queryParams);
      if (
        state.queryParams?.currentPage &&
        (!Number(state.queryParams?.currentPage) || Number(state.queryParams?.currentPage) < 0)
      ) {
        this.search({ ...criteria, currentPage: 1 });
        this.router.navigate([], {
          queryParams: {
            ...state.queryParams,
            currentPage: 1
          }
        });
      } else {
        this.search(criteria);
      }
    }),
    map((routerState) => routerState.state)
  );

  /**
   * This stream is used for the Product Listing and Product Facets.
   *
   * It not only emits search results, but also performs a search on every change
   * of the route (i.e. route params or query params).
   *
   * When a user leaves the PLP route, the PLP component unsubscribes from this stream
   * so no longer the search is performed on route change.
   */
  readonly model$: Observable<ProductSearchPage> = using(
    () => this.searchByRouting$.subscribe(),
    () => this.searchResults$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  /**
   * Expose the `SearchCriteria`. The search criteria are driven by the route parameters.
   *
   * This search route configuration is not yet configurable
   * (see https://github.com/SAP/spartacus/issues/7191).
   */
  protected getCriteriaFromRoute(routeParams: ProductListRouteParams, queryParams: SearchCriteria): SearchCriteria {
    return {
      query: queryParams.query || this.getQueryFromRouteParams(routeParams),
      pageSize: queryParams.pageSize || this.config.view?.defaultPageSize,
      currentPage: queryParams.currentPage,
      sortCode: queryParams.sortCode,
      categoryCode: queryParams.categoryCode ?? routeParams.categoryCode
    };
  }

  /**
   * Resolves the search query from the given `ProductListRouteParams`.
   */
  protected getQueryFromRouteParams({ query, categoryCode, brandCode }: ProductListRouteParams): string {
    if (query) {
      return query;
    }
    if (categoryCode) {
      return this.RELEVANCE_ALLCATEGORIES + categoryCode;
    }

    // TODO: drop support for brands as they should be treated
    // similarly as any category.
    if (brandCode) {
      return this.RELEVANCE_ALLCATEGORIES + brandCode;
    }
  }

  /**
   * Performs a search based on the given search criteria.
   *
   * The search is delegated to the `ProductSearchService`.
   */
  protected search(criteria: SearchCriteria): void {
    let currentPage = criteria.currentPage;
    const pageSize = criteria.pageSize;
    let sort = criteria.sortCode;
    const categoryCode = criteria.categoryCode;

    this.productSearchService.search(
      criteria.query,
      Object.assign(
        {},
        currentPage && { currentPage },
        pageSize && { pageSize },
        categoryCode && { categoryCode },
        sort && { sort }
      )
    );
  }

  /**
   * Get items from a given page without using navigation
   */
  getPageItems(pageNumber: number, pageSize: number): void {
    this.routing
      .getRouterState()
      .subscribe((route) => {
        const routeCriteria = this.getCriteriaFromRoute(route.state.params, route.state.queryParams);
        const criteria = {
          ...routeCriteria,
          currentPage: pageNumber,
          pageSize: pageSize
        };
        this.search(criteria);
      })
      .unsubscribe();
  }

  /**
   * Sort the search results by the given sort code.
   */
  sort(sortCode: string): void {
    this.route({ sortCode });
  }

  /**
   * Routes to the next product listing page, using the given `queryParams`. The
   * `queryParams` support sorting, pagination and querying.
   *
   * The `queryParams` are delegated to the Angular router `NavigationExtras`.
   */
  protected route(queryParams: SearchCriteria): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute
    });
  }

  /**
   * The site context is used to update the search query in case of a
   * changing context. The context will typically influence the search data.
   *
   * We keep this private for now, as we're likely refactoring this in the next
   * major version.
   */
  private get siteContext(): Observable<string>[] {
    // TODO: we should refactor this so that custom context will be taken
    // into account automatically. Ideally, we drop the specific context
    // from the constructor, and query a ContextService for all contexts.

    return [this.languageService.getActive(), this.currencyService.getActive(), this.countryService.getActive()];
  }
}
