import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { svgConfig } from '@config/am-svg-sprite.config';
import { WindowRef } from '@spartacus/core';
import { AmActiveCartService } from 'src/app/amiredeem/pages/cart/service/active-cart.service';
import { OrderSummaryNoticeService } from './order-summary-notice.service';

@Component({
  selector: 'order-summary-notice',
  templateUrl: './order-summary-notice.component.html'
})
export class OrderSummaryNoticeComponent {
  @ViewChild('dialog') dialog: ElementRef;
  svgType = svgConfig;

  autoClose: NodeJS.Timeout;
  private closeTime = this.orderSummaryNoticeService.showTime * 1000;
  @Input() entryNumber;
  @HostListener('window:resize', ['event'])
  onResize(event): void {
    if (this.dialog) {
      this.initDialog();
    }
  }
  constructor(
    private orderSummaryNoticeService: OrderSummaryNoticeService,
    private activeCartService: AmActiveCartService,
    protected winRef: WindowRef
  ) {
    this.initDialog();
  }

  initDialog(): void {
    this.clearAutoClose();
    this.addAutoClose();
  }
  close(): void {
    this.orderSummaryNoticeService.close();
  }
  goToCart(): void {
    this.close();
    // this.activeCartService.reloadActiveCart();
  }

  clearAutoClose(): void {
    if (this.autoClose) {
      clearTimeout(this.autoClose);
    }
  }
  addAutoClose(): void {
    this.autoClose =
      this.winRef.isBrowser() &&
      setTimeout(() => {
        this.close();
      }, this.closeTime);
  }
}
