const PRODUCTTAGGROUPAGREEN = ['HOT', 'NEW', 'PRE_ORDER', 'COMINGSOON'];
const PRODUCTTAGGROUPAORANGE = ['GIFTOFFER', 'SPECIAL_OFFER'];

export function getTagGroupAColor(tagType: string) {
  const type = tagType.toUpperCase();
  if (type.startsWith('PLACEHOLDER')) {
    return 'green';
  }
  if (PRODUCTTAGGROUPAGREEN.includes(type)) {
    return 'green';
  }
  if (PRODUCTTAGGROUPAORANGE.includes(type)) {
    return 'orange';
  }

  return 'grey';
}
